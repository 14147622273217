import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth.guard';
// import { AssocInvitationComponent } from './assoc-invitation/assoc-invitation.component';
// import { AssociatesComponent } from './associates/associates.component';
import { ConnectionListComponent } from './connection-list/connection-list.component';

import { FollowersComponent } from './followers/followers.component';

import { MemberComponent } from './member.component';
import {NotificationComponent} from './notification/notification.component'

const routes: Routes = [

  {path:'member/notification',component:NotificationComponent,canActivate: [AuthGuard]},

{path:'member/followers',component:FollowersComponent,canActivate: [AuthGuard]},
// {path:'member/assoc-invitation',component:AssocInvitationComponent},
// {path:'member/associates',component:AssociatesComponent},
{path:'member/associates',component:ConnectionListComponent,canActivate: [AuthGuard]},


   {
    path:'',
    component:MemberComponent,
     canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberRoutingModule { }
