import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class LikeService {
  
    public apiURL = environment.baseURL;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  
  
    constructor(private httpClient: HttpClient) { }
  
    getAll(post_id,category_id): Observable<any> {
      const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('USERID') });
      const headers = { headers: header };
      console.log(headers, localStorage.getItem('USERID'));
      return this.httpClient.get<any>(this.apiURL + 'like',headers)
        .pipe(
          catchError(this.errorHandler)
        )
    }
  
    create(formData): Observable<any> {
  
      const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('USERID') });
    const headers = { headers: header };
    console.log(headers, localStorage.getItem('USERID'));

      return this.httpClient.post(this.apiURL + 'like',formData,headers)
        .pipe(
          catchError(this.errorHandler)
        )
    }
  
    errorHandler(error: any) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        errorMessage = error.error.message;
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(errorMessage);
    }
  }