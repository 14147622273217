<div class="container form-rc1">
    <div class="row">
        <div class="col-md-8 form-area">
            <div class="ibox">
                <div class="ibox-title">
                    <span class="pull-right">(
                            <strong>{{cartList.length}}</strong>) items
                        </span>
                    <h5>Items in your cart</h5>
                </div>
                <div class="ibox-content" *ngFor="let list of cartList let i = index">
                    <div class="table-responsive">
                        <table class="table shoping-cart-table">
                            <tbody>
                                <tr>
                                    <td class="desc">
                                        <h3>
                                            <a href="#" class="text-navy">
                                                    {{i+1}}. {{list.title}}
                                                </a>
                                        </h3>
                                        <p class="small">
                                            {{list.description}}
                                        </p>
                                        <dl class="small m-b-none">
                                            <dt>Author</dt>
                                            <dd>{{list.username}}</dd>
                                        </dl>
                                        <div class="m-t-sm">
                                            <a (click)="removeCartItem(i)" class="text-muted">
                                                <i class="fa fa-trash"></i> Remove item
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        ₹{{list.price}}
                                        <s class="small text-muted">₹ 100.00</s>
                                    </td>
                                    <td width="65">
                                        <input type="text" class="form-control" placeholder="1">
                                    </td>
                                    <td>
                                        <h4>
                                            ₹{{list.price}}
                                        </h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- <div class="ibox-content">
                    <div class="table-responsive">
                        <table class="table shoping-cart-table">
                            <tbody>
                                <tr>
                                    <td class="desc">
                                        <h3>
                                            <a href="#" class="text-navy">
                                                    2. Land Acquisition Act 2013
                                                </a>
                                        </h3>
                                        <p class="small">
                                            There are many variations of passages of Lorem Ipsum available
                                        </p>
                                        <dl class="small m-b-none">
                                            <dt>Author</dt>
                                            <dd>Team soOLEGAL</dd>
                                        </dl>
                                        <div class="m-t-sm">
                                            <a href="#" class="text-muted">
                                                <i class="fa fa-trash"></i> Remove item
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        ₹50,00
                                        <s class="small text-muted">₹63,00</s>
                                    </td>
                                    <td width="65">
                                        <input type="text" class="form-control" placeholder="2">
                                    </td>
                                    <td>
                                        <h4>
                                            ₹100,00
                                        </h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> -->
            </div>
            <div class="">
                <hr>
                <button class="btn btn-white">
                        <i class="fa fa-arrow-left"></i> Continue shopping
                    </button>
            </div>
        </div>
        <div class="col-md-4">
            <span id="render-amt-summary">
                    <div class="ibox-title">
                        <h5>Cart Summary</h5>
                    </div>
                    <hr>
                    <div class="text-navy">
                        <large>Subtotal</large>
                        <small class="pull-right">
                            <i class="fa fa-inr ft-12"></i> {{totalcartamt}}/-
                        </small>
                    </div>
                    <div class="ft-16" style="background: #ecdbec; padding: 10px;">
                        <div>
                            <b>GST</b>
                            <small class="pull-right text-white"></small>
                            <br>
                            <b style="padding-left: 9px;">
                                <small>- CGST @9%</small>
                            </b>
                            <small></small>
                            <small class="pull-right text-success">
                                <b>
                                    <i class="fa fa-inr ft-12"></i>{{cgstamt}}
                                </b>
                            </small>
                            <br>
                            <b style="padding-left: 9px;">
                                <small>- SGST @9%</small>
                            </b>
                            <small></small>
                            <small class="pull-right text-success">
                                <b>
                                    <i class="fa fa-inr ft-12"></i>{{sgstamt}}
                                </b>
                            </small>
                        </div>
                    </div>
                    <div style="background: #90278e;padding: 10px;color: #fff;">
                        <b>
                            Total
                        </b>
                        <small class="pull-right text-white">
                            <b>
                                <i class="fa fa-inr ft-12"></i> {{TotalTaxamt}}
                            </b>
                        </small>
                    </div>
                    &nbsp;
                    <button (click)="checkout()" style="color: #90278e; background-color:#fff; border-color: #90278e;" class="w-100 btn btn-primary btn-lg" type="submit">Continue to checkout</button>
                </span>
        </div>
    </div>
</div>