import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import {FormGroup} from '@angular/forms'
import { response } from 'express';
import { ToastrService } from 'ngx-toastr';

import { ContactusService } from './contactus.service';



@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  title = 'Contact Us | Soolegal';
 
  formdata:any
  toast: any;
  contacturl:string ='https://api.beta.soolegal.com/public/api/contact';
  test:any;

  contactForm= new FormGroup({
    name:new FormControl('',[Validators.required]),
    mobile:new FormControl('',[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    email: new FormControl('',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    message:new FormControl('',[Validators.required,Validators.maxLength(15)]),
  })
  
  ContactusService: any;
  email: any;
  message:any;
  mobile:any;
  name:any;
  isFormSubmitted = false;
  showSuccess:any;
  ToastrService: any;
  constructor(private formbuilder: FormBuilder,private contactus:ContactusService,private toastr: ToastrService) { }

  ngOnInit(): void {
  }
  
   onSubmit( contactForm) {
    console.log( contactForm)
    this.contactus.PostMessage( contactForm).subscribe(response=>{
      this.isFormSubmitted = true;
      
      if(this.contactForm.valid){
        this.toastr.success("Saved Successfully ")
        this.contactForm.reset();
        this.isFormSubmitted = false
        }
        
        console.log(response)
        
      }),error=>{
        console.log(error.responseType)
        console.log({error})
    }
    
    }
    
   
  }
  
  
  


  
  //  this.contactForm = this.formbuilder.group({
    
  //     name: ['',[Validators.required]],
  //     mobile: ['',[Validators.required]],
  //     email: ['',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
  //     message:['',[Validators.required,Validators.maxLength(50)]]
  //   })




//   if (!this.name) {
//     this.toast.error('Please enter your name', ' ', {
//       timeOut: 3000,
//     });
//   } else if (!this.mobile) {
//     this.toast.error('Please enter mobile', ' ', {
//       timeOut: 3000,
//     });
//   }else if (!this.email){
//     this.toast.error('Please enter email','',{
//       timeOut:3000,
//     });
//  }else if (!this.message){
//    this.toast.error('Please enter message', '',{
//      timeOut:3000,
//    });
//  } else {

//   this.contactus.postcontact('contactForm','value').subscribe((res:any) => {
//     if (res.status == 400) {
//       this.toast.error('', res.data.response)
//     } else {
//       this.cont = new contactus();
//       this.toast.success('', 'Saved successfully !');
//     }

//   }, error => {
//     console.log(error);
//   });

// }




// get name() {
//   return this.contactForm.get('name');
// }
// get mobile() {
//   return this.contactForm.get('mobile');
// }
// get email() {
//   return this.contactForm.get(' email');
// }
// get message() {
//   return this.contactForm.get('message');
// }
