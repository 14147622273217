import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoarComponent } from './roar.component';
import { IndexComponent } from './index/index.component';
import { ViewComponent } from './view/view.component';
import { CreateComponent } from './create/create.component';
// import { RoarFormComponent } from './roarform/roarform.component';

const routes: Routes = [
  { path: 'roar', redirectTo: 'roar', pathMatch: 'full'},
  { path: '', component: IndexComponent },
  { path: 'roar/:roarId/edit', component: IndexComponent } ,
  { path: 'roar/:roarId/view', component: ViewComponent },
  // { path: 'roar/view', component: ViewComponent },
  { path: 'roar/create', component: CreateComponent },
  // { path: 'roar/roarform', component: RoarFormComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoarRoutingModule { }
