import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import{CommentComponent} from './src/app/comment/comment.component';
// import{SingleCommentComponent} from './src/app/comment/singlecomment.component';
import{ CommentComponent } from './comment.component';
import{SingleCommentComponent} from './singlecomment.component';
@NgModule({
    declarations: [
      CommentComponent,
      SingleCommentComponent,
    ],
    imports: [
      CommonModule,
      // BrowserModule,
      FormsModule,
      ReactiveFormsModule,
    ],
    exports: [
      CommentComponent,
      SingleCommentComponent,
      
    ]
    
  })
  export class CommentModule { 
    
  }
