<header class="header1">
    <h1 class="header__title">Add Document To Resource Center</h1>
</header>
<div class="content">

    <div class="content__inner">

        <div class="container overflow-hidden">
            <!-- <form [formGroup]="myForm" (ngSubmit)="submit()"> -->
            <form>
                <div class="multisteps-form">

                    <div class="row">
                        <div class="col-12 col-lg-8 ml-auto mr-auto mb-4">
                            <div class="multisteps-form__progress">
                                <button class="multisteps-form__progress-btn " type="button" title="User Info" [ngClass]="step==1?'js-active':''">Select Category</button>
                                <button class="multisteps-form__progress-btn" type="button" title="Address" [ngClass]="step==2?'js-active':''">Add Document</button>
                                <button class="multisteps-form__progress-btn" type="button" title="Order Info" [ngClass]="step==3?'js-active':''">Submit</button>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-8 m-auto">

                            <!-- <form class="multisteps-form__form"> -->
                            <!-- < class="multisteps-form__form" > -->
                            <div [ngClass]="step==1?'js-active':''" class="multisteps-form__panel shadow p-4 rounded bg-white " data-animation="scaleIn">
                                <div class="multisteps-form__content">
                                    <div class="grid">
                                        <div class="cell">
                                            <div class="inner">
                                                <img class="iconsize" (click)="form(2)" (click)="setstep(2)" (click)="subCategory(2)" src="https://www.soolegal.com/cdn.soolegal.com/assets/uploads/resource-centre/category/Judgements.png">
                                                <div class="caption">
                                                    <a>Judgements</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cell">
                                            <div class="inner">
                                                <img class="iconsize" (click)="form(3)" (click)="setstep(4)"  (click)="subCategory(3)" src="https://cdn.soolegal.com/assets/uploads/resource-centre/category/Acts%20&%20Amendments.png">
                                                <div class="caption">
                                                    <a>Bare Acts</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cell">
                                            <div class="inner">
                                                <img class="iconsize " (click)="form(1)" (click)="setstep(5)"  (click)="subCategory(1)" src="https://cdn.soolegal.com/assets/uploads/resource-centre/category/Legal Procedures.png">
                                                <div class="caption">
                                                    <a>Legal Procedure</a>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="cell">
                                            <div class="inner">
                                                <img class="iconsize" (click)="form(7)" (click)="setstep(2)" src="https://test.soolegal.com/cdn-static/assets/images/needalawyer/LegalNotices.png">
                                                <div class="caption">
                                                    <a>Legal Notices</a>
                                                </div>
                                            </div>
                                        </div> -->
                                   
                                        <div class="cell">
                                            <div class="inner">
                                                <img class="iconsize" (click)="form(4)" (click)="setstep(10)"  (click)="subCategory(4)" src="https://cdn.soolegal.com/assets/uploads/resource-centre/category/Legal Drafts.png">
                                                <div class="caption">
                                                    <a>Legal Formats</a>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <!-- <div class="cell">
                                            <div class="inner">
                                                <img class="iconsize" (click)="form(2)" (click)="setstep(2)" src="https://test.soolegal.com/cdn-static/assets/images/needalawyer/Acts&amp;Amendments.png">
                                                <div class="caption">
                                                    <a>Law|Statute|<br>Acts|Update</a>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="cell">
                                            <div class="inner">
                                                <img class="iconsize" (click)="form(5)" (click)="setstep(2)" src="https://test.soolegal.com/cdn-static/assets/images/needalawyer/CourtAffidavits&amp;Drafts.png">
                                                <div class="caption">
                                                    <a>Court Affidavits &amp; Drafts</a>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="cell">
                                            <div class="inner">
                                                <img class="iconsize" (click)="form(6)" (click)="setstep(2)" src="https://test.soolegal.com/cdn-static/assets/images/needalawyer/CourtApplications&amp;Pleading-Drafts.png">
                                                <div class="caption">
                                                    <a>Court Application &amp; Pleading Drafts</a>
                                                </div>
                                            </div>
                                        </div> -->
<!-- 
                                        <div class="cell">
                                            <div class="inner">
                                                <img class="iconsize" (click)="form(8)" (click)="setstep(2)" src="https://test.soolegal.com/cdn-static/assets/images/needalawyer/LegalIntelligence.png">
                                                <div class="caption">
                                                    <a>Project / Dissertation</a>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="cell">
                                            <div class="inner">
                                                <img class="iconsize" (click)="form(9)" (click)="setstep(2)" src="https://test.soolegal.com/cdn-static/assets/images/needalawyer/researchpaper.png">
                                                <div class="caption">
                                                    <a>Legal Research</a>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="cell">
                                            <div style="display: grid;" class="inner">
                                                <img class="iconsize" (click)="form(10)" (click)="setstep(2)" src="https://test.soolegal.com/cdn-static/assets/images/needalawyer/videos.png">
                                                <div class="caption">
                                                    <a>Videos</a>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="cell">
                                            <div class="inner">
                                                <img class="iconsize" (click)="form(1341)" (click)="setstep(2)" src="https://test.soolegal.com/cdn-static/assets/images/needalawyer/Miscellaneous.png">
                                                <div class="caption">
                                                    <a>Miscellaneous</a>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="cell">
                                            <div style="display: grid;" class="inner">
                                                <img class="iconsize" (click)="setstep('')" (click)="form(12)" src="https://test.soolegal.com/cdn-static/assets/images/needalawyer/others.png">
                                                <div class="caption">
                                                    <a>Others</a>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                            <!-- judgement form start -->
                            <form [formGroup]="myForm" (ngSubmit)="judgement_submit()" style="height:400px;">
                                <div class="multisteps-form__form">
                                    <div [ngClass]="step==2?'js-active':''" class="multisteps-form__panel shadow p-4 rounded bg-white " data-animation="scaleIn">
                                        <h3 class="multisteps-form__title">Upload Your Document</h3>
                                        <div class="multisteps-form__content">
                                            <div class="form-row mt-4">
                                                <div class="col">
                                                    <input class="multisteps-form__input form-control" type="text" formControlName="title" [required]="true" placeholder="Document Title *" /><span *ngIf="submitted && f['title'].errors?.['required']" style="color:red"> (Required)</span>
                                                </div>                                                
                                            </div>
                                            <div >
                                                <div class="form-row mt-4">
                                                    <div class="col">
                                                        <input id="input-b2" name="input-b2" type="file" class="file" formControlName="url" [required]="true" (change)="onFileChange($event)" data-show-preview="false" accept="application/pdf" >
                                                        <span *ngIf="submitted && f['url'].errors?.['required']" style="color:red"> (Required)</span>
                                                    </div>
                                                </div>

                                                <div class="form-row mt-4">
                                                    <div class="col" formControlName="url" (change)="onFileChange($event)">
                                                        <p>Upload .Pdf or .Doc or .Docx file</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="form-row mt-4" *ngIf="enablevideoUploadUrl">
                                                <div class="col">
                                                    <input class="multisteps-form__input form-control" type="text" formControlName="videourl" [required]="true" placeholder="Video Url" />
                                                    <span *ngIf="submitted && f['videourl'].errors?.['required']" style="color:red"> (Required)</span>
                                                </div>

                                            </div> -->



                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label>
                                                        <input type="radio" value="0" name="event-registration" class="radio" formControlName="paymentType" [required]="true"
                                                        (change)="registration($event)"  />
                                                        <span *ngIf="submitted && f['paymentType'].errors?.['required']" style="color:red"> (Required)</span>
                                                        <span class="radio-style d-inline-block"></span>
                                                        <span>Free Document </span>
                                                    </label>
                                                </div>

                                                <!-- <div class="col-md-4">
                                                    <label>
                                                        <input type="radio" value="1" name="event-registration" class="radio" formControlName="paymentType"
                                                        (change)="registration($event)"/>
                                                        <span class="radio-style d-inline-block"></span>
                                                        <span>Paid Document </span>
                                                    </label>`
                                                </div> -->

                                                <!-- <div class="col-md-4">
                                                    <input type="text" formControlName="amount" class="form-control m-0 rounded-0 bg-white" id="exampleInputEmail1" aria-describedby="phoneHelp" placeholder="Document Amount" />
                                                </div> -->
                                            </div>
                                            <div class="button-row d-flex mt-4">
                                                <button class="btn btn-primary js-btn-prev" type="button" (click)="setstep(1)" title="Prev">Prev</button>
                                                <button class="btn btn-primary ml-auto js-btn-next" type="submit" title="Next">Next</button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                               
                            </form>
                            <form [formGroup]="myForm1" (ngSubmit)="submit1()">
                                <div [ngClass]="step==8?'js-active':''" class="multisteps-form__panel shadow p-4 rounded bg-white " data-animation="scaleIn">
                                    <h3 class="multisteps-form__title">Add Tags & Description</h3>
                                    <div class="multisteps-form__content">                                       

                                        <div class="form-row mt-4">
                                            <div class="col">
                                                <input class="multisteps-form__input form-control" type="text" [required]="true" required formControlName="tags" placeholder="Tags" /><span *ngIf="submitted1 && f1['tags'].errors?.['required']" style="color:red"> (Required)</span>
                                            </div>
                                        </div>
                                        <div class="form-row mt-4">
                                            <div class="col">
                                                <label for="FormControlTextareaevents" [required]="true" required formControlName="description">Description</label><span *ngIf="submitted1 && f1['description'].errors?.['required']" style="color:red"> (Required)</span>
                                                <textarea class="form-control" [required]="true" required id="FormControlTextareaevents" formControlName="description" rows="7"></textarea>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="button-row d-flex mt-4 col-12">
                                                <button class="btn btn-primary js-btn-prev" type="button" (click)="setstep(2)" title="Prev">Prev</button>
                                                <button class="btn btn-primary ml-auto js-btn-next" type="submit" title="Next">Submit &
                                            Share </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                            <!-- judgement form end -->

                             <!-- bare acts form start -->
                             <form [formGroup]="myForm" (ngSubmit)="bare_acts_submit()" style="height:400px;">
                                <div class="multisteps-form__form">
                                    <div [ngClass]="step==4?'js-active':''" class="multisteps-form__panel shadow p-4 rounded bg-white " data-animation="scaleIn">
                                        <h3 class="multisteps-form__title">Upload Your Document</h3>
                                        <div class="multisteps-form__content">
                                            <div class="form-row mt-4">
                                                <div class="col">
                                                    <input class="multisteps-form__input form-control" type="text" formControlName="title" [required]="true" placeholder="Document Title *" /><span *ngIf="submitted && f['title'].errors?.['required']" style="color:red"> (Required)</span>
                                                </div>                                               
                                            </div>
                                            <div >
                                                <div class="form-row mt-4">
                                                    <div class="col">
                                                        <input id="input-b2" name="input-b2" type="file" class="file" formControlName="url" [required]="true" (change)="onFileChange($event)" data-show-preview="false" accept=".pdf,.doc,.docx">
                                                        <span *ngIf="submitted && f['url'].errors?.['required']" style="color:red"> (Required)</span>
                                                    </div>
                                                </div>

                                                <div class="form-row mt-4">
                                                    <div class="col" formControlName="url" (change)="onFileChange($event)">
                                                        <p>Upload .Pdf or .Doc or .Docx file</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="form-row mt-4" *ngIf="enablevideoUploadUrl">
                                                <div class="col">
                                                    <input class="multisteps-form__input form-control" type="text" formControlName="videourl" [required]="true" placeholder="Video Url" />
                                                    <span *ngIf="submitted && f['videourl'].errors?.['required']" style="color:red"> (Required)</span>
                                                </div>

                                            </div> -->



                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label>
                                                        <input type="radio" value="0" name="event-registration" class="radio" formControlName="paymentType" [required]="true"
                                                        (change)="registration($event)"  />
                                                        <span *ngIf="submitted && f['paymentType'].errors?.['required']" style="color:red"> (Required)</span>
                                                        <span class="radio-style d-inline-block"></span>
                                                        <span>Free Document </span>
                                                    </label>
                                                </div>

                                                <!-- <div class="col-md-4">
                                                    <label>
                                                        <input type="radio" value="1" name="event-registration" class="radio" formControlName="paymentType"
                                                        (change)="registration($event)"/>
                                                        <span class="radio-style d-inline-block"></span>
                                                        <span>Paid Document </span>
                                                    </label>`
                                                </div> -->

                                                <!-- <div class="col-md-4">
                                                    <input type="text" formControlName="amount" class="form-control m-0 rounded-0 bg-white" id="exampleInputEmail1" aria-describedby="phoneHelp" placeholder="Document Amount" />
                                                </div> -->
                                            </div>
                                            <div class="button-row d-flex mt-4">
                                                <button class="btn btn-primary js-btn-prev" type="button" (click)="setstep(1)" title="Prev">Prev</button>
                                                <button class="btn btn-primary ml-auto js-btn-next" type="submit" title="Next">Next</button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                               
                            </form>                            
                            <form [formGroup]="myForm1" (ngSubmit)="submit1()">
                                <div [ngClass]="step==7?'js-active':''" class="multisteps-form__panel shadow p-4 rounded bg-white " data-animation="scaleIn">
                                    <h3 class="multisteps-form__title">Add Tags & Description</h3>
                                    <div class="multisteps-form__content">                                       

                                        <div class="form-row mt-4">
                                            <div class="col">
                                                <input class="multisteps-form__input form-control" type="text" [required]="true" required formControlName="tags" placeholder="Tags" /><span *ngIf="submitted1 && f1['tags'].errors?.['required']" style="color:red"> (Required)</span>
                                            </div>
                                        </div>
                                        <div class="form-row mt-4">
                                            <div class="col">
                                                <label for="FormControlTextareaevents" [required]="true" required formControlName="description">Description</label><span *ngIf="submitted1 && f1['description'].errors?.['required']" style="color:red"> (Required)</span>
                                                <textarea class="form-control" [required]="true" required id="FormControlTextareaevents" formControlName="description" rows="7"></textarea>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="button-row d-flex mt-4 col-12">
                                                <button class="btn btn-primary js-btn-prev" type="button" (click)="setstep(4)" title="Prev">Prev</button>
                                                <button class="btn btn-primary ml-auto js-btn-next" type="submit" title="Next">Submit &
                          Share </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                            <!-- bare acts form end -->

                             <!-- Legal Procedure form start -->
                             <form [formGroup]="myForm" (ngSubmit)="legal_procedure_submit()" style="height:400px;">
                                <div class="multisteps-form__form">
                                    <div [ngClass]="step==5?'js-active':''" class="multisteps-form__panel shadow p-4 rounded bg-white " data-animation="scaleIn">
                                        <h3 class="multisteps-form__title">Upload Your Document</h3>
                                        <div class="multisteps-form__content">
                                            <div class="form-row mt-4">
                                                <div class="col">
                                                    <input class="multisteps-form__input form-control" type="text" formControlName="title" [required]="true" placeholder="Document Title *" /><span *ngIf="submitted && f['title'].errors?.['required']" style="color:red"> (Required)</span>
                                                </div>                                                
                                            </div>
                                            <div>
                                                <div class="form-row mt-4">
                                                    <div class="col">
                                                        <input id="input-b2" name="input-b2" type="file" class="file" formControlName="url" [required]="true" (change)="onFileChange($event)" data-show-preview="false" accept=".pdf,.doc,.docx">
                                                        <span *ngIf="submitted && f['url'].errors?.['required']" style="color:red"> (Required)</span>
                                                    </div>
                                                </div>

                                                <div class="form-row mt-4">
                                                    <div class="col" formControlName="url" (change)="onFileChange($event)">
                                                        <p>Upload .Pdf or .Doc or .Docx file</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="form-row mt-4" *ngIf="enablevideoUploadUrl">
                                                <div class="col">
                                                    <input class="multisteps-form__input form-control" type="text" formControlName="videourl" [required]="true" placeholder="Video Url" />
                                                    <span *ngIf="submitted && f['videourl'].errors?.['required']" style="color:red"> (Required)</span>
                                                </div>

                                            </div> -->



                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label>
                                                        <input type="radio" value="0" name="event-registration" class="radio" formControlName="paymentType" [required]="true"
                                                        (change)="registration($event)"  />
                                                        <span *ngIf="submitted && f['paymentType'].errors?.['required']" style="color:red"> (Required)</span>
                                                        <span class="radio-style d-inline-block"></span>
                                                        <span>Free Document </span>
                                                    </label>
                                                </div>

                                                <!-- <div class="col-md-4">
                                                    <label>
                                                        <input type="radio" value="1" name="event-registration" class="radio" formControlName="paymentType"
                                                        (change)="registration($event)"/>
                                                        <span class="radio-style d-inline-block"></span>
                                                        <span>Paid Document </span>
                                                    </label>`
                                                </div> -->

                                                <!-- <div class="col-md-4">
                                                    <input type="text" formControlName="amount" class="form-control m-0 rounded-0 bg-white" id="exampleInputEmail1" aria-describedby="phoneHelp" placeholder="Document Amount" />
                                                </div> -->
                                            </div>
                                            <div class="button-row d-flex mt-4">
                                                <button class="btn btn-primary js-btn-prev" type="button" (click)="setstep(1)" title="Prev">Prev</button>
                                                <button class="btn btn-primary ml-auto js-btn-next" type="submit" title="Next">Next</button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                               
                            </form>
                            <form [formGroup]="myForm1" (ngSubmit)="submit1()">
                                <div [ngClass]="step==6?'js-active':''" class="multisteps-form__panel shadow p-4 rounded bg-white " data-animation="scaleIn">
                                    <h3 class="multisteps-form__title">Add Tags & Description legal procedure</h3>                                   
                                    <div class="multisteps-form__content">
                                        <div class="form-row mt-4" *ngIf="enablevideoUploadUrl">
                                            <div class="col"><span *ngIf="submitted1 && f1['select_sub_category_id'].errors?.['required']" style="color:red"> (Required)</span>
                                              
                                                <select class="multisteps-form__select form-control" formControlName="select_sub_category_id" [required]="true">
                                        <option >Select Category</option>  

                          <option value="{{r.category_id}},{{r.name}}" *ngFor="let r of legal_procedure_sub_category_list">{{r.name}}  </option>
                         </select>
                                                <!-- <ng-multiselect-dropdown [placeholder]="'custom placeholder'"  
                      [settings]="dropdownSettings"
                        [data]="dropdownList" 
                        [(ngModel)]="selectedItems"
                         (onSelect)="onItemSelect($event)"
                         (onSelectAll)="onSelectAll($event)">
                      </ng-multiselect-dropdown> -->

                                            </div>
                                        </div>


                                        <div class="form-row mt-4">
                                            <div class="col">
                                                <input class="multisteps-form__input form-control" type="text" [required]="true" required formControlName="tags" placeholder="Tags" /><span *ngIf="submitted1 && f1['tags'].errors?.['required']" style="color:red"> (Required)</span>
                                            </div>
                                        </div>
                                        <div class="form-row mt-4">
                                            <div class="col">
                                                <label for="FormControlTextareaevents" [required]="true" required formControlName="description">Description</label><span *ngIf="submitted1 && f1['description'].errors?.['required']" style="color:red"> (Required)</span>
                                                <textarea class="form-control" [required]="true" required id="FormControlTextareaevents" formControlName="description" rows="7"></textarea>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="button-row d-flex mt-4 col-12">
                                                <button class="btn btn-primary js-btn-prev" type="button" (click)="setstep(5)" title="Prev">Prev</button>
                                                <button class="btn btn-primary ml-auto js-btn-next" type="submit" title="Next">Submit &
                          Share </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>                           
                            <!-- Legal Procedure form end -->

                            <!-- Legal Format form start -->
                            <form [formGroup]="myForm" (ngSubmit)="legal_format_submit()" style="height:400px;">
                                <div class="multisteps-form__form">
                                    <div [ngClass]="step==10?'js-active':''" class="multisteps-form__panel shadow p-4 rounded bg-white " data-animation="scaleIn">
                                        <h3 class="multisteps-form__title">Upload Your Document</h3>
                                        <div class="multisteps-form__content">
                                            <div class="form-row mt-4">
                                                <div class="col">
                                                    <input class="multisteps-form__input form-control" type="text" formControlName="title" [required]="true" placeholder="Document Title *" /><span *ngIf="submitted && f['title'].errors?.['required']" style="color:red"> (Required)</span>
                                                </div>                                              
                                            </div>
                                            <div >
                                                <div class="form-row mt-4">
                                                    <div class="col">
                                                        <input id="input-b2" name="input-b2" type="file" class="file" formControlName="url" [required]="true" (change)="onFileChange($event)" data-show-preview="false" accept=".pdf,.doc,.docx">
                                                        <span *ngIf="submitted && f['url'].errors?.['required']" style="color:red"> (Required)</span>
                                                    </div>
                                                </div>

                                                <div class="form-row mt-4">
                                                    <div class="col" formControlName="url" (change)="onFileChange($event)">
                                                        <p>Upload .Pdf or .Doc or .Docx file</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="form-row mt-4" *ngIf="enablevideoUploadUrl">
                                                <div class="col">
                                                    <input class="multisteps-form__input form-control" type="text" formControlName="videourl" [required]="true" placeholder="Video Url" />
                                                    <span *ngIf="submitted && f['videourl'].errors?.['required']" style="color:red"> (Required)</span>
                                                </div>

                                            </div> -->



                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label>
                                                        <input type="radio" value="0" name="event-registration" class="radio" formControlName="paymentType" 
                                                        (click)="onChange1($event)"  (change)="registration($event)"  />
                                                        <span class="radio-style d-inline-block"></span>
                                                        <span>Free Document </span>
                                                    </label>
                                                </div>

                                                <div class="col-md-4">
                                                    <label>
                                                        <input type="radio" value="1" name="event-registration" class="radio" formControlName="paymentType" [required]="true"
                                                        (click)="onChange1($event)"   (change)="registration($event)"/>
                                                        <span *ngIf="submitted && f['paymentType'].errors?.['required']" style="color:red"> (Required)</span>
                                                        <span class="radio-style d-inline-block"></span>
                                                        <span >Paid Document </span>
                                                    </label>
                                                </div>

                                                <div *ngIf="selectedType == '1'" class="col-md-4">
                                                    <input type="text" formControlName="amount" class="form-control m-0 rounded-0 bg-white" id="exampleInputEmail1" aria-describedby="phoneHelp" placeholder="Document Amount"  [required]="true" />
                                                    <span *ngIf="submitted && f['amount'].errors?.['required']" style="color:red"> (Required)</span>
                                                </div>
                                                <div *ngIf="selectedType == '0'" class="col-md-4">
                                                    <input hidden="true" type="text" formControlName="amountfree" class="form-control m-0 rounded-0 bg-white" id="exampleInputEmail1" aria-describedby="phoneHelp" placeholder="Document Amount"   />
                                                    
                                                </div>
                                            </div>
                                            <div class="button-row d-flex mt-4">
                                                <button class="btn btn-primary js-btn-prev" type="button" (click)="setstep(1)" title="Prev">Prev</button>
                                                <button class="btn btn-primary ml-auto js-btn-next" type="submit" title="Next">Next</button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            
                            </form>
                            <form [formGroup]="myForm1" (ngSubmit)="submit1()">
                                <div [ngClass]="step==11?'js-active':''" class="multisteps-form__panel shadow p-4 rounded bg-white " data-animation="scaleIn">
                                    <h3 class="multisteps-form__title">Add Tags & Description</h3>                                   
                                    <div class="multisteps-form__content">
                                        <div class="form-row mt-4" *ngIf="enablevideoUploadUrl">
                                    <div class="col">
                                        <!-- <span *ngIf="submitted1 && f1['select_sub_category_id'].errors?.['required']" style="color:red"> (Required)</span> -->
                                            
                                 <!-- <select class="multisteps-form__select form-control" formControlName="select_legal_format_sub_category_id" [required]="true">
                            <option >Select Category</option>  

                            <option  value="{{r.category_id}},{{r.name}}" *ngFor="let r of legal_format_sub_category_list">{{r.name}}  </option>
                            </select> -->


                                <div class="form-row mt-4"> 
                                    <span *ngIf="submitted1 && f1['sub_category_name'].errors?.['required']" style="color:red"> (Required)</span>                           
                                    <label>Select Category</label>
                                    <select (change)="onChange($event)" formControlName="sub_category_name" class="form-control select2" type="text" style="width: 100%;" [required]="true">
                                    <option value="15,Contracts & Agreements" >Contracts & Agreements</option>
                                    <option value="16,Court drafts">Court drafts</option>
                                    </select>
                                </div>
                              
                              <div *ngIf="selectedType == '15,Contracts & Agreements'" class="form-row mt-4">
                                <span *ngIf="submitted1 && f1['sub_sub_category_name'].errors?.['required']" style="color:red"> (Required)</span>                           
                                 <label>Select sub Category</label>
                                 <select class="multisteps-form__select form-control" formControlName="sub_sub_category_name" [required]="true">
                                  <option  value="{{r.category_id}},{{r.name}}" *ngFor="let r of legal_format_sub_sub_category_list">{{r.name}}  </option>
                                 </select>
                              </div>
                              
                              <div *ngIf="selectedType == '16,Court drafts'" class="form-row mt-4">
                                <span *ngIf="submitted1 && f1['sub_sub_category_name'].errors?.['required']" style="color:red"> (Required)</span>                           
                                <label>Select sub Category</label>
                                <select class="multisteps-form__select form-control" formControlName="sub_sub_category_name" [required]="true">
                                  <option  value="{{r.category_id}},{{r.name}}" *ngFor="let r of legal_format_sub_sub_category_list">{{r.name}}  </option>
                                </select>
                              </div>

                            
                                                <!-- <ng-multiselect-dropdown [placeholder]="'custom placeholder'"  
                            [settings]="dropdownSettings"
                            [data]="dropdownList" 
                            [(ngModel)]="selectedItems"
                            (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)">
                            </ng-multiselect-dropdown> -->

                                            </div>
                                        </div>


                                        <div class="form-row mt-4">
                                            <div class="col">
                                                <input class="multisteps-form__input form-control" type="text" [required]="true" required formControlName="tags" placeholder="Tags" />
                                                <span *ngIf="submitted1 && f1['tags'].errors?.['required']" style="color:red"> (Required)</span>
                                            </div>
                                        </div>
                                        <div class="form-row mt-4">
                                            <div class="col">
                                                <label for="FormControlTextareaevents" [required]="true" required formControlName="description">Description</label><span *ngIf="submitted1 && f1['description'].errors?.['required']" style="color:red"> (Required)</span>
                                                <textarea class="form-control" [required]="true" required id="FormControlTextareaevents" formControlName="description" rows="7"></textarea>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="button-row d-flex mt-4 col-12">
                                                <button class="btn btn-primary js-btn-prev" type="button" (click)="setstep(10)" title="Prev">Prev</button>
                                                <button class="btn btn-primary ml-auto js-btn-next" type="submit" title="Next">Submit &
                            Share </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>                           
                            <!--  Legal Format form end -->
                            
                            <!-- <form [formGroup]="myForm1" (ngSubmit)="submit1()">
                                <div [ngClass]="step==3?'js-active':''" class="multisteps-form__panel shadow p-4 rounded bg-white " data-animation="scaleIn">
                                    <h3 class="multisteps-form__title">Add Tags & Description </h3>
                                    <div class="multisteps-form__content">
                                        <div class="form-row mt-4" *ngIf="enablevideoUploadUrl">
                                            <div class="col"><span *ngIf="submitted1 && f1['select_category'].errors?.['required']" style="color:red"> (Required)</span>
                                                <select class="multisteps-form__select form-control" formControlName="select_category" [required]="true">
                          <option>Select Category</option>
                          <option value="{{r.category_id}},{{r.name}}" *ngFor="let r of resource">{{r.name}}  </option>
                        </select>
                                                <ng-multiselect-dropdown [placeholder]="'custom placeholder'"  
                      [settings]="dropdownSettings"
                        [data]="dropdownList" 
                        [(ngModel)]="selectedItems"
                         (onSelect)="onItemSelect($event)"
                         (onSelectAll)="onSelectAll($event)">
                      </ng-multiselect-dropdown>

                                            </div>
                                        </div>


                                        <div class="form-row mt-4">
                                            <div class="col">
                                                <input class="multisteps-form__input form-control" type="text" [required]="true" required formControlName="tags" placeholder="Tags" /><span *ngIf="submitted1 && f1['tags'].errors?.['required']" style="color:red"> (Required)</span>
                                            </div>
                                        </div>
                                        <div class="form-row mt-4">
                                            <div class="col">
                                                <label for="FormControlTextareaevents" [required]="true" required formControlName="description">Description</label><span *ngIf="submitted1 && f1['description'].errors?.['required']" style="color:red"> (Required)</span>
                                                <textarea class="form-control" [required]="true" required id="FormControlTextareaevents" formControlName="description" rows="7"></textarea>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="button-row d-flex mt-4 col-12">
                                                <button class="btn btn-primary js-btn-prev" type="button" (click)="setstep(2)" title="Prev">Prev</button>
                                                <button class="btn btn-primary ml-auto js-btn-next" type="submit" title="Next">Submit &
                          Share </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form> -->

                          
                            
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>