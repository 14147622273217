import { Component, OnInit,ViewChild,EventEmitter,Output,ElementRef} from '@angular/core';
import { RoarService } from '../roar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Roar } from '../roar';
import { CommentService } from '../../comment/comment.service';  
import { environment } from 'src/environments/environment';
import { LikeService } from '../../like/like.service';
import { LoginComponent } from '../../login/login.component';
import { LoginService } from '../../login/login.service';
import { ApiService } from '../../api.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ResourceCentreService } from '../../resource-centre/resource-centre.service';
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  tableSize: number = 2;
  tableSizes: any = [3, 6, 9, 12];
  contributor:any;
  top_contributor_list:any; 
  id: number;
  roar1: Roar[] = [];
  roar: any;
  feed_data:any;
  token:string;
  comments: Comment[];
  category_id = 1;
    roarId:any;
    liked:any='';
  r:any;
  roar_title:any;
  closeModal:string;
  constructor(
    public roarService: RoarService,
    private route: ActivatedRoute,
    private router: Router,public resourcecenterService:ResourceCentreService,private modalService: NgbModal,public loginService: LoginService,public api: ApiService,public mainapi: ApiService, public commentService: CommentService,public likeService: LikeService
   ) { if(localStorage.getItem('USERID')){


    this.token =  localStorage.getItem('USERID')
   }
   if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
   this.refresh();
   }
      
 }
@ViewChild(LoginComponent)loginPrev: LoginComponent;
@ViewChild('openLoginModal')loginModal: ElementRef; 
@Output() loginEvent = new EventEmitter<string>();
    
 
  ngOnInit(): void {
    var roarid = this.route.snapshot.params['roarId'];
    this.findRoar(roarid);
    this.getComments(roarid, this.category_id);
    this.topcontributor();
    this.get_top_contributor_list();
    // this.getRoar('');
    }
    // findRoar(roarid){
    //   console.log('getting Roar6');
    //   this.roarService.find(roarid).subscribe((res:any) => {
    //     this.feed_data = res.message.feed_list;
    //     console.log('RoarFinded',  this.roar);
    //   });
    // }
  findRoar(roarid){
    console.log('getting Roar6');
    this.roarService.find(roarid).subscribe((res:any) => {
      this.roar = res.messages.feed_list[0];
      console.log('RoarFinded',  this.roar);
    });
  }
  getRoar(roarId) {
    console.log('getting Roar');
    this.roarService.getAll(roarId).subscribe((res: Roar[]) => {
      this.roar1 = res;
      console.log("Roar Created..:::", this.roar);
    });
  }

  getComments(post_id, category_id) {                             
    this.commentService.getAll(post_id, category_id).subscribe((com: Comment[]) => {
      this.comments = com;
      console.log("Comments..:::", this.comments);

    });
  }
  topcontributor(){
    this.resourcecenterService.topContributor().subscribe((res: any) => {
      this.contributor = res;
    })
  }
  
  // onCommentCreate() {
  //   this.getComments(this.roar.id, this.category_id);
  //   console.log('news creayed');
  // }
  getTopContributorImage(c)
  {
    return environment.pathurl +"cdn/assets/uploads/user_profile_pic/"+ c.url;
  }
  getImage(roar) {
    // return environment.baseURL+"news-image/" + news.url;
    // return "http://localhost:8080/api/news-image/" + news.url;
    return roar.roar_image_url;
  }
  getImagePic(roar) {
    return roar.profile_pic_url;
  }
  get_top_contributor_list()
 {
  this.roarService.top_contributor_list().subscribe((res: any) => {
    this.top_contributor_list = res.messages.top_contributor_list;
    console.log("top_contributor_list.:::", this.top_contributor_list);
})
 }

  onCommentCreate() {
    this.getComments(this.roar.id, this.category_id);
    console.log('news creayed');
  }
  onComment() {
    this.getComments(this.roar.id, this.category_id);
    console.log('news creayed');
  }
 
  like(roar) {      
    if(this.token){
        let roar_id = roar.id,
          liked = roar.liked,
          author_id = roar.author_id;
    
        // this.showicon = !this.showicon;
        // this.show = !this.show;
        console.log('like',roar);
        const formData = new FormData();
        formData.append('post_id', roar_id + '');
        formData.append('category_id', this.category_id + '');
        formData.append('member_id', author_id + '');
        if (liked > 0 ) {
          liked = 0;
          // roar.like_count = roar.like_count - 1;
        } else {
         liked = 1;
          // roar.like_count = roar.like_count + 1;
        }
        // UPdating the ui object
        roar.liked = liked;
    
        formData.append('status', liked + '');
        console.log('status',liked);
    
        this.likeService.create(formData).subscribe((res: any) => {
          console.log("Roar Liked..:::", res.roar);
        });
    
        console.log(this.category_id);
      }
    
      }
  onTableDataChange(event: any) {
    this.page = event;
    this.findRoar;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.findRoar;
  } 


  click(){
    if(this.token){

    }else{
      this.loginPrev.openLogin();
    }
  }
  refresh() {
    if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
    localStorage.clear();
    // window.location.reload();
    }
  }
  openLogin() {
    this.loginModal.nativeElement.click()
  }
  loginSubmit(event:any) {
  }
  getLocation(news_id){
    // return location.href+'/'+news_id;
   return  "https://stackoverflow.com"+'/'+news_id;
  }
  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
     
    
     
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReasons(res)}`;
     
    });
   
  }
  
  private getDismissReasons(reason: any): string {
    
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
     
    }
   
  }
}