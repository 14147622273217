<!--Post Comments-->
<div>
    <form [formGroup]="commentFormGroup" (ngSubmit)="commentSubmit()">
        <div class="row" id="forum2-1357142">
            <div class="col-sm-4">
                <div class="form-group">
                    <input style="height: 29px;" *ngIf="!memberapi.userInfoapi?.member_id" required name="commentname" formControlName="name" id="comment-name2-1357142"
                        type="text" placeholder="Name*" class="form-control">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <input style="height: 29px;"  name="commentemail" *ngIf="!memberapi.userInfoapi?.member_id" required formControlName="email" id="comment-email2-1357142"
                        type="text" placeholder="Email*" class="form-control">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <input style="height: 29px;" name="commentcontactno" *ngIf="!memberapi.userInfoapi?.member_id" formControlName="phone_number"
                        id="comment-contactno2-1357142" type="text" placeholder="Phone" class="form-control">
                </div>
            </div>
            <div class="col-sm-12">
                <!-- <grammarly-extension data-grammarly-shadow-root="true"
            style="position: absolute; top: 0px; left: 0px; pointer-events: none;"
            class="cGcvT"></grammarly-extension>
        <grammarly-extension data-grammarly-shadow-root="true"
            style="mix-blend-mode: darken; position: absolute; top: 0px; left: 0px; pointer-events: none;"
            class="cGcvT"></grammarly-extension> -->
                <input type="hidden" name="topicid" id="topicid2-1357142" data-postid="1357142" data-topic="2"
                    value="2">
                <textarea name="comment" cols="40" rows="1" formControlName="comment" id="comment2-1357142"
                    placeholder="Add a comment..." class="form-control comment-box-height" style="min-height: 100px;"
                    spellcheck="false"></textarea>
            </div>
            <div class="col-sm-12 comment"><button type="submit"  id="comment_forum_post2-1357142"
                    data-forumcombineid="2-1357142" data-mid=""
                    class="comment_forum_post pull-right btn-primary btn btn-xs"
                    style="border-radius: 4px; margin: 3px;"><i class="far fa-comment-alt"></i> Add
                    comment</button></div>
        </div>
    </form>
</div>