
<div class="tab-content" id="ex2-content">
    <div class="tab-pane fade show active" id="ex2-tabs-1" role="tabpanel"
        aria-labelledby="ex2-tab-1">
       
        <div class="row">
            <div class="col-sm-9" style="padding-right:10px">
                <!-- <h3>The High Court of Delhi was established on 31st October, 1966.</h3> -->
                <p style="    padding-left: 15px;" [innerHtml]="barassociation?.description"></p>
              
                </div>
               
               
            <!-- bar association sidebar area start -->
            <div class="col-sm-6 col-md-3">
                <h4>Related Posts</h4>
                <div class="top-arrow">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-short" style="height:30%; width:30%"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
                    </svg>
                </div>

                <div class="box-slider-1" id="sidebar" *ngFor="let l of Limit">
                    <div class="component-box-1" >
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>{{l.title}}</h4>
                                <div class="user-info-box-inner p-0 no-scroll min-height">
                                    <div class="user-info-box">
                                        <div class="bg-white">
                                            <img src="images/pasted-image.jpg" class="img-fluid" />
                                        </div>
                                        <div class="box-wrapper">
                                            <p>
                                               {{l.description}}
                                            </p>
                                            <a [attr.href]="'/event/' + l.id +'/add'" class="btn btn-primary">Register Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                 
                </div> 
                <div class="bottom-arrow">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-down-short" style="height:30%; width:30%"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
                    </svg>
                </div>
            </div>

           
        </div>
    </div>
   
</div>
































