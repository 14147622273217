import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NeedALawyerRoutingModule } from './need-a-lawyer-routing.module';
import { NeedALawyerComponent } from './need-a-lawyer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { LoginModule } from '../login/login.module';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxEditorModule } from 'ngx-editor';
import { ViewComponent } from './view/view.component';
import { HttpClientModule } from '@angular/common/http';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

@NgModule({
  declarations: [
    NeedALawyerComponent,
    ViewComponent
  ],
  imports: [  
    CommonModule,
    NeedALawyerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    ShareButtonsModule,
    ShareIconsModule,
    MatDialogModule,
    MatButtonModule,
    AutocompleteLibModule,
    LoginModule,
    SlickCarouselModule,
    NgxEditorModule,
    HttpClientModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class NeedALawyerModule { }
