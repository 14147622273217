<div class="container" style="margin-top: 7%;">
  <div class="row">
    <div class="col-md-9 col-sm-6">
      <form [formGroup]="myForm" (ngSubmit)="submit()" >
        <h2>Add News</h2>

        <!-- <pre>{{existingNews | json}}</pre> -->
        <div class="form-row">

          <div class="form-group col-md-6">
            <label for="title">Title</label><span *ngIf="submitted && f['title'].errors?.['required']" style="color:red" >
              (Required)</span>

            <input type="text" class="form-control" formControlName="title" [required]="true" id="inputEmail4"
              placeholder="">
              
            <input type="text" class="form-control" formControlName="id" [required]="true" id="inputEmail4"
              placeholder="" hidden="true">

          </div>
          <!-- <div *ngIf="!myForm.valid">Enter Title</div> -->
          <div class="col-lg-6 col-sm-6">
            <label for="image">Select images<span *ngIf="submitted && f['url'].errors?.['required']" style="color:red">
                (Required)</span></label>
            <div class="form-group"><input type="file" name="url" formControlName="url1" id="url"
                (change)="onFileChange($event)" class="filestyle" value="" data-icon="false" tabindex="-1"
                style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
              <div class="bootstrap-filestyle input-group"><input type="text" class="form-control no-padd "
                  placeholder="" disabled="" [value]="newsImage.name?newsImage.name:(existingNews?existingNews.url:'')">
                <span class="group-span-filestyle input-group-btn" tabindex="0"><label for="url"
                    class="btn btn-default "><span class="buttonText">Choose</span></label></span></div>

            </div>

          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputCity">Image Source Link</label>
            <input type="text" class="form-control" formControlName="image_source_title" id="image_source_title"
              placeholder="">
          </div>
          <div class="form-group col-md-6">
            <label for="publishdate">Source Link<span *ngIf="submitted && f['image_source_link'].errors?.['required']"
                style="color:red"> (Required)</span></label>
            <input type="text" class="form-control" [required]="true" formControlName="image_source_link"
              id="image_source_link" placeholder="">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="publishdate">Publish Date<span *ngIf="submitted && f['publish_date'].errors?.['required']"
                style="color:red"> (Required)</span></label><input type="date" name="publishdate" value=""
              formControlName="publish_date" id="publishdate" placeholder="DD-MM-YYYY"
              class="form-control timepicker hasDatepicker">



          </div>
          <div class="form-group col-md-6">
            <label for="inputCity">Tags<span *ngIf="submitted && f['tags'].errors?.['required']" style="color:red">
                (Required)</span></label>
            <input type="text" class="form-control" formControlName="tags" id="inputCity">

          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="video_url">Video Url</label>
            <input type="text" class="form-control" formControlName="video_url" id="video_url"
              placeholder="">



          </div>
          <!-- <div class="form-group col-md-6">
            <label for="inputCity">Tags<span *ngIf="submitted && f['tags'].errors?.['required']" style="color:red">
                (Required)</span></label>
            <input type="text" class="form-control" formControlName="tags" id="inputCity">

          </div> -->
        </div>
        <!-- Note Editor -->
        <div class="form-row">
          <div class="form-group col-md-12">

            <label for="note" formControlName="content">Content <span *ngIf="submitted && f['content'].errors?.['required']"
                style="color:red"> (Required)</span></label>
            <div class="NgxEditor__Wrapper" height="100px">
              <ngx-editor-menu [editor]="editor" height="500px"> </ngx-editor-menu>
              <ngx-editor [editor]="editor" height="100px" formControlName="content" [disabled]="false"
                [placeholder]="'Type here...'"></ngx-editor>
            </div>
            <!-- <div formControlName="content" id="summernote"></div> -->

          </div>
        </div>
        <!--End Note Editor [disabled]="!myForm.valid" -->
        <button type="submit" class="btn btn-primary">Publish</button>
      </form>
    </div>
    <div class="col-md-3">
      <article class="component-box-1">
        <div class="user-info-box-main">
          <div class="user-info-box">
            <h4>Your Profile</h4>
            <div class="user-info-box-inner p-0 no-scroll">
              <div class="user-box">
                <div class="ribbon ribbon-top-right"><span>Premium</span></div>
                <div class="img-box"><a href="javascript:void(0);"
                    [routerLink]="['/'+memberapi.userInfoapi?.username]"><img
                      src="../../assets/images/profile-user-round.png" /></a></div>
                <div class="name">{{memberapi.userInfoapi?.first_name}}</div>
                <p class="small">
                  Advocate | Consultant | Counsellor <br>
                  B.A. LLB (Hons.)
                </p>
                <div class="pc-box">New Delhi</div>

                <div class="progress-bar-box">
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div class="pc-box"><a href="#" class="link">Edit/ Modify Profile</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <div class="black-box h-0 slick-initialized slick-slider slick-dotted">
        <div class="slick-list draggable">
          <div class="slick-track" style="opacity: 1; width: 1729px; transform: translate3d(-741px, 0px, 0px);">
            <div class="slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1"
              style="width: 247px;">
              <div><img src="../../assets/images/a3.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
            <div class="slick-slide" data-slick-index="0" aria-hidden="true" role="tabpanel" id="slick-slide20"
              style="width: 247px;" aria-describedby="slick-slide-control20" tabindex="-1">
              <div><img src="../../assets/images/a1.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
            <div class="slick-slide" data-slick-index="1" aria-hidden="true" role="tabpanel" id="slick-slide21"
              style="width: 247px;" aria-describedby="slick-slide-control21" tabindex="-1">
              <div><img src="../../assets/images/a2.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
            <div class="slick-slide slick-current slick-active" data-slick-index="2" aria-hidden="false" role="tabpanel"
              id="slick-slide22" style="width: 247px;" aria-describedby="slick-slide-control22">
              <div><img src="../../assets/images/a3.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
            <div class="slick-slide slick-cloned" data-slick-index="3" aria-hidden="true" tabindex="-1"
              style="width: 247px;">
              <div><img src="../../assets/images/a1.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
            <div class="slick-slide slick-cloned" data-slick-index="4" aria-hidden="true" tabindex="-1"
              style="width: 247px;">
              <div><img src="../../assets/images/a2.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
            <div class="slick-slide slick-cloned" data-slick-index="5" aria-hidden="true" tabindex="-1"
              style="width: 247px;">
              <div><img src="../../assets/images/a3.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
          </div>
        </div>
        <ul class="slick-dots" role="tablist">
          <li class="" role="presentation"><button type="button" role="tab" id="slick-slide-control20"
              aria-controls="slick-slide20" aria-label="1 of 3" tabindex="-1">1</button></li>
          <li role="presentation" class=""><button type="button" role="tab" id="slick-slide-control21"
              aria-controls="slick-slide21" aria-label="2 of 3" tabindex="-1">2</button></li>
          <li role="presentation" class="slick-active"><button type="button" role="tab" id="slick-slide-control22"
              aria-controls="slick-slide22" aria-label="3 of 3" tabindex="0" aria-selected="true">3</button></li>
        </ul>
      </div>



    </div>
  </div>
</div>


<section class="title-bg-1 my-4  ipad-hide phone-hide">
  <div class="container">
    <div class="row">
      <div class="col-md-2 #90278E-bg-new">
        <div class="triangle-bottomleft"></div>
        <div class="icon-box"></div>
      </div>
      <div class="col-md-10 white-bg-new">
        <div class="triangle-topleft"></div>
        <span class="gray-bg">Go Premium</span>
        <h3>Amplify your stature and reputation...Comment,
          Like, Share...ROAR!</h3>
      </div>
    </div>
  </div>
</section>

<div class="h-50"></div>
<section class="container position-relative mnSlider" >

  <div class="news-slider slick-initialized slick-slider slick-dotted" >
    <div class="slick-list draggable">
      <div class="slick-track" style="opacity: 1; width: 4901px; transform: translate3d(-1131px, 0px, 0px);">
        <div class="slick-slide slick-cloned" data-slick-index="-3" aria-hidden="true" tabindex="-1"
          style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <p>hi</p>
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="../../assets/images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> tit</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">}tit</h4>

                        <img src="../../assets/images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In vehicula
                          libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam iaculis, a
                          venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="-1">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
       
       
      </div>
    </div>
    <ul class="slick-dots" role="tablist">
      <li class="slick-active" role="presentation"><button type="button" role="tab" id="slick-slide-control00"
          aria-controls="slick-slide00" aria-label="1 of 2" tabindex="0" aria-selected="true">1</button></li>
      <li role="presentation"><button type="button" role="tab" id="slick-slide-control01" aria-controls="slick-slide01"
          aria-label="2 of 2" tabindex="-1">2</button></li>
      <li role="presentation"><button type="button" role="tab" id="slick-slide-control02" aria-controls="slick-slide02"
          aria-label="3 of 2" tabindex="-1">3</button></li>
      <li role="presentation"><button type="button" role="tab" id="slick-slide-control03" aria-controls="slick-slide03"
          aria-label="4 of 2" tabindex="-1">4</button></li>
      <li role="presentation"><button type="button" role="tab" id="slick-slide-control04" aria-controls="slick-slide04"
          aria-label="5 of 2" tabindex="-1">5</button></li>
    </ul>
  </div>

  <a href="#" class="arrow ml slick-arrow" style=""><img src="../../assets/images/icons/arrow_left.png"></a><a href="#"
    class="arrow mr r-180 slick-arrow" style=""><img src="../../assets/images/icons/arrow_left.png"></a>

</section>