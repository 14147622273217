import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { BarAssociationService } from '../bar-association.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Barassociation } from '../barassociation';
import { EventService } from '../../event/event.service';
import { FormBuilder, FormGroup, FormControl, Validators, } from '@angular/forms';



@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',

})
export class ViewComponent implements OnInit {
   
    constructor(public barAssociationService:BarAssociationService,public eventService:EventService, private route: ActivatedRoute,) { }
   
    barassociation:Barassociation;
    barassociationcount:Barassociation;
    barassociationcommitte_count:Barassociation;
    Limit:Event[]=[];
   
bar_association_id:any;

    ngOnInit(): void {
    
         this.bar_association_id = this.route.snapshot.paramMap.get('barId');
        this.barCommitteMemberCount(this.bar_association_id);
         this.barMemberCount(this.bar_association_id);
         this.view(this.bar_association_id);
        //  this.limit();
       console.log('qqqqqq',this.bar_association_id);
      }
     
      view(barId){
        this.barAssociationService.bar_association_view(barId).subscribe((res: Barassociation) => {
              this.barassociation = res[0];
              console.log('saaaaaaaaaaa',this.barassociation)
            });
      }
     barMemberCount(barId){
      this.barAssociationService.bar_association_member_count(barId).subscribe((res: Barassociation) => {
        this.barassociationcount = res;
        console.log('saaaaaaaaaaa',this.barassociation)
      });
}
barCommitteMemberCount(barId){
  this.barAssociationService.bar_association_committemember_count(barId).subscribe((res: Barassociation) => {
    this.barassociationcommitte_count = res[0];
    console.log('saaaaaaaaaaa',this.barassociation)
  });
}
limit(){
  this.eventService.limit().subscribe((com:Event[]) => {
    this.Limit = com;
   

  });
 }
     
  
    }