<h5 class="h5">CONTACT US</h5>
<div class="col-xs-12">
    <div class="row">
       
        <div class="col-md-12">

            <div class="row box">
        
                <div class="col-sm-5" style="padding-left:35px">
                  
          
                      <i class="fa fa-map-marker" ></i><strong  class="strong">Address:</strong>{{barassociation.address}} 
                      <br>
                    
                        <i class="fa fa-phone"></i> <strong class="strong">Phone:</strong>{{barassociation.contact_no}} <br>
                    
                        <i class="fa fa-envelope"></i> <strong class="strong">Email:</strong>{{barassociation.email}} <br>

                </div>
                <div class="col-sm-7">
                                                                  
                 <!-- <div>{{barassociation.google_map_url}}</div> -->
                 <iframe width="100%" height="300" src="{{barassociation.google_map_url}}"></iframe>
                </div>
                <!-- End Bar Association Info block-->
            </div>

        </div>
    </div>
</div>
