import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { LoginComponent } from './login.component';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public url = environment.baseURL;
  redirectUrl=''

  constructor(public http: HttpClient) { }

  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  postdata(urlT: any, data: any) {
   
    const updateUrl = this.url + urlT;
    
    return this.http.post(updateUrl, data);
  }
  openlogin(){
    this.loginPrev.openLogin();
  }

  getdata(){
    const updateUrl = this.url + 'top_news_list';

    return this.http.get(updateUrl);
  }
}
