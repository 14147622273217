import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { BarAssociationService } from '../bar-association.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Barassociation } from '../barassociation';
import { EventService } from '../../event/event.service';
@Component({
    selector: 'app-calender',
    templateUrl: './calender.component.html',
    styleUrls: ['./calender.component.css']
  
  })
  export class CalenderComponent implements OnInit {
   
    constructor(public barAssociationService:BarAssociationService,public eventService:EventService, private route: ActivatedRoute,) { }
    barassociation:Barassociation;
    limit1:any;


    ngOnInit(): void {
        var bar_association_id = this.route.parent.snapshot.paramMap.get('barId');
        this.getBarCalender(bar_association_id);
        console.log('gggggg',bar_association_id);
        this.limit();
      }
      getBarCalender(barId){
        this.barAssociationService.bar_association_calender(barId).subscribe((res: Barassociation) => {
          this.barassociation = res;
          console.log('saaaaaaaaaaa',this.barassociation)
        });
      }
      getImage(calender) {
        console.log('hiiiiiiiiiiii',calender.url);
        return calender.url;
       
        // return "http://localhost:8080/api/news-image/" + news.url;
      }
      limit(){
        this.eventService.limit().subscribe((com:any) => {
          this.limit1 = com;
     
      
        });
       }
    }