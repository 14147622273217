import { Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
// import { ActivatedRoute } from '@angular/router';
import { SearchService } from './search.service';
import { RoarService } from '../roar/roar.service';
import { Roar } from '../roar/roar';
import {Location} from '@angular/common'; 
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/shared/canonical.service';
import { LoginComponent } from '../login/login.component';
import { LoginService } from '../login/login.service';
import { EventService } from '../event/event.service';
import { ApiService } from '../api.service';
import { LikeService } from '../like/like.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CommentService } from '../comment/comment.service';
import { Input, Output, EventEmitter } from '@angular/core';
import { MemberService } from '../member/member.service';
import { NewsService } from '../news/news.service';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit ,OnChanges {

  @Input()
  postId: number;

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter();

  calendardata: any;
  eventcalendar: any;
  roarlike: any;
  profiledata: any;
  category: any;
  event: any;
  countmember = false;
 
  page: number = 1;
  count: number = 0;
  tableSize: number = 6;
  tableSizes: any = [3, 6, 9, 12];
 
  pagecmt: number = 1;
  countcmt: number = 0;
  tableSizecmt: number = 4;
  tableSizescmt: any = [3, 6, 9, 12];
  cmt:any;


  param: string;
  title='Search | Soolegal'
  query:any;
  url: string;
  searchlist: any;
  judgmentlist: any;
  p: number = 1;
  // page = 1;
  limit = 4;
  total=0;
  // count=0;
  contenttlist: any;
  eventlist: any;
  roarlist: any;
  newslist: any;
  search_list_data:any;
  token: string;
  category_id = 1;
  comments: Comment[];
  closeModal:string;
  closeResult: string;
  r: any; 
  Roar: any = {};
  roar:any;
  news:any;
  reset: any;

  constructor(private route: ActivatedRoute,public searchapi: SearchService,public api: ApiService,public likeService: LikeService,public commentService: CommentService,private modalService: NgbModal,public memberapi: MemberService, public newsService: NewsService,public roarService: RoarService, public eventService:EventService, private location: Location ,private titleService: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService) { 

    var pathArray = window.location.pathname.split('/');
    this.param = pathArray[2]
  
    // if(this.param =='people'){
    //   this.url = 'search/member'
    // }else if(this.param =='judgement'){
    
    //   this.url = 'search/judgment'
    // }else if(this.param =='content'){
    //   this.url = 'search/content'

    // }else if(this.param =='event'){
    //   this.url = 'search/event'

    // }else if(this.param =='roar'){
    //   this.url = 'search/roar'

    // }else if(this.param =='news'){
    //   this.url = 'search/news'

    // }
    // else if(this.param ==undefined){
    //   this.param= 'people';
    //   this.url = 'search/member'
      
    // }

  }
  
  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  @ViewChild('openLoginModal')loginModal: ElementRef; 

  ngOnChanges(): void {
    var search_key_word = this.route.snapshot.paramMap.get('search_key_word');
    this.post_search(search_key_word);
    
  }
  ngOnInit(): void {

    var search_key_word = this.route.snapshot.paramMap.get('search_key_word');
    
    // var type=this.route.snapshot.paramMap.get('Type');
    this.post_search(search_key_word);

    this.route.queryParams
    .subscribe(params => {
     
      if(params.q){
        this.query = params.q;
      }
     else{
      this.query =''
     }

     
    }
  );
  this.getsearchdata();


  this.canonicalService.setCanonicalURL();

  this.titleService.setTitle(this.title);

  let location = window.location.href
  this.metaTagService.addTags([
    {name: 'description', content: 'SoOLEGAL invites Lawyer & Law Firms to Register. Find Best Lawyers Online, Get Free Legal Advice &  Socially Optimized Legal Directory' },
    {name: 'robots', content: 'index, follow'},
    {name: 'revisit-after', content: '1 days'},
    {name: 'copyright', content: 'Copyright - All Rights Reserved 2017 SoOLegal'},
    {name: 'author', content: 'SoOLEGAL'},
    {name: 'zipcode', content: '110074'},
    {name: 'city', content: 'New Delhi'},
    {name: 'state', content: 'Delhi'},
    {name: 'country', content: 'India'},
    {property: 'og:locale', content: 'en_US'},
    {property: 'og:type', content: 'website'},
    {property: 'og:title', content: this.title},
    {property: 'og:url', content:  location},
    {property: 'og:image', content: 'https://beta.soolegal.com/assets/images/logo.png'},
    {property: 'og:description', content: 'SoOLEGAL invites Lawyer & Law Firms to Register. Find Best Lawyers Online, Get Free Legal Advice &  Socially Optimized Legal Directory'},
    {property: 'og:site_name', content: 'SoOLEGAL'},
    {property: 'article:publisher', content: 'https://www.facebook.com/SOciallyOptimizedLEGAL/'},
    {property: 'fb:app_id', content: ''},
    {name: 'DC.title', content: 'SoOLEGAL'},
    {name: 'geo.region', content: 'IN-DL'},
    {name: 'geo.placename', content: 'New Delhi'},
    {name: 'geo.position', content: ''},
    {name: 'ICBM', content: ''}
  ]); 


  

  
  }

  getsearchdata()
  {
  
    let obj ={
      page:this.page,
      search:this.query,
      limit:this.limit,
      member_id: localStorage.getItem('USERID')?this.searchapi.userInfoapi.member_id:''
    }
   
    this.searchapi.post(this.url, obj).subscribe((res: any) => {
      if(this.url=='search/member'){
    
        this.searchlist = res.data
        this.total = res.total_rows
        if(res.data)
       { this.searchlist.forEach((element,index) => {
          // myArray = element.practice_name.split(',');
          if(element.practice_name!==null){
            this.searchlist[index].prectiselist=   element.practice_name.split(',');
          }
          
        });}
      
       
      }else if(this.url=='search/judgment'){
        this.judgmentlist = res.data
        
      }else if(this.url=='search/content'){
        this.contenttlist = res.data
        
      }
      else if(this.url=='search/event'){
        this.eventlist = res.data
        
      }  else if(this.url=='search/roar'){
        this.roarlist = res.data
        
      } else if(this.url=='search/news'){
        this.newslist = res.data
        
      }
  
    
    })
  }

  pageChanged(event){
    this.p = event 
    
  }

  onScrolls(){
    
    this.count = this.total-this.limit
    // this.count = this.total>=this.limit?this.total-this.limit:this.total
   
    if(this.count>=4){
      this.limit = this.limit+4
      
    }else{
      this.limit = this.limit+this.count
     
    }
    if(this.total>=this.limit&&this.count!=0){
      this.getsearchdata()
    }
  
   
   
   
  }
  onScroll(){
  this.limit = this.limit+4
  this.getsearchdata()


}



  selectsearch(data){

if(data =='people'){
  this.url = 'search/member'
}else if(data =='judgement'){
  this.url = 'search/judgment'
}else if(data =='content'){
  this.url = 'search/content'

}else if(data =='event'){
  this.url = 'search/event'

}else if(data =='roar'){
  this.url = 'search/roar'

}
else if(data =='news'){
  this.url = 'search/news'

}
// newslist
if(this.query){
  let url = 'search/'+data+'?q='+this.query
  // window.location.href = 'search/'+data+'?q='+this.query
  // location.go(url)

   this.getsearchdata();
  this.location.go(url)
}else{
  this.query = ''
  let url = 'search/'+data+'?q='+this.query
  
  this.getsearchdata();
  // window.location.href = 'search/'+data+'?q='+this.query
  this.location.go(url)
}


// this.getsearchdata();
  }

post_search(search_key_word)
{
  const formData = new FormData();
  formData.append("search_key_word",search_key_word);
  this.searchapi.post_search(formData).subscribe((res4: any) => 
  { 
        this.search_list_data=res4.messages.feed_list;
        console.log("searchlist", this.search_list_data)
        this.reset();
   });  

}


isReadMore = false;

  showText() {
    this.isReadMore = !this.isReadMore
  }

  getImage(roar) {
    // return "http://localhost:8080/api/roar-image/" + roar.url;
    // return environment.baseURL + "roar-image/" + roar.url;
    return roar.roar_image_url;
  }

  getImagePic(roar) {
    return roar.profile_pic_url;
  }

  click(){
    if(this.token){
      // this.showform = !this.showform;
      // this.router.navigate(['/roar']);
    // this.submit('');
    }else{
      this.loginPrev.openLogin();
    }
  }
  refresh() {
    if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
    localStorage.clear();
    // window.location.reload();
    }
  }
  openLogin() {
    this.loginModal.nativeElement.click()
  }
  loginSubmit(event:any) {
  }



  like(r) {
    if(this.token){
        let roar_id = r.id,
          liked = r.liked,
          author_id = r.author_id;
    
        // this.showicon = !this.showicon;
        // this.show = !this.show;
        console.log('like',r);
        const formData = new FormData();
        formData.append('post_id', roar_id + '');
        formData.append('category_id', this.category_id + '');
        formData.append('member_id', author_id + '');
        if (liked > 0 ) {
          liked = 0;
          r.like_count = r.like_count - 1;
        } else {
         liked = 1;
          r.like_count = r.like_count + 1;
        }
        // UPdating the ui object
        r.liked = liked;
    
        formData.append('status', liked + '');
        console.log('status',liked);
    
        this.likeService.create(formData).subscribe((res: any) => {
          console.log("Roar Liked..:::", res.roar);
        });
    
        console.log(this.category_id);
      }
    
      }
    
      deleteEvent(event_id) {
        this.eventService.delete(event_id).subscribe((res:any) => {
          this.event = res; 
          alert("record deleted..!");    
          window.location.reload();
        });
      }
    
      open(content, r) {
        this.r = r;
        this.getComments(r.id, 1);
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
        
      }



      // popup for delete icon
  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
     
      this.getRoar('');
      // this.router.navigate(['/roar']);
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
     
    });
   
  }
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }
     
    
      ope(conte) {
        this.modalService.open(conte, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismiss(reason)}`;
        });
      }
    
      getComments(post_id, category_id) {
        this.commentService.getAll(post_id, this.category_id).subscribe((com: Comment[]) => {
          this.comments = com;
          console.log("Comments..:::", this.comments);
    
        });
      }



      
      private getDismiss(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }
    
      // socialshare url
      getLocation(news_id) {
        return location.href + '/' + news_id;
        //  return  "https://stackoverflow.com"+'/'+news_id;
      }
      getRoar(limit) {   
        this.roarService.getAll(0).subscribe((res: Roar[]) => {
          this.roar = res;
         
        });
      }
      onCommentCreate(roar_id) {
        this.getComments(roar_id, this.category_id);
      
      
      }

      deleteRoar(roar_id) {
  
        this.roarService.delete(roar_id).subscribe((res:any) => {
        this.roar = res;
        // this.router.navigate(['roar']);
        alert("record deleted..!");    
        window.location.reload();
       
      });
    }


    deleteNews(news_id){
      this.newsService.delete(news_id).subscribe((res:any)=>{
        this.news = res;
        alert("record deleted..!");    
        window.location.reload();
      });
    }

      onTableDataChange(event: any) {
        this.page = event;
        this.getRoar;
      }
      onTableSizeChange(event: any): void {
        this.tableSize = event.target.value;
        this.page = 1;
        this.getRoar;
      }
     
    
      onTableDataChangecmt(event1: any) {
        this.pagecmt = event1;
        this.getComments;
      }
      onTableSizeChangecmt(event1: any): void {
        this.tableSizecmt = event1.target.value;
        this.pagecmt = 1;
        this.getComments;
      }

} 