<div class="container">
    <div class="col-lg-6 col-md-6 col-sm-12" style="min-width:450px">
        <!-- Write Post -->
      
                    
                    
                        <!-- <input class="form-control"  placeholder="Share what you are thinking here..." -->
                        <!-- style="margin-bottom:15px"> -->
                        <div class="panel-body">
                            <form [formGroup]="myForm" (ngSubmit)="submit(s)" class="model">
                                <input class="form-control" formControlName="title" placeholder="Title"
                                    style="margin-bottom:15px"> &ensp;


                                <textarea class="form-control" rows="2" formControlName="content"
                                    placeholder="What are you thinking?" style="margin-bottom:5px"></textarea>
                                <!-- <label for="image">Select images</label> -->
                                <div class="form-group"><input type="file" name="imageURL" formControlName="url"
                                        id="url" (change)="onFileChange($event)" class="filestyle" value=""
                                        data-icon="false" tabindex="-1"
                                        style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
                                        <input class="form-control" formControlName="title" placeholder="Tags"
                                        style="margin-bottom:15px"> 
                                    <div class="bootstrap-filestyle input-group"><span><a
                                                class="btn btn-trans btn-icon fa fa-image add-tooltip" 
                                                style="background-color:rgba(0, 0, 0, 0.116);font-size: xx-large;"></a></span><input
                                            type="text" class="form-control no-padd " placeholder="" disabled=""
                                            [value]="roarImage.name?roarImage.name:(existingNews?existingNews.url:'')">
                                        <span class="group-span-filestyle input-group-btn" tabindex="0"><label for="url"
                                                class="btn btn-default "><span class="buttonText">Choose</span></label>
                                        </span>
                                    </div>
                                </div>
                                <div class="mar-top clearfix">
                                    <button class="btn btn-sm btn-primary pull-right" type="submit"><i
                                            class="fa fa-pencil fa-fw"></i>
                                        Share</button>
                                    <!-- <a class="btn btn-trans btn-icon fa fa-image add-tooltip" href="#"></a>
                                    <a class="btn btn-trans btn-icon fa fa-video add-tooltip" href="#"></a> -->
                                    <!-- <a class="btn btn-trans btn-icon fa fa-file add-tooltip" href="#"></a> -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
           
            
                