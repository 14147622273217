<div class="modal fade" id="signup-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-top">
        <div class="modal-content PopUpBox">



            <div class="forgetPassword">
                <!-- ./assets/images/close.svg -->
                <a href="javascript:void(0);" class="popup-times" data-dismiss="modal"><img src="../../assets/images/close.svg" #closeRegisterModal></a>

                <div class="flash-content text-center">


                    <div class="text">


                        <div class="text"> <label>
            <p class="title d-flex align-items-center justify-content-center">   Sign Up </p>
          </label>
                            <p>
                                Manage your meetings &amp; appointments easily
                            </p>

                            <p>
                                build a following and enhance your online reputation
                            </p>
                        </div>






                        <form class="mt-3">

                            <!-- <div class="alert alert-danger" role="alert" *ngIf="loginStatus==false">
              {{message}}
              </div> -->
                            <div class="alert alert-success" *ngIf="message" role="alert">
                                {{message}}
                            </div>

                            <div class="form-group m-0 mb-3">
                                <!-- <input type="text" class="form-control" name="role"[(ngModel)]="userInfo.role"  placeholder="User Name" /> -->

                                <select title="Select Member Role" class="form-control" name="role" [(ngModel)]="userInfo.role"  placeholder="Role">
                                 
                          <option value={{data.member_type_id}} *ngFor="let data of roles">{{data.name}}</option>
               
              </select>

                            </div>

                            <div class="form-group m-0 mb-3">
                                <input type="text" class="form-control" name="name" [(ngModel)]="userInfo.name" placeholder="Name" />
                            </div>

                            <div class="form-group m-0 mb-3">
                                <input type="text" class="form-control" name="email" [(ngModel)]="userInfo.email" placeholder="Email" />
                            </div>

                            <div class="form-group m-0 mb-3">
                                <input type="text" class="form-control" name="contact_num" [(ngModel)]="userInfo.contact_num" placeholder="Contact Number" />
                            </div>

                            <div class="form-group m-0 mb-3">
                                <input type="password" class="form-control" name="password" [(ngModel)]="userInfo.password" placeholder=" Password" />
                            </div>


                            <p class="title d-flex align-items-center justify-content-center mt-2 mb-2 robot-text">
                                <!-- <span> I'm not a robot </span> -->
                                <!-- <label class="mb-0">
                <input type="radio" name="case" class="radio" />
                <span class="radio-style check-radio-style"></span>
              </label>  
                           -->
                                <!-- (resolved)="resolved($event)" -->
                                <!-- <re-captcha
              
              siteKey="this.api.siteKey"
            ></re-captcha> -->
            <re-captcha
    (resolved)="resolved($event)"
    siteKey="6Ld18BYeAAAAAK2VhQTGC2WOgpfZzGiR-aXNwiJy"
  ></re-captcha>
                            </p>


                            <div class="d-flex justify-space-between font-12 expand mt-4 ">


                                <!-- <label>
                <p class="title d-flex align-items-center justify-content-center">  <label class="mb-0">
                  <input type="radio" name="case" class="radio">
                  <span class="radio-style check-radio-style"></span>
                </label> I am a Legal Professional</p>
                                </label> -->


                                <!-- 
                                <label>
                <p class="title d-flex align-items-center justify-content-center">  <label class="mb-0">
                  <input type="radio" name="case" class="radio">
                  <span class="radio-style check-radio-style"></span>
                </label> I want Legal Assistance</p>
                                </label> -->



                            </div>

                            <div class="text-center btn-wrapper mt-2">
                                <button type="submit" class="btn btn-primary link-bg " (click)="postRegister()">
                                    <span *ngIf="this.loading === true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    &nbsp;
                                    <!-- <span *ngIf="this.loading === true">Sign In...</span> -->
                                    <span *ngIf="this.loading !== true">Submit</span>
                                    </button>
                            </div>

                        </form>


                    </div>
                    <p class="text-color">or sign up with</p>
                    <div class="social-icons">
                        <a href="#"><i class="fab fa-google-plus"></i></a>
                        <a href="#"><i class="fab fa-facebook"></i></a>

                        <a href="#"><i class="fab fa-twitter"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<span #openRegisterModal data-toggle="modal" data-backdrop="static" data-target="#signup-modal" style="display: none;"></span>