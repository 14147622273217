

 <div class="modal fade" id="otp-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-top">
      <div class="modal-content PopUpBox">
     
      
  
      <div class="forgetPassword">
          <!-- ./assets/images/close.svg -->
        <a href="javascript:void(0);" class="popup-times" data-dismiss="modal"><img src="../../assets/images/close.svg"#closeotpModal></a>
      
        <div class="flash-content text-center">
        
      
          <div class="text"> 
  
  
            <div class="text"> <label>
              <p class="title d-flex align-items-center justify-content-center">   For Lawyers Page </p>
            </label>
              <p>
                Manage your meetings &amp; appointments easily
              </p>
        
              <p>
                build a following and enhance your online reputation
              </p>
            </div>
  
  
  
            <div class="text">
              <label>
              <p class="title d-flex align-items-center justify-content-center">
               
             
                For Litigants</p>
        </label>
              <p>
                Easily find the best legal experts near you
              </p>
        
              <p>
                Get thousands of legal resources for self help &amp; DIY
              </p>
            </div>
         
            <div class="alert alert-success" *ngIf="message"  role="alert">
              {{message}}
           </div>
            <form class="mt-3">
  
              <!-- <div class="form-group m-0 mb-3">
                <input type="text" class="form-control" name="role"[(ngModel)]="userInfo.role"  placeholder="User Name" />
               
              </div> -->
<!--   
              <div class="form-group m-0 mb-3">
                <input type="text" class="form-control"name="name"[(ngModel)]="userInfo.name"  placeholder="Password" />
              </div> -->
              <!-- [(ngModel)]="otp.OTP"  -->
              <div class="form-group m-0 mb-3">
                <input type="password" class="form-control"[(ngModel)]="otpmatch.otp" name="otp" placeholder="OTP" />
              </div>
  
  
              <!-- <p class="title d-flex align-items-center justify-content-center mt-2 mb-2 robot-text">
               <span> I'm not a robot </span>
                
              </p> -->
  
  
              <div class="d-flex justify-space-between font-12 expand mt-4 ">
  
  
                <!-- <label>
                  <p class="title d-flex align-items-center justify-content-center">  <label class="mb-0">
                    <input type="radio" name="case" class="radio">
                    <span class="radio-style check-radio-style"></span>
                  </label>  I am a Legal Professional</p>
                </label>
  
  
                
                <label>
                  <p class="title d-flex align-items-center justify-content-center">  <label class="mb-0">
                    <input type="radio" name="case" class="radio">
                    <span class="radio-style check-radio-style"></span>
                  </label> I want Legal Assistance</p>
                </label> -->
  
  
  
              </div>
            <!--  (click)="postRegister()" -->
              <div class="text-center btn-wrapper mt-2">
                <button type="submit" class="btn btn-primary link-bg "(click)="verifyotp()">Submit</button>               
              </div>
  
            </form>
  
  
          </div>
         <p class="text-color">or sign up with</p>
          <div class="social-icons">
            <a href="#"><i class="fab fa-google-plus"></i></a>
            <a href="#"><i class="fab fa-facebook"></i></a>
      
            <a href="#"><i class="fab fa-twitter"></i></a>
          </div>
        </div>
        </div>
        </div>
      </div>
    </div>
      <span #openOtpModal data-toggle="modal" data-backdrop="static" data-target="#otp-modal" style="display: none;"></span>
  
<app-register></app-register>
<app-login></app-login>
