export class Register {
  role: any;
  name: any;
  email: any;
  contact_num: any;
  password: any;
  middle_name:any;
  Payment_terms_conditions: any;
  admitted_to_practice: any;
  contact_num_visibility: any;
  country_code: any;
  created_date: any;
  custom_url: any;
  dob: any;
  election_service_flag: any;
  email_verification: any;
  email_status: any;
  first_name: any;
  flag: any;
  gender: any;
  gst_no: any;
  international_status: any;
  is_login: any;
  last_name: any;
  member_id: any;
  member_invoice_no: any;
  member_type: any;
  modified_date: any;
  new_password: any;
  other_contact_no: any;
  pre_register_status: any;
  profile_update: any;
  public: any;
  receive_online_payment: any;
  status: any;
  student_internship_status: any;
  student_job_status: any;
  total_reward: any;
  unique_id: any;
  username: any;
  valid_email: any;
  verification_code: any;
    
    constructor() {}
  }