<main role="main">
    <section class="filter">
        <div class="container">
            <div class="h-20"></div>

            <div class="row">
                <div class="col-md-12">
                    <div class="bg-white shadow rounded overflow-hidden">
                        <div class="px-4 pt-0 pb-4 bg-dark">
                            <div style="transform: translateY(5rem);" class="media align-items-end profile-header">
                                <div class="profile mr-3"><img src="images/delhi-BA.jfif" alt="..."
                                        class="rounded mb-2 img-thumbnail"><a href="#"
                                        class="btn btn-dark btn-sm btn-block">Edit profile</a></div>
                                <div class="media-body mb-5 text-white">
                                    <h4 class="mt-0 mb-0">
                                        {{barassociation.bar_association_name}}</h4>
                                    <p class="small mb-4"> <i class="fa fa-map-marker mr-2"></i>{{barassociation.address}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="bg-light p-4 d-flex justify-content-end text-center">
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item">
                                    <h5 class="font-weight-bold mb-0 d-block">{{barassociationcommitte_count}}</h5><small class="text-muted"> <i
                                            class="fa fa-picture-o mr-1"></i>Committee Members</small>
                                </li>
                                <li class="list-inline-item">
                                    <h5 class="font-weight-bold mb-0 d-block">{{barassociationcount}}</h5><small class="text-muted"> <i
                                            class="fa fa-user-circle-o mr-1"></i>Total Members</small>
                                </li>
                            </ul>
                        </div>
                        <div class="">
                            <!-- Tabs navs -->
                            <ul class="nav nav-tabs nav-fill mb-3" id="ex1" role="tablist">
                                <li  class="nav-item" role="presentation">
                                    <a routerLink="aboutus" class="nav-link " routerLinkActive="active" id="ex2-tab-1" data-mdb-toggle="tab" 
                                        role="tab" aria-controls="ex2-tabs-1" aria-selected="true">About Us</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a routerLink="committemembers" class="nav-link" id="ex2-tab-2" data-mdb-toggle="tab" routerLinkActive="active"
                                        role="tab" aria-controls="ex2-tabs-2" aria-selected="false">Committee
                                        Members</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a routerLink="member" class="nav-link" routerLinkActive="active" id="ex2-tab-3" data-mdb-toggle="tab" 
                                        role="tab" aria-controls="ex2-tabs-3" aria-selected="false">Members</a>
                                </li>
                              
                                <li class="nav-item" role="presentation">
                                    <a routerLink="calender" class="nav-link" routerLinkActive="active" id="ex2-tab-3" data-mdb-toggle="tab" 
                                        role="tab" aria-controls="ex2-tabs-3" aria-selected="false">calendar</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a routerLink="gallery" class="nav-link" id="ex2-tab-3" data-mdb-toggle="tab" routerLinkActive="active"
                                        role="tab" aria-controls="ex2-tabs-3" aria-selected="false">Gallery</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a routerLink="helpline" class="nav-link" id="ex2-tab-3" data-mdb-toggle="tab" routerLinkActive="active"
                                        role="tab" aria-controls="ex2-tabs-3" aria-selected="false">Helpline</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a routerLink="contactus" class="nav-link" id="ex2-tab-3" data-mdb-toggle="tab" routerLinkActive="active"
                                        role="tab" aria-controls="ex2-tabs-3" aria-selected="false">Contact Us</a>
                                </li>
                            </ul>
                            <!-- Tabs navs -->

                            <!-- Tabs content -->
                            <router-outlet></router-outlet>
                         <!-- <app-aboutus></app-aboutus> -->
                            <!-- Tabs content -->
                        </div>
                    </div>

                </div>
            </div>
</div>
    </section>
    <div class="h-25 d-inline-block"></div>
    <section class="title-bg-2 my-4 ipad-hide phone-hide">
        <div class="container">
            <div class="row">
                <div class="col-md-2 orange-bg-new order-md-12">
                    <div class="triangle-bottomleft"></div>
                    <div class="icon-box"></div>
                </div>
                <div class="col-md-10 white-bg-new">
                    <div class="triangle-topleft"></div>
                    <span class="gray-bg">Go Premium</span>
                    <h3>SoOLEGAL is recommended by our valued members and users</h3>
                </div>
            </div>
        </div>
    </section>

    <section class="profile-slider">
        <div class="container">
            <div class="events owl-carousel owl-theme" >
                <div *ngFor="let l of Limit">
                    <div class="component-box-1" >
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>{{l.title}}</h4>
                                <div class="user-info-box-inner p-0 no-scroll min-height">
                                    <div class="user-info-box">
                                        <div class="bg-white">
                                            <img src="images/pasted-image.jpg" class="img-fluid" />
                                        </div>
                                        <div class="box-wrapper">
                                            <p>
                                               {{l.content}}
                                            </p>
                                            <a [attr.href]="'/event/' + l.id +'/add'" class="btn btn-primary">Register Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="partners-main-box my-3">
        <div class="container-fluid p-0">
            <div class="bg-gray-new shadow-none">
                <div class="row justify-content-between align-items-center">
                    <div class="col-md-12 col-lg-5 my-2 logo-box">
                        <img src="images/logo.png" alt="" class="im" />
                        <h4 class="title-1">Partner Services</h4>
                    </div>
                    <div class="col-md-12 col-lg-7 my-2">
                        <div class="partners owl-carousel owl-theme owl-loaded owl-drag">
                            <div class="owl-stage-outer">
                                <div class="owl-stage" style="
                        transform: translate3d(-537px, 0px, 0px);
                        transition: all 0.25s ease 0s;
                        width: 1255px;
                      ">
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="owl-item active" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item active" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px">
                                        <div class="item">
                                            <a href="#"><img src="images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-nav disabled">
                                <button type="button" role="presentation" class="owl-prev">
                                    <span aria-label="Previous">‹</span></button><button type="button"
                                    role="presentation" class="owl-next">
                                    <span aria-label="Next">›</span>
                                </button>
                            </div>
                            <div class="owl-dots disabled"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<!-- PopUp Box for event details -->
<div class="modal fade" id="findLawer" tabindex="-1" aria-labelledby="findLawer" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>

            <div class="modal-body popup-body">
                <div class="select-options">
                    <div class="row">
                        <!-- <div class="col-md-3">
                <div class="pretty-setting mr-auto ml-auto bg-default">
                  <select id="option-size" class="custom-control">
                    <option value="Option">All</option>
                    <option value="Option" selected="">Option</option>
                    <option value="Option">Option</option>
                    <option value="Option">Option</option>
                  </select>
                  <span class="text"><span class="label"></span><span class="value">Select Start Date

                    </span></span>
                  <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
                    <path d="M0-.75h24v24H0z" fill="none"></path>
                  </svg>
                </div>
              </div>



              <div class="col-md-3">
                <div class="pretty-setting mr-auto ml-auto bg-default">
                  <select id="option-size" class="custom-control">
                    <option value="Option">All</option>
                    <option value="Option" selected="">Option</option>
                    <option value="Option">Option</option>
                    <option value="Option">Option</option>
                  </select>
                  <span class="text"><span class="label"></span><span class="value">Select End Date

                    </span></span>
                  <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
                    <path d="M0-.75h24v24H0z" fill="none"></path>
                  </svg>
                </div>

              </div>


              <div class="col-md-3 offset-md-3">
                <div class="pretty-setting mr-auto ml-auto bg-default">
                  <select id="option-size" class="custom-control">
                    <option value="Option">All</option>
                    <option value="Option" selected="">Option</option>
                    <option value="Option">Option</option>
                    <option value="Option">Option</option>
                  </select>
                  <span class="text"><span class="label"></span><span class="value">Select Event Thumbnail

                    </span></span>
                  <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
                    <path d="M0-.75h24v24H0z" fill="none"></path>
                  </svg>
                </div>
              </div> -->
                    </div>
                </div>
                <!-- <form class="mt-28">
            <div class="form-group">
              <textarea class="form-control rounded-0 bg-white" placeholder="Event Description"></textarea>
            </div>

            <div class="form-group">
              <input type="text" class="form-control rounded-0 bg-white" id="exampleInputEmail1"
                aria-describedby="phoneHelp" placeholder="Insert Home Page Link" />
            </div>
          </form> -->
                <form>
                    <h3>Add Event</h3>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="title">Title</label>
                            <input type="text" class="form-control bg-white" id="inputEmail4" placeholder="" />
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <label for="image">Select images</label>
                            <div class="form-group">
                                <input type="file" name="imageURL" id="url" class="filestyle" value="" data-icon="false"
                                    tabindex="-1" style="position: absolute; clip: rect(0px, 0px, 0px, 0px)" />
                                <div class="bootstrap-filestyle input-group">
                                    <input type="text" class="form-control bg-white" placeholder="" disabled="" />
                                    <span class="group-span-filestyle input-group-btn" tabindex="0"><label for="url"
                                            class="btn btn-default"><span
                                                class="buttonText">Choose</span></label></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputCity">Event Date</label>
                            <input type="text" class="form-control bg-white" name="daterange"
                                value="01/01/2018 - 01/15/2018" />
                        </div>
                        <div class="form-group col-md-6">
                            <label for="VenueDetails">Venue Details</label>
                            <input type="text" class="form-control bg-white" id="inputEmail4" placeholder="" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="tags">Tags</label>
                            <input type="text" class="form-control bg-white" id="inputtags" placeholder="" />
                        </div>
                        <div class="form-group col-md-6">
                            <label for="eventCategory">Event Category</label>
                            <input type="text" class="form-control bg-white" id="inputcategory" placeholder="" />
                        </div>
                    </div>
                    <!-- Note Editor -->
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="note">Content</label>
                            <textarea name="description" id="your_summernote" class="form-control bg-white"
                                rows="4"></textarea>
                        </div>
                    </div>
                    <!--End Note Editor -->
                    <div class="row">
                        <div class="col-md-4">
                            <label>
                                <input type="radio" name="event-registration" class="radio" />
                                <span class="radio-style d-inline-block"></span>
                                <span>Free Event Registration </span>
                            </label>
                        </div>

                        <div class="col-md-4">
                            <label>
                                <input type="radio" name="event-registration" class="radio" />
                                <span class="radio-style d-inline-block"></span>
                                <span>Paid Event Registration </span>
                            </label>
                        </div>

                        <div class="col-md-4">
                            <input type="text" class="form-control m-0 rounded-0 bg-white" id="exampleInputEmail1"
                                aria-describedby="phoneHelp" placeholder="Event Registration Amount" />
                        </div>
                    </div>
                </form>

                <div class="mt-4">
                    <button type="submit" class="d-block btn btn-primary mb-0 mx-auto">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>