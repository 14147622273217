import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { BarAssociationService } from '../bar-association.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Barassociation } from '../barassociation';
import { EventService } from '../../event/event.service';
@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.css']
  
  })
  export class GalleryComponent implements OnInit {
   
    constructor(public barAssociationService:BarAssociationService,public eventService:EventService, private route: ActivatedRoute,) { }
    barassociation:Barassociation[]=[];
    limit1:any;


    ngOnInit(): void {
        var bar_association_id = this.route.parent.snapshot.paramMap.get('barId');
        this.getBarGallery(bar_association_id);
        this.limit();
      }
      getBarGallery(barId){
       
       
        this.barAssociationService.bar_association_gallery(barId).subscribe((res: Barassociation[]) => {
          this.barassociation = res;
          console.log('123456',this.barassociation)
        });
      }
      getImage(gallery) {
        console.log('hiiiiiiiiiiii',gallery.image_url);
        return gallery.image_url;
       
        // return "http://localhost:8080/api/news-image/" + news.url;
      }
      limit(){
        this.eventService.limit().subscribe((com:any) => {
          this.limit1 = com;
     
      
        });
       }
    }