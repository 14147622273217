<main role="main">
<div class="container-fluid zero">
    <section class="nal">
        <h4 style="margin: 0 5em;" class=" text-center white-text p-5">Soolegal has 70,000+ lawyers in more than 200
            cities and towns and almost all
            countries
            of the world, with more than 70 specialisations. Find the advocate with the right experience to match
            your legal requirement and your budget.
        </h4>
        <div class="needalawyer">
            <span class="blockquote text-center">
                    <p style="color: white; font-weight: 600;" class="mb-0">Connect me with the <span>Best
                            Lawyers</span> </p>
                    <span class="blockquote-footer" style="color: #fff;">Quick, Free and Easy</span>
            </span>
        <form [formGroup]="myForm3" (ngSubmit)="find_lawyer_form_submit()" >    
            <div style="margin: 1em 15em 0em 15em;" class="form-group purple-border">
          <textarea placeholder="Type Your Query..." class="form-control" data-toggle="modal" data-target="#modalLoginForm"
                    rows="5" id='textarea2' (click)="triggerModal(modalData)"></textarea> 
          <textarea placeholder="Type Your Query..." class="form-control" rows="5" id='textarea1'  style="display:none;" formControlName="query"></textarea> 
                <input id="query-board" type="checkbox" aria-label="Checkbox for following text input" formControlName="check_box" >
                <label class="white-text" for="query-board" > Post on Query Board</label><br>
            </div>

            <div class="text-center pb-5">             
                <h2 style="color:#fff">Area of Grievance</h2>
        <div class="container">
            <select id="option-size" formControlName="areaofgrievance" class="form-control custom-control" style="width: 48%;margin-left: 26%;">
                <option *ngFor = "let arealist of area" value="{{arealist.id}},{{arealist.name}}"> {{arealist.name}} </option>
            </select>
        </div>
                <a href="javascript:void(0);" style="margin-right: 5px;"
                class="btn btn-purple m-4" type="submit"  data-toggle="modal" data-target="#lawyer_opinion" (click)="trigger_find_a_lawyer_Modal(modalData3)" >Find a Lawyer</a>
                <a href="#" class="btn btn-light">Give me Information</a>
            </div>

</form>
        </div>
 
    </section>
<br><br><br><br>

<!-- <section class="profile-slider">
    <div class="container">
    <h3>Top Lawyer</h3>       
        <div class="events owl-carousel owl-theme" >

            <div>
                <div class="component-box-1" style="width: 100%; display: inline-block;" *ngFor = "let lawyer_data of lawyer_list" >
                    <div class="user-info-box-main">
                        <div class="user-info-box">
                            <h4>{{lawyer_data.name}}</h4>
                            <div class="user-info-box-inner p-0 no-scroll min-height">
                                <div class="user-info-box">
                                    <div class="bg-white">
                                        <img src='{{lawyer_data.profile_picture}}' class="img-fluid">
                                    </div>
                                    <div class="box-wrapper">
                                        <p>
                                          Role:{{lawyer_data.role}}
                                          Education:{{lawyer_data.education}}
                                          Practice_area:{{lawyer_data.practice_area}}

                                        </p>
                                        <a class="btn btn-primary" tabindex="0">Schedule Appointment</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    </div>
</section> -->
<section  class="profile-slider">
    <div class="container">
        <!-- <div class="row">
            <div class="col-12">
                <div class="section-title">
                  

                </div>
            </div>
        </div> -->

      
                 
        <h2 class="wow fadeInUp" data-wow-delay=".4s" style="margin-top:-68px;text-align: center;">Top Lawyer List</h2>
<ngx-slick-carousel class="carousel user-info-box-inner no-scroll overflow-hidden" #slickModal="slick-carousel" [config]="slideConfig">
     <div ngxSlickItem *ngFor="let lawyer_data of lawyer_list" class="slide text-left">

            <div class="row">                    
                 <div class="col-lg-12">
                        <div class="lawyer-tile">
                            <div class="basic-info">
                                <h4 class="lawyer-name" style="text-align:center!important;">{{lawyer_data.name}}</h4>


                            <div class="author">
                                <div class="author-image lawyer">
                                    <a href="javascript:void(0)" ><img  *ngIf="lawyer_data.profile_picture" src='{{lawyer_data.profile_picture}}' alt="#"></a>
                                    <a href="javascript:void(0)"><img *ngIf="!lawyer_data.profile_picture" src="assets/images/items-grid/author-1.jpg" >
                                    </a>
                                </div>
                            </div>
                            </div>
                            

                            <div class="lawyer-info">
                                <div style="padding: 10px; color: black; padding-left: 37%;" class="lcn-in">
                                    <h5 class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp; {{lawyer_data.role}}</span></h5>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <h6 class="review-count">Roar Ratings</h6>
                                    <div class="stars">
                                        <span class="fa fa-star checked"></span>
                                        <span class="fa fa-star checked"></span>
                                        <span class="fa fa-star checked"></span>
                                        <span class="fa fa-star"></span>
                                        <span class="fa fa-star"></span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <h6 class="review-count">  Education: {{lawyer_data.education}}
                                       </h6>
                                     
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <h6 class="review-count"> Practice_area: <br>{{lawyer_data.practice_area}}</h6>
                                </div>
                                <a class="btn btn-primary" tabindex="0" style="margin-left: 20%;">Schedule Appointment</a>
                            </div>
                        </div>
                        <!-- Start Single List -->
                    </div>



                </div>
            </div>
            <!-- </div>
                </div> -->
            <!-- </div> -->

        </ngx-slick-carousel>


    </div>
</section>

    <div class="overlay"></div>
    <section class="container query-board pt-5">
        <div class="title-qb pb-4">
            <h3>Query Board</h3>         
        </div>
        <div class="row" >
            <div class="col-md-10" >
        <div id="sidebar" class="well sidebar-nav listWrapper">
                <div class="query-box p-4 mb-2"  *ngFor = "let query of queryBoardList"  >
                    <div class="query-title  py-2">                    
                         <h5><a [attr.href]="'/need-a-lawyer/' + query.topic_id">{{query.description | slice:0:100}}</a></h5>
                        <p>Posted On:{{query.posted_date}}</p>
                        <a href="#" class="badge badge-purple">{{query.topic}}</a>
                    </div>
                    <div class="query-body">
                        <p>{{query.description}}</p>
                    </div>
                    <div class="btn-grp">
                        <div class="grp-lft">
                        
                            <button class="btn btn-purple" [attr.disabled]="query.csreply_id? '' : null" (click)="openreplyposter(query.topic_id)">Reply to poster</button>
                            <button type="button" class="btn btn-purple"> Respond <span class="badge badge-primary">{{query.respond_count}}</span><img height="22px"
                                    src="https://img.icons8.com/windows/32/000000/reply-arrow.png" /> </button>
                        </div>
                        <div class="grp-rgt">
                            <img height="22px"
                                src="https://img.icons8.com/fluency-systems-regular/48/000000/visible.png" /><span class="badge badge-primary">{{query.view_count}}</span>
                            <img height="22px" src="https://img.icons8.com/windows/32/000000/reply-arrow.png" />
                            <img height="22px"
                                src="https://img.icons8.com/fluency-systems-regular/48/000000/share.png" />
                        </div>
                    </div>
                </div>
            </div> 
            </div>
            <div class="col-sm-2">
                <!-- It can be fixed with bootstrap affix http://getbootstrap.com/javascript/#affix-->
                <div id="sidebar" class="well sidebar-nav listWrapper">
                    <h5><i class="glyphicon glyphicon-home"></i>
                        <small><b>PRACTICE AREAS</b></small>
                    </h5>
                    <ul class="area-of-greivance" *ngFor = "let arealist of area">
                        <li><a href="{{arealist.id}}">{{arealist.name}}</a></li>
                    
                    </ul>
                </div>
            </div>
        </div>
    </section>
</div>    

</main>

<!-- Reply -->
<div class="modal fade" id="replyposter" tabindex="-1" aria-labelledby="replyposter" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0">

            <button type="button" class="close close-model" data-dismiss="modal" aria-label="Close" (click)="posterReset()" #closeReplyposter style="
            margin-left: 468px;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header panel-heading">
                <h4 class="modal-title">Reply to this Case</h4>                     
            </div>
            <div class="modal-body popup-body">
                <form class="mt-28" [formGroup]="ReplyPoster" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label style="margin-left: 20px;"><input type="checkbox" class="form-check-input" value="1" name="quote_fee" id="quote-fee" (change)="quoteFee($event)" formControlName="case_quote_type" > Would like to quote the fee </label>
                    </div>
                    <div class="form-group render-case-quote-fee" *ngIf="liketoquotefee">
                        <label><input type="radio" checked="checked" name="case_quote_type" formControlName="case_quote_type" id="case_quote_type_whole" value="1"> For the whole case </label>
                        <label>&nbsp;<input type="radio" name="case_quote_type" formControlName="case_quote_type" id="case_quote_type_retainership" value="2"> Retainership</label>
                        <label>&nbsp;<input type="radio" name="case_quote_type" formControlName="case_quote_type" id="case_quote_type_per_hearing" value="3"> Per hearing </label>
                        <div class="form-group">
                            <input name="purpose_quote_fee" formControlName="case_quote_fee" class="bg-white  form-control rounded-0" 
                            [class.is-valid]="posterForm?.case_quote_fee.valid && posterForm?.case_quote_fee.touched"
                            [class.is-invalid]="posterForm?.case_quote_fee.invalid && posterForm?.case_quote_fee.touched" id="purpose-quote-fee" type="text" placeholder="Fee">
                            
                            <div *ngIf="posterForm.case_quote_fee.errors" class="invalid-feedback">
                                <div *ngIf="posterForm.case_quote_fee.errors.required">
                                    Please enter Fee
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="error">Please enter some query</div>

                    <div class="form-content">
                        <div class="alert alert-success" *ngIf="message" role="alert">
                            {{message}}
                        </div>

                            <div class="form-group">

                                <input type="name" class="form-control rounded-0" name="name" [value]="username" disabled="disabled" aria-describedby="emailHelp" placeholder="name">

                            </div>
                            <div class="form-group">

                                <input type="email" class="form-control rounded-0" name="email" [value]="email" disabled="disabled" aria-describedby="emailHelp" placeholder="Email">

                            </div>

                            <div class="form-group">

                                <input type="tel" class="form-control rounded-0" name="mobile" [value]="contact_no" disabled="disabled" aria-describedby="phoneHelp" placeholder="Phone">

                            </div>


                            <label for="note" > </label>
                            <div class="NgxEditor__Wrapper" height="100px" >
                                <ngx-editor-menu [editor]="editor" height="500px" > </ngx-editor-menu>
                                <ngx-editor
                                
                                [editor]="editor"
                                height="100px"
                                formControlName="answer"
                                [disabled]="false"
                                [placeholder]="'Type here...'"
                                [class.is-valid]="posterForm?.answer.valid && posterForm?.answer.touched"
                                [class.is-invalid]="posterForm?.answer.invalid && posterForm?.answer.touched"
                                ></ngx-editor>
                                <div *ngIf="posterForm.answer.errors" class="invalid-feedback">
                                    <div *ngIf="posterForm.answer.errors.required">
                                        Please enter description
                                    </div>
                                </div>
                            </div>  
                            <div class="text-right">
                                <button type="submit" class="d-inline btn btn-primary mb-0 mx-auto">Submit</button>
                                <button type="button" class=" btn btn-primary mb-0 ml-2" data-dismiss="modal" aria-label="Close" (click)="posterReset()" #closeReplyposter>Close</button>
                            </div>

                    </div>
                </form>

            </div>




        </div>
    </div>
</div> 

<span #openReplyposterModal data-toggle="modal" data-backdrop="static" data-target="#replyposter" style="display: none;"></span>
<app-login (loginEvent)="loginSubmit($event)"></app-login>


 <!-- new first modal start -->
 <ng-template #modalData let-modal >
    <!-- <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Enter Your Details Below</h4>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div> -->
    <div class="modal-body">   
        <div class="modal-body popup-body">
            <h2>Enter Your Details Below</h2>
            <form [formGroup]="myForm1" (ngSubmit)="query_form_submit(modalData1)">
                <div class="form-row">         
                <div class="form-group col-md-6">
                    <label for="title">Full Name<span *ngIf="submitted && f['name'].errors?.['required']" style="color:red">  (Required)</span></label>
                    <input type="text" class="form-control" id="inputname" placeholder="" formControlName="name">
                </div>
                <div class="form-group col-md-6">
                    <label for="title">Email<span *ngIf="submitted && f['email'].errors?.['required']" style="color:red">  (Required)</span></label>
                    <input type="text" class="form-control" id="inputemail" placeholder="" formControlName="email">
                </div>        
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="title">Mobile Number<span *ngIf="submitted && f['contact_no'].errors?.['required']" style="color:red">  (Required)</span> </label>
                        <input type="text" class="form-control" id="contactnumber" placeholder="" formControlName="contact_no">
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <label for="title">City <span *ngIf="submitted && f['city'].errors?.['required']" style="color:red">  (Required)</span></label>
                        <input type="text" class="form-control" id="inputemail" placeholder="" formControlName="city">
                    </div>
                </div> 
               
                <div>  
                    <button type="button" class="btn btn-danger"
           
                    (click)="modal.close('Save click')">Close</button>
                <button type="submit" class="btn btn-success" style="float:right;">Submit</button>
              </div>     
            </form>
        </div> 
    </div>
 
</ng-template>
    <!-- new first modal end -->


      <!-- new second modal start -->
  <ng-template #modalData1 let-modal >
 
    <div class="modal-body">    

        <div class="modal-body popup-body">
            <h6>OTP has been sent your email id</h6>
            <form [formGroup]="myForm2" (ngSubmit)="match_otp_form_submit()">
                <div class="form-row">         
                <div class="form-group col-md-6">
                    <label for="title"><span *ngIf="submitted && otp['otp_value'].errors?.['required']" style="color:red">  (Required)</span> </label>
                    <input id="partitioned" type="text" maxlength="4"  formControlName="otp_value" style="padding: 4%;width: 203%;">  
                </div>
                    
                </div>
               
                <div>  
                    <button type="button" class="btn btn-danger"
           
                    (click)="modal.close('Save click')">Close</button>
                <button type="submit" class="btn btn-success" style="float:right;">Submit</button>
              </div>     
            </form>

        </div> 
    </div>
</ng-template>
    <!-- new second modal end -->



<!-- new third modal start -->
<ng-template #modalData3 let-modal >  
    <div class="modal-body">    
        <form [formGroup]="myForm3" (ngSubmit)="find_lawyer_form_submit()" >    
        <div class="form-group">
            <label for="recipient-name" class="col-form-label">Lawyer Opinion:</label>
            <select id="option-size" formControlName="opinion" class="form-control custom-control">
                <option *ngFor = "let opinion of lawyer_opinion" value="{{opinion.opinion}}"> {{opinion.opinion}} </option>
            </select>

            <label for="recipient-name" class="col-form-label">City List:</label>
            <select id="option-size" formControlName="city_name" class="form-control custom-control">
                <option *ngFor ="let city of city_list" value="{{city.id}},{{city.state_id}},{{city.country_id}}"> {{city.name}} </option>
            </select>

            <label for="recipient-name" class="col-form-label">Roar Rating:</label> 
           <span>(Coming Soon..!)</span>
          </div>
          
       <div>  
        <button type="button" class="btn btn-danger"  (click)="modal.close('Save click')">Close</button>
        <button type="submit" class="btn btn-success" style="float:right;">Submit</button>
      </div>     
       
</form>
       
    </div>
</ng-template>

    <!-- new third modal end -->     