import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, Validators, } from '@angular/forms';
import { EventRoutingModule } from './event-routing.module';
import { EventComponent } from './event.component';
import { IndexComponent } from './index/index.component';
import { ViewComponent } from './view/view.component';
import { AddComponent } from './add/add.component';
import { EditComponent } from './edit/edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { LoginModule } from '../login/login.module';
import { NgxEditorModule } from 'ngx-editor';
import { MatButtonModule } from '@angular/material/button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxClampModule } from 'ngx-clamp';
import { NgxPaginationModule } from 'ngx-pagination';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { EventregModule } from '../eventreg/eventreg.module';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';

@NgModule({
  declarations: [EventComponent, IndexComponent, ViewComponent, AddComponent, EditComponent],
  imports: [
    CommonModule,
    EventRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
  MatDatepickerModule,
  MatInputModule,
  NgxEditorModule,
  MatButtonModule,
  NgbModule,
  NgxClampModule,
  NgxPaginationModule,
  ShareModule,
  ShareButtonsPopupModule,
  ShareButtonsModule,
  EventregModule,


  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  exports: [
    MatButtonModule
  ]
})
export class EventModule { }
