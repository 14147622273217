import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ResourceCentreService } from '../resource-centre.service';
import { Resource } from '../resource';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import{rcjudgement} from './scriptJudgement'




@Component({
  selector: 'app-docfree',
  templateUrl: './judgement-form.component.html',
  styleUrls: ['./judgement-form.component.css']
})
export class JudgementComponent implements OnInit {
  attachment_url: any;
  additional_attachment_url: any;
  resource: any;
  service;
  step = 1;
fivedays;
tendays;
fifteendays;
activeid=1;
price;
cgstpercentage:any = 9;
sgstpercentage:any = 9;
cgstamt:any = 0;
sgstamt:any = 0;
TotalTaxamt:any;
pagecount=1;
  constructor(public resourcecenterservice: ResourceCentreService, private formBuilder: FormBuilder,
    private route: ActivatedRoute, private router: Router,) {
  }



  ngOnInit(): void {
    this.servicePackage();
    rcjudgement();

  }
  servicePackage() {
    this.resourcecenterservice.servicePackage().subscribe((res: any) => {
     
      this.service = res;
      
      this.fivedays= res.find(obj => obj.id == 3);
      this.tendays= res.find(obj => obj.id == 2);
      this.fifteendays= res.find(obj => obj.id == 1);
      this.calculation();
      console.log('kkkkkkkkkk',this.fivedays);
      console.log('kkkkkkkkkk',this.tendays);
      console.log('kkkkkkkkkk',this.fifteendays);
    });
   
  }
  setactiveid(id){
    this.activeid=id;
    this.calculation();
    console.log('eventid',id)
  }
  onKeyup(event){
    this.pagecount=event;
this.calculation();
console.log('event',event)
  }

calculation(){
 
    if(this.activeid==3){
      console.log('llllllll',this.activeid,this.fifteendays)
      if(this.pagecount<=parseInt(this.fifteendays.page_base)){
        this.price=this.fifteendays.price;
      }else{
        let digit = this.pagecount - parseInt(this.fifteendays.page_base);
        this.price=parseInt(this.fifteendays.price)+ (parseInt(this.fifteendays.per_page) * digit);
      }
      this.cgstamt = ((this.price * this.cgstpercentage) / 100);
      this.sgstamt = ((this.price * this.sgstpercentage) / 100);
      this.TotalTaxamt = (parseFloat(this.price) + (parseFloat(this.cgstamt) + parseFloat(this.sgstamt))).toFixed(2) ;
    }else if(this.activeid==2){
      
      if(this.pagecount<=parseInt(this.tendays.page_base)){
        this.price=this.tendays.price;
      }else{
        let digit = this.pagecount - parseInt(this.tendays.page_base);
        this.price=parseInt(this.tendays.price) + (parseInt(this.tendays.per_page) * digit);
      }
      this.cgstamt = ((this.price * this.cgstpercentage) / 100);
      this.sgstamt = ((this.price * this.sgstpercentage) / 100);
      this.TotalTaxamt = (parseFloat(this.price) + (parseFloat(this.cgstamt) + parseFloat(this.sgstamt))).toFixed(2) ;
    }else{
      if(this.pagecount<=parseInt(this.fivedays.page_base)){
        this.price=this.fivedays.price;
      }else{
        let digit = this.pagecount - parseInt(this.fivedays.page_base);
        this.price = parseInt(this.fivedays.price)+ (parseInt(this.fivedays.per_page) * digit);
      }
      this.cgstamt = ((this.price * this.cgstpercentage) / 100);
      this.sgstamt = ((this.price * this.sgstpercentage) / 100);
      this.TotalTaxamt = (parseFloat(this.price) + (parseFloat(this.cgstamt) + parseFloat(this.sgstamt))).toFixed(2) ;
    }

}




  myForm = new FormGroup({
    attachment_url: new FormControl('', Validators.required),
    additional_attachment_url: new FormControl(''),
    pages: new FormControl('', Validators.required),
    status: new FormControl(''),
    description: new FormControl('', Validators.required),
    // name: new FormControl('', ),
    // email: new FormControl('',),
    // contact_num: new FormControl(''),
    // address: new FormControl('',),
    // amount: new FormControl(''),

  });
  myForm1 = new FormGroup({
    client_name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    contact_num: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
  });
  get f() {
    return this.myForm.controls;
  }
  get f1() {
    return this.myForm1.controls;
  }
  registration(e) {
    console.log(e.target.value);
  }
  onFileChange(event) {
    this.attachment_url = event.target.files[0];
  }
  onFileChange1(event) {
    this.additional_attachment_url = event.target.files[0];
  }
  status=1;
  submitted = false;
  submit() {
    this.submitted = true;
if(this.myForm.valid){


    const formData = new FormData();

    // formData.append("attachment_url", this.attachment_url);
    // formData.append("additional_attachment_url", this.additional_attachment_url);
    // formData.append('pages', this.myForm.get('pages').value);
    // formData.append("description", this.myForm.get('description').value);
    // formData.append('additional_attachment_url', this.myForm.get('additional_attachment_url').value);
    // formData.append('attachment_url', this.myForm.get('attachment_url').value);
    // formData.append('status', this.status+'');


    // formData.append('client_name', this.myForm.get('client_name').value);
    // formData.append("email", this.myForm.get('email').value);
    // formData.append('address', this.myForm.get('address').value);
    // formData.append('contact_num', this.myForm.get('contact_num').value);



    // this.resourcecenterservice.judgementCreate(formData).subscribe((res:any) => {
     

    // });
    this.step=2;
}
  }

  submitted1 = false;
  submit1() {
    this.submitted1 = true;
    if (this.myForm1.valid) {

      const formData = new FormData();
      formData.append("attachment_url", this.attachment_url);
      formData.append("additional_attachment_url", this.additional_attachment_url);
      formData.append('pages', this.myForm.get('pages').value);
      formData.append("description", this.myForm.get('description').value);
      formData.append('additional_attachment_url', this.myForm.get('additional_attachment_url').value);
      formData.append('attachment_url', this.myForm.get('attachment_url').value);
      formData.append('status', this.status+'');
     
      formData.append('client_name', this.myForm1.get('client_name').value);
      formData.append("email", this.myForm1.get('email').value);
      formData.append('address', this.myForm1.get('address').value);
      formData.append('contact_num', this.myForm1.get('contact_num').value);

      formData.append('status',1+'');
      formData.append('service_type_id',1+'');
      formData.append('advance_fee',1+'');
      formData.append('total_fee',1+'');
      formData.append('remaining_fee',1+'');
      formData.append('reopen_fee',1+'');
     
      this.resourcecenterservice.judgementCreate(formData).subscribe((res:any) => {
     

      });
    }
  }

}