import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'single-comment',
  templateUrl: './singlecomment.component.html',
  //   styleUrls: ['./view.component.css']
})
export class SingleCommentComponent implements OnInit {

  @Input()
  comment: any;

  @Input()
  parentId: number;

  @Input()
  groupId: number;

  @Input()
  isReply:boolean = false;

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
  }
  showform = false;
  onclick() {
    //  if(this.showform==false){
    //    this.showform=true;
    //  }else{
    //    this.showform=false;
    //  }

    //  if(this.showform){
    //   this.showform=false;
    // }else{
    //   this.showform=true;
    // }

    this.showform = !this.showform;

  }


  onCommentCreate() {
this.showform=false;
this.onSubmit.emit([]);
  }
}