import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'    
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
  
import { Roar } from './roar';
  
@Injectable({
  providedIn: 'root'
})
export class RoarService {
  userInfoapi: any;
  // private apiURL = "https://jsonplaceholder.typicode.com";
  public apiURL = environment.baseURL;

    
  /*------------------------------------------
  --------------------------------------------
  Http Header Options
  --------------------------------------------
  --------------------------------------------*/
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
   
  /*------------------------------------------
  --------------------------------------------
  Created constructor
  --------------------------------------------
  --------------------------------------------*/
  constructor(private httpClient: HttpClient) { }
    
  /**
   * Write code on Method
   *
   * @return response()
   */
  getAll(limit): Observable<Roar[]> {
    if(localStorage.getItem('USERID')){
      const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('USERID') });
      const headers = { headers: header };
      return this.httpClient.get<Roar[]>(this.apiURL + 'roar?limit='+limit,headers)
      .pipe(
        catchError(this.errorHandler)
      )
    }else{
      return this.httpClient.get<Roar[]>(this.apiURL + 'roar?limit='+limit)
      .pipe(
        catchError(this.errorHandler)
      )
    }
   
    
  
  }
  
  /**
   * Write code on Method
   *
   * @return response()
   */
  create(formData): Observable<any> {
    const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('USERID') });
    const headers = { headers: header };
    console.log(headers, localStorage.getItem('USERID'));
  
    return this.httpClient.post(this.apiURL + 'post_roar',formData, headers )
  
    .pipe(
      catchError(this.errorHandler)
    )
  }  
   
  /**
   * Write code on Method
   *
   * @return response()
   */
  find(id:number): Observable<any> {
  
    // return this.httpClient.get(this.apiURL + 'roar/' + id)
    return this.httpClient.get(this.apiURL + 'show_feed_data/' + id)
  
    .pipe(
      catchError(this.errorHandler)
    )
  }
    
  /**
   * Write code on Method
   *
   * @return response()
   */
  update(id:number, roar:Roar): Observable<any> {
  
    return this.httpClient.put(this.apiURL + '/roar/' + id, JSON.stringify(roar), this.httpOptions)
 
    .pipe( 
      catchError(this.errorHandler)
    )
  }
       
  /**
   * Write code on Method
   *
   * @return response()
   */
  delete(id:number){
    return this.httpClient.delete(this.apiURL + 'roar/' + id, this.httpOptions)
  
    .pipe(
      catchError(this.errorHandler)
    )
  }
      
  /** 
   * Write code on Method
   *
   * @return response()
   */
  errorHandler(error:any) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }

 roar_list(){
  const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('USERID') });
    const headers = { headers: header };
  return this.httpClient.post(this.apiURL + 'get_feed_list',headers)
  // return this.httpClient.post(this.apiURL + 'get_feed_list' )
    .pipe(
      catchError(this.errorHandler)
    )
}
top_contributor_list()
{
  return this.httpClient.get(this.apiURL + 'top_contributor_list') 
    .pipe(
      catchError(this.errorHandler)
    )
}

}