<section class="hero-area">
    <div class="page-header">
        <h3 style="color: #fff!important;text-align: center;font-weight: bold;" class="text-primary">Resource Center
        </h3>
    </div>
</section>
<div class="resource-page detail">
    <div class="container">
        <div class="resource-dtls">          

            <!-- ##################         Doc Free         ############################## -->
            <div class="col-md-8 col-lg-8" *ngIf="resource.rc_paymetType==0">
                <div class="row">
                    <div class="col-5 col-sm-3">
                        <!-- <img href="assets/images/kanika.png" style="height: 87%;width: 96%;"> -->
                        <img *ngIf="resource.member_profile_pic_url" [src]="getImagePic(resource)"
                            style="height: 87%;width: 96%;">
                        <img *ngIf="!resource.member_profile_pic_url" src="../../assets/images/profile-user-round.png"
                            alt="" style="height: 87%;width: 96%;">
                        <div style="text-align: center;" class="quick-userinfo">
                            <h5>Law Firm</h5>
                            <p>Team Soolegal</p>
                            <div class="like cursor" style="margin: -13px;"><i class="fa fa-phone"
                                    aria-hidden="true"></i><span class="ml-1"> &nbsp; {{resource.member_details[0]['contact_num']}}</span></div>
                        </div>
                    </div>
                    <div class="col-4 col-sm-9">
                        <div class="read-more">
                            <div class="titlecard">
                                <div style="display: flex;" class="resource-title">
                                    <h4>{{resource.rc_title}}</h4>
                                    <h4 style="color: red; font-weight: bold;">FREE</h4>
                                </div>

                                <div class="small d-flex justify-content-start" style="margin-top: 8px;">
                                    <a  class="d-flex align-items-center me-3">
                                        <!-- <i class="fa fa-heart-o" aria-hidden="true"
                                            style="font-size:26px;color:#90278e"></i> -->
                                            <i  class="fa fa-heart-o" style="font-size:26px;color:#90278e" (click)="click()" (click)="like(resource)" *ngIf="!liked"></i> 
                                            <i  class="fa fa-heart-o" style="color:red"  (click)="like(resource)"
                                                *ngIf="liked"></i>

                                        <p style="padding: 5px;" class="mb-0 doc">{{ resource.like_count }}</p>
                                    </a>
                                    <a (click)="open(mymodal,r)" class="d-flex align-items-center me-3"
                                        style="margin-left: 22px;">
                                        <i class="fa fa-commenting-o" aria-hidden="true"
                                            style="font-size:26px;color:#90278e"></i>
                                        <p style="padding: 5px;" class="mb-0 doc">{{resource.comment_count}}</p>
                                    </a>
                                    <a  class="d-flex align-items-center me-3" style="margin-left: 22px;">
                                        <i class="fa fa-share-alt fa-xl" aria-hidden="true"
                                            style="font-size:26px;color:#90278e"></i>
                                        <p style="padding: 5px;" class="mb-0 doc">{{resource.share_count}}</p>
                                    </a>
                                    <a  class="d-flex align-items-center me-3" style="margin-left: 22px;">
                                        <i class="fa fa-eye fa-xl" aria-hidden="true"
                                            style="font-size:26px;color:#90278e"></i>

                                        <p style="padding: 5px;" class="mb-0 doc">{{resource.view_count}}</p>
                                    </a>
                                </div>

                            </div>
                            <hr>
                            <p [innerHTML]="resource.rc_description"> </p>

                            <p class="prompt">
                                <!-- <a class="button btn btn-primary" href="#">Read More</a> -->
                            </p>
                        </div>
                    </div>
                </div>
                <form class="readmore-form" [formGroup]="myForm" (ngSubmit)="submit()" *ngIf="showform">
                    <h4 style="text-align: center; color: #fff; padding-bottom: 2em;" class="form-title">Interesting
                        ?</h4>
                    <div class="row mb-3">
                        <label for="inputname3" class="col-sm-2 col-form-label" [required]="true" formControlName="name">Name</label>
                        <div class="col-sm-10">
                            <input type="name" class="form-control" id="inputname3"><span
                            *ngIf=" f.name.errors?.required" style="color:red"> (Required)</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputEmail3" class="col-sm-2 col-form-label" [required]="true" formControlName="email">Email</label>
                        <div class="col-sm-10">
                            <input type="email" class="form-control" id="inputEmail3"><span
                            *ngIf="f.email.errors?.required" style="color:red"> (Required)</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputPhone" class="col-sm-2 col-form-label" formControlName="mobile">Mobile</label>
                        <div class="col-sm-10">
                            <input type="phone" class="form-control" id="inputphone">
                        </div>
                    </div>
                    <div class="button-grp">
                        <button type="submit" (click)="ope(modal)" (click)="show()" class="btn btn-primary res-but">Read
                            More</button>
                            <!-- <p>{{f.email.errors|json}}</p> -->
                            <!-- <form  [formGroup]="myForm" (ngSubmit)="rcdownload()" > -->
                        <button type="submit"  (click)="downloadMyFile()" (click)="rcdownload()"
                            class="btn btn-primary res-but" >Free Download</button>
                            <!-- </form> -->
                    </div>
                </form>
                <!-- <div class="document-actions">
                    <h3 style="text-align: inherit;font-size: 25px;padding: 30px 0;font-weight: 700;margin-left: 21px;">
                        Legal Process Outsourcing this Document</h3>
                    <div style="text-align: center; padding: 20px; " class="btn-group">
                        <a href="resource-centre/resource-judgement" class="btn2">Judgement/Synopsis</a>
                        <a href="resource-centre/translation" class="btn2">Translation</a>
                        <a href="resource-centre/legalresearch" class="btn2">Legal Research</a>
                    </div>
                </div> -->
            </div>

            <!-- #####################   Document for LOGIN user  ############################### -->

            <div class="col-md-8 col-lg-8" *ngIf="token && resource?.paymentType==0">
                <div class="row">

                    <div class="col-2 col-sm-3 custom-profile-rc-page">
                        <!-- <img style="width: 150px; border-radius: 50%;" src="assets/images/kanika.png"> -->
                        <img *ngIf="resource.member_profile_pic_url" [src]="getImagePic(resource)"
                            style="height: 87%;width: 96%;">
                        <img *ngIf="!resource.member_profile_pic_url" src="../../assets/images/profile-user-round.png"
                            alt="" style="height: 87%;width: 96%;">
                        <div style="text-align: center;" class="quick-userinfo-rc">
                            <h5 style="padding: 10px 0;">Law Firm</h5>
                            <p>Team Soolegal</p>
                            <div class="like cursor"><i class="fa fa-phone" aria-hidden="true"></i><span class="ml-1">
                                    &nbsp; 9876543210</span></div>
                        </div>
                    </div>

                    <div class="col-4 col-sm-9">
                        <div class="read-more">
                            <div class="titlecard">
                                <div style="display: flex;" class="resource-title">
                                    <h4>{{resource.title}}</h4>
                                    <h4 style="color: red;">FREE</h4>
                                </div>
                                <div class="small d-flex justify-content-start">
                                    <a  class="d-flex align-items-center me-3">
                                        <!-- <i class="fa fa-heart-o" aria-hidden="true"
                                            style="font-size:26px;color:#90278e"></i> -->

                                            <i  class="fa fa-heart-o"  style="font-size:26px;color:#90278e" (click)="click()" (click)="like(resource)" *ngIf="!liked"></i> 
                                            <i class="far fa-heart like" style="color:red"  (click)="like(resource)"
                                                *ngIf="liked"></i>
                                        <p style="padding: 5px;" class="mb-0 doc">{{resource.like_count}}</p>
                                    </a>
                                    <a  class="d-flex align-items-center me-3" style="margin-left: 22px;">
                                        <i class="fa fa-commenting-o" aria-hidden="true"
                                            style="font-size:26px;color:#90278e"></i>
                                        <p style="padding: 5px;" class="mb-0 doc">{{resource.comment_count}}</p>
                                    </a>
                                    <a class="d-flex align-items-center me-3" style="margin-left: 22px;">
                                        <i class="fa fa-share-alt fa-xl" aria-hidden="true"
                                            style="font-size:26px;color:#90278e"></i>
                                        <p style="padding: 5px;" class="mb-0 doc">60</p>
                                    </a>
                                    <a  class="d-flex align-items-center me-3" style="margin-left: 22px;">
                                        <i class="fa fa-eye fa-xl" aria-hidden="true"
                                            style="font-size:26px;color:#90278e"></i>

                                        <p style="padding: 5px;" class="mb-0 doc">{{resource.view_count}}</p>
                                    </a>
                                    <a  class="d-flex align-items-center me-3" style="margin-left: 22px;">
                                        <i class="fa fa-download fa-xl" aria-hidden="true"
                                            style="font-size:26px;color:#90278e" ></i>

                                        <p style="padding: 5px;" class="mb-0 doc">{{resource.download_count}}</p>
                                    </a>
                                </div>
                            </div>
                            <hr>

                            <p [innerHTML]="resource.description">
                            </p>

                        </div>

                    </div>
                </div>
                <!-- <div class="description-doc">
                    
                </div> -->

                <div class="document-actions2">
                    <div class="btn-groupdl">
                        <button class="btn-document" (click)="ope(modal)">View Document</button>

                        <button class="btn-document" (click)="downloadMyFile()" (click)="rcdownload()">Download For Free</button>
                    </div>

                    <div class="small d-flex justify-content-start">
                        <a class="d-flex align-items-center me-3" style="margin-top: 77px;">
                            <i  class="fa fa-heart-o"  style="font-size:26px;color:#90278e" (click)="click()" (click)="like(resource)" *ngIf="!liked"></i> 
                                            <i class="far fa-heart like" style="color:red"  (click)="like(resource)"
                                                *ngIf="liked"></i>
                            <p style="padding: 5px;" class="mb-0 doc">Like</p>
                        </a>
                        <a class="d-flex align-items-center me-3" style="margin-left: 22px;margin-top: 77px;">
                            <i (click)="open(mymodal,r)" class="fa fa-commenting-o" aria-hidden="true"
                                style="font-size:26px;color:#90278e"></i>
                            <p style="padding: 5px;" class="mb-0 doc">Comment</p>
                        </a>
                        <a  class="d-flex align-items-center me-3" style="margin-left: 22px;margin-top: 77px;" (click)="openshare(modalshare)">
                            <i class="fa fa-share-alt fa-xl" aria-hidden="true"
                                style="font-size:26px;color:#90278e"></i>
                            <p style="padding: 5px;" class="mb-0 doc">Share</p>
                        </a>
                        <!-- <div class="comments-count">
                            <a (click)="openshare(modalshare)" class="quick-actions-icons"><i
                                    class="fa fa-share-alt" aria-hidden="true"></i>
                            </a>
                            <span class="count-cmnt">5</span>
                        </div> -->
                        <ng-template #modalshare let-modal>

                            <share-buttons [theme]="'material-dark'"
                                [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                                [show]="15" [url]="getLocation(resource.id)" [autoSetMeta]="false">
                            </share-buttons>


                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Close</button>
                            </div>
                        </ng-template>

                    </div>
                </div>
                <hr>
                <!-- <div class="document-actions">
                    <h3 style="text-align: center; font-size: 20px; padding:30px 0;">Legal Process Outsourcing this
                        Document</h3>
                    <div style="text-align: center; padding: 20px; " class="btn-groupcs">
                        <a href="resource-centre/resource-judgement" class="btn2">Judgement/Synopsis</a>
                        <a href="resource-centre/translation" class="btn2">Translation</a>
                        <a href="resource-centre/legalresearch" class="btn2">Legal Research</a>
                    </div>
                </div> -->
            </div>


            <!-- ############### Paid Document  ###################### -->
            <div class="col-md-8 col-lg-8" *ngIf="resource.rc_paymetType==1">
                <div class="row">
                    <div class="col-5 col-sm-3">
                        <!-- <img href="assets/images/lawyers/online-chamber-kanika.png"> -->
                        <img *ngIf="resource.member_profile_pic_url" [src]="getImagePic(resource)"
                        style="height: 80%;width: 96%;">
                    <img *ngIf="!resource.member_profile_pic_url" src="../../assets/images/profile-user-round.png"
                        alt="" style="height: 80%;width: 96%;">
                        <div style="text-align: center;" class="quick-userinfo">
                            <h5>Law Firm</h5>
                            <p>Team Soolegal</p>
                        </div>
                    </div>
                    <div class="col-4 col-sm-9">
                        <div class="read-more">
                            <div class="titlecard">
                                <div style="display: flex;" class="resource-title">
                                    <h4>{{resource.title}}</h4>
                                    <h4 style="color: red;">{{resource.amount}}</h4>
                                </div>
                                <div class="d-flex flex-row fs-12">
                                    <div class="like p-2 cursor cmnt">{{resource.like_count}}<span class="ml-1" >&nbsp;Like</span></div>
                                    <div class="like p-2 cursor">{{resource.comment_count}}<span class="ml-1">&nbsp;Comment</span></div>
                                    <div class="like p-2 cursor">{{ resource.share_count }}<span class="ml-1">&nbsp;Share</span></div>
                                    <div class="like p-2 cursor">{{resource.rc_download_count}}<span class="ml-1">&nbsp;Downloads</span></div>
                                    <div class="like p-2 cursor">{{resource.view_count}}<span class="ml-1">&nbsp;Views</span></div>
                                </div>
                            </div>
                            <hr>
                            <p [innerHTML]="resource.description" > </p>
                        </div>
                    </div>
                </div>
                <form class="readmore-form" [formGroup]="myFormPaid" (ngSubmit)="submit()">
                    <h4 style="text-align: center; color: #fff; padding-bottom: 2em;" class="form-title">Interesting
                        ?</h4>
                    <div class="row mb-3">
                        <label for="inputname3" class="col-sm-2 col-form-label">Name</label>
                        <div class="col-sm-10">
                            <input type="name" class="form-control" formControlName="name" id="inputname3">
                            <span *ngIf="submitted && formPaid.name.errors?.required" style="color:red"> (Required)</span>

                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
                        <div class="col-sm-10">
                            <input type="email" class="form-control" formControlName="email" id="inputEmail3">
                            <span *ngIf="submitted && formPaid.email.errors?.required" style="color:red"> (Required)</span>

                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputPhone" class="col-sm-2 col-form-label">Mobile</label>
                        <div class="col-sm-10">
                            <input type="phone" class="form-control" formControlName="mobile" id="inputphone">
                            <span *ngIf="submitted && formPaid.mobile.errors?.required" style="color:red"> (Required)</span>

                        </div>
                    </div>
                    <div class="button-grp">
                        <button type="submit" (click)="submitPaidForm('addtocart')" class="btn btn-primary res-but">Add to Cart</button>
                        <button type="submit" class="btn btn-primary res-but">Buy Now</button>
                    </div>
                </form>
                <!-- <div class="document-actions">
                    <h3 style="text-align: center; font-size: 20px; padding:30px 0;">Legal Process Outsourcing this
                        Document</h3>
                    <div style="text-align: center; padding: 20px; " class="btn-group">
                        <a href="resource-centre/resource-judgement" class="btn2">Judgement/Synopsis</a>
                        <a href="resource-centre/translation" class="btn2">Translation</a>
                        <a href="resource-centre/legalresearch" class="btn2">Legal Research</a>
                    </div>
                </div> -->
            </div>



            <!-- ##################### Featured Members   ############################## -->
            <div class="col-md-4 col-lg-4">
                <div class="Featured-members">
                    <div class="card card-margin">
                        <div style="display: flex; justify-content: space-between;" class="card-header no-border">
                            <h5 style="font-weight: bold;">Featured Members</h5>
                            <!-- <button class="btn-stl2">View All</button> -->
                        </div>
                        <div *ngFor="let f of featured">
                            <div class="card-body">
                                <div class="widget-49">
                                    <div class="widget-49-title-wrapper" style="display: flex;">
                                        <!-- <img class="lwyr-img" src="assets/images/kanika.png"> -->
                                        <img *ngIf="f.url" [src]="getImage(f)" class="lwyr-img">
                                        <img *ngIf="!f.url" src="../../assets/images/profile-user-round.png"
                                            alt="" class="lwyr-img">
                                        <div class="lawyer-info">
                                            <span class="widget-49-pro-title"><b>{{f.username}} (lawyer)</b></span>
                                            <span class="widget-49-meeting-time">{{f.email}}</span>
                                            <div class="like cursor"><i class="fa fa-phone" aria-hidden="true"></i><span
                                                    class="ml-1"> &nbsp; {{f.contact_num}}</span></div>
                                            <div class="like cursor"><i class="fa fa-map-marker"
                                                    aria-hidden="true"></i><span class="ml-1"> &nbsp; New Delhi</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lawyer-badge">
                                        <span class="badge bg-light text-dark">Marriage</span>
                                        <span class="badge bg-light text-dark">Divorce</span>
                                        <span class="badge bg-light text-dark">Alimony</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>


    <!-- Most popular videos -->
        <section class="items-grid section custom-padding">
            <div class="container">
                <div class="row">
                    <div class="col-12" style="margin-top: -40px;">
                        <div class="res-cen-car">
                            <div class="heading-cu col-md-6">
                                <h2 style="text-align: left;" class="wow fadeInUp" data-wow-delay=".4s">
                                    Most Popular videos</h2>
                            </div>
                            <div class="control-box arrows">
                                <a class="btn-floating" href="#multi-item-example" data-slide="prev"><i
                                        class="fas fa-chevron-left"></i></a>
                                <a class="btn-floating" href="#multi-item-example" data-slide="next"><i
                                        class="fas fa-chevron-right"></i></a>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="single-head">
                    <div class="row">   

                                <div class="col-12">
                                    <ngx-slick-carousel class="carousel user-info-box-inner no-scroll overflow-hidden" #slickModal="slick-carousel" [config]="slideConfig">
                                        <div ngxSlickItem *ngFor="let v of get_rcpopular_videos;" class="slide text-left">
                                    <!-- Start Single Grid -->
                                    <div class="single-grid wow fadeInUp" data-wow-delay=".2s">
                                        <div class="image">
                                            <a href="item-details.html" class="thumbnail"><iframe width="420" height="280"
                                                    src= {{v.rc_video_url}}>
                                                </iframe></a>
                
                                        </div>
                                        <div class="content">
                                            <div class="top-content">
                                                <ul class="info-list">
                                                    <li><a href="javascript:void(0)">{{v.member_details[0]['first_name']}} </a></li>
                                                    
                                                    <li><a href="javascript:void(0)"> {{v.created_at}}</a>
                                                    </li>
                                                    <!-- <li><a href="javascript:void(0)"> {{v.created_date*1000|date:'yyyy/MM/dd H:m:s'}}</a>
                                                    </li> -->
                                                </ul>
                
                                                <h3 class="title">
                                                    <a href="item-details.html">
                                                        {{v.rc_title}}
                                                    </a>
                                                </h3>
                
                
                                                <div class="user-info video">
                                                    <div class="author-image">
                                                        <a ><img *ngIf="v.member_profile_pic_url" [src]="getImagePic(v)" >
                                                            <img *ngIf="!v.member_profile_pic_url" [src]="getImagePic(v)" >
                                                            <span>Alex Jui</span></a>
                                                    </div>
                
                                                    <div style="color: black;" class="quick-actions">
                                                        <a  class="quick-actions-icons"><i class="lni lni-heart"></i></a>
                                                        <a  class="quick-actions-icons"><i class="lni lni-comments"></i></a>
                                                        <a  class="quick-actions-icons"><i class="lni lni-share"></i></a>
                                                    </div>
                                                </div>
                
                
                
                                            </div>
                
                
                                        </div>
                                    </div>
                                    <!-- End Single Grid -->
                                </div>
                            </ngx-slick-carousel>
                            </div>
                    </div>
                    <div style="margin-top: 3em; text-align: center;" class="button">
                        <button style="font-size:20px; background-color: rgba(235, 82, 82, 0.842); color: white !important;" class="btn">More Articles</button>
                    </div>
                </div>
            </div>
        </section>
    <!-- Most popular videos end -->




 <!-- Most popular articles -->
 <section class="items-grid section custom-padding">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="res-cen-car">
                    <div class="heading-cu col-md-6">
                        <h2 style="text-align: left;" class="wow fadeInUp" data-wow-delay=".4s">
                            Most Popular Articles</h2>
                    </div>

                </div>
            </div>
        </div>

        <div class="single-head">
            <div class="row">


                <ngx-slick-carousel class="carousel user-info-box-inner no-scroll overflow-hidden" #slickModal="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let a of recent" class="slide text-left">
                        <div class="single-grid wow fadeInUp" style="margin: 10px;" data-wow-delay=".2s">
                            <div class="">
                                <div style="padding: 15px; border: 1px solid rgb(209, 208, 208); border-radius: 2%;" class="">

                                    <div class="user-block">
                                        <div class="image1">
                                            <img *ngIf="a.member_profile_pic_url" [src]="getImagePic(a)" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                            <img *ngIf="!a.member_profile_pic_url" src="../../assets/images/profile-user-round.png" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                            <!-- <img *ngIf="a.member_profile_pic_url" [src]="getImagePic(a)"
                                            style="height: 87%;width: 96%;"> -->
                                            <!-- <img *ngIf="!a.member_profile_pic_url" src="../../assets/images/profile-user-round.png"
                                            alt="" style="height: 87%;width: 96%;"> -->

                                        </div>
                                        <div class="user-info2">
                                            <a class="usr-nm">{{a.username}}</a>
                                            <span class="time">{{a.created_date*1000|date:'yyyy/MM/dd H:m:s'}}</span>
                                        </div>
                                    </div>

                                    <hr>

                                    <h4 class="title rc">
                                        <a [attr.href]="'/resource-centre/'+ a.id + '/resource-docfree'">
                                        {{a.title}}
                                    </a>
                                    </h4>
                                    <p class="update-time" [innerHtml]="a.description" ngx-clamp [ngxClampOptions]="{ clamp: 3}">

                                    </p>

                                    <hr>

                                    <div class="social-count">
                                        <div class="likes-count">
                                            <a class="quick-actions-icons" (click)="click()"><i class="fa fa-heart"
                                                (click)="like(a)" *ngIf="!a.liked"></i>
                                            <i class="fa fa-heart" style="color:red" (click)="like(a)"
                                                *ngIf="a.liked"></i></a>
                                            <!-- <span (click)="click()"><i class="far fa-heart inlike"  (click)="like(r)" *ngIf="!r.liked"></i> 
                                                <i class="far fa-heart like" style="color:red"  (click)="like(r)"
                                                    *ngIf="r.liked"></i>{{r.like_count}}</span> -->
                                            <span class="count-lk">{{a.like_count}}</span>
                                        </div>
                                        <div class="shares-count">
                                            <a  (click)="open(mymodal,r)" class="quick-actions-icons"><i
                                                class="fa fa-comment-o"></i></a>
                                            <span class="count-shr">{{a.comment_count}}</span>
                                        </div>





                                        <div class="comments-count">
                                            <a (click)="openshare(modalshare)" class="quick-actions-icons"><i
                                                class="fa fa-share-alt" aria-hidden="true"></i>
                                        </a>
                                            <span class="count-cmnt">5</span>
                                        </div>
                                        <ng-template #modalshare let-modal>

                                            <share-buttons [theme]="'material-dark'" [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']" [show]="15" [url]="getLocation(a.id)" [autoSetMeta]="false">
                                            </share-buttons>


                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                            </div>
                                        </ng-template>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>


            </div>
            <div style="margin-top: 3em; text-align: center;" class="button">
                <button style="font-size:20px; background-color: rgba(235, 82, 82, 0.842); color: white !important;" class="btn">More Articles</button>
            </div>
        </div>
    </div>
</section>
<!-- /most popular articles -->


        <ng-template #mymodal let-modal>


            <article>
                <div class="component-box-1 ">
                    <div class="user-info-box-main">

                        <single-comment-form comment-form [postId2]="resource.id" [categoryId]="3"
                            (onSubmit)="onCommentCreate(resource.id)">

                        </single-comment-form>

                        <div class="user-info-box ">
                            <h4>Comments </h4>

                            <section class="user-info-box-inner no-scroll text-left h-0 p-4">

                                <div *ngFor="let c of comments  | paginate
                                : {
                                    id: 'pagination2',
                                    itemsPerPage: tableSizecmt,
                                    currentPage: pagecmt,
                                    totalItems: countcmt
                                  };
                            let i = index
                          ">

                                    <single-comment [comment]="c" [groupId]="c.id" (onSubmit)="onCommentCreate(resource.id)"></single-comment>
                                    <div style="margin-left:100px">
                                        <single-comment *ngFor="let c1 of c.comments;" [comment]="c1" [groupId]="c.id"
                                            [isReply]="true" (onSubmit)="onComment(resource.id)">
                                        </single-comment>
                                    </div>


                                </div>
                            </section>
                            <div class="d-flex justify-content-center">
                                <pagination-controls id="pagination2" previousLabel="Prev" nextLabel="Next"
                                    (pageChange)="onTableDataChangecmt($event)">
                                </pagination-controls>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

        </ng-template>


        <!-- ##########################  popup  for pdf #################### -->

        <ng-template #modal let-modal>

            <iframe style="width:100%; height:500px;" frameBorder="0" [src]="urlSafe" toolbar="0"
                title="description"></iframe>


            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
            </div>
        </ng-template>


        <!-- ######## popup for comment   -->
        <ng-template #mymodal let-modal>
                    
                  
            <article>
                <div class="component-box-1 ">
                    <div class="user-info-box-main">
    
                        <single-comment-form comment-form [postId2]="r.id" [categoryId]="3"
                            (onSubmit)="onCommentCreate(r.id)">
    
                        </single-comment-form>
    
                        <div class="user-info-box ">
                            <h4>Comments </h4>
    
                            <section class="user-info-box-inner no-scroll text-left h-0 p-4">
    
                                <div *ngFor="let c of comments  | paginate
                                : {
                                    id: 'pagination2',
                                    itemsPerPage: tableSizecmt,
                                    currentPage: pagecmt,
                                    totalItems: countcmt
                                  };
                            let i = index
                          " >
    
                                    <single-comment [comment]="c" [groupId]="c.id"></single-comment>
                                    <div style="margin-left:100px">
                                        <single-comment *ngFor="let c1 of c.comments;" [comment]="c1"
                                            [groupId]="c.id" [isReply]="true"
                                            (onSubmit)="onComment(r.id)">
                                        </single-comment>
                                    </div>
    
                            
                                </div>
                            </section>
                            <div class="d-flex justify-content-center">
                                <pagination-controls
                                id="pagination2"
                                  previousLabel="Prev"
                                  nextLabel="Next"
                                  (pageChange)="onTableDataChangecmt($event)"
                                >
                                </pagination-controls>
                              </div>                                 
    
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
           
        </ng-template>
    













        <style>
            .demo-box {
                border-radius: 15px;
                border: 1px solid #ccc;
                padding: 25px;
                margin: 25px;
            }
        </style>

        <script>
            // Run default
            $(document).ready(function () {
                $(".read-more").readMore();
                $(".read-more.slim").readMore({ previewHeight: 100 });
                $(".read-more.thick").readMore({ previewHeight: 600 });
            });
        </script>
    </div>
</div>