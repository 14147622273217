import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/shared/canonical.service';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  title='About | Soolegal'

  constructor(  private titleService: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();

    this.titleService.setTitle(this.title);

    let location = window.location.href
    this.metaTagService.addTags([
      {name: 'description', content: 'SoOLEGAL invites Lawyer & Law Firms to Register. Find Best Lawyers Online, Get Free Legal Advice &  Socially Optimized Legal Directory' },
      {name: 'robots', content: 'index, follow'},
      {name: 'revisit-after', content: '1 days'},
      {name: 'copyright', content: 'Copyright - All Rights Reserved 2017 SoOLegal'},
      {name: 'author', content: 'SoOLEGAL'},
      {name: 'zipcode', content: '110074'},
      {name: 'city', content: 'New Delhi'},
      {name: 'state', content: 'Delhi'},
      {name: 'country', content: 'India'},
      {property: 'og:locale', content: 'en_US'},
      {property: 'og:type', content: 'website'},
      {property: 'og:title', content: this.title},
      {property: 'og:url', content:  location},
      {property: 'og:image', content: 'https://beta.soolegal.com/assets/images/logo.png'},
      {property: 'og:description', content: 'SoOLEGAL invites Lawyer & Law Firms to Register. Find Best Lawyers Online, Get Free Legal Advice &  Socially Optimized Legal Directory'},
      {property: 'og:site_name', content: 'SoOLEGAL'},
      {property: 'article:publisher', content: 'https://www.facebook.com/SOciallyOptimizedLEGAL/'},
      {property: 'fb:app_id', content: ''},
      {name: 'DC.title', content: 'SoOLEGAL'},
      {name: 'geo.region', content: 'IN-DL'},
      {name: 'geo.placename', content: 'New Delhi'},
      {name: 'geo.position', content: ''},
      {name: 'ICBM', content: ''}
    ]); 
  }
  // https://www.facebook.com/SOciallyOptimizedLEGAL/

}
