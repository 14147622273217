import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NewsService } from '../news.service'
import { News } from '../news';
import { CommentService } from '../../comment/comment.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemberService } from '../../member/member.service';
import { ApiService } from '../../api.service';
import { environment } from 'src/environments/environment';
import { EventService } from '../../event/event.service';


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
  closeResult: string;
  // postId: news[] = [];
  // news: News;
  news: any;
  comments: Comment[];
  userInfo: any;
  category_id = 2;
  postId:number;
  publish_date:any;
  type:any;
Limit:Event[]=[];
  page: number = 1;
  count: number = 0;
  tableSize: number = 3;
  tableSizes: any = [3, 6, 9, 12];



  constructor(private route: ActivatedRoute,public eventService:EventService, private router: Router, public memberapi: MemberService, public mainapi: ApiService, public commentService: CommentService, public newsService: NewsService, private modalService: NgbModal) { }
  


  ngOnInit(): void {
    var newsid = this.route.snapshot.paramMap.get('newsId');
    // var type=this.route.snapshot.paramMap.get('Type');
    this.findNews(newsid);
    this.getComments(newsid, this.category_id);
    this.limit();
    
  }


  getComments(post_id, category_id) {
    this.commentService.getAll(post_id, category_id).subscribe((com: Comment[]) => {
      this.comments = com;
      console.log("Comments..:::", this.comments);

    });
  }

  onCommentCreate() {
    this.getComments(this.news.id, this.category_id);
    console.log('news creayed');
  }
  onComment() {
    this.getComments(this.news.id, this.category_id);
    console.log('news creayed');
  }



  findNews(newsId) {
    this.newsService.find(newsId).subscribe((res: any) => {
      // this.news = res;
      this.news = res.messages.feed_list[0];
      this.news.tags=this.news.tags.split(',');
      
      console.log("NEWS..:::", this.news);
    });

  }
  

  getImage(news) {
    return news.img;
    // return "http://localhost:8080/api/news-image/" + news.url;
  }

  getLocation(news_id){
    // return location.href+'/'+news_id;
   return  "https://stackoverflow.com"+'/'+news_id;
  }
  isReadMore = false;

  showText() {
    this.isReadMore = !this.isReadMore
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getComments;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getComments;
  }
 limit(){
  this.eventService.limit().subscribe((com:Event[]) => {
    this.Limit = com;
    console.log("Comments..:::", this.comments);

  });
 }
}
