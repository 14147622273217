import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ResourceCentreService } from '../resource-centre.service';
import { Resource } from '../resource';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ActivatedRoute, Router } from '@angular/router';
import { rcanimate } from './scriptrc'

@Component({
  selector: 'app-resource-form',
  templateUrl: './resource-form.component.html',
  // styleUrls: ['./resorce-form.component.css']
})
export class ResourceFormComponent implements OnInit {

  resource: Resource[] = [];
  legal_procedure_sub_category_list:any;
  legal_procedure_sub_category_name:any;
  legal_format_sub_category_list:any;
  legal_format_sub_sub_category_list:any;
  name: any = {};
  url: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  category_id:any;
  rc_subcategory_name:any;
  enablevideoUploadUrl=false;  
  parent_id;
  step = 1;
  legal_procedure =1;
  legal_procedure_category_value1:string;
  judgement_category_value1:string;
  bare_acts_category_value1:string;
  legal_format_category_value1:string;
  $sub_category_id:any;
  selectedType = 'opentype';
  subcategory;

  
  constructor(public resourcecenterservice: ResourceCentreService,    private router: Router, private formBuilder: FormBuilder) {
    this.legal_procedure_category_value1 = "1";
    this.judgement_category_value1= "2";
    this.bare_acts_category_value1="3";
    this.legal_format_category_value1="4";
   }

  ngOnInit(): void {

    this.form('');
    this.category_click('');
    rcanimate();
    this.get_legal_procedure_sub_category_list();
    this.get_legal_format_sub_category_list();


  }

  getResource(parent_id) {
    console.log('getting Resource', parent_id);
    this.resourcecenterservice.getAll(parent_id).subscribe((res: any) => {
      //   var resource_category={};
      //   res.forEach(function (value:any) {
      //     resource_category[value.resource_centre_category_id]=[value];
      //     console.log(resource_category);
      // });
      // console.log('ttttttttttttttttttttt',resource_category);
      let resArr = [];
      function findChildren(parentId, parentName) {
        console.log("parentId..:::", parentId);
        let children = res.filter(v => v.parent_id == parentId);
        children.forEach(child => {
          child.name = (parentName ? parentName + ' -> ' : '') + child.name;
          resArr.push(child);
        });
        console.log("resArr..:::", resArr);
        children.forEach(child => {
          findChildren(child.category_id, child.name);
        });
      }
      findChildren(parent_id,null);
      this.resource = resArr;



      console.log("Resource..:::", this.resource);
    })

  }
  setstep(step) {
    this.step = step;
  }
 subCategory(id){
this.subcategory=id;
 }

  form(category_id) {
    console.log('hi', category_id);
    if(category_id==2 ||category_id==3 ){
      console.log("form controls",this.f['videourl']);
      console.log("form controls",this.f['url']);
      this.enablevideoUploadUrl = false;
      // this.f['select_category'].setValidators([Validators.required]);
      // this.f['url'].clearValidators();
      // this.f['url'].updateValueAndValidity()
    }else if(category_id!=''){
      this.enablevideoUploadUrl = true;
      // this.f['select_category'].setValidators([Validators.required]);
      // this.f['url'].setValidators([Validators.required]);
      // this.f['select_category'].clearValidators();
      // this.f['select_category'].updateValueAndValidity()
    }
    this.getResource(category_id);
    // this.setstep;
  }
  category_click(category_id) {
    console.log('hi', category_id);
  }

  myForm = new FormGroup({
    title: new FormControl('', Validators.required),
    url: new FormControl('', Validators.required),
    videourl: new FormControl(''),
    paymentType: new FormControl(''),
    amount: new FormControl(''),
    amountfree: new FormControl(''),
    judgement_category_value: new FormControl(''),
    bare_acts_category_value: new FormControl(''),
    legal_procedure_category_value: new FormControl(''),
   
    // tags: new FormControl('', Validators.required), 
    // description: new FormControl('', Validators.required),
    

  });
  myForm1 = new FormGroup({

    tags: new FormControl('', Validators.required),
    url: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    select_category: new FormControl(''),
    select_sub_category_id: new FormControl('', Validators.required),
    sub_category_name:new FormControl(''),
    sub_sub_category_name:new FormControl(''),

  });
  get f() {
    return this.myForm.controls;
  }
  get f1() {
    return this.myForm1.controls;
  }
  registration(e) {
    console.log(e.target.value);
  }

  onFileChange(event)
   {   
    this.url=event.target.files[0];  
    // var img_url= event.target.files[0]['name'];   
    // var extension = img_url.substr(img_url.lastIndexOf('.'));  
    // if ((extension.toLowerCase() == ".pdf") || (extension.toLowerCase() == ".doc") || (extension.toLowerCase() == ".docx"))
    // {
    //   this.url=event.target.files[0];         
    // }
    // else
    // {
    //   alert("upload pdf, doc and docx format only..!");
    //   this.url="empty";        
    // }
    
  }
  submitted = false;
  submit() {
    this.submitted = true;
    console.log("this.myForm.valid",this.myForm);
    if (this.myForm.valid) {

      const formData = new FormData();
      // formData.append("url", this.url);
      // formData.append('title', this.myForm.get('title').value);
      // formData.append("url", this.myForm.get('url').value);
      // formData.append("description", this.myForm.get('description').value);
      // formData.append("tags", this.myForm.get('tags').value);
      // formData.append("select_category", this.myForm.get('select_category').value);

      // this.resourcecenterservice.create(formData).subscribe((res: Resource[]) => {
      //   this.resource = res;

      // })
      this.step = 3;
    }

  }
  bare_acts_submit()
  {
    this.submitted = true;
    if (this.myForm.valid) {
       this.step = 7;
    }
  }
  judgement_submit()
  {
    this.submitted = true;
    if (this.myForm.valid) {
    this.step = 8;
    }
  }
  legal_procedure_submit()
  {  
    this.submitted = true;
    if (this.myForm.valid) {
   this.step = 6;
    }
  }
  legal_format_submit()
  {
    this.submitted = true;
    if (this.myForm.valid) {
    this.step =11;
    }
  }
  submitted1 = false;
  submit1() {
    this.submitted1 = true;
    // if (this.myForm1.valid) {
       //console.log('amount is',this.myForm.get('paymentType').value)
      const formData = new FormData();
      formData.append('category_id', '3');
      formData.append('rc_title', this.myForm.get('title').value);
      formData.append("rc_document_url", this.url);
      formData.append('videourl', this.myForm.get('videourl').value);
      formData.append('rc_paymentType', this.myForm.get('paymentType').value);
      formData.append("rc_description", this.myForm1.get('description').value);     
      formData.append("rc_tags", this.myForm1.get('tags').value);   
      if(this.subcategory == 2)
      {
        formData.append("rc_category_id",this.subcategory); 
        let category_name="judgement" ;      
        formData.append("rc_category_name",category_name);
      }
      if(this.subcategory == 3)
      {
        formData.append("rc_category_id",this.subcategory); 
        let category_name="Bare Acts" ;      
        formData.append("rc_category_name",category_name);
      }   
     if(this.subcategory == 1)
      {
        let category_name="Legal Procedure ";       
         // find subcategory name
         let rc_subcategory_id_data=this.myForm1.get('select_sub_category_id').value;
         var splitted = rc_subcategory_id_data.split(',');
         var rc_subcategory_id =splitted[0];
         var rc_subcategory_name =splitted[1];
         console.log("concodinate splited -->",splitted);
         console.log("concodinate splited rc_subcategory_id-->",rc_subcategory_id);
         console.log("concodinate splited rc_subcategory_name-->",rc_subcategory_name);        
        //  this.resourcecenterservice.find_sub_category_name(rc_subcategory_id).subscribe((res: any) => {
        //  this.legal_procedure_sub_category_name = res.message[0]['name']; 
        // })     
        //  console.log("Resource centre sub category name list..:::",  this.legal_procedure_sub_category_name);
        formData.append("rc_category_id",this.subcategory); 
        formData.append("rc_category_name",category_name);
        formData.append("rc_subcategory_id", rc_subcategory_id);       
        formData.append("rc_subcategory_name",rc_subcategory_name); 
     
        console.log('sub category name', this.myForm1.get('select_sub_category_id').value);  
       

      }
    if(this.subcategory == 4)
      {
        let category_name="Legal Format";       
         // find subcategory name
         let rc_subcategory_id_data=this.myForm1.get('sub_category_name').value;
         console.log("legal format sub categoryid data",rc_subcategory_id_data);
         var splitted = rc_subcategory_id_data.split(',');
         var rc_subcategory_id =splitted[0];
         var rc_subcategory_name =splitted[1];
        //  console.log("concodinate splited -->",splitted);
        //  console.log("concodinate splited rc_subcategory_id-->",rc_subcategory_id);
        //  console.log("concodinate splited rc_subcategory_name-->",rc_subcategory_name);      
         // find sub_sub_category name
         let rc_sub_sub_category_id_data=this.myForm1.get('sub_sub_category_name').value;
         console.log("legal format sub sub category id data",rc_sub_sub_category_id_data);
         var sub_category_splitted = rc_sub_sub_category_id_data.split(',');
         var rc_sub_sub_category_id =sub_category_splitted[0];
         var rc_sub_sub_category_name =sub_category_splitted[1];
        //  console.log("concodinate splited -->",sub_category_splitted);
        //  console.log("concodinate splited rc_sub_sub_category_id-->",rc_sub_sub_category_id);
        //  console.log("concodinate splited rc_sub_sub_category_name-->",rc_sub_sub_category_name); 
        formData.append("rc_category_id",this.subcategory); 
        formData.append("rc_category_name",category_name);
        formData.append("rc_subcategory_id", rc_subcategory_id);       
        formData.append("rc_subcategory_name",rc_subcategory_name);  
        formData.append("rc_sub_sub_category_id", rc_sub_sub_category_id);       
        formData.append("rc_sub_sub_category_name",rc_sub_sub_category_name);
      }
     
        //formData.append('rc_price', this.myForm.get('amount').value);
        if(this.myForm.get('paymentType').value == 1){
          formData.append('rc_price', this.myForm.get('amount').value);
        }else{
          formData.append('rc_price', this.myForm.get('amountfree').value);
        }
      
       
      this.resourcecenterservice.create(formData).subscribe((res: any) => {
      this.resource = res;     
      })
      // this.router.navigateByUrl('/resource-centre');
    // }
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  get_legal_procedure_sub_category_list() {
  
    this.resourcecenterservice.legal_procedure_sub_category_list().subscribe((res: any) => {
      this.legal_procedure_sub_category_list = res.message;
  
      console.log("Resource..:::", this.resource);
    })
  }

  get_legal_format_sub_category_list() {
  
    this.resourcecenterservice.legal_format_sub_category_list().subscribe((res: any) => {
      this.legal_format_sub_category_list = res.message;
  
      console.log("Resource..:::", this.resource);
    })
  }
  onChange(event)
  {  
    this.selectedType = event.target.value;
    var splitted = event.target.value.split(',');
    var rc_subcategory_id =splitted[0];   
    this.resourcecenterservice.legal_format_sub_sub_category_list(rc_subcategory_id).subscribe((res: any) => {
    this.legal_format_sub_sub_category_list = res.message;
  
      console.log("legal_format_sub_sub_category_list..:::", this.legal_format_sub_sub_category_list);
      
    })
  }
  onChange1(event)
  {   
    this.selectedType = event.target.value;
  }
  
  
}