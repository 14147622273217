<div class="container">
<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
  <li class="nav-item dropdown">

    <div class="dropdown-menu">
      <a class="dropdown-item" id="resource-center-tab" data-toggle="pill" href="#profile" role="tab"
        aria-controls="resource-center" aria-selected="true">Connections</a>

      <!-- <a class="dropdown-item" id="legal-formats-tab" data-toggle="pill"
                href="#new-views" role="tab" aria-controls="legal-formats"
                aria-selected="false">New views</a> -->
      <!-- <a class="dropdown-item" id="legal-notices-tab" data-toggle="pill"
                href="#new-connections" role="tab" aria-controls="legal-notices"
                aria-selected="false"> Connections</a> -->
      <a class="dropdown-item" id="legal-research-tab" data-toggle="pill" href="#pending-connections" role="tab"
        aria-controls="legal-research" aria-selected="false">Pending
        Connection</a>

    </div>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link active" id="resource-center-tab" data-toggle="pill" href="#profile" role="tab"
      aria-controls="resource-center" aria-selected="true">Connections</a>
    
  </li>
 
  <li class="nav-item" role="presentation">
    <a class="nav-link" id="legal-research-tab" data-toggle="pill" href="#pending-connections" role="tab"
      aria-controls="legal-research" aria-selected="false">Pending Connection</a>
  </li>
</ul>


<div class="tab-content p-4" id="pills-tabContent">
  <article class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="probile-tab">
    <!-- <ul>
      <li *ngFor="let getlist of data">
        <div class="img-box">
          <a href="javascript:void(0);" [routerLink]="['/'+getlist.username]">
            <img src="{{getlist.profile_picture}}" style="width:60px; height: 60px; border-radius:50%">
          </a>
        </div>
        <strong> {{getlist.username}}</strong>

        <button style="margin: 5px; background-color: #28a745; border: 1px solid #28a745!important;" type="button"
          class="btn btn-primary" #connection>
          <span class="button-text"><i class="fa fa-fw fa-hourglass-half"></i> Connected </span>
        </button>


      </li>
    </ul> -->
    
    <div class="row">
      <div class="col-lg-12">
        <table class="table table-bordered text-center">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let getlist of data"> 
              <td>
                <div class="img-box">
                  <a href="javascript:void(0);" [routerLink]="['/'+getlist.username]">
                    <img src="{{getlist.profile_picture}}" style="width:60px; height: 60px; border-radius:50%">
                  </a>
                </div>
              </td>
              <td><strong> {{getlist.username}}</strong></td>
              <td><strong> {{getlist.email}}</strong></td>
              <td><strong>
                <button class="btn btn-sm btn btn-danger" 
                (click)="removeconnection(getlist.peer_id)">Remove</button></strong></td>
            </tr>
            
          </tbody>
        </table>
      </div>
    </div>
  </article>
 
  <article class="tab-pane fade" id="pending-connections" role="tabpanel" aria-labelledby="pending-connections">
    <!-- <ul>
      <li *ngFor="let putlist of list;let i = index">
        <div class="img-box">
          <div class="pull-left">
          <a href="javascript:void(0);" [routerLink]="['/'+putlist.username]">
            <img src="{{putlist.profile_picture}}" style="width:60px; height: 60px; border-radius:50%">
          </a>
        </div>
          <h6> <a  [routerLink]="['/'+putlist.username]">{{putlist.name}}</a></h6>
          <p> {{putlist.email}}</p>
        </div>
        <button class="btn btn-sm btn btn-success"
        style=" position: absolute;right: 100px;display: inline-block;"(click)="accept(putlist.peer_id,i)">Accept</button>

        <button class="btn btn-sm btn btn-danger" 
        style=" position: absolute;right: 15px;display: inline-block;"(click)="openreject(putlist.peer_id,putlist.name,i)">Reject</button>
      </li>
    </ul> -->
    <div class="row">
      <div class="col-lg-12">
        <table class="table table-bordered text-center">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let putlist of list;let i = index"> 
              <td>
                <div class="img-box">
                  
                  <a href="javascript:void(0);" [routerLink]="['/'+putlist.username]">
                    <img src="{{putlist.profile_picture}}" style="width:60px; height: 60px; border-radius:50%">
                  </a>
                  
                </div>
              </td>
              <td><a  [routerLink]="['/'+putlist.username]">{{putlist.name}}</a></td>
              <td>{{putlist.email}}</td>
              <td><button class="btn btn-sm btn btn-success "
                 (click)="accept(putlist.peer_id,i)">Accept</button>&nbsp;
        
                <button class="btn btn-sm btn btn-danger" 
                (click)="openreject(putlist.peer_id,putlist.name,i)">Reject</button></td>
            </tr>
            
          </tbody>
        </table>
      </div>
    </div>
    

  
      <div class="modal fade" id="reject" tabindex="-1" aria-labelledby="findLawer" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 28%;">
            <div class="modal-content rounded-0">
    
                <button type="button" class="close close-model" data-dismiss="modal" aria-label="Close" #closedeletearea>
                    <span aria-hidden="true">&times;</span>
                </button>
    
                <div class="modal-body popup-body">
                    <p>   {{name}}  connection Request Reject</p>
    
    
                    <form class="mt-28">
                      <button type="button" class="btn btn-outline-dark" (click)="reject()">OK</button>
    
                        <!-- <span> <button type="submit" class="d-block btn btn-primary "
                                (click)="deletearea()">Okay</button>
                            <button type="submit" class="d-block btn btn-primary " (click)="cancel()">Cancel</button>
    
                        </span> -->
                    </form>
    
    
    
                </div>
    
    
    
    
            </div>
        </div>
    </div>
  
  
  </article>
</div>
</div>
<span #rejectmodel data-toggle="modal" data-backdrop="static" data-target="#reject" style="display: none;"></span>