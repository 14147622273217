import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Register } from '../modals/regsiter';
import { RegisterService } from './register.service';
import {NgxCaptchaModule,ReCaptcha2Component} from 'ngx-captcha';
import { resetFakeAsyncZone } from '@angular/core/testing';
// ../modals/register
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  userInfo: any;
  recaptcha:any;
  token :any;
 loading = false;
  loginStatus:boolean =false;
  message:any;
  // @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('openRegisterModal') registerModal:ElementRef; 
  @ViewChild('closeRegisterModal') closeRegisterModal: ElementRef;
  @Output() registerEvent = new EventEmitter<string>();
  roles: any;
  captcharesponse: string;
  

  constructor(public api: RegisterService) { 

    this.userInfo = new Register();
  }

  ngOnInit(): void {
    this.getrole();
  }

 
  openModal() {
   
    this.registerModal.nativeElement.click()
  }


postRegister() {
 
  if(!this.userInfo.role){
    this.message = 'Role required!';
    setTimeout(() => {
      this.message = '';
      return;
    }, 2500)
   }else if(!this.userInfo.name){
    this.message = 'Name  required!';
    setTimeout(() => {
      this.message = '';
      return;
    }, 2500)

   }else if(!this.userInfo.email){
    this.message = 'Email  required!';
    setTimeout(() => {
      this.message = '';
      return;
    }, 2500)

   }
   else if(!this.userInfo.contact_num){
    this.message = 'contact number  required!';
    setTimeout(() => {
      this.message = '';
      return;
    }, 2500)

   }
   else if(!this.userInfo.password){
    this.message = 'Password required!';
    setTimeout(() => {
      this.message = '';
      return;
    }, 2500)

   }
   else if(!this.captcharesponse){
    this.message = 'Captcha  required!';
    setTimeout(() => {
      this.message = '';
      return;
    }, 2500)

   }
  //  this.captcharesponse
 
  else

 { 
  this.loading = true; 
  this.api.postdata('register', this.userInfo).subscribe((res: any) => {
   
    this.loading = false;
    if(res.status==200){
      this.message = res.message
      this.reset();
    }else if(res.status==500&&res.message.email){
      this.message = res.message.email
      this.reseterr()
    }else if(res.status==500&&res.message.contact_num){
      this.message = res.message.contact_num
      this.reseterr()
    }else if(res.status==500&&res.message.password){
      this.message = res.message.password
      this.reseterr()
    }
    
   
    this.userInfo = new Register();
    this.loginStatus = true;
   this.token = res.token
   localStorage.setItem('otptoken',this.token)

    
  
    
   
    
 
  
  }, error => {

   
    
  });}
}



reset() {
  setTimeout(() => {
   
    this.registerEvent.next('verify-otp');
    this.closeRegisterModal.nativeElement.click();
    this.loginStatus= false;
   
  }, 2000);
}

reseterr(){
  setTimeout(() => {
   
    
   
    this.loginStatus= false;
    this.message =''
   
  }, 2000);

}
otpSubmit(event){

}

// handleReset(){
//   this.captchaElem.resetCaptcha();
// }
// handleLoad(){
//   this.captchaElem.reloadCaptcha();

// }

// handleExpire(){
//   this.captchaElem.expire
// }

getrole(){
  this.api.getdata('roles').subscribe((res2: any) => {

     this.roles = res2.data
    //  name
    // member_type_id

    
  })

}
resolved(captchaResponse: string) {
  this.captcharesponse = captchaResponse
 
}



}
