import { Component, OnInit,Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { LoginComponent } from '../login/login.component';
import { LoginService } from '../login/login.service';
import { OtpVerifyComponent } from '../otp-verify/otp-verify.component';
import { RegisterComponent } from '../register/register.component';
import { SearchComponent } from '../search/search.component';
import { SearchService } from '../search/search.service';

declare const  $ ;
// import { VerifyOtpComponent } from '../register/verify-otp/verify-otp.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})


export class NavbarComponent implements OnInit {
  token: string;
  notification: any;
  searchtext:any;
searchicon:any;
  post_search: any;
 

  constructor(public router: Router,public api: ApiService,public loginapi: LoginService,private toast: ToastrService,private socialAuthService: SocialAuthService,public searchapi: SearchService) { 
if(localStorage.getItem('USERID')){


 this.token =  localStorage.getItem('USERID')
}
if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
this.refresh();
}
   
  }


  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  @ViewChild(RegisterComponent)registerPre: RegisterComponent;
  @ViewChild(OtpVerifyComponent)otpPre: OtpVerifyComponent;
  @ViewChild(ForgetPasswordComponent)forgotPre: ForgetPasswordComponent;
  // @ViewChild(VerifyOtpComponent)otpPre: VerifyOtpComponent;
  @Output() loginEvent = new EventEmitter<string>();
  // VerifyOtpComponent


  ngOnInit(): void {
    
this.refresh();
this.getnotification()

  }
  
  refresh() {
    if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
    localStorage.clear();
  
    this.loginPrev.openLogin();
    window.location.reload();
    }
  
}
alertModal(event,data){
  this.loginapi.redirectUrl = data

  this.loginPrev.openLogin();
}

  loginModal() {
    // this.toast.success('', 'item added in wishlist', { timeOut: 3000 })
   
    if(this.token){
      this.router.navigate(['/member']);

    }else{
      this.loginPrev.openLogin();
    }

   
     
    

  }
  search(){
   
    this.router.navigate(['/search/'+this.searchicon]);
    // this.post_search(this.searchicon);

  }
  
  onKeyup($event){
    this.searchicon=$event;
  }

  loginSubmit(event:any) {
   
    if (event == 'register') {
       this.registerPre.openModal();
   
    } else if (event == 'forget') {
       this.forgotPre.openModal();
    } else if (event == 'cart') {
      // this.api.emitCart();
    }
  }
    registerSubmit(event:any) {
     
      // this.loginEvent.next('verify-otp');
      if(this.registerPre.loginStatus){
        this.otpPre.openotp();
      }else{
        this.registerPre.openModal();
      }
     
  }
  forgotSubmit(event){
 
    this.forgotPre.openModal();

  }
  openLogin() {
   
    this.loginPrev.openLogin();
  }
otpSubmit(event){

}
openSearch(data){
  
 
    window.location.href = 'search' + '?q=' + data
  
}
logout(){
  localStorage.clear()
  sessionStorage.clear();
  this.socialAuthService.signOut()
  // localStorage.removeItem('USERID')
  // this.router.navigate(['/']);
  // window.location.reload();
  this.router.navigate(['/'])
  .then(() => {
    window.location.reload();
  });

}


getnotification(){
    if(this.api.loggedIn())
 { this.api.getdata('notification').subscribe((res2: any) => {

    this.notification = res2.data
   
   
   

    
  })}

}
  notificationupdate(data){

    const formData = new FormData();

   formData.append('id', data.id);
  //  type
  // url

 this.api.postdata('notificationRead', formData).subscribe((res: any) => {
   
   this.router.navigate(['/'+res.data.type+'/'+res.data.url+'/view']);


 })
  }

  
}





