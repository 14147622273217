import { Component, OnInit, Output,EventEmitter,ElementRef,ViewChild } from '@angular/core';
import { FormBuilder,FormGroup,FormControl,Validators,ValidationErrors} from '@angular/forms';

import { ApiService } from '../api.service';
import { LoginComponent } from '../login/login.component';
import { MemberService } from '../member/member.service';
import { NeedALawyerService } from './need-a-lawyer.service';
import { Queryboard } from '../modals/queryboard';
import { ToastrService } from 'ngx-toastr';
import { Editor } from 'ngx-editor';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-need-a-lawyer',
  templateUrl: './need-a-lawyer.component.html',
  styleUrls: ['./need-a-lawyer.component.css']
})
export class NeedALawyerComponent implements OnInit {
  editor: Editor;
  token: string;
  listcaseInfo:any;
  queryboard: Queryboard;
  findAlawyer = true;
  area: any;
  keyword = 'name';
  submitted = false;
  message: string;
  liketoquotefee = false;
  profiledata:any;
  email:any;
  username:any;
  contact_no:any;
  listofsaveCase:any;
  member_id:any; 
  modelOpen:boolean = false;
  registerForm:boolean = true;
  lawyer_opinion:any;
  city_list:any;
  textarea_status:number=0;
  query_board_user_register_status:any;
  closeResult: string;
  closeModal: string;
  modalReference:any;
  modalReference1:any;
  modalReference3:any;
  queryBoardList:any;
  lawyer_list:any;
  slideConfig = { "slidesToShow": 3, "slidesToScroll": 1 };
  @ViewChild('openReplyposterModal') openReplyposterModal: ElementRef;
  @ViewChild('closeReplyposter') closeReplyposter: ElementRef;
  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  @ViewChild('openLoginModal')loginModal: ElementRef; 
  @Output() loginEvent = new EventEmitter<string>();

  constructor(public needALayerapi: NeedALawyerService,public memberapi: MemberService,public api: ApiService, private toast: ToastrService,private modalService: NgbModal) {
    if(localStorage.getItem('USERID')){

      this.token =  localStorage.getItem('USERID');
      
      this.memberapi.getdata('profile').subscribe((res2: any) => {
        this.profiledata = res2.data;    
        this.member_id = this.profiledata.member_id;    
        this.email = this.profiledata.email;
        this.contact_no = this.profiledata.contact_num;
        this.username = this.profiledata.full_name;
        console.log(this.profiledata,"this.profiledata")
        
      })
     }
     if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
     this.refresh();
     }
  }

  ngOnInit(): void {
    this.getarea();
    this.getlayercase();
    this.editor = new Editor();
    this.get_query_board_list();  
    this.get_lawyer_opinion();
    this.get_city_list();
    this.find_lawyer_form_submit();
    this.loginform();
  }

  // myform1
  myForm1 = new FormGroup({
    name: new FormControl('',Validators.required),
    contact_no: new FormControl('',Validators.required),   
    email: new FormControl('',[Validators.email,Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    city: new FormControl('',Validators.required),    
    country: new FormControl('',Validators.required),
   });

  // myform2
  myForm2 = new FormGroup({     
    otp_value: new FormControl(''),   
   });

// myform3
  myForm3 = new FormGroup({
    opinion: new FormControl(''),
  city_name: new FormControl(''),
  query: new FormControl(''),    
  check_box: new FormControl(''),
  areaofgrievance: new FormControl(''),
  
 });

NeedALawyermyForm = new FormGroup({
  keywords: new FormControl('',),
  location: new FormControl(''),
  areaofExpertise: new FormControl(''),
  fromdate: new FormControl(''),
  todate: new FormControl(''),
  casetype: new FormControl(''),
  });

  // get f() {
  //   return this.NeedALawyermyForm.controls;

  // }

    
  getlayercase() {

    let obj = {
      searchcriteria: "all",
    }
    console.log(obj, "obj")
    this.needALayerapi.postdata('queryBoardList', obj).subscribe((res2: any) => {
      
      this.listcaseInfo = res2.profile
      console.log(this.listcaseInfo, "this.listcaseInfo")
    })
  }

  getarea() {
    this.needALayerapi.getdata('practicearea').subscribe((res2: any) => {
      this.area = res2.data
      console.log(this.area)
    })
  }

  refresh() {
    if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
      localStorage.clear();
      window.location.reload();
    }
  }

  
  click(){
    if(this.token){
      // this.router.navigate(['/news/add']);

    }else{
      this.loginPrev.openLogin();
      // this.router.navigate(['/login']);

    }
  }
  
  openLogin() {
    this.loginModal.nativeElement.click()
  }
  
  openfindCase() {
    if(this.token){
      return true;
    }else{
      this.loginPrev.openLogin();
      
    }
  }
  
  loginSubmit(event:any) {
   
  }

  public setValidation(e: any) {
    const val = e.target.value;
    this.NeedALawyermyForm.controls['casetype'].setValue(e.target.value, {
      onlySelf: true
    })
  }

  submit() {
    this.submitted = true;
    console.log('hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii');
    console.log(this.NeedALawyermyForm.get("fromdate").value);
    if (this.NeedALawyermyForm.valid) {
      this.dateformatter(this.NeedALawyermyForm.get("fromdate").value,"fromdate");
      this.dateformatter(this.NeedALawyermyForm.get("todate").value,"todate");
      
    console.log(this.NeedALawyermyForm);
      this.needALayerapi.postdata('queryBoardList',this.NeedALawyermyForm.value).subscribe((res: any) => {
        this.listcaseInfo = res.profile;        
        this.NeedALawyermyForm.reset();
      });
    }
  }
  dateformatter(val:any,type:any) {
    if(val){
      const dateformat = new Date(val);
      var  datetimestamp=dateformat.getTime()/1000;
      this.NeedALawyermyForm.controls[type].setValue(datetimestamp);
      
    }
  }

  // reply to this poster
  
  ReplyPoster = new FormGroup({
    topic_id: new FormControl(''),
    answer: new FormControl('',Validators.required),
    member_id: new FormControl(''),
    case_quote_type: new FormControl(''),
    case_quote_fee: new FormControl(''),
    casetype: new FormControl(''),
    });

    get posterForm() {
      return this.ReplyPoster.controls;
  
    }
    loginform(){
      this.memberapi.getdata('profile').subscribe((res2: any) => {
        this.profiledata = res2.data;    
        this.member_id = this.profiledata.member_id;    
        this.email = this.profiledata.email;
        this.contact_no = this.profiledata.contact_num;
        this.username = this.profiledata.full_name; 
        
      })
    }

  openreplyposter(topic_id:any) {  

 
    if(this.token && this.member_id){
      this.message='';
      this.ReplyPoster.controls['topic_id'].setValue(topic_id, {
        onlySelf: true
      })
      this.ReplyPoster.controls['member_id'].setValue(this.member_id, {
        onlySelf: true
      })
      this.openReplyposterModal.nativeElement.click()
    }else{
      this.loginPrev.openLogin();
    }
  }

  quoteFee(event:any){
    if(event.target.checked){
      this.ReplyPoster.controls['case_quote_type'].setValue("1", {
        onlySelf: true
      })
      this.ReplyPoster.get('case_quote_fee').setValidators(Validators.required);
      this.ReplyPoster.get('case_quote_fee').updateValueAndValidity();
      this.liketoquotefee = true;
    }else{
      this.ReplyPoster.controls['case_quote_type'].setValue("", {
        onlySelf: true
      })  
      this.ReplyPoster.get('case_quote_fee').clearValidators();  
      this.ReplyPoster.get('case_quote_fee').updateValueAndValidity();
      this.liketoquotefee = false;
    }
  }

  onSubmit() {
   
    console.log(this.ReplyPoster);
    this.ReplyPoster.markAllAsTouched();
      if (this.ReplyPoster.valid) {
        console.log(this.ReplyPoster);
        this.needALayerapi.replytoPoster('replytoPoster', this.ReplyPoster.value).subscribe((res2: any) => {
          // status
          console.log(res2.status);
          if (res2.status=="200") {
            setTimeout(() => {
              this.ReplyPoster.reset();
              this.closeReplyposter.nativeElement.click();
              this.liketoquotefee = false;
              this.toast.success('Notification successfully send to client');
            }, 10);
           
          }
          else {
            this.message = " Some Thing went Wrong"
          }
          window.location.reload();
        })
      }
  }
  //close popup 
  posterReset(){
    this.ReplyPoster.reset();
  }
  
  // Save Case
  
  saveCase(topic_id:any) {
    if(this.token){
      this.listofsaveCase = {
        "topic_id" :topic_id,
        "member_id" :this.member_id
      }
      this.needALayerapi.replytoPoster('saveCase', this.listofsaveCase).subscribe((res2: any) => {
        // status
        console.log(res2.status);
        if (res2.status=="200") {
          setTimeout(() => {
            this.ReplyPoster.reset();
            this.closeReplyposter.nativeElement.click();
            this.liketoquotefee = false;
            this.toast.success('Notification successfully send to client');
          }, 2000);
        } else {
          this.message = " Some Thing went Wrong"
        }

      })
        // this.openReplyposterModal.nativeElement.click()
    }else{
      this.loginPrev.openLogin();
    }
  }

  get f(){
    return this.myForm1.controls;
  }  
 
query_form_submit(content)
  {   
      this.submitted = true;   
      if(this.myForm1.get('name').value!="" && this.myForm1.get('city').value!="" && this.myForm1.get('contact_no').value!="" && this.myForm1.get('email').value!="")
      {   
              const formData = new FormData();
              formData.append('name', this.myForm1.get('name').value);
              formData.append('city', this.myForm1.get('city').value);    
              formData.append("contact_num", this.myForm1.get('contact_no').value);
              formData.append("email", this.myForm1.get('email').value);       
              this.needALayerapi.query_board_user_register(formData).subscribe((res: any) => 
              {    

                this.modalReference= this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
                this.modalReference.result.then((res) => {
                   this.closeModal = `Closed with: ${res}`;                 
                 
                 }, (res) => {
                   this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
                  
                 });
                 this.modalReference1.close();           
                                                      
              });     
        
            }
            
      
    }
  
  get otp()
  {
    return this.myForm2.controls;
  }  
 

  match_otp_form_submit()
  {
    this.submitted = true;  
     if(this.myForm2.get('otp_value').value!="" && this.myForm1.get('email').value!="")
    {
       let otp_value;
       let email;
       otp_value=this.myForm2.get('otp_value').value;
       email=this.myForm1.get('email').value;
       const formData = new FormData();
       formData.append("email", email);
       formData.append("otp", otp_value);
       this.needALayerapi.match_otp(formData).subscribe((res: any) => 
       {     
                           
        if (res.status=="200") {
          setTimeout(() => {          
            this.toast.success('OTP matched successfully..!');
          }, 100);
          this.modalReference.close();       
          let textArea2 =(document.getElementById('textarea2') as HTMLTextAreaElement)
          textArea2.style.display ='none'
          let textArea1 =(document.getElementById('textarea1') as HTMLTextAreaElement)
          textArea1.style.display ='block'
         
        } 
        else 
        {
          this.toast.error('Invalid OTP ..!');     
        }
       });     
    }          
  }

resend()
  {    
    const formData = new FormData();
    formData.append("email",this.myForm1.get('email').value);
    this.needALayerapi.resend_otp(formData).subscribe((res: any) => 
    { 
     
      if (res.status=="200") {
        setTimeout(() => {          
          this.toast.success('OTP Sent your mail id..!');
        }, 2000);
      } 
     });  
}
 
find_lawyer_form_submit()
{
var areaofgrievance_splitted= this.myForm3.get('areaofgrievance').value.split(',');
var areaofgrievance_splitted_id =areaofgrievance_splitted[0];
var areaofgrievance_splitted_name =areaofgrievance_splitted[1];
var splitted = this.myForm3.get('city_name').value.split(',');
var city_id =splitted[0];
var state_id =splitted[1];
var country_id =splitted[2];
const formData = new FormData();


  if(this.myForm3.get('check_box').value)
  {
              if(this.myForm3.get('query').value!="")
          {
                formData.append("your_name", this.myForm1.get('name').value);
                formData.append("your_email", this.myForm1.get('email').value);
                formData.append("mobile", this.myForm1.get('contact_no').value);
                formData.append("legal_topic", areaofgrievance_splitted_id);
                formData.append("topic", areaofgrievance_splitted_name);
                formData.append("description", this.myForm3.get('query').value);
                formData.append("slug", areaofgrievance_splitted_name);
                formData.append("country_id", country_id);
                formData.append("state_id",  state_id);
                formData.append("city_id", city_id);
                formData.append("doneed", this.myForm3.get('opinion').value);
                this.needALayerapi.post_query(formData).subscribe((res: any) => 
                {     
                   if (res.status=="200") 
                    {
                      setTimeout(() => {          
                        this.toast.success('Query Posted in Query Board..!');
                      }, 100);    
                      this.myForm3.reset();
                      
                      
                    } 
                    else 
                    {
                      this.toast.error('Something went wrong..!');     
                    }
                });   
              }
              else
              {
                this.toast.error('Please Fill Your Query..!');   
              }
    }

    formData.append("practice_area", areaofgrievance_splitted_id);  
    formData.append("location", city_id);
    this.needALayerapi.find_a_lawyer(formData).subscribe((res4: any) => 
    { 

    this.lawyer_list=res4.profile;
   
    });   
this.modalReference3.close();  
this.get_query_board_list();   
  // window.location.reload();



}


triggerModal(content){
  this.modalReference1= this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  this.modalReference1.result.then((res) => {
     this.closeModal = `Closed with: ${res}`;    
   }, (res) => {
     this.closeModal = `Dismissed ${this.getDismissReason(res)}`;     
   });   
 }
 

 trigger_find_a_lawyer_Modal(content){

  this.modalReference3= this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  this.modalReference3.result.then((res) => {
     this.closeModal = `Closed with: ${res}`;    
   }, (res) => {
     this.closeModal = `Dismissed ${this.getDismissReason(res)}`;     
   });   
 }


open(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

 private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }


  get_lawyer_opinion()
  {
    this.needALayerapi.lawyer_opinion().subscribe((res: any) => 
    {
        this.lawyer_opinion=res;
    });
  }
  
  get_city_list()
  {
    this.needALayerapi.city_list().subscribe((res: any) => 
    {
        this.city_list=res;
     

    });
  }


  get_query_board_list()
  {
    this.needALayerapi.query_board_list().subscribe((res: any) => 
    {
        this.queryBoardList=res.profile;
      

    });
  }

 
 
}
