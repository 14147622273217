import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JobCentreRoutingModule } from './job-centre-routing.module';
import { JobCentreComponent } from './job-centre.component';


@NgModule({
  declarations: [JobCentreComponent],
  imports: [
    CommonModule,
    JobCentreRoutingModule
  ]
})
export class JobCentreModule { }
