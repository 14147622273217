import { Component, OnInit, Input, Output, EventEmitter,ElementRef,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommentService } from './comment.service';

import { ApiService } from '../api.service';
import { MemberService } from '../member/member.service'; 
@Component({
  selector: 'single-comment-form',
  templateUrl: './comment.component.html',
  //   styleUrls: ['./view.component.css']
})
export class CommentComponent implements OnInit {  
  closeResult: string;
  userInfo: any;
 
  @Input()
  postId2: number;

  @Input()
  categoryId: number;

  @Input()
  parentId: number;

  @Input()
  groupId: number;

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter();

  constructor(private route: ActivatedRoute, private router: Router,public memberapi: MemberService,public mainapi: ApiService,
    public commentService: CommentService,) {
     
     }
    
  ngOnInit(): void {

  }

  commentFormGroup = new FormGroup({
    name: new FormControl('',Validators.required),
    email: new FormControl('',Validators.required),
    phone_number: new FormControl(''),
    comment: new FormControl(''),

  });

  get f() {
    return this.commentFormGroup.controls;
  }

  commentSubmit() {
    const formData = new FormData();

    formData.append("name", this.commentFormGroup.get('name').value);
    formData.append('email', this.commentFormGroup.get('email').value);
    formData.append("phone_number", this.commentFormGroup.get('phone_number').value);
    formData.append('comment', this.commentFormGroup.get('comment').value);
    formData.append('post_id', this.postId2 + "");
    formData.append('parent_id', this.parentId + '');
    formData.append('group_id', this.groupId + '');
    formData.append('category_id', this.categoryId + '');
    console.log("formData", this.postId2,this.categoryId);


    this.commentService.create(formData).subscribe((res: any) => {
      console.log("Comment Added..:::", res);
      this.onSubmit.emit([]);
      this.commentFormGroup.reset();

    });
  }
  click(){
    
  }

}
