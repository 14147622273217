<!-- <div class="container">
    <h3>Contact Us</h3>
<form [formGroup]="contactForm" (ngSubmit)="contactus()" novalidate>
    <div class="form-group">
    <label for="firstname"> Name </label>
    <br>
    <input type="text" placeholder="name" formControName="name">
    <br>
    <span *ngIf="name && name.invalid && name.touched">this input field is not valid</span>
    <br>
    <label for="firstname">Mobile</label>
    <br>
    <input type="text" placeholder="mobile" formControName="mobile">
    <br>
    <span *ngIf="mobile && mobile.valid && mobile.touched">this input field is not valid</span>
    <br>
    <label for="firstname">Email </label>
    <br>
    <input type="text" placeholder="email" formControName="email">
    <br>
    <span *ngIf="email && email.valid && email.touched">this input field is not valid</span>
    <br>
    <label for="firstname">Message </label>
    <br>
    <textarea placeholder="message" formControName="message"> </textarea>
    <br>
    <span *ngIf="message && message.valid && message.touched">this input field is not valid</span>
    <br>
    <button type="submit" [disabled]="!contactForm.invalid">Submit</button>
    </div>
</form>
</div> -->



<div class="container" style="margin-top: 7em;">
  <h3 class="fw-bold">Contact Us</h3>
  <hr style="height:2px;border-width:0;color:gray;background-color:gray">

  <form class="form-horizontal" [formGroup]="contactForm" (ngSubmit)="onSubmit(contactForm.value)">

    <div class="form-group">
      <label for="name" style="color: rgb(25, 134, 138); font-size: 18px;">Name</label>
      <br>
      <!-- <div *ngIf="contactForm.get('name').invalid && (contactForm.get('name').dirty || contactForm.get('name').touched)"
        style="color:red;font-size: small; ">
        Please enter name
      </div> -->
      <input formControlName="name" placeholder="Enter Name" id="name" name="name" class="form-control" >

      <div *ngIf="(isFormSubmitted && contactForm.controls.name.errors) || (!contactForm.controls.name.pristine && contactForm.controls.name.invalid) || (contactForm.get('name').invalid && (contactForm.get('name').dirty || contactForm.get('name').touched))">
        <div *ngIf="contactForm.controls.name.errors.required" style="color: red ; font-size: small;">Please enter your fullname</div>
 
     </div>

      <br>
    </div>

    <div class="form-group">
      <label for="mobile" style="color: rgb(25, 134, 138); font-size: 18px;">Mobile Number</label>

      <br>
      <!-- <div
        *ngIf="contactForm.get('mobile').invalid && (contactForm.get('mobile').dirty || contactForm.get('mobile').touched)"
        style="color:red; font-size: small;">
        Please enter mobile
      </div> -->
      <input formControlName="mobile" placeholder="Enter Mobile Number" id="mobile" name="mobile" class="form-control">

      <div  *ngIf="(isFormSubmitted && contactForm.controls.mobile.errors) || (!contactForm.controls.mobile.pristine && contactForm.controls.mobile.invalid) || (contactForm.get('mobile').invalid && (contactForm.get('mobile').dirty || contactForm.get('mobile').touched))">
        <div *ngIf="contactForm.controls.mobile.errors.required" style="color: red ; font-size: small;">mobile no. is required</div>
        <div *ngIf="contactForm.controls.mobile.errors.pattern" style="color: red ; font-size: small;">Please enter valid mobile no.</div>

      </div>
      <br>
    </div>


    <div class="form-group">
      <label for="email" style="color: rgb(25, 134, 138); font-size: 18px;">Email</label>
      <br>
      <!-- <div
        *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)"
        style="color:red; font-size: small;">
        Please enter email
      </div> -->
      <input formControlName="email" placeholder="Enter Email" id="email" name="email" class="form-control">
      <div *ngIf="(isFormSubmitted && contactForm.controls.email.errors) || (!contactForm.controls.email.pristine && contactForm.controls.email.invalid) || (contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched))">
        <div *ngIf="contactForm.controls.email.errors.required" style="color: red ; font-size: small;">Email address is required
        </div>
        <div *ngIf="contactForm.controls.email.errors.pattern" style="color: red ; font-size: small;">Please enter a valid email address
        </div>

      </div>
      <br>
    </div>

    <div class="form-group">
      <label for="message" style="color: rgb(25, 134, 138); font-size: 18px;">Message</label>
      <br>
      <!-- <div
        *ngIf="contactForm.get('message').invalid && (contactForm.get('message').dirty || contactForm.get('message').touched)"
        style="color:red; font-size: small;">
        Please enter message
      </div> -->
      <textarea formControlName="message" placeholder="Enter Message" id="message" name="message"
        class="form-control"></textarea>
      <div
        *ngIf="(isFormSubmitted && contactForm.controls.message.errors) || (!contactForm.controls.message.pristine && contactForm.controls.message.invalid) || (contactForm.get('message').invalid && (contactForm.get('message').dirty || contactForm.get('message').touched)) ">
        <div *ngIf="contactForm.controls.message.errors.required" style="color: red ; font-size: small;">Please enter your Comments</div>
      </div>

      <br>
    </div>

    <button type="submit" class="btn btn-outline-info" style="width: 105px;height: 43px;" float-right (ngSubmit)="onSubmit(contactForm.value)">
      <i class="fa fa-paper-plane"></i>
      Submit</button>
    </form>
  <br>
  <br>
  <div class="container">
    <div class="col-lg-12">
      <ul>
        <li>
          <p style="color: rgb(24, 22, 24);">
            <strong>SOOLEGAL TECHNOLOGIES PRIVATE LIMITED</strong>
          </p>
        </li>
        <li>
          <p style="color: rgb(24, 22, 24);">
            <i class="fa fa-map-marker">
            </i>
            205, B4 Safdarjung Enclave,New Delhi - 110029
          </p>
        </li>
        <li>
          <p style="color: rgb(24, 22, 22);">
            <i class="fa fa-comment"></i>
            <a href="mailto:info@soolegal.com">info@soolegal.com</a>
          </p>
        </li>
        <li>
          <p style="color: rgb(24, 22, 22);">
            <i class="fa fa-phone"></i>
            +91 98109 29455 OR +91-8061273900
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>