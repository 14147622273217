    <!-- Start Hero Area -->
    <section class="hero-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 offset-lg-1 col-md-12 col-12">
                    <div class="hero-text text-center">

                        <div class="search-form wow fadeInUp" data-wow-delay=".7s">
                            <form class="d-flex">                               
                                <input style="border: none;" class="form-control me-2" type="text" (keyup)="onKeyup($event.target.value)"
                                    placeholder="Type Your Query" aria-label="Search" >
                                <button style="width: 20%;" type="button"
                                    class="btn btn-outline-primary" [(ngModel)]="data" >search</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Hero Area -->
    <!-- End Breadcrumbs -->

    <!-- Start Pricing Table Area -->
    <section class="pricing-table section1">
        <div class="container" style="padding-top: 23px;">
            <div class="row">
                <div class="col-lg-9">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="breadcrumbs-content">
                                <h3 class="page-title">Search Result</h3>
                            </div>
                        </div>
                        <!-- <form [formGroup]="myForm" (ngSubmit)="doctype()"> -->
                        <div class="col-lg-6 col-md-6 col-12">
                            <div style="text-align: right; width: 192px; float: right;" class="dropdown">
                                <select (change)="doctype($event.target.value)" class="form-select"  aria-label="Default select example">
                                    <option value='' selected>Document Type</option>
                                    <option value="free">Free</option>
                                    <option value="paid">Paid</option>
                                </select>
                            </div>
                            
                        </div>
                        <!-- </form> -->
                    </div>
                    <div style="padding-top: 20px;" class="row rc" > 
                        <div class="col-lg-4 col-md-6 col-12" *ngFor="let r of resource |filter:data" >
                           
                            <!-- Start Single Grid -->
                            <div class="single-grid wow fadeInUp" data-wow-delay=".2s" >
                                <div class="content">
                                    <div style="padding: 15px; border: 1px solid rgb(209, 208, 208);
                                    border-radius: 2%;" class="top-content1">
                                         <!-- *ngIf="r.category_id=='3'?true:false" -->
                                        <div class="user-block">
                                                <div class="image1">
                                                    <!-- <img src="https://friendkit.cssninja.io/assets/img/avatars/dan.jpg" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31"> -->
                                                    <img *ngIf="r.profilepic_url" [src]="getImagePic(r)"
                                                    class="img-fluid rounded-circle width-50">
                                                    <img *ngIf="!r.profilepic_url" src="../../assets/images/profile-user-round.png" alt=""
                                                    class="img-fluid rounded-circle width-50"> 
                                                </div>
                                                <div class="user-info2">
                                                    <a class="usr-nm" href="#">{{r.member_details[0]['username']}}</a>
                                                    <!-- <span class="time">{{r.created_date*1000|date:'yyyy/MM/dd H:m:s'}}</span> -->
                                                    <br>
                                                    <span class="time">{{r.created_at}}</span>
                                                </div>
                                        </div>

                                        <hr>

                                        <h4 class="title rc">
                                            <a [attr.href]="'/resource-centre/'+ r.id + '/resource-docfree'" (click)="rcview()">
                                                {{r.rc_title}}
                                             </a>
                                        </h4>
                                        <p class="update-time" [innerHTML]="r.rc_description" ngx-clamp [ngxClampOptions]="{ clamp: 2}"></p>
                                            
                                   
                                        
                                        <hr>

                                        <div class="social-count">
                                                <div class="likes-count">
                                                    <!-- <a (click)="like(r)" class="quick-actions-icons"><i class="fa fa-heart-o-o" aria-hidden="true"></i></a> -->
                                                    <i class="quick-actions-icons" class="fa fa-heart-o" (click)="click()" (click)="like(r)" *ngIf="!r.liked"></i> 
                                                        <i class="quick-actions-icons" class="fa fa-heart-o" style="color:red"  (click)="like(r)"
                                                            *ngIf="r.liked"></i>
                                                        <span class="count-lk">{{r.like_count}}</span>
                                                    <!-- <span ><i class="far fa-heart inlike"  (click)="like(r)" *ngIf="!r.liked"></i> 
                                                        <i class="far fa-heart like" style="color:red"  (click)="like(r)"
                                                            *ngIf="r.liked"></i>{{r.like_count}}</span> -->
        
                                                </div>

                                                <div class="share-count-rcview">
                                                    <a  class="d-flex align-items-center me-3" style="margin-left: 28px;">
                                                        <i class="fa fa-eye" aria-hidden="true"></i>
                
                                                        <p class="mb-0 doc" style="margin-left: 6px;">0</p>
                                                    </a>
                                                </div>
                                        
                                                <div class="shares-count" style="margin-left: 37px;">
                                                    <a (click)="open(mymodal,r)" class="quick-actions-icons" style="margin-left: -5px;"><i class="fa fa-comment-o" aria-hidden="true"></i></a>
                                                    <!-- <span (click)="open(mymodal,r)" class="pr-1"><i class="far fa-comment-o"></i>
                                                        {{r.comment_count}}</span> -->
                                                    <span class="count-shr"> {{r.comment_count}}</span>
                                                </div>
                                                <div class="comments-count" style="margin-left: 32px;">
                                                    <a (click)="ope(modal)" class="quick-actions-icons"><i class="fa fa-share-alt" aria-hidden="true"></i>
                                                    </a>
                                                    <span class="count-cmnt">5</span>
                                                </div>
                                                <ng-template #modal let-modal>

                                                    <share-buttons [theme]="'material-dark'"
                                                        [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                                                        [show]="15" [url]="getLocation(r.id)" [autoSetMeta]="false">
                                                    </share-buttons>
        
        
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-outline-dark"
                                                            (click)="modal.close('Save click')">Close</button>
                                                    </div>
                                                </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Single Grid -->
                        </div>
                       
                    </div>
                </div>
                <div class="col-lg-3">
                    
                        <div class="sidebar-rc">
                            <h5 style="padding: 1rem; border-bottom:1px solid rgba(0, 0, 0, .125);font-weight: bold;">Category</h5>
                            <a href="resource-centre/resource-view/2" class="list-group-items list-group-item-action">
                                <i class="bi-film"></i> Judgements                               
                            </a>
                            <a href="resource-centre/resource-view/3" class="list-group-items list-group-item-action">
                                <i class="bi-music-note-beamed"></i> Bare Acts                               
                            </a>
                            <a href="resource-centre/resource-view/1" class="list-group-items list-group-item-action">
                                <i class="bi-camera-fill"></i> Legal Procedures                               
                            </a>

                            <a href="resource-centre/resource-view/4" class="list-group-items list-group-item-action">
                                <i class="bi-film"></i> Legal Formats                               
                            </a>
                         
                               <!-- <a href="resource-centre/resource-view/2" class="list-group-items list-group-item-action">
                                <i class="bi-music-note-beamed"></i> Law|Statute|Acts|Update
                               
                            </a> -->
                            <!-- <a href="resource-centre/resource-view/5" class="list-group-items list-group-item-action">
                                <i class="bi-film"></i> 
                                Court Affidavits and Drafts
                               
                            </a>
                            <a href="resource-centre/resource-view/6" class="list-group-items list-group-item-action">
                                <i class="bi-film"></i> 
                                Court Applications &<br> Pleading Drafts
                               
                            </a> -->
                        </div>
                        &nbsp;
                        <style>
                            .listWrapper {
    max-height:683px;
    overflow-y:auto;
}
                        </style>

                        <div class="sidebar-rc" class="listWrapper">
                           
                            <h5 style="padding: 1rem; border-bottom:1px solid rgba(0, 0, 0, .125);font-weight: bold;" >Subcategory</h5>
                            <div *ngFor="let s of all_sub_category_list" >
                            <a [attr.href]="'/resource-centre/resource-viewcategory/' + s.category_id"  class="list-group-items list-group-item-action ">
                                <i class="bi-camera-fill" ></i> {{s.name}}
                               
                            </a>
                        </div>
                            <!-- <a href="#" class="list-group-items list-group-item-action">
                                <i class="bi-music-note-beamed"></i> Arbitration
                               
                            </a>
                            <a href="#" class="list-group-items list-group-item-action">
                                <i class="bi-film"></i> Arrest
                               
                            </a>
                            <a href="#" class="list-group-items list-group-item-action">
                                <i class="bi-film"></i> Aviation Law
                               
                            </a>
                            <a href="#" class="list-group-items list-group-item-action">
                                <i class="bi-film"></i> 
                                Bankruptcy/ Insolvency
                               
                            </a>
                            <a href="#" class="list-group-items list-group-item-action">
                                <i class="bi-film"></i> 
                                Breach of Trust
                               
                            </a> -->
                        </div>
                </div>
            </div>
        </div>
    </section>
    <!--/ End Pricing Table Area -->

    <!-- Start Newsletter Area -->
    <div class="newsletter section">
        <div class="container">
            <div class="inner-content">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="title">
                            <!-- <i class="lni lni-alarm"></i> -->
                            <h2>Get Your Document Published </h2>
                            <!-- <p>We don't send spam so don't worry.</p> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-5">
                        <div style="text-align: right;" class="button" (click)="click()" >
                            <a  (click)="formResource()" class="btn">Click here<span class="dir-part"></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #mymodal let-modal>
                    
                  
        <article>
            <div class="component-box-1 ">
                <div class="user-info-box-main">

                    <single-comment-form comment-form [postId2]="r.id" [categoryId]="3"
                        (onSubmit)="onCommentCreate(r.id)">

                    </single-comment-form>

                    <div class="user-info-box ">
                        <h4>Comments </h4>

                        <section class="user-info-box-inner no-scroll text-left h-0 p-4">

                            <div *ngFor="let c of comments  | paginate
                            : {
                                id: 'pagination2',
                                itemsPerPage: tableSizecmt,
                                currentPage: pagecmt,
                                totalItems: countcmt
                              };
                        let i = index
                      " >

                                <single-comment [comment]="c" [groupId]="c.id"></single-comment>
                                <div style="margin-left:100px">
                                    <single-comment *ngFor="let c1 of c.comments;" [comment]="c1"
                                        [groupId]="c.id" [isReply]="true"
                                        (onSubmit)="onComment(r.id)">
                                    </single-comment>
                                </div>

                        
                            </div>
                        </section>
                        <div class="d-flex justify-content-center">
                            <pagination-controls
                            id="pagination2"
                              previousLabel="Prev"
                              nextLabel="Next"
                              (pageChange)="onTableDataChangecmt($event)"
                            >
                            </pagination-controls>
                          </div>                                 

                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </article>
       
    </ng-template>

  
<app-login (loginEvent)="loginSubmit($event)"></app-login>