<main role="main">
    <section class="ipad-hide phone-hide">
       <div class="container-fluid inner-title">
          <div class="row">
             <div class="col-md-1 pl-0 text-right"><span>NEWS</span></div>
             <div class="col-md-10">Delhi HC directs Singh Brothers to Maintain Status-quo over Assets & Properties  |  Delhi HC directs...</div>
             <div class="col-md-1 pr-0 text-left"><span>NEWS</span></div>
          </div>
       </div>
    </section>
    <seciton class="top-banner">
       <div class="container">
          <div class="row">
             <div class="col-md-10 offset-md-1">
                
                    <div class="component-box-1">
                       <div class="user-info-box-main">
                          <div class="user-info-box">
                             <h4>Your Notifications</h4>
                            <div class="user-info-box-inner calendar-box no-scroll p-4">
                              
  
                                <div class="notificatons-content" *ngFor="let data of listdata" >
  <!-- ../../assets/images/icon_#90278E_9.png -->
                                   <span class="icon"><img src="../../assets/images/icon_#90278E_9.png"></span><span>{{data?.fullname}}</span>
                                   <span class="text-content font-weight-600"><a href="javascript:void(0);" [style.color]="data.status==0?'black':'gray'" (click)="notificationupdate(data)">{{data?.value}} on {{data?.date_time}}</a> </span>
                                  
  
                               
  
                                   </div>
  
  
  
  
                                   <!-- <div class="notificatons-content">
  
                                      <span class="icon"><img src="../../assets/images/icon_#90278E_2.png"></span>
                                      <span class="text-content font-weight-600">4 upcoming meetings in your calendar</span>
     
                                      <span class="right "><a href="#" class="btn btn-primary  w-200">Manage calendar</a></span>
     
                                      </div> -->
  
  
  
  
<!--   
                                      <div class="notificatons-content">
  
                                         <span class="icon"><img src="../../assets/images/icon_#90278E_9.png"></span>
                                         <span class="text-content font-weight-600"> 4 items in your cart will expire on DD/MM/YYYY</span>
        
                                         <span class="right "><a href="#" class="btn btn-primary w-200">Proceed to pay</a></span>
        
                                         </div>
   -->
  
  
  
  
                               </div>
  
  
                          </div>
                       </div>                
                 </div>
             
  
             </div>
           
          </div>
        </div>
      
    </seciton>
  
  
    <section class="partners-main-box my-3">
       <div class="container-fluid p-0">
          <div class="bg-gray-new shadow-none">
             <div class="row justify-content-between align-items-center">
                <div class="col-md-12 col-lg-5 my-2 logo-box ">
                   <img src="images/logo.png" alt="" class="im">   
                   <h4 class="title-1">Partner Services</h4>
                </div>
                <div class="col-md-12 col-lg-7 my-2">
                   <div class="partners owl-carousel owl-theme owl-loaded owl-drag">
                      <div class="owl-stage-outer">
                         <div class="owl-stage" style="transform: translate3d(-537px, 0px, 0px); transition: all 0.25s ease 0s; width: 1255px;">
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                            <div class="owl-item active" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item active" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                            <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                            <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                         </div>
                      </div>
                      <div class="owl-nav disabled"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div>
                      <div class="owl-dots disabled"></div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
  </main>	
  