
<main role="main">
    <section class="ipad-hide phone-hide">
       <div class="container-fluid inner-title">
          <div class="row">
             <div class="col-md-1 pl-0 text-right"><span>NEWS</span></div>
             <div class="col-md-10">Delhi HC directs Singh Brothers to Maintain Status-quo over Assets & Properties  |  Delhi HC directs...</div>
             <div class="col-md-1 pr-0 text-left"><span>NEWS</span></div>
          </div>
       </div>
    </section>       
  <div class="container-fluid">  
    <ul class="nav nav-pills mb-3 d-flex justify-content-between" id="pills-tab" role="tablist">
            <!-- <li class="nav-item">      
               <a class=" nav-link active rounded-0" *ngIf="param=='people'" id="pills-home-tab" data-toggle="pill"(click)="selectsearch('people')" href="#lawyers" role="tab" aria-controls="pills-lawyers" aria-selected="true">Member1</a>
               <a class=" nav-link  rounded-0"*ngIf="param!='people'" id="pills-home-tab" data-toggle="pill"(click)="selectsearch('people')" href="#lawyers" role="tab" aria-controls="pills-lawyers" aria-selected="false">Member</a>
            </li> -->
            <li class="nav-item">
               <a class=" nav-link active rounded-0" *ngIf="param=='content'" id="pills-home-tab" data-toggle="pill"(click)="selectsearch('content')" href="#content" role="tab" aria-controls="pills-lawyers" aria-selected="true">Content</a>
               <a class="nav-link  rounded-0"*ngIf="param!='content'" id="pills-profile-tab" data-toggle="pill" href="#content" role="tab"(click)="selectsearch('content')" aria-controls="pills-profile" aria-selected="false">Content</a>
            </li>
            <li class="nav-item">
               <a class=" nav-link active rounded-0" *ngIf="param=='event'" id="pills-home-tab" data-toggle="pill"(click)="selectsearch('event')" href="#jobs" role="tab" aria-controls="pills-lawyers" aria-selected="true">Event</a>
               <a class="nav-link  rounded-0" *ngIf="param!='event'"id="pills-contact-tab" (click)="selectsearch('event')" data-toggle="pill" href="#jobs" role="tab" aria-controls="pills-jobs" aria-selected="false">Event </a>
            </li>    
            <li class="nav-item">
               <a class=" nav-link active rounded-0" *ngIf="param=='roar'" id="pills-home-tab" data-toggle="pill"(click)="selectsearch('roar')" href="#events" role="tab" aria-controls="pills-lawyers" aria-selected="true">Roar</a>
               <a class="nav-link rounded-0" *ngIf="param!='roar'"id="pills-contact-tab" data-toggle="pill"(click)="selectsearch('roar')" href="#events" role="tab" aria-controls="pills-events" aria-selected="false">Roar</a>
            </li>
            <li class="nav-item">
               <a class=" nav-link active rounded-0" *ngIf="param=='news'" id="pills-home-tab" data-toggle="pill"(click)="selectsearch('news')" href="#news" role="tab" aria-controls="pills-lawyers" aria-selected="true">News</a>
               <a class="nav-link rounded-0" *ngIf="param!='news'"id="pills-contact-tab" data-toggle="pill"(click)="selectsearch('news')" href="#news" role="tab" aria-controls="pills-events" aria-selected="false">News</a>
            </li>
            <!-- <li class="nav-item">      
            <a class=" nav-link active rounded-0" *ngIf="param=='judgement'" id="pills-home-tab" data-toggle="pill"(click)="selectsearch('judgement')" href="#resources" role="tab" aria-controls="pills-lawyers" aria-selected="true">Judgement</a>
            <a class="nav-link  rounded-0" *ngIf="param !='judgement'" id="pills-profile-tab" data-toggle="pill" href="#resources" (click)="selectsearch('judgement')"role="tab" aria-controls="pills-profile" aria-selected="false">Judgement</a>
            </li> -->  
   </ul> 
  
  <hr>
  
<section class="tab-content" id="pills-tabContent">
            <!-- [ngClass]="param=='judgement' ? 'tab-pane fade show active' : 'tab-pane fade'" -->
  
   <!-- <article  [ngClass]="param=='people'||param=='undefined' ? 'tab-pane fade show active' : 'tab-pane fade'" id="lawyers" role="tabpanel" aria-labelledby="pills-home-tab">
       
  Start row
  <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"(scrolled)="onScroll()">
  <div class="row mb-4" *ngFor="let data of searchlist ">   
     <div class="col-md-10 offset-md-1 row">  
  <div class="col-md-4">
  
  
<article class="component-box-1 mb-0">
     <div class="user-info-box-main">
       <div class="user-info-box">
         <h4>Your Profile</h4>
         <div class="user-info-box-inner p-0 no-scroll">
           <div class="user-box">
             <div class="ribbon ribbon-top-right" *ngIf="data.isPrime"><span>Premium</span></div>
             <div class="img-box">
                <a [routerLink]="['/'+data.username]">
                <img *ngIf="data.profile_picture" src="{{data?.profile_picture}}">
                <img *ngIf="!data.profile_picture" src="../../assets/images/profile-user-round.png">
               </a>
            </div>
            <a [routerLink]="['/'+data.username]">
             <div class="name">{{data?.fullname}}</div>
            </a>
             <p class="small">
               {{data?.member_type_name}} <br>
               {{data?.degree_name}}
             </p>
             
  
             <div class="progress-bar-box">
               <div class="progress">
                 <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
               </div>
               <div class="pc-box">{{data?.city_name}} ,{{data?.country_name}}</div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </article>
  
  
  
  
  
  </div>
  
  
  <div class="col-md-8 position-relative">
  
  
  <div class="fields-container position-absolute full-width b-0 ">
  
     <form>
        <div class="row">
           <div class="col-md-6">
            <input type="text" class="form-control" placeholder="BAR Association 1">
          </div> 
          <div class="col-md-6">
            <input type="text" class="form-control" placeholder="BAR Association 2" value="{{data?.barassociation}}"readonly>
          </div>
        </div>
  
  
        <div class="row">
           <div class="col-md-6">
             <input type="text" class="form-control" placeholder="BAR Number" value="{{data?.barassociationNum}}"readonly>
           </div>
           <div class="col-md-6">
            
           </div>
         </div>
  
  
  
         <div class="row">
           <div class="col-md-6" *ngFor="let arr of data.prectiselist">
             <input type="text" class="form-control" placeholder="Area of Expertise" value="{{arr}}"readonly>
           </div>
            <div class="col-md-6">
             <input type="text" class="form-control" placeholder="Area of Expertise">
           </div> 
         </div>
  
  
         
          <div class="row">
           <div class="col-md-6">
             <input type="text" class="form-control" placeholder="Area of Expertise">
           </div>
           <div class="col-md-6">
             <input type="text" class="form-control" placeholder="Area of Expertise">
           </div>
         </div> 
  
  
         
         <div class="row">
           <div class="col">
             <button class="btn btn-primary"><a [routerLink]="['/'+data.username]">View Profile</a></button>
           </div>
           <div class="col text-right">
              <button class="btn btn-primary">Connect</button>
           </div>
         </div>
  
  
  
      </form>
  
  </div>
  
  
  
  </div>
  
  
     </div>
    
  
  </div>
  

  </div>
  
  
   </article> -->
  
  <!-- <article [ngClass]="param=='judgement' ? 'tab-pane fade show active' : 'tab-pane fade'" id="resources" role="tabpanel" aria-labelledby="pills-lawyers-tab">
    <div *ngFor="let data of judgmentlist">
    <h3><a [routerLink]="['/rc/'+data.slug]">{{data?.title}}</a></h3>
    <p><a [routerLink]="['/'+data.username]">{{data?.fullname}}</a><span> {{data?.created_date*1000|date: 'dd/MM/yyyy'}}  {{data?.judge_name}}    {{data?.date_of_judgement}} </span> </p>
    <p>{{data?.description}} &nbsp; <a [routerLink]="['/rc/'+data.slug]"> Read more</a> </p>

    <p>{{data?.country_name}}</p>
    <span class="badge badge-primary">{{data.total_view!=null?data.total_view:'0'}} <i class="fa fa-eye"></i></span>
    <p>{{data?.court_name}}
    <a herf="#" class="btn btn-primary">Learn more...</a>
    </div>
  </article> -->
  
  
<article  [ngClass]="param=='content' ? 'tab-pane fade show active' : 'tab-pane fade'" id="content" role="tabpanel" aria-labelledby="pills-resources-tab">
   <div *ngFor="let r of search_list_data">
      <div class="catd-body" *ngIf="r.category_id=='3'?true:false" >
         <div class="row p-2">
             <div class="col-sm-6">
                 <div class="row">
                     <div class="col-lg-4 col-3">                       
                             <img *ngIf="r.profile_pic_url" src={{r.profile_pic_url}}
                             class="img-fluid rounded-circle width-50">
                             <img *ngIf="!r.profile_pic_url" src="../../assets/images/profile-user-round.png" alt=""
                             class="img-fluid rounded-circle width-50"> 
                     </div>
                     <div class="col-lg-8 col-7 p-0">
                         <!-- <h5 class="m-0">{{r.member_details[0]['username']}}</h5> -->
                         <span class="badge badge-danger">Resource centre</span>                  
                         <p>{{r.created_at}}</p>
                     </div>
                 </div>
             </div>
             <div class="col-sm-6">
                 <i class="ft-more-vertical pull-right"></i>
             </div>
         </div>
         <div class="write-post">
             <div class="col-sm-12 px-2">
                 <h4><a  [attr.href]="'/roar/'+ r.id + '/view'" style="text-decoration:none;color:black"  >{{r.rc_title}}</a></h4>
                 <p [ngClass]="{ 'limitTextHeight': !isReadMore }" [innerHTML]="r.rc_description"> </p>
             </div>
             <a style="padding:10px;" (click)="showText()" style="color:#90278E" >{{ isReadMore ? 'Read Less': 'Read More' }}</a>
             <div class="col-sm-12 px-2 pb-2">
                 <img [src]="getImage(r)" alt="" class="img-fluid">
             </div>
             <hr class="m-0">
             <div class="row p-1">
                 <div class="col-6">
                     <div class="row">
                         <div class="col-4 pr-0">
                             <span (click)="click()"><i class="far fa-heart inlike"  (click)="like(r)" *ngIf="!r.liked"></i> 
                                 <i class="far fa-heart like" style="color:red"  (click)="like(r)"
                                     *ngIf="r.liked"></i>
                                     {{r.like_count}}</span>
                                 <i class="fa fa-eye ccounts" aria-hidden="true"></i>
                                 {{r.view_count}}
                         </div>
                       
                     </div>
                 </div>
                 <div class="col-6">
                     <div class="pull-right">
                         <!-- <span class="pr-1"><i class="far fa-comment-alt"></i> 44</span> -->
                         <span (click)="open(mymodal,r)" class="pr-1"><i class="far fa-comment-alt"></i>
                             {{r.comment_count}}</span>
                         <span (click)="ope(modal)" class="pr-1"><i class="far fa-share-square"></i>
                             </span>
                         <ng-template #modal let-modal>
                             <share-buttons [theme]="'material-dark'"
                                 [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                                 [show]="15" [url]="getLocation(r.id)" [autoSetMeta]="false">
                             </share-buttons>
                             <div class="modal-footer">
                                 <button type="button" class="btn btn-outline-dark"
                                     (click)="modal.close('Save click')">Close</button>
                             </div>
                         </ng-template>
                     </div>
                 </div>
             </div>
         </div>
     </div>       
   </div>     
</article>
  
  
      <article  [ngClass]="param=='event' ? 'tab-pane fade show active' : 'tab-pane fade'" id="jobs" role="tabpanel" aria-labelledby="pills-jobs-tab">
         <div *ngFor="let r of search_list_data">
 <!-- event -->
 <div class="catd-body" *ngIf="r.category_id=='4'?true:false" >
   <div class="row p-2">
       <div class="col-sm-6">
           <div class="row">
               <div class="col-lg-4 col-3">
                   <!-- <img src="../../assets/images/profile-user-round.png" alt=""
                       class="img-fluid rounded-circle width-50"> -->
                       <img *ngIf="r.profile_pic_url" [src]="getImagePic(r)"
                       class="img-fluid rounded-circle width-50">
                       <!-- <img *ngIf="r.profile_pic_url" src={{r.profile_pic_url}} -->                    
                       <img *ngIf="!r.profile_pic_url" src="../../assets/images/profile-user-round.png" alt=""
                       class="img-fluid rounded-circle width-50"> 
               </div>
               <div class="col-lg-8 col-7 p-0">
                   <h5 class="m-0">{{r.username}}</h5>
                   <span class="badge badge-warning">Event</span>
                   
                   <span> <a title="Edit" *ngIf="memberapi.userInfoapi?.member_id==r.event_author_id"
                           class=" btn-xs " [attr.href]="'/event/' + r.id +'/edit'"><i
                               class="fas fa-edit"></i></a></span>
                   <a title="delete" *ngIf="memberapi.userInfoapi?.member_id==r.event_author_id"
                       (click)="triggerModal(modalData)" class="btn-xs">
                       <ng-template #modalData let-modal>
                           <div class="modal-header">
                               <h4 class="modal-title" id="modal-basic-title">DELETE EVENT</h4>
                               <button type="button" class="close" aria-label="Close"
                                   (click)="modal.dismiss('Cross click')">
                                   <span aria-hidden="true">×</span>
                               </button>
                           </div>
                           <div class="modal-body">
                               <p><strong>Are you sure you want to delete this Event</strong></p>


                           </div>
                           <div class="modal-footer">
                               <button type="button" class="btn btn-danger"
                                   (click)="deleteEvent(r.id)"
                                   (click)="modal.close('Save click')">Delete</button>
                           </div>
                       </ng-template><i class="fas fa-trash" style="color:#90278E"></i>
                   </a>
                   <!-- <p>{{r.publish_date|date:'yyyy-MM-dd H:m:s'}}</p> -->
                   <p>{{r.created_at}}</p>
               </div>
           </div>
       </div>
       <div class="col-sm-6">
           <i class="ft-more-vertical pull-right"></i>
       </div>
   </div>
   <div class="write-post">
       <div class="col-sm-12 px-2">
        
           <h4 ><a [attr.href]="'/event/' + r.id+'/view'" style="text-decoration:none;color:black"  >{{r.event_title}}</a></h4>
           <p [ngClass]="{ 'limitTextHeight': !isReadMore }" [innerHTML]="r.event_description"> </p>
        
      
       

       </div>
       <a style="padding:10px;" (click)="showText()" style="color:#90278E" >{{ isReadMore ? 'Read Less': 'Read More' }}</a>
       <div class="col-sm-12 px-2 pb-2">
           <img [src]="getImage(r)" alt="" class="img-fluid">
       </div>
       <hr class="m-0">
       <div class="row p-1">
           <div class="col-6">
               <div class="row">
                   <div class="col-4 pr-0">
                       <span (click)="click()"><i class="far fa-heart inlike"  (click)="like(r)" *ngIf="!r.liked"></i> 
                           <i class="far fa-heart like" style="color:red"  (click)="like(r)"
                               *ngIf="r.liked"></i>
                               {{r.like_count}}</span>
                           <i class="fa fa-eye ccounts" aria-hidden="true"></i>
                           {{r.view_count}}
                   </div>

                 
               </div>
           </div>
           <div class="col-6">
               <div class="pull-right">
                   <!-- <span class="pr-1"><i class="far fa-comment-alt"></i> 44</span> -->
                   <span (click)="open(mymodal,r)" class="pr-1"><i class="far fa-comment-alt"></i>
                       {{r.comment_count}}</span>
                   <span (click)="ope(modal)" class="pr-1"><i class="far fa-share-square"></i>
                       </span>
                   <ng-template #modal let-modal>
                       <share-buttons [theme]="'material-dark'"
                           [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                           [show]="15" [url]="getLocation(r.id)" [autoSetMeta]="false">
                       </share-buttons>
                       <div class="modal-footer">
                           <button type="button" class="btn btn-outline-dark"
                               (click)="modal.close('Save click')">Close</button>
                       </div>
                   </ng-template>
               </div>
           </div>
       </div>
   </div>
</div>

<!--event end -->
            
      
         </div>
      </article>
  
  
      <article  [ngClass]="param=='roar' ? 'tab-pane fade show active' : 'tab-pane fade'" id="events" role="tabpanel" aria-labelledby="pills-events-tab">
        <div *ngFor="let r of search_list_data">
<!-- roar -->
<div class="catd-body" *ngIf="r.category_id=='1'?true:false" >
    <div class="row p-2">
        <div class="col-sm-6">
            <div class="row">
                <div class="col-lg-4 col-3">                   
                        <img *ngIf="r.profile_pic_url" [src]="getImagePic(r)"
                        class="img-fluid rounded-circle width-50">                        
                        <img *ngIf="!r.profile_pic_url" src="../../assets/images/profile-user-round.png" alt=""
                        class="img-fluid rounded-circle width-50"> 
                </div>
                <div class="col-lg-8 col-7 p-0">
                    <!-- <h5 class="m-0">{{r.member_details[0].username}}</h5> -->
                    <span class="badge badge-success">Roar</span>
                    <span> <a title="Edit" *ngIf="memberapi.userInfoapi?.member_id==r.member_id"
                            class=" btn-xs " [attr.href]="'/roar/' + r.id +'/edit'"><i
                                class="fas fa-edit"></i></a></span>
                    <a title="delete" *ngIf="memberapi.userInfoapi?.member_id==r.member_id"
                        (click)="triggerModal(modalData)" class="btn-xs">
                        <ng-template #modalData let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title">DELETE ROAR</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p><strong>Are you sure you want to delete this Roar</strong></p>


                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger"
                                    (click)="deleteRoar(r.id)"
                                    (click)="modal.close('Save click')">Delete</button>
                            </div>
                        </ng-template><i class="fas fa-trash" style="color:#90278E"></i>
                    </a>
                    <!-- <p>{{r.publish_date|date:'yyyy-MM-dd H:m:s'}}</p> -->
                    <p>{{r.created_at}}</p>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <i class="ft-more-vertical pull-right"></i>
        </div>
    </div>
    <div class="write-post">
        <div class="col-sm-12 px-2">
            <h4><a  [attr.href]="'/roar/'+ r.id + '/view'" style="text-decoration:none;color:black"  >{{r.roar_title}}</a></h4>
            <p [ngClass]="{ 'limitTextHeight': !isReadMore }" [innerHTML]="r.roar_content"> </p>
         
       

        </div>
        <a style="padding:10px;" (click)="showText()" style="color:#90278E" >{{ isReadMore ? 'Read Less': 'Read More' }}</a>
   
        <hr class="m-0">
        <div class="row p-1">
            <div class="col-6">
                <div class="row">
                    <div class="col-4 pr-0">
                        <span (click)="click()"><i class="far fa-heart inlike"  (click)="like(r)" *ngIf="!r.liked"></i> 
                            <i class="far fa-heart like" style="color:red"  (click)="like(r)"
                                *ngIf="r.liked"></i>
                                {{r.like_count}}</span>
                                <i class="fa fa-eye ccounts" aria-hidden="true"></i>
                                {{r.view_count}}
                    </div>

                    
                </div>
            </div>
            <div class="col-6">
                <div class="pull-right">
                    <!-- <span class="pr-1"><i class="far fa-comment-alt"></i> 44</span> -->
                    <span (click)="open(mymodal,r)" class="pr-1"><i class="far fa-comment-alt"></i>
                        {{r.comment_count}}</span>






                    <span (click)="ope(modal)" class="pr-1"><i class="far fa-share-square"></i>
                        </span>

                    <ng-template #modal let-modal>

                        <share-buttons [theme]="'material-dark'"
                            [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                            [show]="15" [url]="getLocation(r.id)" [autoSetMeta]="false">
                        </share-buttons>


                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Close</button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- roar end -->



        </div>
   
   
   </article>


      <article  [ngClass]="param=='news' ? 'tab-pane fade show active' : 'tab-pane fade'" id="news" role="tabpanel" aria-labelledby="pills-events-tab">
        <div *ngFor="let r of search_list_data">
      <!-- News -->
      <div class="catd-body" *ngIf="r.category_id=='2'?true:false" >
        <div class="row p-2">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-lg-4 col-3">
                     
                            <img *ngIf="r.profile_pic_url" [src]="getImagePic(r)"
                            class="img-fluid rounded-circle width-50">
                          
                            <img *ngIf="!r.profile_pic_url" src="../../assets/images/profile-user-round.png" alt=""
                            class="img-fluid rounded-circle width-50"> 
                    </div>
                    <div class="col-lg-8 col-7 p-0">
                        <h5 class="m-0">{{r.member_details[0].username}}</h5>
                        <span class="badge badge-primary">News</span>
                        <span> <a title="Edit" *ngIf="memberapi.userInfoapi?.member_id==r.member_id"
                                class=" btn-xs " [attr.href]="'/news/' + r.id +'/edit'"><i
                                    class="fas fa-edit"></i></a></span>
                                  
                        <a title="delete" *ngIf="memberapi.userInfoapi?.member_id==r.member_id"
                            (click)="triggerModal(modalData)" class="btn-xs">
                            <ng-template #modalData let-modal>
                                <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">DELETE NEWS</h4>
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <p><strong>Are you sure you want to delete this News</strong></p>


                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger"
                                        (click)="deleteNews(r.id)"
                                        (click)="modal.close('Save click')">Delete</button>
                                </div>
                            </ng-template><i class="fas fa-trash" style="color:#90278E"></i>
                        </a>
                       
                        <p>{{r.created_at|date:'yyyy-MM-dd H:m:s'}}</p>
                      
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <i class="ft-more-vertical pull-right"></i>
            </div>
        </div>
        <div class="write-post">
            <div class="col-sm-12 px-2">
               
                <h4><a [attr.href]="'/news/' + r.id" style="text-decoration:none;color:black">{{r.news_title}}</a></h4>
               
               
                <p [ngClass]="{ 'limitTextHeight': !isReadMore }" [innerHTML]="r.news_content"> </p>
             
           
               

            </div>
            <a style="padding:10px;" (click)="showText()" style="color:#90278E" >{{ isReadMore ? 'Read Less': 'Read More' }}</a>
            <div class="col-sm-12 px-2 pb-2">
                <img [src]="getImage(r)" alt="" class="img-fluid">
            </div>
            <hr class="m-0">
            <div class="row p-1">
                <div class="col-6">
                    <div class="row">
                        <div class="col-4 pr-0">
                            <span (click)="click()"><i class="far fa-heart inlike"  (click)="like(r)" *ngIf="!r.liked"></i> 
                                <i class="far fa-heart like" style="color:red"  (click)="like(r)"
                                    *ngIf="r.liked"></i>
                                    {{r.like_count}}</span>
                                    <i class="fa fa-eye ccounts" aria-hidden="true"></i>{{r.view_count}}
                        </div>
                      
                    </div>
                </div>
                <div class="col-6">
                    <div class="pull-right">
                      
                        <span (click)="open(mymodal,r)" class="pr-1"><i class="far fa-comment-alt"></i>
                            {{r.comment_count}}</span>






                        <span (click)="ope(modal)" class="pr-1"><i class="far fa-share-square"></i>
                            </span>

                        <ng-template #modal let-modal>

                            <share-buttons [theme]="'material-dark'"
                                [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                                [show]="15" [url]="getLocation(r.id)" [autoSetMeta]="false">
                            </share-buttons>


                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Close</button>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- news end -->  
      </div>



      </article>
  

  </section>
  
  
  </div>
  
  <div class="h-100"></div>  
  
    <section class="partners-main-box my-3">
       <div class="container-fluid p-0">
          <div class="bg-gray-new shadow-none">
             <div class="row justify-content-between align-items-center">
                <div class="col-md-12 col-lg-5 my-2 logo-box ">
                   <img src="images/logo.png" alt="" class="im">   
                   <h4 class="title-1">Partner Services</h4>
                </div>
                <div class="col-md-12 col-lg-7 my-2">
                   <div class="partners owl-carousel owl-theme owl-loaded owl-drag">
                      <div class="owl-stage-outer">
                         <div class="owl-stage" style="transform: translate3d(-537px, 0px, 0px); transition: all 0.25s ease 0s; width: 1255px;">
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                            <div class="owl-item active" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item active" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                            <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                            <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-1.png" alt="partners"></a> </div>
                            </div>
                            <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                               <div class="item"><a href="#"><img src="images/partners/partner-2.png" alt="partners"> </a></div>
                            </div>
                         </div>
                      </div>
                      <div class="owl-nav disabled"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div>
                      <div class="owl-dots disabled"></div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
  </main>	


    <!--Post Comments-->
    <ng-template #mymodal let-modal>
                    
                  
      <article>
          <div class="component-box-1 ">
              <div class="user-info-box-main">

                  <single-comment-form comment-form [postId2]="r.id" [categoryId]="1"
                      (onSubmit)="onCommentCreate(r.id)">

                  </single-comment-form>

                  <div class="user-info-box ">
                      <h4>Comments </h4>

                      <section class="user-info-box-inner no-scroll text-left h-0 p-4">

                          <div *ngFor="let c of comments  | paginate
                          : {
                              id: 'pagination2',
                              itemsPerPage: tableSizecmt,
                              currentPage: pagecmt,
                              totalItems: countcmt
                            };
                      let i = index
                    " >

                              <single-comment [comment]="c" [groupId]="c.id" (onSubmit)="onCommentCreate(r.id)"></single-comment>
                              <div style="margin-left:100px">
                                  <single-comment *ngFor="let c1 of c.comments;" [comment]="c1"
                                      [groupId]="c.id" [isReply]="true"
                                      (onSubmit)="onCommentCreate(r.id)">
                                  </single-comment>
                              </div>

             
                          </div>
                      </section>
                      <div class="d-flex justify-content-center">
                          <pagination-controls
                          id="pagination2"
                            previousLabel="Prev"
                            nextLabel="Next"
                            (pageChange)="onTableDataChangecmt($event)"
                          >
                          </pagination-controls>
                        </div>                                 

                      <div class="modal-footer">
                          <button type="button" class="btn btn-outline-dark"
                              (click)="modal.close('Save click')">Close</button>
                      </div>
                  </div>
              </div>
          </div>
      </article>
     
  </ng-template>