<main role="main" class="home-section">
    <seciton class="top-banner">
        <div class="container">
            <div class="row lawyerBox">
                <div class="col-md-7 col-sm-6">
                    <div class="component-box-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box items">
                                <h4>Need a Lawyer</h4>
                                <div class="user-info-box-inner calendar-box no-scroll bg-image bg-lawyer p-2 pt-3 h-0 ">
                                    <form #needAlayerForm="ngForm" class="was-validated mt-3 mb-3">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div class="form-group m-0">
                                                    <textarea class="form-control rounded-0 h-120 p-2  is-invalid " id="textarea" name="description" #description="ngModel" [ngClass]="{ 'is-invalid': description.invalid }" placeholder="Type your query here.." (change)="ontext($event.target.value)" [(ngModel)]="findlawyer.description"
                                                        required></textarea>

                                                    <div *ngIf="!findAlayer && description.invalid" class="invalid-feedback">
                                                        <div *ngIf="description.errors.required">
                                                            Please enter your query to proceed
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="d-flex flex-column justify-content-between h-120">
                                                    <h3 class="m-0">
                                                        Connect me with <br /> the <span> <a href="#"> Best Lawyer </a> </span>
                                                    </h3>

                                                    <h3 class="m-0 smallText">Quick. <span>Free.</span> Easy.</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div class="mt-2 mb-2">
                                                    <div class="form-group m-0">
                                                        <!-- by click this button open find a lawer pop up -->
                                                        <button type="button" data-toggle="modal" id="modelPopUp" (click)="openfindlawer()" class="btn btn-primary btn-transparent font-weight-normal" style="background-color: #90278e !important;">Find a Lawer</button>

                                                        <button type="button" class="btn btn-primary btn-transparent font-weight-normal information" (click)="opensearch()">Give me Information</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-6">
                    <div class="component-box-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box items">
                                <h4>SoOLegal Concierge</h4>
                                <div class="user-info-box-inner calendar-box no-scroll p-0 h-0 position-relative Concierge">
                                    <!-- images/concierge-ad.jpg -->
                                    <img src="../../assets/images/concierge-ad.jpg" class="img-fluid" />

                                    <a href="tel:+91 9876543210" class="btn btn-primary position-absolute font-weight-normal">Call or
                    WhatsApp on +91 9876543210 </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center wrapper-700 text-gray-v1 mt-4">
                <h2 class="mt-4 mt-sm-0">Why Choose SoOLegal</h2>
                <p>
                    With the largest coverage of lawyers, SoOLegal helps you select the best lawyer based on our proprietary ROAR score methodology. This ensures that the lawyer with the right specialization and experience is recommended to you, with their fee estimates
                    up front.
                </p>
            </div>
            <section class="my-5">
                <div class="container container-small">
                    <div class="events owl-carousel owl-theme">
                        <div class="item small-box">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box d-flex m-0 p-0 align-items-center">
                                    <div class="img-box"><img src="../../assets/images/icons/rank_icons_black.png" alt="Rank" /></div>

                                    <div class="info text-left ml-1">
                                        <div class="rank text-color font-weight-bold">98%</div>
                                        <div class="success-title">Successfull Cases</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item small-box">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box d-flex m-0 p-0 align-items-center">
                                    <div class="img-box"><img src="../../assets/images/icons/icons_black_29.png" alt="Rank" /></div>

                                    <div class="info text-left ml-1">
                                        <div class="rank text-color font-weight-bold">1,700+</div>
                                        <div class="success-title">Registered Lawyers</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item small-box">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box d-flex m-0 p-0 align-items-center">
                                    <div class="img-box"><img src="../../assets/images/icons/icons_black_30.png" alt="Rank" /></div>

                                    <div class="info text-left ml-1">
                                        <div class="rank text-color font-weight-bold">1,200</div>
                                        <div class="success-title">Monthly Articles</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item small-box">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box d-flex m-0 p-0 align-items-center">
                                    <div class="img-box"><img src="../../assets/images/icons/icons_black_31.png" alt="Rank" /></div>

                                    <div class="info text-left ml-1">
                                        <div class="rank text-color font-weight-bold">70+</div>
                                        <div class="success-title">Legal Specialities</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </seciton>
    <section class="title-bg-2 my-4 ipad-hide phone-hide">
        <div class="container">
            <div class="row">
                <div class="col-md-2 orange-bg-new order-md-12">
                    <div class="triangle-bottomleft"></div>
                    <div class="icon-box"></div>
                </div>
                <div class="col-md-10 white-bg-new">
                    <div class="triangle-topleft"></div>
                    <span class="gray-bg">Go Premium</span>
                    <h3>Amplify your stature and reputation...Comment, Like, Share...ROAR!
                    </h3>
                </div>
            </div>
        </div>
    </section>

    <div class="w-25 p-3"></div>
    <section class="container position-relative mnSlider">
        <div class="news-slider">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigHomrRoar" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
                <article ngxSlickItem *ngFor="let slide of roarlist ; let i = index">

                    <div class="component-box-1 p-0">
                        <div class="user-info-box-main p-0">
                            <div class="user-info-box">
                                <div class="user-info-box-inner no-scroll pb-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="jobId mb-3">
                                            <div class="img-box">
                                                <a href="javascript:void(0);" [routerLink]="['/'+slide.username]">
                                                    <img src="{{slide.member_profile_picture}}" style="width:60px; height: 60px; border-radius:50%">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="job-posted-date mb-3 text-left">
                                            <a href="javascript:void(0);" [routerLink]="['/'+slide.username]">
                                                <p>
                                                    <span style="display:block;">{{slide.publish_date* 1000| date: 'dd/MM/yyyy'}}</span>
                                                    <span style="display:block;">{{slide.fullname}}</span>
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="job-details text-left mb-3">

                                        <a  class="link"  [routerLink]="['/roar/'+slide.slug]" type="text"><h4 class="mb-3 color-gray" [innerHtml]="slide.title"  ngx-clamp [ngxClampOptions]="{ clamp: 1}" id="texttitle1" title="{{slide.title}}" ></h4></a>  
                                        <a  class="link" [routerLink]="['/roar/'+slide.slug]"><img  src="{{slide.image_url}}" class="img-fluid roar-custom-size" id="texttitle2"></a>
                                    </div>
                                    <div class="text-wrapper text-left">
                                        <p><span [innerHTML]="slide.description" name="readmore"></span> <a class="link" [routerLink]="['/roar/'+slide.slug]">
                        Readmore</a></p>

                                    </div>
                                    <div class="text-right pull-right">
                                        <a href="javascript:void(0);" class="btn text-white color-gray"><span><small>{{memberapi.roarlike|async}}</small></span><i class="far fa-heart"></i></a>
                                        <a href="#" class="btn text-white color-gray"><i class="far fa-comments"></i></a>
                                        <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </ngx-slick-carousel>
        </div>
    </section>

    <div class="w-25 p-3"></div>
    <!-- tile bg -->
    <section class="title-bg-1 my-4 ipad-hide phone-hide">
        <div class="container">
            <div class="row">
                <div class="col-md-2 orange-bg-new">
                    <div class="triangle-bottomleft"></div>
                    <div class="icon-box"></div>
                </div>
                <div class="col-md-10 white-bg-new">
                    <div class="triangle-topleft"></div>
                    <span class="gray-bg ">Go Premium</span>
                    <h3>Get the best lawyers based on SoOLegal's ROAR score methodology
                    </h3>
                </div>
            </div>
        </div>
    </section>
    <div class="w-25 p-3"></div>
    <section>
        <div class="container">
            <div class="component-box-1">
                <div class="user-info-box-main">
                    <div class="user-info-box">
                        <h4>What is your case?</h4>
                        <div class="user-info-box-inner calendar-box no-scroll">
                            <div class="mt-4 what-is-case-slider">

                                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigHomrCase" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">

                                    <article ngxSlickItem *ngFor="let slide of homeapi.casequery" class="slide lawyer-list-box pl-4 pr-4">
                                        <div class="mb-3"><img src="{{slide.img}}" alt="lawyer" /></div>
                                        <h4 class="default-font">{{slide.name}}</h4>

                                        <label class="mb-0">
                                            <input type="radio" name="case" (click)="selectcase(slide.name)" class="radio" />
                                            <span class="radio-style"></span>
                                        </label>

                                        <p>
                                            {{(slide.content.length>6)? (slide.content | slice:0:250)+'..':(slide.content)}}
                                            <a href="#"> More... </a> "
                                        </p>
                                        <!-- <p>
                                            A valid divorce under Indian laws is granted only by courts. Special Family Courts hear divorce cases. Depending on the type of marriage (Hindu, Muslim, Christian, Special, etc), there are different grounds for divorce and different procedures...
                                            <a href="#"> More... </a> "
                                        </p> -->
                                    </article>

                                </ngx-slick-carousel>

                            </div>
                            <h3 class="text-center mt-3 mb-1">How can SooLegal help you today?
                            </h3>
                            <div class="flex-box text-center mt-0">
                                <div class="flex-item">
                                    <p>I need a lawyer, fast!</p>
                                    <label>
                                        <input type="radio" name="need-lawyer" (click)="selecttext('I need a lawyer, fast!')"
                                        class="radio" />
                                        <span class="radio-style"></span>
                                </label>
                                </div>
                                <div class="flex-item">
                                    <p>I need an opinion</p>
                                    <label>
                                        <input type="radio" name="need-lawyer" (click)="selecttext('I need an opinion')" class="radio" />
                                        <span class="radio-style"></span>
                                    </label>
                                </div>
                                <div class="flex-item">
                                    <p>I just need information</p>
                                    <label>
                                        <input type="radio" name="need-lawyer" (click)="selecttext('I just need information')"
                                        class="radio" />
                                        <span class="radio-style"></span>
                                    </label>
                                </div>
                                <div class="flex-item">
                                    <p>I need a checklist</p>
                                    <label>
                                        <input type="radio" name="need-lawyer" (click)="selecttext('I need a checklist')" class="radio" />
                                        <span class="radio-style"></span>
                                    </label>
                                </div>
                                <div class="flex-item">
                                    <p>I need a readymade template
                                    </p>
                                    <label>
                                        <input type="radio" name="need-lawyer" (click)="selecttext('I need a readymade template')"
                                        class="radio" />
                                        <span class="radio-style"></span>
                                    </label>
                                </div>

                                <div class="flex-item right text-right">
                                    <btn class="btn btn-primary right text-white font-weight-normal" (click)="submitcase()">Submit</btn>
                                </div>
                            </div>
                            <!-- <a href="#" class="arrow left-m"><img src="../../assets/images/icons/arrow_left.png" /></a><a href="#" class="arrow  r-180 right-m"><img src="../../assets/images/icons/arrow_left.png" /></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="need">
        <div class="container">
        </div>
    </section>
    <div class="w-25 p-3"></div>
    <section class="legal-help">
        <div class="container">
            <div class="component-box-1">
                <div class="user-info-box-main">
                    <div class="user-info-box">
                        <h4>Looking for legal help?

                        </h4>
                        <div class="user-info-box-inner calendar-box no-scroll">
                            <div class="text-center wrapper-700">
                                <p class="mt-4 mb-1">
                                    SoOLegal has over 1700 advocates in ## cities and towns, with 70 specialisations.<br> Find the advocate with the right experience to match your legal requirement and your budget</p>

                            </div>
                            <div class="h-25 d-inline-block"></div>
                            <div class="row">
                                <div class="col-sm-12 col-md-4 text-center">
                                    <div class="dropdown custom-dropdown">
                                        <!-- <button value="" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="selected_val"> Specialization </span>
                    </button> -->
                                        <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                       <button class="dropdown-item" type="button" *ngFor="let data of area" (click)="getprectise(data.id)"value="{{data.id}}"#specialization>{{data.name}}</button> 
                     </div> -->
                                        <div class="ng-autocomplete">
                                            <ng-autocomplete #auto [data]="area" [searchKeyword]="keyword" placeholder="Specialization" (selected)='selectEvent($event)' (inputCleared)="searchCleared('prime')" (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                                [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4 text-center">
                                    <div class="dropdown">
                                        <!-- <button value="" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="selected_val"> Location </span>
                    </button> -->
                                        <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                       <button class="dropdown-item" type="button" value="New Delhi"(click)="getlocation('New Delhi')">New Delhi</button>
                       <button class="dropdown-item" type="button" value="Mumbai"(click)="getlocation('Mumbai')">Mumbai</button>
                       <button class="dropdown-item" type="button" value="Jaipur"(click)="getlocation('Jaipur')">Jaipur</button>
                       <button class="dropdown-item" type="button" value="Chennai"(click)="getlocation('Chennai')">Chennai</button>
                       <button class="dropdown-item" type="button" value="Lucknow"(click)="getlocation('Lucknow')">Lucknow</button>
                       <button class="dropdown-item" type="button" value="Nagpur"(click)="getlocation('Nagpur')">Nagpur</button>
                       <button class="dropdown-item" type="button" value="Chandigarh"(click)="getlocation('Chandigarh')">Chandigarh</button>
                      
                     </div> -->
                                        <div class="ng-autocomplete">
                                            <ng-autocomplete #auto [data]="homeapi.location" [searchKeyword]="keyword" placeholder=" Location" (selected)='selectEvent($event)' (inputCleared)="searchCleared('location')" (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                                [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                    </div>



                                </div>
                                <div class="col-sm-12 col-md-4 text-center">


                                    <div class="dropdown custom-dropdown">
                                        <!-- <button value="" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="selected_val"> ROAR Rating (Coming Soon) </span>
                    </button> -->
                                        <div class="ng-autocomplete" style="background: #ff0;">
                                            <ng-autocomplete #auto [searchKeyword]="keyword" placeholder=" ROAR Rating (Coming Soon)" (selected)='selectEvent($event)' (closed)="closePanel($event)" (inputCleared)="searchCleared('roar')" (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <!-- <a [innerHTML]="item.name"></a> -->
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <!-- <div [innerHTML]="notFound"></div> -->
                                            </ng-template>
                                        </div>
                                        <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                      <button class="dropdown-item" type="button" value="700 - 1000 Points">700 - 1000 Points</button>
                       <button class="dropdown-item" type="button" value="500 - 700 Points">500 - 700 Points</button>
                       <button class="dropdown-item" type="button" value="300 - 500 Points">300 - 500 Points</button>
                       <button class="dropdown-item" type="button" value="> 300 Point">> 300 Points</button>
                    
                    </div> -->
                                    </div>



                                </div>
                            </div>
                            <div class="h-25 d-inline-block"></div>


                            <section class="topMatched">

                                <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigShopByCollection"
          (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
          (beforeChange)="beforeChange($event)">
                  
                  <article ngxSlickItem *ngFor="let data of primemember | slice:0:4; let i = index;">
                    <div  class="component-box-1 mb-0">
                    <div class="user-info-box-main">
                      <div class="user-info-box">
                        <h4>Top Matched</h4>
                        <div class="user-info-box-inner p-0 no-scroll">
                          <div class="user-box">
                            <div class="ribbon ribbon-top-right"><span>Premium</span></div>
                            <div class="img-box"><img src="../../assets/images/ab_circle.png" /></div>
                            <div class="name">Avani Bansal</div>
  
                            <p class="small">
                              Advocate | Consultant | Counsellor <br />
                              B.A. LLB (Hons.)
                            </p>
  
                            <div class="progress-bar-box">
                              <div class="progress">
                                <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <div class="pc-box">New Delhi (Now Online)</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   </div>
                    <a class="btn btn-primary mx-auto d-block btn-small"  data-toggle="modal" data-target="#findLawer" id="modelPopUp" >Schedule Appointment</a>
  
                  </article>


                </ngx-slick-carousel> -->
                                <ngx-slick-carousel *ngIf="primemember!=''" class="carousel" #slickModal="slick-carousel" [config]="slideConfigHomrUser" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
                                    <div ngxSlickItem *ngFor="let slide of primemember" class="slide">
                                        <div class="component-box-1 mb-0">
                                            <div class="user-info-box-main">
                                                <div class="user-info-box">
                                                    <h4>{{lflhstatus}}</h4>
                                                    <div class="user-info-box-inner p-0 no-scroll">
                                                        <div class="user-box">
                                                            <div class="ribbon ribbon-top-right"><span *ngIf="slide.isPrime">Premium</span></div>
                                                            <div class="img-box">
                                                                <a href="javascript:void(0);" [routerLink]="['/'+slide.username]">
                                                                    <img *ngIf="slide.profile_picture" src="{{slide.profile_picture}}" />
                                                                    <img src="../../assets/images/profile-user-round.png" *ngIf="!slide.profile_picture" class="img-fluid max-255">
                                                                </a>
                                                            </div>
                                                           <a [routerLink]="['/'+slide.username]"> <div class="name">{{slide.name}}</div> </a>
                                                            <!-- <a href="javascript:void(0);"
              [routerLink]="['/'+slide.username]"></a> -->
                                                            <p class="small">
                                                                {{slide.role}} <br /> {{slide.education}}
                                                            </p>

                                                            <div class="progress-bar-box">
                                                                <div class="progress">
                                                                    <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="pc-box" *ngIf="slide.city_name&&slide.country_name">{{slide.city_name}} ({{slide.country_name}})
                                                                </div>
                                                                <div class="pc-box" *ngIf="!slide.city_name">Location Not Mention</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="btn btn-primary mx-auto d-block btn-small" (click)="scheduleAppointment(slide.member_id)" id="modelPopUp">Schedule Appointment</a>

                                    </div>
                                </ngx-slick-carousel>
                                <div *ngIf="primemember==''">
                                    <p class="text-danger py-5">No Record Found</p>
                                </div>

                            </section>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="h-25 d-inline-block"></div>
    <section class="title-bg-2 my-4 ipad-hide phone-hide">
        <div class="container">
            <div class="row">
                <div class="col-md-2 orange-bg-new order-md-12">
                    <div class="triangle-bottomleft"></div>
                    <div class="icon-box"></div>
                </div>
                <div class="col-md-10 white-bg-new">
                    <div class="triangle-topleft"></div>
                    <span class="gray-bg">Go Premium</span>
                    <h3>Be at ease with SoOLegal's Cloud &amp; AI powered DIY assistance</h3>
                </div>
            </div>
        </div>
    </section>
    <div class="h-25 d-inline-block"></div>
















    <section class="resource-legal" id="resource-legal">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <div class="component-box-1">
                        <img src="../../assets/images/sprite.png" class="sprite">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>Explore, Research, and Empower Yourself</h4>
                                <div class="user-info-box-inner calendar-box no-scroll text-left items">
                                    <p class="text-center text-color-black font-18">SoOLegal’s Resource Centre has over 12000 articles, on various aspects of the Law. Download useful documents, articles, and ready made document templates.
                                    </p>
                                    <div class="resources">
                                        <div class="h-25 d-inline-block"></div>
                                        <div class="row mb-4" *ngFor="let data of homeapi.categorydata; let i = index;">
                                            <div class="col-md-6">


                                                <div class="dropdown  custom-dropdown">
                                                    <!-- <button value="" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                            <span class="selected_val">{{data.name}}</span>

                          </button> -->
                                                    <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <a class="dropdown-item"*ngFor="let sub of data.data" [routerLink]="['/rc/cat/'+ data.value +'/'+ sub.value]" title="Armed Forces Tribunal">{{sub.name}}</a>
                           
                   
                            
                            
                           </div> -->


                                                    <div class="ng-autocomplete">
                                                        <ng-autocomplete #auto [data]="data.data" [searchKeyword]="keyword" placeholder="{{data.name}}" (selected)='selectEventlegal($event,data.value)' (closed)="close($event)" (clear)="clear($event)" (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                                            [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                                                        </ng-autocomplete>

                                                        <ng-template #itemTemplate let-item>
                                                            <a [innerHTML]="item.name"></a>
                                                        </ng-template>

                                                        <ng-template #notFoundTemplate let-notFound>
                                                            <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                    </div>
                                                </div>


                                            </div>
                                            <div class="col-md-6">
                                                <p class="text-center small text-color-black">To make your job easy and quick, choose from an exhaustive list of templates and formats</p>
                                            </div>
                                        </div>

                                        <!-- <div class="row mb-4">
                        <div class="col-md-6">
                         
                         
                          
                         <div class="dropdown  custom-dropdown">
                           <button value="" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <span class="selected_val">  Legal Notices </span>
 
                           </button>
                           <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
 
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/armed-forces-tribunal" title="Armed Forces Tribunal">Armed Forces Tribunal</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/aviation-law" title="Aviation Law">Aviation Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/banking-and-finance-law" title="Banking & Finance Law">Banking & Finance Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/bankruptcyandinsolvency" title="Bankruptcy/Insolvency">Bankruptcy/Insolvency</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/civil-litigation" title="Civil Litigation">Civil Litigation</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/competition-law" title="Competition Law">Competition Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/consumer-protection" title="Consumer Protection">Consumer Protection</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/corporate-law" title="Corporate Law">Corporate Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/criminal-law" title="Criminal Law">Criminal Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/cyber-law" title="Cyber Law">Cyber Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/debt-recovery" title="Debt Recovery">Debt Recovery</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/energy-and-natural-resources" title="Energy and Natural Resources">Energy and Natural Resources</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/energy-law" title="Energy Law">Energy Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/familyandpersonal-law" title="Family/Personal Law">Family/Personal Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/immigration-law" title="Immigration Law">Immigration Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/insurance-law" title="Insurance Law">Insurance Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/intellectual-property-rights" title="Intellectual Property Rights">Intellectual Property Rights</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/international-law" title="International Law">International Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/labour-laws" title="Labour Laws">Labour Laws</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-noticesmedia-law" title="Media Law">Media Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/merger-and-amalgamation" title="Merger and Amalgamation">Merger and Amalgamation</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/motor-vehicle-accidentsand-road-injury" title="Motor Vehicle Accidents/ Road Injury">Motor Vehicle Accidents/ Road Injury</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/municipal-law" title="Municipal Law">Municipal Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/property-law" title="Property Law">Property Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/rail-law" title="Rail Law">Rail Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/real-estate" title="Real Estate">Real Estate</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/shipping-and-maritime-law" title="Shipping and Maritime Law">Shipping and Maritime Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/sports-law" title="Sports Law">Sports Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/taxation-law" title="Taxation Law">Taxation Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/transportation-law" title="Transportation Law">Transportation Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/white-collar-crime" title="White Collar Crime">White Collar Crime</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/constitutional-law" title="Constitutional Law">Constitutional Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/arbitration-2" title="Arbitration">Arbitration</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-notices/medical-law-2" title="Medical law">Medical law</a>
                   
                            
                            
                           </div>
                         </div>
 
 
 
 
                        </div>
                        <div class="col-md-6">
                          <p class="text-center small text-color-black">To fast track your work, review
                           examples of Legal Notices</p>
                        </div>
                      </div>
   -->
                                        <!-- <div class="row mb-4">
                        <div class="col-md-6">
                           
                         <div class="dropdown custom-dropdown">
                           <button value="" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                             <span class="selected_val">   Legal Procedures </span>
 
                           </button>
                           <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/armed-forces-tribunal" title="Armed Forces Tribunal">Armed Forces Tribunal</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/aviation-law" title="Aviation Law">Aviation Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/banking-and-finance-law" title="Banking & Finance Law">Banking & Finance Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/bankruptcyandinsolvency" title="Bankruptcy/Insolvency">Bankruptcy/Insolvency</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/civil-litigation" title="Civil Litigation">Civil Litigation</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/competition-law" title="Competition Law">Competition Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/consumer-protection" title="Consumer Protection">Consumer Protection</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/corporate-law" title="Corporate Law">Corporate Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/criminal-law" title="Criminal Law">Criminal Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/cyber-law" title="Cyber Law">Cyber Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/debt-recovery" title="Debt Recovery">Debt Recovery</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/energy-and-natural-resources" title="Energy and Natural Resources">Energy and Natural Resources</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/energy-law" title="Energy Law">Energy Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/familyandpersonal-law" title="Family/Personal Law">Family/Personal Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/immigration-law" title="Immigration Law">Immigration Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/insurance-law" title="Insurance Law">Insurance Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/intellectual-property-rights" title="Intellectual Property Rights">Intellectual Property Rights</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/international-law" title="International Law">International Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/labour-laws" title="Labour Laws">Labour Laws</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/media-law" title="Media Law">Media Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/merger-and-amalgamation" title="Merger and Amalgamation">Merger and Amalgamation</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/motor-vehicle-accidentsand-road-injury" title="Motor Vehicle Accidents/ Road Injury">Motor Vehicle Accidents/ Road Injury</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/municipal-law" title="Municipal Law">Municipal Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/property-law" title="Property Law">Property Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/rail-law" title="Rail Law">Rail Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/real-estate" title="Real Estate">Real Estate</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/shipping-and-maritime-law" title="Shipping and Maritime Law">Shipping and Maritime Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/sports-law" title="Sports Law">Sports Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/taxation-law" title="Taxation Law">Taxation Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/transportation-law" title="Transportation Law">Transportation Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/white-collar-crime" title="White Collar Crime">White Collar Crime</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/constitutional-law" title="Constitutional Law">Constitutional Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/arbitration-2" title="Arbitration">Arbitration</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/legal-procedures/medical-law-2" title="Medical law">Medical law</a>
                    
                           </div>
                         </div>
 
 
                        </div>
                        <div class="col-md-6">
                          <p class="text-center small text-color-black">To quickly understand various
                           procedures, choose from an exhaustive list of
                           topics</p>
                        </div>
                      </div> -->

                                        <!-- <div class="row mb-4">
                        <div class="col-md-6">
                         <div class="dropdown custom-dropdown">
                           <button value="" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                             <span class="selected_val">    Law Satutes  </span>
 
                           </button>
                           <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/armed-forces-tribunal" title="Armed Forces Tribunal">Armed Forces Tribunal</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/aviation-law" title="Aviation Law">Aviation Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/banking-and-finance-law" title="Banking & Finance Law">Banking & Finance Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/bankruptcyandinsolvency" title="Bankruptcy/Insolvency">Bankruptcy/Insolvency</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/civil-litigation" title="Civil Litigation">Civil Litigation</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/competition-law" title="Competition Law">Competition Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/consumer-protection" title="Consumer Protection">Consumer Protection</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/corporate-law" title="Corporate Law">Corporate Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/criminal-law" title="Criminal Law">Criminal Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/cyber-law" title="Cyber Law">Cyber Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/debt-recovery" title="Debt Recovery">Debt Recovery</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/energy-and-natural-resources" title="Energy and Natural Resources">Energy and Natural Resources</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/energy-law" title="Energy Law">Energy Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/familyandpersonal-law" title="Family/Personal Law">Family/Personal Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/immigration-law" title="Immigration Law">Immigration Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/insurance-law" title="Insurance Law">Insurance Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/intellectual-property-rights" title="Intellectual Property Rights">Intellectual Property Rights</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/international-law" title="International Law">International Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/labour-laws" title="Labour Laws">Labour Laws</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/media-law" title="Media Law">Media Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/merger-and-amalgamation" title="Merger and Amalgamation">Merger and Amalgamation</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/motor-vehicle-accidentsand-road-injury" title="Motor Vehicle Accidents/ Road Injury">Motor Vehicle Accidents/ Road Injury</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/municipal-law" title="Municipal Law">Municipal Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/property-law" title="Property Law">Property Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/rail-law" title="Rail Law">Rail Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/real-estate" title="Real Estate">Real Estate</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/shipping-and-maritime-law" title="Shipping and Maritime Law">Shipping and Maritime Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/sports-law" title="Sports Law">Sports Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/taxation-law" title="Taxation Law">Taxation Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/transportation-law" title="Transportation Law">Transportation Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/white-collar-crime" title="White Collar Crime">White Collar Crime</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/constitutional-law" title="Constitutional Law">Constitutional Law</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/arbitration-2" title="Arbitration">Arbitration</a>
                            <a class="dropdown-item" href="http://13.232.236.151:3000/rc/cat/law-statute-acts-update/medical-law-2" title="Medical law">Medical law</a>
                   
                            
                            
                           </div>
                         </div>
                        </div>
                        <div class="col-md-6">
                          <p class="text-center small text-color-black">Review and Research from our
                           comprehensive collection</p>
                        </div>
                      </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-md-5">
                    <div class="component-box-1">

                        <img src="../../assets/images/sprite.png" class="sprite">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>Premium Legal Services</h4>
                                <div class="user-info-box-inner calendar-box no-scroll p-4 text-color-black items">

                                    <div class="d-flex">
                                        <article>
                                            <!-- data-target="#findLawer" -->
                                            <div class="services-info text-center mt-2 mb-2">
                                                <div data-toggle="tooltip" data-placement="top" data-html="true" title="<p><strong>Agreements & Contracts</strong><br>Order stamp papers, get printed copies delivered to your doorstep</p>
                       ">
                                                    <img src="../../assets/images/icons/doc_trans.png" (click)="premium(4,'Agreements & Contracts')" class="img-fluid" />
                                                    <p>Agreements & Contracts
                                                    </p>
                                                </div>
                                            </div>




                                            <div class="services-info text-center mt-2 mb-2" id="modelPopUp">
                                                <div data-toggle="tooltip" data-placement="top" data-html="true" title="<p><strong>Send a Legal Notice</strong><br>Send a Legal Notice Text here....</p>">
                                                    <img src="../../assets/images/icons/notice.png" class="img-fluid" (click)="premium(5,'Send a Legal Notice')">
                                                    <p>Send a Legal Notice
                                                    </p>
                                                </div>
                                            </div>







                                            <div class="services-info text-center mt-2 mb-2" id="modelPopUp">
                                                <div data-toggle="tooltip" data-placement="top" data-html="true" title="<p><strong>Legal Language Translation<br></strong>Quick turnaround of your translation jobs across 50+ domestic and international languages 
                       Judgement Synopsis</p>">
                                                    <img src="../../assets/images/icons/research.png" class="img-fluid" (click)="premium(6,'Legal Language Translation')">
                                                    <p>Legal Language Translation
                                                    </p>
                                                </div>
                                            </div>
                                        </article>



                                        <article>
                                            <div class="services-info text-center mt-2 mb-2" id="modelPopUp">
                                                <div data-toggle="tooltip" data-placement="top" data-html="true" title="<p><strong>Judgement Synopsis
                     </strong><br>Have a Paralegal prepare a professional synopsis for you quick reference
                   </p>">
                                                    <img src="../../assets/images/icons/synopsis.png" class="img-fluid" (click)="premium(1,'Judgement Synopsis')">
                                                    <p>Judgement Synopsis

                                                    </p>
                                                </div>
                                            </div>



                                            <div class="services-info text-center mt-2 mb-2" id="modelPopUp">

                                                <div data-toggle="tooltip" data-placement="top" data-html="true" title="<p><strong>Legal Research </strong><br> Have a Paralegal prepare a professional research for you quick reference </p>">
                                                    <img src="../../assets/images/icons/synopsis.png" class="img-fluid" (click)="premium(3,'Legal Research')">
                                                    <p>Legal Research


                                                    </p>
                                                </div>
                                            </div>

                                            <div class="services-info text-center mt-2 mb-2" id="modelPopUp">
                                                <div data-toggle="tooltip" data-placement="top" data-html="true" title="<p><strong>Vet Legal Documents </strong><br>Upload your document and you receive a  marked up draft with explanations of terms and suggestions on additional clauses </p>">
                                                    <img src="../../assets/images/icons/synopsis.png" class="img-fluid" (click)="premium( 7,'Vet Legal Documents')">
                                                    <p>Vet Legal Documents


                                                    </p>
                                                </div>
                                            </div>

                                        </article>

                                    </div>
                                    <!--endflexbox-->



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>















    <section class="title-bg-1 my-4 ipad-hide phone-hide">
        <div class="container">
            <div class="row">
                <div class="col-md-2 orange-bg-new">
                    <div class="triangle-bottomleft"></div>
                    <div class="icon-box"></div>
                </div>
                <div class="col-md-10 white-bg-new">
                    <div class="triangle-topleft"></div>
                    <span class="gray-bg ">Go Premium</span>
                    <h3>SoOLEGAL is recommended by our valued members and users</h3>
                </div>
            </div>
        </div>
    </section>




    <section class="profile-slider">
        <div class="container">
            <div class="profile m-0 p-0">
                <!-- <article class="component-box-1 component-box-bg-light">
            <div class="highlight-box">
              <div class="user-box text-center">
                <div class="img-box"></div>
  
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sagittis molestie scelerisque. Maecenas ac ante quam. Proin sodales odio nec tortor convallis, sed feugiat mi efficitur. Nam consequat purus lorem, non rhoncus
                  velit laoreet eget. Nulla nec suscipit nunc, sit amet rutrum nulla.
                </p>
 
                <p><a href="#" class="link">Name</a></p>
              </div>
            </div>
          </article> -->




                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigHomeFeedback" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
                    <div ngxSlickItem *ngFor="let slide of testimoniallist" class="profile-slider">
                        <div class="component-box-1 component-box-bg-light">
                            <div class="user-info-box-main p-0">
                                <div class="user-box text-center">
                                    <div class="img-box">
                                        <img src="{{slide.profile_picture}}">
                                    </div>

                                    <p>
                                        {{slide.content}}
                                    </p>

                                    <p><a href="#" class="link">{{slide.name}}</a></p>

                                </div>
                            </div>
                        </div>
                        <!-- <a class="btn btn-primary mx-auto d-block btn-small"  data-toggle="modal" data-target="#findLawer" id="modelPopUp" >Schedule Appointment</a> -->
                        <!-- class="news-slider" -->
                    </div>
                </ngx-slick-carousel>
                <!-- <article class="component-box-1 component-box-bg-light">
            <div class="highlight-box">
              <div class="user-box text-center">
                <div class="img-box"></div>
  
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sagittis molestie scelerisque. Maecenas ac ante quam. Proin sodales odio nec tortor convallis, sed feugiat mi efficitur. Nam consequat purus lorem, non rhoncus
                  velit laoreet eget. Nulla nec suscipit nunc, sit amet rutrum nulla.
                </p>
                <p><a href="#" class="link">Name</a></p>
              </div>
            </div>
          </article>
  
          <article class="component-box-1 component-box-bg-light">
            <div class="highlight-box">
              <div class="user-box text-center">
                <div class="img-box"></div>
  
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sagittis molestie scelerisque. Maecenas ac ante quam. Proin sodales odio nec tortor convallis, sed feugiat mi efficitur. Nam consequat purus lorem, non rhoncus
                  velit laoreet eget. Nulla nec suscipit nunc, sit amet rutrum nulla.
                </p>
                <p><a href="#" class="link">Name</a></p>
              </div>
            </div>
          </article>
  
          <article class="component-box-1 component-box-bg-light">
            <div class="highlight-box">
              <div class="user-box text-center">
                <div class="img-box"></div>
  
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sagittis molestie scelerisque. Maecenas ac ante quam. Proin sodales odio nec tortor convallis, sed feugiat mi efficitur. Nam consequat purus lorem, non rhoncus
                  velit laoreet eget. Nulla nec suscipit nunc, sit amet rutrum nulla.
                </p>
                <p><a href="#" class="link">Name</a></p>
              </div>
            </div>
          </article>
  
          <article class="component-box-1 component-box-bg-light">
            <div class="highlight-box">
              <div class="user-box text-center">
                <div class="img-box"></div>
  
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sagittis molestie scelerisque. Maecenas ac ante quam. Proin sodales odio nec tortor convallis, sed feugiat mi efficitur. Nam consequat purus lorem, non rhoncus
                  velit laoreet eget. Nulla nec suscipit nunc, sit amet rutrum nulla.
                </p>
                <p><a href="#" class="link">Name</a></p>
              </div>
            </div>
          </article> -->
            </div>
        </div>
    </section>








    <!-- <section class="box my-5">
      <div class="container">
        <div class="black-box h-0">
          <img src="../../assets/images/add.jpg" class="img-fluid" />
  
          <img src="../../assets/images/a5.jpg" class="img-fluid" />
        </div>
      </div>
      
    </section> -->
    <section class="box my-5">
        <div class="container">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigHomeAds" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
                <div ngxSlickItem class="black-box h-0">
                    <img src="../../assets/images/banner.png" style="width: inherit;" class="img-fluid" />

                    <!-- <a class="btn btn-primary mx-auto d-block btn-small"  data-toggle="modal" data-target="#findLawer" id="modelPopUp" >Schedule Appointment</a> -->
                    <!-- class="news-slider" -->
                </div>
            </ngx-slick-carousel>
        </div>
    </section>



</main>

<div class="modal fade" id="findLawer" tabindex="-1" aria-labelledby="findLawer" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0">

            <button type="button" class="close close-model" data-dismiss="modal" aria-label="Close" #closefindlawer>
                <span aria-hidden="true">&times;</span>
            </button>


            <div class="modal-body popup-body">


                <div class="error">Please enter some query</div>

                <div class="form-content">
                    <p> Thank you for reaching out! </p>



                    <p> Our team will look into your query and connect with you within 24 hour with the best solution. </p>



                    <p> Please share your preferred contact details for us to re-connect with you.</p>

                    <div class="alert alert-success" *ngIf="message" role="alert">
                        {{message}}
                    </div>



                    <form class="mt-28">
                        <div class="form-group">

                            <input type="email" class="form-control rounded-0" name="email" [(ngModel)]="findlawyer.email" aria-describedby="emailHelp" placeholder="Email">

                        </div>

                        <div class="form-group">

                            <input type="tel" class="form-control rounded-0" name="mobile" [(ngModel)]="findlawyer.mobile" aria-describedby="phoneHelp" placeholder="Phone">

                        </div>


                        <div class="form-group form-check">
                            <input type="checkbox" checked="checked" class="form-check-input" id="exampleCheck1" value="1" name="is_share_info" [(ngModel)]="findlawyer.is_share_info" />
                            <label class="form-check-label" for="exampleCheck1">Do not share my information</label>
                        </div>

                        <button type="submit" class="d-block btn btn-primary mb-0 mx-auto" (click)="postfindlawer()">Submit</button>
                    </form>

                </div>

            </div>




        </div>
    </div>
</div>

<div class="modal fade" id="caseHelp" tabindex="-1" aria-labelledby="caseHelp" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0">

            <button type="button" class="close close-model" data-dismiss="modal" aria-label="Close" #closewhatyourcase>
        <span aria-hidden="true">&times;</span>
      </button>

            <div class="modal-body popup-body">


                <div class="error">Please enter some query</div>

                <div class="form-content">
                    <p> Thank you for contacting SooLegal </p>



                    <p> Our Lawyer will contact you. </p>



                    <p> Please share your preferred contact details for us to re-connect with you.</p>

                    <div class="alert alert-success" *ngIf="message" role="alert">
                        {{message}}
                    </div>



                    <form class="mt-28">
                        <div class="form-group">

                            <input type="email" class="form-control rounded-0" name="email" [(ngModel)]="findlawyer.email" aria-describedby="emailHelp" placeholder="Email">

                        </div>

                        <div class="form-group">

                            <input type="tel" class="form-control rounded-0" name="mobile" [(ngModel)]="findlawyer.mobile" aria-describedby="phoneHelp" placeholder="Phone">

                        </div>


                        <div class="form-group form-check">
                            <input type="checkbox" checked="checked" class="form-check-input" id="exampleCheck1" value="1" name="is_share_info" [(ngModel)]="findlawyer.is_share_info" />
                            <label class="form-check-label" for="exampleCheck1">Do not share my information</label>
                        </div>

                        <button type="submit" class="d-block btn btn-primary mb-0 mx-auto" (click)="postwhatyourcase()">Submit</button>
                    </form>

                </div>

            </div>




        </div>
    </div>
   
</div>
<span #openfindlawerModal data-toggle="modal" data-backdrop="static" data-target="#findLawer" style="display: none;"></span>
<span #opencasehelpModal data-toggle="modal" data-backdrop="static" data-target="#caseHelp" style="display: none;"></span>


<app-login (loginEvent)="loginSubmit($event)"></app-login>