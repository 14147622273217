

 
  

  <style type="text/css">
    ul.pagination.justify-content-center.pagination-sm li {
      width: 50px !important;
    }
  </style>

  <main role="main" class="web-text-color">
    <!-- <section class="ipad-hide phone-hide">
      <div class="container-fluid inner-title">
        <div class="row">
          <div class="col-md-1 pl-0 text-right"><span>NEWS</span></div>
          <div class="col-md-10">Delhi HC directs Singh Brothers to Maintain Status-quo over Assets & Properties | Delhi
            HC directs...</div>
          <div class="col-md-1 pr-0 text-left"><span>NEWS</span></div>
        </div>
      </div>
    </section> -->

    <section class="public-profile section">
      <div class="container-fluid width">
        <div class="row">
          <div class="col-md-12 col-sm-6">

            <!-- <div class="user-info-box-inner no-scroll eventSlider"> -->
            <!-- 1 -->
            <ngx-slick-carousel class="carousel user-info-box-inner no-scroll overflow-hidden"
              #slickModal="slick-carousel" [config]="slideConfig">              
              <div ngxSlickItem *ngFor="let n of latest_news;" class="slide text-left">
                <div class="m-0 p-0 text-left sec">
                  <!-- <img class="d-block" [src]="getImage(n)" width="100%" alt=""> -->
                  <img class="d-block" src={{n.news_image_url}} width="100%" alt="">
                 
                  <div class="captionbg d-md-block">
                    <h3>{{n.title}}</h3>
                    <p [innerHtml]="n.content" ngx-clamp [ngxClampOptions]="{ clamp: 3}"> </p>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
      
          </div>
        </div>
        <div class="h-40"></div>
        <div class="col-md-12">
          <div class="cus-quickactions" style="display: flex; justify-content: space-between;">
            <div class="text-left pull-left iconclr">
              <h3 class="news-title">News Headlines</h3>
            </div>
            <!-- <div>
              <button style="float: right;" href="/news/add" class="btn btn-primary btn-xs"><i class="fa fa-plus fa-fw"></i> News</button>
            </div> -->
            <div class="add-newbtn">
              <a title="Add News" class="btn btn-primary btn-xs pull-right" (click)="click()" (click)="addnews()" ><i
                  class="fa fa-plus fa-fw"></i> News</a>
            </div>
          </div>
        </div>
        <div class="h-20"></div>
        <div class="component-box-1 pt-0">
          <div class="user-info-box-main">
            <div class="user-info-box">
              <div class="user-info-box-inner no-scroll p-0 m-0">
                <div class="advanced-search">
                  <div id="profile-details">
                    <ul class="nav nav-pills nav-fill" id="pills-tab" role="tablist">
                      <li class="nav-item dropdown dis">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                          aria-haspopup="true" aria-expanded="false">Dropdown <i class="fas fa-caret-down"
                            aria-hidden="true"></i></a>
               
                            <div class="dropdown-menu">
                          <a (click)="onclick('')" class="dropdown-item" id="resource-center-tab" data-toggle="pill"
                             role="tab" aria-controls="resource-center" aria-selected="true">Latest
                            News</a>
                          <!-- <a (click)="onclick('international')" class="dropdown-item" id="legal-formats-tab"
                            data-toggle="pill"  role="tab" aria-controls="legal-formats"
                            aria-selected="false">International News</a> -->
                          <a (click)="onclick('myviews')"  class="dropdown-item" id="legal-notices-tab"
                            data-toggle="pill"  role="tab" aria-controls="legal-notices"
                            aria-selected="false">My News</a>
                          <a (click)="onclick('favorites')"  class="dropdown-item" id="legal-research-tab"
                            data-toggle="pill" role="tab" aria-controls="legal-research"
                            aria-selected="false">Favorites</a>
                        </div>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a (click)="onclick('')" class="nav-link active" id="resource-center-tab" data-toggle="pill"
                           role="tab" aria-controls="resource-center" aria-selected="true">Latest
                          News</a>
                      </li>
                      <!-- <li class="nav-item" role="presentation">
                        <a (click)="onclick('international')" class="nav-link" id="legal-formats-tab" data-toggle="pill"
                           role="tab" aria-controls="legal-formats"
                          aria-selected="false">International News</a>
                      </li> -->
                      <li class="nav-item" role="presentation">
                        <a (click)="onclick('myviews')" *ngIf="memberapi.userInfoapi?.member_id" class="nav-link" id="legal-notices-tab" data-toggle="pill"
                           role="tab" aria-controls="legal-notices" aria-selected="false">My News</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a (click)="onclick('favorites')" *ngIf="memberapi.userInfoapi?.member_id" class="nav-link" id="legal-research-tab" data-toggle="pill"
                          role="tab" aria-controls="legal-research" aria-selected="false">Favorites</a>
                      </li>
                    </ul>
                    <div class="tab-content p-4" id="pills-tabContent">
                      <!-- Latest News -->
                      <article class="tab-pane fade show active" id="latest-news" role="tabpanel"
                        aria-labelledby="probile-tab">
                      <!-- <pre>{{latest_news | json}}</pre> -->
                        <!-- card -->
                        <div id="jar">
                          <!-- <div class="row mx-auto content" *ngFor="let n of news -->
                            <div class="row mx-auto content" *ngFor="let n of latest_news
                          | paginate
                          : {
                              itemsPerPage: tableSize,
                              currentPage: page,
                              totalItems: count
                            };
                      let i = index
                    " >
                            <article class="row mt-2 mb-4">

                              <div class="col-md-4">
                                <div class="icon">
                                  <!-- <img class="lazy blog-img-thumbnail img-responsive img-thumbnail" [src]="getImage(n)"> -->
                                  <img class="lazy blog-img-thumbnail img-responsive img-thumbnail" src={{n.news_image_url}}>
                                </div>
                              </div>

                              <div class="col-md-8">
                                <div class="desc" >
                                  <p class="small link">
                                     <span class="author-name">{{n.member_details[0].username}}</span>| 
                                     <span class="author-date">{{n.created_at}}</span> 
                                      <!-- <span class="author-date">{{n.publish_date*1000|date:'yyyy/MM/dd'}}</span> -->
                                      
                                  </p>
                                  <h1><a [attr.href]="'/news/' + n.id">{{n.news_title}}</a></h1>

                                  <p [innerHtml]="n.news_content" class="h-2px" ngx-clamp [ngxClampOptions]="{ clamp: 2}"></p>

                                </div>
                                <!-- <share-buttons [theme]="'material-dark'"
                                [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                                [show]="9"
                                
                                [url]="getLocation(n.id)"
                                
                                [autoSetMeta]="false"
                               ></share-buttons> -->
                                <div class="cus-quickactions" style="display: flex; justify-content: space-between;">
                                  <div class="text-left pull-left iconclr">
                                    <a [attr.href]="'/news/' + n.id" class="btn text-white color-gray" tabindex="0"><i
                                        class="far fa-eye" aria-hidden="true"></i></a>
                                  <span> <a (click)="bookmark(n)" (click)="click()" class="btn text-white color-gray" tabindex="0"><i 
                                        class="far fa-bookmark"  aria-hidden="true" *ngIf="!n.bookmark"></i><i
                                         class="far fa-bookmark" style="color:blue" aria-hidden="true"*ngIf="n.bookmark"></i></a></span> 
                                    <a (click)="open(mymodal)" class="btn text-white color-gray" tabindex="0"><i
                                        class="fas fa-share-alt" aria-hidden="true"></i></a>
                                    <!-- <share-popup-button>Share</share-popup-button> -->
                                   



                                    <ng-template #mymodal let-modal>

                                      <share-buttons [theme]="'material-dark'"
                                      [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                                      [show]="15"
                                      
                                      [url]="getLocation(n.id)"  
                                      
                                      [autoSetMeta]="false"
                                     ></share-buttons>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="modal.close('Save click')">Close</button>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div *ngIf="type=='myviews'">
                                    <!-- <a href="news/:newsId/edit" ></a>
                                    <button style="float: right;" class="btn btn-primary btn-xs"><i class="fas fa-edit"></i>Edit</button> -->
                                    <a title="Edit" class=" btn-xs " [attr.href]="'/news/' + n.id +'/edit'"><i
                                        class="fas fa-edit"></i></a>
                                        <a title="delete" *ngIf="memberapi.userInfoapi?.member_id==n.member_id"  (click)="triggerModal(modalData)" class="btn-xs" 
                                                
                                        ><ng-template #modalData let-modal>
                                            <div class="modal-header">
                                              <h4 class="modal-title" id="modal-basic-title">DELETE NEWS</h4>
                                              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">×</span>
                                              </button>
                                            </div>
                                            <div class="modal-body">
                                              <p><strong>Are you sure you want to delete this News</strong></p>
                                              
                                              
                                            </div>
                                            <div class="modal-footer">
                                              <button type="button" class="btn btn-danger" (click)="deleteNews(n.id)" (click)="modal.close('Save click')">Delete</button>
                                            </div>
                                          </ng-template><i
                                          class="fas fa-trash" style="color:#90278E" ></i> </a>
                                  </div>
                                </div>
                              </div>
                            
                            </article>
                            <!-- End Card -->
                            <!-- <nav> 
                              <ul class="pagination justify-content-center pagination-sm">
                                (pageChange)="onTableDataChange($event)"
                              </ul>
                            </nav> -->
                          </div>
                  
                        </div>
                      
      
                      </article>
                      <div class="d-flex justify-content-center">
                        <pagination-controls
                          previousLabel="Prev"
                          nextLabel="Next"
                          (pageChange)="onTableDataChange($event)" >
                        </pagination-controls>
                      </div>
                    
                    </div>
                  </div>
                  <!-- end profile details-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 text-left">
          <strong>Disclaimer: </strong><em>SoOLEGAL in Media collaboration with Lawstreet Journal. SoOLEGAL take no
            responsbility for the content provided by Lawstreet Journal. For any discrepancies <a target="_blank"
              href="https://lawstreet.co/">Contact Lawstreet Journal</a></em>.
        </div>
      </div>

    </section>
    <section class="title-bg-1 my-4  ipad-hide phone-hide">
      <div class="container">
        <div class="row">
          <div class="col-md-2 #90278E-bg-new">
            <div class="triangle-bottomleft"></div>
            <div class="icon-box"></div>
          </div>
          <div class="col-md-10 white-bg-new">
            <div class="triangle-topleft"></div>
            <span class="gray-bg">Go Premium</span>
            <h3>Get the best lawyer based on SoOLegal's proprietary ROAR Score methodology</h3>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="my-5" *ngFor="let e of event"> -->
      <section class="my-5">
      <div class="container position-relative">

        <ngx-slick-carousel class="carousel user-info-box-inner no-scroll overflow-hidden"
        #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let e of event" class="slide text-left">
         
            <!-- <div class="component-box-1 component-box-bg-light"> -->
              <!-- <div class="highlight-box"> -->
                <p class="font-weight-bold">2020 SoOLegal Knowledge Series

                  {{e.username}}</p>
                <div class="img-box"><img src="images/avani_extn_1200x628.jpg" alt="Event" class="img-fluid" /> </div>

                <div class="date">DD/MM/YYY</div>
                <a href="#" class="btn">Register Now</a>
              </div>
            <!-- </div> -->
          
        
        <!-- </div> -->
      </ngx-slick-carousel>

        <section class="lawyer-slider slider-margin" >
       


                </section>


        <a href="#" class="arrow law-ml"><img src="images/icons/arrow_left.png" /></a><a href="#"
          class="arrow law-mr r-180"><img src="images/icons/arrow_left.png" /></a>


      </div>
    </section>
  </main>

 
  <div class="mobile-menu-bar">
    <a data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
      aria-label="Toggle navigation"> <img src="images/home.png" alt="home" /> </a>
    <a href="#"> <img src="images/users.png" alt="User" /> </a>
  </div>

 
  



<app-login (loginEvent)="loginSubmit($event)"></app-login>