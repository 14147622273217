import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventComponent } from './event.component';
import { IndexComponent } from './index/index.component';
import { ViewComponent } from './view/view.component';
import { AddComponent } from './add/add.component';
import { EditComponent } from './edit/edit.component';

const routes: Routes = [
  { path: 'event/:eventId/add', component: AddComponent },
  { path: 'event/:eventId/edit', component: IndexComponent },
  { path: 'event', redirectTo: 'event/index', pathMatch: 'full'},
  { path: '', component: IndexComponent },
 
  { path: 'event/:eventId/view', component: ViewComponent },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventRoutingModule { }

