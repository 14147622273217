import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';
import { RegisterService } from '../register/register.service';
export class otp {
  JWTAuthorization:any;
  otp: any;
 
}

@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.css']
})
export class OtpVerifyComponent implements OnInit {
  otpmatch :any
  token:any;
  message:any;
  @Output() otpEvent = new EventEmitter<string>();
  @ViewChild('openOtpModal') otpModal:ElementRef; 
  @ViewChild('closeotpModal')closeotpModal:ElementRef; 
  @ViewChild(RegisterComponent)registerotp: RegisterComponent;
  @ViewChild(LoginComponent)login: LoginComponent;

  constructor(public api: RegisterService,public router: Router) { 
    this.otpmatch = new otp();
   this.token=  localStorage.getItem('otptoken')
   
  
   
  }
  

  ngOnInit(): void {
  }
  openotp(){
   
    this.otpModal.nativeElement.click()
  }
  resetverifyotp(){setTimeout(() => {
    this.message = ''
    this.closeotpModal.nativeElement.click();
  //  this.router.navigate(['/login']);
  this.login.openLogin();
  // this.otpEvent.next('login');
  }, 2000);

  }
  reseterror(){
    setTimeout(() => {
      this.message = ''
      
    }, 2000);
  }
  verifyotp(){
    if(!this.otpmatch.otp){
      this.message = 'OTP required!';
    setTimeout(() => {
      this.message = '';
      return;
    }, 2500)
    }
  else
  { 
   
   
    this.api.postotp('matchSignUpOTP', this.otpmatch).subscribe((res: any) => {
      if(res.status==200){
this.message = res.message;
        this.resetverifyotp()
      }else{
        if(res.message.otp){
          this.message= res.message.otp;
        }else{
          this.message = res.message;
        }
        this.reseterror();
      }
     
     
  
    })}
   
  }

}
