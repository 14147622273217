import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RegisterRoutingModule } from './register-routing.module';
import { RegisterComponent } from './register.component';
import {HttpClientModule} from '@angular/common/http';
 import { NgxCaptchaModule } from 'ngx-captcha';
// import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { RecaptchaModule } from "ng-recaptcha";




@NgModule({
  declarations: [RegisterComponent],
  exports:[RegisterComponent],
  imports: [
    CommonModule,
    RegisterRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
     NgxCaptchaModule
  ]
})
export class RegisterModule { }
