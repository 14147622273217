


	
	<main role="main" id="roar-page">
  <section class=" ipad-hide phone-hide">
    <div class="container-fluid inner-title" style="margin-top: 71px;">
      <div class="row">
        <div class="col-md-1 pl-0 text-right"><span>NEWS</span></div>
        <div class="col-md-10">Delhi HC directs Singh Brothers to Maintain Status-quo over Assets & Properties  |  Delhi HC directs...</div>
        <div class="col-md-1 pr-0 text-left"><span>NEWS</span></div>
      </div>
    </div>
  </section>



  
  <section class="ipad-top-space">
    <div class="container container-medium">
      <div class="row">



<div class="col-md-7 offset-md-5">	
			<div class="roar-search  ipad-hide phone-hide">
  <div class="input-group">
    <div class="input-group-prepend">
      <button class="btn btn-outline-secondary pl-1 pb-0 pt-0" type="button"><img src="../../assets/images/icons/pin.png"></button>
    </div>
    <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username with two button addons" >
    <div class="input-group-append">
      <button class="btn btn-outline-secondary pr-1 pb-0 pt-0" type="button">ROAR</button>
    </div>
  </div>
</div>
</div>

        <div class="col-md-6 col-lg-3 ">
			
			<div class="roar-search dk-none">
  <div class="input-group">
    <div class="input-group-prepend">
      <button class="btn btn-outline-secondary" type="button"></button>
    </div>
    <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username with two button addons" >
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" type="button">ROAR</button>
    </div>
  </div>
</div>
		





<article class="component-box-1" *ngFor="let c of top_contributor_list">
  <div class="user-info-box-main">
    <h4>Top Contributers</h4>
    <div class="user-info-box" >
      
      <div class="user-info-box-inner p-0 no-scroll">
        <div class="user-box">
          <div class="ribbon ribbon-top-right"><span>Premium</span></div>
          <div class="img-box">
            <!-- <img src="../../assets/images/ab_circle.png" /> -->
            <img *ngIf="c.url"   [src]="getTopContributorImage(c)" />
                                                <img *ngIf="!c.url" src="../../assets/images/profile-user-round.png" alt=""
                                                class="img-fluid rounded-circle width-50"> 
          </div>
          <div class="name">{{c.username}}</div>

          <!-- <p class="small">
           {{c.qualification}}<br />
            B.A. LLB (Hons.)
          </p> -->
          <div class="lawyer-info">
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 0)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Lawyer</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 1)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Law Firm</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 2)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Associate</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 3)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Partner</span></p>
            </div>

            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 4)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Publisher</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 5)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Author</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 6)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Student</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 7)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Bar Association</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 8)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Retired Judge</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 9)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Law Academician</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 11)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Chartered Accountant</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 12)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Company Secretary</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type ==13)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Intelligence Services</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 14)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Cost Accountant</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 15)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Tax Lawyer</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 16)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Consultant</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 17)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Temporary Login</span></p>
            </div>
            <div style="padding: 10px; color: black;" class="lcn-in" 
            *ngIf="(c.member_type == 12)">
                <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Company Secretary</span></p>
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <h6 class="review-count">Roar Ratings</h6>
                <div class="stars">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <h6 class="review-count">Articles &nbsp;{{c.Total}}</h6>
            </div>
        </div>
          <div class="progress-bar-box">
            <div class="progress">
              <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            
          <!-- <div class="pc-box">New Delhi (Now Online)</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</article>

</div>
    
<div class="col-md-6 col-lg-9 "> 
<!-- <pre>{{roar| json}}</pre> -->  
<!-- roar section start -->
<article  *ngIf="roar.category_id=='1'?true:false" >
    <div class="component-box-1 ">
      <div class="user-info-box-main">
      <div class="user-info-box">
        <h4></h4>
        <div class="user-info-box-inner no-scroll pb-3">
        <div class="d-flex justify-content-between">
          <div class="jobId mb-3">
          <p>
            <img *ngIf="roar.profile_pic_url" [src]="getImagePic(roar)"  class="img-fluid rounded-circle width-10"style="height:80px">
            <!-- <img *ngIf="roar.profile_pic_url" src={{roar.profile_pic_url}}> -->
          
            <img *!ngIf="roar.profile_pic_url" src="../../assets/images/profile-user-round.png" alt=""
                                            class="img-fluid rounded-circle width-10"style="height:80px"> 
          </p>
          </div>
          <div class="job-posted-date mb-3 text-left">
          <!-- <p>{{roar.publish_date*1000|date:'yyyy/MM/dd H:m:s'}}  -->
            <p>{{roar.roar_publish_date*1000|date:'yyyy/MM/dd H:m:s'}} 
          <br> 
          {{roar.member_details[0]['username']}}</p>
          </div>
        </div>
        <div class="job-details text-left mb-3">
          <h4 class="mb-3 color-gray">{{roar.roar_title}}</h4>
          <img [src]="getImage(roar)" class="img-fluid">
          <!-- <img src={{roar.roar_image_url}} class="img-fluid"> -->
        </div>
        <div class="text-wrapper text-left">         
            <p [innerHtml]="roar.roar_content"></p>           
                
        </div>      
          
          <div class="text-right pull-right"   >
            <!-- <span (click)="click()"><i class="far fa-heart inlike" style="color:red" (click)="like(roar)" *ngIf="!liked"></i> 
              <i class="far fa-heart like" style="color:red"  (click)="like(roar)"
                  *ngIf="liked"></i></span> -->
                  <span (click)="click()"><i class="far fa-heart inlike"  (click)="like(roar)" *ngIf="!liked"></i> 
                    <i class="far fa-heart like" style="color:red"  (click)="like(roar)"
                        *ngIf="liked"></i>{{roar.like_count}}</span>
          
          <a  class="btn text-white color-gray"><i class="fas fa-share-alt" (click)="triggerModal(modalData)"></i></a>
          <ng-template #modalData let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Share</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
                <share-buttons [theme]="'material-dark'"
                [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                [show]="15" [url]="getLocation(roar.id)"[autoSetMeta]="false"></share-buttons>
              
              
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark"
                (click)="modal.close('Save click')">Close</button>
            </div>
          </ng-template>
          </div>


        </div>
      </div>
      </div>
    </div>
</article>
<single-comment-form [postId2]="roar.id" [categoryId]="1" (onSubmit)="onCommentCreate()"></single-comment-form>
<!-- roar section end-->
  
<article>
      <div class="component-box-1 ">
        <div class="user-info-box-main">
        <div class="user-info-box ">
          <h4>Comments</h4>




          <!-- <section class="user-info-box-inner no-scroll text-left h-0 p-4">
          
            
            <div class="media">
              <img src="images/ab_circle.png" class="mr-3 media-img" alt="...">
              <div class="media-body">
			  <div class="cmnt-name">Avani Bansal</div>
              
                <p class="m-0">An employment contract is an agreement that discusses in detail the working rapport of a company and an employee. It helps both parties to comprehend their obligations and the stipulations of employment.</p>
           
          <div class="text-right d-flex justify-content-end">
          <a href="#" class="btn text-white color-gray"><i class="far fa-heart"></i></a>
          <a href="#" class="btn text-white color-gray"><i class="far fa-comments"></i></a>
          <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
          </div>

                <article class="media mt-3 clearfix">
                  <a class="mr-3" href="#">
                    <img src="images/kanika.png" class="media-img-ans" alt="...">
                  </a>
                  <div class="media-body">
                   <div class="cmnt-name">User 2</div>
                    <p class="m-0">An employment contract is an agreement that discusses in detail the working rapport of a company and an employee. It helps both parties to comprehend their obligations and the stipulations of employment.</p>
                 
                    <div class="text-right d-flex justify-content-end">
                      <a href="#" class="btn text-white color-gray"><i class="far fa-heart"></i></a>
                      <a href="#"   class="btn text-white color-gray"><i class="far fa-comments"></i></a>
                      <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
                      </div>
                 
                 
                  </div>
                </article>



                <article class="media mt-3 clearfix">
                  <a class="mr-3" href="#">
                    <img src="images/kanika.png" class="media-img-ans" alt="...">
                  </a>
                  <div class="media-body">
                   <div class="cmnt-name">User 2</div>
				   
                    <p class="m-0">An employment contract is an agreement that discusses in detail the working rapport of a company and an employee. It helps both parties to comprehend their obligations and the stipulations of employment.</p>
                 
                    <div class="text-right d-flex justify-content-end">
                      <a href="#" class="btn text-white color-gray"><i class="far fa-heart"></i></a>
                      <a href="#" class="btn text-white color-gray"><i class="far fa-comments"></i></a>
                      <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
                      </div>
                 
                 
                  </div>
                </article>



              </div>
            </div>
  
          </section>
 -->


          <section class="user-info-box-inner no-scroll h-0 p-4 text-left">
          
            <div *ngFor="let c of comments
            | paginate
            : {
              
                itemsPerPage: tableSize,
                currentPage: page,
                totalItems: count
              };
        let i = index
      " >
                                                
              <single-comment [comment]="c" [groupId]="c.id" (onSubmit)="onComment()"></single-comment>
              <div style="margin-left:100px">
              <single-comment *ngFor="let c1 of c.comments;" [comment]="c1" [groupId]="c.id" [isReply]="true"></single-comment>
              </div>
              </div>
            <!-- <div class="media">
              <img src="images/ab_circle.png" class="mr-3 media-img" alt="...">
              <div class="media-body">
              <div class="cmnt-name">Avani Bansal</div>
                <p class="m-0">An employment contract is an agreement that discusses in detail the working rapport of a company and an employee. It helps both parties to comprehend their obligations and the stipulations of employment. </p>
            <div class="text-right d-flex justify-content-end">
          <a href="#" class="btn text-white color-gray"><i class="far fa-heart"></i></a>
          <a href="#" class="btn text-white color-gray"><i class="far fa-comments"></i></a>
          <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
          </div>
               




              </div>
            </div> -->
          </section>
          <div class="d-flex justify-content-center">
              <pagination-controls
                previousLabel="Prev"
                nextLabel="Next"
                (pageChange)="onTableDataChange($event)"
              >
              </pagination-controls>
            </div>

          <!-- <div class="load-more">  
         <a href="#"> Load More </a>  
          </div>
          </section>
		     -->



        </div>
        </div>
      </div>
      </article>
  


        </div>

      </div>
    </div>
  </section>

  	<div class="h-50"></div>













		
		


  
		
		<section class="partners-main-box my-3">
    <div class="container-fluid p-0">
      <div class="bg-gray-new shadow-none">
       
        <div class="row justify-content-between align-items-center">
          <div class="col-md-12 col-lg-5 my-2 logo-box "> <img src="../../assets/images/logo.png" alt="" class="roarimg" />   <h4 class="title-1">Partner Services</h4> </div>
          <div class="col-md-12 col-lg-7 my-2">
            <div class="partners owl-carousel owl-theme">
              <div class="item"><a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a> </div>
              <div class="item"><a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
		
		
 
		<div class="mobile-menu-bar">
		<a data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"> <img src="../../assets/images/home.png" alt="home" /> </a>
		<a href="#"> <img src="../../assets/images/users.png" alt="User" /> </a>
		</div>
		
</main>

	
	<!-- PopUp Box for comment details -->
<div class="modal fade" id="comment" tabindex="-1" aria-labelledby="comment" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content rounded-0">

      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      
      <div class="modal-body popup-body">


<form class="mt-28">
  <div class="form-group">
    <textarea class="form-control rounded-0 bg-white" placeholder="Comment Here"></textarea>
  </div>


  <div class="mt-4">
    <button type="submit" class="d-block btn btn-primary mb-0 mx-auto">Submit</button>
  </div>
</form>
      </div>
    </div>
  </div>
</div>

	
	
	

