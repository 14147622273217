import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  eventcalendar: any;
  public url = environment.baseURL;
  eventlist=[];
  calendardata: any;

  constructor(public http: HttpClient) {
    this.getcalendar();
   }

  getcalendar(){
    
    let obj={
      date:''
    }
  
    this.postmember('calendarData',obj).subscribe((res2: any) => {
  
      this.eventcalendar = res2.calendar
      if(this.eventcalendar){
        this.pushevent();
      }
     
     
     
 
      
    })

  }

  pushevent(){
    // new Date(element.end*1000);
   
      // className: 'scheduler_basic_event',
      // color:'black'
      let colour 
      let textColor
    
      
      if(this.eventcalendar.hearings.length!=0){
        this.eventcalendar.hearings.forEach(ele => {
          colour ='#FF7D01'
          textColor= 'black'
          this.eventlist.push({'start':new Date(ele.hearing_date*1000),title:ele.description,id:ele.id,color:colour,textColor:textColor,currentdate:new Date()}) 
        });
       

      }
      if(this.eventcalendar.appointments.length!=0){
        this.eventcalendar.appointments.forEach(ele => {
          colour ='#F5C0D7'
          textColor= 'black'
          this.eventlist.push({'start':ele.start_time,'end':ele.end_time,title:ele.title,id:ele.id,color:colour,textColor:textColor,currentdate:new Date()}) 
        });
       

      }
      if(this.eventcalendar.events.length!=0){
        this.eventcalendar.events.forEach(ele => {
          colour ='#ADF3F7'
          textColor= 'black'
          this.eventlist.push({'start':ele.start,'end':ele.end,title:ele.title,id:ele.id,color:colour,textColor:textColor,currentdate:new Date()}) 
        });
       

      }
    
    
   

     
      
   
   
  }


  postmember(urlT: any, data: any) {
    const updateUrl = this.url + urlT;
    const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('USERID')); // may be localStorage/sessionStorage
    const headers = { headers: header };
   
    return this.http.post(updateUrl, data,headers);
  }
  getdata(urlt: any) {
  
    const getUrl = this.url + urlt;
    const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('USERID')); 
    const headers = { headers: header };
    return this.http.get(getUrl,headers)
   
  }

  getcalendardata(data){
    // calendarData
  
    let obj={
      date:data
    }
    this.postmember('calendarData',obj).subscribe((res2: any) => {
    
      this.calendardata = res2.calendar
     
    
    
   
  
     
   })

  }

}
