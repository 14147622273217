import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { RoarService } from '../roar.service';
import { NewsService } from '../../news/news.service';
import { EventService } from '../../event/event.service';
import { Roar } from '../roar';
import { Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemberService } from '../../member/member.service';
import { ApiService } from '../../api.service';
import { CommentService } from '../../comment/comment.service';
import { LikeService } from '../../like/like.service';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../login/login.service';
import { LoginComponent } from '../../login/login.component';
import { count } from '../../modals/count';

@Component({

  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  

  @Input()
  postId: number;

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter();

  calendardata: any;
  eventcalendar: any;
  roarlike: any;
  profiledata: any;
  category: any;
  event: any;
  countmember = false;
  roarlist: any;
 
  page: number = 1;
  count: number = 0;
  tableSize: number = 6;
  tableSizes: any = [3, 6, 9, 12];

 
  pagecmt: number = 1;
  countcmt: number = 0;
  tableSizecmt: number = 4;
  tableSizescmt: any = [3, 6, 9, 12];
cmt:any;
  
  news:any;
  top_news_list:any;
  roar_list:any;
  top_contributor_list:any;
  closeModal:string;
  closeResult: string;
  userInfo: any;
  total: any;
  roar: Roar[] = [];
  Roar: any = {};
  roarImage: any = {};
  comments: Comment[];
  category_id = 1;
  showMore = false;
  r: any;
  s: any;
  existingRoar: any = null;
  id: number;
  formData: any;
  status: any;
  status1 = 1;
  token: string;

  News: any;

  p: Number = 1;
  count1: Number = 5;


 
  constructor(private route: ActivatedRoute,public newsService: NewsService,public eventService:EventService, public likeService: LikeService,public api: ApiService, public loginService: LoginService, public commentService: CommentService, public roarService: RoarService, private router: Router, private modalService: NgbModal, public memberapi: MemberService, public mainapi: ApiService) { 
    if(localStorage.getItem('USERID')){


      this.token =  localStorage.getItem('USERID')
     }
     if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
     this.refresh();
     }
        
   }
  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  @ViewChild('openLoginModal')loginModal: ElementRef; 
  @Output() loginEvent = new EventEmitter<string>();
  


  ngOnInit(): void {
    var roarid = this.route.snapshot.paramMap.get('roarId');
    this.getComments('', this.category_id);
    console.log("Roar Index");

    this.getRoar(roarid);
    if(roarid){
      this.findRoar(roarid);
    }
    this.gettotal();
    this.getroar();
    this.getevent();
    this.getprofile();
    this.getNews();
    this.get_Top_News_list();
    this.get_roar_list();
    this.get_top_contributor_list();
    console.log("roar list",this.roar);
    

  }

  isReadMore = false;

  showText() {
    this.isReadMore = !this.isReadMore
  }

  myForm = new FormGroup({
    title: new FormControl(''),
    tags: new FormControl(''),
    content: new FormControl(''),
    url: new FormControl(''),
    roar_video_url:new FormControl(''),
    status1: new FormControl(''),
  });


  get f() {
    return this.myForm.controls;
  }

  onFileChange(event) {
    this.roarImage = event.target.files[0];
  }
  showform = false;
  submit() {
   
    // this.showform = !this.showform;
    const formData = new FormData();
    if (this.existingRoar?.id) {
      formData.append("id", this.existingRoar?.id);
    }
    formData.append("roar_image_url", this.roarImage);
    formData.append('roar_title', this.myForm.get('title').value);
    formData.append('roar_tags', this.myForm.get('tags').value);
    formData.append("roar_content", this.myForm.get('content').value);
    formData.append("roar_image_url", this.myForm.get('url').value);
    formData.append("roar_video_url", this.myForm.get('roar_video_url').value);
    formData.append("category_id", this.category_id + '');
    formData.append("status", this.status1 + '')

    console.log('hiiiiiiiiiiiiiiiiiiiiii', this.status1);
    this.roarService.create(formData).subscribe((res: any) => {
    console.log("Roar Created..:::", res.roar);    
  
     
      this.showform=false;
      this.router.navigate(['/roar']);
    });
    
    //  formData.forEach((value,key) => {
    //   console.log(key+" "+value)
    // });
  }
  
 
   
  onclick() {
    this.showform = !this.showform;
    if(this.showform && this.existingRoar){
      setTimeout(() => {
        this.myForm.patchValue({
          title: this.existingRoar.roar_title,
          tags:this.existingRoar.roar_tags,
          publish_date: this.existingRoar.publish_date,
          content: this.existingRoar.roar_content,
          url: this.existingRoar.roar_image_url,
        });
      }, 100);
      
    }
    this.myForm.reset();
    this.getRoar('');
    
  }
 
 
  like(r) {
if(this.token){
    let roar_id = r.id,
      liked = r.liked,
      author_id = r.author_id;

    // this.showicon = !this.showicon;
    // this.show = !this.show;
    console.log('like',r);
    const formData = new FormData();
    formData.append('post_id', roar_id + '');
    formData.append('category_id', this.category_id + '');
    formData.append('member_id', author_id + '');
    if (liked > 0 ) {
      liked = 0;
      r.like_count = r.like_count - 1;
    } else {
     liked = 1;
      r.like_count = r.like_count + 1;
    }
    // UPdating the ui object
    r.liked = liked;

    formData.append('status', liked + '');
    console.log('status',liked);

    this.likeService.create(formData).subscribe((res: any) => {
      console.log("Roar Liked..:::", res.roar);
    });

    console.log(this.category_id);
  }

  }


  getComments(post_id, category_id) {
    this.commentService.getAll(post_id, this.category_id).subscribe((com: Comment[]) => {
      this.comments = com;
      console.log("Comments..:::", this.comments);

    });
  }

  getRoar(limit) {
    console.log('getting Roar');
    this.roarService.getAll(0).subscribe((res: Roar[]) => {
      this.roar = res;
      console.log("Roar Created..:::", this.roar);
    });
  }

  findRoar(roarId) {
    
    console.log('getting Roar6');
    this.roarService.find(roarId).subscribe((res: any) => {
      this.existingRoar = res.messages.feed_list[0];
   
    });
  }
  getNews() {
    console.log('getting news');
    this.newsService.limitnews().subscribe((res: any) => {
      this.news = res;
      console.log("NEWS..:::", this.news);
    })
  }

  get_Top_News_list(){
    console.log('getting news');
    this.newsService.top_news_list().subscribe((res: any) => {
      this.top_news_list = res;
      console.log("Top_News_list..:::", this.top_news_list);
    })
  }

get_roar_list()
  {
    this.roarService.roar_list().subscribe((res: any) => {
      this.roar_list = res.messages.feed_list;
      console.log("roar_list.:::", this.roar_list);
  })
 }

 get_top_contributor_list()
 {
  this.roarService.top_contributor_list().subscribe((res: any) => {
    this.top_contributor_list = res.messages.top_contributor_list;
    console.log("top_contributor_list.:::", this.top_contributor_list);
})
 }

  getImage(roar) {
    // return "http://localhost:8080/api/roar-image/" + roar.url;
    // return environment.baseURL + "roar-image/" + roar.url;
    return roar.roar_image_url;
  }
  getTopContributorImage(c)
  {
    return environment.pathurl +"cdn/assets/uploads/user_profile_pic/"+ c.url;
  }
  getNewsImage(news){
    return news.url;
  }
  getImagePic(roar) {
    return roar.profile_pic_url;
  }

  onCommentCreate(roar_id) {
    this.getComments(roar_id, this.category_id);
    console.log(this.roar, this.category_id);
    console.log('roar creayed');
  }
  
  deleteRoar(roar_id) {
  
      this.roarService.delete(roar_id).subscribe((res:any) => {
      this.roar = res;
      // this.router.navigate(['roar']);
      alert("record deleted..!");    
      window.location.reload();
     
    });
  }
  deleteNews(news_id){
    this.newsService.delete(news_id).subscribe((res:any)=>{
      this.news = res;
      alert("record deleted..!");    
      window.location.reload();
    });
  }
  deleteEvent(event_id) {
    this.eventService.delete(event_id).subscribe((res:any) => {
      this.event = res; 
      alert("record deleted..!");    
      window.location.reload();
    });
  }



  open(content, r) {
    this.r = r;
    this.getComments(r.id, 1);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
 

  ope(conte) {
    this.modalService.open(conte, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismiss(reason)}`;
    });
  }


  private getDismiss(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // socialshare url
  getLocation(news_id) {
    return location.href + '/' + news_id;
    //  return  "https://stackoverflow.com"+'/'+news_id;
  }

  click(){
    if(this.token){
      // this.showform = !this.showform;
      // this.router.navigate(['/roar']);
    // this.submit('');
    }else{
      this.loginPrev.openLogin();
    }
  }
  refresh() {
    if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
    localStorage.clear();
    // window.location.reload();
    }
  }
  openLogin() {
    this.loginModal.nativeElement.click()
  }
  loginSubmit(event:any) {
  }


  // popup for delete icon
  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
     
      this.getRoar('');
      this.router.navigate(['/roar']);
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReasons(res)}`;
     
    });
   
  }
  
  private getDismissReasons(reason: any): string {
    
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
     
    }
   
  }

 

  onTableDataChange(event: any) {
    this.page = event;
    this.getRoar;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getRoar;
  }
 

  onTableDataChangecmt(event1: any) {
    this.pagecmt = event1;
    this.getComments;
  }
  onTableSizeChangecmt(event1: any): void {
    this.tableSizecmt = event1.target.value;
    this.pagecmt = 1;
    this.getComments;
  }







  // ###############33side bar ##################333 
  gettotal() {


    this.memberapi.getdata('dashboard').subscribe((res2: any) => {
      this.total = res2.dashboard
    
      this.memberapi.roarlike.next(this.total.roar_like);
     
    
      if (this.total) {
        this.countmember = true

      } else {
      }
    })

  }


  getevent() {


    this.memberapi.getdata('memberEvent').subscribe((res2: any) => {

      this.event = res2.profil

    })

  }


  getprofile(){
    
    this.memberapi.getdata('profile').subscribe((res2: any) => {
  
      this.profiledata = res2.data     
    })
  
  }
  getroar() {
    this.memberapi.getdata('memberRoar').subscribe((res2: any) => {

      this.roarlist = res2.profile
    
    })

  }
  // top_news_list(){
  //   const updateUrl = this.url + 'top_news_list';

  //   return this.http.get(updateUrl);
  // }
 

}