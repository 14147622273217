<!-- Start Hero Area -->
<section class="hero-area1 overlay1">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 offset-lg-1 col-md-12 col-12">
                <div class="hero-text text-center">
                    <!-- Start Hero Text -->
                    <div class="section-heading">
                        <h2 class="wow fadeInUp" data-wow-delay=".3s"> Comprehensive Collection of Your Legal Needs</h2>

                    </div>
                    <!-- End Search Form -->
                    <!-- Start Search Form -->
                    <div class="container">
                        <div class="row">

                            <div class="col">
                                <div style="display:inline-grid;" class="button">
                                    <button class="btn icon-box1">
                                        <img class="icon-box1x"
                                            src="../../assets/images/Banner Section Icons/Group 154.png" />
                                    </button>
                                    <a class="title-service" style="color:white" href="resource-centre/resource-view/2">Judgements</a>
                                </div>
                            </div>

                            <div class="col">
                                <div style="display:inline-grid;" class="button custom">
                                    <button class="btn icon-box1">
                                        <img class="icon-box1x"
                                            src="../../assets/images/Banner Section Icons/Group 153.png" />
                                    </button>
                                    <a class="title-service" style="color:white" href="resource-centre/resource-view/3">Bare Acts</a>
                                </div>
                            </div>

                            <div class="col">
                                <div style="display:inline-grid;" class="button custom">
                                    <button class="btn icon-box1">
                                        <img class="icon-box1x"
                                            src="../../assets/images/Banner Section Icons/Group 151.png" />
                                    </button>
                                    <a class="title-service" style="color:white" href="resource-centre/resource-view/1">Legal Procedures</a>
                                </div>
                            </div>


                            <div class="col">
                                <div style="display:inline-grid;" class="button">
                                    <button class="btn icon-box1">
                                        <img class="icon-box1x"
                                            src="../../assets/images/Banner Section Icons/Group 152.png" />
                                    </button>
                                    <a class="title-service" style="color:white" href="resource-centre/resource-view/4">Legal Formats</a>
                                </div>
                            </div>

                            <div class="col">
                                <div style="display:inline-grid;" class="button">
                                    <button class="btn icon-box1">
                                        <img class="icon-box1x"
                                            src="../../assets/images/Banner Section Icons/Group 152.png" />
                                    </button>
                                    <a class="title-service" style="color:white" href="/need-a-lawyer">Need a Lawyer</a>
                                </div>
                            </div>
                           
                            
                            
                        </div>
                    </div>

                    <div class="search-form wow fadeInUp" [(ngModel)]="filterTerm" data-wow-delay=".7s" [ngModelOptions]="{standalone: true}">
                        <div class="d-flex">

                            <input class="form-control me-2" type="text" [(ngModel)]="filterTerm" (keyup)="onKeyup($event.target.value)" [ngModelOptions]="{standalone: true}" placeholder="Search" aria-label="Search">

                            <button style="width: 20%;" type="button"class="btn btn-outline-primary">Seacrh</button>

                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>
</section>
<!-- End Hero Area -->

<div class="newsletter1 section">
    <div class="container">
        <div class="inner-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-6">
                    <div class="title">

                        <h2 style="font-size: 30px; text-align:left; padding-left: 30px;">Get Your Document Published </h2>

                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-5">
                    <div style="text-align: right;" class="button" *ngIf="this.api.loggedIn()">
                        <a  class="btn"  href="resource-centre/resource-centre-form" (click)="formResource()">Click to Publish<span
                                class="dir-part"></span></a>                              
                    </div>
                    <div style="text-align: right;" class="button" *ngIf="!this.api.loggedIn()">
                        <a  class="btn" (click)="click()" >Click here<span
                                class="dir-part"></span></a>                              
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Start How Works Area -->
<section class="how1-works section" style="background-color: #fff;">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h4 style="font-size:30px" class="wow fadeInUp" data-wow-delay=".4s">Soolegal houses a comprehensive collection of legal articles judjements opinions and many other documents.Search here as per requirement
                    </h4>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-12">
                <!-- Start Single Work -->
                <div class="single-work wow fadeInUp" data-wow-delay=".2s">
                    <a class="space" href="javascript:void(0)">
                        <span class="hglt-box">

                            <img src="../../assets/images/logoResource/Group 148.png" />

                        </span>
                        <span class="hglt-box">
                            <h4>Constitutional
                                <br>Amendments
                            </h4>


                        </span>
                    </a>
                </div>
                <!-- End Single Work -->
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <!-- Start Single Work -->
                <div class="single-work wow fadeInUp" data-wow-delay=".4s">
                    <a href="javascript:void(0)">
                        <span class="hglt-box">

                            <img src="../../assets/images/logoResource/Group 149.png" />

                        </span>
                        <span class="hglt-box">

                            <h4>Acts and <br> Amendments</h4>


                        </span>
                    </a>
                </div>
                <!-- End Single Work -->
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <!-- Start Single Work -->
                <div class="single-work wow fadeInUp" data-wow-delay=".6s">
                    <a href="javascript:void(0)">
                        <span class="hglt-box">

                            <img src="../../assets/images/logoResource/Group 150.png" />

                        </span>
                        <span class="hglt-box">

                            <h4>Laws | Statute<br>Acts | Updates

                            </h4>


                        </span>
                    </a>
                </div>
                <!-- End Single Work -->
            </div>

        </div>
    </div>
</section>
<!-- End How Works Area -->

<!-- ########################   -->





<div *ngIf="searchlist.length==0">
    <!-- Most popular articles -->
    <section class="items-grid section custom-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="res-cen-car">
                        <div class="heading-cu col-md-6">
                            <h2 style="text-align: left;margin-top: -67px;" class="wow fadeInUp" data-wow-delay=".4s">
                                Most Popular Articles</h2>
                        </div>

                    </div>
                </div>
            </div>

            <div class="single-head">
                <div class="row">

                    <!-- <pre>{{get_article_List | json}}</pre> -->
                    <ngx-slick-carousel class="carousel user-info-box-inner no-scroll overflow-hidden" #slickModal="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let a of get_article_List | filter: filterTerm " class="slide text-left">
                            <div class="single-grid wow fadeInUp" style="margin: 10px;" data-wow-delay=".2s">
                                <div class="">
                                    <div style="padding: 15px; border: 1px solid rgb(209, 208, 208); border-radius: 2%;" class="">

                                        <div class="user-block">
                                            <div class="image1">
                                                <img *ngIf="a.member_profile_pic_url" [src]="getImagePic(a)" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                                <img *ngIf="!a.member_profile_pic_url" src="../../assets/images/profile-user-round.png" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                                <!-- <img *ngIf="a.member_profile_pic_url" [src]="getImagePic(a)"
                                                style="height: 87%;width: 96%;"> -->
                                                <!-- <img *ngIf="!a.member_profile_pic_url" src="../../assets/images/profile-user-round.png"
                                                alt="" style="height: 87%;width: 96%;"> -->

                                            </div>
                                            <div class="user-info2">
                                                <a class="usr-nm">{{a.member_details[0]['first_name']}}</a>
                                                <span class="time">{{a.created_at}}</span>
                                                <!-- <span class="time">{{a.created_date*1000|date:'yyyy/MM/dd H:m:s'}}</span> -->
                                            </div>
                                        </div>

                                        <hr>

                                        <h4 class="title rc">
                                            <a [attr.href]="'/resource-centre/'+ a.id + '/resource-docfree'">
                                                {{a.rc_title}}
                                        </a>
                                        </h4>
                                        <p class="update-time" [innerHtml]="a.rc_description" ngx-clamp [ngxClampOptions]="{ clamp: 3}">

                                        </p>

                                        <hr>

                                        <div class="social-count">
                                            <div class="likes-count">
                                                <a class="quick-actions-icons" (click)="click()"><i class="fa fa-heart-o"
                                                    (click)="like(a)" *ngIf="!a.liked"></i>
                                                <i class="fa fa-heart-o" (click)="click()"  style="color:red" (click)="like(a)"
                                                    *ngIf="a.liked"></i></a>
                                                <!-- <span (click)="click()"><i class="far fa-heart inlike"  (click)="like(r)" *ngIf="!r.liked"></i> 
                                                    <i class="far fa-heart like" style="color:red"  (click)="like(r)"
                                                        *ngIf="r.liked"></i>{{r.like_count}}</span> -->
                                                <span class="count-lk">{{a.like_count}}</span>
                                            </div>

                                            <div class="shares-count">
                                                <a  class="d-flex align-items-center me-3">
                                                    <i class="fa fa-eye" aria-hidden="true"></i>
            
                                                    <p style="padding: 5px;" class="mb-0 doc">0</p>
                                                </a>
                                            </div>

                                            <div class="shares-count">
                                                <a (click)="ope(modal,a)" class="quick-actions-icons"><i
                                                    class="fa fa-comment-o"></i></a>
                                                <span class="count-shr">{{a.comment_count}}</span>
                                            </div>

                                            <!--Post Comments-->
                                            <ng-template #modal let-modal>


                                                <article>
                                                    <div class="component-box-1 ">
                                                        <div class="user-info-box-main">

                                                            <single-comment-form comment-form [postId2]="a.id" [categoryId]="3" (onSubmit)="onCommentCreate(a.id)">

                                                            </single-comment-form>

                                                            <div class="user-info-box ">
                                                                <h4>Comments </h4>

                                                                <section class="user-info-box-inner no-scroll text-left h-0 p-4">

                                                                    <div *ngFor="let c of comments  | paginate
                                                                        : {
                                                                            id: 'pagination2',
                                                                            itemsPerPage: tableSizecmt,
                                                                            currentPage: pagecmt,
                                                                            totalItems: countcmt
                                                                        };
                                                                        let i = index
                                                                    ">

                                                                        <single-comment [comment]="c" [groupId]="c.id" (onSubmit)="onCommentCreate(a.id)">
                                                                        </single-comment>
                                                                        <div style="margin-left:100px">
                                                                            <single-comment *ngFor="let c1 of c.comments;" [comment]="c1" [groupId]="c.id" [isReply]="true" (onSubmit)="onCommentCreate(a.id)">
                                                                            </single-comment>
                                                                        </div>


                                                                    </div>
                                                                </section>
                                                                <div class="d-flex justify-content-center">
                                                                    <pagination-controls id="pagination2" previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChangecmt($event)">
                                                                    </pagination-controls>
                                                                </div>

                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>

                                            </ng-template>






                                            <div class="comments-count">
                                                <a (click)="openshare(modalshare)" class="quick-actions-icons"><i
                                                    class="fa fa-share-alt" aria-hidden="true"></i>
                                            </a>
                                                <span class="count-cmnt">5</span>
                                            </div>
                                            <ng-template #modalshare let-modal>

                                                <share-buttons [theme]="'material-dark'" [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']" [show]="15" [url]="getLocation(a.id)" [autoSetMeta]="false">
                                                </share-buttons>


                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                                </div>
                                            </ng-template>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngx-slick-carousel>


                </div>
                <div style="margin-top: 3em; text-align: center;" class="button">
                    <button style="font-size:20px; background-color: rgba(235, 82, 82, 0.842); color: white !important;" class="btn">More Articles</button>
                </div>
            </div>
        </div>
    </section>
    <!-- /most popular articles -->

        <!-- Judgements -->
  
    <section class="items-grid section custom-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="res-cen-car">
                        <div class="heading-cu col-md-6">
                            <h2 style="text-align: left;" class="wow fadeInUp" data-wow-delay=".4s">
                                Judgements</h2>
                        </div>

                    </div>
                </div>
            </div>

            <div class="single-head">
                <div class="row">
                    <ngx-slick-carousel class="carousel user-info-box-inner no-scroll overflow-hidden" #slickModal="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let j of judgements;" class="slide text-left">
                            <div class="single-grid wow fadeInUp" style="margin: 10px;" data-wow-delay=".2s">
                                <div class="">
                                    <div style="padding: 15px; border: 1px solid rgb(209, 208, 208); border-radius: 2%;" class="">

                                        <div class="user-block">
                                            <div class="image1">
                                                <img *ngIf="j.member_profile_pic_url" [src]="getImagePic(j)" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                                <img *ngIf="!j.member_profile_pic_url" src="../../assets/images/profile-user-round.png" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                            </div>
                                            <div class="user-info2">
                                                <a class="usr-nm" href="#">{{j.member_details[0]['first_name']}}</a>
                                                <span class="time">{{j.created_at}}</span>
                                            </div>
                                        </div>

                                        <hr>

                                        <h4 class="title rc">
                                            <a [attr.href]="'/resource-centre/'+ j.id + '/resource-docfree'">
                                            {{j.rc_title}}
                                        </a>
                                        </h4>
                                        <p class="update-time" [innerHtml]="j.rc_description" ngx-clamp [ngxClampOptions]="{ clamp: 3}">

                                        </p>

                                        <hr>

                                        <div class="social-count">
                                            <div class="likes-count">
                                                <a class="quick-actions-icons" (click)="click()"><i class="fa fa-heart-o"
                                                    (click)="like(j)" *ngIf="!j.liked"></i>
                                                <i class="fa fa-heart-o" style="color:red" (click)="like(j)"
                                                    *ngIf="j.liked"></i></a>
                                                <span class="count-lk">{{j.like_count}}</span>
                                            </div>

                                            <div class="shares-count">
                                                <a  class="d-flex align-items-center me-3">
                                                    <i class="fa fa-eye" aria-hidden="true"></i>
            
                                                    <p style="padding: 5px;" class="mb-0 doc">0</p>
                                                </a>
                                            </div>

                                            <div class="shares-count">
                                                <a (click)="ope(modal,j)" class="quick-actions-icons"><i
                                                    class="fa fa-comment-o"></i></a>
                                                <span class="count-shr">{{j.comment_count}}</span>
                                            </div>
                                            <!--Post Comments-->
                                            <ng-template #modal let-modal>


                                                <article>
                                                    <div class="component-box-1 ">
                                                        <div class="user-info-box-main">

                                                            <single-comment-form comment-form [postId2]="j.id" [categoryId]="2" (onSubmit)="onCommentCreate(j.id)">

                                                            </single-comment-form>

                                                            <div class="user-info-box ">
                                                                <h4>Comments </h4>

                                                                <section class="user-info-box-inner no-scroll text-left h-0 p-4">

                                                                    <div *ngFor="let c of comments  | paginate
                        : {
                            id: 'pagination2',
                            itemsPerPage: tableSizecmt,
                            currentPage: pagecmt,
                            totalItems: countcmt
                            };
                    let i = index
                    ">

                                                                        <single-comment [comment]="c" [groupId]="c.id" (onSubmit)="onCommentCreate(j.id)">
                                                                        </single-comment>
                                                                        <div style="margin-left:100px">
                                                                            <single-comment *ngFor="let c1 of c.comments;" [comment]="c1" [groupId]="c.id" [isReply]="true" (onSubmit)="onCommentCreate(j.id)">
                                                                            </single-comment>
                                                                        </div>


                                                                    </div>
                                                                </section>
                                                                <div class="d-flex justify-content-center">
                                                                    <pagination-controls id="pagination2" previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChangecmt($event)">
                                                                    </pagination-controls>
                                                                </div>

                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>

                                            </ng-template>


                                            <div class="comments-count">
                                                <a (click)="openshare(modalshare)" class="quick-actions-icons"><i
                                                    class="fa fa-share-alt" aria-hidden="true"></i>
                                            </a>
                                                <span class="count-cmnt">5</span>
                                            </div>
                                            <ng-template #modalshare let-modal>

                                                <share-buttons [theme]="'material-dark'" [include]="['facebook','twitter','linkedin','pinterest','whatsapp','email']" [show]="15" [url]="getLocation(j.id)" [autoSetMeta]="false">
                                                </share-buttons>


                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                                </div>
                                            </ng-template>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngx-slick-carousel>


                </div>

            </div>
        </div>
    </section>

    

     <!-- Bare Acts -->
  
     <section class="items-grid section custom-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="res-cen-car">
                        <div class="heading-cu col-md-6">
                            <h2 style="text-align: left;" class="wow fadeInUp" data-wow-delay=".4s">
                                Bare Acts</h2>
                        </div>

                    </div>
                </div>
            </div>

            <div class="single-head">
                <div class="row">
                   
                    <ngx-slick-carousel class="carousel user-info-box-inner no-scroll overflow-hidden" #slickModal="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let b of bareacts;" class="slide text-left">
                            <div class="single-grid wow fadeInUp" style="margin: 10px;" data-wow-delay=".2s">
                                <div class="">
                                    <div style="padding: 15px; border: 1px solid rgb(209, 208, 208); border-radius: 2%;" class="">
                                    
                                        <div class="user-block">
                                            <div class="image1">
                                                <img *ngIf="b.member_profile_pic_url" [src]="getImagePic(b)" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                                <img *ngIf="!b.member_profile_pic_url" src="../../assets/images/profile-user-round.png" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                            </div>
                                            <div class="user-info2">
                                                <a class="usr-nm" href="#">{{b.member_details[0]['first_name']}}</a>
                                                <span class="time">{{b.created_at}}</span>
                                            </div>
                                        </div>

                                        <hr>

                                        <h4 class="title rc">
                                            <a [attr.href]="'/resource-centre/'+ b.id + '/resource-docfree'">
                                            {{b.rc_title}}
                                        </a>
                                        </h4>
                                        <p class="update-time" [innerHtml]="b.rc_description" ngx-clamp [ngxClampOptions]="{ clamp: 3}">

                                        </p>

                                        <hr>

                                        <div class="social-count">
                                            <div class="likes-count">
                                                <a class="quick-actions-icons" (click)="click()"><i class="fa fa-heart-o"
                                                    (click)="like(b)" *ngIf="!b.liked"></i>
                                                <i class="fa fa-heart-o" style="color:red" (click)="like(b)"
                                                    *ngIf="b.liked"></i></a>
                                                <span class="count-lk">{{b.like_count}}</span>
                                            </div>

                                            <div class="shares-count">
                                                <a  class="d-flex align-items-center me-3">
                                                    <i class="fa fa-eye" aria-hidden="true"></i>
            
                                                    <p style="padding: 5px;" class="mb-0 doc">0</p>
                                                </a>
                                            </div>

                                            <div class="shares-count">
                                                <a (click)="ope(modal,b)" class="quick-actions-icons"><i
                                                    class="fa fa-comment-o"></i></a>
                                                <span class="count-shr">{{b.comment_count}}</span>
                                            </div>
                                            <!--Post Comments-->
                                            <ng-template #modal let-modal>


                                                <article>
                                                    <div class="component-box-1 ">
                                                        <div class="user-info-box-main">

                                                            <single-comment-form comment-form [postId2]="b.id" [categoryId]="2" (onSubmit)="onCommentCreate(b.id)">

                                                            </single-comment-form>

                                                            <div class="user-info-box ">
                                                                <h4>Comments </h4>

                                                                <section class="user-info-box-inner no-scroll text-left h-0 p-4">

                                                                    <div *ngFor="let c of comments  | paginate
                        : {
                            id: 'pagination2',
                            itemsPerPage: tableSizecmt,
                            currentPage: pagecmt,
                            totalItems: countcmt
                          };
                    let i = index
                  ">

                                                                        <single-comment [comment]="c" [groupId]="c.id" (onSubmit)="onCommentCreate(b.id)">
                                                                        </single-comment>
                                                                        <div style="margin-left:100px">
                                                                            <single-comment *ngFor="let c1 of c.comments;" [comment]="c1" [groupId]="c.id" [isReply]="true" (onSubmit)="onCommentCreate(b.id)">
                                                                            </single-comment>
                                                                        </div>


                                                                    </div>
                                                                </section>
                                                                <div class="d-flex justify-content-center">
                                                                    <pagination-controls id="pagination2" previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChangecmt($event)">
                                                                    </pagination-controls>
                                                                </div>

                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>

                                            </ng-template>


                                            <div class="comments-count">
                                                <a (click)="openshare(modalshare)" class="quick-actions-icons"><i
                                                    class="fa fa-share-alt" aria-hidden="true"></i>
                                            </a>
                                                <span class="count-cmnt">5</span>
                                            </div>
                                            <ng-template #modalshare let-modal>

                                                <share-buttons [theme]="'material-dark'" [include]="['facebook','twitter','linkedin','pinterest','whatsapp','email']" [show]="15" [url]="getLocation(b.id)" [autoSetMeta]="false">
                                                </share-buttons>


                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                                </div>
                                            </ng-template>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngx-slick-carousel>


                </div>

            </div>
        </div>
    </section>

    <!-- Legal Procedure -->

    <section class="items-grid section custom-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="res-cen-car">
                        <div class="heading-cu col-md-6">
                            <h2 style="text-align: left;" class="wow fadeInUp" data-wow-delay=".4s">
                                Legal Procedures</h2>
                        </div>

                    </div>
                </div>
            </div>

            <div class="single-head">
                <div class="row">
                    <ngx-slick-carousel class="carousel user-info-box-inner no-scroll overflow-hidden" #slickModal="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let p of resourcelegalProcedure;" class="slide text-left">
                            <div class="single-grid wow fadeInUp" style="margin: 10px;" data-wow-delay=".2s">
                                <div class="">
                                    <div style="padding: 15px; border: 1px solid rgb(209, 208, 208); border-radius: 2%;" class="">

                                        <div class="user-block">
                                            <div class="image1">
                                                <img *ngIf="p.member_profile_pic_url" [src]="getImagePic(p)" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                                <img *ngIf="!p.member_profile_pic_url" src="../../assets/images/profile-user-round.png" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                            </div>
                                            <div class="user-info2">
                                                <a class="usr-nm" href="#">{{p.member_details[0]['first_name']}}</a>
                                                <span class="time">{{p.created_at}}</span>
                                            </div>
                                        </div>

                                        <hr>

                                        <h4 class="title rc">
                                            <a [attr.href]="'/resource-centre/'+ p.id + '/resource-docfree'">
                                            {{p.rc_title}}
                                        </a>
                                        </h4>
                                        <p class="update-time" [innerHtml]="p.rc_description" ngx-clamp [ngxClampOptions]="{ clamp: 3}">

                                        </p>

                                        <hr>

                                        <div class="social-count">
                                            <div class="likes-count">
                                                <a class="quick-actions-icons" (click)="click()"><i class="fa fa-heart-o"
                                                    (click)="like(p)" *ngIf="!p.liked"></i>
                                                <i class="fa fa-heart-o" style="color:red" (click)="like(p)"
                                                    *ngIf="p.liked"></i></a>
                                                <span class="count-lk">{{p.like_count}}</span>
                                            </div>

                                            <div class="shares-count">
                                                <a  class="d-flex align-items-center me-3">
                                                    <i class="fa fa-eye" aria-hidden="true"></i>
            
                                                    <p style="padding: 5px;" class="mb-0 doc">0</p>
                                                </a>
                                            </div>

                                            <div class="shares-count">
                                                <a (click)="ope(modal,p)" class="quick-actions-icons"><i
                                                    class="fa fa-comment-o"></i></a>
                                                <span class="count-shr">{{p.comment_count}}</span>
                                            </div>
                                            <!--Post Comments-->
                                            <ng-template #modal let-modal>


                                                <article>
                                                    <div class="component-box-1 ">
                                                        <div class="user-info-box-main">

                                                            <single-comment-form comment-form [postId2]="p.id" [categoryId]="2" (onSubmit)="onCommentCreate(p.id)">

                                                            </single-comment-form>

                                                            <div class="user-info-box ">
                                                                <h4>Comments </h4>

                                                                <section class="user-info-box-inner no-scroll text-left h-0 p-4">

                                                                    <div *ngFor="let c of comments  | paginate
                        : {
                            id: 'pagination2',
                            itemsPerPage: tableSizecmt,
                            currentPage: pagecmt,
                            totalItems: countcmt
                          };
                    let i = index
                  ">

                                                                        <single-comment [comment]="c" [groupId]="c.id" (onSubmit)="onCommentCreate(p.id)">
                                                                        </single-comment>
                                                                        <div style="margin-left:100px">
                                                                            <single-comment *ngFor="let c1 of c.comments;" [comment]="c1" [groupId]="c.id" [isReply]="true" (onSubmit)="onCommentCreate(p.id)">
                                                                            </single-comment>
                                                                        </div>


                                                                    </div>
                                                                </section>
                                                                <div class="d-flex justify-content-center">
                                                                    <pagination-controls id="pagination2" previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChangecmt($event)">
                                                                    </pagination-controls>
                                                                </div>

                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>

                                            </ng-template>


                                            <div class="comments-count">
                                                <a (click)="openshare(modalshare)" class="quick-actions-icons"><i
                                                    class="fa fa-share-alt" aria-hidden="true"></i>
                                            </a>
                                                <span class="count-cmnt">5</span>
                                            </div>
                                            <ng-template #modalshare let-modal>

                                                <share-buttons [theme]="'material-dark'" [include]="['facebook','twitter','linkedin','pinterest','whatsapp','email']" [show]="15" [url]="getLocation(p.id)" [autoSetMeta]="false">
                                                </share-buttons>


                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                                </div>
                                            </ng-template>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngx-slick-carousel>


                </div>

            </div>
        </div>
    </section>

    <section class="items-grid section custom-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="res-cen-car">
                        <div class="heading-cu col-md-6">
                            <h2 style="text-align: left;" class="wow fadeInUp" data-wow-delay=".4s">
                                Legal Format</h2>
                        </div>

                    </div>
                </div>
            </div>

            <div class="single-head">
                <div class="row">


                    <ngx-slick-carousel class="carousel user-info-box-inner no-scroll overflow-hidden" #slickModal="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let f of resourcelegalformats;" class="slide text-left">
                            <div class="single-grid wow fadeInUp" style="margin: 10px;" data-wow-delay=".2s">
                                <div class="">
                                    <div style="padding: 15px; border: 1px solid rgb(209, 208, 208); border-radius: 2%;" class="">

                                        <div class="user-block">
                                            <div class="image1">
                                                <img *ngIf="f.member_profile_pic_url" [src]="getImagePic(f)" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                                <img *ngIf="!f.member_profile_pic_url" src="../../assets/images/profile-user-round.png" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                            </div>
                                            <div class="user-info2">
                                                <a class="usr-nm" href="#">{{f.member_details[0]['first_name']}}</a>
                                                <span class="time">{{f.created_at}}</span>
                                            </div>
                                        </div>

                                        <hr>

                                        <h4 class="title rc">
                                            <a [attr.href]="'/resource-centre/'+ f.id + '/resource-docfree'">
                                            {{f.rc_title}}
                                        </a>
                                        </h4>
                                        <p class="update-time" [innerHtml]="f.rc_description" ngx-clamp [ngxClampOptions]="{ clamp: 3}">

                                        </p>

                                        <hr>

                                        <div class="social-count">
                                            <div class="likes-count">
                                                <a class="quick-actions-icons" (click)="click()"><i class="fa fa-heart-o"
                                                    (click)="like(f)" *ngIf="!f.liked"></i>
                                                <i class="fa fa-heart-o" style="color:red" (click)="like(f)"
                                                    *ngIf="f.liked"></i></a>
                                                <span class="count-lk">{{f.like_count}}</span>
                                            </div>

                                            <div class="shares-count">
                                                <a  class="d-flex align-items-center me-3">
                                                    <i class="fa fa-eye" aria-hidden="true"></i>
            
                                                    <p style="padding: 5px;" class="mb-0 doc">0</p>
                                                </a>
                                            </div>

                                            <div class="shares-count">
                                                <a (click)="ope(modal,f)" class="quick-actions-icons"><i
                                                    class="fa fa-comment-o"></i></a>
                                                <span class="count-shr">{{f.comment_count}}</span>
                                            </div>
                                            <!--Post Comments-->
                                            <ng-template #modal let-modal>


                                                <article>
                                                    <div class="component-box-1 ">
                                                        <div class="user-info-box-main">

                                                            <single-comment-form comment-form [postId2]="f.id" [categoryId]="3" (onSubmit)="onCommentCreate(f.id)">

                                                            </single-comment-form>

                                                            <div class="user-info-box ">
                                                                <h4>Comments </h4>

                                                                <section class="user-info-box-inner no-scroll text-left h-0 p-4">

                                                                    <div *ngFor="let c of comments  | paginate
                        : {
                            id: 'pagination2',
                            itemsPerPage: tableSizecmt,
                            currentPage: pagecmt,
                            totalItems: countcmt
                          };
                    let i = index
                  ">

                                                                        <single-comment [comment]="c" [groupId]="c.id" (onSubmit)="onCommentCreate(f.id)">
                                                                        </single-comment>
                                                                        <div style="margin-left:100px">
                                                                            <single-comment *ngFor="let c1 of c.comments;" [comment]="c1" [groupId]="c.id" [isReply]="true" (onSubmit)="onCommentCreate(f.id)">
                                                                            </single-comment>
                                                                        </div>


                                                                    </div>
                                                                </section>
                                                                <div class="d-flex justify-content-center">
                                                                    <pagination-controls id="pagination2" previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChangecmt($event)">
                                                                    </pagination-controls>
                                                                </div>

                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>

                                            </ng-template>


                                            <div class="comments-count">
                                                <a (click)="openshare(modalshare)" class="quick-actions-icons"><i
                                                    class="fa fa-share-alt" aria-hidden="true"></i>
                                            </a>
                                                <span class="count-cmnt">5</span>
                                            </div>
                                            <ng-template #modalshare let-modal>

                                                <share-buttons [theme]="'material-dark'" [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']" [show]="15" [url]="getLocation(f.id)" [autoSetMeta]="false">
                                                </share-buttons>


                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                                </div>
                                            </ng-template>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngx-slick-carousel>


                </div>

            </div>
        </div>
    </section>



</div>



<div *ngIf="searchlist.length>0">
    <!-- Most search list -->
    <section class="items-grid section custom-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="res-cen-car">
                        <div class="heading-cu col-md-6">
                            <h2 style="text-align: left;" class="wow fadeInUp" data-wow-delay=".4s">
                                Search List</h2>
                        </div>

                    </div>
                </div>
            </div>

            <div class="single-head">
                <div class="row">                    
                    <div *ngFor="let a of searchlist | filter: filterTerm " class="col-md-4">
                        <div class="single-grid wow fadeInUp" data-wow-delay=".2s">
                            <div class="">
                                <div style="padding: 15px;" class="">

                                    <div class="user-block">
                                        <div class="image1">
                                            <img *ngIf="a.member_profile_pic_url" [src]="getImagePic(a)" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                            <img *ngIf="!a.member_profile_pic_url" src="../../assets/images/profile-user-round.png" data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" data-target="webuiPopover31">
                                            <!-- <img *ngIf="a.member_profile_pic_url" [src]="getImagePic(a)"
                                            style="height: 87%;width: 96%;"> -->
                                            <!-- <img *ngIf="!a.member_profile_pic_url" src="../../assets/images/profile-user-round.png"
                                            alt="" style="height: 87%;width: 96%;"> -->

                                        </div>
                                        <div class="user-info2">
                                            <a class="usr-nm">{{a.member_details[0]['username']}}</a>
                                            <!-- <span class="time">{{a.created_date*1000|date:'yyyy/MM/dd H:m:s'}}</span> -->
                                            <span class="time">{{a.created_at}}</span>
                                        </div>
                                    </div>

                                    <hr>

                                    <h4 class="title rc">
                                        <a [attr.href]="'/resource-centre/'+ a.id + '/resource-docfree'">
                                        {{a.rc_title}}
                                    </a>
                                    </h4>
                                    <p class="update-time" [innerHtml]="a.rc_description" ngx-clamp [ngxClampOptions]="{ clamp: 3}">

                                    </p>

                                    <hr>

                                    <div class="social-count">
                                        <div class="likes-count">
                                            <a class="quick-actions-icons" (click)="click()"><i class="fa fa-heart-o"
                                                (click)="like(a)" *ngIf="!a.liked"></i>
                                            <i class="fa fa-heart-o" style="color:red" (click)="like(a)"
                                                *ngIf="a.liked"></i></a>
                                            <!-- <span (click)="click()"><i class="far fa-heart inlike"  (click)="like(r)" *ngIf="!r.liked"></i> 
                                                <i class="far fa-heart like" style="color:red"  (click)="like(r)"
                                                    *ngIf="r.liked"></i>{{r.like_count}}</span> -->
                                            <span class="count-lk">{{a.like_count}}</span>
                                        </div>

                                      

                                        <div class="shares-count">
                                            <a (click)="ope(modal,a)" class="quick-actions-icons"><i
                                                class="fa fa-comment-o"></i></a>
                                            <span class="count-shr">{{a.comment_count}}</span>
                                        </div>

                                        <!--Post Comments-->
                                        <ng-template #modal let-modal>


                                            <article>
                                                <div class="component-box-1 ">
                                                    <div class="user-info-box-main">

                                                        <single-comment-form comment-form [postId2]="a.id" [categoryId]="3" (onSubmit)="onCommentCreate(a.id)">

                                                        </single-comment-form>

                                                        <div class="user-info-box ">
                                                            <h4>Comments </h4>

                                                            <section class="user-info-box-inner no-scroll text-left h-0 p-4">

                                                                <div *ngFor="let c of comments  | paginate
                    : {
                        id: 'pagination2',
                        itemsPerPage: tableSizecmt,
                        currentPage: pagecmt,
                        totalItems: countcmt
                        };
                let i = index
                ">

                                                                    <single-comment [comment]="c" [groupId]="c.id" (onSubmit)="onCommentCreate(a.id)">
                                                                    </single-comment>
                                                                    <div style="margin-left:100px">
                                                                        <single-comment *ngFor="let c1 of c.comments;" [comment]="c1" [groupId]="c.id" [isReply]="true" (onSubmit)="onCommentCreate(a.id)">
                                                                        </single-comment>
                                                                    </div>


                                                                </div>
                                                            </section>
                                                            <div class="d-flex justify-content-center">
                                                                <pagination-controls id="pagination2" previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChangecmt($event)">
                                                                </pagination-controls>
                                                            </div>

                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>

                                        </ng-template>






                                        <div class="comments-count">
                                            <a (click)="openshare(modalshare)" class="quick-actions-icons"><i
                                                class="fa fa-share-alt" aria-hidden="true"></i>
                                        </a>
                                            <span class="count-cmnt">5</span>
                                        </div>
                                        <ng-template #modalshare let-modal>

                                            <share-buttons [theme]="'material-dark'" [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']" [show]="15" [url]="getLocation(a.id)" [autoSetMeta]="false">
                                            </share-buttons>


                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                                            </div>
                                        </ng-template>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 3em; text-align: center;" class="button">
                    <button style="font-size:20px; background-color: rgba(235, 82, 82, 0.842); color: white;" class="btn">More Articles</button>
                </div>
            </div>
        </div>
    </section>
    <!-- /most search list -->

</div>



<section class="app-promo">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 left-box">
                <img class="custom-img" src="assets/images/Download Section/Group 155.png" alt="dowmload-soolegal">
            </div>
            <div class="col-md-8 content">


                <h2 style="color:white;">Download Soolegal App</h2>

                <div class="icon-area1">
                    <div class="icon-box11">
                        <h5 class="alertsbuttons"><span><img src="assets/images/Download Section/Group 145.png"></span> Legal Alerts
                        </h5>
                    </div>
                    <div class="icon-box11">
                        <h5 class="alertsbuttons"><span><img src="assets/images/Download Section/Group 146.png"></span> Search Documents
                        </h5>
                    </div>
                    <div class="icon-box11">
                        <h5 class="alertsbuttons"><span><img src="assets/images/Download Section/Group 147.png"></span> Instant Connect
                        </h5>
                    </div>
                </div>

                <div class="input-group1 pn">
                    <span class="input-group1-text pn number"><span><b>+ 91</b></span></span>
                    <!-- <span class="input-group1-text pn" id="basic-addon3"><span><b>+ 91</b></span></span> -->
                    <input type="password" class="form-control ds" id="phonenumber" placeholder="Enter Your Mobile Number" aria-label="phonenumber" aria-describedby="basic-addon3">
                    <span class="input-group1-text gt" id="basic-addon3"><span>Get Link in SMS</span></span>
                </div>

                <!--app store btn-->
                <div class="app-store-btn">
                    <a href=""><img class="play-store" alt="outofdate-badge" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"></a>
                    <a href=""><img class="app-store" src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" alt="Apple app store"></a>
                </div>
                <!--app store btn end-->

            </div>
        </div>
    </div>



</section>

<!-- Most popular videos -->
<section class="items-grid section custom-padding">
    <div class="container">
        <div class="row">
            <div class="col-12" style="margin-top: -40px;">
                <div class="res-cen-car">
                    <div class="heading-cu col-md-6">
                        <h2 style="text-align: left;" class="wow fadeInUp" data-wow-delay=".4s">
                            Most Popular videos</h2>
                    </div>
                    <div class="control-box arrows">
                        <a class="btn-floating" href="#multi-item-example" data-slide="prev"><i
                                class="fas fa-chevron-left"></i></a>
                        <a class="btn-floating" href="#multi-item-example" data-slide="next"><i
                                class="fas fa-chevron-right"></i></a>
                    </div>

                </div>
            </div>
        </div>

        <div class="single-head">
            <div class="row">   

                        <div class="col-12">
                            <ngx-slick-carousel class="carousel user-info-box-inner no-scroll overflow-hidden" #slickModal="slick-carousel" [config]="slideConfig">
                                <div ngxSlickItem *ngFor="let v of get_rcpopular_videos;" class="slide text-left">
                            <!-- Start Single Grid -->
                            <div class="single-grid wow fadeInUp" data-wow-delay=".2s">
                                <div class="image">
                                    <a href="item-details.html" class="thumbnail"><iframe width="420" height="280"
                                            src= {{v.rc_video_url}}>
                                        </iframe></a>
        
                                </div>
                                <div class="content">
                                    <div class="top-content">
                                        <ul class="info-list">
                                            <li><a href="javascript:void(0)">{{v.member_details[0]['first_name']}} </a></li>
                                            
                                            <li><a href="javascript:void(0)"> {{v.created_at}}</a>
                                            </li>
                                            <!-- <li><a href="javascript:void(0)"> {{v.created_date*1000|date:'yyyy/MM/dd H:m:s'}}</a>
                                            </li> -->
                                        </ul>
        
                                        <h3 class="title">
                                            <a href="item-details.html">
                                                {{v.rc_title}}
                                            </a>
                                        </h3>
        
        
                                        <div class="user-info video">
                                            <div class="author-image">
                                                <a ><img *ngIf="v.member_profile_pic_url" [src]="getImagePic(v)" >
                                                    <img *ngIf="!v.member_profile_pic_url" [src]="getImagePic(v)" >
                                                    <span>Alex Jui</span></a>
                                            </div>
        
                                            <div style="color: black;" class="quick-actions">
                                                <a  class="quick-actions-icons"><i class="lni lni-heart"></i></a>
                                                <a  class="quick-actions-icons"><i class="lni lni-comments"></i></a>
                                                <a  class="quick-actions-icons"><i class="lni lni-share"></i></a>
                                            </div>
                                        </div>
        
        
        
                                    </div>
        
        
                                </div>
                            </div>
                            <!-- End Single Grid -->
                        </div>
                    </ngx-slick-carousel>
                    </div>
            </div>
            <div style="margin-top: 3em; text-align: center;" class="button">
                <button style="font-size:20px; background-color: rgba(235, 82, 82, 0.842); color: white !important;" class="btn">More Articles</button>
            </div>
        </div>
    </div>
</section>
<!-- Most popular videos end -->

<!-- Start Why Choose Area -->
<section class="why1-choose section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-title">
                    <h2 class="wow fadeInUp" data-wow-delay=".4s">Our top contributor</h2>

                </div>
            </div>
        </div>


        <ngx-slick-carousel class="carousel user-info-box-inner no-scroll overflow-hidden" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let c of contributor" class="slide text-left">

                <div class="row">
                    <div class="col-lg-12">
                        <div class="lawyer-tile">
                            <div class="basic-info">
                                <h4 class="lawyer-name">{{c.username}}</h4>
                            <p class="qualification">{{c.qualification}}</p>

                            <div class="author">
                                <div class="author-image lawyer">
                                    <a href="javascript:void(0)"><img src="assets/images/items-grid/author-1.jpg" alt="#">
                                    </a>
                                </div>
                            </div>
                            </div>
                            

                            <div class="lawyer-info">
                                <div style="padding: 10px; color: black;" class="lcn-in">
                                    <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;{{c.location}}</span></p>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <h6 class="review-count">Roar Ratings</h6>
                                    <div class="stars">
                                        <span class="fa fa-star checked"></span>
                                        <span class="fa fa-star checked"></span>
                                        <span class="fa fa-star checked"></span>
                                        <span class="fa fa-star"></span>
                                        <span class="fa fa-star"></span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <h6 class="review-count">Articles</h6>
                                    <div class="stars">
                                        <h6 class="review-count">Videos {{c.video_count}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Start Single List -->
                    </div>



                </div>
            </div>
            <!-- </div>
                </div> -->
            <!-- </div> -->

        </ngx-slick-carousel>


    </div>
</section>
<!-- /End Why Choose Area -->
<app-login (loginEvent)="loginSubmit($event)"></app-login>













































