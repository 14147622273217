


<main role="main" class="main" *ngIf="public">
    <section class="profile-details">
        <div class="container">
            <!-- #1 - Box -->
            <div class="component-box-1 mb-0">
                <div class="user-info-box-main">
                    <div class="user-info-box">
                        <h4 class="">Profile</h4>
                        <div class="user-info-box-inner no-scroll user-info-box-inner no-scroll p-5">
                            <div class="row">
                                <div class="col-md-4">
                                    <article>
                                        <div>
                                            <div class="user-info-box">

                                                <div class="user-info-box-inner p-0 no-scroll">
                                                    <div class="user-box" *ngIf="public">
                                                        <div class="ribbon ribbon-top-right"><span *ngIf="public.isPrime">Premium</span></div>
                                                        <img src="../../assets/images/profile-user-round.png" *ngIf="!public.profilepicture" class="img-fluid max-255">
                                                        <img *ngIf="public.profilepicture" src="{{public.profilepicture}}" class="img-fluid" style="width: 255px; height: 255px; border-radius: 100%;">

                                                        <div class="profile-text">
                                                            <div class="name" *ngIf="public.fullname">{{public.fullname}}</div>

                                                            <p class="small">
                                                                {{public.member_role}} <br> {{public.email}}
                                                                <br> {{public.contact_num}}
                                                                <br> {{qualification[0].dname}}
                                                            </p>
                                                            <div class="pc-box" *ngIf="public.country_name">{{public.city_name}}({{public.country_name}})</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>

                                </div>

                                <div class="col-md-8" *ngIf="public">
                                    <div class="profile-details text-left base-text-color">
                                        <p [innerHTML]="public.about"></p>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="user-box" style="padding:0px;">
                                        <div class="progress-bar-box">
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-lg-6 col-md-6">

                                    <ul class="nav nav-tabs" id="getviewsloc" role="tablist" data-vusid="1" style="border: 0;">
                                        <li style="padding: 6px 13px 0px 0px;" class="active text-center">
                                            <a style="color:#66757f;" title="Connections" href="https://test.soolegal.com/member/associates">
                                                <b>Connections<br> <i class="fa fa-fw fa-users"></i>{{public.connection}}</b>
                                            </a>

                                        </li>
                                        <li style="padding: 6px 13px 0px 0px;" class="text-center">
                                            <a style="color:#66757f;" title="Followers" href="https://test.soolegal.com/member/followers">
                                                <b>Followers <br><i class="fa fa-fw fa-hand-peace-o"></i>{{public.followers}}</b>
                                            </a>

                                        </li>

                                    </ul>

                                </div>
                                <div class="col-lg-6 col-md-6">

                                    <button style="margin: 5px; background-color: #CCC; border: 1px solid #DDD!important;" type="button" class="btn btn-primary float-right" (click)="openfeedbacks()" href="javascript:void(0)" data-toggle="modal" data-target="#loginmember" title="Follow"*ngIf="sameuser">
                                      <span class="button-text"><i class="fa fa-fw fa-comments-o"></i>  Add Feedback </span>
                                    </button>
                                    <button style="margin: 5px;" type="button" *ngIf="!public.follow && sameuser" class="btn btn-primary float-right" (click)="followrequest()" title="Follow" #follow>
                                      <span class="button-text"><i class="fa fa-fw fa-hand-peace-o"></i>  Follow </span>
                                    </button>
                                    <button style="margin: 5px; background-color: #BBB; border: 1px solid #DDD!important;" type="button" *ngIf="public.follow==1 && sameuser" class="btn btn-primary float-right" (click)="unfollowrequest()" title="Follow" #follow>
                                        <span class="button-text"><i class="fa fa-fw fa-hand-peace-o"></i>  unFollow </span>
                                    </button>

                                    <button style="margin: 5px;" type="button" *ngIf="public.isConnected==''&& sameuser" (click)="connectionrequest()" [class]="mcconnection" #connection>
                                        <span class="button-text"><i class="fa fa-fw fa-hourglass-half"></i>  Add Connection </span>
                                    </button>
                                    <button style="margin: 5px; background-color: #ffc107; border: 1px solid #ffc107!important;" type="button" *ngIf="public.isConnected==0&&public.isConnected!=''&& sameuser" class="btn btn-primary float-right" #connection>
                                      <span class="button-text"><i class="fa fa-fw fa-hourglass-half"></i>  Requested </span>
                                    </button>
                                    <button style="margin: 5px; background-color: #28a745; border: 1px solid #28a745!important;" type="button" *ngIf="public.isConnected==1&& sameuser" class="btn btn-primary float-right" #connection>
                                      <span class="button-text"><i class="fa fa-fw fa-hourglass-half"></i>  Connected </span>
                                    </button>
                                    <button style="margin: 5px;" type="button" *ngIf="public.isConnected==2&& sameuser" class="mcconnection float-right" #connection>
                                      <span class="button-text"><i class="fa fa-fw fa-hourglass-half"></i> Not Accepted </span>
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>
    </section>

    <section class="profile-details mt-4">
        <div class="container">
            <!-- #1 - Box -->
            <div class="component-box-1 mb-0">
                <div class="user-info-box-main">
                    <div class="user-info-box">
                        <h4 class=""></h4>
                        <div class="user-info-box-inner no-scroll user-info-box-inner no-scroll">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="panel panel-default has-shadow">
                                        <div class="panel-body ">
                                            <div class="legal-border marg-bot10">PRACTICE AREA</div>
                                            <a href="#" class="btn btn-default marg-bot5 btn-sm-practice-area btn-default-practice-area" style="font-size:14px; margin:5px;" *ngFor="let data of public.practicearea">{{data.name}}</a>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-lg-6">
                                    <div class="panel panel-default has-shadow">
                                        <div class="panel-body readmore-text">
                                            <div class="legal-border marg-bot10">ADDRESS </div>
                                            <ul class="mrgN no-padd text short" style="list-style-type: none;">
                                                <li><small><i title="Office Address" class="text-muted fa fa-fw fa-building-o"></i> {{public.address}}</small></li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-6">
                                    <div class="panel panel-default has-shadow">
                                        <div class="panel-body readmore-text ">
                                            <div class="legal-border marg-bot10">Bar Council/Association Memberships</div>
                                            <ul class="mrgN no-padd text short" style="list-style-type: none;">
                                                <li><small>{{public.barassociation}}   </small></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-6">
                                    <div class="panel panel-default has-shadow">
                                        <div class="panel-body readmore-text ">
                                            <div class="legal-border marg-bot10">BAR ASSOCIATION</div>
                                            <ul class="mrgN no-padd text short" style="list-style-type: none;">
                                                <li><small>{{public.barassociation}}   </small></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="panel panel-default has-shadow">
                                        <div class="panel-body readmore-text ">
                                            <div class="legal-border marg-bot10">Academic Qualifications</div>
                                            <ul class="mrgN no-padd text short" style="list-style-type: none;">
                                                <li *ngFor="let data of public.qualification"><small>{{data.dname}}   </small></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="panel panel-default has-shadow">
                                        <div class="panel-body readmore-text ">
                                            <div class="legal-border marg-bot10">Work Experience</div>
                                            <ul class="mrgN no-padd text short" style="list-style-type: none;">
                                                <li *ngFor="let experience of public.work_experience"><small>Year: {{experience.start_date}} - {{experience.end_date}} at {{experience.company}} </small><br>
                                                <small> Description: {{experience.description}}
                                                </small>
                                                <hr>
                                            </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="panel panel-default has-shadow">
                                        <div class="panel-body readmore-text ">
                                            <div class="legal-border marg-bot10">Honors and Awards</div>
                                            <ul class="mrgN no-padd text short" style="list-style-type: none;">
                                                <li *ngFor="let award of public.awards"><small>{{award.description}}   </small></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="panel panel-default has-shadow">
                                        <div class="panel-body readmore-text ">
                                            <div class="legal-border marg-bot10">Publications and Books</div>
                                            <!-- <ul class="mrgN no-padd text short" style="list-style-type: none;">
                                                <li *ngFor="let book of public.books_and_article"><small>{{book.}}   </small></li>
                                            </ul> -->
                                            <div class="row ">
                                                <div class="col-md-2 pt-2" *ngFor="let book of public.books_and_article">
                                                    <img src="{{book.attachment}}" class="thumbnail" style="height: 50px; width: 50px;">
                                                    {{book.title}}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="panel panel-default has-shadow">
                                        <div class="panel-body readmore-text ">
                                            <div class="legal-border marg-bot10">ROAR</div>
                                            <!-- <ul class="mrgN no-padd text short" style="list-style-type: none;">
                                                <li><small>{{public.barassociation}}   </small></li>
                                            </ul> -->
                                            <div class="row py-2">
                                                <div class="col-md-3" *ngFor="let roar of public.roar">
                                                    <div class="card bg-dark text-white">
                                                        <a href=""><img src="{{roar.image_url}}" class="card-img" alt="..."></a>
                                                        <div class="card-img-overlay">
                                                          <small class="card-title">{{roar.title}}</small><br>
                                                          <small class="card-text">{{roar.publish_date * 1000 | date}}</small>
                                                        </div>
                                                      </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="panel panel-default has-shadow">
                                        <div class="panel-body readmore-text ">
                                            <div class="legal-border marg-bot10">Videos</div>
                                            <ul class="mrgN no-padd text short" style="list-style-type: none;">
                                                <li><small>   </small></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="partners-main-box my-3 mt-5">
        <div class="container-fluid p-0">
            <div class="bg-gray-new shadow-none">
                <div class="row justify-content-between align-items-center">
                    <div class="col-md-12 col-lg-5 my-2 logo-box">
                        <img src="../../assets/images/logo.png" alt="" class="im" />
                        <h4 class="title-1">Partner Services</h4>
                    </div>
                    <div class="col-md-12 col-lg-7 my-2">
                        <div class="partners owl-carousel owl-theme owl-loaded owl-drag">
                            <div class="owl-stage-outer">
                                <div class="owl-stage" style="transform: translate3d(-537px, 0px, 0px); transition: all 0.25s ease 0s; width: 1255px;">
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                    <div class="owl-item active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-nav disabled">
                                <button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>
                            </div>
                            <div class="owl-dots disabled"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>
<div class="modal fade" id="feedback" tabindex="-1" aria-labelledby="feedback" aria-hidden="true">
    <div class="modal-dialog">
        <form id="form-addclientfeedbacks" novalidate="novalidate">
            <div class="modal-content panel panel-primary">
                <div class="modal-header panel-heading">
                    <button type="button" class="close close-model" data-dismiss="modal" #closefeedback>×</button>
                    <h4 class="modal-title">Add Feedbacks</h4>
                </div>

                <div class="modal-body panel-body">
                    <div class="row">
                        <div class="col-sm-12" id="editprofile-form-error"></div>
                        <!-- <input type="hidden" name="member_id" [(ngModel)]="feedback.member_id" id="member_id" value="{{public.member_id}}"> -->

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" name="name" [(ngModel)]="feedback.name" class="form-control" id="name" placeholder="Name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="text" name="email" [(ngModel)]="feedback.email" class="form-control" id="email" placeholder="Email">
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-sm-12">
                            <div class="form-group">
                                <input type="file" name="feedimgs" (change)="handleFileInput($event.target.files)" class="form-control filestyle" id="imageurl" title="Choose Image" tabindex="-1" style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
                                <div class="bootstrap-filestyle input-group"><input type="text" *ngIf="feedimg" [value]="feedimg.name" class="form-control no-padd " placeholder="" disabled=""> <span class="group-span-filestyle input-group-btn" tabindex="0"><label for="imageurl" class="btn btn-default "><span class="icon-span-filestyle glyphicon glyphicon-folder-open"></span>                                    <span class="buttonText">Choose file</span></label>
                                    </span>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-sm-12">
                            <div class="form-group">
                                <label>Feedbacks</label>
                                <textarea name="feedback" [(ngModel)]="feedback.feedback" cols="40" rows="10" class="form-control" id="clientdescription" placeholder="Feedbacks"></textarea>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="modal-footer panel-footer">
                    <div class="row">
                        <div class="col-sm-12">

                            <button type="button" id="saveclientfeedbacks" class="btn btn-primary add-clientfeedbacks" (click)="savefeedback()">Save</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>
</div>
<span #openfeedback data-toggle="modal" data-backdrop="static" data-target="#feedback" style="display: none;"></span>
<app-login></app-login>