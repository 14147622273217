<div class="modal fade" id="signin-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-top">
        <div class="modal-content PopUpBox">

            <a href="javascript:void(0);" data-dismiss="modal" class="popup-times"><img src="../../assets/images/close.svg" #closeLoginModal style="float: right;margin-right: 6px;margin-top: 6px;width: 20px;"></a>

            <div class="flash-content text-center">
                <div class="text"> <label>
            <p class="title d-flex align-items-center justify-content-center" style="color: #90278e;font-weight: bold;font-size: x-large;">  <label class="mb-0">
              <!-- <input type="radio" name="case" class="radio" /> -->
              <!-- <span class="radio-style check-radio-style"></span> -->
              
            </label> Sign In </p>
                    </label>
                    <p>
                        Manage your meetings & appointments easily
                    </p>

                    <p>
                        build a following and enhance your online reputation
                    </p>
                </div>

                <!-- <div class="text">
            <label>
            <p class="title d-flex align-items-center justify-content-center">
             
              <label class="mb-0">
                <input type="radio" name="case" class="radio" />
                <span class="radio-style check-radio-style"></span>
              </label>
              
              For Litigants</p>
      </label>
            <p>
              Easily find the best legal experts near you
            </p>
      
            <p>
              Get thousands of legal resources for self help & DIY
            </p>
          </div> -->

                <form>
                
                 

                    <div class="alert alert-success" *ngIf="message" role="alert">
                        {{message}}
                    </div>
                    <div class="form-group">

                        <input type="email" class="form-control"  name="email" [(ngModel)]="userInfo.email" aria-describedby="emailHelp" placeholder=" Enter Email *" />
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control"  name="password" [(ngModel)]="userInfo.password" aria-describedby="emailHelp" placeholder="********" />
                    </div>
                    <div class="text-right btn-wrapper signin">
                        <button type="submit" class="btn btn-primary link-bg" (click)="login()">
                          <span *ngIf="this.loading === true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          &nbsp;
                          <!-- <span *ngIf="this.loading === true">Sign In...</span> -->
                          <span *ngIf="this.loading !== true">Sign In</span></button>
                        <small><a href="javascript:void(0);" class="link"(click)="openForgotPassword()">Forgot password</a></small>
                    </div>
                </form>

                <h3 class="text-color mb-2">Not a SoOLegal Member?</h3>
                <!-- data-target="#signup-modal" -->
                <button type="button" class="btn btn-primary link-bg close-signin-mdl" data-toggle="modal" (click)="openRegister()">Sign Up</button>



                <div class="social-icons">
                    <a (click)="loginWithGoogle()"><i class="fab fa-google-plus"></i></a>
                    <a (click)="signInWithFB()"><i class="fab fa-facebook"></i></a>

                    <a href="#"><i class="fab fa-twitter"></i></a>
                </div>
            </div>
        </div>

    </div>
</div>


<!-- <div id="signin-modal" class="modal fade popUpBox-content" >

    <div class="PopUpBox">
      <a href="javascript:void(0);" id="close-signin-mdl" data-dismiss="modal" class="popup-times"><img src="../../assets/images/close.svg" #closeLoginModal></a>
    
      <div class="flash-content text-center">
        <div class="text"> <label>
          <p class="title d-flex align-items-center justify-content-center">  <label class="mb-0">
            <input type="radio" name="case" class="radio" />
            <span class="radio-style check-radio-style"></span>
          </label>  Go to Lawyers Page </p>
        </label>
          <p>
            Manage your meetings & appointments easily
          </p>
    
          <p>
            build a following and enhance your online reputation
          </p>
        </div>
    
        <div class="text">
          <label>
          <p class="title d-flex align-items-center justify-content-center">
           
            <label class="mb-0">
              <input type="radio" name="case" class="radio" />
              <span class="radio-style check-radio-style"></span>
            </label>
            
            For Litigants</p>
    </label>
          <p>
            Easily find the best legal experts near you
          </p>
    
          <p>
            Get thousands of legal resources for self help & DIY
          </p>
        </div>
    
        <form>
          <div class="form-group">
          
            <input type="email" class="form-control" id="exampleInputEmail1"name="email"[(ngModel)]="userInfo.email" aria-describedby="emailHelp" placeholder="SoOLegal ID" />
          </div>
          <div class="form-group">
            <input type="email" class="form-control" id="exampleInputEmail1"name="password"[(ngModel)]="userInfo.password" aria-describedby="emailHelp" placeholder="SoOLegal ID" />
          </div>
          <div class="text-right btn-wrapper">
            <button type="submit" class="btn btn-primary link-bg" (click)="login()">Sign In</button>
            <small><a href="#" class="link">Forgot password</a></small>
          </div>
        </form>
    
        <h3 class="text-color mb-2">Not a SoOLegal Member?</h3>
    
        <button type="button" class="btn btn-primary link-bg" data-toggle="modal" data-target="#signup-modal">Sign Up</button>
     
        
       
        <div class="social-icons">
          <a href="#"><i class="fab fa-google-plus"></i></a>
          <a href="#"><i class="fab fa-facebook"></i></a>
    
          <a href="#"><i class="fab fa-twitter"></i></a>
        </div>
      </div>
    </div>
   
  </div> -->


<span #openLoginModal data-toggle="modal" data-backdrop="static" data-target="#signin-modal" style="display: none;"></span>
<!-- <app-register></app-register> -->