import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { RoarService } from '../../roar/roar.service';
import { NewsService } from '../../news/news.service';
import { Roar } from '../../roar/roar';
import { Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemberService } from '../../member/member.service';
import { ApiService } from '../../api.service';
import { CommentService } from '../../comment/comment.service';
import { LikeService } from '../../like/like.service';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../login/login.service';
import { LoginComponent } from '../../login/login.component';

@Component({

  selector: 'app-roarform',
  templateUrl: './roarform.component.html',
  // styleUrls: ['./.component.css']
})
export class RoarFormComponent implements OnInit {
  

  @Input()
  postId: number;

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter();
 
  page: number = 1;
  count: number = 0;
  tableSize: number = 6;
  tableSizes: any = [3, 6, 9, 12];

 
  pagecmt: number = 1;
  countcmt: number = 0;
  tableSizecmt: number = 4;
  tableSizescmt: any = [3, 6, 9, 12];
cmt:any;
  
  news:any;
  closeModal:string;
  closeResult: string;
  userInfo: any;
 
  roar: Roar[] = [];
  Roar: any = {};
  roarImage: any = {};
  comments: Comment[];
  category_id = 1;
  showMore = false;
  r: any;
  s: any;
  existingNews: any = null;
  id: number;
  formData: any;
  status: any;
  status1 = 1;
  token: string;
  m_id:any;
  News: any;

  p: Number = 1;
  count1: Number = 5;


 
  constructor(private route: ActivatedRoute,public newsService: NewsService, public likeService: LikeService,public api: ApiService, public loginService: LoginService, public commentService: CommentService, public roarService: RoarService, private router: Router, private modalService: NgbModal, public memberapi: MemberService, public mainapi: ApiService) { 
    if(localStorage.getItem('USERID')){


      this.token =  localStorage.getItem('USERID')
     }
     if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
     this.refresh();
     }
        
   }
  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  @ViewChild('openLoginModal')loginModal: ElementRef; 
  @Output() loginEvent = new EventEmitter<string>();
  


  ngOnInit(): void {
    var roarid = this.route.snapshot.paramMap.get('roarId');
    this.getComments(roarid, this.category_id);
    console.log("Roar Index");

    this.getRoar(roarid);
    this.findRoar(roarid);
// this.getNews('');
    console.log("roar list",this.roar);
    

  }

  isReadMore = false;

  showText() {
    this.isReadMore = !this.isReadMore
  }

  myForm = new FormGroup({
    title: new FormControl(''),
    content: new FormControl(''),
    url: new FormControl(''),
    status1: new FormControl(''),
  });


  get f() {
    return this.myForm.controls;
  }

  onFileChange(event) {
    this.roarImage = event.target.files[0];
  }
  showform = false;
  submit(s) {
    this.showform = !this.showform;
    const formData = new FormData();
    if (this.existingNews?.id) {
      formData.append("id", this.existingNews?.id);
    }
    formData.append("url", this.roarImage);
    formData.append('title', this.myForm.get('title').value);
    formData.append("content", this.myForm.get('content').value);
    formData.append("url", this.myForm.get('url').value);

    formData.append("status", this.status1 + '')

    console.log('hiiiiiiiiiiiiiiiiiiiiii', this.status1);

    this.roarService.create(formData).subscribe((res: any) => {
      console.log("Roar Created..:::", res.roar);
       
     
      this.getRoar('');
      
      
     
    });
   
    //  formData.forEach((value,key) => {
    //   console.log(key+" "+value)
    // });
  }
  
 
   
  onclick() {
    this.showform = !this.showform;
    this.myForm.reset();
    this.getRoar('');
    
  }
 
 
  like(m_id) {
if(this.token){
    let roar_id = m_id.id,
      liked = m_id.liked,
      author_id = m_id.author_id;

    // this.showicon = !this.showicon;
    // this.show = !this.show;
    console.log('like',m_id);
    const formData = new FormData();
    formData.append('post_id', roar_id + '');
    formData.append('category_id', this.category_id + '');
    formData.append('member_id', author_id + '');
    if (liked > 0 ) {
      liked = 0;
      m_id.like_count = m_id.like_count - 1;
    } else {
     liked = 1;
      m_id.like_count = m_id.like_count + 1;
    }
    // UPdating the ui object
    m_id.liked = liked;

    formData.append('status', liked + '');
    console.log('status',liked);

    this.likeService.create(formData).subscribe((res: any) => {
      console.log("Roar Liked..:::", res.roar);
    });

    console.log(this.category_id);
  }

  }


  getComments(post_id, category_id) {
    this.commentService.getAll(post_id, this.category_id).subscribe((com: Comment[]) => {
      this.comments = com;
      console.log("Comments..:::", this.comments);

    });
  }

  getRoar(roarId) {
    console.log('getting Roar');
    this.roarService.getAll(roarId).subscribe((res: Roar[]) => {
      this.roar = res;
      console.log("Roar Created..:::", this.roar);
    });
  }

  findRoar(roarId) {
    
    console.log('getting Roar6');
    this.roarService.find(roarId).subscribe((res: Roar) => {
      this.existingNews = res;

      console.log("Roar Created1..:::", this.roar);
      this.existingNews = res;
      this.myForm.patchValue({
        title: this.existingNews.title,

        publish_date: this.existingNews.publish_date,
        content: this.existingNews.content,
        // url: this.existingNews.url,
      });
      console.log('hi', this.existingNews.title);
    });
  }
  getNews(type) {
    console.log('getting news');
    this.newsService.getAll(type).subscribe((res: any) => {
      this.news = res;
      console.log("NEWS..:::", this.news);
    })
  }
  getImage(roar) {
    // return "http://localhost:8080/api/roar-image/" + roar.url;
    // return environment.baseURL + "roar-image/" + roar.url;
    return roar.m_url;
  }
  getImagePic(roar) {
    return roar.profilepic_url;
  }

  onCommentCreate(roar_id) {
    this.getComments(roar_id, this.category_id);
    console.log(this.roar, this.category_id);
    console.log('news creayed');
  }
  onComment(roar_id) {
    this.getComments(roar_id, this.category_id);
    console.log('news creayed');
  }
  deleteRoar(roar_id) {
    this.roarService.delete(roar_id).subscribe((res: Roar[]) => {
      this.roar = res;
      // this.router.navigate(['roar']);
    });
  }

  // share popup
  open(content, r) {
    this.r = r;
    this.getComments(r.id, 1);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  // share popup ends

  ope(conte) {
    this.modalService.open(conte, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismiss(reason)}`;
    });
  }


  private getDismiss(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // socialshare url
  getLocation(news_id) {
    // return location.href + '/' + news_id;
     return  "https://stackoverflow.com"+'/'+news_id;
  }

  click(){
    if(this.token){
      // this.showform = !this.showform;
      // this.router.navigate(['/roar']);
    // this.submit('');
    }else{
      this.loginPrev.openLogin();
    }
  }
  refresh() {
    if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
    localStorage.clear();
    // window.location.reload();
    }
  }
  openLogin() {
    this.loginModal.nativeElement.click()
  }
  loginSubmit(event:any) {
  }


  // popup for delete icon
  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
     
      this.getRoar('');
      this.router.navigate(['/roar']);
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReasons(res)}`;
     
    });
   
  }
  
  private getDismissReasons(reason: any): string {
    
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
     
    }
   
  }

 

  onTableDataChange(event: any) {
    this.page = event;
    this.getRoar;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getRoar;
  }
 

  onTableDataChangecmt(event1: any) {
    this.pagecmt = event1;
    this.getComments;
  }
  onTableSizeChangecmt(event1: any): void {
    this.tableSizecmt = event1.target.value;
    this.pagecmt = 1;
    this.getComments;
  }

}