<main role="main" id="roar-page">
    <section class=" ipad-hide phone-hide">
        <div class="container-fluid inner-title">
            <div class="row">
                <div class="col-md-1 pl-0 text-right"><span>NEWS</span></div>
                <div class="col-md-10">Delhi HC directs Singh Brothers to Maintain Status-quo over Assets & Properties | Delhi HC directs...</div>
                <div class="col-md-1 pr-0 text-left"><span>NEWS</span></div>
            </div>
        </div>
    </section>
    <section class="ipad-top-space">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-lg-3 ">

                    <div class="roar-search dk-none">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <button class="btn btn-outline-secondary" type="button"></button>
                            </div>
                            <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username with two button addons">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button">ROAR</button>
                            </div>
                        </div>
                    </div>


                    <div class="component-box-1 infobox-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4 class="m-0">Your Profile</h4>
                                <div class="user-info-box-inner active h-0 no-scroll">
                                    <div class="icons-box row" *ngIf="this.count">
                                        <div class="col"><span>{{total?.new_views}}</span> <img src="../../assets/images/icons/lawyer-icon.png" alt="Appointments" />
                                            <h5 class="small">New Views</h5>
                                        </div>
                                        <div class="col"><span>{{total?.total_new_connection}}</span><a  [routerLink]="['/member/associates']"> <img src="../../assets/images/icons/lawyer-icon.png" alt="Appointments" /></a>
                                            <h5 class="small">New Connection</h5>
                                        </div>
                                        <div class="col"><span>{{total?.total_pending_connection}}</span> <img src="../../assets/images/icons/lawyer-icon.png" alt="Appointments" />
                                            <h5 class="small">Pending Connection</h5>
                                        </div>
                                    </div>
                                    <p><a [routerLink]="['/dashboard']">Click for detailed information</a></p>
                                </div>
                            </div>
                            <div class="overlay-box" data-toggle="modal" data-target="#user-modal">
                                <div class="message-box">
                                    <p>You are amongst top 27.8% of all SoOLegal Members</p>
                                    <hr>
                                    <p>12.2% visitors have refered your profile to others</p>
                                </div>
                            </div>
                        </div>
                    </div>













                    <div class="component-box-1 infobox-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4 class="m-0">Your ROARS

                                </h4>
                                <div class="user-info-box-inner active h-0 no-scroll">
                                    <div class="icons-box row" *ngIf="this.count">
                                        <div class="col"><span>{{total?.roar_views}}</span> <img src="../../assets/images/icons/roar_view.png" alt="Appointments" />
                                            <h5 class="small">Roar Views</h5>
                                        </div>
                                        <div class="col"><span>{{memberapi?.roarlike| async}}</span> <img src="../../assets/images/icons/roar_view.png" alt="Appointments" />
                                            <h5 class="small">Roar Likes</h5>
                                        </div>
                                        <div class="col"><span>{{total?.roar_comment}}</span> <img src="../../assets/images/icons/roar_view.png" alt="Appointments" />
                                            <h5 class="small">Roar Comments</h5>
                                        </div>
                                    </div>
                                    <p><a [routerLink]="['/dashboard']">Click for detailed information</a></p>
                                </div>
                            </div>
                            <div class="overlay-box" data-toggle="modal" data-target="#user-modal">
                                <div class="message-box">
                                    <p>You are amongst top 27.8% of all SoOLegal Members</p>
                                    <hr>
                                    <p>12.2% visitors have refered your profile to others</p>
                                </div>
                            </div>
                        </div>
                    </div>





                    <div class="component-box-1 infobox-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4 class="m-0">Your Cases

                                </h4>
                                <div class="user-info-box-inner active h-0 no-scroll">
                                    <div class="icons-box row" *ngIf="this.count">
                                        <div class="col"><span>{{total?.client}}</span> <img src="../../assets/images/icons/client.png" alt="Appointments" />
                                            <h5 class="small">Client</h5>
                                        </div>
                                        <div class="col"><span>{{total?.cases}}</span> <img src="../../assets/images/icons/case.png" alt="Appointments" />
                                            <h5 class="small">Cases</h5>
                                        </div>
                                        <div class="col"><span>999</span> <img src="../../assets/images/icons/prospect.png" alt="Appointments" />
                                            <h5 class="small">Prospects</h5>
                                        </div>
                                    </div>
                                    <p><a [routerLink]="['/dashboard']">Click for detailed information</a></p>
                                </div>
                            </div>
                            <div class="overlay-box" data-toggle="modal" data-target="#user-modal">
                                <div class="message-box">
                                    <p>You are amongst top 27.8% of all SoOLegal Members</p>
                                    <hr>
                                    <p>12.2% visitors have refered your profile to others</p>
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
                <div class="col-md-8 col-lg-6 ">

                    <div class="roar-search  ipad-hide phone-hide">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <button class="btn btn-outline-secondary pl-1 pb-0 pt-0" type="button"><img
                                        src="../../assets/images/icons/pin.png"></button>
                            </div>
                            <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username with two button addons">
                           
                            <div class="input-group-append">
                                <li class="nav-item dropdown">
                                     <a   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add Form</a>
                                  
                                    <div class="dropdown-menu fade-up menu-list" aria-labelledby="menu-list">
                                       
                                        <a class="dropdown-item" (click)="triggerModal(modalData)">ROAR</a>
                                        <a class="dropdown-item" (click)="open(modal)">NEWS</a>
                                        <!-- <a class="dropdown-item" [routerLink]="['/resource-centre']">Events</a> -->
                                        <a class="dropdown-item" (click)="ope(mod)">EVENT</a>
                                       
                                    </div>
                                </li>
                               
                            </div>

                            <!-- <select id="option-size" formControlName="select_city" class="custom-control" value="Form" placeholder="Form">
                                <option value="Roar" (click)="triggerModal(modalData)">Roar</option>
                                <option  value='News' selected="">
                                  News  </option>


                            </select>
                            <span class="text"><span class="label" placeholder="Form"></span><span class="value"
                                    formControlName="select_city" placeholder="Form"></span></span> -->
                            
                                    <ng-template #modalData let-modal>
                                        <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">Add ROAR</h4>
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <app-roarform></app-roarform>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-danger"
                                              
                                                (click)="modal.close('Save click')">close</button>
                                        </div>
                                    </ng-template>



                                    <ng-template #modal let-modal>

                                        <app-newsform></app-newsform>
                                      

                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                               (click)="modal.close('Save click')">Close</button>
                                        </div>
                                    </ng-template>


                                    <ng-template #mod let-modal>

                                       
                                      <app-eventform></app-eventform>

                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                                (click)="modal.close('Save click')">Close</button>
                                        </div>
                                    </ng-template>


                           
                        </div>
                    </div>

                                    <div class="component-box-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <app-calendar></app-calendar>
                            </div>
                        </div>
                    </div>



                    <div class="component-box-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4 class="m-0">Your Documents </h4>
                                <div class="user-info-box-inner calendar-box no-scroll h-0 p-4">


                                    <div class="row text-center" *ngIf="this.count">
                                        <div class="col">

                                            <img src="../../assets/images/icons/icon_#90278E_17.png">
                                            <p>Documents ({{total?.document}})

                                            </p>


                                        </div>


                                        <div class="col">

                                            <img src="../../assets/images/icons/icon_#90278E_18.png">
                                            <p>Case ({{total?.cases}})

                                            </p>


                                        </div>



                                        <div class="col">

                                            <img src="../../assets/images/icons/icon_#90278E_19.png">
                                            <p>Services (11)



                                            </p>


                                        </div>



                                        <div class="col">

                                            <img src="../../assets/images/icons/icon_#90278E_20.png">
                                            <p>PILs (11)

                                            </p>


                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>





                    <div class="events owl-carousel owl-theme">
                        <div class="item small-box">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box d-flex m-0 p-0 align-items-center">
                                    <div class="img-box"><img src="../../assets/images/icons/rank_icons_black.png" alt="Rank" /></div>

                                    <div class="info text-left ml-1">
                                        <div class="rank text-color font-weight-bold">98%</div>
                                        <div class="success-title">Successfull Cases</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item small-box">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box d-flex m-0 p-0 align-items-center">
                                    <div class="img-box"><img src="../../assets/images/icons/icons_black_29.png" alt="Rank" /></div>

                                    <div class="info text-left ml-1">
                                        <div class="rank text-color font-weight-bold">1,700+</div>
                                        <div class="success-title">Registered Lawyers</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item small-box">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box d-flex m-0 p-0 align-items-center">
                                    <div class="img-box"><img src="../../assets/images/icons/icons_black_30.png" alt="Rank" /></div>

                                    <div class="info text-left ml-1">
                                        <div class="rank text-color font-weight-bold">1,200</div>
                                        <div class="success-title">Monthly Articles</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item small-box">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box d-flex m-0 p-0 align-items-center">
                                    <div class="img-box"><img src="../../assets/images/icons/icons_black_31.png" alt="Rank" /></div>

                                    <div class="info text-left ml-1">
                                        <div class="rank text-color font-weight-bold">70+</div>
                                        <div class="success-title">Legal Specialities</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>










                </div>
                <div class="col-md-3">





                    <article class="component-box-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>Your Profile</h4>
                                <div class="user-info-box-inner p-0 no-scroll">
                                    <div class="user-box" *ngIf="profiledata">
                                        <div class="ribbon ribbon-top-right"><span *ngIf="profiledata.isPrime">Premium</span></div>
                                        <div class="img-box" *ngIf="!profiledata.profile_picture">
                                            <a href="javascript:void(0);" [routerLink]="['/'+ profiledata?.username]"><img src="../../assets/images/profile-user-round.png" /></a>
                                        </div>
                                        <div class="img-box" *ngIf="profiledata.profile_picture">
                                            <a href="javascript:void(0);" [routerLink]="['/'+profiledata?.username]"><img src="{{profiledata?.profile_picture}}" /></a>
                                        </div>
                                        <!-- profile_picture -->
                                        <div class="name">{{profiledata?.full_name}}</div>

                                        <p class="small">
                                            {{profiledata?.role}} <br />{{profiledata?.education?.degree_name}}
                                        </p>
                                        <div class="pc-box">{{profiledata?.city_name}}({{profiledata?.country_name}})</div>

                                        <div class="progress-bar-box">
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="pc-box"><a [routerLink]="['/profile']" class="link">Edit/ Modify
                                                    Profile</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>






                    <!-- <div class="black-box h-0">
                        <img src="../../assets/images/a1.jpg" class="img-fluid" />

                        <img src="../../assets/images/a2.jpg" class="img-fluid" />

                        <img src="../../assets/images/a3.jpg" class="img-fluid" />
                    </div> -->
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigMemberAds" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
                        <div ngxSlickItem *ngFor="let slide1 of event" class="black-box h-0">
                            <img src="../../assets/images/a3.jpg" class="img-fluid" />
                        </div>
                    </ngx-slick-carousel>



                </div>
            </div>
        </div>
    </section>

    <div class="h-50"></div>
    <section class="title-bg-1 my-4  ipad-hide phone-hide">
        <div class="container">
            <div class="row">
                <div class="col-md-2 #90278E-bg-new">
                    <div class="triangle-bottomleft"></div>
                    <div class="icon-box"></div>
                </div>
                <div class="col-md-10 white-bg-new">
                    <div class="triangle-topleft"></div>
                    <span class="gray-bg">Go Premium</span>
                    <h3>Amplify your stature and reputation...Comment, Like, Share...ROAR!</h3>
                </div>
            </div>
        </div>
    </section>
    <div class="h-50"></div>
















































































    <section class="container position-relative mnSlider">


        <div class="news-slider">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigMemberRoar" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
                <article ngxSlickItem *ngFor="let slide of roarlist">
                    <div class="component-box-1 p-0">
                        <div class="user-info-box-main p-0">
                            <div class="user-info-box">
                                <div class="user-info-box-inner no-scroll pb-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="jobId mb-3">
                                            <p>
                                                <a href="javascript:void(0);" [routerLink]="['/'+slide.username]">
                                                    <img src="{{slide.member_profile_picture}}" style="width:60px; height: 60px; border-radius:50%">
                                                </a>
                                                <!--  <img src="{{slide.member_profile_picture}}" class="img-fluid profilePic"> -->
                                            </p>
                                        </div>

                                        <div class="job-posted-date mb-3 text-left">
                                            <a href="javascript:void(0);" [routerLink]="['/'+slide.username]">
                                            <p>{{slide?.publish_date* 1000| date: 'dd/MM/yyyy'}} <br> {{slide?.fullname}}</p>
                                        </a>
                                        </div>
                                    </div>

                                    <div class="job-details text-left mb-3">
                                        
                                       <a class="link" [routerLink]="['/roar/'+slide.slug]"  type="text"><h6 class="mb-3 color-gray text-wrapper  " [innerHtml]="slide.title"  ngx-clamp [ngxClampOptions]="{ clamp: 1}" title="{{slide.title}}" style=" display: inline-block; width: 400px;overflow: hidden;font-weight:bold;"></h6></a>
                                       
                                       <a class="link" [routerLink]="['/roar/'+slide.slug]"><img src="{{slide.image_url}}" class="img-fluid roar-custom-size"></a>
                                    </div>
                                         <div class="text-wrapper text-left">

                                        <p><span [innerHTML]="slide.description"></span> <a class="link" [routerLink]="['/roar/'+slide.slug]"> Readmore</a></p>

                                        <!-- <div>
          {{(readMore) ? slide.description_full : slide.description | slice:0:50}} <span *ngIf="!readMore">...</span>
          <a href="javascript:;" *ngIf="!readMore" (click)="readMore=true">[Read More]</a>
      </div> -->

                                    </div>


                                    <div class="text-right pull-right">
                                        <a href="javascript:void(0);" class="btn text-white color-gray"><span><small>{{this?.roarlike}}</small></span><i
                                            class="far fa-heart" (click)="create()"></i></a>
                                        <a href="" class="btn text-white color-gray"><i class="far fa-comments"></i></a>
                                        <a href="" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <a class="btn btn-primary mx-auto d-block btn-small"  data-toggle="modal" data-target="#findLawer" id="modelPopUp" >Schedule Appointment</a> -->
                    <!-- class="news-slider" -->
                </article>
            </ngx-slick-carousel>
        </div>

        <!-- <article>
                <div class="component-box-1 p-0">
                    <div class="user-info-box-main p-0">
                        <div class="user-info-box">
                            <div class="user-info-box-inner no-scroll pb-3">
                                <div class="d-flex justify-content-between">
                                    <div class="jobId mb-3">
                                        <p>
                                            <img src="../../assets/images/profile_pic.png" class="img-fluid profilePic">
                                        </p>
                                    </div>

                                    <div class="job-posted-date mb-3 text-left">
                                        <p>DD/MM/YY <br> RK Sharma</p>
                                    </div>
                                </div>

                                <div class="job-details text-left mb-3">
                                    <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?
                                    </h4>

                                    <img src="../../assets/images/news/news-img-1.jpg" class="img-fluid">



                                </div>


                                <div class="text-wrapper text-left">

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit.
                                        In vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus
                                        eu diam iaculis, a venenatis est cursus. Vivamus at
                                        ex dignissim est male... <a href="#" class="link">more</a></p>


                                </div>


                                <div class="text-right pull-right">
                                    <a href="#" class="btn text-white color-gray"><i class="far fa-heart"></i></a>
                                    <a href="#" class="btn text-white color-gray"><i class="far fa-comments"></i></a>
                                    <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>




            <article>
                <div class="component-box-1 p-0">
                    <div class="user-info-box-main p-0">
                        <div class="user-info-box">
                            <div class="user-info-box-inner no-scroll pb-3">
                                <div class="d-flex justify-content-between">
                                    <div class="jobId mb-3">
                                        <p>
                                            <img src="../../assets/images/profile_pic.png" class="img-fluid profilePic">
                                        </p>
                                    </div>

                                    <div class="job-posted-date mb-3 text-left">
                                        <p>DD/MM/YY <br> RK Sharma</p>
                                    </div>
                                </div>

                                <div class="job-details text-left mb-3">
                                    <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?
                                    </h4>

                                    <img src="../../assets/images/news/news-img-1.jpg" class="img-fluid">



                                </div>


                                <div class="text-wrapper text-left">

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit.
                                        In vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus
                                        eu diam iaculis, a venenatis est cursus. Vivamus at
                                        ex dignissim est male... <a href="#" class="link">more</a></p>


                                </div>


                                <div class="text-right pull-right">
                                    <a href="#" class="btn text-white color-gray"><i class="far fa-heart"></i></a>
                                    <a href="#" class="btn text-white color-gray"><i class="far fa-comments"></i></a>
                                    <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>




            <article>
                <div class="component-box-1 p-0">
                    <div class="user-info-box-main p-0">
                        <div class="user-info-box">
                            <div class="user-info-box-inner no-scroll pb-3">
                                <div class="d-flex justify-content-between">
                                    <div class="jobId mb-3">
                                        <p>
                                            <img src="../../assets/images/profile_pic.png" class="img-fluid profilePic">
                                        </p>
                                    </div>

                                    <div class="job-posted-date mb-3 text-left">
                                        <p>DD/MM/YY <br> RK Sharma</p>
                                    </div>
                                </div>

                                <div class="job-details text-left mb-3">
                                    <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?
                                    </h4>

                                    <img src="../../assets/images/news/news-img-1.jpg" class="img-fluid">



                                </div>


                                <div class="text-wrapper text-left">

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit.
                                        In vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus
                                        eu diam iaculis, a venenatis est cursus. Vivamus at
                                        ex dignissim est male... <a href="#" class="link">more</a></p>


                                </div>


                                <div class="text-right pull-right">
                                    <a href="#" class="btn text-white color-gray"><i class="far fa-heart"></i></a>
                                    <a href="#" class="btn text-white color-gray"><i class="far fa-comments"></i></a>
                                    <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>




            <article>
                <div class="component-box-1 p-0">
                    <div class="user-info-box-main p-0">
                        <div class="user-info-box">
                            <div class="user-info-box-inner no-scroll pb-3">
                                <div class="d-flex justify-content-between">
                                    <div class="jobId mb-3">
                                        <p>
                                            <img src="../../assets/images/profile_pic.png" class="img-fluid profilePic">
                                        </p>
                                    </div>

                                    <div class="job-posted-date mb-3 text-left">
                                        <p>DD/MM/YY <br> RK Sharma</p>
                                    </div>
                                </div>

                                <div class="job-details text-left mb-3">
                                    <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?
                                    </h4>

                                    <img src="../../assets/images/news/news-img-1.jpg" class="img-fluid">



                                </div>


                                <div class="text-wrapper text-left">

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit.
                                        In vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus
                                        eu diam iaculis, a venenatis est cursus. Vivamus at
                                        ex dignissim est male... <a href="#" class="link">more</a></p>


                                </div>


                                <div class="text-right pull-right">
                                    <a href="#" class="btn text-white color-gray"><i class="far fa-heart"></i></a>
                                    <a href="#" class="btn text-white color-gray"><i class="far fa-comments"></i></a>
                                    <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>



            <article>
                <div class="component-box-1 p-0">
                    <div class="user-info-box-main p-0">
                        <div class="user-info-box">
                            <div class="user-info-box-inner no-scroll pb-3">
                                <div class="d-flex justify-content-between">
                                    <div class="jobId mb-3">
                                        <p>
                                            <img src="../../assets/images/profile_pic.png" class="img-fluid profilePic">
                                        </p>
                                    </div>

                                    <div class="job-posted-date mb-3 text-left">
                                        <p>DD/MM/YY <br> RK Sharma</p>
                                    </div>
                                </div>

                                <div class="job-details text-left mb-3">
                                    <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?
                                    </h4>

                                    <img src="../../assets/images/news/news-img-1.jpg" class="img-fluid">



                                </div>


                                <div class="text-wrapper text-left">

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit.
                                        In vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus
                                        eu diam iaculis, a venenatis est cursus. Vivamus at
                                        ex dignissim est male... <a href="#" class="link">more</a></p>


                                </div>


                                <div class="text-right pull-right">
                                    <a href="#" class="btn text-white color-gray"><i class="far fa-heart"></i></a>
                                    <a href="#" class="btn text-white color-gray"><i class="far fa-comments"></i></a>
                                    <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article> -->



        <!-- <a href="#" class="arrow ml"><img src="../../assets/images/icons/arrow_left.png" /></a>
        <a href="#" class="arrow mr r-180"><img src="../../assets/images/icons/arrow_left.png" /></a> -->

    </section>
 <section class="container">
        <div class="advanced-search">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Dropdown</a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" id="resource-center-tab" data-toggle="pill" href="#legal-procedures" role="tab" aria-controls="resource-center" aria-selected="true">Legal Procedures</a>
                        <a class="dropdown-item" id="legal-formats-tab" data-toggle="pill" (click)="getsearch('legal-formats')" href="#legal-formats" role="tab" aria-controls="legal-formats" aria-selected="false">Legal Formats</a>
                        <a class="dropdown-item" id="legal-notices-tab" data-toggle="pill" (click)="getsearch('legal-notices')" href="#legal-notices" role="tab" aria-controls="legal-notices" aria-selected="false">Legal Notices</a>
                        <a class="dropdown-item" id="legal-research-tab" data-toggle="pill" (click)="getsearch('legal-research')" href="#legal-research" role="tab" aria-controls="legal-research" aria-selected="false">Legal Research</a>
                    </div>
                </li>

                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="resource-center-tab" data-toggle="pill" href="#resource-center" role="tab" aria-controls="resource-center" aria-selected="true">Legal Procedures</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="legal-formats-tab" data-toggle="pill" (click)="getsearch('legal-research')" href="#legal-formats" role="tab" aria-controls="legal-formats" aria-selected="false">Legal
                        Formats</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="legal-notices-tab" data-toggle="pill" (click)="getsearch('legal-notices')" href="#legal-notices" role="tab" aria-controls="legal-notices" aria-selected="false">Legal
                        Notices</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="legal-research-tab" data-toggle="pill" (click)="getsearch('legal-research')" href="#legal-research" role="tab" aria-controls="legal-research" aria-selected="false">Legal
                        Research</a>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="resource-center" role="tabpanel" aria-labelledby="resource-center-tab">
                    <div class="resource-center-search-box">
                        <div class="resource-center-search-box-title">Resource Center</div>
                        <div class="row justify-content-center">
                            <div class="col-md-8">
                                <p>SoOLegal houses a comprehensive collection of legal articles, judgements, opinions and many other documents. Search here as per your requirement.</p>

                                <div class="resource-search-box text-center">
                                    <div class="form-group search">


                                        <div class="form-inline d-flex justify-content-center">
                                            <div class="relative"> <input type="text" class="form-control m-0 max-400" placeholder="Quick Search" [(ngModel)]="description"><span class="fa fa-search form-control-feedback" aria-hidden="true"></span></div>

                                            <a id="advanced-search-btn1" class="link search_link m-0" (click)="searchsubmit()">Advanced Search</a>
                                        </div>
                                     </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="legal-formats" role="tabpanel" aria-labelledby="legal-formats-tab">
                    <div class="resource-center-search-box">
                        <div class="resource-center-search-box-title">Legal Formats</div>
                        <div class="row justify-content-center">
                            <div class="col-md-8">
                                <p>SoOLegal houses a comprehensive collection of legal articles, judgements, opinions and many other documents. Search here as per your requirement.</p>


                                <div class="resource-search-box text-center">
                                    <div class="form-group search">


                                        <div class="form-inline d-flex justify-content-center">
                                            <div class="relative"> <input type="text" class="form-control m-0 max-400" placeholder="Quick Search" [(ngModel)]="description"><span class="fa fa-search form-control-feedback" aria-hidden="true"></span></div>

                                            <a id="advanced-search-btn1" class="link search_link m-0" (click)="searchsubmit()">Advanced Search</a>
                                        </div>
                                     </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="legal-procedures" role="tabpanel" aria-labelledby="legal-formats-tab">
                    <div class="resource-center-search-box">
                        <div class="resource-center-search-box-title">Legal Formats</div>
                        <div class="row justify-content-center">
                            <div class="col-md-8">
                                <p>SoOLegal houses a comprehensive collection of legal articles, judgements, opinions and many other documents. Search here as per your requirement.</p>


                                <div class="resource-search-box text-center">
                                    <div class="form-group search">


                                        <div class="form-inline d-flex justify-content-center">
                                            <div class="relative"> <input type="text" class="form-control m-0 max-400" placeholder="Quick Search" [(ngModel)]="description"><span class="fa fa-search form-control-feedback" aria-hidden="true"></span></div>

                                            <a id="advanced-search-btn1" href="javascript:void(0);" class="link search_link m-0" (click)="searchsubmit()">Advanced
                                                Search</a>
                                        </div>




                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="legal-notices" role="tabpanel" aria-labelledby="legal-notices-tab">
                    <div class="resource-center-search-box">
                        <div class="resource-center-search-box-title">Legal Notices</div>
                        <div class="row justify-content-center">
                            <div class="col-md-8">
                                <p>SoOLegal houses a comprehensive collection of legal articles, judgements, opinions and many other documents. Search here as per your requirement.</p>

                                <div class="resource-search-box text-center">
                                    <div class="form-group search">


                                        <div class="form-inline d-flex justify-content-center">
                                            <div class="relative"> <input type="text" class="form-control m-0 max-400" placeholder="Quick Search" [(ngModel)]="description"><span class="fa fa-search form-control-feedback" aria-hidden="true"></span></div>

                                            <a id="advanced-search-btn1" class="link search_link m-0" (click)="searchsubmit()">Advanced Search</a>
                                        </div>




                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="legal-research" role="tabpanel" aria-labelledby="legal-research-tab">
                    <div class="resource-center-search-box">
                        <div class="resource-center-search-box-title">Legal Research</div>
                        <div class="row justify-content-center">
                            <div class="col-md-8">
                                <p>SoOLegal houses a comprehensive collection of legal articles, judgements, opinions and many other documents. Search here as per your requirement.</p>


                                <div class="resource-search-box text-center">
                                    <div class="form-group search">


                                        <div class="form-inline d-flex justify-content-center">
                                            <div class="relative"> <input type="text" class="form-control m-0 max-400" placeholder="Quick Search" [(ngModel)]="description"><span class="fa fa-search form-control-feedback" aria-hidden="true"></span></div>

                                            <a id="advanced-search-btn1" class="link search_link m-0" (click)="searchsubmit()">Advanced Search</a>
                                        </div>




                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!-- Advanced Search Box -->
            <div class="advanced-search-box advanced-search-box1 advanced-search-box2 advanced-search-box3 advanced-search-box4 w-100">
                <div class="advanced-search-box-content w-100">
                    <p>Advanced Search</p>
                    <div class="container">
                        <div class="option-box row my-2">
                            <div class="col-md-12 text-center">
                                <form class="advancedSearchForm" action="#">
                                    <div class="row resourceFlex d-flex flex-row justify-content-center align-items-center">
                                        <div class="col px-1">
                                            <p class="text-white font-weight-bold">Court Affidavits and Drafts</p>

                                            <div class="custom-control custom-radio custom-control-inline mr-0">
                                                <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input">
                                                <label class="custom-control-label" for="customRadioInline1"></label>
                                            </div>


                                        </div>
                                        <div class="col px-1">
                                            <p class="text-white font-weight-bold">Law | Statue | Acts | Update</p>
                                            <div class="custom-control custom-radio custom-control-inline mr-0">
                                                <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input">
                                                <label class="custom-control-label" for="customRadioInline2"></label>
                                            </div>
                                        </div>
                                        <div class="col px-1">
                                            <p class="text-white font-weight-bold">Judgements</p>
                                            <div class="custom-control custom-radio custom-control-inline mr-0">
                                                <input type="radio" id="customRadioInline3" name="customRadioInline1" class="custom-control-input">
                                                <label class="custom-control-label" for="customRadioInline3"></label>
                                            </div>
                                        </div>
                                        <div class="col px-1">
                                            <p class="text-white font-weight-bold">Projects and Dissertions</p>
                                            <div class="custom-control custom-radio custom-control-inline mr-0">
                                                <input type="radio" id="customRadioInline4" name="customRadioInline1" class="custom-control-input">
                                                <label class="custom-control-label" for="customRadioInline4"></label>
                                            </div>
                                        </div>
                                        <div class="col px-1">
                                            <p class="text-white font-weight-bold">Micellaneous</p>
                                            <div class="custom-control custom-radio custom-control-inline mr-0">
                                                <input type="radio" id="customRadioInline5" name="customRadioInline1" class="custom-control-input">
                                                <label class="custom-control-label" for="customRadioInline5"></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row resourceFlex2 w-80 m-auto m-web-20">
                                        <div class="col-md-3 col-sm-12 mb-2 advanced-search-col1">
                                            <div class="dropdown resources-dropdown">
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Select Category
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" href="#">Item 1</a>
                                                    <a class="dropdown-item" href="#">Item 2</a>
                                                    <a class="dropdown-item" href="#">Item 3</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-sm-12 mb-2 advanced-search-col2">
                                            <div class="dropdown resources-dropdown">
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Select Country
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" href="#">Item 1</a>
                                                    <a class="dropdown-item" href="#">Item 2</a>
                                                    <a class="dropdown-item" href="#">Item 3</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-sm-12 mb-2 advanced-search-col3">

                                            <div class="custom-control custom-radio custom-control-inline mr-0  h1-30">
                                                <input type="radio" id="customRadioInline10" name="customRadioInline2" class="custom-control-input">
                                                <label class="custom-control-label" for="customRadioInline10"></label>
                                                <p class="text-white font-weight-bold ml-2 color-gray">Free</p>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-sm-12 mb-2 advanced-search-col4">

                                            <div class="custom-control custom-radio custom-control-inline mr-0 h1-30">
                                                <input type="radio" id="customRadioInline11" name="customRadioInline2" class="custom-control-input">
                                                <label class="custom-control-label" for="customRadioInline11"></label>
                                                <p class="text-white font-weight-bold ml-2 color-gray">Paid</p>
                                            </div>
                                        </div>
                                        <div class="col-md-1 col-sm-12 advanced-search-col5">
                                            <a href="#" class="link searchIcon">

                                                <img src="../../assets/images/icons/search.svg">

                                            </a>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-right">
                                            <div class="ejectBox d-flex flex-column justify-content-center align-items-end">
                                                <a href="javascript:void(0);" id="advanced-search-close-btn" class="text-right">
                                                    <img class="" width="20" src="../../assets/images/eject.png" />
                                                    <p class="mb-2">Close</p>
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- Advanced Search Box -->

        </div>



        <div class="component-box-1 component-box-bg-light text-center">

            <div class="descriptor owl-carousel owl-theme">
                <div class="item">
                    <div class="descriptor-box">
                        <img src="../../assets/images/descriptor.png" alt="descriptor" />
                        <h4>Descriptor</h4>
                    </div>
                </div>
                <div class="item">
                    <div class="descriptor-box">
                        <img src="../../assets/images/descriptor.png" alt="descriptor" />
                        <h4>Descriptor</h4>
                    </div>
                </div>
                <div class="item">
                    <div class="descriptor-box">
                        <img src="../../assets/images/descriptor.png" alt="descriptor" />
                        <h4>Descriptor</h4>
                    </div>
                </div>
                <div class="item">
                    <div class="descriptor-box">
                        <img src="../../assets/images/descriptor.png" alt="descriptor" />
                        <h4>Descriptor</h4>
                    </div>
                </div>
                <div class="item">
                    <div class="descriptor-box">
                        <img src="../../assets/images/descriptor.png" alt="descriptor" />
                        <h4>Descriptor</h4>
                    </div>
                </div>
            </div>

        </div>

    </section>







    <div class="h-50"></div>



    <section class="title-bg-1 my-4  ipad-hide phone-hide">
        <div class="container">
            <div class="row">
                <div class="col-md-2 #90278E-bg-new">
                    <div class="triangle-bottomleft"></div>
                    <div class="icon-box"></div>
                </div>
                <div class="col-md-10 white-bg-new">
                    <div class="triangle-topleft"></div>
                    <span class="gray-bg">Go Premium</span>
                    <h3>Get the best lawyer based on SoOLegal's proprietary ROAR Score methodology</h3>
                </div>
            </div>
        </div>
    </section>

    <div class="h-50"></div>

    <section class="my-5">
        <div class="container position-relative">

            <section class="my-5">
                <div class="container position-relative">
                    <div class="eventsSlider slider-margin">

                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigMemberEvent" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">

                            <div ngxSlickItem *ngFor="let slide1 of event" class="news-slider">
                                <div class="component-box-1 component-box-bg-light">
                                    <div class="highlight-box">
                                      
                                        <a [routerLink]="['/event/'+slide1.slug]">
                                        <p class="text-wrapper text-left" class="font-weight-bold" title="2020 SoOLegal Knowledge Series by Avani Bansal" >2020 SoOLegal Knowledge Series by Avani Bansal</p>
                                        </a>
                                        <div class="img-box">
                                            <a [routerLink]="['/event/'+slide1.slug]"  title="2020 SoOLegal Knowledge Series by Avani Bansal">
                                            <img src="{{slide1.image_url}}" alt="Event" class="img-fluid" />
                                        </a>
                                        </div>
                                       <a [routerLink]="['/event/'+slide1.slug]">
                                        <div class="date" >{{slide1?.start* 1000 | date: 'dd/MM/yyyy'}}</div>
                                    </a>
                                        <a href="#" class="btn">Register Now</a>
                                    </div>
                                </div>
                            </div>

                        </ngx-slick-carousel>
                        <!-- <article class="item">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box">
                                    <p class="font-weight-bold">2020 SoOLegal Knowledge Series by Avani Bansal
                                    </p>
                                    <div class="img-box"><img src="../../assets/images/avani_extn_1200x628.jpg" alt="Event" /> </div>
                                    <div class="date">DD/MM/YYY</div>
                                    <a href="#" class="btn">CTA Button</a>
                                </div>
                            </div>
                        </article> -->
                        <!-- <article class="item">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box">
                                    <p class="font-weight-bold">2020 SoOLegal Knowledge Series by Avani Bansal
                                    </p>
                                    <div class="img-box"><img src="../../assets/images/avani_extn_1200x628.jpg" alt="Event" /> </div>
                                    <div class="date">DD/MM/YYY</div>
                                    <a href="#" class="btn">CTA Button</a>
                                </div>
                            </div>
                        </article> -->
                        <!-- <article class="item">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box">
                                    <p class="font-weight-bold">2020 SoOLegal Knowledge Series by Avani Bansal
                                    </p>
                                    <div class="img-box"><img src="../../assets/images/avani_extn_1200x628.jpg" alt="Event" /> </div>
                                    <div class="date">DD/MM/YYY</div>
                                    <a href="#" class="btn">CTA Button</a>
                                </div>
                            </div>
                        </article> -->
                        <!-- <article class="item">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box">
                                    <p class="font-weight-bold">2020 SoOLegal Knowledge Series by Avani Bansal
                                    </p>
                                    <div class="img-box"><img src="../../assets/images/avani_extn_1200x628.jpg" alt="Event" /> </div>
                                    <div class="date">DD/MM/YYY</div>
                                    <a href="#" class="btn">CTA Button</a>
                                </div>
                            </div>
                        </article> -->
                        <!-- <article class="item">
                            <div class="component-box-1 component-box-bg-light">
                                <div class="highlight-box">
                                    <p class="font-weight-bold">2020 SoOLegal Knowledge Series by Avani Bansal
                                    </p>
                                    <div class="img-box"><img src="../../assets/images/avani_extn_1200x628.jpg" alt="Event" /> </div>
                                    <div class="date">DD/MM/YYY</div>
                                    <a href="#" class="btn">CTA Button</a>
                                </div>
                            </div>
                        </article> -->
                    </div>
                    <!-- <a href="#" class="arrow ml"><img src="../../assets/images/icons/arrow_left.png"></a>
                    <a href="#" class="arrow mr r-180"><img src="../../assets/images/icons/arrow_left.png"></a> -->
                </div>
            </section>


            <!-- <a href="#" class="arrow law-ml"><img src="../../assets/images/icons/arrow_left.png" /></a>
            <a href="#" class="arrow law-mr r-180"><img src="../../assets/images/icons/arrow_left.png" /></a> -->


        </div>
    </section>

    <section class="title-bg-2 my-4  ipad-hide phone-hide">
        <div class="container">
            <div class="row">
                <div class="col-md-2 #90278E-bg-new order-md-12">
                    <div class="triangle-bottomleft"></div>
                    <div class="icon-box"></div>
                </div>
                <div class="col-md-10 white-bg-new">
                    <div class="triangle-topleft"></div>
                    <span class="gray-bg">Go Premium</span>
                    <h3>Be at ease with SoOLegal's Cloud & AI powered DIY assistance</h3>
                </div>
            </div>
        </div>
    </section>


    <div class="h-50"></div>


    <section class="partners-main-box my-3">
        <div class="container-fluid p-0">
            <div class="bg-gray-new shadow-none">

                <div class="row justify-content-between align-items-center">
                    <div class="col-md-12 col-lg-5 my-2 logo-box "> <img src="../../assets/images/logo.png" alt="" class="im" />
                        <h4 class="title-1">Partner Services</h4>
                    </div>
                    <div class="col-md-12 col-lg-7 my-2">
                        <div class="partners owl-carousel owl-theme">
                            <div class="item">
                                <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                            </div>
                            <div class="item">
                                <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <div class="mobile-menu-bar">
        <a data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"> <img src="../../assets/images/home.png" alt="home" /> </a>
        <a href="#"> <img src="../../assets/images/users.png" alt="User" /> </a>
    </div>

</main>


<!-- PopUp Box for find a Lawyer -->
<div class="modal fade" id="mycalevent" tabindex="-1" aria-labelledby="findLawer" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 50%;">
        <div class="modal-content rounded-0">

            <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closemycalender>
                <span aria-hidden="true">&times;</span>
            </button>

            <div class="modal-body popup-body">
                <h4>My Calender</h4>

                <div class="tabs">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" (click)="tab01.click()"> Hearing</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"> Appointment</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab03"> Need A Lawyer</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab04"> Public Event</a>
                        </li>
                    </ul>
                </div>
                <div id="tab01" class="tab-contents" #tab01>
                    <div>
                        <h3>Hearing 1</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quos aliquam consequuntur, esse provident impedit minima porro! Laudantium laboriosam culpa quis fugiat ea, architecto velit ab, deserunt rem quibusdam voluptatum.</p>

                        <h3>Hearing 2</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quos aliquam consequuntur, esse provident impedit minima porro! Laudantium laboriosam culpa quis fugiat ea, architecto velit ab, deserunt rem quibusdam voluptatum.</p>

                        <h3>Hearing 3</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quos aliquam consequuntur, esse provident impedit minima porro! Laudantium laboriosam culpa quis fugiat ea, architecto velit ab, deserunt rem quibusdam voluptatum.</p>
                    </div>
                </div>
                <div id="tab02" class="tab-contents" aria-hidden="true" #tab02>
                    <h3>Appointment</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quos aliquam consequuntur, esse provident impedit minima porro! Laudantium laboriosam culpa quis fugiat ea, architecto velit ab, deserunt rem quibusdam voluptatum.</p>

                    <h3>Hearing 2</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quos aliquam consequuntur, esse provident impedit minima porro! Laudantium laboriosam culpa quis fugiat ea, architecto velit ab, deserunt rem quibusdam voluptatum.</p>

                    <h3>Hearing 3</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quos aliquam consequuntur, esse provident impedit minima porro! Laudantium laboriosam culpa quis fugiat ea, architecto velit ab, deserunt rem quibusdam voluptatum.</p>
                </div>




            </div>






        </div>
    </div>
</div>
<div class="modal fade" id="addevent" tabindex="-1" aria-labelledby="findLawer" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 33%;">
        <div class="modal-content rounded-0">

            <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closepersnol>
                <span aria-hidden="true">&times;</span>
            </button>

            <div class="modal-body popup-body">
                <h3> Personal Information </h3>
                <form class="mt-28">

                    <select id="option-size" class="form-control custom-control">
                        <option selected=""> -Select Prefix- </option>

                        <option value="Prof.">Prof.</option>

                        <option value="Adv.">Adv. </option>

                        <option value="Dr.">Dr. </option>

                        <option value="Solicitor.">Solicitor </option>

                        <option value="Dean.">Dean </option>

                        <option value="Retd. Justice">Retd. Justice </option>
                    </select>
                    <!--  [(ngModel)]="userInfo.first_name" -->
                    <div class="form-group">
                        <input type="text" class="form-control rounded-0" name="first_name" aria-describedby="phoneHelp" placeholder="First Name">

                    </div>

                    <div class="form-group">
                        <input type="text" class="form-control rounded-0" name="middle_name" aria-describedby="phoneHelp" placeholder="Middle Name">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control rounded-0" name="last_name" aria-describedby="phoneHelp" placeholder="Last Name">

                    </div>
                    <div class="form-group">

                        <input type="date" class="form-control rounded-0" name="dob" aria-describedby="emailHelp" placeholder="YYYY-MM-DD">

                    </div>

                    <button type="submit" class="d-block btn btn-primary mb-0 mx-auto"> <span
                            class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        &nbsp;

                        <span>Submit</span>
                    </button>
                </form>



            </div>




        </div>
    </div>
</div>
<span #opencalenderModal data-toggle="modal" data-backdrop="static" data-target="#mycalevent" style="display: none;"></span>
<span #calenderModal data-toggle="modal" data-backdrop="static" data-target="#addevent" style="display: none;"></span>
<!-- addevent -->