<div class="container">
    <div class="row">
        <div class="col-md-9 col-sm-6">
            <form [formGroup]="myForm1" (ngSubmit)="submit()">
                <h2>Add News</h2>

                <div class="form-row">

                    <div class="form-group col-md-6">
                        <label for="title">Title</label><span *ngIf="submitted && f.title.errors.required"
                            style="color:red">
                            (Required)</span>

                        <input type="text" class="form-control" formControlName="title" [required]="true"
                            id="inputEmail4" placeholder="">

                    </div>
                    <!-- <div *ngIf="!myForm.valid">Enter Title</div> -->
                    <div class="col-lg-6 col-sm-6">
                        <label for="image">Select images<span *ngIf="submitted && f.url.errors.required"
                                style="color:red">
                                (Required)</span></label>
                        <div class="form-group"><input type="file" name="url" formControlName="url" id="url"
                                (change)="onFileChange($event)" class="filestyle" value="" data-icon="false"
                                tabindex="-1" style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
                            <div class="bootstrap-filestyle input-group"><input type="text"
                                    class="form-control no-padd " placeholder="" disabled=""
                                    [value]="newsImage.name?newsImage.name:(existingNews?existingNews.url:'')">
                                <span class="group-span-filestyle input-group-btn" tabindex="0"><label for="url"
                                        class="btn btn-default "><span class="buttonText">Choose</span></label></span>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="inputCity">Image Source Link</label>
                        <input type="text" class="form-control" formControlName="image_source_title"
                            id="image_source_title" placeholder="">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="publishdate">Source Link<span
                                *ngIf="submitted && f.image_source_link.errors.required" style="color:red">
                                (Required)</span></label>
                        <input type="text" class="form-control" [required]="true" formControlName="image_source_link"
                            id="image_source_link" placeholder="">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="publishdate">Publish Date<span *ngIf="submitted && f.publish_date.errors.required"
                                style="color:red"> (Required)</span></label><input type="date" name="publishdate"
                            value="" formControlName="publish_date" id="publishdate" placeholder="DD-MM-YYYY"
                            class="form-control timepicker hasDatepicker">



                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputCity">Tags<span *ngIf="submitted && f.tags.errors.required" style="color:red">
                                (Required)</span></label>
                        <input type="text" class="form-control" formControlName="tags" id="inputCity">

                    </div>
                </div>
                <!-- Note Editor -->
                <div class="form-row">
                    <div class="form-group col-md-12">

                        <label for="note" formControlName="content">Content <span
                                *ngIf="submitted && f.content.errors.required" style="color:red">
                                (Required)</span></label>
                        <div class="NgxEditor__Wrapper" height="100px">
                            <ngx-editor-menu [editor]="editor" height="500px"> </ngx-editor-menu>
                            <ngx-editor [editor]="editor" height="100px" formControlName="content" [disabled]="false"
                                [placeholder]="'Type here...'"></ngx-editor>
                        </div>
                        <!-- <div formControlName="content" id="summernote"></div> -->

                    </div>
                </div>
                <!--End Note Editor [disabled]="!myForm.valid" -->
                <button type="submit" class="btn btn-primary">Publish</button>
            </form>
        </div>
    </div>
</div>