import { Component,  ElementRef,  OnInit, ViewChild } from '@angular/core';
import { MemberService } from '../member.service';
@Component({
  selector: 'app-connection-list',
  templateUrl: './connection-list.component.html',
  styleUrls: ['./connection-list.component.css']
})
export class ConnectionListComponent implements OnInit {


data:any;
list:any;
peerid:any;
rejectop= false
  name: any;
  @ViewChild('rejectmodel')rejectmodel: ElementRef;
  index: any;
  constructor(private memberService:MemberService,) { 

  
  }

  ngOnInit(): void {

    this.memberService.getlist().subscribe((res:any)=>{
      this.data = res.data;
      console.log(this.data,"connetion")
     
    })

    this.memberService.putlist().subscribe((res2:any)=>{
      this.list = res2.data;
     
    })

  }
accept(data,i){
  const formData = new FormData();

   formData.append('peer_id', data);
  this.memberService.postmember('connection/accept',formData).subscribe((res:any)=>{
    if(res.error==false){
      this.list.splice(i, 1);
    }
   
    
  })
  // postmember
}

reject(){
 
  const formData = new FormData();

   formData.append('peer_id', this.peerid);
  this.memberService.postmember('connection/reject',formData).subscribe((res:any)=>{
    this.list.splice(this.index, 1);
    
  })
  // postmember
}


openreject(data,name,i){
this.peerid = data
this.name = name
this.index = i 

  this.rejectmodel.nativeElement.click()
}


removeconnection(data)
{
  console.log(data ,"member id ")
  const formData = new FormData();

  formData.append('peer_id', data);

 this.memberService.postmember('connection/remove', formData).subscribe((res: any) => {
   console.log(res,"unfollow")
// this.getfollowers();
 })
}  // conformlist() {
  //   if(confirm("Are you sure to delete "+ name)) {
 
  //   }
   
  // }
  // open(data) {

  //   this.memberService.getlist().subscribe(result) => {
 
  //     this.data = res.result;
  //   })
  

  }
  
  
// }

// function result(arg0: string, result: any) {
//   throw new Error('Function not implemented.');
// }


//   this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
//     this.closeResult = `Closed with: ${result}`;
//   }, (reason) => {
//     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
//   });
// }

// private getDismissReason(reason: any): string {
//   if (reason === ModalDismissReasons.ESC) {
//     return 'by pressing ESC';
//   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
//     return 'by clicking on a backdrop';
//   } else {
//     return  `with: ${reason}`;
//   }