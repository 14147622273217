<div class="tab-content" id="ex2-content">
    <div class="tab-pane fade show active" id="ex2-tabs-1" role="tabpanel"
        aria-labelledby="ex2-tab-1">
       
        <div class="row">
            <div class="col-sm-9" style="padding-right:10px">
                <!-- <h3>The High Court of Delhi was established on 31st October, 1966.</h3> -->
                <div class="h2 mrgTN">Album List</div>
                <div id="container"></div>
                <div id="images" class="imgGallery" *ngFor="let b of barassociation">
                    <div class="col-sm-4 col-xs-6">
                        <div class="thumbnail">

                            <img [src]="getImage(b)" class="img-responsive" alt="" title="gallery">


                        </div>
                        
                    </div>
                    <!-- <div class="col-sm-4 col-xs-6">
                        <div class="thumbnail">

                            <a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="lableCB">Vikram Samvat Nav Varsh<br>
                                                                            </a>
                            <a title="Vikram Samvat Nav Varsh" href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="img" style="display: inline-block;"><img src="https://cdn.soolegal.com/assets/uploads/barassociation/gallery/_thumb/1-001.jpg" class="img-responsive barassoAlbum" alt="Vikram Samvat Nav Varsh" title="Vikram Samvat Nav Varsh"></a>

                        </div>
                        
                    </div>
                    <div class="col-sm-4 col-xs-6">
                        <div class="thumbnail">

                            <a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="lableCB">Vikram Samvat Nav Varsh<br>
                                                                            </a>
                            <a title="Vikram Samvat Nav Varsh" href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="img" style="display: inline-block;"><img src="https://cdn.soolegal.com/assets/uploads/barassociation/gallery/_thumb/1-001.jpg" class="img-responsive barassoAlbum" alt="Vikram Samvat Nav Varsh" title="Vikram Samvat Nav Varsh"></a>

                        </div>
                        
                    </div>
                    <div class="col-sm-4 col-xs-6">
                        <div class="thumbnail">

                            <a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="lableCB">Vikram Samvat Nav Varsh<br>
                                                                            </a>
                            <a title="Vikram Samvat Nav Varsh" href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="img" style="display: inline-block;"><img src="https://cdn.soolegal.com/assets/uploads/barassociation/gallery/_thumb/1-001.jpg" class="img-responsive barassoAlbum" alt="Vikram Samvat Nav Varsh" title="Vikram Samvat Nav Varsh"></a>

                        </div>
                        
                    </div>
                    <div class="col-sm-4 col-xs-6">
                        <div class="thumbnail">

                            <a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="lableCB">Vikram Samvat Nav Varsh<br>
                                                                            </a>
                            <a title="Vikram Samvat Nav Varsh" href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="img" style="display: inline-block;"><img src="https://cdn.soolegal.com/assets/uploads/barassociation/gallery/_thumb/1-001.jpg" class="img-responsive barassoAlbum" alt="Vikram Samvat Nav Varsh" title="Vikram Samvat Nav Varsh"></a>

                        </div>
                        
                    </div>
                    <div class="col-sm-4 col-xs-6">
                        <div class="thumbnail">

                            <a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="lableCB">Vikram Samvat Nav Varsh<br>
                                                                            </a>
                            <a title="Vikram Samvat Nav Varsh" href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="img" style="display: inline-block;"><img src="https://cdn.soolegal.com/assets/uploads/barassociation/gallery/_thumb/1-001.jpg" class="img-responsive barassoAlbum" alt="Vikram Samvat Nav Varsh" title="Vikram Samvat Nav Varsh"></a>

                        </div>
                        
                    </div>
                    <div class="col-sm-4 col-xs-6">
                        <div class="thumbnail">

                            <a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="lableCB">Vikram Samvat Nav Varsh<br>
                                                                            </a>
                            <a title="Vikram Samvat Nav Varsh" href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="img" style="display: inline-block;"><img src="https://cdn.soolegal.com/assets/uploads/barassociation/gallery/_thumb/1-001.jpg" class="img-responsive barassoAlbum" alt="Vikram Samvat Nav Varsh" title="Vikram Samvat Nav Varsh"></a>

                        </div>
                        
                    </div>
                    <div class="col-sm-4 col-xs-6">
                        <div class="thumbnail">

                            <a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="lableCB">Vikram Samvat Nav Varsh<br>
                                                                            </a>
                            <a title="Vikram Samvat Nav Varsh" href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association/gallery/2" class="img" style="display: inline-block;"><img src="https://cdn.soolegal.com/assets/uploads/barassociation/gallery/_thumb/1-001.jpg" class="img-responsive barassoAlbum" alt="Vikram Samvat Nav Varsh" title="Vikram Samvat Nav Varsh"></a>

                        </div>
                        
                    </div> -->
                </div>
                </div>
               
            <!-- bar association sidebar area start -->
            <div class="col-md-3">
                <h5>View Past Events/videos</h5>
                <div class="top-arrow">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-short" style="height:30%; width:30%"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
                    </svg>
                </div>


              
                <div class="box-slider-1" id="sidebar" *ngFor="let l of limit1">
                    <div class="component-box-1" >
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>{{l.name}}</h4>
                                <div class="user-info-box-inner p-0 no-scroll min-height">
                                    <div class="user-info-box">
                                        <div class="bg-white">
                                            <img src="images/pasted-image.jpg" class="img-fluid" />
                                        </div>
                                        <div class="box-wrapper">
                                            <p>
                                                {{l.description}}
                                            </p>
                                            <a [attr.href]="'/event/' + l.id +'/add'" class="btn btn-primary">Register Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                  
                </div>
                <div class="bottom-arrow">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-down-short" style="height:30%; width:30%"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
                    </svg>
                </div>
            </div>

            <script
                src="https://cdn.static.soolegal.com/assets/js/bar-association.js"></script>
            <!-- bar association sidebar area end -->
        
    </div>
    <div class="tab-pane fade" id="ex2-tabs-2" role="tabpanel" aria-labelledby="ex2-tab-2">
        Tab 2 content
    </div>
    <div class="tab-pane fade" id="ex2-tabs-3" role="tabpanel" aria-labelledby="ex2-tab-3">
        Tab 3 content
    </div>
</div>













