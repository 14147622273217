import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SocialAuthService, SocialUser } from 'angularx-social-login';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginComponent } from './login/login.component';

const helper = new JwtHelperService();
 const login = new Subject<any>();
 const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
 
// const login = new BehaviorSubject<any>(false)

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public url = environment.baseURL;
  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  gettoken: any;
  toke: boolean;
  googletoken: any;
  constructor(public http: HttpClient,private socialAuthService: SocialAuthService) { }
  noImagePath = 'assets/upload.jpg';
 
  tokenNotExpired() {
  
    const token: string = localStorage.getItem('USERID');
    // console.log(token,"token 1")

    // token != null && !helper.isTokenExpired(token);
    this.toke= token!=null&&!helper.isTokenExpired(token)
    

    // this.gettoken= this.socialAuthService.authState.pipe(
    //   map((socialUser: SocialUser) => !!socialUser),
    //   tap((isLoggedIn: boolean) => {

      
    //   })
    // );
    this.gettoken=this.socialAuthService.authState.pipe(
      map((socialUser: SocialUser) =>!!socialUser
      // {
      //   localStorage.setItem('authtoken',socialUser.authToken)
      //   !!socialUser
       
      // }
    ))

    this.gettoken.subscribe((res:any)=>{
     
      this.googletoken = res
    })

   
    // if(token&&)
    return this.googletoken ||this.toke

  
  }
  logout(){
    localStorage.clear()
    sessionStorage.clear()
    this.socialAuthService.signOut()
  }


  loggedIn() {
    return this.tokenNotExpired();
  }
  refreshToken(token: string) {
    this.loginPrev.openrefresh();
    // this.loginPrev.openrefresh()
    //     .then(() => {
    //       window.location.reload();
    //     });
    // return this.http.post(this.url + 'refreshtoken', {
    //   refreshToken: token
    // }, httpOptions);
  }

  getdata(urlt: any) {
    const getUrl = this.url + urlt;
    const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('USERID')); 
    const headers = { headers: header };
    return this.http.get(getUrl,headers);
  }

  postdata(urlT: any, data: any) {
    const updateUrl = this.url + urlT;
    const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('USERID')); // may be localStorage/sessionStorage
    const headers = { headers: header };
   
    return this.http.post(updateUrl, data,headers);
  }
  //  $ ("#signin-modal").modal('show');
  //  $ ("#signin-modal").modal('hide');
  // this.router.navigateByUrl('/')
// client Id
  // 525693853834-t9t3bgdab5brin85b6kvetp8pnrpcvjd.apps.googleusercontent.com

  // secreat key 
  // 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
}
