import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ResourceCentreService {
  public apiURL = environment.baseURL;


  constructor(private httpClient: HttpClient) { }

  getAll(parent_id): Observable<any> {
    if(localStorage.getItem('USERID')){
      const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('USERID') });
      const headers = { headers: header };
      return this.httpClient.get<any>(this.apiURL + 'resourcecenter?parent_id='+parent_id)
      .pipe(
        catchError(this.errorHandler)
      )
    }else{
      return this.httpClient.get<any>(this.apiURL + 'resourcecenter?parent_id='+parent_id)
      .pipe(
        catchError(this.errorHandler)
      )
    }



    // return this.httpClient.get<any>(this.apiURL + 'resourcecenter?parent_id='+parent_id)
    //   .pipe(
    //     catchError(this.errorHandler)
    //   )
  }
  get_all_sub_category_list(){

    return this.httpClient.get(this.apiURL + 'get_all_sub_category_list')

      .pipe(
        catchError(this.errorHandler)
      )
  }



  find(id: number): Observable<any> {

    return this.httpClient.get(this.apiURL + 'news/' + id)

      .pipe(
        catchError(this.errorHandler)
      )
  }

  create(formData): Observable<any> {
    const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('USERID') });
    const headers = { headers: header };
    console.log(headers, localStorage.getItem('USERID'));
  
  //   for (var value of formData.values()) {
  //     console.log(value); 
  //  }
    // return this.httpClient.post(this.apiURL + 'resourcecenter', formData,headers)
    return this.httpClient.post(this.apiURL + 'post_resourcecenter', formData,headers)
      .pipe(
        catchError(this.errorHandler)
      )
  }
  judgementCreate(formData){
    return this.httpClient.post(this.apiURL + 'resource_center_judgement_summary', formData)
    .pipe(
      catchError(this.errorHandler)
    )
  }
  translation(formData){
    return this.httpClient.post(this.apiURL + 'resource_center_legal_document_translation', formData)
    .pipe(
      catchError(this.errorHandler)
    )
  }
  research(formData){
    return this.httpClient.post(this.apiURL + 'resource_center_legal_research', formData)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getDocFree(resource_centre_id){
    return this.httpClient.get(this.apiURL + 'rcDocFree?resource_centre_id='+resource_centre_id )
    .pipe(
      catchError(this.errorHandler)
    )
  }

  rcDocViewCreate(formData){
    return this.httpClient.post(this.apiURL + 'rcDocViewCreate',formData )
    .pipe(
      catchError(this.errorHandler)
    )
  }

// resourceList(){
//    return this.httpClient.post(this.apiURL + 'rcDocViewCreate',formData )
//     .pipe(
//       catchError(this.errorHandler)
//     )
// }
resourcelistview(category_id,payment_type){
  console.log("service call",payment_type);
  // let data={
  //   "category_id" :category_id,
  //   "payment_type":payment_type
  // }
  return this.httpClient.post(this.apiURL + 'resource-center-list?category_id='+category_id+'&payment_type='+payment_type,'')
  .pipe(
    catchError(this.errorHandler)
  )
}
resourceSingleview(resource_center_id){
  // return this.httpClient.post(this.apiURL + 'resource-center-view?resource_center_id='+resource_center_id,'')
  return this.httpClient.get(this.apiURL + 'show_feed_data/' + resource_center_id)
  .pipe(
    catchError(this.errorHandler)
  )
}
featured_members(){
  return this.httpClient.get(this.apiURL + 'featured_members')
  .pipe(
    catchError(this.errorHandler)
  )
}
most_recent_article(){
  return this.httpClient.get(this.apiURL + 'resource_center_most_recent_article')
  .pipe(
    catchError(this.errorHandler)
  )
}
topContributor(){
  return this.httpClient.get(this.apiURL + 'resource-center-top-contributor')
  .pipe(
    catchError(this.errorHandler)
  )
}
totalsearch(){
  return this.httpClient.get(this.apiURL + 'totalsearch')
  .pipe(
    catchError(this.errorHandler)
  )
}
rcdownload(formData){
  return this.httpClient.post(this.apiURL + 'rcdownload',formData)
  .pipe(
    catchError(this.errorHandler)
  )
}
// rcview(formData){
//   return this.httpClient.post(this.apiURL + 'rcView',formData)
//   .pipe(
//     catchError(this.errorHandler)
//   )
// }

rcview(formData){
  return this.httpClient.post(this.apiURL + 'category_filter',formData)
  .pipe(
    catchError(this.errorHandler)
  )
}
search(search_key_word){
  return this.httpClient.get(this.apiURL + 'resource_center_search?search_key_word='+search_key_word)
  .pipe(
    catchError(this.errorHandler)
  )
}

post_search(formData): Observable<any> {   
  
  return this.httpClient.post(this.apiURL + 'post_search', formData)
  .pipe(
    catchError(this.errorHandler)
  )
}  







servicePackage(){
  return this.httpClient.get(this.apiURL + 'resource_center_service_package')
  .pipe(
    catchError(this.errorHandler)
  )
// }
// subcategoryList(category_name){
//   return this.httpClient.post(this.apiURL + 'resource_center_all_subcategory_view?category_name='+category_name,'')
//   .pipe(
//     catchError(this.errorHandler)
//   )
// }
}
subcategoryList(category_id){
  return this.httpClient.get(this.apiURL + 'get_sub_category_view/'+category_id)
  .pipe(
    catchError(this.errorHandler)
  )
}
  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  // add to cart
  addtocart(formData){
    console.log("service call",formData);
    return this.httpClient.post(this.apiURL + 'resource_center_addCart',formData)
    .pipe(
      catchError(this.errorHandler)
    )
  }
  resourceCartList(){
    return this.httpClient.get(this.apiURL + 'resource_center_cart_list')
    .pipe(
      catchError(this.errorHandler)
    )
  }
  removecartItem(itemId){
    return this.httpClient.get(this.apiURL + 'resource_center_removeInCart?itemId='+itemId)
    .pipe(
      catchError(this.errorHandler)
    )
  }
  resource_center_checkout_page(formData){
    console.log("service call",formData);
    return this.httpClient.post(this.apiURL + 'resource_center_checkout_page',formData)
    .pipe(
      catchError(this.errorHandler)
    )
  }
  videos(payment_type){
    return this.httpClient.get(this.apiURL + 'resource_center_most_recent_videos?payment_type='+payment_type)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  // Resource Centre Get List

  resource_centre_article_list(){
    return this.httpClient.get(this.apiURL + 'most_recent_article')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  get_rc_popular_videos(){
    return this.httpClient.get(this.apiURL + 'most_recent_videos')
    .pipe(
      catchError(this.errorHandler)
    )
  }

judgementlistview(){
  let rc_category_id={
  "rc_category_id" :2
  
}
  // return this.httpClient.post(this.apiURL + 'category_filter?category_id='+category_id)
  return this.httpClient.post(this.apiURL + 'category_filter',rc_category_id)
  .pipe(
    catchError(this.errorHandler)
  )
} 

rcbareactslistview(){
  let rc_category_id={
  "rc_category_id" :3
  
}
  // return this.httpClient.post(this.apiURL + 'category_filter?category_id='+category_id)
  return this.httpClient.post(this.apiURL + 'category_filter',rc_category_id)
  .pipe(
    catchError(this.errorHandler)
  )
} 

resourcelegalprocedurelistview(){
  let rc_category_id={
  "rc_category_id" :1
  
}
  // return this.httpClient.post(this.apiURL + 'category_filter?category_id='+category_id)
  return this.httpClient.post(this.apiURL + 'category_filter',rc_category_id)
  .pipe(
    catchError(this.errorHandler)
  )
} 

resourcelegalformatslistview(){
  let rc_category_id={
  "rc_category_id" :4
  
}
  // return this.httpClient.post(this.apiURL + 'category_filter?category_id='+category_id)
  return this.httpClient.post(this.apiURL + 'category_filter',rc_category_id)
  .pipe(
    catchError(this.errorHandler)
  )
} 

legal_procedure_sub_category_list()
{
  return this.httpClient.get(this.apiURL + 'get_sub_category_list/1')
  .pipe(
    catchError(this.errorHandler)
  )
}

legal_format_sub_category_list()
{
  return this.httpClient.get(this.apiURL + 'get_legal_format_sub_category_list/4')
  .pipe(
    catchError(this.errorHandler)
  )
}
legal_format_sub_sub_category_list($sub_category_id)
{
  return this.httpClient.get(this.apiURL + 'get_legal_format_sub_category_list/'+$sub_category_id)
  .pipe(
    catchError(this.errorHandler)
  )
}

find_sub_category_name(rc_subcategory_id)
{
  return this.httpClient.get(this.apiURL + 'get_category_name_list/'+ rc_subcategory_id)
  .pipe(
    catchError(this.errorHandler)
  )
}

}
