import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { BarAssociationService } from '../bar-association.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Barassociation } from '../barassociation';
import { EventService } from '../../event/event.service';
@Component({
    selector: 'app-member',
    templateUrl: './member.component.html',
    styleUrls: ['./member.component.css']
  
  })
  export class MemberComponent implements OnInit {
   
    constructor(public barAssociationService:BarAssociationService, public eventService:EventService,private route: ActivatedRoute,) { }
    barassociation:Barassociation[]=[];
   

    ng2NestedSearch:any;
    searchName : string = "";
    searchEmail : string = "";
    searchNumber : string = "";
    filterMetadata = { count: 0, isNestedSearch:true };
    limit1:any;

   getevent:any;
   
   


    ngOnInit(): void {
        var bar_association_id = this.route.parent.snapshot.paramMap.get('barId');
        this.getBarMember(bar_association_id);
        // this.getevent();
        this.limit();
        console.log('gggggg',bar_association_id);
      }
      getBarMember(barId){

        this.barAssociationService.bar_association_member(barId).subscribe((res: Barassociation[]) => {
          this.barassociation = res;
          console.log('saaaaaaaaaaa',this.barassociation)
        });
      }
      limit(){
        this.eventService.limit().subscribe((com:any) => {
          this.limit1 = com;
     
      
        });
       }
     

    }