import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoginModule } from './login/login.module';
import { RegisterModule } from './register/register.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OtpVerifyComponent } from './otp-verify/otp-verify.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
// import { RoarComponent } from './roar/roar.component';
// import { NewsComponent } from './news/news.component';
// import { ResourceCentreComponent } from './resource-centre/resource-centre.component';
// import { EventComponent } from './event/event.component';
import { CartComponent } from './cart/cart.component';
// import { PrivacyComponent } from './privacy/privacy.component';
// import { TransactionTermsComponent } from './transaction-terms/transaction-terms.component';
// import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { EventModule } from './event/event.module';
import { RoarModule } from './roar/roar.module';
import { NewsModule } from './news/news.module';
import { CommentModule } from './comment/comment.module';
import { ResourceCentreModule } from './resource-centre/resource-centre.module';
import { NeedALawyerModule } from './need-a-lawyer/need-a-lawyer.module';
import { ServicesModule } from './services/services.module';
import { BarAssociationModule } from './bar-association/bar-association.module';
import { JobCentreModule } from './job-centre/job-centre.module';
import { AboutModule } from './about/about.module';
import { PrivacyModule } from './privacy/privacy.module';
import { TransactionTermsModule } from './transaction-terms/transaction-terms.module';
import { HowItWorksModule } from './how-it-works/how-it-works.module';
import { MemberModule } from './member/member.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PublicProfileModule } from './public-profile/public-profile.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RecaptchaModule } from "ng-recaptcha";
import { SearchModule } from './search/search.module';
import { LoadingInterceptor } from './modals/LoadingInterceptor';
import { MonitorInterceptor } from './monitor.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule } from 'ngx-tabset';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { OverlayModule } from '@angular/cdk/overlay';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { FacebookLoginProvider } from "angularx-social-login";
import { NgxPaginationModule } from 'ngx-pagination';
import { NotificationModule } from './notification/notification.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgxClampModule } from 'ngx-clamp';
import { NgxUiLoaderModule ,NgxUiLoaderRouterModule,NgxUiLoaderHttpModule} from "ngx-ui-loader";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FilterPipeModule } from 'ng2-nested-search';
import { ReadMoreModule } from 'ng-readmore';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// import { MdDialog, MdDialogRef } from '@angular/material';

// import { CustomMaterialModule } from './custom-material/custom-material.module';
// import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';


// import { ConfirmationDialogComponent } from './ui/confirmation-dialog/confirmation-dialog.component';

import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { ContactusModule } from './contactus/contactus.module';



// import { RoarModule } from './roar/roar.module';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ForgetPasswordComponent,
    NavbarComponent,
    OtpVerifyComponent,
    FooterComponent,
    CartComponent,
    // ConfirmationDialog,
  ],
  
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    LoginModule,
    RegisterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    EventModule,
    RoarModule,
    NewsModule,
    ResourceCentreModule,
    NeedALawyerModule,
    ServicesModule,
    BarAssociationModule,
    JobCentreModule,
    AboutModule,
    PrivacyModule,
    TransactionTermsModule,
    HowItWorksModule,
    MemberModule,
    SearchModule,
    ImageCropperModule,
    PublicProfileModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    SlickCarouselModule,
    NgbModule,
    TabsModule,
    ShareButtonsPopupModule,
    OverlayModule,
    AutocompleteLibModule,
    SocialLoginModule,
    NgxPaginationModule,
    NotificationModule,
    MatDatepickerModule,
    MatInputModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatDialogModule,
    MatButtonModule,
    NgxUiLoaderModule.forRoot({minTime:2000}),
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule,
    Ng2SearchPipeModule,
    FilterPipeModule,
    NgxClampModule ,
    ReadMoreModule,
    CommentModule,
    PdfViewerModule,
    ContactusModule,
    NgMultiSelectDropDownModule.forRoot(),
    // minTime
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MonitorInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true, //keeps the user signed in
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('525693853834-t9t3bgdab5brin85b6kvetp8pnrpcvjd.apps.googleusercontent.com') // your client id
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("254936553494580")
          }
        ]
      }
    },
    // AuthGuardService
  ],
  bootstrap: [AppComponent],
  
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
  
})
export class AppModule { }