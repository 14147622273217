
  <main role="main">
   
    <section class="filter">
      <div class="container">
        <div class="h-20"></div>

        <div class="row">
          <div class="col-md-9 col-sm-6" style="margin-top: 27px;">
            <h4>Bar Association</h4>
            <form [formGroup]="myForm" (ngSubmit)="sort()" style="margin-top: 40px;">
            <div class="filter-tags flex-center">

              <div class="pretty-setting">
                <select id="option-size" formControlName="country" class="custom-control">
                  <option value="Sort Country"> Sort Country </option>
                  <option *ngFor="let s of sort_country" value="{{s.country}}" selected="">
                    {{s.country}} </option>

                  
                </select>
                <span class="text"><span class="label"></span><span class="value" formControlName="country">Select Country</span></span>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="#ccc"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </div>

              <div class="pretty-setting">
                <select id="option-size" formControlName="state" class="custom-control">
                  <option value="Sort State"> Sort State </option>
                  <option *ngFor="let s of sort_state" value="{{s.state}}" selected="">
                    {{s.state}} </option>
                  </select>
                
                <span class="text"><span class="label"></span><span class="value" formControlName="state">Select State</span></span>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="#ccc"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
               
              </div>
             
              <div class="pretty-setting">
                <select id="option-size" formControlName="city" class="custom-control">
                  <option value="Sort City"> Sort City </option>
                  <option *ngFor="let c of sort_city" value="{{c.city}}" selected="">
                    {{c.city}} </option>

                  
                </select>
                <span class="text"><span class="label"></span><span class="value" formControlName="city">Select City</span></span>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="#ccc"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </div>

              <div class="pretty-setting">
                <select id="option-size" formControlName="district" class="custom-control">
                  <option value="Sort District"> Sort District </option>
                  <option *ngFor="let s of sort_district" value="{{s.district}}" selected="">
                    {{s.district}} </option>
                </select>
                <span class="text"><span class="label"></span><span class="value">Select District</span></span>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="#ccc"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </div>

              <a  (click)="sort()"  style="color: white !important;" class="btn btn-primary btn-filter flex-center text-center">Sort</a>
              
            </div>
          </form>


            <div class="events-details" *ngFor="let b of barassociation" >
      <!-- <div class="events-details"> -->
              <article class="row" >
                <div class="col-md-10">
                  <div class="wrapper">
                    <a [attr.href]="'/bar-association/' + b.bar_association_id"><h3  class="title">{{b.bar_association_name}}</h3></a>
                    <p style="padding: 10px 0px 0px ;" [innerHtml]="b.description" ngx-clamp [ngxClampOptions]="{ clamp: 3}">
                    </p>
                    <div style="display: flex;" class="contact-group">
                      <p class="small link"><i class="fa fa-phone"></i> {{b.contact_no}}</p>
                      <p class="small link"><i class="fa fa-fw fa-envelope"></i>{{b.email}} </p>
                    </div>
                  </div>
                </div>
                <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <div class="img"><a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association"><img src="https://cdn.soolegal.com/assets/uploads/user_profile_pic/_thumb/Logo.jpg" class="img-thumbnail" alt="Delhi High Court Bar Association" title="Delhi High Court Bar Association" width="150" height="50"></a></div>
                </div>
                <!-- <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                </div> -->
              </article>

              <!-- <article class="row">
                <div class="col-md-10">
                  <div class="wrapper">
                    <h3  class="title">Delhi High Court Bar Association</h3>
                    <p style="padding: 10px 0;">Initially, High Court of judicature at Lahore, which was established by a letter patent dated 21 March, 1919, exercised judicature over the then provinces of Punjab and Delhi. This position continued till Indian Independence Act, 1947 when the dominions of India and Pakistan were created.
                    </p>
                    <div style="display: flex;" class="contact-group">
                      <p class="small link"><i style="transform: rotate(90deg);" class="fa fa-fw fa-phone"></i> 01123385562</p>
                      <p class="small link"><i class="fa fa-fw fa-envelope"></i>dhcbar@gmail.com, </p>
                    </div>
                  </div>
                </div>
                <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <div class="img"><a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association"><img src="https://cdn.soolegal.com/assets/uploads/user_profile_pic/_thumb/Logo.jpg" class="img-thumbnail" alt="Delhi High Court Bar Association" title="Delhi High Court Bar Association" width="150" height="50"></a></div>
                </div> -->
                <!-- <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                </div> -->
              <!-- </article>
              <article class="row">
                <div class="col-md-10">
                  <div class="wrapper">
                    <h3  class="title">Delhi High Court Bar Association</h3>
                    <p style="padding: 10px 0;">Initially, High Court of judicature at Lahore, which was established by a letter patent dated 21 March, 1919, exercised judicature over the then provinces of Punjab and Delhi. This position continued till Indian Independence Act, 1947 when the dominions of India and Pakistan were created.
                    </p>
                    <div style="display: flex;" class="contact-group">
                      <p class="small link"><i style="transform: rotate(90deg);" class="fa fa-fw fa-phone"></i> 01123385562</p>
                      <p class="small link"><i class="fa fa-fw fa-envelope"></i>dhcbar@gmail.com, </p>
                    </div>
                  </div>
                </div>
                <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <div class="img"><a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association"><img src="https://cdn.soolegal.com/assets/uploads/user_profile_pic/_thumb/Logo.jpg" class="img-thumbnail" alt="Delhi High Court Bar Association" title="Delhi High Court Bar Association" width="150" height="50"></a></div>
                </div> -->
                <!-- <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                </div> -->
              <!-- </article>
              <article class="row">
                <div class="col-md-10">
                  <div class="wrapper">
                    <h3  class="title">Delhi High Court Bar Association</h3>
                    <p style="padding: 10px 0;">Initially, High Court of judicature at Lahore, which was established by a letter patent dated 21 March, 1919, exercised judicature over the then provinces of Punjab and Delhi. This position continued till Indian Independence Act, 1947 when the dominions of India and Pakistan were created.
                    </p>
                    <div style="display: flex;" class="contact-group">
                      <p class="small link"><i style="transform: rotate(90deg);" class="fa fa-fw fa-phone"></i> 01123385562</p>
                      <p class="small link"><i class="fa fa-fw fa-envelope"></i>dhcbar@gmail.com, </p>
                    </div>
                  </div>
                </div>
                <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <div class="img"><a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association"><img src="https://cdn.soolegal.com/assets/uploads/user_profile_pic/_thumb/Logo.jpg" class="img-thumbnail" alt="Delhi High Court Bar Association" title="Delhi High Court Bar Association" width="150" height="50"></a></div>
                </div> -->
                <!-- <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                </div> -->
              <!-- </article>
              <article class="row">
                <div class="col-md-10">
                  <div class="wrapper">
                    <h3  class="title">Delhi High Court Bar Association</h3>
                    <p style="padding: 10px 0;">Initially, High Court of judicature at Lahore, which was established by a letter patent dated 21 March, 1919, exercised judicature over the then provinces of Punjab and Delhi. This position continued till Indian Independence Act, 1947 when the dominions of India and Pakistan were created.
                    </p>
                    <div style="display: flex;" class="contact-group">
                      <p class="small link"><i style="transform: rotate(90deg);" class="fa fa-fw fa-phone"></i> 01123385562</p>
                      <p class="small link"><i class="fa fa-fw fa-envelope"></i>dhcbar@gmail.com, </p>
                    </div>
                  </div>
                </div>
                <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <div class="img"><a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association"><img src="https://cdn.soolegal.com/assets/uploads/user_profile_pic/_thumb/Logo.jpg" class="img-thumbnail" alt="Delhi High Court Bar Association" title="Delhi High Court Bar Association" width="150" height="50"></a></div>
                </div> -->
                <!-- <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                </div> -->
              <!-- </article>
              <article class="row">
                <div class="col-md-10">
                  <div class="wrapper">
                    <h3  class="title">Delhi High Court Bar Association</h3>
                    <p style="padding: 10px 0;">Initially, High Court of judicature at Lahore, which was established by a letter patent dated 21 March, 1919, exercised judicature over the then provinces of Punjab and Delhi. This position continued till Indian Independence Act, 1947 when the dominions of India and Pakistan were created.
                    </p>
                    <div style="display: flex;" class="contact-group">
                      <p class="small link"><i style="transform: rotate(90deg);" class="fa fa-fw fa-phone"></i> 01123385562</p>
                      <p class="small link"><i class="fa fa-fw fa-envelope"></i>dhcbar@gmail.com, </p>
                    </div>
                  </div>
                </div>
                <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <div class="img"><a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association"><img src="https://cdn.soolegal.com/assets/uploads/user_profile_pic/_thumb/Logo.jpg" class="img-thumbnail" alt="Delhi High Court Bar Association" title="Delhi High Court Bar Association" width="150" height="50"></a></div>
                </div> -->
                <!-- <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                </div> -->
              <!-- </article>
              <article class="row">
                <div class="col-md-10">
                  <div class="wrapper">
                    <h3  class="title">Delhi High Court Bar Association</h3>
                    <p style="padding: 10px 0;">Initially, High Court of judicature at Lahore, which was established by a letter patent dated 21 March, 1919, exercised judicature over the then provinces of Punjab and Delhi. This position continued till Indian Independence Act, 1947 when the dominions of India and Pakistan were created.
                    </p>
                    <div style="display: flex;" class="contact-group">
                      <p class="small link"><i style="transform: rotate(90deg);" class="fa fa-fw fa-phone"></i> 01123385562</p>
                      <p class="small link"><i class="fa fa-fw fa-envelope"></i>dhcbar@gmail.com, </p>
                    </div>
                  </div>
                </div>
                <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <div class="img"><a href="https://www.soolegal.com/bar-association/delhi-high-court-bar-association"><img src="https://cdn.soolegal.com/assets/uploads/user_profile_pic/_thumb/Logo.jpg" class="img-thumbnail" alt="Delhi High Court Bar Association" title="Delhi High Court Bar Association" width="150" height="50"></a></div>
                </div> -->
                <!-- <div class="col col-md-2 text-right">
                  <div class="wrapper"></div>
                  <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                </div> -->
              <!-- </article> -->
            </div>
            <!-- <div class="d-flex justify-content-center">
                <pagination-controls
                  previousLabel="Prev"
                  nextLabel="Next"
                  (pageChange)="onTableDataChange($event)"
                >
                </pagination-controls>
              </div> -->
  
          </div>

          <div class="col-sm-6 col-md-3" style="margin-top: 32px;">
            <h4>View Past Events/videos</h4>
            <!-- <div class="top-arrow">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-short" style="height:30%; width:30%"
                  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                      d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
              </svg>
          </div> -->
            <div class="box-slider-1" id="sidebar" *ngFor="let l of Limit">
              <div class="component-box-1" >
                  <div class="user-info-box-main">
                      <div class="user-info-box">
                          <h4>{{l.title}}</h4>
                          <div class="user-info-box-inner p-0 no-scroll min-height">
                              <div class="user-info-box">
                                  <div class="bg-white">
                                      <img src="images/pasted-image.jpg" class="img-fluid" />
                                  </div>
                                  <div class="box-wrapper">
                                      <p>
                                         {{l.content}}
                                      </p>
                                      <a [attr.href]="'/event/' + l.id +'/add'" class="btn btn-primary">Register Now</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

           
          </div> 
          <!-- <div class="bottom-arrow">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-down-short" style="height:30%; width:30%"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
            </svg>
        </div> -->
          </div>
        </div>
      </div>
    </section>
    <div class="h-25 d-inline-block"></div>
    <section class="title-bg-2 my-4 ipad-hide phone-hide">
      <div class="container">
        <div class="row">
          <div class="col-md-2 #90278E-bg-new order-md-12">
            <div class="triangle-bottomleft"></div>
            <div class="icon-box"></div>
          </div>
          <div class="col-md-10 white-bg-new">
            <div class="triangle-topleft"></div>
            <span class="gray-bg">Go Premium</span>
            <h3>SoOLEGAL is recommended by our valued
              members and users</h3>
          </div>
        </div>
      </div>
    </section>

    <section class="profile-slider">
      <div class="container">
        <div class="events owl-carousel owl-theme">



          <div>
            <div class="component-box-1" style="width: 100%; display: inline-block;">
              <div class="user-info-box-main">
                <div class="user-info-box">
                  <h4>Online Webinar (August 2020)</h4>
                  <div class="user-info-box-inner p-0 no-scroll min-height">
                    <div class="user-info-box">
                      <div class="bg-white">
                        <img src="../../assets/images/pasted-image.jpg" class="img-fluid">
                      </div>
                      <div class="box-wrapper">
                        <p>
                          Global Pandemic of Domestic Violence: Best Practices &amp; Legal Solutions for Organizations
                          and Survivors.
                        </p>
                        <a class="btn btn-primary" tabindex="0">Watch Video</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




        </div>
      </div>
    </section>
    <section class="partners-main-box my-3">
      <div class="container-fluid p-0">
        <div class="bg-gray-new shadow-none">
          <div class="row justify-content-between align-items-center">
            <div class="col-md-12 col-lg-5 my-2 logo-box" style="padding-left: 60px;">
              <img src="../../assets/images/-logo.png" alt="" class="im" />
              <h4 class="title-1" style="margin-right: 50px;">Partner Services</h4>
            </div>
            <div class="col-md-12 col-lg-7 my-2">
              <div class="partners owl-carousel owl-theme owl-loaded owl-drag">
                <div class="owl-stage-outer">
                  <div class="owl-stage"
                    style="transform: translate3d(-537px, 0px, 0px); transition: all 0.25s ease 0s; width: 1255px;">
                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                      </div>
                    </div>
                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                      </div>
                    </div>
                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                      </div>
                    </div>
                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                      </div>
                    </div>
                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                      </div>
                    </div>
                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                      </div>
                    </div>
                    <div class="owl-item active" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                      </div>
                    </div>
                    <div class="owl-item active" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                      </div>
                    </div>
                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                      </div>
                    </div>
                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /> </a>
                      </div>
                    </div>
                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /></a>
                      </div>
                    </div>
                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /> </a>
                      </div>
                    </div>
                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /></a>
                      </div>
                    </div>
                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                      <div class="item">
                        <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /> </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="owl-nav disabled">
                  <button type="button" role="presentation" class="owl-prev"><span
                      aria-label="Previous">‹</span></button><button type="button" role="presentation"
                    class="owl-next"><span aria-label="Next">›</span></button>
                </div>
                <div class="owl-dots disabled"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>




  <!-- PopUp Box for event details -->
  <div class="modal fade" id="findLawer" tabindex="-1" aria-labelledby="findLawer" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

        <div class="modal-body popup-body">


          <div class="select-options">

            <div class="row">

              <!-- <div class="col-md-3">
                <div class="pretty-setting mr-auto ml-auto bg-default">
                  <select id="option-size" class="custom-control">
                    <option value="Option">All</option>
                    <option value="Option" selected="">Option</option>
                    <option value="Option">Option</option>
                    <option value="Option">Option</option>
                  </select>
                  <span class="text"><span class="label"></span><span class="value">Select Start Date

                    </span></span>
                  <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
                    <path d="M0-.75h24v24H0z" fill="none"></path>
                  </svg>
                </div>
              </div>



              <div class="col-md-3">
                <div class="pretty-setting mr-auto ml-auto bg-default">
                  <select id="option-size" class="custom-control">
                    <option value="Option">All</option>
                    <option value="Option" selected="">Option</option>
                    <option value="Option">Option</option>
                    <option value="Option">Option</option>
                  </select>
                  <span class="text"><span class="label"></span><span class="value">Select End Date

                    </span></span>
                  <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
                    <path d="M0-.75h24v24H0z" fill="none"></path>
                  </svg>
                </div>

              </div>


              <div class="col-md-3 offset-md-3">
                <div class="pretty-setting mr-auto ml-auto bg-default">
                  <select id="option-size" class="custom-control">
                    <option value="Option">All</option>
                    <option value="Option" selected="">Option</option>
                    <option value="Option">Option</option>
                    <option value="Option">Option</option>
                  </select>
                  <span class="text"><span class="label"></span><span class="value">Select Event Thumbnail

                    </span></span>
                  <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
                    <path d="M0-.75h24v24H0z" fill="none"></path>
                  </svg>
                </div>
              </div> -->
            </div>
          </div>
          <!-- <form class="mt-28">
            <div class="form-group">
              <textarea class="form-control rounded-0 bg-white" placeholder="Event Description"></textarea>
            </div>

            <div class="form-group">
              <input type="text" class="form-control rounded-0 bg-white" id="exampleInputEmail1"
                aria-describedby="phoneHelp" placeholder="Insert Home Page Link" />
            </div>
          </form> -->
          <form>
            <h3>Add Event</h3>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="title">Title</label>
                <input type="text" class="form-control bg-white" id="inputEmail4" placeholder="">
              </div>
              <div class="col-lg-6 col-sm-6">
                <label for="image">Select images</label>
                <div class="form-group"><input type="file" name="imageURL" id="url" class="filestyle" value=""
                    data-icon="false" tabindex="-1" style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
                  <div class="bootstrap-filestyle input-group"><input type="text" class="form-control bg-white "
                      placeholder="" disabled=""> <span class="group-span-filestyle input-group-btn"
                      tabindex="0"><label for="url" class="btn btn-default "><span
                          class="buttonText">Choose</span></label></span></div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputCity">Event Date</label>
                <input type="text" class="form-control bg-white" name="daterange" value="01/01/2018 - 01/15/2018" />
              </div>
              <div class="form-group col-md-6">
                <label for="VenueDetails">Venue Details</label>
                <input type="text" class="form-control bg-white" id="inputEmail4" placeholder="">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="tags">Tags</label>
                <input type="text" class="form-control bg-white" id="inputtags" placeholder="">
              </div>
              <div class="form-group col-md-6">
                <label for="eventCategory">Event Category</label>
                <input type="text" class="form-control bg-white" id="inputcategory" placeholder="">
              </div>
            </div>
            <!-- Note Editor -->
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="note">Content</label>
                <textarea name="description" id="your_summernote" class="form-control bg-white" rows="4"></textarea>
              </div>
            </div>
            <!--End Note Editor -->
            <div class="row">
              <div class="col-md-4">
                <label>
                  <input type="radio" name="event-registration" class="radio" />
                  <span class="radio-style d-inline-block"></span>
                  <span>Free Event Registration </span>
                </label>
              </div>

              <div class="col-md-4">
                <label>
                  <input type="radio" name="event-registration" class="radio" />
                  <span class="radio-style d-inline-block"></span>
                  <span>Paid Event Registration </span>
                </label>
              </div>

              <div class="col-md-4">
                <input type="text" class="form-control m-0 rounded-0 bg-white" id="exampleInputEmail1"
                  aria-describedby="phoneHelp" placeholder="Event Registration Amount" />
              </div>
            </div>
          </form>

          <div class="mt-4">
            <button type="submit" class="d-block btn btn-primary mb-0 mx-auto">Submit</button>
          </div>

        </div>
      </div>
    </div>
  </div>

 

  
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
