import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { BarAssociationService } from '../bar-association.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Barassociation } from '../barassociation';
import { EventService } from '../../event/event.service';
@Component({
    selector: 'app-contact',
    templateUrl: './contactus.component.html',
    styleUrls: ['./contactus.component.css']
  
  })
  export class ContactusComponent implements OnInit {
   
    constructor(public barAssociationService:BarAssociationService,public eventService:EventService, private route: ActivatedRoute,) { }
    barassociation:Barassociation;
    limit1:any;


    ngOnInit(): void {
        var bar_association_id = this.route.parent.snapshot.paramMap.get('barId');
        this.getBarContact(bar_association_id);
        this.limit();
      }
      getBarContact(barId){
        
        this.barAssociationService.bar_association_contactus(barId).subscribe((res: Barassociation) => {
          this.barassociation = res[0];
          console.log('mmmmm',res)
          
        });
      }
      limit(){
        this.eventService.limit().subscribe((com:any) => {
          this.limit1 = com;
     
      
        });
       }
    }