import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { ForgetService } from './forget.service';
export class forget {
  email:any;
  otp:any;
  password:any;
 
}





@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  forgetemail:any;
  confirm:any;
  message:any;
  @ViewChild('openforgotModal') forgotModal:ElementRef; 
  @ViewChild('openforgototpModal') forgototpModal:ElementRef; 
  @ViewChild('closeforgotModal') closeforgotModal: ElementRef;
  @ViewChild('closeforgototpModal') closeforgototpModal: ElementRef;
  @ViewChild('openresetModal') resetModal: ElementRef;
  @ViewChild('closeresetModal') closeresetModal: ElementRef;
  @ViewChild(LoginComponent)loginnav: LoginComponent;

  token: any;
  constructor(public api: ForgetService,public router: Router) { 

    this.forgetemail = new forget();
  }

  ngOnInit(): void {
  }
  openModal(){
    this.forgotModal.nativeElement.click()

  }
  reset() {
    setTimeout(() => {
      this.message = ''
      this.closeforgotModal.nativeElement.click();
      this.forgototpModal.nativeElement.click()
      
     
    }, 2000);
  }

  resetotp(){
   

    setTimeout(() => {
     this.message = ''
      this.closeforgototpModal.nativeElement.click();
      this.resetModal.nativeElement.click();
      
     
    }, 2000);

  }

  reseterror(){
    setTimeout(() => {
     this.message =''
      // this.forgotModal.nativeElement.click()
     
      
     
    }, 2000);
  
  }
  forgotemail(){
    if(!this.forgetemail.email){
      this.message = 'Email required!';
      setTimeout(() => {
        this.message = '';
        return;
      }, 2500)
     }
    else
   { 
    
    this.api.postdata('sendForgotOTP', this.forgetemail).subscribe((res: any) => {
    
     
      if(res.status==200){
        this.message = res.message
        this.reset()
      }else{
        if(res.message.email){
          this.message = res.message.email
        }else{
          this.message = res.message
        }
        
        this.reseterror()
      }

    })}

  }
  sendotp(){
    if(!this.forgetemail.otp){
      this.message = 'OTP required!';
      setTimeout(() => {
        this.message = '';
        return;
      }, 2500)
     }
    else
  {  
    console.log(this.forgetemail,"this.forgetemail")
    this.api.post('matchOTP', this.forgetemail).subscribe((res: any) => {
     console.log(res,"after forget")
      if(res.data.token){
        this.token = res.data.token
          localStorage.setItem('forgetotptoken',this.token)
         
      }

      if(res.status==200){
        this.message = res.message
        this.resetotp()
      }else{
        if(res.message.otp){
          this.message = res.message.otp
        }else{
          this.message = res.message
        }
        
        this.reseterror()
      }
     
     
     

    })}

  }
  resetpass(){
    setTimeout(() => {
      this.message=''
      this.closeresetModal.nativeElement.click();
      // this.router.navigate(['/profile']);
      this.loginnav.openLogin();
    }, 2000);

  }

  resetpassword(){
    if(this.forgetemail.password&&this.confirm)
{if(this.forgetemail.password===this.confirm){




  this.api.postotp('setPassword', this.forgetemail).subscribe((res: any) => {
 
    if(res.status==200){
      this.message = res.message
      this.resetpass()
      
    }else{
      this.reseterror()
    }
   

  })
}else{
  this.message = ' Password Dose not Match '
  setTimeout(() => {
    this.message=''
  }, 2000);
  
}}else{
  this.message = ' Password Is Required '
  setTimeout(() => {
    this.message = ''
  }, 2000);

}
   
   

  }

}
