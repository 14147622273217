import { Component,Output, OnInit ,ViewChild,ElementRef,EventEmitter} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NewsService } from '../news.service'
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { News } from '../news';
import { MemberService } from '../../member/member.service'; 
import { ApiService } from '../../api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginComponent } from '../../login/login.component';
import { EventService } from '../../event/event.service';
import { LoginService } from '../../login/login.service';
import { type } from 'os';


declare var jQuery:any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  name = 'ngx sharebuttons';
  closeResult: string;
  closeModal:string;
   
  page: number = 1;
  count: number = 0;
  tableSize: number = 5;
  tableSizes: any = [3, 6, 9, 12];

  news: News[] = []
  slidernews: News[] = []
  type='';
  
  slideConfig = { "slidesToShow": 1, "slidesToScroll": 1 };
  n:any;
  token: string;
  latest_news:any;

  constructor(public newsService: NewsService,public eventService:EventService,private modalService: NgbModal,public memberapi: MemberService,public mainapi: ApiService,private route: ActivatedRoute,private router: Router,public api: ApiService,) {
    if(localStorage.getItem('USERID')){


      this.token =  localStorage.getItem('USERID')
     }
     if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
     this.refresh();
     }
        
   }
  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  @ViewChild('openLoginModal')loginModal: ElementRef; 
  @Output() loginEvent = new EventEmitter<string>();
  ngOnInit(): void {
    console.log("News Index");
    this.refresh();
    this.route.queryParams.subscribe(params=>{
    if(params['type']){
     this.type=params['type']
    }
     this.getNews( this.type|| '');
     this.getEvent('','','','','','');
     this.get_latest_news();
    //console.log('qqqqqqqqqqqqqqqqqqqqqqq', params['type']);
   });
  } 
  getNews(type) {
    console.log('getting news');
    this.newsService.getAll(type).subscribe((res: News[]) => {
      this.news = res;
      if(type==''){
        this.slidernews=res;
      }

      console.log("NEWS..:::", this.news);
    })
  }  
  onclick(type) {
    this.getNews(type);
    this.type=type;
     console.log('international news' + type);
  }

  
  isShown: boolean = true ;
  isShow: boolean = false ;
  
bookmark(n){
  
  if(this.token){

 let news_id=this.type=='favorites'?n.news_id:n.id,
 bookmark=n.bookmark;
  this.isShown = ! this.isShown;
  this.isShow = ! this.isShow;
  if(bookmark>0){
    bookmark=0;
   
  }else{
    bookmark=1;
   
  }
  n.bookmark = bookmark;
  this.newsService.bookmark(news_id).subscribe((res: News[]) => {
    this.news = res;
    // this.router.navigate(['/news']);
    console.log("NEWS..:::", this.news);
    this.getNews(this.type);
  });
 
}

}

  

  getImage(news) {
    // return "http://localhost:8080/api/news-image/" + news.url;
    return news.url;
    // return environment.baseURL+"/news-image/" + news.url;
  }
  
  deleteNews(news_id){
    this.newsService.delete(news_id).subscribe((res:News[])=>{
      this.news = res;
      this.router.navigate(['/news']);
    });
  }
  
 
  myForm = new FormGroup({
    title: new FormControl(''),
    upload_image: new FormControl(''),
    content: new FormControl(''),
  });
getLocation(news_id){
  return location.href+'/'+news_id;
//  return  "https://stackoverflow.com"+'/'+news_id;
// return  "http://beta.soolegal.com"+'/'+news_id;
}

 


// share popup
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  // share popup ends

  
   // popup for delete icon
   triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
     
      this.getNews('');
      this.router.navigate(['/news']);
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReasons(res)}`;
     
    });
   
  }
  
  private getDismissReasons(reason: any): string {
    
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
     
    }
   
  }
click2()
{
   this.loginPrev.openLogin();
}
  click(){
    
    if(this.token){
      // this.router.navigate(['/news/add']);

    }else{
      this.loginPrev.openLogin();
      // this.router.navigate(['/login']);

    }
  }
  refresh() {
    if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
    localStorage.clear();
    window.location.reload();
    }
  }
  openLogin() {
    this.loginModal.nativeElement.click()
  }
  loginSubmit(event:any) {
   
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.getNews;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getNews;
  }
  addnews(){
    if(this.token){
      this.router.navigate(['/news/add']);
    }
  }
event:Event[]=[];
  getEvent(eventId,venue_details,start_date,end_date,event_category,event_type) {
    console.log('getting Event');
    console.log(start_date);
    console.log(end_date);
    this.eventService.getAll(eventId,venue_details,start_date,end_date,event_category,event_type,0).subscribe((res: any) => {
      this.event = res;
    });
    console.log('getting Event',this.event);
  }
  getImageEvent(event) {
    // return environment.baseURL+"news-image/" + news.url;
    // return "http://localhost:8080/api/event-image/" + event.banner;
    return event.img;
    
  }

  get_latest_news()
  {
   
    this.newsService.latest_news().subscribe((res: any) => {
    this.latest_news = res.messages.feed_list;
    console.log("latest_news_list.:::", this.latest_news);
  }) 
}








}
