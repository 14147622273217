import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MemberRoutingModule } from './member-routing.module';
import { MemberComponent } from './member.component';
import { FullCalendarModule } from '@fullcalendar/angular'; 
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import ListGridPlugin from '@fullcalendar/list'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CalendarComponent } from './calendar/calendar.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TabsModule} from 'ngx-tabset';
import { DatepickerModule } from 'ng2-datepicker';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { NotificationComponent } from './notification/notification.component';
import { FollowersComponent } from './followers/followers.component';
import { NgxEditorModule } from 'ngx-editor';
//import { AssociatesComponent } from './associates/associates.component';
//import { AssocInvitationComponent } from './assoc-invitation/assoc-invitation.component';
import { ConnectionListComponent } from './connection-list/connection-list.component';
import{RoarModule} from '../roar/roar.module';
import{NewsModule} from '../news/news.module';
import{NewsFormComponent} from './newsform/newsform.component';
import{RoarFormComponent} from './roarform/roarform.component';
import{EventFormComponent} from './eventform/eventform.component'
import { EventregModule } from '../eventreg/eventreg.module';
// import {TimeAgoPipe} from 'time-ago-pipe';


//import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
//  import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
FullCalendarModule.registerPlugins([ 
  interactionPlugin,
  dayGridPlugin,
  timeGridPlugin,
  ListGridPlugin,
  googleCalendarPlugin
]);


@NgModule({
  declarations: [MemberComponent,EventFormComponent, NewsFormComponent,RoarFormComponent,CalendarComponent, NotificationComponent, FollowersComponent, ConnectionListComponent, ],
  imports: [
    CommonModule,
    MemberRoutingModule,
    FullCalendarModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TabsModule,
    DatepickerModule,
    RoarModule,
    NewsModule,
    NgxEditorModule,
    EventregModule
   
  
   
    
   
    //  NgxMatTimepickerModule
  ],

  exports: [CalendarComponent,MemberComponent,],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
 
})
export class MemberModule { }
