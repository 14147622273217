import { Component, EventEmitter, OnInit, Output} from '@angular/core';
// import { ActivatedRoute } from '@angular/router';
// import { Notification, Observable } from 'rxjs';
import { MemberService } from '../member.service';
import { ApiService } from '../../api.service';
import { Router } from '@angular/router';
// import {NgToastService} from 'ng-angular-popup';
// import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  
 
 listdata:any;
 gray='#646464'
 black='black'
 

  constructor(private memberService:MemberService,private api:ApiService,public router: Router) { 
    
  }

 
   async ngOnInit(): Promise<void> {

    
     this.memberService.list().subscribe(async (res:any)=>{
       this.listdata = res.data;
     
      });
      }

      notificationupdate(data){
     
            const formData = new FormData();
        
           formData.append('id', data.id);
          //  type
          // url
        
         this.api.postdata('notificationRead', formData).subscribe((res: any) => {
         
           this.router.navigate(['/'+res.data.type+'/'+res.data.url+'/view']);
        
        
         })
          }
        
  
  
    }
  
  
  
  
  




  
  
  // if (result) {
    //   Swal.fire( 'success')
        // } else if (result.isDenied) {
          //   Swal.fire('Changes are not saved', '', 'info')
          // }
          
          // Swal.fire(`
          //   Login: ${result.value.login}
          //   Password: ${result.value.password}
          // `.trim())
          
          
          

          
          
          
          // Swal.fire({
  //   title: 'Login Form',
    
  //   html: `<input type="text" id="login" class="swal2-input" placeholder="Username">
  //     <input type="password" id="password" class="swal2-input" placeholder="Password">`,
      
  //   showCancelButton: true,
   
  //   showLoaderOnConfirm: true,
  //   preConfirm: () => {
    //     let timeout;
    
    //     function myFunction() {
      //       timeout = setTimeout(alertFunc, 3000);
  //     }
  
  //     function alertFunc() {
    //       alert("Hello!");
    //     }
    
    //     return fetch(`login`)
    //       .then(response => {
      
      //         if (response.ok) {
        //           throw new Error(response.statusText)
        //         }
  //         return response.json()
  //       })
  
  //       .catch(error => {
  //         Swal.showValidationMessage(
    //           `Request failed`
    //         )
    
    //       })
    //   },
    //   allowOutsideClick: () => !Swal.isLoading()
    // }).then((result) => {
      
    
      
      // await Swal.fire({
      //   title: 'Login Form',
      //   html: `<input type="text" id="login" class="swal2-input" placeholder="Username">
      //   <input type="password" id="password" class="swal2-input" placeholder="Password"> `,
      //   // footer: "<button (click)='withoutread()' class='btn btn-info'>Login</button>",
      //   confirmButtonText: 'Login',
      //   focusConfirm: false,
      //   preConfirm: () => {
         
      //     const login = Swal.getPopup().querySelector('#login')
      //     const password = Swal.getPopup().querySelector('#password')
      //     setTimeout(() => { alert('hello') 
      //     if (login || password)  {
              
      //     }
    
      //     return { login: login, password: password }
      //   }, 1000)
      //   }
        
      // }).then((result) => {
      //  
      // })
      
  // })