import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roar',
  templateUrl: './roar.component.html',
  styleUrls: ['./roar.component.css']
})
export class RoarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
