import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent, EventInput, CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventHoveringArg } from '@fullcalendar/angular';
import { Calendar } from '@fullcalendar/core';
import { CalendarService } from './calendar.service';
import { client } from '../../modals/client';
import { Appointment } from '../../modals/appoinment';
import { ToastrService } from 'ngx-toastr';
// Appointment
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  Events = [];
  calendarOptions: CalendarOptions;
  currentEvents: EventApi[] = [];
  date :any
  @ViewChild('opencalenderModal')myCalenderModal: ElementRef; 
  @ViewChild('calenderModal')calenderModal: ElementRef; 
  @ViewChild('calendarAppointment')calendarAppointment: ElementRef; 
  @ViewChild('closesetting')closesetting: ElementRef; 

  @ViewChild('addclient')addclient: ElementRef; 
  @ViewChild('closeclient')closeclient: ElementRef; 
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  client: any;
  appoinments: Appointment;
  clientlist: any;
  Appointmentlist: any;
  selectdate: any;
  editappointment = false;
  testapointment= false;
  needalawerlist: any;
  publiceventlist: any;
  
  // closeclient
  constructor(public calendarapi: CalendarService,private toast: ToastrService) { 
    this.date = new Date();
    this.client = new client();
    this.appoinments= new Appointment()
  }

  ngOnInit(): void {
    setTimeout(() => {
      let email= 'sgrishma735@gmail.com'
     
      let calendarApi = this.calendarComponent.getApi()
     
     
      this.calendarOptions = {
       
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        initialView: 'dayGridMonth',
      
        weekends: true,
        selectable: true,
        googleCalendarApiKey: 'AIzaSyBNkmzPJymSKYJBPWoZyOtBGPUpv6n2-XY',
        
       
        // events: {
        //   googleCalendarId: email,
          
        // },
        editable: false,
        locale: 'en',
        selectMirror: true,
     dayMaxEvents: 2,
         eventsSet: this.handleEvents.bind(this),
          eventClick: this.handleEventClick.bind(this),
        eventDidMount:this.handleEventsdidmount.bind(this),
        eventMouseEnter:this.handleEventmouseenet.bind(this),
        views: {
          timeGrid: {
            eventLimit: 4,
            dayMaxEvents:4 // adjust to 6 only for timeGridWeek/timeGridDay
          }
        },
        //  events: 'http://www.google.com/calendar/feeds/indian__en%40holiday.calendar.google.com/public/basic',
      
        eventSources: [
        
          {
            url: 'http://www.google.com/calendar/feeds/indian__en%40holiday.calendar.google.com/public/basic', // use the `url` property
           
          },
          {
            events: this.calendarapi.eventlist,
            // events: [],
            color: 'black',     // an option!
            textColor: 'yellow' // an option!
          }
        ],
        dateClick: this.onDateClick.bind(this),
        
        
        
       
      };
    
      calendarApi.render();
    }, 2500);
    this.getclientlist();
   
  }

  doSomethingOnTabSelect(event:any){

  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;

  }

  handleEventClick(clickInfo:EventClickArg) {
     
     
    // this.calenderModal.nativeElement.click()
    // if (confirm(`Are you sure you want to delete the event '${clickInfo}'`)) {
    //   clickInfo.event.remove();
    // }
  }

  handleEventsdidmount(event){
     
  }

  handleEventmouseenet(arg: EventHoveringArg){
     
    // event.el.outerText
  

  }
  onDateClick(res) {
    //  myCalenderModal
    this.selectdate = res.dateStr
    
     
      // this.getcalendardata(res.dateStr)
      // this.Appointments();
      this.getappointmentlist();
      this.getneedalawerlist();
      this.geteventlist();
     this.myCalenderModal.nativeElement.click()
    
    //  alert('Clicked on date : ' + res.dateStr)
  }
appoinment(){
  this.calendarAppointment.nativeElement.click()
}

addclients(){

  this.addclient.nativeElement.click()
}
postclient(){
  this.calendarapi.postmember('registerClient',this.client).subscribe((res2: any) => {

if(res2.error==false){
  this.toast.success('', res2.message, { timeOut: 3000 })
}else{
  this.toast.error('', 'Something Went Wrong', { timeOut: 3000 })
}
this.getclientlist();
this.closeclient.nativeElement.click()

  })

}
postappintment(){
 
  this.appoinments.start_time = this.selectdate+' '+this.appoinments.start_time
  this.appoinments.end_time = this.selectdate+' '+this.appoinments.end_time

  this.calendarapi.postmember('addAppointment',this.appoinments).subscribe((res2: any) => {

this.getappointmentlist()
this.calendarapi.getcalendar()
if(res2.error== false){
  this.toast.success('', res2.message, { timeOut: 3000 })
}else{
  this.toast.error('', 'Something Went Wrong', { timeOut: 3000 })
}

this.closesetting.nativeElement.click()

  })

}


getclientlist(){
  this.calendarapi.getdata('memberClient').subscribe((res2: any) => {
  
    this.clientlist = res2.data
  
  
  
 

   
 })

}
getappointmentlist(){
  let obj=
  {
    date:this.selectdate
  }
 
  this.calendarapi.postmember('appointmentList',obj).subscribe((res2: any) => {
  
    this.Appointmentlist = res2.data
   
    if(this.Appointmentlist.length==0){
      this.testapointment = true
    
    }else{
      this.testapointment = false
     
    }
  
  
  
 

   
 })

}

getneedalawerlist(){
  let obj=
  {
    date:this.selectdate
  }
  this.calendarapi.postmember('memberNeedALawyer',obj).subscribe((res2: any) => {
  
    this.needalawerlist = res2.data
    // if(this.Appointmentlist.length==0){
    //   this.testapointment = true
    // }
  
  
  
 

   
 })

}
geteventlist(){

  let obj=
  {
    date:this.selectdate
  }
  this.calendarapi.postmember('publicEvent',obj).subscribe((res2: any) => {
  
    this.publiceventlist = res2.data
    // if(this.Appointmentlist.length==0){
    //   this.testapointment = true
    // }
  
  
  
 

   
 })

}
openedit(data,i){
  this.editappointment = true
  this.appoinments= this.Appointmentlist[i]
 

  this.calendarAppointment.nativeElement.click()

}
updateappintment(){
  this.appoinments.start_time = this.selectdate+' '+this.appoinments.start_time
  this.appoinments.end_time = this.selectdate+' '+this.appoinments.end_time
 
  this.calendarapi.postmember('updateAppointment',this.appoinments).subscribe((res2: any) => {
  
    this.getappointmentlist()
    this.calendarapi.getcalendar()
    if(res2.error== false){
      this.toast.success('', res2.message, { timeOut: 3000 })
    }else{
      this.toast.error('', 'Something Went Wrong', { timeOut: 3000 })
    }
    
    this.closesetting.nativeElement.click()
    
      })

}
  // addclient

  // ngAfterViewInit(){
  //   var firstTabEl = document.querySelector('#myTab li:last-child a')
  //   var firstTab = new bootstrap.Tab(firstTabEl)
  //   firstTab.show()
  // }
}
