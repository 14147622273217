import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import jwt_decode from "jwt-decode";
import { Register } from '../modals/regsiter';
import { count } from '../modals/count';
import { FullCalendarComponent, EventInput, CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventHoveringArg } from '@fullcalendar/angular';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import { MemberService } from './member.service';
import{RoarService} from '../roar/roar.service'
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { retry } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EventregService } from '../eventreg/eventreg.service';


@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit,AfterViewInit {


  closeModal:string;
  closeResult: string;
  token: string;
  tokenID: any;
  userInfo: any;
  Events = [];
  calendarOptions: CalendarOptions;
  @ViewChild('opencalenderModal') myCalenderModal: ElementRef;
  @ViewChild('calenderModal') calenderModal: ElementRef;
  @ViewChild('tab02') tab02: ElementRef;
  // tab02

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  total: any;
  roarlist: any;
  description = ''
  slideConfigroar = { "slidesToShow": 4, "slidesToScroll": 4 };
  slideConfigevent = { "slidesToShow": 4, "slidesToScroll": 4 };

  slideConfigMemberEvent = {
    accessibility: true,
    'slidesToShow': 4,
    'slidesToScroll': 4,
    'autoplay': true,
    initialSlide: 0,
    'dots': false,
    'arrows': true,
    'infinite': true,
    'mobileFirst': false,
    'focusOnSelect': false,
    "autoplaySpeed": 3000,
    'respondTo': 'window',
    rows: 1,
    prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    'responsive': [{
      'breakpoint': 768,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'initialSlide': 1,
        arrows: false,
        dots: false,
        prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
        nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',

      }
    }]
  };

  slideConfigMemberRoar = {
    accessibility: true,
    'slidesToShow': 3,
    'slidesToScroll': 3,
    'autoplay': true,
    initialSlide: 0,
    'dots': false,
    'arrows': true,
    'infinite': true,
    'mobileFirst': false,
    'focusOnSelect': false,
    "autoplaySpeed": 3000,
    'respondTo': 'window',
    rows: 1,
    prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    'responsive': [{
      'breakpoint': 768,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'initialSlide': 1,
        arrows: false,
        dots: false,
        prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
        nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',

      }
    }]
  };

  slideConfigMemberAds = {
    accessibility: true,
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'autoplay': true,
    initialSlide: 0,
    'dots': false,
    'arrows': false,
    'infinite': true,
    'mobileFirst': false,
    'focusOnSelect': false,
    "autoplaySpeed": 3000,
    'respondTo': 'window',
    rows: 1,
    prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    'responsive': [{
      'breakpoint': 768,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'initialSlide': 1,
        arrows: false,
        dots: false,
        prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
        nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',

      }
    }]
  };

  category: any;
  event: any;
  count = false;

  calendardata: any;
  eventcalendar: any;
  roarlike: any;
  profiledata: any;


  constructor(public eventregService: EventregService,public roarservice:RoarService,private modalService: NgbModal, public memberapi: MemberService,private ngxService: NgxUiLoaderService, public mainapi: ApiService, private memberService:MemberService) {
    this.userInfo = new Register();
    this.total = new count();

    if (this.mainapi.loggedIn() == false && localStorage.getItem('USERID')) {
      this.refresh();
    }



  }
  //  opencalenderModal

  someMethod() {
    //  .getApi()

    // calendarApi.next();
  }

  ngOnInit(): void {


   

    this.gettotal();
    this.getroar();
    this.getevent();
    this.getprofile();




    this.someMethod()

    this.memberapi.roarlike.subscribe({
      next: (v) => {
       
        this.roarlike = v
      }
    });


  }

  refresh() {
    if (this.mainapi.loggedIn() == false && localStorage.getItem('USERID')) {
      localStorage.clear();
      window.location.reload();
    }

  }

  create() {
    this.roarlike = this.roarlike + 1
    
  }
  gettotal() {


    this.memberapi.getdata('dashboard').subscribe((res2: any) => {
      this.total = res2.dashboard
    
      this.memberapi.roarlike.next(this.total.roar_like);
     
    
      if (this.total) {
        this.count = true

      } else {
      }







    })

  }


  getevent() {


    this.memberapi.getdata('memberEvent').subscribe((res2: any) => {

      this.event = res2.profile






    })

  }


  getprofile(){
    
    this.memberapi.getdata('profile').subscribe((res2: any) => {
  
      this.profiledata = res2.data
     
      
      
 
      
    })
  
  }







  getroar() {
    this.memberapi.getdata('memberRoar').subscribe((res2: any) => {

      this.roarlist = res2.profile
     





    })

  }

  getsearch(data) {

    this.category = data

  }

  searchsubmit() {
    if (this.description && this.category) {
      window.location.href = 'rc/cat/' + this.category + '?q=' + this.description
    } else if (this.category) {
      window.location.href = 'rc/cat/' + this.category
    } else if (this.description) {
      window.location.href = 'search/resource-centre' + '?q=' + this.description
    }
  }


  slickInit(e) {
   
  }

  breakpoint(e) {
   
  }

  afterChange(e) {
   
  }

  beforeChange(e) {
    
  }
  ngAfterViewInit(){
    this.ngxService.stop();
  }






  triggerModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
     
      // this.getRoar('');
      // this.router.navigate(['/roar']);
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReasons(res)}`;
     
    });
   
  }
  
  private getDismissReasons(reason: any): string {
    
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
     
    }
   
  }


  open(content) {
   
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  } 

  ope(content) {
   
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    
  }
  
  private getDismissReaso(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  } 


}


