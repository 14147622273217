import { Injectable } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { ReCaptchaV3Service } from 'ngx-captcha';


@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  public url = environment.baseURL;
  siteKey:any;
  

  constructor(public http: HttpClient,private reCaptchaV3Service: ReCaptchaV3Service) {
    this.siteKey = "6LdBwdYdAAAAAGFtMiVk19kbescCUcJ5DwiRkgkd"
    // 6LdBwdYdAAAAAGFtMiVk19kbescCUcJ5DwiRkgkd
    // 6LcoBbodAAAAAMtQq6Tnu7u4UiknY8fGcxhbL7YX

    // this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
    //   console.log('This is your token: ', token);
    // }, {
    //     useGlobalDomain: false
    // });
    
   }


  postdata(urlT: any, data: any) {
    // console.log(JSON.stringify(data));
    const updateUrl = this.url + urlT;
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.post(updateUrl, data);
  }

  postotp(urlT: any, data: any) {
    const updateUrl = this.url + urlT;
    const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('otptoken')); // may be localStorage/sessionStorage
    const headers = { headers: header };
   
    return this.http.post(updateUrl, data,headers);
  }
  getdata(urlt: any) {
    const getUrl = this.url + urlt;
    return this.http.get(getUrl);
  }
  getDataByID(urlt: any, id: any) {
    const getUrl = this.url + urlt + '/' + id;
    return this.http.get(getUrl);
  }

 

 

  
}
