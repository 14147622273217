import { Injectable } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'


@Injectable({
  providedIn: 'root'
})
export class ForgetService {

  public url = environment.baseURL;

  constructor(public http: HttpClient) { }


  postdata(urlT: any, data: any) {
   
    const updateUrl = this.url + urlT;
    
    return this.http.post(updateUrl, data);
  }


  postotp(urlT: any, data: any) {
   
    const updateUrl = this.url + urlT;
    const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('forgetotptoken')); // may be localStorage/sessionStorage
    const headers = { headers: header };
   
    return this.http.post(updateUrl, data,headers);
  }

  post(urlT: any, data: any) {
   
    const updateUrl = this.url + urlT;
    // const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('forgetotptoken')); // may be localStorage/sessionStorage
    // const headers = { headers: header };
   
    return this.http.post(updateUrl, data);
  }

}
