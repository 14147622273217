import { Component, OnInit } from '@angular/core';
import { observable } from 'rxjs';
import { ApiResponse } from './api.responce';
// import { notification } from './notificatication.model';
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

listdata :any;
  constructor(private notificationService:NotificationService) { }

  ngOnInit(): void {
   this.notificationService.list().subscribe((res:any)=>{
     this.listdata = res.data ;
     console.log(this.listdata);
   });
  }

}
