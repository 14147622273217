import { Component, OnInit, Input, Output, EventEmitter,ElementRef,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EventService } from '../../event/event.service';
import { EventregService } from '../../eventreg/eventreg.service';
import { DatePipe } from '@angular/common';
import { ApiService } from '../../api.service';
import { MemberService } from '../../member/member.service'; 
import { LoginComponent } from '../../login/login.component';
@Component({
  selector: 'app-eventform',
  templateUrl: './eventform.component.html',
  //   styleUrls: ['./view.component.css']
})
export class EventFormComponent implements OnInit {  
  closeResult: string;
  userInfo: any;
  token:string;
 
  

  constructor(public eventService: EventService,public api: ApiService,public eventregService: EventregService,private route: ActivatedRoute, private router: Router,public memberapi: MemberService,public mainapi: ApiService,
    ) {}
     


    eventImage: any={};
existingEvent:any=null;



  ngOnInit(): void {
    var eventid = this.route.snapshot.paramMap.get('eventId');
    if (eventid) {
      // this.findEvent(eventid);
    }
  }

  myform = new FormGroup({
    title: new FormControl('',Validators.required),
    banner: new FormControl('',),
    venue_details: new FormControl('',Validators.required),
    description: new FormControl('',Validators.required),
    start_date: new FormControl('',Validators.required),
    end_date:new FormControl('',Validators.required),
    tags:new FormControl(''),
    event_category:new FormControl(''),
    amount:new FormControl(''),
    paidtype:new FormControl(''),
   
  });
  get f() {
    return this.myform.controls;

  }
  onFileChange(event) {
    this.eventImage = event.target.files[0];
  }
  registration(e) {
    console.log(e.target.value);
  }
  submitted=false;
  submit() {
   
    this.submitted = true;
    if (this.myform.valid) {
    console.log('hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii');
    
    const formData = new FormData();
    if (this.existingEvent?.id) {
      formData.append("id", this.existingEvent.id);
    }
    formData.append("banner", this.eventImage);
    formData.append('title', this.myform.get('title').value);
    formData.append("banner", this.myform.get('banner').value);
    formData.append('venue_details', this.myform.get('venue_details').value);
    formData.append('description', this.myform.get('description').value);
    formData.append('start_date', this.myform.get('start_date').value);
    formData.append('end_date', this.myform.get('end_date').value);
    formData.append('tags', this.myform.get('tags').value);
    formData.append('event_category', this.myform.get('event_category').value);
    formData.append('amount', this.myform.get('amount').value);
    formData.append('paidtype', this.myform.get('paidtype').value);

    this.eventService.create(formData).subscribe((res: any) => {
      // console.log("Event Created..:::", res.event);
      // formData.forEach((value,key) => {
      //     console.log(key+" "+value)
      //   });
     
      this.router.navigate(['event']);
    });
this.myform.reset();
  }
  
  }
  // findEvent(eventId) {
  //   let datepipe = new DatePipe('en-US');
  //   let datepipe1 = new DatePipe('en-US');
  //   this.eventService.find(eventId).subscribe((res: Event) => {
  //     this.existingEvent = res;
  //     console.log('jiiiiiiiiiiii',eventId)
  //     this.myform.patchValue({
  //       title: this.existingEvent.title,
  //       start_date: datepipe.transform(this.existingEvent.start_date*1000, 'yyyy-MM-dd'),
  //       end_date: datepipe1.transform(this.existingEvent.end_date*1000, 'yyyy-MM-dd'),
  //       venue_details: this.existingEvent.venue_details,
  //       description: this.existingEvent.description,
  //       tags: this.existingEvent.tags,
  //       event_category: this.existingEvent.event_category,
  //       paidtype:this.existingEvent.paidtype,
  //       amount:this.existingEvent.amount,
  //       banner: this.existingEvent.banner,
       
      
  //     });

  //   });
  // }
 
  
}
