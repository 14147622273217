import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { Event } from './event';

@Injectable({
  providedIn: 'root'
})
export class EventregisterService {

  private apiURL = environment.baseURL;
    
 
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private httpClient: HttpClient) { }

  // getAll(type: string): Observable<Event[]> {
  //   return this.httpClient.get<Event[]>(this.apiURL + '/event?type=' + type)
  //   .pipe(
  //     catchError(this.errorHandler)
  //   )
  // }
  getAll(id): Observable<Event[]> {
    return this.httpClient.get<Event[]>(this.apiURL + 'eventregister')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  create(formData): Observable<any> {
   
  
    return this.httpClient.post(this.apiURL + 'eventregister', formData)
    .pipe(
      catchError(this.errorHandler)
    )
  }  

  find(id: number): Observable<any> {

    return this.httpClient.get(this.apiURL + 'eventregister/' + id)

      .pipe(
        catchError(this.errorHandler)
      )
  }

  errorHandler(error:any) { 
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }

}
