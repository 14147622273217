<footer style="margin-top: 0px;">
    
<!-- new changes 06_08_22 by vignesh -->
   <div class="container">
        <div class="row">
            <div class="col">
                <h5 class="followtop">Get To Know Us</h5>
                <ul>
                    <li class="knowus"><a [routerLink]="['about']">About Us</a></li>
                    <li class="knowus"><a [routerLink]="['privacy']">Privacy Policy</a></li>
                    <li class="knowus"><a [routerLink]="['privacy']">T & C</a></li>
                    <li class="knowus"><a href="http://localhost:4200/home/contact">Contact Us</a> </li>
                </ul>
            </div>
            <div class="col">
                <h5 class="followtop">Make Money With Us</h5>
                <ul>
                    <li class="knowus"><a href="#">Sell Legal Drafts/Templates</a></li>
                    <li class="knowus"><a href="#">Sell Research Paper/Project/Dissertation</a></li> 
                    <li class="knowus"><a href="#">Became a SoOlegal Upgrade Member</a></li>
                    <li class="knowus"><a href="#">Earn Reward/Referral Points</a></li>
                    <li class="knowus"><a href="#">T&C for Transcation Services</a></li>
                </ul>
            </div>
            <div class="col">
                <h5 class="followtop">Let Us Help You</h5>
                <ul>
                    <li class="knowus"><a href="#">How It Works</a></li>
                    <li class="knowus"><a href="#">Post Your Legal Query</a></li> 
                    <li class="knowus"><a href="#">Legal Services</a></li>
                    <li class="knowus"><a href="#">Find a Lawyer</a></li>
                    <li class="knowus"><a href="#">FeedBack</a></li>
                    <li class="knowus"><a href="#">Download SoOlegal App</a></li>
                </ul>
            </div>
            
            <div class="col">
                <div class="foo-logo">
                    <img src="assets/images/one.png" class="img-fluid">
                </div>
                <h5 class="followtop" style="margin-top: 98px;">Follow Us</h5>
                <ul class="listing-item">
                    <li class="knowus">
                        <a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f" style='font-size:21px'></i></a>
                    </li>
                    <li class="knowus">
                        <a href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram" style='font-size:21px'></i></a>
                    </li>
                    <li class="knowus">
                        <a href="https://twitter.com/" target="_blank"><i class="fab fa-twitter" style='font-size:21px'></i></a>
                    </li>
                   
                    <li class="knowus">
                        <a href="https://in.linkedin.com/" target="_blank"><i class="fab fa-linkedin-in" style='font-size:21px'></i></a>
                    </li>
                    
                </ul>
              
            </div>
        </div>
        <hr class="linetag">
            <span class="copyright">
                <b>&copy; SoOLegal 2022. All Rights Reserved</b>
                <img src="../../assets/images/payment-gateway-icon.png" alt="" class="footercards" />
            </span>
        <hr class="linetag">
   </div>
   

    <div class="container">
        <div class="content-wrapper">
            <p class="disclaimer">
                <b>Disclaimer:</b> SoOLegal is not allowed to advertise and solicit work as per the rules and regulations of Bar Council of India. So it expressly disclaims any kind of warranty whether implied or expressed.
                Your use of SoOLegal service is completely at your own risk. Readers and Subscribers should seek proper advice from an expert before acting on the information mentioned herein. The content on this web 
            </p>
            
        </div>
    </div>

    <app-about></app-about>
    <app-privacy></app-privacy>
</footer>