<h4 style="color: 	rgb(128,0,128); text-align:center; font-size: 28px; ">Followers</h4>
<hr>
<div class="container">
<div class="row">
    <div class="col-lg-12">
      <table class="table table-bordered text-center">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr  *ngFor="let getlist of followerlist" > 
            <td>
              <div class="img-box">
                <!-- profile -->
                <a href="javascript:void(0);" [routerLink]="['/'+getlist.username]">
                  <img src="{{getlist.profilepicture}}" style="width:60px; height: 60px; border-radius:50%">
                </a>
                
              </div>
            </td>
            <td>{{getlist.name}}</td>
            <td>{{getlist.email}}</td>
            <td>
                <button (click)="unfollow(getlist.member_id)">
               unfollow
            </button>
          </tr>
          
        </tbody>
      </table>
    </div>
  </div>
</div>
