<main role="main">
    

    <seciton class="top-banner">
        <div class="container">
            <div class="row" style="margin-top: 49px;">
                <div class="col-md-9 col-sm-6">
                    <div class="component-box-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>Knowledge is Power. Knowledge Shared is Power Multiplied!</h4>
                                <div class="user-info-box-inner no-scroll eventSlider">
                                    <div class="text-left">
                                        <div class="m-0 p-0 text-left">
                                            <p>
                                                SoOLEGAL offers you an
                                                extraordinary opportunity to enhance your reputation and
                                                reach by hosting an online event. Perhaps even earn some
                                                money in the bargain!
                                            </p>


                                            <!-- <a  class="btn btn-primary rounded btn-lg right"
                                                data-target="#findLawer" (click)="click()">Add Event</a> -->
                                            <a  class="btn btn-primary rounded btn-lg right" 
                                                 (click)="triggerModal(modalData)" (click)="click()" >Add Event</a>
                                        </div>
                                    </div>

                                    <div class="text-left">
                                        <div class="m-0 p-0 text-left">
                                            <p>
                                                Want to host or organize an event? No time for figuring out the
                                                technology or the logistics? SoOLEGAL’s proprietary tool can help.
                                                From promotions and handling registrations, to increasing attendee
                                                engagement and
                                                post event analysis, we have it all!
                                            </p>

                                            <p>
                                                Focus on your Content. Leave the Rest to Us.
                                            </p>

                                            <a  class="btn btn-primary rounded btn-lg right" 
                                            (click)="triggerModal(modalData)" (click)="click()">Add Event</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="component-box full-gray">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <img src="../../assets/images/post-requirmentsss.jpg" class="img-fluid" />
                            </div>
                        </div>

                        <!-- <div class="user-info-box-main">
                            <div class="user-info-box">
                                <img src="../../assets/images/post-requirmentsss.jpg" class="img-fluid" />
                            </div>
                        </div> -->

                        <!-- <div class="user-info-box-main">
                            <div class="user-info-box">
                                <img src="../../assets/images/post-requirmentsss.jpg" class="img-fluid" />
                            </div>
                        </div> -->

                        <!-- <div class="user-info-box-main">
                            <div class="user-info-box">
                                <img src="../../assets/images/post-requirmentsss.jpg" class="img-fluid" />
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </seciton>

    <section class="filter">
        <div class="container">
            <div class="h-20"></div>

            <div class="row">
                <div class="col-md-9 col-sm-6">
                    <h4>Upcoming Events</h4>
                    <form [formGroup]="myForm" (ngSubmit)="sort()">
                        <div class="filter-tags flex-center" style="margin-top: 20px;">

                            <div class="pretty-setting">
                                <select id="option-size" formControlName="select_city" class="custom-control">
                                    <option value="Select City" >Select City</option>
                                    <option *ngFor="let s of sorting_venuedetails" value="{{s.event_venue_details}}" selected="">
                                        {{s.event_venue_details}} </option>


                                </select>
                                <span class="text"><span class="label"></span><span class="value"
                                        formControlName="select_city">Select
                                        City</span></span>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                                    fill="#ccc" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>

                            <div class="pretty-setting">
                                <select id="option-size" formControlName="select_month" class="custom-control">
                                    <option value="Select Month" >Select Month</option>
                                    <option *ngFor="let m of months " value="{{m}}" selected="">{{m}}</option>


                                </select>
                                <span class="text"><span class="label"></span><span class="value"
                                        formControlName="select_month">Select
                                        Month</span></span>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                                    fill="#ccc" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>


                            <div class="pretty-setting">
                                <select id="option-size" formControlName="event_category" class="custom-control">
                                    <option value="Event Category">Event Category</option>
                                    <option *ngFor="let c of sorting_eventcategory;" value="{{c.event_category}}"
                                        selected=""> {{c.event_category}} </option>

                                </select>
                                <span class="text"><span class="label"></span><span class="value">Event
                                        Category</span></span>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                                    fill="#ccc" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>


                            <div class="pretty-setting">
                                <select id="option-size" formControlName="event_type" 
                                    class="custom-control">
                                    <option value="Event Type">Event Type</option>
                                    <option value="Free">Free Event</option>
                                    <option value="Paid">Paid Event</option>


                                </select>
                                <span class="text"><span class="label"></span><span class="value">Event Type</span></span>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                                    fill="#ccc" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>
                           
                            <!-- <div class="pretty-setting">
                                <select id="option-size" formControlName="select_year" ([ngModel])="selectedYear"
                                    class="custom-control">
                                  
                                    <option *ngFor="let y of years" [value]="y">{{ y }}</option>

                                </select>
                                <span class="text"><span class="label"></span><span class="value">Select
                                        Year</span></span>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                                    fill="#ccc" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div> -->


                            <a (click)="sort()" style="color: white !important;" class="btn btn-primary btn-filter flex-center text-center">Sort</a>

                        </div>
                    </form>
<!-- <pre>{{event | json}}</pre> -->
                    <div class="events-details" *ngFor="let e of event
                        | paginate
                        : {
                            itemsPerPage: tableSize,
                            currentPage: page,
                            totalItems: count
                          };
                    let i = index
                  ">
                        <article class="row">
                            <div class="col-md-5">
                                <div class="wrapper">
                                    <h3 class="title">{{e.event_title}}</h3>
                                    <span > <a title="Edit"  *ngIf="memberapi.userInfoapi?.member_id==e.event_author_id"
                                            class=" btn-xs " [attr.href]="'/event/' + e.id +'/edit'"><i
                                                class="fas fa-edit"></i></a></span>
                                    <a title="delete" *ngIf="memberapi.userInfoapi?.member_id==e.event_author_id"
                                        (click)="deleteEvent(e.id)" (click)="triggerModal(modal)" class="btn-xs "><i
                                            class="fas fa-remove"></i>
                                        <ng-template #modal let-modal>

                                            <div class="modal-body">
                                                <p><strong>Are you sure you want to delete </strong></p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-danger" (click)="deleteEvent(e.id)"
                                                    (click)="modal.close('Save click')">delete</button>
                                            </div>
                                        </ng-template>
                                    </a>
                                    <p [innerHTML]="e.description" class="h-2px" ngx-clamp
                                        [ngxClampOptions]="{ clamp: 2}"></p>
                                    <!-- <p class="small link">{{e.event_tags}}</p> -->
                                </div>
                            </div>
                            <div class="col col-md-4 offset-md-1">
                                <div class="wrapper"></div>
                                <h3 class="title">{{e.event_start_date*1000|date:'yyyy/MM/dd'}} to
                                    {{e.event_end_date*1000|date:'yyyy/MM/dd'}}</h3>
                                <p class="small link">{{e.event_category}}</p>
                            </div>
                          
                            <div class="box-wrapper">                               
                                <a class="btn btn-primary" style="float: right;margin-top: -20px;" [attr.href]="'/event/' + e.id +'/add'">Register Now</a>
                            </div>
                            <div class="col col-md-2" style="margin-top: -81px;margin-left: 45em;">
                                <div class="wrapper"></div>
                                <h3 class="btn btn-primary"><a [attr.href]="'/event/' + e.id+'/view'">View More</a>
                                </h3>
                            </div>
                        </article>

                      
                    </div>
                    <br><br>
                    <div class="d-flex justify-content-center">
                        <pagination-controls previousLabel="Prev" nextLabel="Next"
                            (pageChange)="onTableDataChange($event)">
                        </pagination-controls>
                    </div>
                </div>

                <div class="col-sm-6 col-md-3" >
                    <h4>View Past Events/videos</h4>
                    <div class="top-arrow">
                        <!-- <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-short"
                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
                        </svg> -->
                    </div>

                    <div class="box-slider-1" id="sidebar" *ngFor="let e of event" style="margin-top: 15px;">
                        <div class="component-box-1">
                            <div class="user-info-box-main">
                                <div class="user-info-box">
                                    <h4>Online Webinar (August 2020)</h4>
                                    <div class="user-info-box-inner p-0 no-scroll min-height">
                                        <div class="user-info-box">
                                            <div class="bg-white">
                                                <img src="../../assets/images/pasted-image.jpg" class="img-fluid" />
                                            </div>
                                            <div class="box-wrapper">                                               
                                                <a class="btn btn-primary" [attr.href]="'/event/' + e.id +'/add'">Register Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                      
                    </div>
                    <div class="bottom-arrow">
                        <!-- <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-down-short"
                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
                        </svg> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="h-25 d-inline-block"></div>
    <section class="title-bg-2 my-4 ipad-hide phone-hide">
        <div class="container">
            <div class="row">
                <div class="col-md-2 #90278E-bg-new order-md-12">
                    <div class="triangle-bottomleft"></div>
                    <div class="icon-box"></div>
                </div>
                <div class="col-md-10 white-bg-new">
                    <div class="triangle-topleft"></div>
                    <span class="gray-bg">Go Premium</span>
                    <h3>SoOLEGAL is recommended by our valued
                        members and users</h3>
                </div>
            </div>
        </div>
    </section>

    <section class="profile-slider">
        <div class="container">
            <div class="events owl-carousel owl-theme">

<!-- 

                <div *ngFor="let e of event">
                    <div class="component-box-1" style="width: 100%; display: inline-block;">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>Online Webinar (August 2020)</h4>
                                <div class="user-info-box-inner p-0 no-scroll min-height">
                                    <div class="user-info-box">
                                        <div class="bg-white">
                                            <img src="images/pasted-image.jpg" class="img-fluid">
                                        </div>
                                        <div class="box-wrapper">
                                            <p>
                                                {{e.title}}
                                            </p>
                                            <a class="btn btn-primary" [attr.href]="'/event/' + e.id +'/add'" tabindex="0">Register Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->




            </div>
        </div>
    </section>
    <section class="partners-main-box my-3">
        <div class="container-fluid p-0">
            <div class="bg-gray-new shadow-none">
                <div class="row justify-content-between align-items-center">
                    <div class="col-md-12 col-lg-5 my-2 logo-box">
                        <img src="../../assets/images/logo.png" style="margin-left: 28px;" alt="" class="im" />
                        <h4 class="title-1" style="margin-right: 40px;margin-top: 16px;">Partner Services</h4>
                    </div>
                    <div class="col-md-12 col-lg-7 my-2">
                        <div class="partners owl-carousel owl-theme owl-loaded owl-drag">
                            <div class="owl-stage-outer">
                                <div class="owl-stage"
                                    style="transform: translate3d(-537px, 0px, 0px); transition: all 0.25s ease 0s; width: 1255px;">
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="owl-item active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-nav disabled">
                                <button type="button" role="presentation" class="owl-prev"><span
                                        aria-label="Previous">‹</span></button><button type="button" role="presentation"
                                    class="owl-next"><span aria-label="Next">›</span></button>
                            </div>
                            <div class="owl-dots disabled"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<!-- PopUp Box for event details -->
<!-- <div (click)="click()" > -->
   
    <ng-template #modalData let-modal >
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Event</h4>
            <button type="button" class="close" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <!-- <app-eventregistration ></app-eventregistration> -->

            <div class="modal-body popup-body">
                <!-- <h2>Add Events</h2> -->
                <form [formGroup]="myform" (ngSubmit)="submit()">
                    <!-- <pre>{{existingEvent | json}}</pre> -->
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="title">Title<span *ngIf="submitted && f['title'].errors?.['required']" style="color:red">  (Required)</span></label>
                            <input type="text" class="form-control" formControlName="title"
                                style="background-color: white;" id="inputEmail4" placeholder="">
                                <input type="text" class="form-control" formControlName="id"
                                style="background-color: white;" id="inputEmail4" placeholder="" hidden="true">
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <label for="image">Select images<span *ngIf="submitted && f['banner'].errors?.['required']" style="color:red">  (Required)</span></label>
                            <div class="form-group"><input type="file" name="url" formControlName="banner" id="url"
                                    (change)="onFileChange($event)" class="filestyle" value="" data-icon="false"
                                    tabindex="-1" style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
                                <div class="bootstrap-filestyle input-group"><input type="text"
                                        class="form-control no-padd " placeholder="" disabled=""
                                        style="background-color: white;"
                                        [value]="eventImage.name?eventImage.name:(existingEvent?existingEvent.event_banner:'')">
                                    <span class="group-span-filestyle input-group-btn" tabindex="0"><label for="url"
                                            class="btn btn-default "><span
                                                class="buttonText">Choose</span></label></span></div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">

                        <div class="form-group col-md-3">
                            <label for="publishdate">Start Date<span *ngIf="submitted && f['start_date'].errors?.['required']" style="color:red">  (Required)</span></label><input type="date" name="publishdate" value=""
                                style="background-color: white;" formControlName="publish_date" id="publishdate"
                                formControlName="start_date" placeholder="Start_date"
                                class="form-control timepicker hasDatepicker">
                        </div>
                        <div class="form-group col-md-3">
                            <label for="publishdate">End Date <span *ngIf="submitted && f['end_date'].errors?.['required']" style="color:red">  (Required)</span></label><input type="date" name="publishdate" value=""
                                style="background-color: white;" formControlName="publish_date" id="publishdate"
                                formControlName="end_date" placeholder="end_date"
                                class="form-control timepicker hasDatepicker">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="VenueDetails">Venue Details <span *ngIf="submitted && f['venue_details'].errors?.['required']" style="color:red">  (Required)</span></label>
                            <input type="text" class="form-control" formControlName="venue_details"
                                style="background-color: white;" id="inputEmail4" placeholder="">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="tags">Tags<span *ngIf="submitted && f['tags'].errors?.['required']" style="color:red">  (Required)</span></label>
                            <input type="text" class="form-control" formControlName="tags"
                                style="background-color: white;" id="inputtags" placeholder="">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="eventCategory">Event Category<span *ngIf="submitted && f['event_category'].errors?.['required']" style="color:red">  (Required)</span></label>
                            <!-- <input type="text" class="form-control value" formControlName="event_category" id="inputcategory"
                                style="background-color: white;" placeholder=""> -->
                                <select id="option-size" formControlName="event_category" class="form-control" style="background-color:white">
                                    <option value="Seminar" >Seminar</option>
                                    <option  value="Workshop"> Workshop </option>
                                    <option  value="MaterClass"> MaterClass </option>
                                    <option  value="courses"> courses </option>


                                       
                                </select>
                                <!-- <div class="pretty-setting"> -->
                                    <!-- <select id="option-size" formControlName="select_city" class="custom-control">
                                        <option value="Select City" >Select City</option>
                                        <option  value="" selected="">
                                            event </option>
    
    
                                    </select> -->
                                    <!-- <span class="text"><span class="label"></span><span class="value"
                                            formControlName="select_city">Select
                                            City</span></span> -->
                                    <!-- <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                                        fill="#ccc" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg> -->
                                </div>
                        </div>
                    <!-- </div> -->

                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="note">Content<span *ngIf="submitted && f['description'].errors?.['required']" style="color:red">  (Required)</span></label>
                            <textarea name="description" formControlName="description" id="your_summernote" class="form-control bg-white"
                                rows="4"></textarea>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <label><span *ngIf="submitted && f['paidtype'].errors?.['required']" style="color:red">  (Required)</span>
                                <input type="radio" value="0" name="event-registration" class="radio"formControlName="paidtype"
                                (change)="registration($event)" />
                                <span class="radio-style d-inline-block"></span>
                                <span>Free Event Registration </span>
                            </label>
                        </div>

                        <div class="col-md-4">
                            <label>
                                <input type="radio" value="1" name="event-registration" class="radio"formControlName="paidtype"
                                (change)="registration($event)" />
                                <span class="radio-style d-inline-block"></span>
                                <span>Paid Event Registration </span>
                            </label>
                        </div>

                        <div class="col-md-4">
                            <input type="text" formControlName="amount" class="form-control m-0 rounded-0 bg-white" id="exampleInputEmail1"
                                aria-describedby="phoneHelp" placeholder="Event Registration Amount" />
                        </div>
                    </div>


                    <div class="mt-4">
                        <button type="submit"   class="d-block btn btn-primary mb-0 mx-auto">Submit</button>
                    </div>
                </form>

            </div>
        <!-- </div>
    </div>
</div> -->

<app-login (loginEvent)="loginSubmit($event)"></app-login>


<!-- PopUp Box for event details -->

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger"
               
                (click)="modal.close('Save click')">Close</button>
        </div>
    </ng-template>

<!-- </div> -->

<app-login (loginEvent)="loginSubmit($event)"></app-login>