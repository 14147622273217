import { Component,Output,EventEmitter,ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder,FormGroup,FormControl,Validators,} from '@angular/forms';
import { HomeService } from './home.service';
import { Findlawer } from '../modals/findlawer';
import { ToastrService } from 'ngx-toastr';
import { MemberService } from '../member/member.service';
import { ApiService } from '../api.service';
import { LoginComponent } from '../login/login.component';
import { LoginService } from '../login/login.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/shared/canonical.service';
// import { Validators } from 'ngx-editor';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  primemember: any;
  token: string;

  title='HOME | Soolegal'

  slide: string;
  // Showmore = true;
  // currentId:any = '';

  

 
  
  // @Input()showToggleButton:boolean = true;
  
  
  // toggle(index:any,event:any) {
    
  //   let text = event.target.text;
  //   this.slide=text;
  //   console.log(index)
  //   this.Showmore = !this.Showmore;
  //   console.log(text);
  
  //   }
  
  
//    if(this.Showmore == false){
//     this.currentId = this.text;
//     this.Showmore = false;
//     return;
//   } 
//   else( this.currentId = index)
//   {
//    this.currentId.Showmore
//   } 
    // return this.slide;
   
    // event.target.disabled = true;

  //  isReadMore:boolean = true;

  // textid=`{{slide.title}}`


  
  slideConfigHomrUser = {
    accessibility: true,
    'slidesToShow': 4,
    'slidesToScroll': 4,
    'autoplay': true,
    initialSlide: 0,
    'dots': false,
    'arrows': true,
    'infinite': true,
    'mobileFirst': false,
    'focusOnSelect': false,
    "autoplaySpeed": 3000,
    'respondTo': 'window',
    rows: 1,
    prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    'responsive': [{
      'breakpoint': 768,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'initialSlide': 1,
        arrows: false,
        dots: false,
        prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
        nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',

      }
    }]
  };

  slideConfigHomrRoar = {
    accessibility: true,
    'slidesToShow': 3,
    'slidesToScroll': 3,
    'autoplay': false,
    initialSlide: 0,
    'dots': false,
    'arrows': true,
    'infinite': true,
    'mobileFirst': false,
    'focusOnSelect': false,
    "autoplaySpeed": 3000,
    'respondTo': 'window',
    rows: 1,
    prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    'responsive': [{
      'breakpoint': 768,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'initialSlide': 1,
        arrows: false,
        dots: false,
        prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
        nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',

      }
    }]
  };

  slideConfigHomrCase = {
    accessibility: true,
    'slidesToShow': 3,
    'slidesToScroll': 3,
    'autoplay': true,
    initialSlide: 0,
    'dots': false,
    'arrows': true,
    'infinite': true,
    'mobileFirst': false,
    'focusOnSelect': false,
    "autoplaySpeed": 3000,
    'respondTo': 'window',
    rows: 1,
    prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    'responsive': [{
      'breakpoint': 768,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'initialSlide': 1,
        arrows: false,
        dots: false,
        prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
        nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',

      }
    }]
  };

  slideConfigHomeAds = {
    accessibility: true,
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'autoplay': true,
    initialSlide: 0,
    'dots': false,
    'arrows': true,
    'infinite': true,
    'mobileFirst': false,
    'focusOnSelect': false,
    "autoplaySpeed": 3000,
    'respondTo': 'window',
    rows: 1,
    prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    'responsive': [{
      'breakpoint': 768,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'initialSlide': 1,
        arrows: false,
        dots: false,
        prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
        nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',

      }
    }]
  };

  slideConfigHomeFeedback = {
    accessibility: true,
    'slidesToShow': 4,
    'slidesToScroll': 4,
    'autoplay': true,
    initialSlide: 0,
    'dots': false,
    'arrows': true,
    'infinite': true,
    'mobileFirst': false,
    'focusOnSelect': false,
    "autoplaySpeed": 3000,
    'respondTo': 'window',
    rows: 1,
    prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
    'responsive': [{
      'breakpoint': 768,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'initialSlide': 1,
        arrows: false,
        dots: false,
        prevArrow: '<a href="javascript:void(0);" class="arrow ml slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',
        nextArrow: '<a href="javascript:void(0);" class="arrow mr r-180 slider-nav-zindex"><img src="../../assets/images/icons/arrow_left.png"></a>',

      }
    }]
  };
  
  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  @ViewChild('openLoginModal')loginModal: ElementRef; 
  @Output() loginEvent = new EventEmitter<string>();

  @ViewChild('ngAutoCompleteApi') ngAutocompleteApi: ElementRef;
  @ViewChild('auto') auto;

  findlawyer: Findlawer;
  textvalid = false
  @ViewChild('openfindlawerModal') openfindlawerModal: ElementRef;
  @ViewChild('opencasehelpModal') opencasehelpModal: ElementRef;
  @ViewChild('closefindlawer') closefindlawer: ElementRef;
  @ViewChild('closewhatyourcase') closewhatyourcase: ElementRef;
  @ViewChild('specialization') specialization: ElementRef;
  // specialization
  message: string;
  category: any;
  text: any;
  roarlist: any;
  textroardescription = false;
  findAlayer = true;
  readMore = false;
  area: any;
  textselect = ''
  datacase = ''
  location: any;
  prime = ''
  lflhstatus = 'Top Lawyers';
  locationmember = ''
  roarlike: any;
  keyword = 'name';
  feedbacklist: any;
  testimoniallist: any;
  subject:any;

  constructor(public homeapi: HomeService, public memberapi: MemberService, private toast: ToastrService,public api: ApiService,private titleService: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService) {
    if(localStorage.getItem('USERID')){

      this.token =  localStorage.getItem('USERID')
     }
     if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
     this.refresh();
     }

    this.findlawyer = new Findlawer();

    

  }

  ngOnInit(): void {
    this.getprime();
    this.getroar();
    this.getarea();
    this.getfeedback();
    this.getTestimonial();
    this.memberapi.roarlike.subscribe({
      next: (v) => {
     

        this.roarlike = v
      }
    });

    this.canonicalService.setCanonicalURL();

    this.titleService.setTitle(this.title);

    let location = window.location.href
    this.metaTagService.addTags([
      {name: 'description', content: 'SoOLEGAL invites Lawyer & Law Firms to Register. Find Best Lawyers Online, Get Free Legal Advice &  Socially Optimized Legal Directory' },
      {name: 'robots', content: 'index, follow'},
      {name: 'revisit-after', content: '1 days'},
      {name: 'copyright', content: 'Copyright - All Rights Reserved 2017 SoOLegal'},
      {name: 'author', content: 'SoOLEGAL'},
      {name: 'zipcode', content: '110074'},
      {name: 'city', content: 'New Delhi'},
      {name: 'state', content: 'Delhi'},
      {name: 'country', content: 'India'},
      {property: 'og:locale', content: 'en_US'},
      {property: 'og:type', content: 'website'},
      {property: 'og:title', content: this.title},
      {property: 'og:url', content:  location},
      {property: 'og:image', content: 'https://beta.soolegal.com/assets/images/logo.png'},
      {property: 'og:description', content: 'SoOLEGAL invites Lawyer & Law Firms to Register. Find Best Lawyers Online, Get Free Legal Advice &  Socially Optimized Legal Directory'},
      {property: 'og:site_name', content: 'SoOLEGAL'},
      {property: 'article:publisher', content: 'https://www.facebook.com/SOciallyOptimizedLEGAL/'},
      {property: 'fb:app_id', content: ''},
      {name: 'DC.title', content: 'SoOLEGAL'},
      {name: 'geo.region', content: 'IN-DL'},
      {name: 'geo.placename', content: 'New Delhi'},
      {name: 'geo.position', content: ''},
      {name: 'ICBM', content: ''}
    ]); 
  }

  getprime() {

    let obj = {
      practice_area: this.prime,
      location: this.locationmember
    }
 
    this.homeapi.postdata('legalHelpSearch', obj).subscribe((res2: any) => {

      this.primemember = res2.profile
      if(this.prime || this.locationmember){
        this.lflhstatus = 'Top Matched';
      }
    
    })
  }

  getarea() {
    this.homeapi.getdata('practicearea').subscribe((res2: any) => {
      this.area = res2.data
    })
  }

  searchCleared(type:any) {
    if(type=="prime"){
      this.prime='';
      this.getprime();
    }else if(type=="location"){
      this.locationmember ='';
      this.getprime();
    }
 
    // this.homeapi.location = [];
  }

  closedEventApi() {
  
  }

  getprectise(data) {
  
    this.prime = data
    this.getprime();

  }

  ontext(event: any) {
    if (event) {
      this.textvalid = true;
    }else{
      this.textvalid = false;
    }
  }

  opensearch() {
    if (this.findlawyer.description) {
      window.location.href = 'search/people' + '?q=' + this.findlawyer.description
    } else {
      this.toast.error('Query  Required')
    }

  }
  postfindlawer() {
    if (this.findlawyer.is_share_info) {
      this.findlawyer.is_share_info = 1
    } else {
      this.findlawyer.is_share_info = 0
    }

    if (!this.findlawyer.email) {
      this.toast.error('Email Required')
    } else if (!this.findlawyer.mobile) {
      this.toast.error('Mobile Required')
    }
    else {
      if (this.category) {
        this.findlawyer.category = this.category
        this.findlawyer.description = this.text
      }

      if(this.subject == '')
      {
        this.subject = 'Need A Lawyer';
      }

      this.findlawyer.subject = this.subject;

      this.homeapi.postdata('findALawyer', this.findlawyer).subscribe((res2: any) => {
        // status
        if (res2.status) {
          this.message = " Your Query is submited Connect you shortly"
          setTimeout(() => {
            this.findlawyer = new Findlawer();
            this.closefindlawer.nativeElement.click()
            this.message = ''
          }, 2000);
        } else {
          this.message = " Some Thing went Wrong"
        }

      })
    }
  }

  postwhatyourcase() {
    if (this.findlawyer.is_share_info) {
      this.findlawyer.is_share_info = 1
    } else {
      this.findlawyer.is_share_info = 0
    }

    if (!this.findlawyer.email) {
      this.toast.error('Email Required')
    } else if (!this.findlawyer.mobile) {
      this.toast.error('Mobile Required')
    }
    else {
      this.findlawyer.subject = "What Is Your Case";
      this.homeapi.postdata('findALawyer', this.findlawyer).subscribe((res2: any) => {
        // status
        if (res2.status) {
          this.message = " Your Query is submited Connect you shortly"
          setTimeout(() => {
            this.findlawyer = new Findlawer();
            this.closewhatyourcase.nativeElement.click()
            this.message = ''
          }, 2000);
        } else {
          this.message = " Some Thing went Wrong"
        }

      })
    }
  }
  getroar() {
    this.homeapi.getdata('homeRoarList').subscribe((res2: any) => {
      this.roarlist = res2.roar
      console.log(this.roarlist,"this.roarlist")
     
    
    })
  }

  getfeedback() {
    this.homeapi.getdata('feedbackList').subscribe((res2: any) => {
      this.feedbacklist = res2.data
      // console.log(this.feedbacklist, "this.feedbacklist")
    })
  }

  getTestimonial()
  {
      this.homeapi.getdata('testimonial').subscribe((res2:any) => {
        this.testimoniallist = res2.data;
     
      })
  }

  textroar() {
    this.textroardescription = true

  }
  closePanel(e): void {
 
    // e.stopPropagation();
    this.auto.close();
  }

  premium(data, text) {
    this.category = data
    this.text = text
    this.subject = text
    this.openfindlawerModal.nativeElement.click()
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {

  }

  beforeChange(e) {

  }
  selectcase(data) {
    // console.log(data,"check event ")
    this.textvalid = true
    this.datacase = data
    this.findlawyer.description = this.text
  }

  selecttext(data) {
  
    this.textselect = data
    this.findlawyer.description = this.text
  }

  submitcase() {
    if(this.token){
      if (this.datacase && (this.textselect == '' || this.textselect == 'I just need information' || this.textselect == 'I need a checklist' || this.textselect == 'I need a readymade template')){

        window.location.href = 'rc/cat/' + this.datacase;

      }else if ((this.datacase && this.textselect == 'I need an opinion') || (this.datacase && this.textselect == 'I need a lawyer, fast!')) {

        this.opencasehelpModal.nativeElement.click();
        this.findlawyer.description=this.datacase;
        this.findlawyer.category=(this.textselect == 'I need an opinion')? "I need an opinion" : "I need a lawyer, fast!";
        // window.location.href = 'need-a-lawyer'
      }
    }else{
      this.loginPrev.openLogin();
      // this.router.navigate(['/login']);
    }
  }

  getlocation(location) {
    this.locationmember = location
    this.getprime();
  }
  
  
  
  selectEvent(item) {
  
    if (item.id) {
      this.getprectise(item.id)
    }
    if (item.value) {
      this.getlocation(item.value)
    }

    this.locationmember
    // do something with selected item
  }

  selectEventlegal(item, data) {
 
    window.location.href = 'rc/cat/' + data + '/' + item.value
    // /rc/cat/'+ data.value +'/'+ sub.value
  }

  close(item) {
  
  }
  clear(item) {
  
  }
  closespecilization(item) {
  
  }
  closelocation(item: Event) {
  
    // item.stopPropagation();
    item.stopPropagation()
    // close();
    this.ngAutocompleteApi.nativeElement.close();
   
  }
  clearEventStatic(item) {
    
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }

  openfindlawer() {
    if(this.token){
      if (this.textvalid == true) {
        this.subject ="";
        this.openfindlawerModal.nativeElement.click()
      }else{
        this.findAlayer = false;
      }
    }else{
      this.loginPrev.openLogin();
      // this.router.navigate(['/login']);
    }
  }
  
  scheduleAppointment(memberid:number) {
    if(this.token){
      if (memberid != null) {
        this.subject ="Looking For Legal";
        this.findlawyer.member_id = memberid;
        this.findlawyer.description = "Looking for legal help"
        this.openfindlawerModal.nativeElement.click()
      }else{
        this.findAlayer = false;
      }
    }else{
      this.loginPrev.openLogin();
    }
  }

  refresh() {
    if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
    localStorage.clear();
    window.location.reload();
    }
  }
  openLogin() {
    this.loginModal.nativeElement.click()
  }
  loginSubmit(event:any) {
   
    // if (event == 'register') {
    //    this.registerPre.openModal();
   
    // } else if (event == 'forget') {
    //    this.forgotPre.openModal();
    // } else if (event == 'cart') {
    //   // this.api.emitCart();
    // }
  }

  slides = [
    { img: 'https://via.placeholder.com/600.png/09f/fff' },
    { img: 'https://via.placeholder.com/600.png/021/fff' },
    { img: 'https://via.placeholder.com/600.png/321/fff' },
    { img: 'https://via.placeholder.com/600.png/422/fff' },
    { img: 'https://via.placeholder.com/600.png/654/fff' },
  ];
  slideConfig = { slidesToShow: 4, slidesToScroll: 4 };
  addSlide() {
    this.slides.push({ img: 'http://placehold.it/350x150/777777' });
  }
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  ngAfterViewInit(){
    // this.ngxService.stop();
  }
}
