<!-- <app-view></app-view> -->
<div class="col-md-12">
    <div class="row">
        <div class="col-sm-9">
            <div class="row">

                <div class="col-md-12">
                    <div class="box">
                        <div class="row">

                            <div class="col-lg-4 col-sm-4">
                                <div class="form-group">
                                    <input type="text" name="name" class="form-control" [(ngModel)]="searchName"
                                        id="m-search-name" placeholder="Search Name">
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <div class="form-group">
                                    <input type="text" name="mail" class="form-control" [(ngModel)]="searchEmail"
                                        id="m-search-email" placeholder="Search Email">
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-3">
                                <div class="form-group">
                                    <input type="text" name="cnt" class="form-control" [(ngModel)]="searchNumber"
                                        id="m-search-contact" placeholder="Search Contact No">
                                </div>
                            </div>
                            <!-- <div class="col-lg-1 col-sm-1 text-right">
                                <button type="buttom" class="btn btn-primary"><i class="fa fa-search"></i></button>
                            </div> -->
                        </div>
                    </div>
                </div>

            </div>
            <div class="row"
                *ngFor="let b of barassociation |  ng2NestedSearch: { 'username' : searchName, 'email' : searchEmail,'contact_num' : searchNumber} : filterMetadata" 
                style="padding-left:10px">
                <div class="col-sm-12">
                    <ul class="li-n profile-list">
                        <li>
                            <div class="img">
                                <img src="https://cdn.static.soolegal.com/assets/images/profile2.jpg"
                                    class="img-thumbnail" alt="Shrey" title="Shrey" width="100" height="50">
                            </div>
                            <div class="detail" style=" margin-left: 70px;">
                                <div class="title">
                                    {{b.username}}
                                </div>
                                <div class="text-muted">
                                    <p><i  class="fa fa-fw fa-envelope"></i><span class="pad">{{b.email}}</span></p>
                                    <p><i class="fa fa-fw fa-phone"></i><span class="pad">{{b.contact_num}}</span></p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <span *ngIf="filterMetadata.count==0">No Results </span>
            </div>
        </div>
        <div class="col-sm-3">
            <!-- bar association sidebar area start -->
           
                <h5 style="padding-left:10px">View Past Events/videos</h5>
                <div class="top-arrow">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-short" style="height:30%; width:30%"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
                    </svg>
                </div>


              
                <div class="box-slider-1" id="sidebar" *ngFor="let l of limit1">
                    <div class="component-box-1" >
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>{{l.name}}</h4>
                                <div class="user-info-box-inner p-0 no-scroll min-height">
                                    <div class="user-info-box">
                                        <div class="bg-white">
                                            <img src="images/pasted-image.jpg" class="img-fluid" />
                                        </div>
                                        <div class="box-wrapper">
                                            <p>
                                                {{l.description}}
                                            </p>
                                            <a [attr.href]="'/event/' + l.id +'/add'" class="btn btn-primary">Register Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                  
                </div>
                <div class="bottom-arrow">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-down-short" style="height:30%; width:30%"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
 



<!-- End Bar Association Info block-->