import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder,FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ResourceCentreService } from '../resource-centre.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  totalcartamt:any = 0;
  cgstpercentage:any = 9;
  sgstpercentage:any = 9;
  cgstamt:any = 0;
  sgstamt:any = 0;
  TotalTaxamt:any;
  submitted = false;
  
  constructor(
    private formBuilder: FormBuilder,
    public resourcecenterservice: ResourceCentreService ,
    private route: ActivatedRoute,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.getResourcecartList();
  }

  getResourcecartList() {
    this.resourcecenterservice.resourceCartList().subscribe((res) => {
      // this.cartList = res;
      this.getTotalAmt(res);
      // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.resource.attachement_url + "#toolbar=0");
      // console.log('saaaaaaaaaaa', this.resource)
    });
  }

  getTotalAmt(res:any){
    if(res.length>0){
      var totalamt = 0;
      res.forEach((item, index) => {
        totalamt =totalamt + parseInt(item['price']);
        console.log(item); // 1, 2, 3
        console.log(index); // 0, 1, 2
      });
      this.totalcartamt = totalamt;
      this.cgstamt = ((this.totalcartamt * this.cgstpercentage) / 100);
      this.sgstamt = ((this.totalcartamt * this.sgstpercentage) / 100);
      this.TotalTaxamt = parseFloat(this.totalcartamt) + parseFloat(this.cgstamt) + parseFloat(this.sgstamt) ;
    }
  }
    basicFormGroup = new FormGroup({
      firstName: new FormControl('',Validators.required),
      lastName: new FormControl('',Validators.required),
      email: new FormControl('',Validators.required),  // for Private
      phone: new FormControl('',Validators.required),
      gstn: new FormControl('',Validators.required),
      company: new FormControl(''),
      address: new FormControl('',Validators.required),  
      country: new FormControl('',Validators.required), // for Private
      state: new FormControl('',Validators.required), // for Private
      zipCode: new FormControl('',Validators.required),
      sameAddress: new FormControl(''),
      saveInfo: new FormControl(''),
    });
  
  get form(): { [key: string]: AbstractControl } {
    return this.basicFormGroup.controls;
  }

  changeSelect(value: any, dd: string) {
    console.log(value);
    if(value!=''){
      this.basicFormGroup.controls[dd].setValue(value, {
        onlySelf: true
      })
    }
  }
  
  public setValidation(e: any,dd: string) {
    const val = e.target.value;
    console.log(e.target.checked);
    if (e.target.checked) {
      this.basicFormGroup.controls[dd].setValue('1', {
        onlySelf: true
      });
      
      this.basicFormGroup.get(dd).updateValueAndValidity();
      console.log(this.basicFormGroup.controls[dd].value,"val");
    } else{
      this.basicFormGroup.controls[dd].setValue('0', {
        onlySelf: true
      });
      console.log(this.basicFormGroup.controls[dd].value,"val");
    }
  }
  public onSubmit(submitSrc: string){
    // this.showLoader = true;
    this.submitted = true;
    console.log(this.basicFormGroup);
    console.log('ttttt')
    if(this.basicFormGroup.status === 'VALID'){
      const formData = new FormData();
    formData.append('firstname', this.basicFormGroup.get('firstName').value);
    formData.append('lastname', this.basicFormGroup.get('lastName').value);
    formData.append('email', this.basicFormGroup.get('email').value);
    formData.append('telephone', this.basicFormGroup.get('phone').value);
    formData.append('gstn', this.basicFormGroup.get('gstn').value);
    formData.append('address', this.basicFormGroup.get('address').value);
    formData.append('zipcode', this.basicFormGroup.get('zipCode').value);
    formData.append('company', this.basicFormGroup.get('company').value);
    formData.append('country', this.basicFormGroup.get('country').value);
    formData.append('state', this.basicFormGroup.get('state').value);
    formData.append('client_id', 1+'');
    formData.append('net_amount', 1+'');
    formData.append('total', 1+'');
    formData.append('product_type_id', 1+'');
    formData.append('qty', 1+'');
    formData.append('reference_id', 1+'');
    this.resourcecenterservice.resource_center_checkout_page(formData).subscribe((res: any) => {
      

    })

    }
   

  }
}
