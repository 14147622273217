import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicProfileRoutingModule } from './public-profile-routing.module';
import { PublicProfileComponent } from './public-profile.component';
import { LoginModule } from '../login/login.module';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';



@NgModule({
  declarations: [PublicProfileComponent],
  imports: [
    CommonModule,
    PublicProfileRoutingModule,
    LoginModule,
    FormsModule,
    ReactiveFormsModule,
    
  ]
})
export class PublicProfileModule { }
