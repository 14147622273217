import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, FormControl, Validators, } from '@angular/forms';
import { NewsService } from '../../news/news.service';
import { RoarService } from '../../roar/roar.service';
import { HttpClient } from '@angular/common/http';
import { News } from '../../news/news';
import { DatePipe } from '@angular/common';
import { Editor } from 'ngx-editor';
import { MemberService } from '../../member/member.service';
import { ApiService } from '../../api.service';
// import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Roar } from 'src/app/roar/roar';






@Component({
  selector: 'app-newsform',
  templateUrl: './newsform.component.html',
//   styleUrls: ['./create.component.css'],
 
})
export class NewsFormComponent implements OnInit {

  editor: Editor;
  status=1;
  title:any;
  roar: Roar[]=[];
  
  constructor(private formBuilder: FormBuilder,private route: ActivatedRoute, public newsService: NewsService,public roarService: RoarService, private router: Router, public memberapi: MemberService, public mainapi: ApiService) { }

  existingNews: any = null;
 

  ngOnInit(): void {
    this.editor = new Editor();
    var newsid = this.route.snapshot.paramMap.get('newsId');
   
  

  }
  news: News;
  newsImage: any = {};

  myForm1 = new FormGroup({
    title: new FormControl('',Validators.required),
    url: new FormControl('',Validators.required),
    image_source_title: new FormControl(''),
    image_source_link: new FormControl(''),
    publish_date: new FormControl('',Validators.required),
    tags: new FormControl('',Validators.required),
    content: new FormControl('',Validators.required),
    Status:new FormControl('')
  });
  get f() {
    return this.myForm1.controls;
  }

  onFileChange(event) {
    this.newsImage = event.target.files[0];
  }
  submitted=false;
  submit() {
    this.submitted = true;
    if (this.myForm1.valid) {
      
    const formData = new FormData();
    if (this.existingNews?.id) {
      formData.append("id", this.existingNews.id);
    }
    formData.append("url", this.newsImage);
    formData.append('title', this.myForm1.get('title').value);
    formData.append("url", this.myForm1.get('url').value);
    formData.append('image_source_link', this.myForm1.get('image_source_link').value);
    formData.append('image_source_title', this.myForm1.get('image_source_title').value);
    formData.append('publish_date', this.myForm1.get('publish_date').value);
    formData.append('tags', this.myForm1.get('tags').value);
    formData.append('content', this.myForm1.get('content').value);
    formData.append('status',this.status+'');

    this.newsService.create(formData).subscribe((res: any) => {
      console.log("News Created..:::", res.news);

      this.router.navigate(['/news/' + res.news]);

     
     
    });
  
      }
   
  }
}
