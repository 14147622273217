import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NeedALawyerService {
  public apiURL = environment.baseURL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  public url = environment.baseURL;
  constructor(private httpClient: HttpClient) { }
  
  getdata(urlt: any) {
    const getUrl = this.url + urlt;
    return this.httpClient.get(getUrl);
  }

  postdata(urlt: any,data: any) {
    console.log(this.apiURL + urlt);
    const getUrl = this.apiURL + urlt;
    return this.httpClient.get(getUrl,data);
  }
  
  replytoPoster(urlt: any,data: any) {
    console.log(this.apiURL + urlt);
    const getUrl = this.apiURL + urlt;
    return this.httpClient.post(getUrl,data);
  }

  saveCase(urlt: any,data: any) {
    console.log(this.apiURL + urlt);
    const getUrl = this.apiURL + urlt;
    return this.httpClient.post(getUrl,data);
  }


query_board_user_register(formData): Observable<any> {   
  
    return this.httpClient.post(this.apiURL + 'queryboard_user_register', formData)
    .pipe(
      catchError(this.errorHandler)
    )
  }  

  match_otp(formData): Observable<any> {   
  
    return this.httpClient.post(this.apiURL + 'matchOtpForBar', formData)
    .pipe(
      catchError(this.errorHandler)
    )
  }  

  resend_otp(formData): Observable<any> {   
  
    return this.httpClient.post(this.apiURL + 'resendOtpForBar', formData)
    .pipe(
      catchError(this.errorHandler)
    )
  }  

  lawyer_opinion()
  {
    return this.httpClient.get(this.apiURL + 'lawyer_opinion')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  city_list()
  {
    return this.httpClient.get(this.apiURL + 'city_list')
    .pipe(
      catchError(this.errorHandler)
    )
  }
  
  post_query(formData): Observable<any> {   
  
    return this.httpClient.post(this.apiURL + 'post_query', formData)
    .pipe(
      catchError(this.errorHandler)
    )
    }

  query_board_list(): Observable<any> {   

    return this.httpClient.get(this.apiURL + 'queryBoardList')
    .pipe(
      catchError(this.errorHandler)
    )
    }

find_a_lawyer(formData): Observable<any> {   
  
  return this.httpClient.post(this.apiURL + 'legalHelpSearch', formData)
  .pipe(
    catchError(this.errorHandler)
  )
  }


  find_case(topic_id) {
    return this.httpClient.get(this.apiURL + 'get_listed_case/'+topic_id)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  find_case_reply(topic_id) {
    return this.httpClient.get(this.apiURL + 'get_case_reply/'+topic_id)
    .pipe(
      catchError(this.errorHandler)
    )
  }


  
  errorHandler(error:any) { 
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }


 
}
