<main role="main" class="pt-5">
    <section class="jobs">
        <div class="container">
            <div class="row">
                <article class="profile-elements mb-4 col-md-9">
                    <!-- #1 - Box -->
                    <div class="component-box-1 mb-0">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>Find a Job</h4>

                                <div class="user-info-box-inner no-scroll mh-0 user-info-box-inner no-scroll p-4">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div>
                                                <input type="text" class="form-control rounded-0 custom-input-text text-center bg-white" placeholder="Keywords, Title, Job Title" />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div>
                                                <input type="text" class="form-control rounded-0 custom-input-text text-center bg-white" placeholder="Locations" />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div>
                                                <input type="text" class="form-control rounded-0 custom-input-text text-center bg-white" placeholder="Qualifications/Experiance" />
                                            </div>
                                        </div>

                                        <div class="col-md-12 mt-4">
                                            <div class="d-flex justify-content-between d-grid align-items-center">
                                                <div>
                                                    <label class="form-check-inline custom-input-text">Posted Between</label>
                                                    <span class="form-check-inline">
                              <input type="text" class="form-control rounded-0 custom-input-text text-center bg-white w-126" placeholder="DD/MM/YY" />
                            </span>
                                                    <label class="form-check-inline custom-input-text">To</label>
                                                    <span class="form-check-inline">
                              <input type="text" class="form-control rounded-0 custom-input-text text-center bg-white w-126" placeholder="DD/MM/YY" />
                            </span>
                                                </div>

                                                <div>
                                                    <label class="custom-input-text m-0">Show All Jobs </label>

                                                    <input type="radio" class="form-control rounded-0 custom-input-text text-center h-12" name="job_type" />
                                                </div>

                                                <div>
                                                    <label class="custom-input-text m-0"> Show Only Job Works </label>

                                                    <input type="radio" class="form-control rounded-0 custom-input-text text-center h-12" name="job_type" />
                                                </div>

                                                <div>
                                                    <a class="btn btn-cta color-gray"><span style="font-weight: bold;">Find Job</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article class="col-md-3">
                    <!-- #1 - Box -->
                    <div class="component-box-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>Hire For Jobs</h4>
                                <div class="user-info-box-inner no-scroll h-0 p-0">
                                    <a href="#" data-toggle="modal" data-target="#job-offers"><img src="../../assets/images/jobs.png" class="img-fluid" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </section>

    <section class="job-post-header">
        <div class="container">
            <div class="row">
                <div class="col-md-9">
                    <h4>Top jobs based on your profile</h4>
                </div>

                <div class="col-md-3">
                    <h4 class="d-flex justify-content-around header-text"><a href="#"> Saved Jobs</a> <a href="#"> Applied Jobs</a></h4>
                </div>
            </div>
        </div>
    </section>

    <section class="job-posts">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="component-box-1 p-0">
                        <div class="user-info-box-main p-0">
                            <div class="user-info-box">
                                <div class="user-info-box-inner no-scroll pb-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="jobId mb-3">
                                            <p>
                                                Job Id: #444 <br /> HFHSF Legal
                                            </p>
                                        </div>

                                        <div class="job-posted-date mb-3">
                                            <p>Posted on: DD/MM/YY</p>
                                        </div>
                                    </div>

                                    <div class="job-details text-left mb-3">
                                        <h3 class="mb-3">Legal Intern</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis cursus ultrices ipsum, eu pharetra nisi facilisis at. Etiam purus ipsum, aliquam eu elit quis, rutrum commodo sapien. <a href="#">Read More</a></p>

                                        <ul class="profile-keys list-unstyled">
                                            <li><strong>Key Skills: </strong> text here...</li>
                                            <li><strong>Qualifications: </strong> text here...</li>
                                            <li><strong>Experiance: </strong> text here...</li>
                                            <li><strong>Locations: </strong> text here...</li>
                                        </ul>
                                    </div>

                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <a href="#" class="btn btn-cta text-white mr-2">Apply Now</a>
                                            <a href="#" class="btn btn-cta text-white">Save Job</a>
                                        </div>

                                        <div>
                                            <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="component-box-1 p-0">
                        <div class="user-info-box-main p-0">
                            <div class="user-info-box">
                                <div class="user-info-box-inner no-scroll pb-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="jobId mb-3">
                                            <p>
                                                Job Id: #444 <br /> HFHSF Legal
                                            </p>
                                        </div>

                                        <div class="job-posted-date mb-3">
                                            <p>Posted on: DD/MM/YY</p>
                                        </div>
                                    </div>

                                    <div class="job-details text-left mb-3">
                                        <h3 class="mb-3">Legal Intern</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis cursus ultrices ipsum, eu pharetra nisi facilisis at. Etiam purus ipsum, aliquam eu elit quis, rutrum commodo sapien. <a href="#">Read More</a></p>

                                        <ul class="profile-keys list-unstyled">
                                            <li><strong>Key Skills: </strong> text here...</li>
                                            <li><strong>Qualifications: </strong> text here...</li>
                                            <li><strong>Experiance: </strong> text here...</li>
                                            <li><strong>Locations: </strong> text here...</li>
                                        </ul>
                                    </div>

                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <a href="#" class="btn btn-cta text-white mr-2">Apply Now</a>
                                            <a href="#" class="btn btn-cta text-white">Save Job</a>
                                        </div>

                                        <div>
                                            <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="component-box-1 p-0">
                        <div class="user-info-box-main p-0">
                            <div class="user-info-box">
                                <div class="user-info-box-inner no-scroll pb-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="jobId mb-3">
                                            <p>
                                                Job Id: #444 <br /> HFHSF Legal
                                            </p>
                                        </div>

                                        <div class="job-posted-date mb-3">
                                            <p>Posted on: DD/MM/YY</p>
                                        </div>
                                    </div>

                                    <div class="job-details text-left mb-3">
                                        <h3 class="mb-3">Legal Intern</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis cursus ultrices ipsum, eu pharetra nisi facilisis at. Etiam purus ipsum, aliquam eu elit quis, rutrum commodo sapien. <a href="#">Read More</a></p>

                                        <ul class="profile-keys list-unstyled">
                                            <li><strong>Key Skills: </strong> text here...</li>
                                            <li><strong>Qualifications: </strong> text here...</li>
                                            <li><strong>Experiance: </strong> text here...</li>
                                            <li><strong>Locations: </strong> text here...</li>
                                        </ul>
                                    </div>

                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <a href="#" class="btn btn-cta text-white mr-2">Apply Now</a>
                                            <a href="#" class="btn btn-cta text-white">Save Job</a>
                                        </div>

                                        <div>
                                            <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="component-box-1 p-0">
                        <div class="user-info-box-main p-0">
                            <div class="user-info-box">
                                <div class="user-info-box-inner no-scroll pb-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="jobId mb-3">
                                            <p>
                                                Job Id: #444 <br /> HFHSF Legal
                                            </p>
                                        </div>

                                        <div class="job-posted-date mb-3">
                                            <p>Posted on: DD/MM/YY</p>
                                        </div>
                                    </div>

                                    <div class="job-details text-left mb-3">
                                        <h3 class="mb-3">Legal Intern</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis cursus ultrices ipsum, eu pharetra nisi facilisis at. Etiam purus ipsum, aliquam eu elit quis, rutrum commodo sapien. <a href="#">Read More</a></p>

                                        <ul class="profile-keys list-unstyled">
                                            <li><strong>Key Skills: </strong> text here...</li>
                                            <li><strong>Qualifications: </strong> text here...</li>
                                            <li><strong>Experiance: </strong> text here...</li>
                                            <li><strong>Locations: </strong> text here...</li>
                                        </ul>
                                    </div>

                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <a href="#" class="btn btn-cta text-white mr-2">Apply Now</a>
                                            <a href="#" class="btn btn-cta text-white">Save Job</a>
                                        </div>

                                        <div>
                                            <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="component-box-1 p-0">
                        <div class="user-info-box-main p-0">
                            <div class="user-info-box">
                                <div class="user-info-box-inner no-scroll pb-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="jobId mb-3">
                                            <p>
                                                Job Id: #444 <br /> HFHSF Legal
                                            </p>
                                        </div>

                                        <div class="job-posted-date mb-3">
                                            <p>Posted on: DD/MM/YY</p>
                                        </div>
                                    </div>

                                    <div class="job-details text-left mb-3">
                                        <h3 class="mb-3">Legal Intern</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis cursus ultrices ipsum, eu pharetra nisi facilisis at. Etiam purus ipsum, aliquam eu elit quis, rutrum commodo sapien. <a href="#">Read More</a></p>

                                        <ul class="profile-keys list-unstyled">
                                            <li><strong>Key Skills: </strong> text here...</li>
                                            <li><strong>Qualifications: </strong> text here...</li>
                                            <li><strong>Experiance: </strong> text here...</li>
                                            <li><strong>Locations: </strong> text here...</li>
                                        </ul>
                                    </div>

                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <a href="#" class="btn btn-cta text-white mr-2">Apply Now</a>
                                            <a href="#" class="btn btn-cta text-white">Save Job</a>
                                        </div>

                                        <div>
                                            <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="component-box-1 p-0">
                        <div class="user-info-box-main p-0">
                            <div class="user-info-box">
                                <div class="user-info-box-inner no-scroll pb-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="jobId mb-3">
                                            <p>
                                                Job Id: #444 <br /> HFHSF Legal
                                            </p>
                                        </div>

                                        <div class="job-posted-date mb-3">
                                            <p>Posted on: DD/MM/YY</p>
                                        </div>
                                    </div>

                                    <div class="job-details text-left mb-3">
                                        <h3 class="mb-3">Legal Intern</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis cursus ultrices ipsum, eu pharetra nisi facilisis at. Etiam purus ipsum, aliquam eu elit quis, rutrum commodo sapien. <a href="#">Read More</a></p>

                                        <ul class="profile-keys list-unstyled">
                                            <li><strong>Key Skills: </strong> text here...</li>
                                            <li><strong>Qualifications: </strong> text here...</li>
                                            <li><strong>Experiance: </strong> text here...</li>
                                            <li><strong>Locations: </strong> text here...</li>
                                        </ul>
                                    </div>

                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <a href="#" class="btn btn-cta text-white mr-2">Apply Now</a>
                                            <a href="#" class="btn btn-cta text-white">Save Job</a>
                                        </div>

                                        <div>
                                            <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="component-box-1 p-0">
                        <div class="user-info-box-main p-0">
                            <div class="user-info-box">
                                <div class="user-info-box-inner no-scroll pb-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="jobId mb-3">
                                            <p>
                                                Job Id: #444 <br /> HFHSF Legal
                                            </p>
                                        </div>

                                        <div class="job-posted-date mb-3">
                                            <p>Posted on: DD/MM/YY</p>
                                        </div>
                                    </div>

                                    <div class="job-details text-left mb-3">
                                        <h3 class="mb-3">Legal Intern</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis cursus ultrices ipsum, eu pharetra nisi facilisis at. Etiam purus ipsum, aliquam eu elit quis, rutrum commodo sapien. <a href="#">Read More</a></p>

                                        <ul class="profile-keys list-unstyled">
                                            <li><strong>Key Skills: </strong> text here...</li>
                                            <li><strong>Qualifications: </strong> text here...</li>
                                            <li><strong>Experiance: </strong> text here...</li>
                                            <li><strong>Locations: </strong> text here...</li>
                                        </ul>
                                    </div>

                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <a href="#" class="btn btn-cta text-white mr-2">Apply Now</a>
                                            <a href="#" class="btn btn-cta text-white">Save Job</a>
                                        </div>

                                        <div>
                                            <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="component-box-1 p-0">
                        <div class="user-info-box-main p-0">
                            <div class="user-info-box">
                                <div class="user-info-box-inner no-scroll pb-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="jobId mb-3">
                                            <p>
                                                Job Id: #444 <br /> HFHSF Legal
                                            </p>
                                        </div>

                                        <div class="job-posted-date mb-3">
                                            <p>Posted on: DD/MM/YY</p>
                                        </div>
                                    </div>

                                    <div class="job-details text-left mb-3">
                                        <h3 class="mb-3">Legal Intern</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis cursus ultrices ipsum, eu pharetra nisi facilisis at. Etiam purus ipsum, aliquam eu elit quis, rutrum commodo sapien. <a href="#">Read More</a></p>

                                        <ul class="profile-keys list-unstyled">
                                            <li><strong>Key Skills: </strong> text here...</li>
                                            <li><strong>Qualifications: </strong> text here...</li>
                                            <li><strong>Experiance: </strong> text here...</li>
                                            <li><strong>Locations: </strong> text here...</li>
                                        </ul>
                                    </div>

                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <a href="#" class="btn btn-cta text-white mr-2">Apply Now</a>
                                            <a href="#" class="btn btn-cta text-white">Save Job</a>
                                        </div>

                                        <div>
                                            <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="component-box-1 p-0">
                        <div class="user-info-box-main p-0">
                            <div class="user-info-box">
                                <div class="user-info-box-inner no-scroll pb-3">
                                    <div class="d-flex justify-content-between">
                                        <div class="jobId mb-3">
                                            <p>
                                                Job Id: #444 <br /> HFHSF Legal
                                            </p>
                                        </div>

                                        <div class="job-posted-date mb-3">
                                            <p>Posted on: DD/MM/YY</p>
                                        </div>
                                    </div>

                                    <div class="job-details text-left mb-3">
                                        <h3 class="mb-3">Legal Intern</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis cursus ultrices ipsum, eu pharetra nisi facilisis at. Etiam purus ipsum, aliquam eu elit quis, rutrum commodo sapien. <a href="#">Read More</a></p>

                                        <ul class="profile-keys list-unstyled">
                                            <li><strong>Key Skills: </strong> text here...</li>
                                            <li><strong>Qualifications: </strong> text here...</li>
                                            <li><strong>Experiance: </strong> text here...</li>
                                            <li><strong>Locations: </strong> text here...</li>
                                        </ul>
                                    </div>

                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <a href="#" class="btn btn-cta text-white mr-2">Apply Now</a>
                                            <a href="#" class="btn btn-cta text-white">Save Job</a>
                                        </div>

                                        <div>
                                            <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="partners-main-box my-3">
        <div class="container-fluid p-0">
            <div class="bg-gray-new shadow-none">
                <div class="row justify-content-between align-items-center">
                    <div class="col-md-12 col-lg-5 my-2 logo-box">
                        <img src="../../assets/images/logo.png" alt="" class="im" />
                        <h4 class="title-1">Partner Services</h4>
                    </div>
                    <div class="col-md-12 col-lg-7 my-2">
                        <div class="partners owl-carousel owl-theme owl-loaded owl-drag">
                            <div class="owl-stage-outer">
                                <div class="owl-stage" style="transform: translate3d(-537px, 0px, 0px); transition: all 0.25s ease 0s; width: 1255px;">
                                    <div class="owl-item" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-nav disabled">
                                <button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>
                            </div>
                            <div class="owl-dots disabled"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>



<!-- Modal box for job post -->
<div class="modal fade" id="job-offers" tabindex="-1" aria-labelledby="Job Offers" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content bg-transparent p-0 m-0">

            <div>
                <!-- #1 - Box -->
                <div class="component-box-1 m-0 p-1">
                    <div class="user-info-box-main">
                        <div class="user-info-box ">
                            <h4>Post a Job</h4>


                            <div class="user-info-box-inner no-scroll mh-0 user-info-box-inner no-scroll pt-4 pl-4 pr-4 pb-0">
                                <div class="row ">
                                    <div class="col-md-4">
                                        <div>
                                            <input type="text" class="form-control rounded-0 custom-input-text text-center bg-white" placeholder="Keywords, Title, Job Title">
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div>
                                            <input type="text" class="form-control rounded-0 custom-input-text text-center bg-white" placeholder="Locations">
                                        </div>
                                    </div>


                                    <div class="col-md-4">
                                        <div>
                                            <input type="text" class="form-control rounded-0 custom-input-text text-center bg-white" placeholder="Qualifications/Experiance">
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-4">

                                        <textarea class="form-control  custom-input-text is-invalid bg-white rounded-0" id="validationTextarea" placeholder="Job Description"></textarea>



                                    </div>

                                </div>



                                <div class="modal-footer pt-0 pb-0 border-0">

                                    <button type="button" class="btn btn-primary">Post Job</button>
                                </div>


                            </div>
                        </div>
                    </div>




                </div>
            </div>

        </div>
    </div>
</div>