<div class="modal fade" id="forgot-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-top">
        <div class="modal-content PopUpBox">

            <a href="javascript:void(0);" data-dismiss="modal" class="popup-times"><img src="../../assets/images/close.svg" #closeforgotModal></a>

            <div class="flash-content text-center">
                <div class="text">
                    <p class="title">
                        Forgot Username/Password
                    </p>

                    <p>Please enter your registerd mobile number or e-mail to recive details to recover/reset your username/password</p>
                    <div class="alert alert-success" *ngIf="message" role="alert">
                        {{message}}
                    </div>

                    <form class="mt-3">
                        <div class="form-group m-0">
                            <input type="text" class="form-control" [(ngModel)]="forgetemail.email" name="email" aria-describedby="emailHelp" placeholder="Mobile/Email ID" />
                        </div>

                        <div class="text-center btn-wrapper mt-3">
                            <button type="submit" class="btn btn-primary link-bg" (click)="forgotemail()">Submit</button>

                        </div>
                    </form>


                </div>


                <p class="text-color">or sign up with</p>
                <div class="social-icons">
                    <a href="#"><i class="fab fa-google-plus"></i></a>
                    <a href="#"><i class="fab fa-facebook"></i></a>

                    <a href="#"><i class="fab fa-twitter"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="forgototp-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-top">
        <div class="modal-content PopUpBox">

            <a href="javascript:void(0);" data-dismiss="modal" class="popup-times"><img src="../../assets/images/close.svg" #closeforgototpModal></a>

            <div class="flash-content text-center">


                <div class="text">

                    <p>A code will be sent to the mobile no/e-mail that you enter above. Enter the recieved code/OTP below.</p>

                    <div class="alert alert-success" *ngIf="message" role="alert">
                        {{message}}
                    </div>
                    <form class="mt-3">
                        <div class="form-group m-0 mb-3">
                            <!-- [(ngModel)]="forgetemail.email" -->
                            <input type="password" class="form-control" [(ngModel)]="forgetemail.otp" name="otp" placeholder="OTP" />
                        </div>

                        <div class="text-center btn-wrapper mt-3">
                            <button type="submit" class="btn btn-primary link-bg" (click)="sendotp()">Submit</button>

                        </div>
                    </form>


                </div>
                <p class="text-color">or sign up with</p>
                <div class="social-icons">
                    <a href="#"><i class="fab fa-google-plus"></i></a>
                    <a href="#"><i class="fab fa-facebook"></i></a>

                    <a href="#"><i class="fab fa-twitter"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="reset-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-top">
        <div class="modal-content PopUpBox">

            <a href="javascript:void(0);" data-dismiss="modal" class="popup-times"><img src="../../assets/images/close.svg" #closeresetModal></a>

            <div class="flash-content text-center">


                <div class="text">

                    <div class="alert alert-success" *ngIf="message" role="alert">
                        {{message}}
                    </div>

                    <form class="mt-3">

                        <div class="form-group m-0 mb-3">
                            <input type="text" class="form-control" [(ngModel)]="forgetemail.password" name="password" placeholder="New Password" />
                        </div>

                        <div class="form-group m-0 mb-3">
                            <input type="text" class="form-control" [(ngModel)]="confirm" name="confirm" placeholder="Re-Enter New Password" />
                        </div>


                        <p class="title d-flex align-items-center justify-content-center mt-2 mb-2 robot-text">
                            <span> I'm not a robot </span>
                            <label class="mb-0">
                      <input type="radio" name="case" class="radio" />
                      <span class="radio-style check-radio-style"></span>
                    </label>
                        </p>

                        <div class="text-center btn-wrapper mt-3">
                            <button type="submit" class="btn btn-primary link-bg mt-4" (click)="resetpassword()">Submit</button>
                        </div>

                    </form>


                </div>
                <p class="text-color">or sign up with</p>
                <div class="social-icons">
                    <a href="#"><i class="fab fa-google-plus"></i></a>
                    <a href="#"><i class="fab fa-facebook"></i></a>

                    <a href="#"><i class="fab fa-twitter"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>




<span #openforgotModal data-toggle="modal" data-backdrop="static" data-target="#forgot-modal" style="display: none;"></span>
<span #openforgototpModal data-toggle="modal" data-backdrop="static" data-target="#forgototp-modal" style="display: none;"></span>
<span #openresetModal data-toggle="modal" data-backdrop="static" data-target="#reset-modal" style="display: none;"></span>

<app-login></app-login>