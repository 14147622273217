import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BarAssociationComponent } from './bar-association.component';
import { ViewComponent } from './view/view.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { MemberComponent } from './member/member.component';
import { CalenderComponent } from './calender/calender.component';
import { CommitteComponent } from './committemembers/committemembers.component';
import { ContactusComponent } from './contactus/contactus.component';
import { HelplineComponent } from './helpline/helpline.component';
import { GalleryComponent } from './gallery/gallery.component';

const routes: Routes = [
  {
    path:'',
    component:BarAssociationComponent
  },
  { path: 'bar-association/:barId', component: ViewComponent,
  children: [
   
  { path: 'aboutus', component: AboutusComponent },
  { path: '',   redirectTo: 'aboutus', pathMatch: 'full' },
  { path: 'member', component: MemberComponent },
  { path: 'calender', component: CalenderComponent },
  { path: 'committemembers', component: CommitteComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'helpline', component: HelplineComponent },
  { path: 'gallery', component: GalleryComponent },
  ]
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BarAssociationRoutingModule { }
