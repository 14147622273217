import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {ApiResponse} from 'src/app/notification/api.responce';
import { Observable } from 'rxjs';
// import {notification} from 'src/app/notification/notificatication.model'
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  NotificationService: any;
  
  baseUrl:string = 'http://beta.soolegal.com/public/api/notification';
  constructor(private http:HttpClient ) { }


  list(){
    const Headers = new HttpHeaders().set('JWTAuthorization' , localStorage.getItem('USERID'));
    const headers = { headers: Headers };
   return this.http.get(this.baseUrl,headers);
  
   }
}
