import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { Barassociation } from './barassociation';
@Injectable({
  providedIn: 'root'
})
export class BarAssociationService {
  public apiURL = environment.baseURL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private httpClient: HttpClient) {  }
    
  
  getAll(city,country,state,district): Observable<any> {
    
    return this.httpClient.get<Barassociation[]>(this.apiURL + 'barassociation?city='+city+'&country='+country+'&state='+state+'&district='+district)
      .pipe(
        catchError(this.errorHandler)
      )
  }
  sort_city(){
    return this.httpClient.get(this.apiURL + 'sort_city' )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  sort_country(){
    return this.httpClient.get(this.apiURL + 'sort_country' )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  sort_state(){
    return this.httpClient.get(this.apiURL + 'sort_state' )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  sort_district(){
    return this.httpClient.get(this.apiURL + 'sort_district' )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  bar_association_view(bar_association_id){
    return this.httpClient.get(this.apiURL + 'bar_association_view?bar_association_id='+bar_association_id )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  bar_association_member(bar_association_id){
    return this.httpClient.get(this.apiURL + 'bar_association_member?bar_association_id='+bar_association_id )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  bar_association_member_count(bar_association_id){
    return this.httpClient.get(this.apiURL + 'bar_association_member_count?bar_association_id='+bar_association_id )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  bar_association_committemember_count(bar_association_id){
    return this.httpClient.get(this.apiURL + 'bar_association_committemember_count?bar_association_id='+bar_association_id )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  bar_association_calender(bar_association_id){
    return this.httpClient.get(this.apiURL + 'bar_association_calender?bar_association_id='+bar_association_id )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  bar_association_commiteemember(bar_association_id){
    return this.httpClient.get(this.apiURL + 'bar_association_commiteemember?bar_association_id='+bar_association_id )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  bar_association_aboutus(bar_association_id){
    return this.httpClient.get(this.apiURL + 'bar_association_aboutus?bar_association_id='+bar_association_id )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  bar_association_contactus(bar_association_id){
    return this.httpClient.get(this.apiURL + 'bar_association_contactus?bar_association_id='+bar_association_id )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  bar_association_gallery(bar_association_id){
    return this.httpClient.get(this.apiURL + 'bar_association_gallery?bar_association_id='+bar_association_id )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
