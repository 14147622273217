import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, } from '@angular/forms';
import { ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EventService } from '../event.service';
import { Event } from '../event';
import { Editor } from 'ngx-editor';
import { RoarService } from '../../roar/roar.service';
import { EventregisterService } from '../eventregister.service';

declare var jQuery:any;
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {
  editor: Editor;
  existingEvent:any=null;
  eventId:any;
  event_id:number;
  // event:Event;
   event:any;
var:any;
roar:any;
Roar:[] = [];
  constructor(private formBuilder: FormBuilder,public eventregisterService: EventregisterService,public roarService: RoarService, private route: ActivatedRoute,private router: Router, public eventService: EventService,) { }

  ngOnInit(): void {
    // jQuery('input[name="daterange"]').daterangepicker({
    //   opens: 'left'
    // }, function(start, end, label) {
    //   console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
    // });
    var eventid = this.route.snapshot.params['eventId'];
    this.findEvent(eventid);
  }
  findEvent(event_id){
    console.log('getting Event');
    this.eventService.find(event_id).subscribe((res:any) => {
      this.event = res.messages.feed_list[0];
    });
    console.log('getting Event',this.event);
   
  }

  myForm = new FormGroup({
    name: new FormControl('',Validators.required),
    contact_no: new FormControl('',Validators.required),
    event: new FormControl(''),
    email: new FormControl('',[Validators.email,Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    city: new FormControl(''),
    zip: new FormControl(''),
    country: new FormControl(''),
    profession: new FormControl(''),
    address: new FormControl(''),
   
  });
  changeevent(e) {
    console.log(e.target.value);
  }
  get f(){
    return this.myForm.controls;
  }
  submitted=false;
  paidtype;
  submit() {
   
    this.submitted = true;
    if (this.myForm.valid) {
   
    const formData = new FormData();
    formData.append('name', this.myForm.get('name').value);
    formData.append("contact_no", this.myForm.get('contact_no').value);
    formData.append("email", this.myForm.get('email').value);
    formData.append('category', this.myForm.get('event').value);
    formData.append('city', this.myForm.get('city').value);
   formData.append('event_id',this.event.id+'');
   formData.append('registration_category',this.myForm.get('profession').value);
   formData.append('event_country',this.myForm.get('country').value);
   formData.append('event_zip',this.myForm.get('zip').value);
    this.eventregisterService.create(formData).subscribe((res: any) => {
      console.log("Event Registered..:::",this.event.id );
     
this.myForm.reset();
this.router.navigate(['event']);

    });
   
      }  }
}






















































































// myForm = new FormGroup({
//   title: new FormControl('',),
//   banner: new FormControl(''),
//   venue_details: new FormControl(''),
//   description: new FormControl(''),
//   start_date: new FormControl(''),
//   end_date:new FormControl(''),
//   tags:new FormControl(''),
//   event_category:new FormControl(''),
//   amount:new FormControl(''),
//   paidtype:new FormControl(''),
 
// });
// get f() {
//   return this.myForm.controls;

// }

// onFileChange(event) {
//   this.eventImage = event.target.files[0];
// }
// registration(e) {
//   console.log(e.target.value);
// }

// submit() {
//   console.log('hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')
  
//   const formData = new FormData();
//   if (this.existingEvent?.id) {
//     formData.append("id", this.existingEvent.id);
//   }
//   formData.append("banner", this.eventImage);
//   formData.append('title', this.myForm.get('title').value);
//   formData.append("banner", this.myForm.get('banner').value);
//   formData.append('venue_details', this.myForm.get('venue_details').value);
//   formData.append('description', this.myForm.get('description').value);
//   formData.append('start_date', this.myForm.get('start_date').value);
//   formData.append('end_date', this.myForm.get('end_date').value);
//   formData.append('tags', this.myForm.get('tags').value);
//   formData.append('event_category', this.myForm.get('event_category').value);
//   formData.append('amount', this.myForm.get('amount').value);
//   formData.append('paidtype', this.myForm.get('paidtype').value);

//   this.eventService.create(formData).subscribe((res: any) => {
//     console.log("Event Created..:::", res.event);
   
//     this.router.navigate(['event']);
//   });
// }
// findEvent(eventId) {
//   this.eventService.find(eventId).subscribe((res: Event) => {
//     this.existingEvent = res;

//     this.myForm.patchValue({
//       title: this.existingEvent.title,
//       start_date: this.existingEvent.start_date,
//       end_date: this.existingEvent.end_date,
//       venue_details: this.existingEvent.venue_details,
//       description: this.existingEvent.description,
//       tags: this.existingEvent.tags,
//       event_category: this.existingEvent.event_category,
      
    
//       banner: this.existingEvent.banner,
     
    
//     });

//   });