import { Component } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'views';
  constructor(private ngxService: NgxUiLoaderService){}

  ngOnInit(): void {
    this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId
    // Stop the foreground loading after 5s
    // setTimeout(() => {
    //   // stop foreground spinner of the master loader with 'default' taskId
    // }, 5000);
}

ngAfterViewInit(){
    this.ngxService.stop();
}

}
