import { Component, OnInit, Input, Output,ViewChild, EventEmitter,ElementRef } from '@angular/core';
import { ResourceCentreService } from '../resource-centre.service';
import { Resource } from '../resource';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../login/login.service';
import { LoginComponent } from '../../login/login.component';
import { ApiService } from '../../api.service';
import { LikeService } from '../../like/like.service';
import { CommentService } from '../../comment/comment.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-resource-form',
  templateUrl: './subcategory.component.html',
//   styleUrls: ['./resorce-form.component.css']
})
export class subcategoryComponent implements OnInit {

  @Input()
  postId: number;

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter();

  resource: Resource[]=[] ;
  comments: Comment[];
  featured:Resource[]=[];
  totalsearch:any;
  data:string;
  subcategory:Resource[]=[];
//  category_id=1;
subcategoryresult:any;
all_sub_category_list:any;

 category:number;
 token: string;
 category_id=3;
 pagecmt: number = 1;
  countcmt: number = 0;
  tableSizecmt: number = 4;
  tableSizescmt: any = [3, 6, 9, 12];
  r: any;
  c:any;
cmt:any;
closeResult:string;
categoryname:any;
//  resource_center_data:any;
//  attachment_url:any;


ng2NestedSearch:any;
searchtitle : string = "";
searchEmail : string = "";
searchNumber : string = "";
filterMetadata = { count: 0, isNestedSearch:true };

  constructor(public resourcecenterservice: ResourceCentreService,public commentService: CommentService,public likeService: LikeService, private formBuilder: FormBuilder,public api: ApiService,private modalService: NgbModal,
    private route: ActivatedRoute,)
   {
    if(localStorage.getItem('USERID')){


      this.token =  localStorage.getItem('USERID')
     }
     if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
     this.refresh();
     }
    }
  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  @ViewChild('openLoginModal')loginModal: ElementRef; 
  @Output() loginEvent = new EventEmitter<string>();
  
  ngOnInit(): void {

    this.categoryname = this.route.snapshot.paramMap.get('subcategory');
    this.sublist(this.categoryname);
    // this.getComments(this.categoryname, this.category_id);
    this.searchresult();
    // this.getResource(1)
    this.search('');
    this.get_all_sub_category_list()
console.log('gggggg',this.categoryname);

  }

  getList(categoryid,payment_type) {
    console.log('getting Resource',payment_type);

    this.resourcecenterservice.resourcelistview(categoryid,payment_type).subscribe((res: Resource[]) => {
      //   var resource_category={};
      //   res.forEach(function (value:any) {
      //     resource_category[value.resource_centre_category_id]=[value];
      //     console.log(resource_category);
      // });
      // console.log('ttttttttttttttttttttt',resource_category);
      this.resource = res;
// this.resource.resource_center_data;
// this.resource.attachment_url;


      console.log("Resource..:::", this.resource);
    })
}


rcview() {
   
  const formData = new FormData();
  formData.append('resource_centre_id', this.categoryname); 
  this.resourcecenterservice.rcview(formData).subscribe((res: any) => {
    
  });

}
sublist(category_name){
  this.resourcecenterservice.subcategoryList(category_name).subscribe((res: Resource[]) => {
    this.subcategory = res;
  })
}
getResource(parent_id) {
    console.log('getting Resource', parent_id);

    this.resourcecenterservice.getAll(parent_id).subscribe((res: any) => {
   
       this.subcategoryresult =res;
    
    })

  }

  get_all_sub_category_list() {
   
    this.resourcecenterservice.get_all_sub_category_list().subscribe((res: any) => {
 
     this.all_sub_category_list =res;
  console.log("category_filter_list is",res)
  })

}

searchresult(){
  this.resourcecenterservice.totalsearch().subscribe((res: any) => {
   this.totalsearch = res;
  })
}


myForm = new FormGroup({
  payment_type: new FormControl(''),
  });

doctype(payment_type){
 
  console.log('ooooooooooooooo',this.categoryname,payment_type)
  
    this.getList(this.categoryname,payment_type);
}

click(){
  if(this.token){
    
  }else{
    this.loginPrev.openLogin();
  }
}


like(r) {
  if(this.token){
      let resource_id = r.id,
        liked = r.liked,
        member_id = r.member_id;
  
      // this.showicon = !this.showicon;
      // this.show = !this.show;
      console.log('like',r);
      const formData = new FormData();
      formData.append('post_id', resource_id + '');
      formData.append('category_id', this.category_id + '');
      formData.append('member_id', member_id + '');
      if (liked > 0 ) {
        liked = 0;
        r.like_count = r.like_count - 1;
      } else {
       liked = 1;
        r.like_count = r.like_count + 1;
      }
      // UPdating the ui object
      r.liked = liked;
  
      formData.append('status', liked + '');
      console.log('status',liked);
  
      this.likeService.create(formData).subscribe((res: any) => {
        console.log("Roar Liked..:::", res.roar);
      });
  
      console.log(this.category_id);
    }
  
    }
    refresh() {
      if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
      localStorage.clear();
      // window.location.reload();
      }
    }
    openLogin() {
      this.loginModal.nativeElement.click()
    }
    loginSubmit(event:any) {
    }
    getImagePic(resource) {
      return resource.member_profile_pic_url;
    }



  getComments(post_id, category_id) {
    this.commentService.getAll(post_id, this.category_id).subscribe((com: Comment[]) => {
      this.comments = com;
      console.log("Comments..:::", this.comments);

    });
  }

    onCommentCreate(resource_id) {
      this.getComments(resource_id, this.category_id);
      console.log(resource_id, this.category_id);
      console.log('news creayed');
    }
    onComment(resource_id) {
      this.getComments(resource_id, this.category_id);
      console.log('news creayednnnnnnnnnnnnnn');
    }
    search(search){
      this.resourcecenterservice.search(search).subscribe((res: any) => {
        console.log(search,"rez");
        this.resource =res;
        if(search==''){
          this.getList(this.categoryname,'');
        }
        this.totalsearch = res;
      })
    }
    onKeyup($event){
      this.search($event);
    }




    ope(conte) {
      this.modalService.open(conte, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismiss(reason)}`;
      });
    }
  
  
    private getDismiss(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }


    // comment
    open(content, r) {
      this.r = r;
      this.getComments(r.id, 1);
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      
    }
    
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }



    onTableDataChangecmt(event1: any) {
      this.pagecmt = event1;
      this.getComments;
    }
    onTableSizeChangecmt(event1: any): void {
      this.tableSizecmt = event1.target.value;
      this.pagecmt = 1;
      this.getComments;
    }


    // socialshare url
  getLocation(news_id) {
    // return location.href + '/' + news_id;
     return  "https://stackoverflow.com"+'/'+news_id;
  }
}