<section [ngClass]="{'user-info-box-inner no-scroll text-left h-0 p-4':!isReply, 'media mt-3 clearfix': isReply}">
     <!-- media mt-3 clearfix -->


    <div class="media" >
        <img *ngIf="!isReply" src="../../assets/images/profile-user-round.png"  class="mr-3 media-img" alt="...">
       
            <img *ngIf="isReply" src="../../assets/images/profile-user-round.png" class="mr-3 media-img-ans" alt="...">
            
        <div class="media-body">
              <br>
            <p class="m-0">{{comment.reply_content}}</p>

            <div class="text-right d-flex justify-content-end">
                <!-- <a href="#" class="btn text-white color-gray"><i class="far fa-heart"></i></a> -->

                <a (click)="onclick()" class="btn text-white color-gray"><i class="far fa-comments"></i>Reply</a>

                <!-- <a href="#" class="btn text-white color-gray"><i class="fas fa-share-alt"></i></a> -->
            </div>
        </div>
       
    </div>
    <single-comment-form [parentId]="comment.id" [groupId]="groupId" [postId2]="comment.post_id" [categoryId]="comment.category_id" (onSubmit)="onCommentCreate()" *ngIf="showform">

    </single-comment-form>
</section>