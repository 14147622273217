export class Feedback {
  
    name: any;
    email: any;
    member_id: any;
    feedback: any;
    attachment:any;
    client_id:any;
   
    constructor() {}
  }