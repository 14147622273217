import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import jwt_decode from "jwt-decode";
import { Register } from '../modals/regsiter';
import { Feedback } from '../modals/feedback';
import { PublicProfileService } from './public-profile.service';
import { Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { MemberService } from '../member/member.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.css']
})
export class PublicProfileComponent implements OnInit,AfterViewInit {
  token: string;
  tokenID: any;
  userInfo: Register;
  public: any;
  param: any;
  follows= false;
  member_id='';
  @ViewChild(LoginComponent)login: LoginComponent;
  @ViewChild('follow')follow: ElementRef;
  @ViewChild('connection')connection: ElementRef;
  @ViewChild('openfeedback')openfeedback: ElementRef;
  @ViewChild('closefeedback')closefeedback: ElementRef;

  // closefeedback
  mclass= 'btn btn-primary';
  mcconnection= 'btn btn-primary float-right';
  feedback: any;
  feedimg: File;
  feedimgs: string | ArrayBuffer;
  length: any;
  qualification: any;
  sameuser= false;
  constructor(public api: ApiService,private ngxService: NgxUiLoaderService,public public_profileapi: PublicProfileService,private toast: ToastrService,public memberapi:MemberService,public router: Router, ) {
    this.userInfo = new Register();
    this.feedback = new Feedback();
    var pathArray = window.location.pathname.split('/');
    this.param = pathArray[1]
    console.log(pathArray[1],"this.param")
    // if(this.api.loggedIn){


    //   this.token =  localStorage.getItem('USERID')
    //   this.tokenID = jwt_decode(this.token)
    //   this.userInfo =this.tokenID.userdata
     
    //  }
   }

  ngOnInit(): void {
    
    // this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId
    // // Stop the foreground loading after 5s
    // setTimeout(() => {
    //   this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
    // }, 5000);
    this.getpublic_profile();

   

    // OR
    // this.ngxService.startBackground("do-background-things");
    // // Do something here...
    // this.ngxService.stopBackground("do-background-things");

    // this.ngxService.startLoader("loader-01"); // start foreground spinner of the loader "loader-01" with 'default' taskId
    // // Stop the foreground loading after 5s
    // setTimeout(() => {
    //   this.ngxService.stopLoader("loader-01"); // stop foreground spinner of the loader "loader-01" with 'default' taskId
    // }, 5000)
  }
  getpublic_profile(){
    let obj = {
      slug:this.param,
      member_id: localStorage.getItem('USERID')?this.memberapi.userInfoapi.member_id:''
    }
   console.log(obj,"obj rrrr")
  
    this.public_profileapi.post('publicProfile',obj).subscribe((res2: any) => {
      console.log(res2,"this.public")
      this.public = res2.profile
      if(localStorage.getItem('USERID')&&res2.profile){
        if(this.memberapi.userInfoapi.member_id !=res2.profile.member_id){
this.sameuser = true
        }
        
      } else if(!localStorage.getItem('USERID')){
        this.sameuser = true
      }
     this.length= this.public.qualification.length
     this.qualification = this.public.qualification.slice(-1)
     console.log(this.qualification,"this.qualification")
      // if(this.public.follow==1){
      //   this.follow.nativeElement.innerText = 'UnFollow';
      //   this.mclass='btn btn-success'
      // }
      console.log(res2,"this.public")
     
     
     
 
      
    })

  }

followrequest(){
  if(this.api.loggedIn())
  {
   console.log(this.public.member_id,"this.public.member_id")
    const formData = new FormData();

   formData.append('to_member_id', this.public.member_id);

  this.public_profileapi.postdata('followMember', formData).subscribe((res: any) => {
    if(res.error==false){
     
      this.getpublic_profile();

     
     
      // btn btn-success
    }

    console.log(res,"follow request")
  })}else{
    this.login.openLogin();

  }
}
unfollowrequest(){
  const formData = new FormData();

   formData.append('to_member_id', this.public.member_id);

  this.public_profileapi.postdata('unfollowMember', formData).subscribe((res: any) => {
    console.log(res,"unfollow")
    this.getpublic_profile();
  })
}

connectionrequest(){
if(this.api.loggedIn())
 { const formData = new FormData();

  formData.append('receiver_id', this.public.member_id);

 this.public_profileapi.postdata('sendConnectionRequest', formData).subscribe((res: any) => {
console.log(res,"connection")
if(res.error==false){
  this.mcconnection='btn btn-success'
  this.connection.nativeElement.innerText = 'Requested';
}

 })
}else{
  this.login.openLogin();
}
}

openfeedbacks(){
  
  this.openfeedback.nativeElement.click()
}

handleFileInput(files: FileList) {
  
  this.feedimg = files.item(0);
  console.log(this.feedimg)



  const reader = new FileReader();


 
  reader.readAsDataURL(this.feedimg);
  reader.onload = e => this.feedimgs = (reader.result);



}

savefeedback(){
  if(!this.feedback.name){
    this.toast.error("name required")

  }else if(!this.feedback.email){
    this.toast.error("Email required")

  }else if(!this.feedback.feedback){
    this.toast.error("Feed Back Required")

  }else
{ console.log(this.feedimg,"this.feedimg")
  const formData = new FormData();
  formData.append('name', this.feedback.name);
  formData.append('email', this.feedback.email);
  formData.append('member_id', this.public.member_id);
  formData.append('attachment', this.feedimg);
  formData.append('feedback', this.feedback.feedback);
  formData.append('client_id', localStorage.getItem('USERID')?this.memberapi.userInfoapi.member_id:'');
  // this.feedimg
  this.public_profileapi.post('feedback',formData).subscribe((res2: any) => {

console.log(res2,"res2")
if(res2.error==false){
  this.closefeedback.nativeElement.click()
}
  })}

}


ngAfterViewInit(){
  this.ngxService.stop();
}
}
