import { Component, OnInit, Output,EventEmitter,ElementRef,ViewChild } from '@angular/core';
import { FormBuilder,FormGroup,FormControl,Validators,ValidationErrors} from '@angular/forms';
import { ApiService } from '../../api.service';
import { LoginComponent } from '../../login/login.component';
import { MemberService } from '../../member/member.service';
import { NeedALawyerService } from '../need-a-lawyer.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommentService } from '../../comment/comment.service';
import { EventService } from '../../event/event.service';
import { ToastrService } from 'ngx-toastr';
import { Editor } from 'ngx-editor';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
 

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css'],
 
})
export class ViewComponent implements OnInit {
  closeResult: string;
  // postId: news[] = [];
  // news: News;
  news: any;
  comments: Comment[];
  userInfo: any;
  category_id = 2;
  postId:number;
  publish_date:any;
  type:any;
  Limit:Event[]=[];
  page: number = 1;
  count: number = 0;
  tableSize: number = 3;
  tableSizes: any = [3, 6, 9, 12];
  case:any;
  case_reply:any=[];
  token: string;
  t_id:any;
  listcaseInfo:any;
  findAlawyer = true;
  area: any;
  keyword = 'name';
  submitted = false;
  closeModal: string;
  member_id: any; 
  message: string;
  liketoquotefee = false;
  email:any;
  username:any;
  contact_no:any;
  listofsaveCase:any;
  editor: Editor;  
  profiledata:any;
  queryBoardList:any;


  @ViewChild('openReplyposterModal') openReplyposterModal: ElementRef;
  @ViewChild('closeReplyposter') closeReplyposter: ElementRef;
  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  @ViewChild('openLoginModal')loginModal: ElementRef; 
  @Output() loginEvent = new EventEmitter<string>();
  NeedALawyermyForm: any;
  transform(val:string , length:number):string {
    return val.length > length ? `${val.substring(0, length)} ...` : val
  }

  constructor(private route: ActivatedRoute,public needALayerapi: NeedALawyerService,public memberapi: MemberService, private router: Router,public eventService:EventService,public api: ApiService, private toast: ToastrService,public commentService: CommentService, private modalService: NgbModal) {
    if(localStorage.getItem('USERID')){

    this.token =  localStorage.getItem('USERID');
    
    this.memberapi.getdata('profile').subscribe((res2: any) => {
      this.profiledata = res2.data;    
      this.member_id = this.profiledata.member_id;    
      this.email = this.profiledata.email;
      this.contact_no = this.profiledata.contact_num;
      this.username = this.profiledata.full_name;
      console.log(this.profiledata,"this.profiledata")
      
    })
   }
   if(this.api.loggedIn()==false&&localStorage.getItem('USERID'))
   {
   this.refresh();
   }
}
refresh() {
  if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
    localStorage.clear();
    window.location.reload();
  }
}

openLogin() {
  this.loginModal.nativeElement.click()
}

  ngOnInit(): void {   
    this.editor = new Editor();  
    this.get_query_board_list();  
    var topic_id = this.route.snapshot.paramMap.get('topic_id');
    // var type=this.route.snapshot.paramMap.get('Type');
    // this.findNews(topic_id);
    this.findcase(topic_id);
    this.find_case_reply(topic_id);
    this.getComments(topic_id, this.category_id);
    this.limit();
    
  }


  getComments(post_id, category_id) {
    this.commentService.getAll(post_id, category_id).subscribe((com: Comment[]) => {
      this.comments = com;
      console.log("Comments..:::", this.comments);

    });
  }

  onCommentCreate() {
    this.getComments(this.news.id, this.category_id);
    console.log('news creayed');
  }
  onComment() {
    this.getComments(this.news.id, this.category_id);
    console.log('news creayed');
  }



 

  getLocation(news_id){
    // return location.href+'/'+news_id;
   return  "https://stackoverflow.com"+'/'+news_id;
  }
  isReadMore = false;

  showText() {
    this.isReadMore = !this.isReadMore
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getComments;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getComments;
  }
 limit(){
  this.eventService.limit().subscribe((com:Event[]) => {
    this.Limit = com;
    console.log("Comments..:::", this.comments);

  });
 }


 findcase(topic_id) {
    this.needALayerapi.find_case(topic_id).subscribe((res: any) => {   
    this.case = res[0];

    this.t_id= topic_id;
    console.log("case list",this.case)   
  });

}
find_case_reply(topic_id) {
this.needALayerapi.find_case_reply(topic_id).subscribe((res: any) => {   
  this.case_reply = res;    
});
}

dateformatter(val:any,type:any) {
  if(val){
    const dateformat = new Date(val);
    var  datetimestamp=dateformat.getTime()/1000;
    this.NeedALawyermyForm.controls[type].setValue(datetimestamp);
     
  }
}

// reply to this poster

ReplyPoster = new FormGroup({
  topic_id: new FormControl(''),
  answer: new FormControl('',Validators.required),
  member_id: new FormControl(''),
  case_quote_type: new FormControl(''),
  case_quote_fee: new FormControl(''),
  casetype: new FormControl(''),
  });

  get posterForm() {
    return this.ReplyPoster.controls;

  }
openreplyposter(topic_id:any) {

  if(this.token && this.member_id){
    this.message='';
    this.ReplyPoster.controls['topic_id'].setValue(topic_id, {
      onlySelf: true
    })
    this.ReplyPoster.controls['member_id'].setValue(this.member_id, {
      onlySelf: true
    })
    this.openReplyposterModal.nativeElement.click()
  }else{
    this.loginPrev.openLogin();
  }
}


quoteFee(event:any){
  if(event.target.checked){
    this.ReplyPoster.controls['case_quote_type'].setValue("1", {
      onlySelf: true
    })
    this.ReplyPoster.get('case_quote_fee').setValidators(Validators.required);
    this.ReplyPoster.get('case_quote_fee').updateValueAndValidity();
    this.liketoquotefee = true;
  }else{
    this.ReplyPoster.controls['case_quote_type'].setValue("", {
      onlySelf: true
    })  
    this.ReplyPoster.get('case_quote_fee').clearValidators();  
    this.ReplyPoster.get('case_quote_fee').updateValueAndValidity();
    this.liketoquotefee = false;
  }
}

onSubmit(topic_id) {
 
  console.log(this.ReplyPoster);
  this.ReplyPoster.markAllAsTouched();
    if (this.ReplyPoster.valid) {
      console.log(this.ReplyPoster);
      this.needALayerapi.replytoPoster('replytoPoster', this.ReplyPoster.value).subscribe((res2: any) => {
        // status
        console.log(res2.status);
        if (res2.status=="200") {
          setTimeout(() => {
            this.ReplyPoster.reset();
            this.closeReplyposter.nativeElement.click();
            this.liketoquotefee = false;
            this.toast.success('Notification successfully send to client');
          },1);
          this.find_case_reply(topic_id);
          window.location.reload();
        } else {
          this.message = " Some Thing went Wrong"
        }
      
      })
    }
}

 //close popup 
 posterReset(){
  this.ReplyPoster.reset();
}
 // Save Case
  
 saveCase(topic_id:any) {
  if(this.token){
    this.listofsaveCase = {
      "topic_id" :topic_id,
      "member_id" :this.member_id
    }
    this.needALayerapi.replytoPoster('saveCase', this.listofsaveCase).subscribe((res2: any) => {
      // status
      console.log(res2.status);
      if (res2.status=="200") {
        setTimeout(() => {
          this.ReplyPoster.reset();
          this.closeReplyposter.nativeElement.click();
          this.liketoquotefee = false;
          this.toast.success('Notification successfully send to client');
        }, 2000);
      } else {
        this.message = " Some Thing went Wrong"
      }

    })
      // this.openReplyposterModal.nativeElement.click()
  }else{
    this.loginPrev.openLogin();
  }
}

 
loginSubmit(event:any) {
   
}

public setValidation(e: any) {
  const val = e.target.value;
  this.NeedALawyermyForm.controls['casetype'].setValue(e.target.value, {
    onlySelf: true
  })
}



get_query_board_list()
{
  this.needALayerapi.query_board_list().subscribe((res: any) => 
  {
      this.queryBoardList=res.profile;
    

  });
}








}

