<main role="main">
    <section class="ipad-hide phone-hide">
        <div class="container-fluid inner-title">
            <div class="row">
                <div class="col-md-1 pl-0 text-right"><span>NEWS</span></div>
                <div class="col-md-10">Delhi HC directs Singh Brothers to Maintain Status-quo over Assets & Properties | Delhi HC directs...</div>
                <div class="col-md-1 pr-0 text-left"><span>NEWS</span></div>
            </div>
        </div>
    </section>

    <seciton class="top-banner">
        <div class="container">
            <div class="row">
                <div class="col-md-9 col-sm-6">
                    <div class="component-box-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>Knowledge is Power. Knowledge Shared is Power Multiplied!</h4>
                                <div class="user-info-box-inner no-scroll eventSlider">
                                    <div class="text-left">
                                        <div class="m-0 p-0 text-left">
                                            <p>
                                                SoOLEGAL offers you an extraordinary opportunity to enhance your reputation and reach by hosting an online event. Perhaps even earn some money in the bargain!
                                            </p>



                                            <a href="#" class="btn btn-primary rounded btn-lg right" data-toggle="modal" data-target="#findLawer">Add Event</a>
                                        </div>
                                    </div>

                                    <div class="text-left">
                                        <div class="m-0 p-0 text-left">
                                            <p>
                                                Want to host or organize an event? No time for figuring out the technology or the logistics? SoOLEGAL’s proprietary tool can help. From promotions and handling registrations, to increasing attendee engagement and post event analysis, we have it all!
                                            </p>

                                            <p>
                                                Focus on your Content. Leave the Rest to Us.
                                            </p>

                                            <a href="#" class="btn btn-primary rounded btn-lg right" data-toggle="modal" data-target="#findLawer">Add Event</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="component-box full-gray">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <img src="../../assets/images/post-requirmentsss.jpg" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </seciton>

    <section class="filter">
        <div class="container">
            <div class="h-20"></div>

            <div class="row">
                <div class="col-md-9 col-sm-6">
                    <h4>Upcoming Events</h4>
                    <div class="filter-tags flex-center">
                        <div class="pretty-setting">
                            <select id="option-size" class="custom-control">
                 <option value="Gurgaon" selected=""> Gurgaon </option>
           
                 <option value="New Delhi">New Delhi </option>
           
                 <option value="Jaipur">Jaipur </option>
           
                 <option value="Mumbai">Mumbai </option>
           
                 <option value="Chennai">Chennai </option>
           
                 <option value="Bangalore">Bangalore </option>
           
                 <option value="Lucknow">Lucknow </option>
               </select>
                            <span class="text"><span class="label"></span><span class="value">Select City</span></span>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="#ccc" xmlns="http://www.w3.org/2000/svg">
                 <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
               </svg>
                        </div>

                        <div class="pretty-setting">
                            <select id="option-size" class="custom-control">
                 <option value="January" selected="">January</option>
                 <option value="February">February</option>
           
                 <option value="February">February</option>
           
                 <option value="March">March</option>
                 <option value="March">March</option>
                 <option value="April">April</option>
                 <option value="May">May</option>
                 <option value="June">June</option>
                 <option value="July">July</option>
               </select>
                            <span class="text"><span class="label"></span><span class="value">Select Month</span></span>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="#ccc" xmlns="http://www.w3.org/2000/svg">
                 <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
               </svg>
                        </div>

                        <div class="pretty-setting">
                            <select id="option-size" class="custom-control">
                 <option value="2020" selected="">2020</option>
                 <option value="2019">2019</option>
                 <option value="2018">2018</option>
                 <option value="2017">2017</option>
                 <option value="2016">2016</option>
                 <option value="2015">2015</option>
                 <option value="2014">2014</option>
               </select>
                            <span class="text"><span class="label"></span><span class="value">Select Year</span></span>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="#ccc" xmlns="http://www.w3.org/2000/svg">
                 <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
               </svg>
                        </div>

                        <div class="pretty-setting">
                            <select id="option-size" class="custom-control">
                 <option value="Online Seminar" selected=""> Online Seminar</option>
                 <option value="Legal Matters Symposium">Legal Matters Symposium</option>
                 <option value="2019 Legal Expo">2019 Legal Expo</option>
                 <option value="Education & Learning">Education & Learning</option>
                 <option value="Mock Courts">Mock Courts</option>
                 <option value="Legal Podcast">Legal Podcast</option>
                 <option value="SoOLegal Confidential">SoOLegal Confidential</option>
               </select>
                            <span class="text"><span class="label"></span><span class="value">Event Type</span></span>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="#ccc" xmlns="http://www.w3.org/2000/svg">
                 <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
               </svg>
                        </div>

                        <a href="#" class="btn btn-primary btn-filter flex-center text-center">Sort</a>
                    </div>


                    <div class="events-details">
                        <article class="row">
                            <div class="col-md-5">
                                <div class="wrapper">
                                    <h3 class="title">SoOLegal Knowledge Series</h3>
                                    <p>5 Week Professional Training In The Comfort Of Your Home- taught by Adv. Avani Bansal</p>
                                    <p class="small link">Professional Training, Online Learning, Course, Litigation, Certificate, Advocate</p>
                                </div>
                            </div>
                            <div class="col col-md-4 offset-md-1">
                                <div class="wrapper"></div>
                                <h3 class="title">Aug. 28, 2020 to Sept. 25, 2020</h3>
                                <p class="small link">Online Educational Series</p>
                            </div>
                            <div class="col col-md-2 text-right">
                                <div class="wrapper"></div>
                                <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                            </div>
                        </article>

                        <article class="row">
                            <div class="col-md-5">
                                <div class="wrapper">
                                    <h3 class="title">SoOLegal Knowledge Series</h3>
                                    <p>5 Week Professional Training In The Comfort Of Your Home- taught by Adv. Avani Bansal</p>
                                    <p class="small link">Professional Training, Online Learning, Course, Litigation, Certificate, Advocate</p>
                                </div>
                            </div>
                            <div class="col col-md-4 offset-md-1">
                                <div class="wrapper"></div>
                                <h3 class="title">Aug. 28, 2020 to Sept. 25, 2020</h3>
                                <p class="small link">Online Educational Series</p>
                            </div>
                            <div class="col col-md-2 text-right">
                                <div class="wrapper"></div>
                                <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                            </div>
                        </article>

                        <article class="row">
                            <div class="col-md-5">
                                <div class="wrapper">
                                    <h3 class="title">SoOLegal Knowledge Series</h3>
                                    <p>5 Week Professional Training In The Comfort Of Your Home- taught by Adv. Avani Bansal</p>
                                    <p class="small link">Professional Training, Online Learning, Course, Litigation, Certificate, Advocate</p>
                                </div>
                            </div>
                            <div class="col col-md-4 offset-md-1">
                                <div class="wrapper"></div>
                                <h3 class="title">Aug. 28, 2020 to Sept. 25, 2020</h3>
                                <p class="small link">Online Educational Series</p>
                            </div>
                            <div class="col col-md-2 text-right">
                                <div class="wrapper"></div>
                                <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                            </div>
                        </article>

                        <article class="row">
                            <div class="col-md-5">
                                <div class="wrapper">
                                    <h3 class="title">SoOLegal Knowledge Series</h3>
                                    <p>5 Week Professional Training In The Comfort Of Your Home- taught by Adv. Avani Bansal</p>
                                    <p class="small link">Professional Training, Online Learning, Course, Litigation, Certificate, Advocate</p>
                                </div>
                            </div>
                            <div class="col col-md-4 offset-md-1">
                                <div class="wrapper"></div>
                                <h3 class="title">Aug. 28, 2020 to Sept. 25, 2020</h3>
                                <p class="small link">Online Educational Series</p>
                            </div>
                            <div class="col col-md-2 text-right">
                                <div class="wrapper"></div>
                                <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                            </div>
                        </article>

                        <article class="row">
                            <div class="col-md-5">
                                <div class="wrapper">
                                    <h3 class="title">SoOLegal Knowledge Series</h3>
                                    <p>5 Week Professional Training In The Comfort Of Your Home- taught by Adv. Avani Bansal</p>
                                    <p class="small link">Professional Training, Online Learning, Course, Litigation, Certificate, Advocate</p>
                                </div>
                            </div>
                            <div class="col col-md-4 offset-md-1">
                                <div class="wrapper"></div>
                                <h3 class="title">Aug. 28, 2020 to Sept. 25, 2020</h3>
                                <p class="small link">Online Educational Series</p>
                            </div>
                            <div class="col col-md-2 text-right">
                                <div class="wrapper"></div>
                                <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                            </div>
                        </article>

                        <article class="row">
                            <div class="col-md-5">
                                <div class="wrapper">
                                    <h3 class="title">SoOLegal Knowledge Series</h3>
                                    <p>5 Week Professional Training In The Comfort Of Your Home- taught by Adv. Avani Bansal</p>
                                    <p class="small link">Professional Training, Online Learning, Course, Litigation, Certificate, Advocate</p>
                                </div>
                            </div>
                            <div class="col col-md-4 offset-md-1">
                                <div class="wrapper"></div>
                                <h3 class="title">Aug. 28, 2020 to Sept. 25, 2020</h3>
                                <p class="small link">Online Educational Series</p>
                            </div>
                            <div class="col col-md-2 text-right">
                                <div class="wrapper"></div>
                                <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                            </div>
                        </article>

                        <article class="row">
                            <div class="col-md-5">
                                <div class="wrapper">
                                    <h3 class="title">SoOLegal Knowledge Series</h3>
                                    <p>5 Week Professional Training In The Comfort Of Your Home- taught by Adv. Avani Bansal</p>
                                    <p class="small link">Professional Training, Online Learning, Course, Litigation, Certificate, Advocate</p>
                                </div>
                            </div>
                            <div class="col col-md-4 offset-md-1">
                                <div class="wrapper"></div>
                                <h3 class="title">Aug. 28, 2020 to Sept. 25, 2020</h3>
                                <p class="small link">Online Educational Series</p>
                            </div>
                            <div class="col col-md-2 text-right">
                                <div class="wrapper"></div>
                                <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                            </div>
                        </article>

                        <article class="row">
                            <div class="col-md-5">
                                <div class="wrapper">
                                    <h3 class="title">SoOLegal Knowledge Series</h3>
                                    <p>5 Week Professional Training In The Comfort Of Your Home- taught by Adv. Avani Bansal</p>
                                    <p class="small link">Professional Training, Online Learning, Course, Litigation, Certificate, Advocate</p>
                                </div>
                            </div>
                            <div class="col col-md-4 offset-md-1">
                                <div class="wrapper"></div>
                                <h3 class="title">Aug. 28, 2020 to Sept. 25, 2020</h3>
                                <p class="small link">Online Educational Series</p>
                            </div>
                            <div class="col col-md-2 text-right">
                                <div class="wrapper"></div>
                                <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                            </div>
                        </article>

                        <article class="row">
                            <div class="col-md-5">
                                <div class="wrapper">
                                    <h3 class="title">SoOLegal Knowledge Series</h3>
                                    <p>5 Week Professional Training In The Comfort Of Your Home- taught by Adv. Avani Bansal</p>
                                    <p class="small link">Professional Training, Online Learning, Course, Litigation, Certificate, Advocate</p>
                                </div>
                            </div>
                            <div class="col col-md-4 offset-md-1">
                                <div class="wrapper"></div>
                                <h3 class="title">Aug. 28, 2020 to Sept. 25, 2020</h3>
                                <p class="small link">Online Educational Series</p>
                            </div>
                            <div class="col col-md-2 text-right">
                                <div class="wrapper"></div>
                                <h3 class="title"><a href="#" class="link">Learn More</a></h3>
                            </div>
                        </article>
                    </div>
                </div>

                <div class="col-sm-6 col-md-3">
                    <h4>View Past Events/videos</h4>
                    <div class="top-arrow">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
              </svg>
                    </div>

                    <div class="box-slider-1" id="sidebar">
                        <div class="component-box-1">
                            <div class="user-info-box-main">
                                <div class="user-info-box">
                                    <h4>Online Webinar (August 2020)</h4>
                                    <div class="user-info-box-inner p-0 no-scroll min-height">
                                        <div class="user-info-box">
                                            <div class="bg-white">
                                                <img src="../../assets/images/pasted-image.jpg" class="img-fluid" />
                                            </div>
                                            <div class="box-wrapper">
                                                <p>
                                                    Global Pandemic of Domestic Violence: Best Practices & Legal Solutions for Organizations and Survivors.
                                                </p>
                                                <a class="btn btn-primary">Watch Video</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="component-box-1">
                            <div class="user-info-box-main">
                                <div class="user-info-box">
                                    <h4>Online Webinar (August 2020)</h4>
                                    <div class="user-info-box-inner p-0 no-scroll min-height">
                                        <div class="user-info-box">
                                            <div class="bg-white">
                                                <img src="../../assets/images/pasted-image.jpg" class="img-fluid" />
                                            </div>
                                            <div class="box-wrapper">
                                                <p>
                                                    Global Pandemic of Domestic Violence: Best Practices & Legal Solutions for Organizations and Survivors.
                                                </p>
                                                <a class="btn btn-primary">Watch Video</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="component-box-1">
                            <div class="user-info-box-main">
                                <div class="user-info-box">
                                    <h4>Online Webinar (August 2020)</h4>
                                    <div class="user-info-box-inner p-0 no-scroll min-height">
                                        <div class="user-info-box">
                                            <div class="bg-white">
                                                <img src="../../assets/images/pasted-image.jpg" class="img-fluid" />
                                            </div>
                                            <div class="box-wrapper">
                                                <p>
                                                    Global Pandemic of Domestic Violence: Best Practices & Legal Solutions for Organizations and Survivors.
                                                </p>
                                                <a class="btn btn-primary">Watch Video</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="component-box-1">
                            <div class="user-info-box-main">
                                <div class="user-info-box">
                                    <h4>Online Webinar (August 2020)</h4>
                                    <div class="user-info-box-inner p-0 no-scroll min-height">
                                        <div class="user-info-box">
                                            <div class="bg-white">
                                                <img src="../../assets/images/pasted-image.jpg" class="img-fluid" />
                                            </div>
                                            <div class="box-wrapper">
                                                <p>
                                                    Global Pandemic of Domestic Violence: Best Practices & Legal Solutions for Organizations and Survivors.
                                                </p>
                                                <a class="btn btn-primary">Watch Video</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="component-box-1">
                            <div class="user-info-box-main">
                                <div class="user-info-box">
                                    <h4>Online Webinar (August 2020)</h4>
                                    <div class="user-info-box-inner p-0 no-scroll min-height">
                                        <div class="user-info-box">
                                            <div class="bg-white">
                                                <img src="../../assets/images/pasted-image.jpg" class="img-fluid" />
                                            </div>
                                            <div class="box-wrapper">
                                                <p>
                                                    Global Pandemic of Domestic Violence: Best Practices & Legal Solutions for Organizations and Survivors.
                                                </p>
                                                <a class="btn btn-primary">Watch Video</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="component-box-1">
                            <div class="user-info-box-main">
                                <div class="user-info-box">
                                    <h4>Online Webinar (August 2020)</h4>
                                    <div class="user-info-box-inner p-0 no-scroll min-height">
                                        <div class="user-info-box">
                                            <div class="bg-white">
                                                <img src="../../assets/images/pasted-image.jpg" class="img-fluid" />
                                            </div>
                                            <div class="box-wrapper">
                                                <p>
                                                    Global Pandemic of Domestic Violence: Best Practices & Legal Solutions for Organizations and Survivors.
                                                </p>
                                                <a class="btn btn-primary">Watch Video</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-arrow">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-down-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
              </svg>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="h-25 d-inline-block"></div>
    <section class="title-bg-2 my-4 ipad-hide phone-hide">
        <div class="container">
            <div class="row">
                <div class="col-md-2 orange-bg-new order-md-12">
                    <div class="triangle-bottomleft"></div>
                    <div class="icon-box"></div>
                </div>
                <div class="col-md-10 white-bg-new">
                    <div class="triangle-topleft"></div>
                    <span class="gray-bg">Go Premium</span>
                    <h3>SoOLEGAL is recommended by our valued members and users</h3>
                </div>
            </div>
        </div>
    </section>

    <section class="profile-slider">
        <div class="container">
            <div class="events owl-carousel owl-theme">



                <div>
                    <div class="component-box-1" style="width: 100%; display: inline-block;">
                        <div class="user-info-box-main">
                            <div class="user-info-box">
                                <h4>Online Webinar (August 2020)</h4>
                                <div class="user-info-box-inner p-0 no-scroll min-height">
                                    <div class="user-info-box">
                                        <div class="bg-white">
                                            <img src="../../assets/images/pasted-image.jpg" class="img-fluid">
                                        </div>
                                        <div class="box-wrapper">
                                            <p>
                                                Global Pandemic of Domestic Violence: Best Practices &amp; Legal Solutions for Organizations and Survivors.
                                            </p>
                                            <a class="btn btn-primary" tabindex="0">Watch Video</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </section>
    <section class="partners-main-box my-3">
        <div class="container-fluid p-0">
            <div class="bg-gray-new shadow-none">
                <div class="row justify-content-between align-items-center">
                    <div class="col-md-12 col-lg-5 my-2 logo-box">
                        <img src="../../assets/images/logo.png" alt="" class="im" />
                        <h4 class="title-1">Partner Services</h4>
                    </div>
                    <div class="col-md-12 col-lg-7 my-2">
                        <div class="partners owl-carousel owl-theme owl-loaded owl-drag">
                            <div class="owl-stage-outer">
                                <div class="owl-stage" style="transform: translate3d(-537px, 0px, 0px); transition: all 0.25s ease 0s; width: 1255px;">
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                    <div class="owl-item active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned active" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                                        </div>
                                    </div>
                                    <div class="owl-item cloned" style="width: 59.617px; margin-right: 30px;">
                                        <div class="item">
                                            <a href="#"><img src="../../assets/images/partners/partner-2.png" alt="partners" /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-nav disabled">
                                <button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>
                            </div>
                            <div class="owl-dots disabled"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>