import { Component, OnInit,ViewChild,ElementRef,TemplateRef} from '@angular/core';
import { EventService } from '../event.service';
import { MemberService } from '../../member/member.service';
import { ApiService } from '../../api.service';
import { LoginComponent } from '../../login/login.component';
import { Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators, } from '@angular/forms';
import{EventregComponent} from '../../eventreg/eventreg.component';
import { EventregService } from '../../eventreg/eventreg.service';
import { DatePipe } from '@angular/common';
import e from 'express';

@Component({
  selector: 'app-index-event',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  
  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter();

  page: number = 1;
  count: number = 0;
  tableSize: number = 8;
  tableSizes: any = [3, 6, 9, 12];

  closeModal: string;
  event:Event[]=[];
event_id:any;
id: number;

select_month:any;
venue_details: any;
 start_date='';
end_date='';
selectedYear: number;
years: number[] = [];
eventImage: any={};
existingEvent:any;
sort_venuedetails={};
sort_eventcategory={};
closeResult: string;
modalReference:any;


token:string;
  constructor(private formBuilder: FormBuilder,private modalService: NgbModal,private route: ActivatedRoute,public eventService: EventService,private router: Router, public memberapi: MemberService,public api: ApiService, public mainapi: ApiService,public eventregService: EventregService) {
    if(localStorage.getItem('USERID')){


      this.token =  localStorage.getItem('USERID')
     }
     if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
     this.refresh();
     }
  
   }
  
  @ViewChild(LoginComponent)logrefinPrev: LoginComponent;
  @ViewChild('openLoginModal')loginModal: ElementRef; 
  @Output() loginEvent = new EventEmitter<string>();
  @ViewChild('modalData')
  private modalData:TemplateRef<any>;
  // @ViewChild('myModalClose') private myModalClose: ElementRef;
  ngOnInit(): void {
    this.getEvent('','','','','','','');
    this.sorting_venuedetails();
    this.sorting_eventcategory();
    var eventid = this.route.snapshot.paramMap.get('eventId');
    if (eventid) {
      this.findEvent(eventid);
    }
      this.myform = new FormGroup({
        title: new FormControl('',Validators.required),
        banner: new FormControl('',eventid?null:Validators.required),
        venue_details: new FormControl('',Validators.required),
        description: new FormControl('',Validators.required),
        start_date: new FormControl('',Validators.required),
        end_date:new FormControl('',Validators.required),
        tags:new FormControl('',Validators.required),
        event_category:new FormControl('',Validators.required),
        amount:new FormControl(''),
        paidtype:new FormControl(''),
        event_author_id:new FormControl(''),
        id:new FormControl(''),
       
      });
    // if (eventid) {
    //   this.findEvent(eventid);
    // }
  }






  myform:FormGroup;
  

  
  
  
    get f() {
      return this.myform.controls;
  
    }
    onFileChange(event) {
      this.eventImage = event.target.files[0];
    }
    registration(e) {
      console.log(e.target.value);
    }
    submitted=false;
    submit() {
     
      
      this.submitted = true;
      if (this.myform.valid) {
    
        // this.myModalClose.nativeElement.click();
      const formData = new FormData();
      if (this.existingEvent?.id) {
        formData.append("event_id", this.myform.get('id').value);
      }      
      formData.append("event_banner", this.eventImage);
      formData.append('event_title', this.myform.get('title').value);
      formData.append("event_banner", this.myform.get('banner').value);
      formData.append('event_venue_details', this.myform.get('venue_details').value);
      formData.append('event_description', this.myform.get('description').value);
      formData.append('event_start_date', this.myform.get('start_date').value);
      formData.append('event_end_date', this.myform.get('end_date').value);
      formData.append('event_tags', this.myform.get('tags').value);
      formData.append('event_category', this.myform.get('event_category').value);
      formData.append('event_amount', this.myform.get('amount').value);
      formData.append('event_paidtype', this.myform.get('paidtype').value);
      // console.log(this.myform.get('amount').value);
      this.eventService.create(formData).subscribe((res: any) => {
        // console.log("Event Created..:::", res.event);
        // formData.forEach((value,key) => {
        //     console.log(key+" "+value)
        //   });
      });
      this.modalReference.close();
  this.myform.reset();
  this.getEvent;
  // this.router.navigate(['/event']);
  window.location.reload();

  
    }
    
    }
    findEvent(eventId) {
      let datepipe = new DatePipe('en-US');
      let datepipe1 = new DatePipe('en-US');
      this.eventService.find(eventId).subscribe((res: any) => {
        this.existingEvent = res.messages.feed_list[0];
        console.log('jiiiiiiiiiiii',eventId)
        
        this.myform.patchValue({
          event_author_id: this.existingEvent.event_author_id,
          id: this.existingEvent.id,
          title: this.existingEvent.event_title,
          start_date: datepipe.transform(this.existingEvent.event_start_date*1000, 'yyyy-MM-dd'),
          end_date: datepipe1.transform(this.existingEvent.event_end_date*1000, 'yyyy-MM-dd'),
          venue_details: this.existingEvent.event_venue_details,
          description: this.existingEvent.event_description,
          tags: this.existingEvent.event_tags,
          event_category: this.existingEvent.event_category,
          paidtype:this.existingEvent.event_paidtype,
          amount:this.existingEvent.event_amount,
          banner: this.existingEvent.event_banner,
         
        
        });
        this.modalReference= this.modalService.open(this.modalData, {ariaLabelledBy: 'modal-basic-title'});
    //  this.modalService.open(this.modalData)
      });
    }
   
    click(){
      if(this.token){
        
        this.router.navigate(['/event/add']);
      
      }else{
        // this.loginPrev.openLogin();
       
  
      }
    }
    refresh() {
      if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
      localStorage.clear();
     
      }
    }
    openLogin() {
      this.loginModal.nativeElement.click()
    }
    loginSubmit(event:any) {
      }
  


  
  getEvent(eventId,venue_details,start_date,end_date,event_category,event_type,limit) {
    console.log('getting Event');
    console.log(start_date);
    console.log(end_date);
    this.eventService.getAll(eventId,venue_details,start_date,end_date,event_category,event_type,0).subscribe((res: any) => {
      this.event = res;
    });
    console.log('getting Event',this.event);
  }
  sorting_venuedetails(){
    this.eventService.sorting().subscribe((res: any) => {
      this.sorting_venuedetails = res; 
    });
  }
  sorting_eventcategory(){
    this.eventService.sort_eventcategory().subscribe((res: any) => {
      this.sorting_eventcategory = res; 
    });
  }
  deleteEvent(event_id) {
    this.eventService.delete(event_id).subscribe((res:any) => {
      this.event = res; 
    });
  }

  triggerModal(content) {
    // if(this.token){
   this.modalReference= this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
   this.modalReference.result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
     
      // this.getEvent('','','','','','','');
      // this.router.navigate(['/event']);
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
     
    });
  // }
   
  }
  
  private getDismissReason(reason: any): string {
    
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
     
    }
   
  }

  myForm = new FormGroup({
    select_city: new FormControl(''),
    select_month: new FormControl(''),
    select_year: new FormControl(''),
    event_category: new FormControl(''),
    event_type: new FormControl(''),
    });

 

     months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "Octber", "November", "December"];
     currentYear: number = new Date().getFullYear();
  sort(){
  
  var start_date:any='';
  var end_date:any='';
    if(this.myForm.get('select_month').value  ){
    var month1 = this.months.indexOf(this.myForm.get('select_month').value);
    var year= this.currentYear ;
  
   if(month1 >=0 ) {

    let startdate = new Date(year, month1, 1);
    let enddate = new Date(year, month1, 1);
    enddate.setMonth(enddate.getMonth() + 1);
    enddate.setDate(enddate.getDate() - 1);
    start_date=startdate.getTime()/1000;
    end_date=enddate.getTime()/1000;

   } else {
    let startdate = new Date(year, 0, 1);
    let enddate = new Date(year, 11, 31);
    start_date=startdate.getTime()/1000;
    end_date=enddate.getTime()/1000;
   }
  
   
    }
    console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjjjjj');
     
  //  console.log(start_date);
  //  console.log(end_date);
  //  var startdate_timestamp=(new Date().getTime()/1000);
  //  var enddate_timestamp=(new Date("18/03/2022").getTime());


    var venue_details=this.myForm.get('select_city').value;
   if(venue_details=='Select City'){
   venue_details='';
   }
    var event_category=this.myForm.get('event_category').value;
    if(event_category=='Event Category'){
      event_category='';
      }
     var event_type=this.myForm.get('event_type').value;
     if(event_type=='Event Type'){
      event_type='';
      }
      if(event_type=='Free'){
        event_type=0;
        }
        if(event_type=='Paid'){
          event_type=1;
          }

    this.getEvent(this.id,venue_details,start_date,end_date,event_category,event_type,0);
  //  var month1 = month1.toLowerCase();
   
  

   
  
    // formData.forEach((value,key) => {
    //   console.log(key+" "+value)
  //  });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getEvent;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getEvent;
  }
  getCurrentYear() {
    const date =  new Date();
    return date.getFullYear();
}
getYears(from) {
    const years = [];
    const currentYear = this.getCurrentYear();
    for (let index = 0; index <= currentYear - from; index+10) {
        years.push(from + index);
    }
    console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjjjjj',this.years);
    return {years, currentYear};
}
 

//event reg form popup
open(content) {
  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
  
}

private getDismissReaso(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}







}
