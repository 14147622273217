import { Component, OnInit } from '@angular/core';
import { BarAssociationService } from './bar-association.service';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, Validators, } from '@angular/forms';
import { Barassociation } from './barassociation';
import { EventService } from '../event/event.service';
@Component({
  selector: 'app-bar-association',
  templateUrl: './bar-association.component.html',
  styleUrls: ['./bar-association.component.css']
})
export class BarAssociationComponent implements OnInit {
barassociation:Barassociation[]=[];
  constructor(public barAssociationService:BarAssociationService,public eventService:EventService,private formBuilder: FormBuilder) { }
  page: number = 1;
  count: number = 0;
  tableSize: number = 2;
  tableSizes: any = [3, 6, 9, 12];
  sort_city={};
  sort_country={};
  sort_state={};
  sort_district={};
  id: number;
  Limit:Event[]=[];
  ngOnInit(): void {
    this.getBarAssociation('','','','');
    this.sorting_city();
    this.sorting_country();
    this.sorting_district();
    this.sorting_state();
    this.limit();
  }
  getBarAssociation(city,state,country,district) {
    console.log('getting Event');
    
    this.barAssociationService.getAll(city,state,country,district).subscribe((res: Barassociation[]) => {
      this.barassociation = res;
      console.log('saaaaaaaaaaa',this.barassociation)
    });
   
  }
  myForm = new FormGroup({
    city: new FormControl('',),
    state: new FormControl(''),
    country: new FormControl(''),
    district: new FormControl(''),
    });
  sort(){
    
    var city=this.myForm.get('city').value;
    if(city=='Sort City'){
      city="";
    }
   
    var country=this.myForm.get('country').value;
    if(country=='Sort Country'){
      country="";
    }
    var state=this.myForm.get('state').value;
    if(state=='Sort State'){
      state="";
    }

    var district=this.myForm.get('district').value;
    if(district=='Sort District'){
      district="";
    }
    this.getBarAssociation(city,country,state,district);
  }
  sorting_city(){
    this.barAssociationService.sort_city().subscribe((res: any) => {
      this.sort_city = res; 
    });
  }
  sorting_country(){
    this.barAssociationService.sort_country().subscribe((res: any) => {
      this.sort_country = res; 
    });
  }
  sorting_state(){
    this.barAssociationService.sort_state().subscribe((res: any) => {
      this.sort_state = res; 
    });
  }
  sorting_district(){
    this.barAssociationService.sort_district().subscribe((res: any) => {
      this.sort_district = res; 
    });
  }


  onTableDataChange(event: any) {
    this.page = event;
    // this.findRoar;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    // this.findRoar;
  } 
  limit(){
    this.eventService.limit().subscribe((com:Event[]) => {
      this.Limit = com;
    
  
    });
   }
}
