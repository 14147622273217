

<section class=" ipad-hide phone-hide">
    <div class="container-fluid inner-title" style="
    margin-top: 6%;
">
        <div class="row">
            <div class="col-md-1 pl-0 text-right"><span>NEWS</span></div>
            <div class="col-md-10">Delhi HC directs Singh Brothers to Maintain Status-quo over Assets &amp;
                Properties |
                Delhi HC directs...</div>
            <div class="col-md-1 pr-0 text-left"><span>NEWS</span></div>
        </div>
    </div>
</section>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="text-center wrapper text-gray-v1 mt-4" >
                <a *ngFor="let t of news.tags" [attr.href]="'/news?type=' + t" class="badge bg-secondary">{{t}}</a>
                <!-- <span  (click)="sort(news.tags)" formControlName="tags"  class="badge bg-secondary" >{{news.tags}}</span> -->
                <!-- <span  class="badge bg-secondary">Zomato</span> -->
               <!-- <pre>{{news | json}}</pre> -->
                <div class="h-20"></div>
                <h1 class="mrgN lineH10">{{news.news_title}}</h1>
                <div class="small text-muted mrgB"> <i class="fa fa-fw fa-pencil-square-o"></i> <a
                     class="text-capitalize">{{news.member_details[0]['username']}}</a> <i
                        class="fa fa-fw fa-calendar"></i>{{news.news_publish_date*1000|date:'yyyy/MM/dd'}}</div>
                <div class="h-20"></div>
                <div class="social-link">
                    <b>Share - </b>
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <share-buttons [theme]="'material-dark'"
                                    [include]="['facebook']"
                                    [show]="9"
                                    
                                    [url]="getLocation(news.id)"
                                    
                                    [autoSetMeta]="false"
                                   ></share-buttons>
                        </li>
                        <li class="list-inline-item">
                            <share-buttons [theme]="'material-dark'"
                                    [include]="['twitter']"
                                    [show]="9"
                                    
                                    [url]="getLocation(news.id)"
                                    
                                    [autoSetMeta]="false"
                                   ></share-buttons>

                        </li>
                        <li class="list-inline-item">
                            <share-buttons [theme]="'material-dark'"
                                    [include]="['whatsapp']"
                                    [show]="9"
                                    
                                    [url]="getLocation(news.id)"
                                    
                                    [autoSetMeta]="false"
                                   ></share-buttons>
                        </li>
                        <li class="list-inline-item">
                            <share-buttons [theme]="'material-dark'"
                                    [include]="['pinterest']"
                                    [show]="9"
                                    
                                    [url]="getLocation(news.id)"
                                    
                                    [autoSetMeta]="false"
                                   ></share-buttons>
                        </li>
                    </ul>
                </div>
                <section class="component-box-1">
                    <div class="user-info-box-main">
                        <div class="user-info-box ">
                            <div class="user-info-box-inner no-scroll p-0">
                                <!-- <img [src]="getImage(news)" class="img-fluid"> -->
                                <img src={{news.news_image_url}} class="img-fluid">
                            </div>
                        </div>
                    </div>
                </section>
                <div class="row">
                    <div class="col-md-9 ">
                        <div class="post-con ">
                            <p [innerHtml]="news.news_content">
                            </p>
                            <a style="color:#90278E;" target="_blank" [attr.href]="news.news_image_source_link"
                                class="text-capitalize"> Continue Reading... </a>
                        </div>
                        <hr class="solid">
                        <single-comment-form [postId2]="news.id" [categoryId]="2" (onSubmit)="onCommentCreate()"></single-comment-form>
                        

                      
                        <article>
                            <div class="component-box-1 ">
                                <div class="user-info-box-main">
                                    <div class="user-info-box ">
                                        <h4>Comments</h4>




                                        <section class="user-info-box-inner no-scroll text-left h-0 p-4">
                                            <div *ngFor="let c of comments| paginate
                                            : {
                                                itemsPerPage: tableSize,
                                                currentPage: page,
                                                totalItems: count
                                              };
                                        let i = index
                                      "  >
                                            
                                                <single-comment [comment]="c" [groupId]="c.id" (onSubmit)="onComment()"></single-comment>
                                                <div style="margin-left:100px">
                                                <single-comment *ngFor="let c1 of c.comments;" [comment]="c1" [groupId]="c.id" [isReply]="true" (onSubmit)="onComment()"></single-comment>
                                              
                                            </div>

                                            </div>
                                           
                                        </section>
                                        <div class="d-flex justify-content-center">
                                            <pagination-controls
                                              previousLabel="Prev"
                                              nextLabel="Next"
                                              (pageChange)="onTableDataChange($event)"
                                            >
                                            </pagination-controls>
                                          </div>


                                    </div>
                                </div>
                            </div>
                        </article>



                    </div>
                    <div class="col-sm-6 col-md-3">
                        <h4>Related Posts</h4>
                        <div class="top-arrow">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-short" style="height:30%; width:30%"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
                            </svg>
                        </div>

                        <div class="box-slider-1" id="sidebar" *ngFor="let l of Limit">
                            <div class="component-box-1" >
                                <div class="user-info-box-main">
                                    <div class="user-info-box">
                                        <h4>{{l.title}}</h4>
                                        <div class="user-info-box-inner p-0 no-scroll min-height">
                                            <div class="user-info-box">
                                                <div class="bg-white">
                                                    <img src="images/pasted-image.jpg" class="img-fluid" />
                                                </div>
                                                <div class="box-wrapper">
                                                    <p>
                                                       {{l.content}}
                                                    </p>
                                                    <a [attr.href]="'/event/' + l.id +'/add'" class="btn btn-primary">Register Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                         
                        </div> 
                        <div class="bottom-arrow">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-down-short" style="height:30%; width:30%"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- </div> -->