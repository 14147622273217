import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BrowserModule } from '@angular/platform-browser';
import { NewsRoutingModule } from './news-routing.module';
import { IndexComponent } from './index/index.component';
import { ViewComponent } from './view/view.component';
import { CreateComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';
import { NewsComponent } from './news.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule,} from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { toHTML } from 'ngx-editor';  
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
// import{CommentComponent} from '../comment/comment.component';
// import{SingleCommentComponent} from '../comment/singlecomment.component';
import { CommentModule } from '../comment/comment.module';
import { RoarModule } from '../roar/roar.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxClampModule } from 'ngx-clamp';
import { LoginModule } from '../login/login.module';
import { EventModule } from '../event/event.module';


@NgModule({
  declarations: [
    NewsComponent,
    IndexComponent,
    CreateComponent,
    ViewComponent,
    EditComponent,
  ],
  imports: [
    // BrowserModule,
    CommonModule,
    NewsRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxEditorModule,
    SlickCarouselModule,
    ShareModule,
    ShareButtonsPopupModule,
    ShareIconsModule,
    ShareButtonsModule,
    NgxPaginationModule,
    NgxClampModule,
    CommentModule,
    EventModule,
    // Validators,
    LoginModule,
    RoarModule,
  
    
  ]
  
})
export class NewsModule {}
