export class client {
   
  
    name: any;
    email: any;
    contact_num: any;
    address:any;
    contact_person_name:any;
    
   

    constructor() {}
  }