export class Appointment {
   
  
    title: any;
    client_id: any;
    description: any;
    start_time:any;
    end_time:any;
    client_name:any;
    
   

    constructor() {}
  }