import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment'
// import { Comment } from '../../comment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  public apiURL = environment.baseURL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }


  constructor(private httpClient: HttpClient) { }

  getAll(post_id,category_id): Observable<any> {
    // const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('member_id') });
    // const headers = { headers: header };
    // console.log(headers, localStorage.getItem('member_id'));
    return this.httpClient.get<any>(this.apiURL + 'comment?post_id=' + post_id +'&category_id='+category_id)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  create(formData): Observable<any> {

    return this.httpClient.post(this.apiURL + 'comment', formData)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}