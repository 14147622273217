<div class="container">
  <div class="row">
  <!-- <div class="col-md-9 col-sm-6"> -->
    <div class="col-sm-12">
    
      <h2>Event Registration</h2>
      <div class="event-reg">
        <strong>Event Topic:</strong> 
        <p style="display:inline;">{{event.event_title}}</p>
        <div class="">
          <b>From:</b>
          <p style="display:inline;">{{event.event_start_date*1000|date:'yyyy/MM/dd'}}</p><br>
          <b>To:</b>
          <p style="display:inline;">{{event.event_end_date*1000|date:'yyyy/MM/dd'}}</p>
        </div>
          <b *ngIf="event.event_paidtype==1">Amount:{{event.event_amount}}</b>
          <b *ngIf="event.event_paidtype==0">Amount:Free</b>
          <p style="display:inline;"></p>
      </div>
      &nbsp; 
      <form [formGroup]="myForm" (ngSubmit)="submit()">
      <div class="form-row">
       
        <div class="form-group col-md-6">
          <label for="title">Name<span *ngIf="submitted && f['name'].errors?.['required']" style="color:red">  (Required)</span></label>
          <input type="text" class="form-control" id="inputname" placeholder="" formControlName="name">
        </div>
        <div class="form-group col-md-6">
          <label for="title">Contact Number<span *ngIf="submitted && f['contact_no'].errors?.['required']" style="color:red">  (Required)</span> </label>
          <input type="text" class="form-control" id="contactnumber" placeholder="" formControlName="contact_no">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="title">Email<span *ngIf="submitted && f['email'].errors?.['required']" style="color:red">  (Required)</span></label>
          <input type="text" class="form-control" id="inputemail" placeholder="" formControlName="email">
        </div>
        <div class="form-group col-md-6">
          <label for="title">Address</label>
          <input type="text" class="form-control" id="contactaddress" placeholder="" formControlName="address">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="title">Profession</label>
          <input type="text" class="form-control" id="inputemail" placeholder="" formControlName="profession">
        </div>
        <div class="col-lg-6 col-sm-6">
          <label for="title">City</label>
          <input type="text" class="form-control" id="inputemail" placeholder="" formControlName="city">
        
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="title"> Country</label>
          <input type="text" class="form-control" id="inputemail" placeholder="" formControlName="country">
        </div>
        <div class="col-lg-6 col-sm-6">
          <label for="title">Zip(Postal Code)</label>
          <input type="text" class="form-control" id="inputemail" placeholder="" formControlName="zip">
        
        </div>
      </div>
      <!-- <div class="legalChooseOptions mt-3 mb-3"> -->
        <!-- <div class="row pl-3 pr-3 pt-1 pb-1  ">
            <article class="col-md-2" >
              <div class="text-center">
                  <p class="text-center"> As Visitor </p>
                  <label>
                      <input type="radio" value="1" name="services" class="radio" formControlName="event" (change)="changeevent($event)">
                      <span class="radio-style border-black"></span>
                  </label>
              </div>
            </article>
            <article class="col-md-2">
              <div class="text-center">
                <p class="text-center"> As Speaker </p>
                <label>
                    <input type="radio"  value="2" name="services" class="radio" formControlName="event" (change)="changeevent($event)">
                    <span class="radio-style border-black"></span>
                </label>                
              </div>
           </article>
            <article class="col-md-2">

              <div class="text-center">
                <p class="text-center"> As Exhibitor </p>
                <label>
                    <input type="radio" value="3" name="services" class="radio" formControlName="event" (change)="changeevent($event)">
                    <span class="radio-style border-black"></span>
                </label>
              </div>
            </article>
            <article class="col-md-2">
              <div class="text-center">
                <p class="text-center"> As Sponsor </p>
                <label>
                    <input type="radio" value="4" name="services" class="radio" formControlName="event" (change)="changeevent($event)">
                    <span class="radio-style border-black"></span>
                </label>
              </div>
            </article>
        </div> -->
    <!-- </div> -->
      
      <!-- <button  type="submit" class="btn btn-primary">Register & Pay</button> -->
      <!-- <button *ngIf="event.paidtype==0" type="submit" class="btn btn-primary">Register</button> -->
      <div>
      <button  type="submit" class="btn btn-primary" *ngIf="event.event_paidtype==0">Register</button>
      <button  type="submit1" class="btn btn-primary" *ngIf="event.event_paidtype==1">Register and pay</button>
     
    </div>

      <!-- <button *ngIf="event.paidtype==1" type="submit" class="btn btn-primary">Register and pay</button> -->
      
      </form>
   </div>
   
  </div>


































































































<!-- Add Roar Start
<div class="container">
  <div class="row">
    <div class="col-md-9 col-sm-6">

      <h2>Add Events</h2>
      <form [formGroup]="myForm" (ngSubmit)="submit()">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="title">Title</label>
            <input type="text" class="form-control" formControlName="title" id="inputEmail4" placeholder="">
          </div>
          <div class="col-lg-6 col-sm-6">
            <label for="image">Select images</label>
            <div class="form-group"><input type="file" name="url" formControlName="banner" id="url"
                (change)="onFileChange($event)" class="filestyle" value="" data-icon="false" tabindex="-1"
                style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
              <div class="bootstrap-filestyle input-group"><input type="text" class="form-control no-padd "
                  placeholder="" disabled=""
                  [value]="eventImage.name?eventImage.name:(existingEvent?existingEvent.banner:'')"> <span
                  class="group-span-filestyle input-group-btn" tabindex="0"><label for="url"
                    class="btn btn-default "><span class="buttonText">Choose</span></label></span></div>
            </div>
          </div>
        </div>
        <div class="form-row">
        
          <div class="form-group col-md-3">
            <label for="publishdate">Start Date</label><input type="date" name="publishdate" value=""
              formControlName="publish_date" id="publishdate" formControlName="start_date" placeholder="Start_date"
              class="form-control timepicker hasDatepicker">
          </div>
          <div class="form-group col-md-3">
            <label for="publishdate">End Date</label><input type="date" name="publishdate" value=""
              formControlName="publish_date" id="publishdate" formControlName="end_date" placeholder="end_date"
              class="form-control timepicker hasDatepicker">
          </div>
          <div class="form-group col-md-6">
            <label for="VenueDetails">Venue Details</label>
            <input type="text" class="form-control" formControlName="venue_details" id="inputEmail4" placeholder="">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="tags">Tags</label>
            <input type="text" class="form-control" formControlName="tags" id="inputtags" placeholder="">
          </div>
          <div class="form-group col-md-6">
            <label for="eventCategory">Event Category</label>
            <input type="text" class="form-control" formControlName="event_category" id="inputcategory" placeholder="">
          </div>
        </div>
      
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="note">Content</label>
           
            <div class="NgxEditor__Wrapper" height="500px">
              <ngx-editor-menu [editor]="editor" height="500px"> </ngx-editor-menu>
              <ngx-editor [editor]="editor" height="200px" formControlName="description" [placeholder]="'Type here...'">
              </ngx-editor>
            </div>
          </div>
        </div>






        <div class="container"><br />
          <div class="form-group">
            <div class="checkbox">
              <label data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
                aria-controls="collapseOne">
                <input type="checkbox" /> Click here for online registration facility (selecting any one of the below
                options, visitor can register for this event online)
              </label>
            </div>
          </div>
          <div id="collapseOne" aria-expanded="false" class="collapse">
            <div class="text-left legalChooseOptions mt-3 mb-3">
              <div class="row pl-3 pr-3 pt-1 pb-1  ">
                <article class="col-md-1 text-center">
                  <p class="text-center"> Free </p>
                  <label>
                    <input type="radio" formControlName="reg_category" value="0" name="services" class="radio"
                      formControlName="paidtype" (change)="registration($event)">
                    <span class="radio-style border-black"></span>
                  </label>
                </article>
                <article class="col-md-1 text-center">

                  <p class="text-center"> Paid </p>


                  <label>
                    <input type="radio" value="2" name="services" class="radio">
                    <span class="radio-style border-black"></span>
                  </label>
                </article>
                <article class="col-md-10 text-center">

                  <input type="text" class="form-control" formControlName="amount" 
                    placeholder="Registration amount" id="inputcategory">
                  <p>For paid registrations the payment will be collected by soolegal payment gateway. Soolegal will
                    remit the amount
                    to your account after deducting 20%(Soolegal charges) . To know more please contact soolegal
                    info@soolegal.com</p>
                  <div>
                    <article class="col-md-6 " display="flex">
                      <p class="text-center"> Accept payment without selection criteria </p>
                      <label>
                        <input type="radio" value="1" name="services" class="radio" formControlName="paidtype"
                          (change)="registration($event)">
                        <span class="radio-style border-black"></span>
                      </label>
                    </article>

                    <article class="col-md-6 " display="">

                      <span>
                        <p class="text-center">Accept payment with selection criteria </p>


                        <label>
                          <input type="radio" value="2" name="services" class="radio" formControlName="paidtype"
                            (change)="registration($event)">
                          <span class="radio-style border-black"></span>
                        </label>
                      </span>
                    </article>
                  </div>
                </article>
              </div>
            </div>
          </div>

        </div>
        <button type="subm" class="btn btn-primary">Add Event</button>
      </form>
    </div>


    <div class="col-md-3">
      <article class="component-box-1">
        <div class="user-info-box-main">
          <div class="user-info-box">
            <h4>Your Profile</h4>
            <div class="user-info-box-inner p-0 no-scroll">
              <div class="user-box">
                <div class="ribbon ribbon-top-right"><span>Premium</span></div>
                <div class="img-box"><img src="images/ab_circle.png"></div>
                <div class="name">Avani Bansal</div>

                <p class="small">
                  Advocate | Consultant | Counsellor <br>
                  B.A. LLB (Hons.)
                </p>
                <div class="pc-box">New Delhi</div>

                <div class="progress-bar-box">
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85"
                      aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div class="pc-box"><a href="#" class="link">Edit/ Modify Profile</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>






      <div class="black-box h-0 slick-initialized slick-slider slick-dotted">
        <div class="slick-list draggable">
          <div class="slick-track" style="opacity: 1; width: 1729px; transform: translate3d(-741px, 0px, 0px);">
            <div class="slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1"
              style="width: 247px;">
              <div><img src="images/a3.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
            <div class="slick-slide" data-slick-index="0" aria-hidden="true" role="tabpanel" id="slick-slide20"
              style="width: 247px;" aria-describedby="slick-slide-control20" tabindex="-1">
              <div><img src="images/a1.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
            <div class="slick-slide" data-slick-index="1" aria-hidden="true" role="tabpanel" id="slick-slide21"
              style="width: 247px;" aria-describedby="slick-slide-control21" tabindex="-1">
              <div><img src="images/a2.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
            <div class="slick-slide slick-current slick-active" data-slick-index="2" aria-hidden="false" role="tabpanel"
              id="slick-slide22" style="width: 247px;" aria-describedby="slick-slide-control22">
              <div><img src="images/a3.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
            <div class="slick-slide slick-cloned" data-slick-index="3" aria-hidden="true" tabindex="-1"
              style="width: 247px;">
              <div><img src="images/a1.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
            <div class="slick-slide slick-cloned" data-slick-index="4" aria-hidden="true" tabindex="-1"
              style="width: 247px;">
              <div><img src="images/a2.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
            <div class="slick-slide slick-cloned" data-slick-index="5" aria-hidden="true" tabindex="-1"
              style="width: 247px;">
              <div><img src="images/a3.jpg" class="img-fluid" style="width: 100%; display: inline-block;"></div>
            </div>
          </div>
        </div>
        <ul class="slick-dots" style="" role="tablist">
          <li class="" role="presentation"><button type="button" role="tab" id="slick-slide-control20"
              aria-controls="slick-slide20" aria-label="1 of 3" tabindex="-1">1</button></li>
          <li role="presentation" class=""><button type="button" role="tab" id="slick-slide-control21"
              aria-controls="slick-slide21" aria-label="2 of 3" tabindex="-1">2</button></li>
          <li role="presentation" class="slick-active"><button type="button" role="tab" id="slick-slide-control22"
              aria-controls="slick-slide22" aria-label="3 of 3" tabindex="0" aria-selected="true">3</button></li>
        </ul>
      </div>



    </div>
  </div>
</div>


<section class="title-bg-1 my-4  ipad-hide phone-hide">
  <div class="container">
    <div class="row">
      <div class="col-md-2 #90278E-bg-new">
        <div class="triangle-bottomleft"></div>
        <div class="icon-box"></div>
      </div>
      <div class="col-md-10 white-bg-new">
        <div class="triangle-topleft"></div>
        <span class="gray-bg">Go Premium</span>
        <h3>Amplify your stature and reputation...Comment,
          Like, Share...ROAR!</h3>
      </div>
    </div>
  </div>
</section>

<div class="h-50"></div>
<section class="container position-relative mnSlider"   >

  <div class="news-slider slick-initialized slick-slider slick-dotted">
    <div class="slick-list draggable">
      <div class="slick-track" style="opacity: 1; width: 4901px; transform: translate3d(-1131px, 0px, 0px);">
        <div class="slick-slide slick-cloned" data-slick-index="-3" aria-hidden="true" tabindex="-1"
          style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left" >
                          <p> <br>hi</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="-1">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="slick-slide slick-cloned" data-slick-index="-2" aria-hidden="true" tabindex="-1"
          style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> hi</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="-1">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1"
          style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> RK </p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="-1">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" role="tabpanel"
          id="slick-slide00" style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> RK Sharma</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="0">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="0"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="slick-slide slick-active" data-slick-index="1" aria-hidden="false" role="tabpanel"
          id="slick-slide01" style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> RK Sharma</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="0">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="0"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="slick-slide slick-active" data-slick-index="2" aria-hidden="false" role="tabpanel"
          id="slick-slide02" style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> RK Sharma</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="0">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="0"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="slick-slide" data-slick-index="3" aria-hidden="true" tabindex="-1" role="tabpanel"
          id="slick-slide03" style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> RK Sharma</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="-1">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="slick-slide" data-slick-index="4" aria-hidden="true" tabindex="-1" role="tabpanel"
          id="slick-slide04" style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> RK Sharma</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="-1">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="slick-slide slick-cloned" data-slick-index="5" aria-hidden="true" tabindex="-1"
          style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> RK Sharma</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="-1">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="slick-slide slick-cloned" data-slick-index="6" aria-hidden="true" tabindex="-1"
          style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> RK Sharma</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="-1">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="slick-slide slick-cloned" data-slick-index="7" aria-hidden="true" tabindex="-1"
          style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> RK Sharma</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="-1">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="slick-slide slick-cloned" data-slick-index="8" aria-hidden="true" tabindex="-1"
          style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> RK Sharma</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="-1">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div class="slick-slide slick-cloned" data-slick-index="9" aria-hidden="true" tabindex="-1"
          style="width: 357px;">
          <div>
            <article style="width: 100%; display: inline-block;">
              <div class="component-box-1 p-0">
                <div class="user-info-box-main p-0">
                  <div class="user-info-box">
                    <div class="user-info-box-inner no-scroll pb-3">
                      <div class="d-flex justify-content-between">
                        <div class="jobId mb-3">
                          <p>
                            <img src="images/profile_pic.png" class="img-fluid profilePic">
                          </p>
                        </div>

                        <div class="job-posted-date mb-3 text-left">
                          <p>DD/MM/YY <br> RK Sharma</p>
                        </div>
                      </div>

                      <div class="job-details text-left mb-3">
                        <h4 class="mb-3 color-gray">What to do if you are an aacused in the law of POSCO?</h4>

                        <img src="images/news/news-img-1.jpg" class="img-fluid">



                      </div>


                      <div class="text-wrapper text-left">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin id suscipit velit. In
                          vehicula libero libero, ac malesuada lectus dignissim nec. Cras egestas purus eu diam
                          iaculis, a venenatis est cursus. Vivamus at ex dignissim est male... <a href="#" class="link"
                            tabindex="-1">more</a></p>


                      </div>


                      <div class="text-right pull-right">
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-heart"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="far fa-comments"
                            aria-hidden="true"></i></a>
                        <a href="#" class="btn text-white color-gray" tabindex="-1"><i class="fas fa-share-alt"
                            aria-hidden="true"></i></a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
    <ul class="slick-dots" style="" role="tablist">
      <li class="slick-active" role="presentation"><button type="button" role="tab" id="slick-slide-control00"
          aria-controls="slick-slide00" aria-label="1 of 2" tabindex="0" aria-selected="true">1</button></li>
      <li role="presentation"><button type="button" role="tab" id="slick-slide-control01" aria-controls="slick-slide01"
          aria-label="2 of 2" tabindex="-1">2</button></li>
      <li role="presentation"><button type="button" role="tab" id="slick-slide-control02" aria-controls="slick-slide02"
          aria-label="3 of 2" tabindex="-1">3</button></li>
      <li role="presentation"><button type="button" role="tab" id="slick-slide-control03" aria-controls="slick-slide03"
          aria-label="4 of 2" tabindex="-1">4</button></li>
      <li role="presentation"><button type="button" role="tab" id="slick-slide-control04" aria-controls="slick-slide04"
          aria-label="5 of 2" tabindex="-1">5</button></li>
    </ul>
  </div>

  <a href="#" class="arrow ml slick-arrow" style=""><img src="images/icons/arrow_left.png"></a><a href="#"
    class="arrow mr r-180 slick-arrow" style=""><img src="images/icons/arrow_left.png"></a>

</section> -->

































