import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import jwt_decode from "jwt-decode";
import { environment } from '../../environments/environment'
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})


export class MemberService {

  MemberService: any;
  
  public url = environment.baseURL;
  baseUrl: string ='https://api.beta.soolegal.com/public/api';
  notify: string = 'https://api.beta.soolegal.com/public/api/notification';
  putbaseurl:string = 'https://api.beta.soolegal.com/public/api';
  token: string;
  tokenID: any;
  userInfo: any;
  eventcalendar: any;
  eventlist = [];
  // roarlike = new Subject<any>();
  roarlike = new BehaviorSubject<any>(0);
  roarview = new Subject<any>();
  roarcomment = new Subject<any>();
  Client = new Subject<any>();
  Cases = new Subject<any>();
  NewViews = new Subject<any>();
  NewConnection = new Subject<any>();
  PendingConnection = new Subject<any>();
  userInfoapi: any;

  constructor(public http: HttpClient) {

    if (localStorage.getItem('USERID')) {


      this.token = localStorage.getItem('USERID')
      this.tokenID = jwt_decode(this.token)
      this.userInfoapi = this.tokenID.userdata
    


    }
    this.roarlike.subscribe({
      next: (v) => console.log(`observerB: ${v}`)
    });
  }



  postmember(urlT: any, data: any) {
    const updateUrl = this.url + urlT;
    const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('USERID')); // may be localStorage/sessionStorage
    const headers = { headers: header };

    return this.http.post(updateUrl, data, headers)
      .pipe(
        catchError(this.errorHandler)
      )
  }
  getdata(urlt: any) {

    const getUrl = this.url + urlt;
    const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('USERID'));
    const headers = { headers: header };
    return this.http.get(getUrl, headers)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  getevent(urlt: any) {
    const getUrl = urlt;
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.get(getUrl)
      .pipe(
        catchError(this.errorHandler)
      )
  }
  postdashboard(urlT: any, data: any) {
    const updateUrl = this.url + urlT;
    const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('USERID')); // may be localStorage/sessionStorage
    const headers = { headers: header };

    return this.http.post(updateUrl, data, headers)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  get(urlt: any) {
    const getUrl = this.url + urlt;

    return this.http.get(getUrl)
      .pipe(
        catchError(this.errorHandler)
      )
  }
  deletedata(urlt: any, id: any) {
    const deleteUrl = this.url + urlt + '/' + id;
    const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('USERID')); // may be localStorage/sessionStorage
    const headers = { headers: header };

    return this.http.delete(deleteUrl, headers)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  patchdata(urlT: any, data: any) {
   
    const updateUrl = this.url + urlT;
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.patch(updateUrl, data)
      .pipe(
        catchError(this.errorHandler)
      )
  }
  putdata(urlT: any, data: any) {
   
    const putUrl = this.url + urlT;
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.http.put(putUrl, data)
      .pipe(
        catchError(this.errorHandler)
      )
  }


  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  list() {

 
    const Headers = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('USERID'));
    const headers = { headers: Headers };
  
   
    return this.http.get(this.notify , headers);
  }

  getlist(){
   
    const Headers = new HttpHeaders().set('JWTAuthorization',localStorage.getItem('USERID'));
    const headers = {headers:Headers};
  

   return this.http.get(this.baseUrl + '/connection' , headers);
  }

 

  putlist()
  {

    const Headers = new HttpHeaders().set('JWTAuthorization',localStorage.getItem('USERID'));
    const headers = {headers:Headers};
   

   return this.http.get(this.putbaseurl + '/connection/pending', headers);
    
}


}
