export class Findlawer {
  
    email: any;
    mobile: any;
    description: any;
    category: any;
    is_share_info:any;
    subject:any;
    member_id:any;
   
    constructor() {}
  }