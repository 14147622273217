import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleLoginProvider,FacebookLoginProvider, SocialAuthService } from 'angularx-social-login';
import { ApiService } from '../api.service';
import { Register } from '../modals/regsiter';
import { RegisterComponent } from '../register/register.component';
import { LoginService } from './login.service';
import {Location} from '@angular/common';
import { SearchService } from '../search/search.service';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  register= false;

  loginStatus:boolean =false;
  loading = false;
 
  @ViewChild('openLoginModal')loginModal: ElementRef; 
  @ViewChild('closeLoginModal')closeLoginModal:ElementRef; 
  
  @Output() loginEvent = new EventEmitter<string>();
  userInfo: any;
  message:any
  token: any;
  arraydata: any;
 


  constructor(public api: LoginService,public searchapi:SearchService,private socialAuthService: SocialAuthService,private location: Location,public mainapi: ApiService,public router: Router, private activatedRoute:ActivatedRoute) {
    this.userInfo = new Register();
   
   
   }
 

  ngOnInit(): void {
    this.getAllData();
  }
  loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
      .then(() => this.router.navigate(['member']));
      
      //  *ngIf="socialAuthServive.authState | async as socialUser"
      // {{socialUser.name}}
      // {{socialUser.email}}
  }
  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
   
  } 
  openLogin() {
    this.loginModal.nativeElement.click();
    
    
  }
  openrefresh() {
    this.loginModal.nativeElement.click()

    // this.loginModal.nativeElement.click()
    //     .then(() => {
    //       // window.location.reload();
    //       let token = localStorage.getItem('USERID')
    //       return token
    //     });

  }
  openRegister() {
   this.register = true
   
    this.closeLoginModal.nativeElement.click();
    
    this.loginEvent.next('register');
    
  }
  openForgotPassword(){
  
    this.closeLoginModal.nativeElement.click();
    this.loginEvent.next('forget');
  }
  reseterror(){
    setTimeout(() => {
      this.message = ''
      
    }, 2000);
  }


  login() {
   if(!this.userInfo.email){
    this.message = 'Email required!';
    setTimeout(() => {
      this.message = '';
      return;
    }, 2500)
   }else if(!this.userInfo.password){

    this.message = 'password required!';
    setTimeout(() => {
      this.message = '';
      return;
    }, 2500)

   }else
    
    {
      this.loading = true;
      this.api.postdata('login', this.userInfo).subscribe((res: any) => {
     
      if(res.data.token){
        this.token = res.data.token
        localStorage.setItem('USERID',this.token)
        
      }
      this.loginStatus = true;
      this.loading = false;
      
      this.userInfo = new Register();
      if(res.status==200){
        this.message = res.message
        this.reset()
      }else if(res.status==500&&res.message.email){
        this.message = res.message.email
        this.reseterror();
      }else if(res.status==500&&res.message.password){
        this.message = res.message.password
        this.reseterror();
      }else {
        this.message = res.message
        this.reseterror();
      }

    
    
      
   
    
    }, error => {
      
    });}
  }

  reset(){
    setTimeout(() => {
   
      // this.registerEvent.next('verify-otp');
      this.message =''
       this.closeLoginModal.nativeElement.click();
       this.loginStatus= false;
      //  this.router.navigate(['/member']);
      //  window.location.reload();
     
   
    
      if(window.location.pathname=='/'){

        console.log("if  condition")
        this.router.navigate([this.api.redirectUrl?this.api.redirectUrl:'/member'])
        .then(() => {
          window.location.reload();
        });
      }
      else{
        // this.location.back();
        // history.back();
        //  this.location.go(window.location.pathname)
        //  this.location.go(this.api.redirectUrl)
        console.log("else condition")
        this.router.navigate([this.api.redirectUrl?this.api.redirectUrl:window.location.pathname])
        .then(() => {
          window.location.reload();
        });

      }
     
     
    }, 2000);
  }

  getAllData(){
    this.api.getdata().subscribe((res: any) => {
      console.log('res',res);
      this.arraydata = res;
    });
  }

}
