import { Component, OnInit,Input,Output,EventEmitter,ViewChild,ElementRef } from '@angular/core';
import { ClientRequest } from 'http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ResourceCentreService } from './resource-centre.service';
import { Resource } from './resource';
import { CommentService } from '../comment/comment.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LikeService } from '../like/like.service';
import { LoginComponent } from '../login/login.component';
import { ApiService } from '../api.service';
import { SearchService } from '../search/search.service';
@Component({
  selector: 'app-resource-centre',
  templateUrl: './resource-centre.component.html',
  styleUrls: ['./resource-centre.component.css']
})
export class ResourceCentreComponent implements OnInit {
  @Input()
  postId: number;

  

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter();
 

  constructor(private router: Router,public resourcecenterservice: ResourceCentreService,public searchapi: SearchService,public mainapi: ApiService,public api: ApiService, public likeService: LikeService, public commentService: CommentService, private modalService: NgbModal,) {
    if(localStorage.getItem('USERID')){


      this.token =  localStorage.getItem('USERID')
     }
     if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
     this.refresh();
     }
   }
   @ViewChild(LoginComponent)loginPrev: LoginComponent;
   @ViewChild('openLoginModal')loginModal: ElementRef; 
   @Output() loginEvent = new EventEmitter<string>();

  resource: Resource[] = [];
  recent: Resource[] = [];
  notice: Resource[] = [];
  research: Resource[] = [];
  judgements: any;
  bareacts: any;
  resourcelegalProcedure: any;
  resourcelegalformats: any;
  legalProcedure: Resource[] = [];
  legalformat: Resource[] = [];
  contributor:any;
  videos:any;
  get_rcpopular_videos:any;
  get_article_List:any;
  comments: Comment[];
  slideConfig = { "slidesToShow": 3, "slidesToScroll": 1 };
  pagecmt: number = 1;
  countcmt: number = 0;
  tableSizecmt: number = 4;
  tableSizescmt: any = [3, 6, 9, 12];
  searchlist: Resource[] = [];
  cmt:any;
  l:any;
 category_id = '';
 categorytype=3;
 token:any;
 filterTerm!: string;
 


 ng2NestedSearch:any;
 searchtitle : string = "";
    searchEmail : string = "";
    searchNumber : string = "";
    filterMetadata = { count: 0, isNestedSearch:true };





  ngOnInit(): void {
    this.getResource();
    this.mostArticle();
    this.getLegalProcedure(1, '');
    this.legalResearch(9, '');
    this.getLegalNotice(4, '');
    this.getLegalFormat(3,'')
    this.getComments('', 3);
    this.topcontributor();
    this.popularvideos('');
    this.getarticleList();
    this.getrcpopularvideos();
    this.getJudgements();
    this.getBareacts();
    this.rcgetLegalProcedure();
    this.rcgetLegalFormat()

  }

  getComments(post_id, category_id) {
    this.commentService.getAll(post_id,3).subscribe((com: Comment[]) => {
      this.comments = com;
      console.log("Comments..:::", this.comments);

    });
  }

  

  getResource() {
    console.log('getting Resource');

    this.resourcecenterservice.getAll('').subscribe((res: Resource[]) => {
      //   var resource_category={};
      //   res.forEach(function (value:any) {
      //     resource_category[value.resource_centre_category_id]=[value];
      //     console.log(resource_category);
      // });
      // console.log('ttttttttttttttttttttt',resource_category);
      this.resource = res;
    })

  }
  mostArticle() {
    this.resourcecenterservice.most_recent_article().subscribe((res: Resource[]) => {
      this.recent = res;
    })
  }

  
  getLegalProcedure(category_id, payment_type) {
    console.log('getting Resource');
    this.resourcecenterservice.resourcelistview(category_id, payment_type).subscribe((res: Resource[]) => {
      this.legalProcedure = res;

      console.log("Resource..:::", this.resource);
    })
  }
  getLegalNotice(category_id, payment_type) {
    this.resourcecenterservice.resourcelistview(category_id, payment_type).subscribe((res: Resource[]) => {
      this.notice = res;
    })
  }
  getLegalFormat(category_id, payment_type) {
    this.resourcecenterservice.resourcelistview(category_id, payment_type).subscribe((res: Resource[]) => {
      this.legalformat = res;
    })
  }

 
  legalResearch(category_id, payment_type) {
    this.resourcecenterservice.resourcelistview(category_id, payment_type).subscribe((res: Resource[]) => {
      this.research = res;
    })
  }
topcontributor(){
  this.resourcecenterservice.topContributor().subscribe((res: any) => {
    this.contributor = res;
  })
}
popularvideos(payment_type){
  this.resourcecenterservice.videos(payment_type).subscribe((res: any) => {
    this.videos = res;
  })
}

  getImagePic(r) {
return r.profile_pic_url;
  }

  click(){
    if(this.token){
      
    }else{
      this.loginPrev.openLogin();
    }
  }
  formResource(){
    if(this.token){
      this.router.navigate(['/resource-centre/resource-form']);
    }
  }

  like(r) {
    if(this.token){
      let resource_id = r.id,
        liked = r.liked,
        member_id = r.member_id;
  
      // this.showicon = !this.showicon;
      // this.show = !this.show;
      console.log('like',r);
      const formData = new FormData();
      formData.append('post_id', resource_id + '');
      formData.append('category_id', 3 + '');
      formData.append('member_id', member_id + '');
      if (liked > 0 ) {
        liked = 0;
        r.like_count = r.like_count - 1;
      } else {
       liked = 1;
        r.like_count = r.like_count + 1;
      }
      // UPdating the ui object
      r.liked = liked;
  
      formData.append('status', liked + '');
      console.log('status',liked);
  
      this.likeService.create(formData).subscribe((res: any) => {
        console.log("Roar Liked..:::", res.roar);
      });
  
      console.log(this.category_id);
    }
  }




  getLocation(r) {

  }
  loginSubmit(event:any) {
  }




  onCommentCreate(legalprocedure_id) {
    this.getComments(legalprocedure_id, 3);
  
  }
  // ################# comment popup legal procedure #################
  closeResult: string;
  open(content, r) {
    this.l = r;
    this.getComments(r.id, 1);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
// ################# comment popup legal notice #################
  ope(content,n) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismiss(reason)}`;
    });
  }


  private getDismiss(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
// ################# comment popup legal notice #################



// ################# comment popup legal format #################



// ######pagination 
onTableDataChangecmt(event1: any) {
  this.pagecmt = event1;
  this.getComments;
}
onTableSizeChangecmt(event1: any): void {
  this.tableSizecmt = event1.target.value;
  this.pagecmt = 1;
  this.getComments;
}

// ###########share popup
openshare(conte) {
  this.modalService.open(conte, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissshare(reason)}`;
  });
}


private getDismissshare(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}
refresh() {
  if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
  localStorage.clear();
  // window.location.reload();
  }
}
// search data

search(search){
  // this.resourcecenterservice.search(search).subscribe((res: any) => {
  //   this.searchlist =res;
  //   console.log(search,"rez");
  //   if(search==''){
  //     this.mostArticle();
  //     this.getLegalProcedure(1, '');
  //     this.legalResearch(9, '');
  //     this.getLegalNotice(4, '');
  //     this.getLegalFormat(3,'')
  //     this.getComments('', 3);
  //   }
  // })

  const formData = new FormData();
      formData.append("search_key_word",search);
      formData.append("category_id",'3');
      this.searchapi.post_search(formData).subscribe((res4: any) => 
      { 
        this.searchlist =res4.messages.feed_list;
            // this.search_list_data=res4.messages.feed_list;
            // console.log("searchlist", this.search_list)
        
       });  
}
onKeyup($event){
  this.search($event);
}

// Rc Get List

getarticleList() {
  this.resourcecenterservice.resource_centre_article_list().subscribe((res: any) => {
    this.get_article_List = res.messages.feed_list;
  })
}

getrcpopularvideos(){
  this.resourcecenterservice.get_rc_popular_videos().subscribe((res: any) => {
    this.get_rcpopular_videos = res.messages.feed_list;
  })
}

getJudgements() {  
  this.resourcecenterservice.judgementlistview().subscribe((res:any) => {
  this.judgements = res.messages.category_filter_list;  
  console.log("Resource..:::", this.judgements);
  })
}

getBareacts() {  
  this.resourcecenterservice.rcbareactslistview().subscribe((res:any) => {
  this.bareacts = res.messages.category_filter_list;  
  console.log("Resource..:::", this.bareacts);
  })
}

rcgetLegalProcedure() {
  console.log('getting Resource');
  this.resourcecenterservice.resourcelegalprocedurelistview().subscribe((res: any) => {
    this.resourcelegalProcedure = res.messages.category_filter_list;

    console.log("Resource..:::", this.resource);
  })
}

rcgetLegalFormat() {
  console.log('getting Resource');
  this.resourcecenterservice.resourcelegalformatslistview().subscribe((res: any) => {
    this.resourcelegalformats = res.messages.category_filter_list;

    console.log("Resource..:::", this.resource);
  })
}



}



