<!-- <div class="sticky-top">
    <nav class="navbar navbar-expand-md navbar-light bg-light mb-5">
        <div class="container">
            <a class="search-mobile openBtn"> <img src="../../assets/images/search.svg" alt="search" /></a>

 
            <a class="navbar-brand" href="https://beta.soolegal.com">
                <img src="../../assets/images/logo.png" alt="" />
                <div class="live-chat">LIVE Chat <span>Available</span></div>
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <img src="../../assets/images/menu.svg" alt="Menu" />
        </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="offcanvas-header">
                    <button class="btn btn-close float-right"><i class="fas fa-times"></i></button>
                    <h5 class="py-2 text-white">Main navbar</h5>
                </div>
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item active m-hide">
                        <a class="nav-link text-right">
                            <div class="input-group search-top">
                                <input type="text" class="form-control" name="searchtext" [(ngModel)]="searchtext" (keyup.enter)="openSearch(searchvalue.value)" placeholder="Quick Search..." aria-describedby="button-addon2" #searchvalue />
                                <div class="input-group-append">
                                    <button type="button" id="button-addon2"><img src="../../assets/images/search.svg" alt="search"(click)="openSearch(searchvalue.value?searchvalue.value:'')" /></button>

                                    <div class="dropdown-menu fade-up alert-list" aria-labelledby="alerts">
                                        <a class="dropdown-item" [routerLink]="['/search/people']" [queryParams]="{q: searchvalue.value}">Member</a>
                                        <a class="dropdown-item" [routerLink]="['/search/judgement']" [queryParams]="{q: searchvalue.value}">Judgement</a>
                                        <a class="dropdown-item" [routerLink]="['/search/content']" [queryParams]="{q: searchvalue.value}">Resource Centre</a>
                                        <a class="dropdown-item" [routerLink]="['/search/event']" [queryParams]="{q: searchvalue.value}">Event</a>
                                        <a class="dropdown-item" [routerLink]="['/search/roar']" [queryParams]="{q: searchvalue.value}">Roar</a>

                                    </div>
                                </div>
                            </div>
                            <span class="d-hide">Search</span>


                        </a>

                    </li>
                    <li class="nav-item dropdown ipad-hide phone-hide" *ngIf="this.api.loggedIn()">
                        <a class="nav-link dropdown-toggle" href="#" id="alerts" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <a *ngIf="this.api.loggedIn()" class="dropdown-item" [routerLink]="['/member/notification']"><img src="../../assets/images/alert.svg" alt="Alerts" /></a> <span>Alerts</span></a>
                        <div class="dropdown-menu fade-up alert-list" aria-labelledby="alerts">
                          
                            <a class="dropdown-item" (click)="notificationupdate(data)" *ngFor="let data of notification">
                                <span class="bg-light" *ngIf="data.status==1">{{data?.value}}</span>
                                <span *ngIf="data.status==0">{{data?.value}}</span>
                            </a>
                           
                        </div>
                    </li>
                    <li class="nav-item dropdown ipad-hide phone-hide" *ngIf="!this.api.loggedIn()">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0);" (click)="alertModal($event,'/member/notification')" id="alerts" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../../assets/images/alert.svg" alt="Alerts" /> <span>Alerts</span></a>
                    </li>
                    <li class="nav-item dropdown ipad-hide phone-hide">
                        <a class="nav-link dropdown-toggle" href="#" id="cart" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../../assets/images/cart.svg" alt="Cart" /> <span>Cart</span></a>
                        <div class="dropdown-menu fade-up cart-list" aria-labelledby="cart">
                            <a class="dropdown-item">
                                <img src="../../assets/images/legal-doc.svg" alt="Legal Document" />
                                <h6>Legal Document Translation</h6>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <h5>1199/- Per Year</h5>
                            </a>
                            <a class="dropdown-item">
                                <img src="../../assets/images/legal-doc.svg" alt="Legal Document" />
                                <h6>Legal Research</h6>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <h5>Complete Purchase</h5>
                            </a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../../assets/images/menu.svg" alt="Menu" /> <span>Menu</span></a>
                        <div class="dropdown-menu fade-up menu-list" aria-labelledby="menu-list">
                            <a *ngIf="this.api.loggedIn()" class="dropdown-item" [routerLink]="['/roar']">MY PROFILE</a>
                            <a class="dropdown-item" [routerLink]="['/roar']">ROAR</a>
                            <a class="dropdown-item" [routerLink]="['/news']">NEWS</a>
                            <a class="dropdown-item" [routerLink]="['/resource-centre']">RESOURCE CENTRE</a>
                            <a class="dropdown-item" [routerLink]="['/need-a-lawyer']">NEED A LAWYER</a>
                            <a class="dropdown-item" [routerLink]="['/event']">EVENTS</a>
                          
                            <a class="dropdown-item" [routerLink]="['/bar-association']">BAR ASSOCIATION</a>
                            
                            <a class="dropdown-item" [routerLink]="['/resource-centre']">JUDGMENT</a>
                            <a *ngIf="this.api.loggedIn()" href="javascript:void(0);" (click)="logout()" class="dropdown-item">LOGOUT</a>
                            <a *ngIf="!this.api.loggedIn()" href="javascript:void(0);" (click)="loginModal()" class="nav-link" data-backdrop="static" data-keyboard="false" data-toggle="modal" class="dropdown-item">LOGIN</a>
                        </div>
                    </li>
                    <li class="nav-item">
                       
                        <a *ngIf="!this.api.loggedIn()" href="javascript:void(0);" (click)="loginModal()" class="nav-link" data-backdrop="static" data-keyboard="false" data-toggle="modal"> <img src="../../assets/images/login.svg" alt="Login" /> <span>Login123</span></a>
                        <a *ngIf="this.api.loggedIn()" href="javascript:void(0);" (click)="loginModal()" class="nav-link" data-backdrop="static" data-keyboard="false" data-toggle="modal"> <img src="../../assets/images/profile-user.png" style="width:34px;height: 32px;" alt="Login" /> <span>My Profile</span></a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>-->

<!-- <app-login (loginEvent)="loginSubmit($event)"></app-login>
<app-register (registerEvent)="registerSubmit($event)"></app-register>
<app-otp-verify (otpEvent)="otpSubmit($event)"></app-otp-verify>
<app-forget-password (forgotEvent)="forgotSubmit($event)"></app-forget-password> 




 <div class="sticky-top">
<body>
<header class="header navbar-area">
    <div class="container" style="background-color: #f8f8f8;">
        <div class="container">
            <div class="row">
             <div class="col-12">
              <nav class="navbar navbar-expand-lg navbar-light bg-light rounded">
               <ul class="navbar-nav mr-auto">
                <li class="nav-item dropdown megamenu-li">
                 <a class="nav-link dropdown-toggle" href="" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-bars" aria-hidden="true" style="
                    font-size: 30px;
                "></i></a>
                 <div class="dropdown-menu megamenu" aria-labelledby="dropdown01">
                  <div class="row">
                   <div class="col-sm-6 col-lg-3">
                    <h5>Quick Links</h5>
                    <a  class="list-group-item list-group-item-action border" [routerLink]="['/roar']" >Roar</a>
                    <a  class="list-group-item list-group-item-action border" [routerLink]="['/news']">News</a>
                    <a  class="list-group-item list-group-item-action border" [routerLink]="['/resource-centre']">Resource Centre</a>
                    <a  class="list-group-item list-group-item-action border">Need a Lawyer</a>
                    
                   </div>
                   <div class="col-sm-6 col-lg-3">
                    <h5>&nbsp;</h5>
                    <a  class="list-group-item list-group-item-action border" [routerLink]="['/event']">Events</a>
                    <a  class="list-group-item list-group-item-action border" [routerLink]="['/bar-association']">Bar Association</a>
                    <a  class="list-group-item list-group-item-action border">service</a>
                   </div>
                   <div class="col-sm-6 col-lg-3">
                    <h5>Search</h5>
                    <a class="list-group-item list-group-item-action border" [routerLink]="['/member']">Member</a>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                    <h5>My Profile</h5>
                    <a class="list-group-item list-group-item-action border"*ngIf="this.api.loggedIn()" href="javascript:void(0);" (click)="logout()" class="dropdown-item">Logout</a>                    
                   </div>
                  
                  </div>
                 </div>
                </li>             
               </ul>
               <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbars" aria-expanded="false" aria-label="Toggle navigation">
                               <span class="navbar-toggler-icon"></span>
                     </button>
               <div class="collapse navbar-collapse" id="navbar">
                <ul class="navbar-nav mr-auto">
                 <li class="nav-item dropdown megamenu-li">
                         <a class="navbar-brand" href="index.html">
                    <img src="../../assets/images/logo.png" alt="Logo">                   
                </a>
                
                 </li>

                <div style="" class="nav-mn">
                    <li class="nav-item dropdown megamenu-li">
                     
                        <form>
                            <div class="form-group sm-4 search">
                                <i style="padding-right: 5px;" class="fa fa-search" aria-hidden="true"></i>
                                <input id="exampleFormControlInput" type="email" placeholder="Type your query" class="fom-control form-control-underlined">
                          </div>
                        </form>
                    </li>
                    <li class="nav-item dropdown megamenu-li">
                        <a href="javascript:void(0)">
                            <i class="lni lni-enter">
                                <img style="height: 25px; padding-right: 8px;" src="assets/images/Header/Group 21.png ">
    
                            </i> Alerts</a>
                    </li>
                    <li class="nav-item dropdown megamenu-li">
                        <a href="javascript:void(0)"><i class="lni lni-enter">
                            <img style="height: 25px; padding-right: 8px;"  src="assets/images/Header/Group 23.png ">
                            </i> Cart</a>
                    </li>
                    <li class="nav-item dropdown megamenu-li">
                       
                            <a *ngIf="!this.api.loggedIn()" href="javascript:void(0);" (click)="loginModal()" class="nav-link" data-backdrop="static" data-keyboard="false" data-toggle="modal"><img style="height: 25px; padding-right: 8px;"  src="assets/images/Header/Group 25.png "> <span>Login</span></a>
                    </li> 
                    
                </div>              
                </ul>
               </div>
              </nav>
             </div>
            </div>
           </div>
    </div>
</header>
</body>
<script>
    $(document).ready(function() {
	$(".megamenu").on("click", function(e) {
		e.stopPropagation();
	});
});
</script>
</div> -->

<div class="container-hd">
    <div class="top-nav">
        <div class="logosl">
            <a href="/"><img src="../../assets/images/Header/logo.png"></a>
        </div>
        <div class="row">
            <div class="showheadval"><span>Search</span> for top <span>lawyers</span> in your city</div>
            <div class="col-lg-12 card-margin">
                <div class="justify-content-center mt-3 mb-3">
                    <div class="input-field"> <input placeholder="City, neighbourhood , address , ZIP..." class="form-control" (keyup)="onKeyup($event.target.value)" />
                        <button class="btn btn1" (click)="search()"><i class="fa fa-search"></i></button> </div>
                </div>
            </div>
        </div>
        <div class="regblk">
            Want to be a<br>
            <!-- <span>Soolegal </span><br> -->
            Soolegal <span>Member</span><br>
            <a href="#" class="btnlink" target="_blank"><img src="../../assets/images/Header/regbtn.png"></a>

        </div>
    </div>
    <div class="bottomblk">
        <div class="maincontainer">
            <!--Menu Blk start here -->
            <div class="nav-menu">
                <div id="menubar">

                    <ul id="menu">
                        <!-- <li class="tsy"><a href="/article/tag/corona-resource-centre"><strong>Corona Resource Centre</strong></a></li>-->
                        <!--<li><a href="/article/tag/Store-launch">Unlock Diary</a></li> -->
                        <li><a href="/roar">Roar</a>
                        </li>
                        <li><a href="/news">News</a></li>
                        <li><a href="/resource-centre">Resource Centre</a></li>
                        <li><a href="/event">Events</a></li>
                        <li><a href="/bar-association">Bar Association</a></li>
                        <li><a href="/need-a-lawyer">Need a Lawyer</a></li>
                        <li><a *ngIf="!this.api.loggedIn()" href="javascript:void(0);" (click)="loginModal()"
                                data-backdrop="static" data-keyboard="false" data-toggle="modal"> <img
                                    src="../../assets/images/Header/loginsignup.png" alt="Login" class="btnlink"
                                    target="_blank" style="height: 28px;"></a>
                        </li>
                        <li>
                            <div class="dropdown">
                                <a *ngIf="this.api.loggedIn()" href="javascript:void(0);" id="dropdownMenuButton"
                                    class="dropdown" data-toggle="dropdown">Hi User</a>
                                <!-- <p aria-hidden="true" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">user</p> -->
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" href="#">My Profile</a>
                                    <a class="dropdown-item" href="#">Edit Profile</a>
                                    <a class="dropdown-item" href="#" (click)="logout()">Logout</a>
                                </div>
                            </div>
                        </li>            
                    </ul>
                    


                    <app-login (loginEvent)="loginSubmit($event)"></app-login>
                    <app-register (registerEvent)="registerSubmit($event)"></app-register>

                </div>
            </div>
            <!--Menu blk end here -->

            <div class="socblk">
                <div class="searchfindblk" id="searchfindblk" style="display:none;">
                    <form action="/search-content/" method="get">
                        <div class="seaiconfind"><i class="fa fa-search" aria-hidden="true"></i></div>
                        <input type="text" name="keyword" class="inputnew">
                        <div class="hidesub" id="closegsearch" onclick="search()"><img
                               (click)="search()" src="https://www.franchiseindia.com/images/crosssearch.png"></div>
                    </form>
                </div>
                <!-- <div class="nosearchblk" id="nosearchblk">
                    <ul class="social">
                        <li><a href="https://www.facebook.com/RestaurantIndia" target="_blank"><i
                                    class="fa fa-facebook"></i></a></li>
                        <li><a href="https://twitter.com/IndianRestCong" target="_blank"><i
                                    class="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/restaurantindia/" target="_blank"><i
                                    class="fa fa-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/restaurant.india/" target="_blank"><i
                                    class="fa fa-instagram"></i></a></li>
                    </ul>
                    <div class="seaicon" onclick="showSearch()" id="searchoptnew"><i class="fa fa-search"
                            aria-hidden="true"></i></div>

                </div> -->
            </div>
        </div>

    </div>
</div>

<div class="container mob">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" href="#">Navbar</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Link</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Dropdown
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Something else here</a>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link disabled" href="#">Disabled</a>
            </li>
          </ul>
          <form class="form-inline my-2 my-lg-0">
            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
          </form>
        </div>
      </nav>
</div>
