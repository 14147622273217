import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoarRoutingModule } from './roar-routing.module';
import { RoarComponent } from './roar.component';
import { IndexComponent } from './index/index.component';
import { ViewComponent } from './view/view.component';
// import { RoarFormComponent } from './roarform/roarform.component';
import { CreateComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommentModule } from '../comment/comment.module';
// import {  } from './cmtmodel/cmtmodel.module';
import { EllipsisModule } from 'ngx-ellipsis';
// import{CommentComponent} from './comment4/comment.component';
// import{SingleCommentComponent} from './comment4/singlecomment.component';
// import { CommentComponent } from '../news/comment/comment.component'; 
import { LoginModule } from '../login/login.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReadMoreModule } from 'ng-readmore';




@NgModule({
  declarations: [RoarComponent, IndexComponent, ViewComponent, CreateComponent, EditComponent,],
  imports: [
    CommonModule,
    RoarRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    ShareButtonsPopupModule,
    ShareIconsModule,
    ShareButtonsModule,
    NgbModule,
    CommentModule,
    EllipsisModule,
    LoginModule,
    NgxPaginationModule,
    
    ReadMoreModule
  
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class RoarModule { }
