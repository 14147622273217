import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SearchComponent } from './search.component';
import { PublicProfileComponent } from '../public-profile/public-profile.component';
const routes: Routes = [

  { 
    path:'search/:search_key_word', component:SearchComponent
  },
  {
    path:'',
    component:SearchComponent
  },
 
  // {
  //   path:'people',
  //   component:SearchComponent
  // },
  // {
  //   path:'news',
  //   component:SearchComponent
  // },
  // {
  //   path:'roar',
  //   component:SearchComponent
  // },
  // {
  //   path:'event',
  //   component:SearchComponent
  // },
  // {
  //   path:'job-centre',
  //   component:SearchComponent
  // },
  // {
  //   path:'judgement',
  //   component:SearchComponent
  // },

  // {
  //   path:'content',
  //   component:SearchComponent
  // },
  // content
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchRoutingModule { }
