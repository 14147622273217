import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public url = environment.baseURL;
  token: string;
  tokenID: any;
  userInfoapi: any;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

public apiURL = environment.baseURL;
  httpClient: any;



  constructor(public http: HttpClient) { 

    if (localStorage.getItem('USERID')) {


      this.token = localStorage.getItem('USERID')
      this.tokenID = jwt_decode(this.token)
      this.userInfoapi = this.tokenID.userdata
    


    }
  }

  post(urlT: any, data: any) {
    const updateUrl = this.url + urlT;
    return this.http.post(updateUrl, data);
  }


  getdata(urlt: any) {
    const getUrl = this.url + urlt;
    
    return this.http.get(getUrl);
  }
  get(urlt: any) {
    const getUrl = this.url + urlt;
   
    return this.http.get(getUrl);
  }

  post_search(formData): Observable<any> {   
  
    return this.http.post(this.apiURL + 'post_search', formData)
    .pipe(
      catchError(this.errorHandler)
    )
  }  

  errorHandler(error:any) { 
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }




}
