import { Injectable } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { ReCaptchaV3Service } from 'ngx-captcha';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  public url = environment.baseURL;
  constructor(public http: HttpClient) { }

  casequery = [
    
    { name: 'Divorce', value: 'Divorce',img:"../../assets/images/icons/icons_frame_1.png",
    content:"``Marriages may be made in heaven, but \
    divorce is granted only by courts``. Special Family \
    Courts hear divorce cases. Depending on the type of marriage (Hindu, Muslim, Christian, Special, etc), \
    there are different grounds for divorce and different procedures. Whether you just want to know your \
    rights, or you are looking for closure in a bad marriage, SoOLegal experts can help."
  },
    { name: 'Property', value: 'Property',img:"../../assets/images/icons/icons_frame_2.png",
    content:"``Property disputes cause the lawsuits in India``. Whether you want to avoid \
    problems by getting the proper documentation, or you need a lawyer to help you get out \
    of a mess, SoOLegal is your best resource. Learn all what you need to check as a buyer \
    or seller of land or buildings. Learn your rights to family property. Protect your \
    dependents from future dispute. Get help with paperwork and proper registration"
  },
    { name: 'POSH',    value: 'POSH',img:"../../assets/images/icons/icons_frame_3.png",  
    content:" In 2013, the Sexual Harassment of Women at Workplace (Prevention, Prohibition and \
    Redressal) Act (commonly called POSH or The Act) became law. POSH was India's first \
    piece of legislation that specifically dealt with workplace sexual harassment of women." 
  },
    { name: 'Cyber Crime', value: 'Cyber Crime',img:"../../assets/images/icons/icons_frame_3.png",
    content:"With use of technology gaining momentum in almost all walks of society, right \
    from corporate governance and state administration, up to the lowest level of petty \
    shopkeepers computerizing their billing system, we find an ever increase in cyber crime. \
    The Information Technology Act 2000 and the I.T. Amendment Act 2008 and other legislations \
    deal with such electronic offences "
   },
    { name: 'Bail',  value: 'Bail',img:"../../assets/images/icons/icons_frame_3.png",
    content:"``Bail is the default, jail is the exception``Need bail fast for yourself or a \
    family member? Be it bail for criminal cases, or cheque bounce cases, or be it for anticipatory\
    bail,SoOLegal's experts can help you with a speedy resolution." 
  },
    { name: 'Will',  value: 'Will',img:"../../assets/images/icons/icons_frame_3.png",
    content:"``You don't need to write a Will if you will never die``. Protect your dependents \
    and safeguard their rights. From knowing the essentials of a will, why it is advisable to\
    register it, and how to change it, to seeking help in when you need to challenge a \
    suspicious will, SoOLegal's experts are there to help you.."  
  },
   
  ];
  location = [
    
    { name: 'New Delhi', value: 'New Delhi' },
    { name: 'Mumbai', value: 'Mumbai' },
    { name: 'Jaipur', value: 'Jaipur' },
    { name: 'Chennai', value: 'Chennai' },
    { name: 'Lucknow', value: 'Lucknow' },
    { name: 'Nagpur', value: 'Nagpur' },
    { name: 'Chandigarh', value: 'Chandigarh' },
  ];

  categorydata=[
   { data:[
      { name: 'Armed Forces Tribunal', value: 'armed-forces-tribunal' },
      { name: 'Aviation Law', value: 'aviation-law' },
      { name: 'Banking & Finance Law', value: 'banking-and-finance-law' },
      { name: 'Bankruptcy/Insolvency', value: 'bankruptcyandinsolvency' },
      { name: 'Civil Litigation', value: 'civil-litigation' },
      { name: 'Competition Law', value: 'competition-law' },
      { name: 'Consumer Protection', value: 'consumer-protection' },
      { name: 'Corporate Law', value: 'corporate-law' },
      { name: 'Criminal Law', value: 'criminal-law' },
      { name: 'Cyber Law', value: 'cyber-law' },
      { name: 'Debt Recovery', value: 'debt-recovery' },
      { name: 'Energy and Natural Resources', value: 'energy-and-natural-resources' },
      { name: 'Energy Law', value: 'energy-law' },
      { name: 'Family/Personal Law', value: 'familyandpersonal-law' },
      { name: 'Immigration Law', value: 'immigration-law' },
      { name: 'Insurance Law', value: 'insurance-law' },
      { name: 'Intellectual Property Rights', value: 'intellectual-property-rights' },
      { name: 'International Law', value: 'international-law' },
      { name: 'Labour Laws', value: 'labour-laws' },
      { name: 'Media Law', value: 'media-law' },
      { name: 'Merger and Amalgamation', value: 'merger-and-amalgamation' },
      { name: 'Motor Vehicle Accidents/ Road Injury', value: 'motor-vehicle-accidentsand-road-injury' },
      { name: 'Municipal Law', value: 'municipal-law' },
      { name: 'Property Law', value: 'property-law' },
      { name: 'Rail Law', value: 'rail-law' },
      { name: 'Real Estate', value: 'real-estate' },
      { name: 'Shipping and Maritime Law', value: 'shipping-and-maritime-law' },
      { name: 'Sports Law', value: 'sports-law' },
      { name: 'Taxation Law', value: 'taxation-law' },
      { name: 'Transportation Law', value: 'transportation-law' },
      { name: 'White Collar Crime', value: 'white-collar-crime' },
      { name: 'Constitutional Law', value: 'constitutional-law' },
      { name: 'Arbitration', value: 'arbitration-2' },
      { name: 'Medical law', value: 'medical-law-2' },
     


    ],name:'Legal Formats',value:'legal-formats'},
    { data:[
      { name: 'Armed Forces Tribunal', value: 'armed-forces-tribunal' },
      { name: 'Aviation Law', value: 'aviation-law' },
      { name: 'Banking & Finance Law', value: 'banking-and-finance-law' },
      { name: 'Bankruptcy/Insolvency', value: 'bankruptcyandinsolvency' },
      { name: 'Civil Litigation', value: 'civil-litigation' },
      { name: 'Competition Law', value: 'competition-law' },
      { name: 'Consumer Protection', value: 'consumer-protection' },
      { name: 'Corporate Law', value: 'corporate-law' },
      { name: 'Criminal Law', value: 'criminal-law' },
      { name: 'Cyber Law', value: 'cyber-law' },
      { name: 'Debt Recovery', value: 'debt-recovery' },
      { name: 'Energy and Natural Resources', value: 'energy-and-natural-resources' },
      { name: 'Energy Law', value: 'energy-law' },
      { name: 'Family/Personal Law', value: 'familyandpersonal-law' },
      { name: 'Immigration Law', value: 'immigration-law' },
      { name: 'Insurance Law', value: 'insurance-law' },
      { name: 'Intellectual Property Rights', value: 'intellectual-property-rights' },
      { name: 'International Law', value: 'international-law' },
      { name: 'Labour Laws', value: 'labour-laws' },
      { name: 'Media Law', value: 'media-law' },
      { name: 'Merger and Amalgamation', value: 'merger-and-amalgamation' },
      { name: 'Motor Vehicle Accidents/ Road Injury', value: 'motor-vehicle-accidentsand-road-injury' },
      { name: 'Municipal Law', value: 'municipal-law' },
      { name: 'Property Law', value: 'property-law' },
      { name: 'Rail Law', value: 'rail-law' },
      { name: 'Real Estate', value: 'real-estate' },
      { name: 'Shipping and Maritime Law', value: 'shipping-and-maritime-law' },
      { name: 'Sports Law', value: 'sports-law' },
      { name: 'Taxation Law', value: 'taxation-law' },
      { name: 'Transportation Law', value: 'transportation-law' },
      { name: 'White Collar Crime', value: 'white-collar-crime' },
      { name: 'Constitutional Law', value: 'constitutional-law' },
      { name: 'Arbitration', value: 'arbitration-2' },
      { name: 'Medical law', value: 'medical-law-2' },
    ],name:'Legal Notices',value:'legal-notices'},
    { data:[
      { name: 'Armed Forces Tribunal', value: 'armed-forces-tribunal' },
      { name: 'Aviation Law', value: 'aviation-law' },
      { name: 'Banking & Finance Law', value: 'banking-and-finance-law' },
      { name: 'Bankruptcy/Insolvency', value: 'bankruptcyandinsolvency' },
      { name: 'Civil Litigation', value: 'civil-litigation' },
      { name: 'Competition Law', value: 'competition-law' },
      { name: 'Consumer Protection', value: 'consumer-protection' },
      { name: 'Corporate Law', value: 'corporate-law' },
      { name: 'Criminal Law', value: 'criminal-law' },
      { name: 'Cyber Law', value: 'cyber-law' },
      { name: 'Debt Recovery', value: 'debt-recovery' },
      { name: 'Energy and Natural Resources', value: 'energy-and-natural-resources' },
      { name: 'Energy Law', value: 'energy-law' },
      { name: 'Family/Personal Law', value: 'familyandpersonal-law' },
      { name: 'Immigration Law', value: 'immigration-law' },
      { name: 'Insurance Law', value: 'insurance-law' },
      { name: 'Intellectual Property Rights', value: 'intellectual-property-rights' },
      { name: 'International Law', value: 'international-law' },
      { name: 'Labour Laws', value: 'labour-laws' },
      { name: 'Media Law', value: 'media-law' },
      { name: 'Merger and Amalgamation', value: 'merger-and-amalgamation' },
      { name: 'Motor Vehicle Accidents/ Road Injury', value: 'motor-vehicle-accidentsand-road-injury' },
      { name: 'Municipal Law', value: 'municipal-law' },
      { name: 'Property Law', value: 'property-law' },
      { name: 'Rail Law', value: 'rail-law' },
      { name: 'Real Estate', value: 'real-estate' },
      { name: 'Shipping and Maritime Law', value: 'shipping-and-maritime-law' },
      { name: 'Sports Law', value: 'sports-law' },
      { name: 'Taxation Law', value: 'taxation-law' },
      { name: 'Transportation Law', value: 'transportation-law' },
      { name: 'White Collar Crime', value: 'white-collar-crime' },
      { name: 'Constitutional Law', value: 'constitutional-law' },
      { name: 'Arbitration', value: 'arbitration-2' },
      { name: 'Medical law', value: 'medical-law-2' },
    ],name:'Legal Procedures',value:'legal-procedures'},
    { data:[
      { name: 'Armed Forces Tribunal', value: 'armed-forces-tribunal' },
      { name: 'Aviation Law', value: 'aviation-law' },
      { name: 'Banking & Finance Law', value: 'banking-and-finance-law' },
      { name: 'Bankruptcy/Insolvency', value: 'bankruptcyandinsolvency' },
      { name: 'Civil Litigation', value: 'civil-litigation' },
      { name: 'Competition Law', value: 'competition-law' },
      { name: 'Consumer Protection', value: 'consumer-protection' },
      { name: 'Corporate Law', value: 'corporate-law' },
      { name: 'Criminal Law', value: 'criminal-law' },
      { name: 'Cyber Law', value: 'cyber-law' },
      { name: 'Debt Recovery', value: 'debt-recovery' },
      { name: 'Energy and Natural Resources', value: 'energy-and-natural-resources' },
      { name: 'Energy Law', value: 'energy-law' },
      { name: 'Family/Personal Law', value: 'familyandpersonal-law' },
      { name: 'Immigration Law', value: 'immigration-law' },
      { name: 'Insurance Law', value: 'insurance-law' },
      { name: 'Intellectual Property Rights', value: 'intellectual-property-rights' },
      { name: 'International Law', value: 'international-law' },
      { name: 'Labour Laws', value: 'labour-laws' },
      { name: 'Media Law', value: 'media-law' },
      { name: 'Merger and Amalgamation', value: 'merger-and-amalgamation' },
      { name: 'Motor Vehicle Accidents/ Road Injury', value: 'motor-vehicle-accidentsand-road-injury' },
      { name: 'Municipal Law', value: 'municipal-law' },
      { name: 'Property Law', value: 'property-law' },
      { name: 'Rail Law', value: 'rail-law' },
      { name: 'Real Estate', value: 'real-estate' },
      { name: 'Shipping and Maritime Law', value: 'shipping-and-maritime-law' },
      { name: 'Sports Law', value: 'sports-law' },
      { name: 'Taxation Law', value: 'taxation-law' },
      { name: 'Transportation Law', value: 'transportation-law' },
      { name: 'White Collar Crime', value: 'white-collar-crime' },
      { name: 'Constitutional Law', value: 'constitutional-law' },
      { name: 'Arbitration', value: 'arbitration-2' },
      { name: 'Medical law', value: 'medical-law-2' },
    ],name:'Law Satutes',value:'law-satutes'}
  ]

  getdata(urlt: any) {
    const getUrl = this.url + urlt;
    return this.http.get(getUrl);
  }

  postdata(urlT: any, data: any) {
  
    const updateUrl = this.url + urlT;
    // const headers = new Headers();
    // headers.append('Accept', 'application/json');
    // headers.append('Content-Type', 'application/json');
    return this.http.post(updateUrl, data);
  }
}
