export class count {
   
  
    new_views: any;
    total_new_connection: any;
    total_pending_connection: any;
    roar_views
    roar_like
    roar_comment
    client
    cases
    document
   

    constructor() {}
  }