import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import{EventregComponent} from './eventreg.component';
import { LoginModule } from '../login/login.module';
// import{SingleCommentComponent} from './src/app/comment/singlecomment.component';

@NgModule({
    declarations: [
      EventregComponent,
    ],
    imports: [
      CommonModule,
      // BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      LoginModule
    ],
    exports: [
      EventregComponent,
    ]
    
  })
  export class EventregModule { 
    
  }
