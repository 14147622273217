import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResourceCentreComponent } from './resource-centre.component';
import { ResourceFormComponent } from './resource-form/resource-form.component';
import { DocFreeComponent } from './docfree/docfree.component';
// import { DocPaidComponent } from './docpaid/docpaid.component';
import { ViewComponent } from './view/view.component';
import { ResearchComponent } from './research/research.component';
import { TranslationComponent } from './translation/translation.component';
// import { DocLoginUserComponent } from './doc-loginUser/doc-loginUser.component';
import { JudgementComponent } from './judgement-form/judgement-form.component';
import { AddToCartComponent } from './add-to-cart/add-to-cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { subcategoryComponent } from './subcategory/subcategory.component';
const routes: Routes = [
  {
    path:'',
    component:ResourceCentreComponent
  },
  {path:'resource-centre/resource-centre-form', component:ResourceFormComponent},
  {path:'resource-centre/:ResourceId/resource-docfree', component:DocFreeComponent},
  // {path:'resource-centre/resource-docpaid', component:DocPaidComponent},
  // {path:'resource-centre/resource-docloginuser', component:DocLoginUserComponent},
  {path:'resource-centre/resource-view/:categoryId', component:ViewComponent},
  {path:'resource-centre/resource-judgement', component:JudgementComponent},
  {path:'resource-centre/translation', component:TranslationComponent},
  {path:'resource-centre/legalresearch', component:ResearchComponent},  
  {path:'resource-centre/add-to-cart', component:AddToCartComponent},
  {path:'resource-centre/checkout', component:CheckoutComponent},
  {path:'resource-centre/resource-viewcategory/:subcategory', component:subcategoryComponent},
      
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResourceCentreRoutingModule { }
