import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule,} from '@angular/forms';
import { ResourceCentreRoutingModule } from './resource-centre-routing.module';
import { ResourceCentreComponent } from './resource-centre.component';
import { ResourceFormComponent } from './resource-form/resource-form.component';
import { DocFreeComponent } from './docfree/docfree.component';
import { TranslationComponent } from './translation/translation.component';
import { JudgementComponent } from './judgement-form/judgement-form.component';
import { ResearchComponent } from './research/research.component';
import { ViewComponent } from './view/view.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxClampModule } from 'ngx-clamp';
import { CommentModule } from '../comment/comment.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoginModule } from '../login/login.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FilterPipeModule } from 'ng2-nested-search';
import { AddToCartComponent } from './add-to-cart/add-to-cart.component';
import { subcategoryComponent } from './subcategory/subcategory.component';
import { CheckoutComponent } from './checkout/checkout.component';
@NgModule({
  declarations: [ResourceCentreComponent,subcategoryComponent,TranslationComponent,JudgementComponent,ResearchComponent,ResourceFormComponent,DocFreeComponent,ViewComponent, AddToCartComponent,CheckoutComponent],
  imports: [
    CommonModule,
    ResourceCentreRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    CommentModule,
    NgxPaginationModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxClampModule,
    ShareModule,
    ShareButtonsPopupModule,
    ShareIconsModule,
    ShareButtonsModule,
    LoginModule,
    SlickCarouselModule,
    Ng2SearchPipeModule,
    FilterPipeModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class ResourceCentreModule { }
