<div class="container form-rc1">
    <form class="needs-validation" [formGroup]="basicFormGroup" novalidate="">
        <div class="row">
            <div class="col-md-8 col-lg-8 form-area ">
                <h4 class="mb-3">Billing address</h4>
                <div class="row g-3">
                    <div class="col-sm-6">
                        <label for="firstName" class="form-label">First name</label>
                        <input type="text" formControlName="firstName" class="form-control" id="firstName" placeholder="" [required]="true">
                        <div *ngIf="submitted && form['firstName'].errors" class="invalid-feedback">
                            <div *ngIf="form['firstName'].errors['required']"> Valid first name is required.</div>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label for="lastName" class="form-label">Last name</label>
                        <input type="text" formControlName="lastName" class="form-control" id="lastName" placeholder="" [required]="true">
                        <div *ngIf="submitted && form['lastName'].errors" class="invalid-feedback">
                            <div *ngIf="form['lastName'].errors['required']"> Valid last name is required.</div>
                        </div>
                    </div>

                    <div class="col-12">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" formControlName="email" class="form-control" id="email" placeholder="" [required]="true">
                        <div *ngIf="submitted && form['email'].errors" class="invalid-feedback">
                            <div *ngIf="form['email'].errors['required']">Please enter a valid email address for shipping updates.</div>
                        </div>
                    </div>

                    <div class="col-12">
                        <label for="phone" class="form-label">Contact Number</label>
                        <input type="number" formControlName="phone" class="form-control" id="phone" placeholder="" [required]="true">
                        <div *ngIf="submitted && form['phone'].errors" class="invalid-feedback">
                            <div *ngIf="form['phone'].errors['required']">Please enter a valid Phone Number for shipping updates.</div>
                        </div>
                    </div>

                    <div class="col-12">
                        <label for="gstn" class="form-label">GSTN</label>
                        <input type="text" formControlName="gstn" class="form-control" id="gstn" placeholder="" [required]="true">
                        <div *ngIf="submitted && form['gstn'].errors" class="invalid-feedback">
                            <div *ngIf="form['gstn'].errors['required']"> Please enter your GST Number</div>
                        </div>
                    </div>

                    <div class="col-12">
                        <label for="Company" class="form-label">Company</label>
                        <input type="text" formControlName="company" class="form-control" id="Company" placeholder="">
                    </div>


                    <div class="col-12">
                        <label for="address" class="form-label">Address</label>
                        <input type="text" formControlName="address" class="form-control" id="address" placeholder="" [required]="true">
                        <div *ngIf="submitted && form['address'].errors" class="invalid-feedback">
                            <div *ngIf="form['address'].errors['required']"> Please enter your shipping address.</div>
                        </div>
                    </div>


                    <div class="col-md-5">
                        <label for="country" class="form-label">Country</label>
                        <select formControlName="country" class="form-select" id="country" (change)="changeSelect($event.target.value, 'country')" [required]="true">
                            <option value="">Choose...</option>
                            <option>India</option>
                          </select>
                        <div *ngIf="submitted && form['country'].errors" class="invalid-feedback">
                            <div *ngIf="form['country'].errors['required']"> Please select a valid country.</div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="state" class="form-label">State</label>
                        <select formControlName="state" class="form-select" id="state" (change)="changeSelect($event.target.value, 'state')" [required]="true">
                            <option value="">Choose...</option>
                            <option>Tamil Nadu</option>
                          </select>
                        <div *ngIf="submitted && form['state'].errors" class="invalid-feedback">
                            Please provide a valid state.
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="zip" class="form-label">Zip</label>
                        <input formControlName="zipCode" type="text" class="form-control" id="zip" placeholder="" [required]="true">
                        <div *ngIf="submitted && form['zipCode'].errors" class="invalid-feedback">
                            <div *ngIf="form['zipCode'].errors['required']"> Zip code required.</div>
                        </div>
                    </div>
                </div>

                <hr class="my-4">

                <div class="form-check">
                    <input type="checkbox" formControlName="sameAddress" class="form-check-input" id="same-address" (change)="setValidation($event,'sameAddress')">
                    <label class="form-check-label" for="same-address">Shipping address is the same as my billing address</label>
                </div>

                <div class="form-check">
                    <input type="checkbox" formControlName="saveInfo" class="form-check-input" id="save-info" value="yes" (change)="setValidation($event,'saveInfo')">
                    <label class="form-check-label" for="save-info">Save this information for next time</label>
                </div>

            </div>
            <div class="col-md-4 col-lg-4">
                <span id="render-amt-summary">
                <div class="ibox-title">
                    <h5>Cart Summary</h5>
                </div>
                <hr>
                <div class="text-navy">
                    <large>Subtotal</large>
                    <small class="pull-right">
                        <i class="fa fa-inr ft-12"></i> {{totalcartamt}}/-
                    </small>
                </div>
                <div class="ft-16" style="background: #ecdbec; padding: 10px;">
                    <div>
                        <b>GST</b>
                        <small class="pull-right text-white"></small>
                        <br>
                        <b style="padding-left: 9px;">
                            <small>- CGST @9%</small>
                        </b>
                        <small></small>
                        <small class="pull-right text-success">
                            <b>
                                <i class="fa fa-inr ft-12"></i>{{cgstamt}}
                            </b>
                        </small>
                        <br>
                        <b style="padding-left: 9px;">
                            <small>- SGST @9%</small>
                        </b>
                        <small></small>
                        <small class="pull-right text-success">
                            <b>
                                <i class="fa fa-inr ft-12"></i>{{sgstamt}}
                            </b>
                        </small>
                    </div>
                </div>
                <div style="background: #90278e;padding: 10px;color: #fff;">
                    <b>Total</b>
                    <small class="pull-right text-white">
                        <b>
                            <i class="fa fa-inr ft-12"></i> {{TotalTaxamt}}
                        </b>
                    </small>
                </div>
                &nbsp;
                <button (click)="onSubmit('save')" style="color: #90278e; background-color:#fff; border-color: #90278e;" class="w-100 btn btn-primary btn-lg" type="submit">Make Payment</button>
                </span>
            </div>
        </div>
    </form>
</div>