import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { BarAssociationService } from '../bar-association.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Barassociation } from '../barassociation';
import { EventService } from '../../event/event.service';
@Component({
    selector: 'app-committemembers',
    templateUrl: './committemembers.component.html',
    styleUrls: ['./committemembers.component.css']
  
  })
  export class CommitteComponent implements OnInit {
   
    constructor(public barAssociationService:BarAssociationService,public eventService:EventService, private route: ActivatedRoute,) { }
    barassociation:Barassociation[]=[];
    ng2NestedSearch:any;
    searchName : string = "";
    searchEmail : string = "";
    searchNumber : string = "";
    filterMetadata = { count: 0, isNestedSearch:true };
    limit1:any;


    ngOnInit(): void {
        var bar_association_id = this.route.parent.snapshot.paramMap.get('barId');
        this.getBarCommitte(bar_association_id);
        this.limit();
      }
      getBarCommitte(barId){
        
        this.barAssociationService.bar_association_commiteemember(barId).subscribe((res: Barassociation[]) => {
          this.barassociation = res;
          
        });
      }
      limit(){
        this.eventService.limit().subscribe((com:any) => {
          this.limit1 = com;
     
      
        });
       }
    }