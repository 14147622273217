import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { text } from '@fortawesome/fontawesome-svg-core';
import { response } from 'express';
import { Observable, ObservableInput } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
 

@Injectable({
  providedIn: 'root'
})
export class ContactusService {


  contacturl: string = 'https://api.beta.soolegal.com/public/api/contact';


  ContactusService: string
  reset: any;

constructor(private http: HttpClient,private toastr: ToastrService) { }

  PostMessage(input: any) {
    return this.http.post(this.contacturl, input, { responseType: 'text' })
      .pipe(
        map(
          (response) => {
            if (response) {
              return response;
            }else{
              return null;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )

    }
    showSuccess(message, title){
      this.toastr.success(message, title)
  }

  // postcontact(urlT: any, data: any) {
  //   const contacturl = this.url + urlT;
  //   const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('USERID')); // may be localStorage/sessionStorage
  //   const headers = { headers: header };

  //   return this.http.post(contacturl, data, headers)
  //     .pipe(
  //       catchError(this.errorHandler)
  //     )
  // }

}
