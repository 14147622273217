import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { BarAssociationService } from '../bar-association.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Barassociation } from '../barassociation';
import { EventService } from '../../event/event.service';

@Component({
    selector: 'app-aboutus',
    templateUrl: './aboutus.component.html',
  
  })
  export class AboutusComponent implements OnInit {
   
    constructor(public barAssociationService:BarAssociationService, private route: ActivatedRoute,public eventService:EventService,) { }
    barassociation:Barassociation;
    Limit:Event[]=[];


    ngOnInit(): void {
        var bar_association_id = this.route.parent.snapshot.paramMap.get('barId');
        this.getBarAboutus(bar_association_id);
        this.limit();
      }
      getBarAboutus(barId){
        let bar_id=barId;
        const formData = new FormData();
        formData.append('bar_id', bar_id + '');
        console.log('aaaaaaaaaaaaaaaaaaaaaaaaaa',bar_id);
        this.barAssociationService.bar_association_aboutus(barId).subscribe((res: Barassociation) => {
          this.barassociation = res[0];
          console.log('123456',this.barassociation)
        });
      }

      limit(){
        this.eventService.limit().subscribe((com:Event[]) => {
          this.Limit = com;
     
      
        });
       }
    }