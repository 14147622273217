<!-- <div class="modal fade" id="findLawer" tabindex="-1" aria-labelledby="findLawer" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0">

            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button> -->

            <div class="modal-body popup-body">
                <!-- <h2>Add Events</h2> -->
                <form [formGroup]="myform" (ngSubmit)="submit()">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="title">Title<span *ngIf="submitted && f.title.errors.required" style="color:red">  (Required)</span></label>
                            <input type="text" class="form-control" formControlName="title"
                                style="background-color: white;" id="inputEmail4" placeholder="">
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <label for="image">Select images<span *ngIf="submitted && f.banner.errors.required" style="color:red">  (Required)</span></label>
                            <div class="form-group"><input type="file" name="url" formControlName="banner" id="url"
                                    (change)="onFileChange($event)" class="filestyle" value="" data-icon="false"
                                    tabindex="-1" style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
                                <div class="bootstrap-filestyle input-group"><input type="text"
                                        class="form-control no-padd " placeholder="" disabled=""
                                        style="background-color: white;"
                                        [value]="eventImage.name?eventImage.name:(existingEvent?existingEvent.banner:'')">
                                    <span class="group-span-filestyle input-group-btn" tabindex="0"><label for="url"
                                            class="btn btn-default "><span
                                                class="buttonText">Choose</span></label></span></div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">

                        <div class="form-group col-md-3">
                            <label for="publishdate">Start Date<span *ngIf="submitted && f.start_date.errors.required" style="color:red">  (Required)</span></label><input type="date" name="publishdate" value=""
                                style="background-color: white;" formControlName="publish_date" id="publishdate"
                                formControlName="start_date" placeholder="Start_date"
                                class="form-control timepicker hasDatepicker">
                        </div>
                        <div class="form-group col-md-3">
                            <label for="publishdate">End Date <span *ngIf="submitted && f.end_date.errors.required" style="color:red">  (Required)</span></label><input type="date" name="publishdate" value=""
                                style="background-color: white;" formControlName="publish_date" id="publishdate"
                                formControlName="end_date" placeholder="end_date"
                                class="form-control timepicker hasDatepicker">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="VenueDetails">Venue Details <span *ngIf="submitted && f.venue_details.errors.required" style="color:red">  (Required)</span></label>
                            <input type="text" class="form-control" formControlName="venue_details"
                                style="background-color: white;" id="inputEmail4" placeholder="">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="tags">Tags<span *ngIf="submitted && f.tags.errors.required" style="color:red">  (Required)</span></label>
                            <input type="text" class="form-control" formControlName="tags"
                                style="background-color: white;" id="inputtags" placeholder="">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="eventCategory">Event Category<span *ngIf="submitted && f.event_category.errors.required" style="color:red">  (Required)</span></label>
                            <!-- <input type="text" class="form-control value" formControlName="event_category" id="inputcategory"
                                style="background-color: white;" placeholder=""> -->
                                <select id="option-size" formControlName="event_category" class="form-control" style="background-color:white">
                                    <option value="Seminar" >Seminar</option>
                                    <option  value="Workshop"> Workshop </option>
                                    <option  value="MaterClass"> MaterClass </option>
                                    <option  value="courses"> courses </option>


                                       
                                </select>
                                <!-- <div class="pretty-setting"> -->
                                    <!-- <select id="option-size" formControlName="select_city" class="custom-control">
                                        <option value="Select City" >Select City</option>
                                        <option  value="" selected="">
                                            event </option>
    
    
                                    </select> -->
                                    <!-- <span class="text"><span class="label"></span><span class="value"
                                            formControlName="select_city">Select
                                            City</span></span> -->
                                    <!-- <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
                                        fill="#ccc" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg> -->
                                </div>
                        </div>
                    <!-- </div> -->

                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="note">Content<span *ngIf="submitted && f.description.errors.required" style="color:red">  (Required)</span></label>
                            <textarea name="description" formControlName="description" id="your_summernote" class="form-control bg-white"
                                rows="4"></textarea>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <label>
                                <input type="radio" value="0" name="event-registration" class="radio"formControlName="paidtype"
                                (change)="registration($event)" />
                                <span class="radio-style d-inline-block"></span>
                                <span>Free Event Registration </span>
                            </label>
                        </div>

                        <div class="col-md-4">
                            <label>
                                <input type="radio" value="1" name="event-registration" class="radio"formControlName="paidtype"
                                (change)="registration($event)" />
                                <span class="radio-style d-inline-block"></span>
                                <span>Paid Event Registration </span>
                            </label>
                        </div>

                        <div class="col-md-4">
                            <input type="text" formControlName="amount" class="form-control m-0 rounded-0 bg-white" id="exampleInputEmail1"
                                aria-describedby="phoneHelp" placeholder="Event Registration Amount" />
                        </div>
                    </div>


                    <div class="mt-4">
                        <button type="submit"  class="d-block btn btn-primary mb-0 mx-auto">Submit</button>
                    </div>
                </form>

            </div>
        <!-- </div>
    </div>
</div> -->

<app-login (loginEvent)="loginSubmit($event)"></app-login>


<!-- PopUp Box for event details -->