import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { Event } from './event';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private apiURL = environment.baseURL;
    
 
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private httpClient: HttpClient) { }

  // getAll(type: string): Observable<Event[]> {
  //   return this.httpClient.get<Event[]>(this.apiURL + '/event?type=' + type)
  //   .pipe(
  //     catchError(this.errorHandler)
  //   )
  // }
  getAll(id,venue_details,start_date,end_date,event_category,event_type,limit): Observable<Event[]> {
    return this.httpClient.get<Event[]>(this.apiURL + 'event?venue_details='+ venue_details + '&start_date=' + start_date + '&end_date='+ end_date +'&event_category='+event_category+'&event_type='+event_type+'&limit='+limit)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  create(formData): Observable<any> {
    const header = new HttpHeaders({'JWTAuthorization': localStorage.getItem('USERID')}); 
    const headers = { headers: header };
    console.log(headers, localStorage.getItem('USERID'));
  
    return this.httpClient.post(this.apiURL + 'post_event', formData,headers)
    .pipe(
      catchError(this.errorHandler)
    )
  }  

  find(id: number): Observable<any> {

    // return this.httpClient.get(this.apiURL + 'event/' + id)
    return this.httpClient.get(this.apiURL + 'show_feed_data/' + id)

      .pipe(
        catchError(this.errorHandler)
      )
  }
  delete(id: number) {
    const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('USERID') });
    const headers = { headers: header };
    console.log(headers, localStorage.getItem('USERID'));
    return this.httpClient.delete(this.apiURL + 'event/' + id, headers)

      .pipe(
        catchError(this.errorHandler)
      )
  }

  sorting(){
    return this.httpClient.get(this.apiURL + 'sort_venuedetails' )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  sort_eventcategory(){
    return this.httpClient.get(this.apiURL + 'sort_eventcategory' )
    .pipe(
      catchError(this.errorHandler)
    )
  }
  limit(){
    return this.httpClient.get(this.apiURL + 'limit' )
    .pipe(
      catchError(this.errorHandler)
    )
  }

  errorHandler(error:any) { 
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }

}
