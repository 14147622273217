




    <main role="main">
        <div class="h-50"></div>
        <div class="container">

        <!-- <pre>{{event | json}}</pre> -->
            <section class="component-box-1">
                <div class="user-info-box-main">
                    <div class="user-info-box ">
                        <h4>{{event.event_title}}</h4>
                        <div class="user-info-box-inner no-scroll p-0" >
                            <img [src]="getEventImage(event)" class="img-fluid">
                        </div>
                    </div>
                </div> 
            </section>
            <!-- <a title="Edit" *ngIf="memberapi.userInfoapi?.member_id"
            class=" btn-xs " [attr.href]="'/event/' + event.id +'/edit'"><i
                class="fas fa-edit"></i></a> -->
            <div class="clear text-right"><a  class="btn text-white color-gray" tabindex="0"><i
                        class="fas fa-share-alt" aria-hidden="true" (click)="triggerModal(modalData)"></i> 
                        <ng-template #modalData let-modal>
                            <div class="modal-header">
                              <h4 class="modal-title" id="modal-basic-title">Share</h4>
                              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="modal-body">
                                <share-buttons [theme]="'material-dark'"
                                [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                                [show]="15"
                                
                                [url]="getLocation(event.id)"
                                
                                [autoSetMeta]="false"
                               ></share-buttons>
                              
                              
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Close</button>
                            </div>
                          </ng-template>
                    </a> <br>Share</div>


                              
            <div class="row">


                <div class="col-md-9 ">

                    <div class="content">

                        <p [innerHtml]="event.event_description">
                        </p>
                    </div>
                    <div class="row">
                        <div class="col-md-5 ">
                            <div class="date font-weight-bold d-flex justify-content-between mt-4 mb-4">

                                <div> From:<br>
                                   {{event.event_start_date*1000|date:'yyyy/MM/dd'}}
                                </div>
                                <div>
                                    Till:<br>
                                    {{event.event_end_date*1000|date:'yyyy/MM/dd'}}
                                </div>
                            </div>
                            <div class="text-content m-0">

                                <!-- <p>
                                    Quisque nunc lorem, interdum ultrices nibh tristique, elementum vestibulum odio.
                                    Nunc maximus ante quis sapien blandit, vel malesuada massa euismod. Suspendisse
                                    fringilla quam vel eros interdum varius. Phasellus eget massa eu justo maximus
                                    molestie non et sapien. Maecenas ligula nunc, sodales in metus in, eleifend
                                    vulputate nisi. Duis eu sodales diam, nec lacinia felis. Nam non maximus sapien.
                                    Etiam ultricies dictum nulla vitae volutpat. Duis efficitur orci a lectus faucibus,
                                    id dapibus neque lobortis. Nullam porttitor turpis elit, sit amet rutrum augue
                                    hendrerit quis.
                                </p> -->
                            </div>
                            <!-- <a [attr.href]="'/event/' + event.id +'/add'" class="btn btn-primary bg-black font-weight-normal">Register</a> -->
                        </div>
                        <div class="col-md-7">
                            <form [formGroup]="myForm" (ngSubmit)="submit()">
                            <div class="top-30">
                                <!-- <div class="component-box-1 mb-0 ">
                                    <div class="user-info-box-main">
                                        <div class="user-info-box">
                                            <h4 class="mb-0"></h4>
                                            <div
                                                class="user-info-box-inner no-scroll mh-0 no-scroll mh-0 pl-0 pr-0 form-elements form-elements bg-transparent">
                                                <div class="row no-gutters mb-1">
                                                    <div class="col-md-12">
                                                        <div class="custom-input-bg">
                                                            <input type="text" formControlName="name"
                                                                class="form-control rounded-0 custom-input-bg "
                                                                placeholder="Full Name">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters mb-1">


                                                    <div class="col-md-12">
                                                        <div class="custom-input-bg">
                                                            <input type="text" formControlName="mobile_or_email"
                                                                class="form-control rounded-0 custom-input-bg "
                                                                placeholder="Mobile Number/Email">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-left legalChooseOptions mt-3 mb-3">
                                                    <div class="row pl-3 pr-3 pt-1 pb-1  ">
                                                        <article class="col-md-3 text-center" >
                                                          <p class="text-center"> As Visitor </p>
                                                            <label>
                                                                <input type="radio" value="1" name="services" class="radio" formControlName="event" (change)="changeevent($event)">
                                                                <span class="radio-style border-black"></span>
                                                            </label>
                                                        </article>
                                                        <article class="col-md-3 text-center">

                                                            <p class="text-center"> As Speaker </p>


                                                            <label>
                                                                <input type="radio"  value="2" name="services" class="radio" formControlName="event" (change)="changeevent($event)">
                                                                <span class="radio-style border-black"></span>
                                                            </label>
                                                       </article>
                                                        <article class="col-md-3 text-center">

                                                            <p class="text-center"> As Exhibitor </p>


                                                            <label>
                                                                <input type="radio" value="3" name="services" class="radio" formControlName="event" (change)="changeevent($event)">
                                                                <span class="radio-style border-black"></span>
                                                            </label>
                                                        </article>
                                                        <article class="col-md-3 text-center">
                                                            <p class="text-center"> As Sponsor </p>
                                                            <label>
                                                                <input type="radio" value="4" name="services" class="radio" formControlName="event" (change)="changeevent($event)">
                                                                <span class="radio-style border-black"></span>
                                                            </label>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>
                                       </div>
                                    </div>

                                </div> -->
                                <div class="text-center float-right">
                                    <!-- <button type="submit"   class="btn btn-primary bg-black font-weight-normal">Register
                                        Here</button> -->
                                        <a [attr.href]="'/event/' + event.id +'/add'" class="btn btn-primary bg-black font-weight-normal">Register</a>
                                </div>
                                <!-- <div *ngIf="event.paidtype==1" class="text-center float-right">
                                    <button type="submit"  class="btn btn-primary bg-black font-weight-normal">Register
                                        and Pay</button>
                                </div>
                                <div *ngIf="event.paidtype==2" class="text-center float-right">
                                    <button type="submit"  class="btn btn-primary bg-black font-weight-normal">Registe
                                        Here</button>
                                </div> -->
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <section class="component-box-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box ">
                                <h4>Lawserv 5th Edition</h4>
                                <div class="user-info-box-inner h-0 no-scroll p-0">

                                    <div class="bg-white sideContent p-2">
                                        Content

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>




                    <section class="component-box-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box ">
                                <h4>Lawserv 5th Edition</h4>
                                <div class="user-info-box-inner h-0 no-scroll p-0">

                                    <div class="bg-white sideContent p-2">
                                        Content

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>





                    <section class="component-box-1">
                        <div class="user-info-box-main">
                            <div class="user-info-box ">
                                <h4>Lawserv 5th Edition</h4>
                                <div class="user-info-box-inner h-0 no-scroll p-0">

                                    <div class="bg-white sideContent p-2">
                                        Content

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>






                </div>


            </div>






            <div class="h-25 d-inline-block"></div>
            <section class="title-bg-2 my-4 ipad-hide phone-hide">
                <div class="container">
                    <div class="row">
                        <div class="col-md-2 #90278E-bg-new order-md-12">
                            <div class="triangle-bottomleft"></div>
                            <div class="icon-box"></div>
                        </div>
                        <div class="col-md-10 white-bg-new">
                            <div class="triangle-topleft"></div>
                            <span class="gray-bg">Go Premium</span>
                            <h3>Be at ease with SoOLegal's Cloud &amp; AI powered DIY assistance</h3>
                        </div>
                    </div>
                </div>
            </section>


        </div>



        <div class="h-25 d-inline-block"></div>

        <section class="profile-slider">
            <div class="container">
                <div class="profile m-0 p-0">
                    <article class="component-box-1 component-box-bg-light">
                        <div class="highlight-box">
                            <div class="user-box text-center">
                                <div class="img-box"></div>

                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sagittis molestie
                                    scelerisque. Maecenas ac ante quam. Proin sodales odio nec tortor convallis, sed
                                    feugiat mi efficitur. Nam consequat purus lorem, non rhoncus
                                    velit laoreet eget. Nulla nec suscipit nunc, sit amet rutrum nulla.
                                </p>

                                <p><a href="#" class="link">Name</a></p>
                            </div>
                        </div>
                    </article>

                    <article class="component-box-1 component-box-bg-light">
                        <div class="highlight-box">
                            <div class="user-box text-center">
                                <div class="img-box"></div>

                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sagittis molestie
                                    scelerisque. Maecenas ac ante quam. Proin sodales odio nec tortor convallis, sed
                                    feugiat mi efficitur. Nam consequat purus lorem, non rhoncus
                                    velit laoreet eget. Nulla nec suscipit nunc, sit amet rutrum nulla.
                                </p>
                                <p><a href="#" class="link">Name</a></p>
                            </div>
                        </div>
                    </article>

                    <article class="component-box-1 component-box-bg-light">
                        <div class="highlight-box">
                            <div class="user-box text-center">
                                <div class="img-box"></div>

                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sagittis molestie
                                    scelerisque. Maecenas ac ante quam. Proin sodales odio nec tortor convallis, sed
                                    feugiat mi efficitur. Nam consequat purus lorem, non rhoncus
                                    velit laoreet eget. Nulla nec suscipit nunc, sit amet rutrum nulla.
                                </p>
                                <p><a href="#" class="link">Name</a></p>
                            </div>
                        </div>
                    </article>

                    <article class="component-box-1 component-box-bg-light">
                        <div class="highlight-box">
                            <div class="user-box text-center">
                                <div class="img-box"></div>

                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sagittis molestie
                                    scelerisque. Maecenas ac ante quam. Proin sodales odio nec tortor convallis, sed
                                    feugiat mi efficitur. Nam consequat purus lorem, non rhoncus
                                    velit laoreet eget. Nulla nec suscipit nunc, sit amet rutrum nulla.
                                </p>
                                <p><a href="#" class="link">Name</a></p>
                            </div>
                        </div>
                    </article>

                    <article class="component-box-1 component-box-bg-light">
                        <div class="highlight-box">
                            <div class="user-box text-center">
                                <div class="img-box"></div>

                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sagittis molestie
                                    scelerisque. Maecenas ac ante quam. Proin sodales odio nec tortor convallis, sed
                                    feugiat mi efficitur. Nam consequat purus lorem, non rhoncus
                                    velit laoreet eget. Nulla nec suscipit nunc, sit amet rutrum nulla.
                                </p>
                                <p><a href="#" class="link">Name</a></p>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </section>






   


