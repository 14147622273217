<main role="main">
    <section class="ipad-hide phone-hide">
      <div class="container-fluid inner-title">
        <div class="row">
          <div class="col-md-1 pl-0 text-right"><span>NEWS MAIN</span></div>
          <div class="col-md-10">Delhi HC directs Singh Brothers to Maintain Status-quo over Assets & Properties | Delhi
            HC directs...</div>
          <div class="col-md-1 pr-0 text-left"><span>NEWS MAIN</span></div>
        </div>
      </div>
    </section>

    <section class="public-profile section">
      <div class="container-fluid width">
        <div class="row">
          <div class="col-md-12 col-sm-6">
            
        <div class="user-info-box-inner no-scroll eventSlider">
          <!-- 1 -->
          <div class="text-left">
            <div class="m-0 p-0 text-left sec">
              <img class="d-block" src="https://lawstreet.co/secure/uploads/2022/01/lj_4105_Covid.jpg" alt="">
              <div class="captionbg d-md-block">
                <h3>Supreme Court Again Extends The Limitation Period To File Cases Due To COVID-19</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci esse vitae exercitationem fugit,
                  numquam minus! Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci esse vitae
                  exercitationem fugit, numquam minus!</p>
              </div>
            </div>
          </div>
          <!-- 2 -->
          <div class="text-left">
            <div class="m-0 p-0 text-left sec">
              <img class="d-block" src="https://lawstreet.co/secure/uploads/2022/01/lj_5386_modi_s.jpg" alt="">
              <div class="captionbg d-md-block">
                <h3>Supreme Court forms an Independent Committee to Probe PM Modi Security Lapse.</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci esse vitae exercitationem fugit,
                  numquam minus!</p>
              </div>
            </div>
          </div>
          <!-- 3 -->
          <div class="text-left">
            <div class="m-0 p-0 text-left sec">
              <img class="d-block" src="images/lj_9233_Amaz.jpg" alt="">
              <div class="captionbg d-md-block">
                <h3>Amazon moves Supreme Court against Delhi HC division bench order staying arbitration with Future
                  Group before Singapore tribunal (SIAC)</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci esse vitae exercitationem fugit,
                  numquam minus!</p>
              </div>
            </div>
          </div>
        </div>
        </div>
   
        <!--Slider ends-->
        </div>
        <div class="h-20"></div>
        <div class="h-20"></div>
        <div class="col-md-12">
        <h3 class="news-title">News Headlines</h3>
        <div class="add-newbtn">
        <a title="Add News"  class="btn btn-primary btn-xs pull-right" href="https://test.soolegal.com/news/add"><i class="fa fa-plus fa-fw"></i> News</a>
      </div>
      </div>
        <div class="h-20"></div>
        <div class="component-box-1 pt-0">
          <div class="user-info-box-main">
            <div class="user-info-box">
              <div class="user-info-box-inner no-scroll p-0 m-0">
                <div class="advanced-search">
                  <div id="profile-details">
                    <ul class="nav nav-pills nav-fill" id="pills-tab" role="tablist">
                      <li class="nav-item dropdown dis">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                          aria-haspopup="true" aria-expanded="false">Dropdown <i class="fas fa-caret-down"
                            aria-hidden="true"></i></a>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" id="resource-center-tab" data-toggle="pill" href="#latest-news"
                            role="tab" aria-controls="resource-center" aria-selected="true">Latest News</a>
                          <a class="dropdown-item" id="legal-formats-tab" data-toggle="pill" href="#inter-news"
                            role="tab" aria-controls="legal-formats" aria-selected="false">International News</a>
                          <a class="dropdown-item" id="legal-notices-tab" data-toggle="pill" href="#my-news" role="tab"
                            aria-controls="legal-notices" aria-selected="false">My News</a>
                          <a class="dropdown-item" id="legal-research-tab" data-toggle="pill" href="#favorites"
                            role="tab" aria-controls="legal-research" aria-selected="false">Favorites</a>
                        </div>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="resource-center-tab" data-toggle="pill" href="#latest-news"
                          role="tab" aria-controls="resource-center" aria-selected="true">Latest News</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="legal-formats-tab" data-toggle="pill" href="#inter-news" role="tab"
                          aria-controls="legal-formats" aria-selected="false">International News</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="legal-notices-tab" data-toggle="pill" href="#my-news" role="tab"
                          aria-controls="legal-notices" aria-selected="false">My News</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="legal-research-tab" data-toggle="pill" href="#favorites" role="tab"
                          aria-controls="legal-research" aria-selected="false">Favorites</a>
                      </li>
                    </ul>
                    <div class="tab-content p-4" id="pills-tabContent">
                      <!-- Latest News -->
                      <article class="tab-pane fade show active" id="latest-news" role="tabpanel"
                        aria-labelledby="probile-tab">

                        <!-- card -->
                        <div id="jar">
                          <div class="row mx-auto content">
                            <article class="row mt-2 mb-4">
                              
                              <div class="col-md-4">
                                <div class="icon">
                                  <img class="lazy blog-img-thumbnail img-responsive img-thumbnail"
                                    src="https://lawstreet.co/secure/uploads/2022/01/lj_4105_Covid.jpg">
                                </div>
                              </div>

                              <div class="col-md-8">
                                <div class="text-left pull-left iconclr">
                                  <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-eye"
                                      aria-hidden="true"></i></a>
                                  <a href="#" class="btn text-white color-gray" tabindex="0"><i
                                      class="far fa-bookmark" aria-hidden="true"></i></a>
                                  <a href="#" class="btn text-white color-gray" tabindex="0"><i
                                      class="fas fa-share-alt" aria-hidden="true"></i></a>

                                </div>
                                <div class="desc">
                                  <h1><a href="#">
                                      Supreme Court Again Extends The Limitation Period To File Cases Due To
                                      COVID-19</a></h1>

                                  <p> <a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci esse
                                      vitae exercitationem fugit, numquam minus! Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Adipisci esse vitae exercitationem fugit, numquam minus!</a></p>
                                  <p class="small link"> <span class="author-name">Legal Formats,</span> <span
                                      class="author-date">Date,</span> <span class="author-time">Time.</span>
                                  </p>
                                  
                                </div>
                              </div>
                            </article>
                            <!-- End Card -->
                          </div>
                          <!-- 2 -->
                          <div class="row mx-auto content">
                            <article class="row mt-2 mb-4">
                              <div class="col-md-4">
                                <div class="icon">
                                  <img class="lazy blog-img-thumbnail img-responsive img-thumbnail"
                                    src="https://lawstreet.co/secure/uploads/2022/01/lj_4503_Cov.jpg">
                                </div>
                              </div>

                              <div class="col-md-8">
                                <div class="text-left pull-left iconclr">
                                  <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-eye"
                                      aria-hidden="true"></i></a>
                                  <a href="#" class="btn text-white color-gray" tabindex="0"><i
                                      class="far fa-bookmark" aria-hidden="true"></i></a>
                                  <a href="#" class="btn text-white color-gray" tabindex="0"><i
                                      class="fas fa-share-alt" aria-hidden="true"></i></a>

                                </div>
                                <div class="desc">
                                  <h1><a href="#">
                                      Election Commission Focuses On Vaccinations</a></h1>

                                  <p> <a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci esse
                                      vitae exercitationem fugit, numquam minus! Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Adipisci esse vitae exercitationem fugit, numquam minus!</a></p>
                                  <p class="small link"> <span class="author-name">Legal Formats,</span> <span
                                      class="author-date">Date,</span> <span class="author-time">Time.</span>
                                  </p>
                                  
                                </div>
                              </div>
                            </article>
                            <!-- End Card -->
                          </div>
                          <!-- 3 -->
                          <div class="row mx-auto content">
                            <article class="row mt-2 mb-4">
                              <div class="col-md-4">
                                <div class="icon">
                                  <img class="lazy blog-img-thumbnail img-responsive img-thumbnail"
                                    src="https://lawstreet.co/secure/uploads/2022/01/lj_9233_Amaz.jpg">
                                </div>
                              </div>

                              <div class="col-md-8">
                                <div class="text-left pull-left iconclr">
                                  <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-eye"
                                      aria-hidden="true"></i></a>
                                  <a href="#" class="btn text-white color-gray" tabindex="0"><i
                                      class="far fa-bookmark" aria-hidden="true"></i></a>
                                  <a href="#" class="btn text-white color-gray" tabindex="0"><i
                                      class="fas fa-share-alt" aria-hidden="true"></i></a>

                                </div>
                                <div class="desc">
                                  <h1><a href="#">
                                      Amazon moves Supreme Court against Delhi HC division bench order staying
                                      arbitration with Future Group before Singapore tribunal (SIAC)</a></h1>

                                  <p> <a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci esse
                                      vitae exercitationem fugit, numquam minus! Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Adipisci esse vitae exercitationem fugit, numquam minus!</a></p>
                                  <p class="small link"> <span class="author-name">Legal Formats,</span> <span
                                      class="author-date">Date,</span> <span class="author-time">Time.</span>
                                  </p>
                                  
                                </div>
                              </div>
                            </article>
                            <!-- End Card -->
                          </div>
                        </div>
                        <nav>
                          <ul class="pagination justify-content-center pagination-sm">
                          </ul>
                        </nav>

                      </article>
                      <!--End Latest News -->

                      <!-- International News -->
                      <article class="tab-pane fade" id="inter-news" role="tabpanel" aria-labelledby="new-views-tab">

                        <article class="row mt-2 mb-4">

                          <div class="col-md-4">
                            <div class="icon">
                              <img class="lazy blog-img-thumbnail img-responsive img-thumbnail"
                                src="https://images.indianexpress.com/2022/01/Opinion-2-4.jpg">
                            </div>
                          </div>

                          <div class="col-md-7">
                            <div class="text-left pull-left iconclr">
                              <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-eye"
                                  aria-hidden="true"></i></a>
                              <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-bookmark"
                                  aria-hidden="true"></i></a>
                              <a href="#" class="btn text-white color-gray" tabindex="0"><i class="fas fa-share-alt"
                                  aria-hidden="true"></i></a>

                            </div>
                            <div class="desc">
                              <h1><a href="#">How India has approached customary international law - The Indian
                                  Express</a></h1>

                              <p> <a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci esse
                                  vitae exercitationem fugit, numquam minus! Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Adipisci esse vitae exercitationem fugit, numquam minus!</a></p>
                              <p class="small link"> <span class="author-name">Legal Formats,</span> <span
                                  class="author-date">Date,</span> <span class="author-time">Time.</span>
                              </p>
                            </div>
                          </div>
                        </article>
                      </article>
                      <!--End International News -->

                      <!-- My News -->
                      <article class="tab-pane fade" id="my-news" role="tabpanel" aria-labelledby="new-connections">
                        
                        <article class="row mt-2 mb-4">

                          <div class="col-md-4">
                            <div class="icon">
                              <img class="lazy blog-img-thumbnail img-responsive img-thumbnail"
                                src="https://images.indianexpress.com/2022/01/Opinion-2-4.jpg">
                            </div>
                          </div>

                          <div class="col-md-7">
                            <div class="text-left pull-left iconclr">
                              <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-eye"
                                  aria-hidden="true"></i></a>
                              <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-bookmark"
                                  aria-hidden="true"></i></a>
                              <a href="#" class="btn text-white color-gray" tabindex="0"><i class="fas fa-share-alt"
                                  aria-hidden="true"></i></a>

                            </div>
                            <div class="desc">
                              <h1><a href="#">How India has approached customary international law - The Indian
                                  Express</a></h1>

                              <p> <a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci esse
                                  vitae exercitationem fugit, numquam minus! Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Adipisci esse vitae exercitationem fugit, numquam minus!</a></p>
                              <p class="small link"> <span class="author-name">Legal Formats,</span> <span
                                  class="author-date">Date,</span> <span class="author-time">Time.</span>
                              </p>
                            </div>
                          </div>
                        </article>
                      </article>
                      <!-- End My News -->

                      <!--Favorites News -->
                      <article class="tab-pane fade" id="favorites" role="tabpanel"
                        aria-labelledby="pending-connections">
                        <article class="row mt-2 mb-4">

                          <div class="col-md-4">
                            <div class="icon">
                              <img class="lazy blog-img-thumbnail img-responsive img-thumbnail"
                                src="https://images.indianexpress.com/2022/01/Opinion-2-4.jpg">
                            </div>
                          </div>

                          <div class="col-md-7">
                            <div class="text-left pull-left iconclr">
                              <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-eye"
                                  aria-hidden="true"></i></a>
                              <a href="#" class="btn text-white color-gray" tabindex="0"><i class="far fa-bookmark"
                                  aria-hidden="true"></i></a>
                              <a href="#" class="btn text-white color-gray" tabindex="0"><i class="fas fa-share-alt"
                                  aria-hidden="true"></i></a>

                            </div>
                            <div class="desc">
                              <h1><a href="#">How India has approached customary international law - The Indian
                                  Express</a></h1>

                              <p> <a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci esse
                                  vitae exercitationem fugit, numquam minus! Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Adipisci esse vitae exercitationem fugit, numquam minus!</a></p>
                              <p class="small link"> <span class="author-name">Legal Formats,</span> <span
                                  class="author-date">Date,</span> <span class="author-time">Time.</span>
                              </p>
                            </div>
                          </div>
                        </article>
                      </article>
                      <!--End Favorites News -->
                    </div>
                  </div>
                  <!-- end profile details-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 text-left">
          <strong>Disclaimer: </strong><em>SoOLEGAL in Media collaboration with Lawstreet Journal. SoOLEGAL take no
            responsbility for the content provided by Lawstreet Journal. For any discrepancies <a target="_blank"
              href="https://lawstreet.co/">Contact Lawstreet Journal</a></em>.
        </div>
      </div>

    </section>
    <section class="title-bg-1 my-4  ipad-hide phone-hide">
      <div class="container">
        <div class="row">
          <div class="col-md-2 #90278E-bg-new">
            <div class="triangle-bottomleft"></div>
            <div class="icon-box"></div>
          </div>
          <div class="col-md-10 white-bg-new">
            <div class="triangle-topleft"></div>
            <span class="gray-bg">Go Premium</span>
            <h3>Get the best lawyer based on SoOLegal's proprietary ROAR Score methodology</h3>
          </div>
        </div>
      </div>
    </section>

    <section class="my-5">
      <div class="container position-relative">
        
        <section class="lawyer-slider slider-margin">
          
                <article class="item"><div class="component-box-1 component-box-bg-light">
        <div class="highlight-box">
          <p class="font-weight-bold">2020 SoOLegal Knowledge Series
  
            by Avani Bansal</p>
          <div class="img-box"><img src="../../assets/images/avani_extn_1200x628.jpg" alt="Event" class="img-fluid" /> </div>
         
          <div class="date">DD/MM/YYY</div>
          <a href="#" class="btn">Register Now</a>
          </div>
        </div></article>
  
  
        <article class="item"><div class="component-box-1 component-box-bg-light">
          <div class="highlight-box">
            <p class="font-weight-bold">2020 SoOLegal Knowledge Series
    
              by Avani Bansal</p>
            <div class="img-box"><img src="images/avani_extn_1200x628.jpg" alt="Event"  class="img-fluid"/> </div>
           
            <div class="date">DD/MM/YYY</div>
            <a href="#" class="btn">Register Now</a>
            </div>
          </div></article>
  
  
  
          <article class="item"><div class="component-box-1 component-box-bg-light">
            <div class="highlight-box">
              <p class="font-weight-bold">2020 SoOLegal Knowledge Series
      
                by Avani Bansal</p>
              <div class="img-box"><img src="images/avani_extn_1200x628.jpg" alt="Event"  class="img-fluid"/> </div>
             
              <div class="date">DD/MM/YYY</div>
              <a href="#" class="btn">Register Now</a>
              </div>
            </div></article>
  
  
  
            <article class="item"><div class="component-box-1 component-box-bg-light">
              <div class="highlight-box">
                <p class="font-weight-bold">2020 SoOLegal Knowledge Series
        
                  by Avani Bansal</p>
                <div class="img-box"><img src="images/avani_extn_1200x628.jpg" alt="Event"  class="img-fluid" /> </div>
               
                <div class="date">DD/MM/YYY</div>
                <a href="#" class="btn">Register Now</a>
                </div>
              </div></article>
  
  
  
              <article class="item"><div class="component-box-1 component-box-bg-light">
                <div class="highlight-box">
                  <p class="font-weight-bold">2020 SoOLegal Knowledge Series
          
                    by Avani Bansal</p>
                  <div class="img-box"><img src="images/avani_extn_1200x628.jpg" alt="Event" /> </div>
                 
                  <div class="date">DD/MM/YYY</div>
                  <a href="#" class="btn">Register Now</a>
                  </div>
                </div></article>
        
              </section>
        
        
    <a href="#" class="arrow law-ml"><img src="images/icons/arrow_left.png" /></a><a href="#" class="arrow law-mr r-180"><img src="images/icons/arrow_left.png" /></a>
  
        
        </div>
      </section>
  </main>
