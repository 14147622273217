import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { BarAssociationService } from '../bar-association.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Barassociation } from '../barassociation';

@Component({
    selector: 'app-contact',
    templateUrl: './helpline.component.html',
  
  })
  export class HelplineComponent implements OnInit {
   
    constructor(public barAssociationService:BarAssociationService, private route: ActivatedRoute,) { }
    barassociation:Barassociation;
   


    ngOnInit(): void {
        var bar_association_id = this.route.parent.snapshot.paramMap.get('barId');
        this.getBarContact(bar_association_id);
      }
      getBarContact(barId){
        
        this.barAssociationService.bar_association_contactus(barId).subscribe((res: Barassociation) => {
          this.barassociation = res[0];
          console.log('mmmmm',res)
          
        });
      }
    }