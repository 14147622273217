import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicProfileService {
  public url = environment.baseURL;


  constructor(public http: HttpClient) { }
  get(urlt: any) {
    const getUrl = this.url + urlt;
   
    return this.http.get(getUrl);
  }
  postdata(urlT: any, data: any) {
    const updateUrl = this.url + urlT;
    const header = new HttpHeaders().set('JWTAuthorization', localStorage.getItem('USERID')); // may be localStorage/sessionStorage
    const headers = { headers: header };
   
    return this.http.post(updateUrl, data,headers);
  }
  post(urlT: any, data: any) {
    const updateUrl = this.url + urlT;
    return this.http.post(updateUrl, data);
  }
}
