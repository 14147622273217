<h4 class="m-0">Your Calender </h4>
<div class="user-info-box-inner calendar-box no-scroll">
    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
    <!-- dateClick="dateClick($event)"(click)="eventClick($event)" -->
</div>

<div class="modal fade" id="mycalevent" tabindex="-1" aria-labelledby="findLawer" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 50%;">
        <div class="modal-content rounded-0 my-calendar">

            <button type="button" class="close close-model" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>

            <div class="modal-body popup-body">
                <h3>My Calender</h3>
                <hr>
                <ngx-tabset (onSelect)="doSomethingOnTabSelect($event)">
                    <ngx-tab tabTitle="" [active]="false" [bypassDOM]="true">
                        <ng-template>
                            <ngx-tabset customNavClass="nav nav-tabs nav-justified nav-test-class" customTabsClass="container">
                                <!-- tabSubTitle="a little subtitle" -->
                                <!-- *ngIf="testapointment" -->
                                <ngx-tab tabTitle="Appointment">
                                    <a data-toggle="modal" (click)="appoinment()" class="addHearing btn btn-sm btn-warning rkmd-btn-xs pull-right modalTopBtn btn-custom-cls"><i class="fa fa-plus"></i> Add Appointment</a>
                                    <div *ngIf="!testapointment">
                                        <div class="table-responsive-sm">
                                            <table class="table marg-bot0 the-table-custom">
                                                <thead>
                                                    <tr class="bdrBN ">
                                                        <th class="ft-12" style="width: 25%;">Date &amp; Time</th>
                                                        <th class="ft-12" style="width: 30%;">Client Name</th>
                                                        <th class="ft-12" style="width: 40%;">Message</th>
                                                        <th class="ft-12" style="width: 5%;">#</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of Appointmentlist; let i = index;">
                                                        <td><span class="text-black ft-12">{{data.start_time}}</span></td>

                                                        <td><span class="text-black ft-12">{{data.client_name}} </span></td>
                                                        <td><span class="text-black ft-12"> {{data.description}}</span></td>
                                                        <td>
                                                            <i title="Edit" class="fa fa-edit fa-fw" (click)="openedit(data,i)"></i>
                                                            <!-- <div class="title marg-bot10 marg-top10 pull-right"> -->
                                                            <!-- <a data-toggle="modal" href="#modifycalendarAppoitnment" class="modifycalendarAppoitnment btn rkmd-btn-xs bg-white" data-appoitnmentid="566" data-appointmentdate="2022-02-17"> Edit </a> -->
                                                            <!-- </div> -->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div *ngIf="testapointment" class="custom-no-result">
                                        There is no results!
                                    </div>

                                </ngx-tab>
                                <ngx-tab tabTitle="Need A Lawer" [bypassDOM]="true">
                                    <ng-template>
                                        <div>
                                            <!-- <h3>Need A lawer  1 </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quos aliquam consequuntur, esse
                                            provident impedit minima porro! Laudantium laboriosam culpa quis fugiat ea, architecto velit ab,
                                            deserunt rem quibusdam voluptatum.</p>
                    
                                        <h3>Need A lawer  2</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quos aliquam consequuntur, esse
                                            provident impedit minima porro! Laudantium laboriosam culpa quis fugiat ea, architecto velit ab,
                                            deserunt rem quibusdam voluptatum.</p>
                    
                                        <h3>Need A lawer   3</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quos aliquam consequuntur, esse
                                            provident impedit minima porro! Laudantium laboriosam culpa quis fugiat ea, architecto velit ab,
                                            deserunt rem quibusdam voluptatum.</p> -->


                                            <ul class="scrollV" id="datewise-personalAppointment">
                                                <li class="card-hover marg-bot22" *ngFor="let data of needalawerlist; let i = index;" style="border:1px dashed #cecccc; background: #f8f8f8;" id="hearingid-1">
                                                    <a target="_blank" href="https://test.soolegal.com/need-a-lawyer/test-6"> Legal topic: {{data.topic}} | City: {{data.cityname}} 

                                                    | {{data.doneed}}  |

                                                                            {{data.created_date}} 
                                            </a>
                                                </li>
                                            </ul>

                                        </div>
                                    </ng-template>
                                </ngx-tab>

                                <ngx-tab tabTitle="Public Events" [bypassDOM]="true">
                                    <ng-template>
                                        <div>
                                            <!-- publiceventlist -->
                                            <!-- <h3>Public Events  1 </h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quos aliquam consequuntur, esse
                                                provident impedit minima porro! Laudantium laboriosam culpa quis fugiat ea, architecto velit ab,
                                                deserunt rem quibusdam voluptatum.</p>
                        
                                            <h3>Public Events  2</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quos aliquam consequuntur, esse
                                                provident impedit minima porro! Laudantium laboriosam culpa quis fugiat ea, architecto velit ab,
                                                deserunt rem quibusdam voluptatum.</p>
                        
                                            <h3>Public Events   3</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quos aliquam consequuntur, esse
                                                provident impedit minima porro! Laudantium laboriosam culpa quis fugiat ea, architecto velit ab,
                                                deserunt rem quibusdam voluptatum.</p> -->
                                            <ul class="scrollV" id="datewise-personalAppointment">
                                                <li class="card-hover marg-bot22" *ngFor="let data of publiceventlist; let i = index;" style="border:1px dashed #cecccc; background: #f8f8f8;" id="hearingid-1">
                                                    <a target="_blank" href="https://test.soolegal.com/need-a-lawyer/test-6"> 
    
                                                                                {{data.title}} 
                                                </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </ng-template>
                                </ngx-tab>

                            </ngx-tabset>
                        </ng-template>
                    </ngx-tab>
                </ngx-tabset>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="calendarAppointment" tabindex="-1" aria-labelledby="setting" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 33%;">
        <div class="modal-content rounded-0">
            <button type="button" class="close close-model" data-dismiss="modal" aria-label="Close" #closesetting>
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body popup-body">
                <h3> Add Appointment </h3>

                <form>
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                            <input class="form-control" id="title-appointment" [(ngModel)]="appoinments.title" name="title" placeholder="Title" type="text">

                        </div>

                    </div>
                    <div class="col-lg-12 col-sm-12">
                        <div class="input-group">
                            <div class="select-control">
                                <select [(ngModel)]="appoinments.client_id" name="client_id" id="event_client_name" class="form-control">
                        <option data-clientid="" value="" >*Select Client</option>
                        <option data-clientid="7"  *ngFor="let data of clientlist"value="{{data.client_id}}">{{data.client_name}}</option>
                     
                    </select>


                            </div><span id="err-client" style="color:red"></span>
                            <div class="input-group-btn"><a data-toggle="modal" (click)="addclients()" class="addEvent2 btn btn-default" data-ssid="1"><i class="fa fa-plus"> </i></a></div>
                        </div>


                    </div>
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                            <input class="form-control" id="title-appointment" name="description" [(ngModel)]="appoinments.description" placeholder="Title" type="text">

                        </div>

                    </div>
                    <!-- <div class="col-lg-6 col-sm-6">
                    <div class="input-group"><span class="input-group-addon" id="create-task-date">2022-02-09</span><input type="text" name="date_time" value="" id="date-time-task-date" class="form-control slider_timepicker index9" placeholder="HH:MM PM">
</div>
                </div> -->
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group"><span class="input-group-addon" id="create-task-date">{{selectdate}}</span><input type="time" [(ngModel)]="appoinments.start_time" name="start_time" id="date-time-task-date_to" class="form-control slider_timepicker index9" placeholder="HH:MM PM">
                            <!-- <div class="input-group"><span class="input-group-addon" id="create-task-date">{{selectdate}}</span><input type="text" name="date_time" value="" id="date-time-task-date" class="form-control slider_timepicker index9 hasDatepicker valid" placeholder="HH:MM PM" aria-required="true">-->
                        </div>


                        <div class="ui-datepicker-title">Choose Time

                            <!-- <div class="input-group"><span class="input-group-addon" id="create-task-date">2022-02-17</span><input type="text" name="date_time" value="" id="date-time-task-date" class="form-control slider_timepicker index9 hasDatepicker valid" placeholder="HH:MM PM" aria-required="true">
</div> -->
                            <!-- <ngx-datepicker [(ngModel)]="date"></ngx-datepicker> -->
                            <!-- <ngx-mat-timepicker [(ngModel)]="myTimePicker">
    </ngx-mat-timepicker> -->
                            <!-- <input [ngxMatTimepicker]="picker">
<ngx-mat-timepicker #picker></ngx-mat-timepicker> -->

                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group"><span class="input-group-addon" id="create-task-date">{{selectdate}}</span><input type="time" [(ngModel)]="appoinments.end_time" name="end_time" id="date-time-task-date_to" class="form-control slider_timepicker index9" placeholder="HH:MM PM">
                        </div>

                    </div>

                    <button type="button" data-share="1" class="btn rkmd-btn btn-primary new-appointment-on" id="add-calendar-appointment-1">Save &amp; Share</button>
                    <button type="button" data-share="0" class="btn rkmd-btn btn-success new-appointment-on" *ngIf="!editappointment" id="add-calendar-appointment-0" (click)="postappintment()">Save</button>
                    <button type="button" data-share="0" class="btn rkmd-btn btn-success new-appointment-on" *ngIf="editappointment" id="add-calendar-appointment-0" (click)="updateappintment()">Update</button>
                    <button type="button" data-dismiss="modal" class="btn rkmd-btn btn-danger">Close</button>
                    <!-- editappointment -->

                </form>

            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="addclient" tabindex="-1" aria-labelledby="setting" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 33%;">
        <div class="modal-content rounded-0">
            <button type="button" class="close close-model" data-dismiss="modal" aria-label="Close" #closeclient>
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body popup-body">
                <h3> Add client </h3>
                <form>
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                            <input class="form-control" id="title-appointment" [(ngModel)]="client.name" name="name" placeholder="client Name" type="text">

                        </div>

                    </div>
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                            <input class="form-control" id="title-appointment" [(ngModel)]="client.email" name="email" placeholder="email" type="text">

                        </div>


                    </div>
                    <!-- <div class="col-lg-6 col-sm-6">
                    <div class="input-group"><span class="input-group-addon" id="create-task-date">2022-02-09</span><input type="text" name="date_time" value="" id="date-time-task-date" class="form-control slider_timepicker index9" placeholder="HH:MM PM">
</div>
                </div> -->
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                            <input class="form-control" id="title-appointment" name="contact_num" [(ngModel)]="client.contact_num" placeholder="Contact Number" type="text">

                        </div>
                        <!-- <div class="ui-datepicker-title">Choose Time
    <!-- <ngx-datepicker [(ngModel)]="date"></ngx-datepicker> -->
                        <!-- <ngx-mat-timepicker [(ngModel)]="myTimePicker">
    </ngx-mat-timepicker> -->
                        <!-- <input [ngxMatTimepicker]="picker">
<ngx-timepicker #picker></ngx-mat-timepicker> 

</div> -->
                    </div>
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                            <input class="form-control" id="title-appointment" name="address" [(ngModel)]="client.address" placeholder="Address " type="text">

                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                            <input class="form-control" id="title-appointment" name="contact_person_name" [(ngModel)]="client.contact_person_name" placeholder="Contact Person " type="text">

                        </div>
                    </div>

                    <button type="button" data-share="1" class="btn rkmd-btn btn-primary new-appointment-on" id="add-calendar-appointment-1">Save &amp; Share</button>
                    <button type="button" data-share="0" class="btn rkmd-btn btn-success new-appointment-on" id="add-calendar-appointment-0" (click)="postclient()">Save</button>
                    <button type="button" data-dismiss="modal" class="btn rkmd-btn btn-danger">Close</button>

                </form>

            </div>

        </div>
    </div>
</div>

<span #opencalenderModal data-toggle="modal" data-backdrop="static" data-target="#mycalevent" style="display: none;"></span>
<span #calendarAppointment data-toggle="modal" data-backdrop="static" data-target="#calendarAppointment" style="display: none;"></span>
<span #addclient data-toggle="modal" data-backdrop="static" data-target="#addclient" style="display: none;"></span>

<!-- addclient -->