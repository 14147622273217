<div class="container form-rc1" *ngIf="step==1">
    <form [formGroup]="myForm" (ngSubmit)="submit()">
        <div class="row">

            <div class="col-md-8 form-area">
                <h3 style="padding:10px;">Document Translation</h3>
                <div class="btn-group form-input-rc">
                    <div class="select-control col-md-12 col-sm-12 marg-bot10 no-padd foren">

                        <div class="input-group date" id="set-tax-inv-rate">
                            <span class="input-group-addon">From </span><span *ngIf="submitted && f.language_from.errors.required" style="color:red"> (Required)</span>
                            <select title="Select Language From" name="languagefrom" formControlName="language_from" class="showlanguagebox form-control selectpicker bs-select-hidden" formControlName="language_from" id="languagefrom" data-live-search="TRUE">
                                <option class="bs-title-option" value="">Select Language From</option>
                                <optgroup>
                                    <option data-fromflag="1" value="1">English</option>
                                    <option data-fromflag="1" value="2">Arabic</option>
                                    <option data-fromflag="1" value="4">German</option>
                                    <option data-fromflag="1" value="5">Spanish</option>
                                    <option data-fromflag="1" value="6">French</option>
                                    <option data-fromflag="1" value="8">Italian</option>
                                    <option data-fromflag="1" value="11">Russian</option>
                                    <option data-fromflag="1" value="14">Chinese</option>
                                </optgroup>

                                <optgroup label="Indian Languages">
                                    <option data-fromflag="2" value="7">Hindi</option>
                                    <option data-fromflag="2" value="3">Bengali/Bangla</option>
                                    <option data-fromflag="2" value="9">Marathi</option>
                                    <option data-fromflag="2" value="10">Punjabi</option>
                                    <option data-fromflag="2" value="12">Tamil</option>
                                    <option data-fromflag="2" value="13">Telugu</option>
                                </optgroup>

                            </select>
                        </div>




                    </div>

                </div>
                <div class="btn-group form-input-rc">
                    <div class="select-control col-md-12 col-sm-12 marg-bot10 no-padd foren">

                        <div class="input-group date" id="set-tax-inv-rate">
                            <span class="input-group-addon">To </span><span *ngIf="submitted && f.language_to.errors.required" style="color:red"> (Required)</span>
                            <select title="Select Language To" name="languageTo" formControlName="language_to" class="showlanguagebox form-control selectpicker bs-select-hidden" id="languagefrom" data-live-search="TRUE">
                                <option class="bs-title-option" value="" formControlName="language_to">Select Language To</option>
                                <optgroup>
                                    <option data-fromflag="1" value="1">English</option>
                                    <option data-fromflag="1" value="2">Arabic</option>
                                    <option data-fromflag="1" value="4">German</option>
                                    <option data-fromflag="1" value="5">Spanish</option>
                                    <option data-fromflag="1" value="6">French</option>
                                    <option data-fromflag="1" value="8">Italian</option>
                                    <option data-fromflag="1" value="11">Russian</option>
                                    <option data-fromflag="1" value="14">Chinese</option>
                                </optgroup>

                                <optgroup label="Indian Languages">
                                    <option data-fromflag="2" value="7">Hindi</option>
                                    <option data-fromflag="2" value="3">Bengali/Bangla</option>
                                    <option data-fromflag="2" value="9">Marathi</option>
                                    <option data-fromflag="2" value="10">Punjabi</option>
                                    <option data-fromflag="2" value="12">Tamil</option>
                                    <option data-fromflag="2" value="13">Telugu</option>
                                </optgroup>

                            </select>
                        </div>




                    </div>

                </div>

                <div class="form-input-rc">
                    <label for="validationCustom03" class="form-label">Please Enter Number of Pages in
                        Attachment</label><span *ngIf="submitted && f.pages.errors.required" style="color:red"> (Required)</span>
                    <input type="text" formControlName="pages" class="form-control" (keyup)="onKeyup($event.target.value)" id="validationCustom03" required>

                </div>
                <div class="form-input-rc">
                    <div class="form-group"><span *ngIf="submitted && f.description.errors.required" style="color:red"> (Required)</span>
                        <label>Description and Further Information/Instructions</label>
                        <textarea formControlName="description" id="editor"></textarea>
                    </div>
                </div>
                <div class="form-input-rc"><span *ngIf="submitted1 && f1.attachment_url.errors.required" style="color:red"> (Required)</span>
                    <label class="form-label" for="customFile">Upload Document for more information/Instruction</label>
                    <input type="file" formControlName="attachment_url" class="form-control" id="customFile">
                </div>

                <button style="color: #90278e; background-color:#fff; border-color: #90278e;" class="w-100 btn btn-primary btn-lg" type="submit">Continue to checkout</button>

            </div>

            <div class="col-md-4">
                <div class="mb-3">
                    <h6>Preferred Delivery Time</h6>
                    <ul class="nav nav-pills" id="myTab" role="tablist">
                        <li class="nav-item"><a class="nav-link active" id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true" (click)="setactiveid(6)">5 Days</a></li>
                        <li class="nav-item"><a class="nav-link" id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false" (click)="setactiveid(5)">10 Days</a></li>
                        <li class="nav-item"><a class="nav-link" id="contact-tab" data-toggle="tab" role="tab" aria-controls="contact" aria-selected="false" (click)="setactiveid(4)">15 Days</a></li>
                    </ul>
                </div>
                <div class="tab-content mb-4">
                    <div class="tab-pane fade show active" *ngIf="activeid==6" id="15days" role="tabpanel" aria-labelledby="home-tab">
                        <span id="render-amt-summary">
                            <div class="service-detailsrc">
                                <div class="ft-16 close-div"><small>Service :</small> <small class="pull-right">Judgment
                                        Summary/Synopsis</small> </div>
                                <hr>
                                <div class="ft-16 close-div"><small>1 page</small> <small class="pull-right"><i
                                            class="fa fa-inr ft-12"></i> {{price}}/-</small> </div>
                                <hr>
                                <div class="ft-16 close-div"><small>Judgment Interpretation and Outcome charges
                                        :</small>
                                    <small class="pull-right"><i class="fa fa-inr ft-12"> </i> {{price}}/-</small>
                                </div>
                                <div class="ft-16" style="background: #ecdbec; padding: 10px;">
                                    <div>
                                        <b>GST</b> <small class="pull-right text-white"></small>
                                        <br>
                                        <b style="padding-left: 9px;"><small>- CGST @9%</small></b><small> </small>
                                        <small class="pull-right text-success"><b><i
                                                    class="fa fa-inr ft-12"></i>{{cgstamt}}</b></small>
                                        <br>
                                        <b style="padding-left: 9px;"><small>- SGST @9%</small></b><small> </small>
                                        <small class="pull-right text-success"><b><i
                                                    class="fa fa-inr ft-12"></i>{{sgstamt}}</b></small>
                                    </div>
                                </div>
                                <div style="background: #90278e;padding: 10px;color: #fff;"> <b>Total Cost for the
                                        Service :
                                    </b><small class="pull-right text-white"><b><i class="fa fa-inr ft-12"></i> {{TotalTaxamt}}</b>
                                    </small></div>
                            </div>
                        </span>
                    </div>
                    <div class="" *ngIf="activeid==5" id="10days" role="tabpanel" aria-labelledby="profile-tab">
                        <span id="render-amt-summary">
                            <div class="service-detailsrc">
                                <div class="ft-16 close-div"><small>Service :</small> <small class="pull-right">Judgment
                                        Summary/Synopsis</small> </div>
                                <hr>
                                <div class="ft-16 close-div"><small>1 page</small> <small class="pull-right"><i
                                            class="fa fa-inr ft-12"></i> {{price}}/-</small> </div>
                                <hr>
                                <div class="ft-16 close-div"><small>Judgment Interpretation and Outcome charges
                                        :</small>
                                    <small class="pull-right"><i class="fa fa-inr ft-12"> </i> {{price}}/-</small>
                                </div>
                                <div class="ft-16" style="background: #ecdbec; padding: 10px;">
                                    <div>
                                        <b>GST</b> <small class="pull-right text-white"></small>
                                        <br>
                                        <b style="padding-left: 9px;"><small>- CGST @9%</small></b><small> </small>
                                        <small class="pull-right text-success"><b><i
                                                    class="fa fa-inr ft-12"></i>{{cgstamt}}</b></small>
                                        <br>
                                        <b style="padding-left: 9px;"><small>- SGST @9%</small></b><small> </small>
                                        <small class="pull-right text-success"><b><i
                                                    class="fa fa-inr ft-12"></i>{{sgstamt}}</b></small>
                                    </div>
                                </div>
                                <div style="background: #90278e;padding: 10px;color: #fff;"> <b>Total Cost for the
                                        Service :
                                    </b><small class="pull-right text-white"><b><i class="fa fa-inr ft-12"></i> {{TotalTaxamt}}</b>
                                    </small></div>
                            </div>
                        </span>
                    </div>
                    <div class="" *ngIf="activeid==4" id="5days" role="tabpanel" aria-labelledby="contact-tab">
                        <span id="render-amt-summary">
                            <div class="service-detailsrc">
                                <div class="ft-16 close-div"><small>Service :</small> <small class="pull-right">Judgment
                                        Summary/Synopsis</small> </div>
                                <hr>
                                <div class="ft-16 close-div"><small>1 page</small> <small class="pull-right"><i
                                            class="fa fa-inr ft-12"></i> {{price}}/-</small> </div>
                                <hr>
                                <div class="ft-16 close-div"><small>Judgment Interpretation and Outcome charges
                                        :</small>
                                    <small class="pull-right"><i class="fa fa-inr ft-12"> </i> {{price}}/-</small>
                                </div>
                                <div class="ft-16" style="background: #ecdbec; padding: 10px;">
                                    <div>
                                        <b>GST</b> <small class="pull-right text-white"></small>
                                        <br>
                                        <b style="padding-left: 9px;"><small>- CGST @9%</small></b><small> </small>
                                        <small class="pull-right text-success"><b><i
                                                    class="fa fa-inr ft-12"></i>{{cgstamt}}</b></small>
                                        <br>
                                        <b style="padding-left: 9px;"><small>- SGST @9%</small></b><small> </small>
                                        <small class="pull-right text-success"><b><i
                                                    class="fa fa-inr ft-12"></i>{{sgstamt}}</b></small>
                                    </div>
                                </div>
                                <div style="background: #90278e;padding: 10px;color: #fff;"> <b>Total Cost for the
                                        Service :
                                    </b><small class="pull-right text-white"><b><i class="fa fa-inr ft-12"></i> {{TotalTaxamt}}</b>
                                    </small></div>
                                  
                            </div>
                        </span>
                    </div>
                </div>

            </div>

        </div>
    </form>
</div>




<div class="container form-rc1" *ngIf="step==2">
    <form [formGroup]="myForm1" (ngSubmit)="submit1()">
        <div class="row">
            <div class="col-md-8 col-lg-8 form-area ">
                <h4 class="mb-3">Billing address</h4>
                <!-- <form class="needs-validation" novalidate=""> -->
                <div class="row g-3">
                    <div class="col-12"><span *ngIf="submitted1 && f1.client_name.errors.required" style="color:red"> (Required)</span>
                        <label for="firstName" class="form-label">Name</label>
                        <input type="text" class="form-control" formControlName="client_name" placeholder="" required="">
                        <div class="invalid-feedback">
                            Valid first name is required.
                        </div>
                    </div>


                    <div class="col-12">
                        <label for="email" class="form-label">Email</label><span *ngIf="submitted1 && f1.email.errors.required" style="color:red"> (Required)</span>
                        <input type="email" class="form-control" formControlName="email" placeholder="">
                        <div class="invalid-feedback">
                            Please enter a valid email address for shipping updates.
                        </div>
                    </div>

                    <div class="col-12">
                        <label for="phone" class="form-label">Contact Number</label><span *ngIf="submitted1 && f1.contact_num.errors.required" style="color:red"> (Required)</span>
                        <input type="number" class="form-control" formControlName="contact_num" placeholder="">
                        <div class="invalid-feedback">
                            Please enter a valid Phone Number for shipping updates.
                        </div>
                    </div>




                    <div class="col-12">
                        <label for="address" class="form-label">Address</label><span *ngIf="submitted1 && f1.address.errors.required" style="color:red"> (Required)</span>
                        <input type="text" class="form-control" formControlName="address" placeholder="" required="">
                        <div class="invalid-feedback">
                            Please enter your shipping address.
                        </div>
                    </div>








                    <hr class="my-4">

                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="same-address">
                        <label class="form-check-label" for="same-address">Shipping address is the same as my
                                billing address</label>
                    </div>

                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="save-info">
                        <label class="form-check-label" for="save-info">Save this information for next
                                time</label>
                    </div>
                </div>
                <!-- </form> -->
            </div>
            <div class="col-md-4 col-lg-4">
                <span id="render-amt-summary">
                    <div class="ibox-title">
                        <h5>Cart Summary</h5>
                    </div>
                    <hr>
                    <div class="text-navy">
                        <large>Subtotal</large>
                        <small class="pull-right">
                            <i class="fa fa-inr ft-12"></i> 499/-
                        </small>
                    </div>
                    <div class="ft-16" style="background: #ecdbec; padding: 10px;">
                        <div>
                            <b>GST</b>
                            <small class="pull-right text-white"></small>
                            <br>
                            <b style="padding-left: 9px;">
                                <small>- CGST @9%</small>
                            </b>
                            <small></small>
                            <small class="pull-right text-success">
                                <b>
                                    <i class="fa fa-inr ft-12"></i>72
                                </b>
                            </small>
                            <br>
                            <b style="padding-left: 9px;">
                                <small>- SGST @9%</small>
                            </b>
                            <small></small>
                            <small class="pull-right text-success">
                                <b>
                                    <i class="fa fa-inr ft-12"></i>72
                                </b>
                            </small>
                        </div>
                    </div>
                    <div style="background: #90278e;padding: 10px;color: #fff;">
                        <b>
                            Total
                        </b>
                        <small class="pull-right text-white">
                            <b>
                                <i class="fa fa-inr ft-12"></i> 942
                            </b>
                        </small>
                    </div>
                    &nbsp;
                    <button style="color: #90278e; background-color:#fff; border-color: #90278e;"
                        class="w-100 btn btn-primary btn-lg" type="submit">Make Payment</button>
                </span>
            </div>
        </div>
    </form>
</div>

<!-- summernote -->
<script>
    tinymce.init({
        selector: '#editor',
        menubar: false,
        statusbar: false,
        plugins: 'autoresize anchor autolink charmap code codesample directionality fullpage help hr image imagetools insertdatetime link lists media nonbreaking pagebreak preview print searchreplace table template textpattern toc visualblocks visualchars',
        toolbar: 'h1 h2 bold italic strikethrough blockquote bullist numlist backcolor | link image media | removeformat help fullscreen ',
        skin: 'bootstrap',
        toolbar_drawer: 'floating',
        min_height: 200,
        autoresize_bottom_margin: 16,
        setup: (editor) => {
            editor.on('init', () => {
                editor.getContainer().style.transition = "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out"
            });
            editor.on('focus', () => {
                editor.getContainer().style.boxShadow = "0 0 0 .2rem rgba(0, 123, 255, .25)",
                    editor.getContainer().style.borderColor = "#80bdff"
            });
            editor.on('blur', () => {
                editor.getContainer().style.boxShadow = "",
                    editor.getContainer().style.borderColor = ""
            });
        }
    });
</script>