import { Component, OnInit,Input,Output,EventEmitter} from '@angular/core';
import { EventService } from '../event.service';
import { EventregisterService } from '../eventregister.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Event } from '../event';
import { FormBuilder, FormGroup, FormControl, Validators, } from '@angular/forms';
import { environment } from 'src/environments/environment';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { MemberService } from '../../member/member.service';
import { ApiService } from '../../api.service';
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {


  constructor(public eventService: EventService, public memberapi: MemberService,public api: ApiService,public eventregisterService: EventregisterService, private route: ActivatedRoute,private modalService: NgbModal) { }
  id: number;
  // event:Event;
  event:any;
  eventId:any;
  event_id:number;
  closeResult: string;
  closeModal:string;

  ngOnInit(): void {
    var eventid = this.route.snapshot.params['eventId'];
    console.log('fffff',eventid)
    this.findEvent(eventid);
  }
  getEventImage(event) {
    // return environment.baseURL+"news-image/" + news.url;
    // return "http://localhost:8080/api/event-image/" + event.banner;
    return environment.pathurl+"cdn/assets/uploads/news/assets/uploads/event/" + event.event_banner;
    
  }

  findEvent(event_id){
    console.log('getting Event');
    this.eventService.find(event_id).subscribe((res:any) => {
      this.event = res.messages.feed_list[0];
    });
    console.log('getting Event',this.event);
    console.log('yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy');
  }

if(paidtype=0){
  
}
  
 
  myForm = new FormGroup({
    firstname: new FormControl('',),
    lastname: new FormControl(''),
    mobile: new FormControl(''),
    event: new FormControl(''),
    email: new FormControl(''),
    city: new FormControl(''),
    zip: new FormControl(''),
    country: new FormControl(''),
    profession: new FormControl(''),
    address: new FormControl(''),
  });
  changeevent(e) {
    console.log(e.target.value);
  }
  get f(){
    return this.myForm.controls;
  }
  submit() {
    console.log('hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')
    const formData = new FormData();
    formData.append('firstname', this.myForm.get('name').value);
    formData.append("contact_no", this.myForm.get('mobile').value);
    formData.append("email", this.myForm.get('email').value);
    formData.append('category', this.myForm.get('event').value);
    formData.append('city', this.myForm.get('city').value);
   formData.append('event_id',this.event.id+'');
   formData.append('registration_category',this.myForm.get('registration_category').value);
    this.eventregisterService.create(formData).subscribe((res: any) => {
      console.log("Event Registered..:::", this.event.id);
     
this.myForm.reset();
    });
  }
  getLocation(news_id){
    // return location.href+'/'+news_id;
   return  "https://stackoverflow.com"+'/'+news_id;
  }
    // popup for delete icon
    triggerModal(content) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
        this.closeModal = `Closed with: ${res}`;
       
      
       
      }, (res) => {
        this.closeModal = `Dismissed ${this.getDismissReasons(res)}`;
       
      });
     
    }
    
    private getDismissReasons(reason: any): string {
      
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return  `with: ${reason}`;
       
      }
     
    }
  }








  
 