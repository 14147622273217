import { Injectable, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
declare const  $ ;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public api: ApiService,public loginapi: LoginService) {}
  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  
      // return true;
      if (this.api.loggedIn()) {
        return true;
      }
      $ ("#signin-modal").modal('show');
      this.router.navigateByUrl('/')
  return false;


     
  }
  
}
