import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransactionTermsRoutingModule } from './transaction-terms-routing.module';
import { TransactionTermsComponent } from './transaction-terms.component';


@NgModule({
  declarations: [TransactionTermsComponent],
  imports: [
    CommonModule,
    TransactionTermsRoutingModule
  ]
})
export class TransactionTermsModule { }
