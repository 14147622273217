import { Component, OnInit } from '@angular/core';
import { ResourceCentreService } from '../resource-centre.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.css']
})
export class AddToCartComponent implements OnInit {

  cartList:any = [];
  totalcartamt:any = 0;
  cgstpercentage:any = 9;
  sgstpercentage:any = 9;
  cgstamt:any = 0;
  sgstamt:any = 0;
  TotalTaxamt:any;
  
  constructor(
    public resourcecenterservice: ResourceCentreService ,
    private route: ActivatedRoute,
    private router: Router,
    ) { }
  ngOnInit(): void {
    this.getResourcecartList();
  }
  getResourcecartList() {
    this.resourcecenterservice.resourceCartList().subscribe((res) => {
      this.cartList = res;
      this.getTotalAmt();
      // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.resource.attachement_url + "#toolbar=0");
      // console.log('saaaaaaaaaaa', this.resource)
    });
  }
  removeCartItem(itemId:any) {
    this.resourcecenterservice.removecartItem(itemId).subscribe((res) => {
      this.cartList = res;
      this.getTotalAmt();
      // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.resource.attachement_url + "#toolbar=0");
      // console.log('saaaaaaaaaaa', this.resource)
    });
  }
  getTotalAmt(){
    if(this.cartList.length>0){
      var totalamt = 0;
      this.cartList.forEach((item, index) => {
        totalamt =totalamt + parseInt(item['price']);
        console.log(item); // 1, 2, 3
        console.log(index); // 0, 1, 2
      });
      this.totalcartamt = totalamt;
      this.cgstamt = ((this.totalcartamt * this.cgstpercentage) / 100);
      this.sgstamt = ((this.totalcartamt * this.sgstpercentage) / 100);
      this.TotalTaxamt = parseFloat(this.totalcartamt) + parseFloat(this.cgstamt) + parseFloat(this.sgstamt) ;
    }
  }
  checkout(){
    if(this.cartList.length>0){
      this.router.navigateByUrl('/resource-centre/checkout');  // open welcome component
    }
  }
}
