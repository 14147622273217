import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl} from '@angular/forms';
import { NewsService } from '../news.service';
import { RoarService } from '../../roar/roar.service';
import { HttpClient } from '@angular/common/http';
import { News } from '../news';
import { DatePipe } from '@angular/common';
import { Editor } from 'ngx-editor';
import { MemberService } from '../../member/member.service';
import { ApiService } from '../../api.service';
// import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Roar } from 'src/app/roar/roar';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],

})
export class CreateComponent implements OnInit {

  editor: Editor;
  status = 1;
  title: any;
  roar: Roar[] = [];
  video_url:any;
  category_id = 2;
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, public newsService: NewsService, public roarService: RoarService, private router: Router, public memberapi: MemberService, private toast: ToastrService, public mainapi: ApiService) { }
  id:any;
  url:any;
  existingNews:any;
  myForm:FormGroup;


  ngOnInit(): void {
    this.editor = new Editor();
    var newsid = this.route.snapshot.paramMap.get('newsId');
    if (newsid) {
      this.findNews(newsid);
    }
    this.myForm = new FormGroup({
      title: new FormControl('', Validators.required),
      
      image_source_title: new FormControl(''),
      image_source_link: new FormControl(''),
      publish_date: new FormControl('', Validators.required),
      tags: new FormControl('', Validators.required),
      content: new FormControl('', Validators.required),
      Status: new FormControl(''),
      video_url: new FormControl(''),
      id: new FormControl(''),
      url: new FormControl(''),
    });
    var roarid = this.route.snapshot.paramMap.get('roarId');
    this.getRoar(roarid);

  }
  // news: News;
  news: any;
  newsImage: any = {};

 

  get form(): { [key: string]: AbstractControl } {
    return this.myForm.controls;
  }

  changeSelect(value: any, dd: string) {
    console.log(value);
    if(value!=''){
      this.myForm.controls[dd].setValue(value, {
        onlySelf: true
      })
    }
  }
  
  public setValidation(e: any,dd: string) {
    const val = e.target.value;
    console.log(e.target.checked);
    if (e.target.checked) {
      this.myForm.controls[dd].setValue('1', {
        onlySelf: true
      });
      
      this.myForm.get(dd).updateValueAndValidity();
      console.log(this.myForm.controls[dd].value,"val");
    } else{
      this.myForm.controls[dd].setValue('0', {
        onlySelf: true
      });
      console.log(this.myForm.controls[dd].value,"val");
    }
  }



  get f() {
    return this.myForm.controls;
  }

  onFileChange(event) {
    this.newsImage = event.target.files[0];
  }
  submitted = false;
  submit() {

    this.submitted = true;
    // if(this.myForm.status === 'VALID'){
    
      const formData = new FormData();
      if (this.existingNews?.id) {        
      formData.append("news_id", this.myForm.get('id').value);
      }
      formData.append("news_image_url", this.newsImage);
      formData.append('news_title', this.myForm.get('title').value);
      formData.append("news_image_url", this.myForm.get('url').value);
      formData.append('news_image_source_link', this.myForm.get('image_source_link').value);
      formData.append('news_image_source_title', this.myForm.get('image_source_title').value);
      formData.append('news_publish_date', this.myForm.get('publish_date').value);
      formData.append('news_tags', this.myForm.get('tags').value);
      formData.append('news_content', this.myForm.get('content').value);
      formData.append('news_status', this.status + '');
      formData.append('news_video_url', this.video_url + '');
      formData.append('category_id', this.category_id + '');
      this.newsService.create(formData).subscribe((res: any) => {
        console.log("News Created..:::", res.news);
        this.toast.success('News successfully posted');
        this.router.navigate(['/news']);



      });

    // }

  }



  //    this.http.post('http://localhost:8080/api/news/create', formData)
  // .subscribe(res => {
  //   console.log(res);
  //   alert('Created Successfully.');
  // })

  getImage(news) {
    // return "http://localhost:8080/api/news-image/" + news.url;
    // return environment.baseURL+"news-image/" + news.url;
    return "..HTTP_NEWS_IMAGE_PATH" + news.url;

  }
  findNews(newsId) {
    // let date=this.existingNews.publish_date;

    let datepipe = new DatePipe('en-US');

    this.newsService.find(newsId).subscribe((res: any) => {
      this.existingNews = res.messages.feed_list[0];
      //console.log(this.existingNews);
      this.myForm.patchValue({
        id: this.existingNews.id,
        title: this.existingNews.news_title,
        url: this.existingNews.news_image_url,
        image_source_title: this.existingNews.news_image_source_title,
        image_source_link: this.existingNews.news_image_source_link,
        publish_date: datepipe.transform(this.existingNews.news_publish_date * 1000, 'yyyy-MM-dd'),
        tags: this.existingNews.news_tags,
        content: this.existingNews.news_content,
        video_url:this.existingNews.news_video_url
        
      });
      // console.log("NEWS..:::", this.news, {
      //   title: this.existingNews.title,
      //   url: this.existingNews.url,
      //   image_source_title: this.existingNews.image_source_title,
      //   image_source_link: this.existingNews.image_source_link,
      //   publish_date: this.existingNews.publish_date,
      //   tags: this.existingNews.tags,
      //   content: this.existingNews.content,
      // });


    });



  }

  getRoar(roarId) {
    console.log('getting Roar');
    this.roarService.getAll(roarId).subscribe((res: Roar[]) => {
      this.roar = res;
      console.log("Roar Created..:::", this.roar);
    });
  }


}




