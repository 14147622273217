import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { HomeComponent } from './home/home.component';
import { NeedALawyerComponent } from './need-a-lawyer/need-a-lawyer.component';

const routes: Routes = [

   {
      path: 'notification',
      loadChildren : () => import('./notification/notification.module').then(m => m.NotificationModule),
   },
  {
      path: '',
      component:NeedALawyerComponent
  },
 {
    path : 'member',
   
    loadChildren : () => import('./member/member.module').then(m => m.MemberModule),
   
 },
//    {path: 'admin/customer/message', component: CustomersDetailsComponent, canActivate: [AdminGuard]},
  {
      path: 'login',
      loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
      path : 'register',
      loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
  },
  {
      path : 'forget-password',
      loadChildren : () => import('./forget-password/forget-password.module').then(m => m.ForgetPasswordModule)
  },
  {
      path : 'profile',
      loadChildren : () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
      path : 'auth',
      loadChildren : () => import('./auth/auth.module').then(m => m.AuthModule)
  },
   {
      path : 'dashboard',
      loadChildren : () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
   },
   {
      path : 'feed',
      loadChildren : () => import('./feed/feed.module').then(m => m.FeedModule)
   },
   {
      path : 'roar',
      loadChildren : () => import('./roar/roar.module').then(m => m.RoarModule)
   },
   {
      path : 'news',
      loadChildren : () => import('./news/news.module').then(m => m.NewsModule)
   },
   {
      path : 'rc/cat/:catdata/:subcatdata',
      loadChildren : () => import('./resource-centre/resource-centre.module').then(m => m.ResourceCentreModule)
   },
   {
      path : 'resource-centre',
      loadChildren : () => import('./resource-centre/resource-centre.module').then(m => m.ResourceCentreModule)
   },
   {
      path : 'rc/cat/:data',
      loadChildren : () => import('./resource-centre/resource-centre.module').then(m => m.ResourceCentreModule)
   },
   {
      path : 'rc/:data',
      loadChildren : () => import('./resource-centre/resource-centre.module').then(m => m.ResourceCentreModule)
   },
   {
      path : 'search/resource-centre',
   loadChildren : () => import('./search/search.module').then(m => m.SearchModule)
   },
   {
      path : 'need-a-lawyer',
      loadChildren : () => import('./need-a-lawyer/need-a-lawyer.module').then(m => m.NeedALawyerModule)
   },
   {
      path : 'event',
      loadChildren : () => import('./event/event.module').then(m => m.EventModule)
   },
   // {
   //    path : 'event/:data',
   //    loadChildren : () => import('./event/event.module').then(m => m.EventModule)
   // },
   {
      path : 'services',
      loadChildren : () => import('./services/services.module').then(m => m.ServicesModule)
   },
   {
      path : 'bar-association',
      loadChildren : () => import('./bar-association/bar-association.module').then(m => m.BarAssociationModule)
   },
   {
      path : 'job-centre',
      loadChildren : () => import('./job-centre/job-centre.module').then(m => m.JobCentreModule)
   },
   {
    path : 'search',
    loadChildren : () => import('./search/search.module').then(m => m.SearchModule)
 },
 {
   path : 'about',
    loadChildren : () => import('./about/about.module').then(m => m.AboutModule)
},
 {
    path : 'privacy',
    loadChildren : () => import('./privacy/privacy.module').then(m => m.PrivacyModule)
 },
 
 {
    path : 'transaction-terms',
    loadChildren : () => import('./transaction-terms/transaction-terms.module').then(m => m.TransactionTermsModule)
 },
 {
    path : 'how-it-work',
    loadChildren : () => import('./how-it-works/how-it-works.module').then(m => m.HowItWorksModule)
 },
 {
   path : ':name',
   loadChildren : () => import('./public-profile/public-profile.module').then(m => m.PublicProfileModule)
},

// {
//    path : 'search/people',
//    loadChildren : () => import('./search/search.module').then(m => m.SearchModule)
// },
// {
//    path : 'search',
//    loadChildren : () => import('./search/search.module').then(m => m.SearchModule)
// },
   

{
   path : 'searc',
   loadChildren : () => import('./search/search.module').then(m => m.SearchModule)
},
{
   path : 'home/contact',
   loadChildren : () => import('./contactus/contactus.module').then(m => m.ContactusModule)
},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
