import { Component, OnInit, Input, Output, EventEmitter,ViewChild,ElementRef } from '@angular/core';
import { ResourceCentreService } from '../resource-centre.service';
import { Resource } from '../resource';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LikeService } from '../../like/like.service';
import { CommentService } from '../../comment/comment.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginComponent } from '../../login/login.component';
import { ApiService } from '../../api.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-docfree',
  templateUrl: './docfree.component.html'
  // styleUrls: ['./resorce-form.component.css']
})
export class DocFreeComponent implements OnInit {
  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  resourceId;
  // resource: Resource;
  resource: any;
  featured:Resource[]=[];
  comments: Comment[];
  closeModal:string;
  get_rcpopular_videos:any;
  categoryId: any;
  token: string;
  urlSafe;
  name: any = {};
  paymentType:any;
  toolbar:any;
  pagecmt: number = 1;
  countcmt: number = 0;
  tableSizecmt: number = 4;
  tableSizescmt: any = [3, 6, 9, 12];
  r: any;
  c:any;
cmt:any;
closeResult:string;
categorytype=3;
categoryid:any;
liked:any='';
recent: Resource[] = [];
slideConfig = { "slidesToShow": 3, "slidesToScroll": 1 };


  constructor(public sanitizer: DomSanitizer,
    public api: ApiService,
    private modalService: NgbModal, 
    public resourcecenterservice: ResourceCentreService, 
    public likeService: LikeService, 
    public commentService: CommentService, 
    private formBuilder: FormBuilder,
    private route: ActivatedRoute, private router: Router,) {

    if(localStorage.getItem('USERID')){


      this.token =  localStorage.getItem('USERID')
     }
     if(this.api.loggedIn()==false&&localStorage.getItem('USERID')){
     this.refresh();
     }
    }
  @ViewChild(LoginComponent)loginPrev: LoginComponent;
  @ViewChild('openLoginModal')loginModal: ElementRef; 
  @Output() loginEvent = new EventEmitter<string>();

  ngOnInit(): void {
     this.categoryid = this.route.snapshot.paramMap.get('ResourceId');
    console.log('rrrrrrrrrrrrr', this.categoryid);
    this.mostArticle();
    this.getDocFree(this.categoryid);
    this.featured_members();
    this.getrcpopularvideos();
    this.getComments(this.categoryid,3);
   
console.log('payyyyyyyyyyyyyy',this.paymentType);
  }
  showform;
  show(){
    this.showform = !this.showform;
  }

  getDocFree(resourceId) {
    this.resourcecenterservice.resourceSingleview(resourceId).subscribe((res: any) => {
      this.resource = res.messages.feed_list[0];
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.resource.attachement_url + "#toolbar=0");
      console.log('saaaaaaaaaaa', this.resource)
    });
  }
  featured_members(){
    this.resourcecenterservice.featured_members().subscribe((res: Resource[]) => {
     
      this.featured = res;
  
  
      console.log("Resource..:::", this.resource);
    })
  }
  changeevent(e) {
    console.log(e.target.value);
  }
  get f() {
    return this.myForm.controls;
  }
  myForm = new FormGroup({
    name: new FormControl('',Validators.required),
    mobile: new FormControl(''),
    email: new FormControl('',Validators.required),
    resource_centre_id:new FormControl(''),
  });
  submitted=false;
  submit() {
    this.submitted = true;
    if (this.myForm.valid) {

    const formData = new FormData();
    formData.append('name', this.myForm.get('name').value);
    formData.append("mobile", this.myForm.get('mobile').value);
    formData.append("email", this.myForm.get('email').value);

    this.resourcecenterservice.rcDocViewCreate(formData).subscribe((res: any) => {
      console.log("Event Registered..:::");

      this.myForm.reset();
    });
  }
  }

mostArticle() {
  this.resourcecenterservice.most_recent_article().subscribe((res: Resource[]) => {
    this.recent = res;
  })
}
  
  rcdownload() {
    this.submitted==true;
  //  if(this.myForm.valid){
    const formData = new FormData();
    formData.append('resource_centre_id', this.categoryid);
   
    this.resourcecenterservice.rcdownload(formData).subscribe((res: any) => {
      console.log("Event Registered..:::");
    });

  // }
}
  like(resource) {
    if(this.token){
     
        let resource_id = resource.id,
          liked = resource.liked,
          member_id = resource.member_id;
    
        // this.showicon = !this.showicon;
        // this.show = !this.show;
        console.log('like',resource);
        const formData = new FormData();
        formData.append('post_id', resource_id + '');
        formData.append('category_id',this.categorytype+'');
        formData.append('member_id', member_id + '');
        if (liked > 0 ) {
          liked = 0;
          resource.like_count = resource.like_count - 1;
        } else {
         liked = 1;
         resource.like_count = resource.like_count + 1;
        }
        // UPdating the ui object
        resource.liked = liked;
    
        formData.append('status', liked + '');
        console.log('status',liked);
    
        this.likeService.create(formData).subscribe((res: any) => {
          console.log("Roar Liked..:::", res.roar);
        });
    
        console.log(this.categoryid);
      }
    
      }


  getIframeUrl() {
    return "http://localhost/soolegal/cdn/assets/uploads/news/assets/uploads/roar/Lorem_ipsum.pdf"; // this.resource.attachement_url;
  }
  downloadMyFile(){
    this.submitted==true;
    if(this.myForm.valid){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.resource.attachement_url);
    link.setAttribute('download', `products.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

click(){
  if(this.token){
    // this.showform = !this.showform;
    // this.router.navigate(['/roar']);
  // this.submit('');
  }else{
    this.loginPrev.openLogin();
  }
}
refresh(){

}


getImagePic(resource) {
  return resource.member_profile_pic_url;
}
getImage(featured){
  return featured.url;
}

getComments(post_id, categoryid) {
  this.commentService.getAll(post_id, 3).subscribe((com: Comment[]) => {
    this.comments = com;
    console.log("Comments..:::", this.comments);

  });
}

  onCommentCreate(resource_id) {
    this.getComments(resource_id, 3);
    console.log(resource_id, 3);
    console.log('news creayed');
  }
  onComment(resource_id) {
    this.getComments(resource_id, 3);
    console.log('news creayednnnnnnnnnnnnnn');
  }

// !!!!!!!!!!!!!!!!!popup for comment

  open(content, r) {
    this.r = r;
    this.getComments(this.resource.id, 3);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

// !!!!!!!!!!!!   popup for pdf


 ope(conte) {
  this.modalService.open(conte, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismiss(reason)}`;
  });
}


private getDismiss(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

// ###########share popup
openshare(conte) {
  this.modalService.open(conte, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissshare(reason)}`;
  });
}


private getDismissshare(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

  onTableDataChangecmt(event1: any) {
    this.pagecmt = event1;
    this.getComments;
  }
  onTableSizeChangecmt(event1: any): void {
    this.tableSizecmt = event1.target.value;
    this.pagecmt = 1;
    this.getComments;
  }


// ############### comment popup ########## 
// popup for delete icon
triggerModal(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((res) => {
    this.closeModal = `Closed with: ${res}`;
  }, (res) => {
    this.closeModal = `Dismissed ${this.getDismissReasons(res)}`;
   
  });
 
}

private getDismissReasons(reason: any): string {
  
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return  `with: ${reason}`;
   
  }
 
}
  // socialshare url
  getLocation(resource_id) {
    return location.href + '/' + resource_id;
    //  return  "https://stackoverflow.com"+'/'+news_id;
  }
// paid form

  myFormPaid = new FormGroup({
    name: new FormControl('',Validators.required),
    mobile: new FormControl(''),
    email: new FormControl('',Validators.required),
    resource_centre_id:new FormControl(''),
  });

  get formPaid() {
    return this.myFormPaid.controls;
  }
  
  submitPaidForm(type:any) {
    console.log(this.resource['id'],"this.formPaid");
    if (this.myFormPaid.valid) {
      const formData = new FormData();
      formData.append('id', this.resource['id']);
      formData.append("title", this.resource['title']);
      formData.append("username", this.resource['username']);
      formData.append("paymentType", this.resource['paymentType']);
      formData.append("description", this.resource['description']);
      formData.append("price", this.resource['price']);
      formData.append("mobile", this.myFormPaid.get('mobile').value);
      formData.append("email", this.myFormPaid.get('email').value);
      console.log(formData.get('key'),"this.formPaid");
      this.resourcecenterservice.addtocart(formData).subscribe((res: Resource[]) => {
        // this.resource = res;
        console.log("res",res['status']);
        if(res['status']=="201"){
          this.router.navigateByUrl('/resource-centre/add-to-cart');  // open welcome component
        }
      })
    }
  }

  getrcpopularvideos(){
    this.resourcecenterservice.get_rc_popular_videos().subscribe((res: any) => {
      this.get_rcpopular_videos = res.messages.feed_list;
    })
  }

}




