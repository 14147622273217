<!-- 

	<main role="main" id="roar-page">
        <section class=" ipad-hide phone-hide">
          <div class="container-fluid inner-title">
            <div class="row">
              <div class="col-md-1 pl-0 text-right"><span>NEWS</span></div>
              <div class="col-md-10">Delhi HC directs Singh Brothers to Maintain Status-quo over Assets & Properties  |  Delhi HC directs...</div>
              <div class="col-md-1 pr-0 text-left"><span>NEWS</span></div>
            </div>
          </div>
        </section>
      
      
      
        
        <section class="ipad-top-space">
          <div class="container container-small">
            <div class="row">
      
      
      
     
      
              <div class="col-md-6 col-lg-12">
          
                
      
      
      
                
              
        <article>
          <div class="component-box-1 ">
            <div class="user-info-box-main">
            <div class="user-info-box">
              <h4></h4>
              <div class="user-info-box-inner no-scroll pb-3">
              
      
      
      
              <div class="text-wrapper text-left">
      
               
                  <p>An employment contract is an agreement that discusses in detail the working rapport of a company and an employee. It helps both parties to comprehend their obligations and the stipulations of employment.</p>
                  
                  <p>Importance</p>
                  
                  <p>A good quality employment contract is valuable to both the employee and the employer. It spells out the privileges and obligations of each party, protects the job security of the employee and protects the employer from certain risks such as the discharge of confidential employer information after the period of employment ends. Some jurisdictions require employment contracts for particular positions.</p>
                  
                  <p>i) Term- Most employment contracts have a specific term of employment. This assures that employeeshavea secured position in the company as long as they do not disobey the terms of the contract, and also permits employers to release the employee at the ending of the term.</p>
                  
                  <p>ii) Termination-A good employment contract will accurately mention what acts can amount to termination of the employee. This, in a way, helps both parties, because it allows the employee to know which activities are allowed and which are forbidden, thus rendering the possibility a serious breach less likely. The labour law of the concernedjurisdiction should be crosschecked to ensure that the terms of the contract do not disagree with legal requirements.</p>
                  
                  <p>iii) Non-competition Covenants-If the employee will have accessibility to confidential company information, it is imperative from the employer's point of view to comprise a clause preventing the employee from revealing this information to others. An employer might also desire to prevent the employee from working for competitors, although the labour laws of various jurisdictions differ on the satisfactoriness of such a clause. In both cases, non-competing clauses are typically binding on the employee for a certain time being after the employment ends.</p>
                  
                  <p>iv) Duties-The duties of both the employer and the employee should be clearly stipulated in the employment contract. This section should comprisean employee’s job duties, salary, benefits, and any overtime bonus. The employer's right to shift the employee to a better position should also be mentioned.</p>
                  
                  <p>v) Dispute Resolution -A good employment contract will indicate dispute resolution procedures that reduce the time and expenditure of a courtroom battle. Arbitration procedures offers lesser time and expense, although appeals from arbitration decisions are usuallycomplicated, it is a more preferred resolution method.</p>
                  
                  <p>Essentials of Employment Contracts</p>
                  
                  <p>When a person drafts an employment contract, he/she shall ensure that these particulars are present in the contract:</p>
                  
                  <p>i)&nbsp; Title of Contract: When a company has to draft an Employment Contract, the company should begin with giving a title to the Employment Contract. The title of the Employment Contract need not give any details about the terms and conditions of the Contract. In general, the title ‘Employment Contract’ is considered more than enough.</p>
                  
                  <p>ii)&nbsp; State the Parties: It is crucial that the name and identity of the parties to the Contract must be mentioned in the contract explicitly. Generally, in the case of Employment Contract, the parties comprise the name of the Company and the name of the Employee. In addition, the locality of the Company must be particularly written in the Contract.</p>
                  
                  <p>iii)&nbsp; List Terms and Conditions:&nbsp; The most important aspect in drafting an Employment Contract, are the stipulations of the contract.By and large, the bare minimum requirement of an Employment contract is salary, hours of employment, and the severance packages provided in terms and conditions.</p>
                  
                  <p>iv)&nbsp; Delineate Position Duties: It ought to be kept in mind that the employee should not be astonished by any new accountability or work, and the employer is getting what needs to be done. For ensuring these things, it is vital to write an outline of things, and it is important to write a detailed outline of the duties of the person applying for such a position. A complete list should be prepared with the Employment Contract for providing transparency about the tasks associated with the position.</p>
                  
                  <p>v)&nbsp; Be Clear on Compensation: The compensation facet of your contract must be unambiguous and direct. This way, there will not be any perplexity or disagreement regarding the employee is first or second retribution.</p>
                  
                  <p>vi)&nbsp; Miscellaneous Clauses: On the basis of the nature of the Company and the kind of business, the company may consider adding other clauses in the contract which include, i) Non-Solicitation Clause- which bans the employees from soliciting the customers from any particular geographical area and taking them with him ii) Privacy- this clause says that the employee shall not have expectations of confidentiality in the employer-issued devices. iii) Probationary Clause- this clause provides the authority to an employer to remove an employee within a particular period of time.</p>
                  
                  <p>After the Company has finished drafting the Employment Contract, it is essential to seek advice from an attorney to methodically review the contract. This way can unpredictably lower the chances of facing litigation arising out of the contract. The legal document should be combined with the prior oral or written negotiations that took place between the parties.</p>
                  <p>Legal Validity of Employment Bond</p>
                  
                  <p>Employment bonds are employment agreements, which comprise of a negative covenant. In Indian Law, the employment agreements with negative covenants areconsidered to be valid and legally enforceable if the parties agree to have free consent, i.e. without fraud, coercion, undue influence, mistake and misrepresentation. The Indian courts are of theopinion that in the event of a breach of contract by the employee, the employer shall be allowed to recuperate damages only if the employer bore a considerable amount of expenditure. Indian law makes it compulsory the employment bonds be “reasonable” in order to be legitimate. The expression reasonable remains vagueas it is not defined in the Indian law, and therefore the courts have given connotation to “reasonable” depending upon the facts and circumstances of the cases. The suggestion which has emerged till now is that state of affairs predetermined in the contract should be vital to protect the attention of the employer and pay reimbursementto the losses caused by the breach of contract. Additionally, the punishment or compulsory employment period fixed should not be disproportionate.</p>
                  <p>Can one challenge the Enforceability of employment bond?</p>
                  
                  <p>The legality of Employment bonds can be challenged based on Section27 of the Indian Contract Act. Section 27 of the Indian Contract Act, 1872 imposes a prohibition on any agreement in restraint of trade and profession.</p>
                  
                  <p>As per the authority of Section 27, any terms and conditions of an employment contract which directly or indirectly makes it compulsory for the employee to provide service to the employer or puts a constriction on them joining the competitor or other employer will not be not valid under the Indian law. The employee has the complete right to leave the employment even if there has been an agreement in the employment bond to serve the employer for a precise period of time.</p>
                  
                  <p>For an employment bond to be legitimate under Indian law, it has to be proved that it is essential for the autonomy of trade. In a circumstance, where the employer is able to establish that the employee is, joining the competitor to reveal the trade secret then the court may issue an injunction order restricting the employee from joining the competitor. If an agreement is challenged on the basis of violating the provision relating to restraint of trade, the burden is on the party supporting the contract to show that restraint is reasonably essential to protect his interests.</p>
                  
                  <p>Important Judgements</p>
                  
                  <p>Sushilaben Indravadan Gandhi &amp; Anr. v. New India Assurance Company Limited &amp;aOrs. (SLP (CIVIL) NO: 1170 OF 2019)</p>
                  
                  <p>Here, in this case, Dr Alpesh Gandhi, (the deceased) husband of the Appellant, Mrs. Sushilaben Indravadan Gandhi had entered into a contract with the Rotary Eye Institute on May 4, 1996, and the contract was titled as ‘Contract for Services as Honorary Ophthalmic Surgeon at Rotary Eye Institute’. The insured, i.e. the Institute, had taken up a ‘Private Car B’ policy from the New IndiaAssurance Company Limited (the Respondent) wherein the Institute had paid an extra premium or an endorsement of IMT-5 (the Insurance Policy). That insurance policy further gave accidental personal coverage to unnamed passengers other than the ones insured, his paid driver, cleaner or a person employed by the insured and coming within the scope of Workman Compensation Act, 1923 to the scale of 100% compensation in case of death. Due to the incautious and negligent driving of the bus driver, the deceased while travelling in a mini-bus owned by the Institute, suffered some serious injuries and eventually died.</p>
                  
                  <p>Therefore, the Appellant filed a petition before the Motor Accidental Claim Tribunal under Section 166 ofthe Motor Vehicles Act, 1988 against the Respondent, the Institute as well as the driver of the mini-bus and claimed a compensation of wherein the Tribunal held that the employment arrangement between the deceased and the institute to be a ‘Contract for Service&amp;. Hence the deceased was not an employee of the institute. Therefore, the for the above-stated reason, the Tribunal directed the Respondent, the institute and the driver of the mini-bus to pay a compensation of Rs. 37,63,100/- as well as interest at the rate of 8% p.a.Discontented by order of the Tribunal, an appeal was filed by the Respondent before the High Court of Gujarat wherein the Court relied upon the limitation of liability clause under the Insurance Policy which discharged the Respondent from any liability to a third party since the death has taken place in the course of the employment of such person. Moreover, the Court was of the opinion that since the contract between the insurance company and the dead person was a ‘Contract of Service’, the accountability of insurance company towards the deceased was narrowed to the extent ofRs.50, 000/-.Thereafter, being upset by the decision of Gujarat High Court, the Appellant filed an appeal before the Supreme Court for increasing the amount of compensation. The Court referred tovarious judgments which and the tests laid down to adapt the difference between a ‘Contract for Service’ and a ‘Contract of Service’. The Court diligently assessed precedents for this aspect and observed that there were no established tests that can be practised and that each fact would be examined for drawing a conclusion on this aspect. Furthermore, the Court observed that the preliminary tests where an employer exercises control over the person engaged could not be enforced in separation.</p>
                  
                  <p>Niranjan Shankar Golikari v. Century Spinning &amp; Mfg Co. Ltd (1967 AIR 1098)</p>
                  
                  <p>In this famous case, a foreign producer entered into a with a company manufacturing tyre yarn, which expressed that the company would preserve the secrecy of all technicalinformation. In execution of the agreement, the company signed a non-disclosure agreement withthe appellant at the time of his employment. The Supreme Court of India identified the tests toregulate the rationality of restrictive agreements in terms of Section 27 of the Contract Act.Considering the above-stated facts, the agreement was held to be valid, and the appellant wasduly restrained from serving elsewhere for the duration of the agreement. The Supreme Court held that there is an implied term in a contract of employment that a previous employee may not make use of his previous employer’s trade secrets. Disregarding this exception, the employee is authorized to utilize most of the knowledge and skill possessed by him. The Court depended on Lord Halsbury’s Laws of England which held that as a general principle an individual was authorized to implement his lawful trade or calling whenever he wills, as well as the law, had resentment restrained against intervention with trade even at the risk of intervention with freedom of contract, as it was a public policy to combat all restraints upon freedom of individualaction which isdetrimental to interests of trade. This principle was based upon public policy, which is an effectual concept that changes and evolves depending upon time and needs.</p>
                  
                  <p>Percept D’Mark (India) Pvt. Ltd. v. Zaheer Khan &amp; Anr.[Appeal (civil): 5573-5574 of 2004]</p>
                  
                  <p>In this case, it was held by the Supreme Court that a restrictive contract extending beyond its terms is void and non-enforceable. The Court further held that the doctrine of restraint of trade does not apply during the continuation of the contract of employment, and it is applied only when the contract comes to an end. Moreover, it was observed that the doctrine of restraint of trade is not constricted to contracts of employment but is also significant to all other contracts.</p>
                  
                  <p>Gujarat Bottling v. Coca Cola(1995 AIR 2372)</p>
                  
                  <p>In this case, the Supreme Court distinguished that in the past, nations frequently went to war for the conservation and improvement of their economic interests, but things have changed with the passing time. Whereas during this time of competition and high employee turnover rate, the employers generally try to safeguard their trade secrets so as to direct to engage in the market,make their employees sign contracts/agreements which limit their employees from revealing the job profile, henceforward, competing with the same establishment or workingwith the same competitors. Thus, such agreements entered between the employer, and the employee should not hinder the growth of employees and secure the interests of the employer.</p>
                  
                  <p>Conclusion</p>
                  
                  <p>The notion of the employment agreement is analogous to any other contract in force. A comprehensive employment contract provides the significant duties and responsibilities of the employee. The duties and responsibilities help him to comprehend accurately what his employer is expecting him to do. The major objective of an employment contract is to avoid revelation of information, non-competition, non-solicitation as well as fortification of confidential information, so it is always prudent to implement a written agreement of employment between the employer and the employee. The employee signs the employment contract. An appointment letter is generally executed with a view to cover the probation period of an employee until the time such employee is made permanent in the organization by the employer.</p>
                
             
              </div>
            
      
      
              </div>
            </div>
            </div>
          </div>
          </article>
      
      
      
      
    
      
      
              </div>
      
            </div>
          </div>
        </section>
      
            <div class="h-50"></div>
      
      
      
      
      
      
      
      
      
      
      
      
      
              
              
      
      
        
            
              
              <div class="mobile-menu-bar">
              <a data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"> <img src="images/home.png" alt="home" /> </a>
              <a href="#"> <img src="images/users.png" alt="User" /> </a>
              </div>
              
      </main>
      
           -->


           <div class="container-wrapper">
            <div class="container" style="margin-top: 110px;">
          
            <p><font color="#333333"><font size="3" style="font-size: 12pt; font-weight: bold;"><b>Privacy
              Policy </b></font></font>
          </p>
          <p><font color="#333333"><font size="3" style="font-size: 12pt">SoOLEGAL.com
            respects the security and Privacy of its users and members. Our main
            aim is to offer you a personalized internet experience which delivers
            relevant services, resources and information.  </font></font>
          </p>
          <p><font color="#333333"><font size="3" style="font-size: 12pt; font-weight: bold;"><b>How
            SoOLEGAL will protect your information?</b></font></font></p>
            <p><font color="#333333"><font size="3" style="font-size: 12pt">To
              protect your personal information is of utmost importance to us.
              SoOLEGAL does not make the personal information available to any
              third party without your permission. Access to some of our services,
              content and information is password protected. We would advise you
              not to share your password with anyone. Moreover whenever you sign
              into account always remember to log out at the end of the session.
              This will make sure that any third party cannot use your personal
              information in case you are sharing your computer or using computer
              at any public place. Unfortunately over the internet no data
              transmission can be guaranteed as 100% secure. Despite of all the
              efforts made by SoOLEGAL to protect your personal information, it
              cannot ensure the security of all the information you transmit. You
              transmit any such information on your own risk. But once we receive
              your transmission we would make our best effort to ensure its
              security and protection on our system. </font></font>
          </p>
            
              <p><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#333333"><font face="Calibri, serif;font-weight:bold"><b>Changes
                to the Privacy Policy</b></font></font></font></font></p>
                <p style="margin-bottom: 0.22cm; line-height: 100%"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#333333"><font face="Calibri, serif">SoOLEGAL
                  reserves the right to make changes to its Privacy Policy and Terms of
                  Use at any point of time without prior notice. If there are any
                  significant changes to the Privacy Policy then members and users will
                  be notified. We also encourage users and members of SoOLEGAL to keep
                  checking this page for major updates, if they are not getting notice
                  of any improvements made on the portal. </font></font></font></font>
              </p>
              <p><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#333333"><font face="Calibri, serif;font-weight:bold"><b>For
                any queries </b></font></font></font></font>
          </p>
          <p><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#333333"><font face="Calibri, serif">In
            case you have any queries regarding the Privacy Policy of SoOLEGAL,
            then you may contact us by sending an email to </font></font><a href="mailto:info@soolegal.com"><font face="Calibri, serif">info@soolegal.com</font></a><font color="#333333"><font face="Calibri, serif">
            or visit our Contact Us page </font></font></font></font>
          </p>
          <p><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#333333"><font face="Calibri, serif;font-weight:bold"><b>Correct
            Inaccuracies</b></font></font></font></font></p>
            <p style="margin-bottom: 0.22cm; line-height: 100%"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#333333"><font face="Calibri, serif">When
              you sign up for SoOLEGAL products and services, then we may notify
              you through an e-mail confirming your account with our system. The
              message will be sent to your email address registered with us, in
              which we have described the ways to change or delete the account</font></font><font color="#333333"><font face="Calibri, serif"><b>
              </b></font></font><font color="#333333"><font face="Calibri, serif">information
              submitted. </font></font></font></font>
          </p>
          <p><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><font color="#333333"><font face="Calibri, serif;font-weight:bold"><b>Cookies:
          </b></font></font><font color="#333333"><font face="Calibri, serif">SoOLEGAL
          use cookies to allow you to use our services without any trouble and
          also to keep a track of particular statistical information which
          helps us to make improvements on our site. Cookies let us to save
          preferences and passwords for you so that you don’t have to write
          them next time you make a visit on the site. </font></font></font></font>
          </p>
          </div>
          </div>