<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous">

<section class="container query-board pt-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item"><a href="#">Query Board</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{case.topic}}</li>
        </ol>
      </nav>
    <div class="row">
        <div class="col-md-9">        
            <div class="query-title  py-2">
                <h3>{{case.description | slice:0:100}}</h3>
                <a href="#" class="badge badge-purple">{{case.topic}}</a>
            </div>
            <div class="query-body">
                <p>{{case.description}}</p>
            </div>
            <div class="grp-lft">
                <button type="button" class="btn btn-mdorange" [attr.disabled]="case.id? '' : null" (click)="openreplyposter(t_id)">Reply to this Case</button>
                <button type="button" class="btn btn-solid" style="margin-left: 1%;"><i class="fa fa-share" aria-hidden="true" ></i> Respond Now </button>
            </div>
    <div *ngIf="case_reply[0]"> 
            <h5 class="title-nal mt-5">Answers({{case_reply[0]['reply_count']}})</h5>      
                <div class="media g-mb-30 media-comment"  *ngFor ="let cr of case_reply">             
                      <img class="d-flex g-width-50 g-height-50 rounded-circle g-mt-3 g-mr-15" src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Image Description">
                      <div class="media-body u-shadow-v18 g-bg-secondary g-pa-30">
                            <div class="g-mb-15">
                            <h5 class="h5 g-color-gray-dark-v1 mb-0">{{cr.member_details[0]['username']}}</h5>
                            <span class="g-color-gray-dark-v4 g-font-size-12">{{cr.created_date*1000|date:'yyyy/MM/dd H:m:s'}}</span>
                      </div>                  
                        <p>{{cr.answer}}</p>                  
                        <ul class="list-inline d-sm-flex my-0">
                          <li class="list-inline-item g-mr-20">
                            <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" href="#!">
                              <i class="fa fa-thumbs-up g-pos-rel g-top-1 g-mr-3"></i>
                              178
                            </a>
                          </li>
                          <li class="list-inline-item g-mr-20">
                            <a class="u-link-v5 g-color-gray-dark-v4 g-color-primary--hover" href="#!">
                              <i class="fa fa-thumbs-down g-pos-rel g-top-1 g-mr-3"></i>
                              34
                            </a>
                          </li>
                          <li class="list-inline-item ml-auto">
                            <button class="btn btn-sm btn-block btn-mdorange rlc">Contact Now</button>
                          </li>
                        </ul>
                      </div>
                  </div>
            </div>
     </div>
     
        <div class="col-sm-3" listWrapper>
            <h5 style="text-align:center;color: cadetblue;text-transform: uppercase;">Recently Listed Cases</h5>
           
            <div class="card mb-4 box-shadow " *ngFor = "let query of queryBoardList" >
                
                <div class="card-body">
                  <h6 class="card-title pricing-card-title"><a [attr.href]="'/need-a-lawyer/' + query.topic_id">{{query.topic}}</a></h6>
                  <p>Posted On:{{query.posted_date}}</p>
                  <a href="#" class="badge badge-purple">{{query.topic}}</a>
                  <p class="body-mrc">{{query.description}}</p>
                  <button type="button" class="btn btn-sm btn-block btn-mdorange rlc" [attr.disabled]="query.csreply_id? '' : null" (click)="openreplyposter(query.topic_id)">Reply to this Case</button>
                </div>
            </div>
            <!-- <div class="card mb-4 box-shadow">
                
                <div class="card-body">
                  <h6 class="card-title pricing-card-title">Can I Get a Legal Advisor for Filing a Civil and Criminal Case</h6>
                  <a href="#" class="badge badge-purple">In Criminal Law</a>
                  <p class="body-mrc">My spouse is a Schizophrenia patient. She is not capable of taking care of himself/herself and constantly refusing to ...</p>
                  <button type="button" class="btn btn-sm btn-block btn-mdorange rlc">Reply to this Case</button>
                </div>
            </div> -->
        </div>
    </div>
</section>

<!-- Reply -->
<div class="modal fade" id="replyposter" tabindex="-1" aria-labelledby="replyposter" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0">

            <button type="button" class="close close-model" data-dismiss="modal" aria-label="Close" (click)="posterReset()" #closeReplyposter style="
            margin-left: 468px;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-header panel-heading">
                <h4 class="modal-title">Reply to this Case</h4>                     
            </div>
            <div class="modal-body popup-body">
                <form class="mt-28" [formGroup]="ReplyPoster" (ngSubmit)="onSubmit(t_id)">
                    <div class="form-group">
                        <label style="margin-left: 20px;"><input type="checkbox" class="form-check-input" value="1" name="quote_fee" id="quote-fee" (change)="quoteFee($event)" formControlName="case_quote_type" > Would like to quote the fee </label>
                    </div>
                    <div class="form-group render-case-quote-fee" *ngIf="liketoquotefee">
                        <label><input type="radio" checked="checked" name="case_quote_type" formControlName="case_quote_type" id="case_quote_type_whole" value="1"> For the whole case </label>
                        <label>&nbsp;<input type="radio" name="case_quote_type" formControlName="case_quote_type" id="case_quote_type_retainership" value="2"> Retainership</label>
                        <label>&nbsp;<input type="radio" name="case_quote_type" formControlName="case_quote_type" id="case_quote_type_per_hearing" value="3"> Per hearing </label>
                        <div class="form-group">
                            <input name="purpose_quote_fee" formControlName="case_quote_fee" class="bg-white  form-control rounded-0" 
                            [class.is-valid]="posterForm?.case_quote_fee.valid && posterForm?.case_quote_fee.touched"
                            [class.is-invalid]="posterForm?.case_quote_fee.invalid && posterForm?.case_quote_fee.touched" id="purpose-quote-fee" type="text" placeholder="Fee">
                            
                            <div *ngIf="posterForm.case_quote_fee.errors" class="invalid-feedback">
                                <div *ngIf="posterForm.case_quote_fee.errors.required">
                                    Please enter Fee
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="error">Please enter some query</div>

                    <div class="form-content">
                        <div class="alert alert-success" *ngIf="message" role="alert">
                            {{message}}
                        </div>

                            <div class="form-group">

                                <input type="name" class="form-control rounded-0" name="name" [value]="username" disabled="disabled" aria-describedby="emailHelp" placeholder="name">

                            </div>
                            <div class="form-group">

                                <input type="email" class="form-control rounded-0" name="email" [value]="email" disabled="disabled" aria-describedby="emailHelp" placeholder="Email">

                            </div>

                            <div class="form-group">

                                <input type="tel" class="form-control rounded-0" name="mobile" [value]="contact_no" disabled="disabled" aria-describedby="phoneHelp" placeholder="Phone">

                            </div>


                            <label for="note" > </label>
                            <div class="NgxEditor__Wrapper" height="100px" >
                                <ngx-editor-menu [editor]="editor" height="500px" > </ngx-editor-menu>
                                <ngx-editor
                                
                                [editor]="editor"
                                height="100px"
                                formControlName="answer"
                                [disabled]="false"
                                [placeholder]="'Type here...'"
                                [class.is-valid]="posterForm?.answer.valid && posterForm?.answer.touched"
                                [class.is-invalid]="posterForm?.answer.invalid && posterForm?.answer.touched"
                                ></ngx-editor>
                                <div *ngIf="posterForm.answer.errors" class="invalid-feedback">
                                    <div *ngIf="posterForm.answer.errors.required">
                                        Please enter description
                                    </div>
                                </div>
                            </div>  
                            <div class="text-right">
                                <button type="submit" class="d-inline btn btn-primary mb-0 mx-auto">Submit</button>
                                <button type="button" class=" btn btn-primary mb-0 ml-2" data-dismiss="modal" aria-label="Close" (click)="posterReset()" #closeReplyposter>Close</button>
                            </div>

                    </div>
                </form>

            </div>




        </div>
    </div>
</div> 

<span #openReplyposterModal data-toggle="modal" data-backdrop="static" data-target="#replyposter" style="display: none;"></span>
<app-login (loginEvent)="loginSubmit($event)"></app-login>