import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment'
// import { Comment } from './comment';
import { News } from './news';
import { LoginService } from '../login/login.service';



@Injectable({
  providedIn: 'root'
})
export class NewsService {

  // private apiURL = "https://jsonplaceholder.typicode.com/depakraj/soolegalrest";

  public apiURL = environment.baseURL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private httpClient: HttpClient) { }


  getAll(type: string): Observable<News[]> {
    let user_id = localStorage.getItem('USERID');
    let headers = this.httpOptions
    if (user_id) {

      const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('USERID') });
      headers = { headers: header };
      console.log(headers, localStorage.getItem('USERID'));
    }

    return this.httpClient.get<News[]>(this.apiURL + 'news?type=' + type, headers)
      .pipe(
        catchError(this.errorHandler)
      )
  }



  create(formData): Observable<any> {
    const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('USERID') });
    const headers = { headers: header };
    console.log(headers, localStorage.getItem('USERID'));
console.log(formData);
    return this.httpClient.post(this.apiURL + 'post_news', formData, headers)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  find(id: number): Observable<any> {

    // return this.httpClient.get(this.apiURL + 'news/' + id)
    return this.httpClient.get(this.apiURL + 'show_feed_data/' + id)

      .pipe(
        catchError(this.errorHandler)
      )
  }

  update(id: number, news: News): Observable<any> {

    return this.httpClient.put(this.apiURL + 'news/', JSON.stringify(news), this.httpOptions)

      .pipe(
        catchError(this.errorHandler)
      )
    // return this.httpClient.put(this.apiURL + '/posts/' + id, JSON.stringify(news), this.httpOptions)

    // .pipe( 
    //   catchError(this.errorHandler)
    // )                                                                                                                                                                     
  }

  delete(id: number) {
    const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('USERID') });
    const headers = { headers: header };
    console.log(headers, localStorage.getItem('USERID'));
    return this.httpClient.delete(this.apiURL + 'news/' + id, headers)

      .pipe(
        catchError(this.errorHandler)
      )
  }
  bookmark(news_id) {
    const header = new HttpHeaders({ 'JWTAuthorization': localStorage.getItem('USERID') });
    const headers = { headers: header };
    console.log(headers, localStorage.getItem('USERID'));
    return this.httpClient.post(this.apiURL + 'news-bookmark/' + news_id, {}, headers)
      .pipe(
        catchError(this.errorHandler)
      )
  }
limitnews(){
  return this.httpClient.get(this.apiURL + 'limitnews' )
    .pipe(
      catchError(this.errorHandler)
    )
}
  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  top_news_list(){
    return this.httpClient.get(this.apiURL + 'top_news_list' )
      .pipe(
        catchError(this.errorHandler)
      )
  }
  
  latest_news(){
    return this.httpClient.get(this.apiURL + 'latest_feed_list/2')
      .pipe(
        catchError(this.errorHandler)
      )
  }
 
  



}



