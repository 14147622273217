import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { BarAssociationRoutingModule } from './bar-association-routing.module';
import { BarAssociationComponent } from './bar-association.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxClampModule } from 'ngx-clamp';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ViewComponent } from './view/view.component';
import { MemberComponent } from './member/member.component';
import { CalenderComponent } from './calender/calender.component';
import { CommitteComponent } from './committemembers/committemembers.component';
import { HelplineComponent } from './helpline/helpline.component';
import { ContactusComponent } from './contactus/contactus.component';
import { GalleryComponent } from './gallery/gallery.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FilterPipeModule } from 'ng2-nested-search';
import { EventModule } from '../event/event.module';



@NgModule({
  declarations: [BarAssociationComponent,AboutusComponent,ViewComponent,MemberComponent,CalenderComponent,
    CommitteComponent,HelplineComponent,ContactusComponent,GalleryComponent],
  imports: [
    CommonModule,
    BarAssociationRoutingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgxClampModule,
    Ng2SearchPipeModule,
    FilterPipeModule, 
    EventModule

  ],
     
})
export class BarAssociationModule { }
