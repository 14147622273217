
<section class="ipad-top-space" style="margin-top: 135px;">
    <div class="container container-medium minwidth">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card shadow-none pa roar">
                    <div class="card-body roar">
                        <h4>Top Contributers</h4>
                        <article class="component-box-1" *ngFor="let c of top_contributor_list">
                            <div class="user-info-box-main">
                                <div class="user-info-box">
                                  
                                    <div class="user-info-box-inner p-0 no-scroll" >
                                        <div class="user-box">
                                            <div class="ribbon ribbon-top-right"><span>Premium</span></div>
                                            <!-- <div class="img-box"><img src="../../assets/images/ab_circle.png" /></div> -->
                                            <div class="img-box">
                                                <img *ngIf="c.url"   [src]="getTopContributorImage(c)" />
                                                <img *ngIf="!c.url" src="../../assets/images/profile-user-round.png" alt=""
                                                class="img-fluid rounded-circle width-50"> 
                                            </div>

                                            <div class="name">{{c.username}}</div>
    
                                            <!-- <p class="small">
                                                Advocate | Consultant | Counsellor <br /> B.A. LLB (Hons.)
                                            </p> -->

                                            <div class="lawyer-info">
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 0)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Lawyer</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 1)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Law Firm</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 2)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Associate</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 3)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Partner</span></p>
                                                    </div>

                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 4)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Publisher</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 5)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Author</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 6)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Student</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 7)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Bar Association</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 8)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Retired Judge</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 9)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Law Academician</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 11)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Chartered Accountant</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 12)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Company Secretary</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type ==13)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Intelligence Services</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 14)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Cost Accountant</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 15)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Tax Lawyer</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 16)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Consultant</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 17)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Temporary Login</span></p>
                                                    </div>
                                                    <div style="padding: 10px; color: black;" class="lcn-in" 
                                                    *ngIf="(c.member_type == 12)">
                                                        <p class="fa-solid fa-location-dot"><span style="font-family: jost;color: white;">&nbsp;Company Secretary</span></p>
                                                    </div>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <h6 class="review-count">Roar Ratings</h6>
                                                        <div class="stars">
                                                            <span class="fa fa-star checked"></span>
                                                            <span class="fa fa-star checked"></span>
                                                            <span class="fa fa-star checked"></span>
                                                            <span class="fa fa-star"></span>
                                                            <span class="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <h6 class="review-count">Articles &nbsp;{{c.Total}}</h6>
                                                    </div>
                                                </div>
                                            <div class="progress-bar-box">
                                                <div class="progress">
                                                    <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <!-- <div class="pc-box">New Delhi (Now Online)</div> -->
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                          
                        </article>
                       
                        <!-- <article class="component-box-1">
                            <div class="user-info-box-main">
                                <div class="user-info-box">
                                    <h4>Your Profile</h4>
                                    <div class="user-info-box-inner p-0 no-scroll">
                                        <div class="user-box">
                                            <div class="ribbon ribbon-top-right"><span>Premium</span></div>
                                            <div class="img-box">
                                                <a href="javascript:void(0);"
                                                    [routerLink]="['/'+memberapi.userInfoapi?.username]"><img
                                                        src="../../assets/images/profile-user-round.png" /></a>
                                            </div>
                                            <div class="name">{{memberapi.userInfoapi?.first_name}}</div>

                                            <p class="small">
                                                Advocate | Consultant | Counsellor <br /> B.A. LLB (Hons.)
                                            </p>
                                            <div class="pc-box">New Delhi</div>

                                            <div class="progress-bar-box">
                                                <div class="progress">
                                                    <div class="progress-bar" role="progressbar" style="width: 85%;"
                                                        aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <div class="pc-box"><a href="#" class="link">Edit/ Modify Profile</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article> -->


                        <!-- <div class="component-box-1 infobox-1">
                            <div class="user-info-box-main">
                                <div class="user-info-box">
                                    <h4 class="m-0">Your ROARS

                                    </h4>
                                    <div class="user-info-box-inner active h-0 no-scroll">
                                        <div class="icons-box row">
                                            <div class="col"><span>999</span> <img src="../../assets/images/icons/roar_view.png"
                                                    alt="Appointments" />
                                                <h5 class="small">Roar Views</h5>
                                            </div>
                                            <div class="col"><span>999</span> <img src="../../assets/images/icons/roar_view.png"
                                                    alt="Appointments" />
                                                <h5 class="small">Roar Likes</h5>
                                            </div>
                                            <div class="col"><span>999</span> <img src="../../assets/images/icons/roar_view.png"
                                                    alt="Appointments" />
                                                <h5 class="small">Roar Comments</h5>
                                            </div>
                                        </div>
                                        <p><a href="#">Click for detailed information</a></p>
                                    </div>
                                </div>
                                <div class="overlay-box" data-toggle="modal" data-target="#user-modal">
                                    <div class="message-box">
                                        <p>You are amongst top 27.8% of all SoOLegal Members</p>
                                        <hr>
                                        <p>12.2% visitors have refered your profile to others</p>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="component-box-1 infobox-1">
                            <div class="user-info-box-main">
                                <div class="user-info-box">
                                    <h4 class="m-0">Your Cases

                                    </h4>
                                    <div class="user-info-box-inner active h-0 no-scroll">
                                        <div class="icons-box row">
                                            <div class="col"><span>999</span> <img src="../../assets/images/icons/client.png"
                                                    alt="Appointments" />
                                                <h5 class="small">Client</h5>
                                            </div>
                                            <div class="col"><span>999</span> <img src="../../assets/images/icons/case.png"
                                                    alt="Appointments" />
                                                <h5 class="small">Cases</h5>
                                            </div>
                                            <div class="col"><span>999</span> <img src="../../assets/images/icons/prospect.png"
                                                    alt="Appointments" />
                                                <h5 class="small">Prospects</h5>
                                            </div>
                                        </div>
                                        <p><a href="#">Click for detailed information</a></p>
                                    </div>
                                </div>
                                <div class="overlay-box" data-toggle="modal" data-target="#user-modal">
                                    <div class="message-box">
                                        <p>You are amongst top 27.8% of all SoOLegal Members</p>
                                        <hr>
                                        <p>12.2% visitors have refered your profile to others</p>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>
  

            </div>
            <!-- <pre>{{existingRoar | json}}</pre> -->
            <!-- Feed Section -->
            <div class="col-lg-6 col-md-6 col-sm-12">
                <!-- Write Post -->
                <div class="card shadow-none roar">
                    <div class="catd-body-ctm">
                        <div class="write-post">
                            <div class="form-group">
                              <h3>Write a post</h3>
                          </div>

                            <div class="panel">
                                <input class="form-control" (click)="onclick()" (click)="click()" placeholder="Share what you are thinking here..." 
                                style="margin-bottom:15px">
                                <div class="panel-body roar">
                                    <form [formGroup]="myForm" (ngSubmit)="submit()" *ngIf="showform">
                                        <input class="form-control" formControlName="title" placeholder="Title"
                                            style="margin-bottom:15px"> &ensp;

                                        <input class="form-control" formControlName="tags" placeholder="tags"
                                            style="margin-bottom:15px"> &ensp;

                                        <textarea class="form-control" rows="2" formControlName="content"
                                            placeholder="What are you thinking?" style="margin-bottom:5px"></textarea>
                                        <!-- <label for="image">Select images</label> -->
                                        <div class="form-group"><input type="file" name="imageURL" formControlName="url"
                                                id="url" (change)="onFileChange($event)" class="filestyle" value=""
                                                data-icon="false" tabindex="-1"
                                                style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">

                                            <div class="bootstrap-filestyle input-group"><span><a
                                                        class="btn btn-trans btn-icon fa fa-image add-tooltip"
                                                        style="background-color:rgba(0, 0, 0, 0.116)"></a></span><input
                                                    type="text" class="form-control no-padd " placeholder="" disabled=""
                                                    [value]="roarImage.name?roarImage.name:(existingRoar?existingRoar.roar_image_url:'')">
                                                <span class="group-span-filestyle input-group-btn" tabindex="0"><label
                                                        for="url" class="btn btn-default "><span
                                                            class="buttonText">Choose</span></label>
                                                </span>
                                            </div>
                                        </div>
                                        <input class="form-control" formControlName="roar_video_url" placeholder="roar_video_url"
                                            style="margin-bottom:15px"> &ensp;
                                        <div class="mar-top clearfix">
                                            <button class="btn btn-sm btn-primary pull-right" type="submit"
                                                (click)="click()" ><i class="fa fa-pencil fa-fw"></i>
                                                Share</button>
                                            <!-- <a class="btn btn-trans btn-icon fa fa-image add-tooltip" href="#"></a>
                                                <a class="btn btn-trans btn-icon fa fa-video add-tooltip" href="#"></a> -->
                                            <!-- <a class="btn btn-trans btn-icon fa fa-file add-tooltip" href="#"></a> -->
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <hr class="m-0">

                        </div>
                    </div>
                </div>

                <!-- User Post 1 -->
                <!-- <div class="card shadow-none pa roar" *ngFor="let r of roar_list  
                  paginate
                    : {
                        id: 'pagination1',
                        itemsPerPage: tableSize,
                        currentPage: page,
                        totalItems: count
                      };
                let i = index
              " > -->
              <div class="card shadow-none pa roar" *ngFor="let r of roar_list             
              let i = index
          " >
              <!-- <pre>{{existingRoar | json}}</pre> -->
 <!-- News -->
                    <div class="catd-body" *ngIf="r.category_id=='2'?true:false" >
                        <div class="row p-2">
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-lg-4 col-3">
                                        <!-- <img src="../../assets/images/profile-user-round.png" alt=""
                                            class="img-fluid rounded-circle width-50"> -->
                                            <img *ngIf="r.profile_pic_url" [src]="getImagePic(r)"
                                            class="img-fluid rounded-circle width-50">
                                            <!-- <img *ngIf="r.profile_pic_url" src={{r.profile_pic_url}}
                                            class="img-fluid rounded-circle width-50"> -->
                                            <img *ngIf="!r.profile_pic_url" src="../../assets/images/profile-user-round.png" alt=""
                                            class="img-fluid rounded-circle width-50"> 
                                    </div>
                                    <div class="col-lg-8 col-7 p-0">
                                        <h5 class="m-0">{{r.member_details[0].username}}</h5>
                                        <span class="badge badge-primary">News</span>
                                        <span> <a title="Edit" *ngIf="memberapi.userInfoapi?.member_id==r.member_id"
                                                class=" btn-xs " [attr.href]="'/news/' + r.id +'/edit'"><i
                                                    class="fas fa-edit"></i></a></span>
                                                  
                                        <a title="delete" *ngIf="memberapi.userInfoapi?.member_id==r.member_id"
                                            (click)="triggerModal(modalData)" class="btn-xs">
                                            <ng-template #modalData let-modal>
                                                <div class="modal-header">
                                                    <h4 class="modal-title" id="modal-basic-title">DELETE NEWS</h4>
                                                    <button type="button" class="close" aria-label="Close"
                                                        (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <p><strong>Are you sure you want to delete this News</strong></p>


                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-danger"
                                                        (click)="deleteNews(r.id)"
                                                        (click)="modal.close('Save click')">Delete</button>
                                                </div>
                                            </ng-template><i class="fas fa-trash" style="color:#90278E"></i>
                                        </a>
                                        <!-- <p>{{r.publish_date|date:'yyyy-MM-dd H:m:s'}}</p> -->
                                        <p>{{r.created_at|date:'yyyy-MM-dd H:m:s'}}</p>
                                      
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <i class="ft-more-vertical pull-right"></i>
                            </div>
                        </div>
                        <div class="write-post">
                            <div class="col-sm-12 px-2">
                                <!-- <h4><a  [attr.href]="'/roar/'+ r.id + '/view'" style="text-decoration:none;color:black"  >{{r.news_title}}</a></h4> -->
                                <h4><a [attr.href]="'/news/' + r.id" style="text-decoration:none;color:black">{{r.news_title}}</a></h4>
                               
                               
                                <p [ngClass]="{ 'limitTextHeight': !isReadMore }" [innerHTML]="r.news_content"> </p>
                             
                           
                                <!-- </p> <span> <button type="button" (click)="showText()">
                                        {{ isReadMore ? 'Read Less': 'Read More' }}
                                    </button></span> --> 

                            </div>
                            <a style="padding:10px;" (click)="showText()" style="color:#90278E" >{{ isReadMore ? 'Read Less': 'Read More' }}</a>
                            <div class="col-sm-12 px-2 pb-2">
                                <img [src]="getImage(r)" alt="" class="img-fluid">
                            </div>
                            <hr class="m-0">
                            <div class="row p-1">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-4 pr-0">
                                            <span (click)="click()"><i class="far fa-heart inlike"  (click)="like(r)" *ngIf="!r.liked"></i> 
                                                <i class="far fa-heart like" style="color:red"  (click)="like(r)"
                                                    *ngIf="r.liked"></i>
                                                    {{r.like_count}}</span>
                                                    <i class="fa fa-eye ccounts" aria-hidden="true"></i>{{r.view_count}}
                                        </div>

                                        <!--      <div class="col-8 pl-0">
                                          <ul class="list-unstyled users-list m-0">
                                              <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="John Doe" class="avatar avatar-sm pull-up">
                                                  <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-19.png" alt="Avatar">
                                              </li>
                                              <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Katherine Nichols" class="avatar avatar-sm pull-up">
                                                  <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-18.png" alt="Avatar">
                                              </li>
                                              <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Joseph Weaver" class="avatar avatar-sm pull-up">
                                                  <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-17.png" alt="Avatar">
                                              </li>
                                              <li class="avatar avatar-sm">
                                                  <span class="badge badge-info">+4 more</span>
                                              </li>
                                          </ul>
                                      </div> -->
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="pull-right">
                                        <!-- <span class="pr-1"><i class="far fa-comment-alt"></i> 44</span> -->
                                        <span (click)="open(mymodal,r)" class="pr-1"><i class="far fa-comment-alt"></i>
                                            {{r.comment_count}}</span>






                                        <span (click)="ope(modal)" class="pr-1"><i class="far fa-share-square"></i>
                                            </span>

                                        <ng-template #modal let-modal>

                                            <share-buttons [theme]="'material-dark'"
                                                [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                                                [show]="15" [url]="getLocation(r.id)" [autoSetMeta]="false">
                                            </share-buttons>


                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-outline-dark"
                                                    (click)="modal.close('Save click')">Close</button>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
 <!-- news end -->    

<!-- roar -->
<div class="catd-body" *ngIf="r.category_id=='1'?true:false" >
            <div class="row p-2">
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-lg-4 col-3">
                            <!-- <img src="../../assets/images/profile-user-round.png" alt=""
                                class="img-fluid rounded-circle width-50"> -->
                                <img *ngIf="r.profile_pic_url" [src]="getImagePic(r)"
                                class="img-fluid rounded-circle width-50">
                                <!-- <img *ngIf="r.profile_pic_url" src={{r.profile_pic_url}}
                                class="img-fluid rounded-circle width-50"> -->
                                <img *ngIf="!r.profile_pic_url" src="../../assets/images/profile-user-round.png" alt=""
                                class="img-fluid rounded-circle width-50"> 
                        </div>
                        <div class="col-lg-8 col-7 p-0">
                            <h5 class="m-0">{{r.member_details[0].username}}</h5>
                            <span class="badge badge-success">Roar</span>
                            <span> <a title="Edit" *ngIf="memberapi.userInfoapi?.member_id==r.member_id"
                                    class=" btn-xs " [attr.href]="'/roar/' + r.id +'/edit'"><i
                                        class="fas fa-edit"></i></a></span>
                            <a title="delete" *ngIf="memberapi.userInfoapi?.member_id==r.member_id"
                                (click)="triggerModal(modalData)" class="btn-xs">
                                <ng-template #modalData let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">DELETE ROAR</h4>
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <p><strong>Are you sure you want to delete this Roar</strong></p>


                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger"
                                            (click)="deleteRoar(r.id)"
                                            (click)="modal.close('Save click')">Delete</button>
                                    </div>
                                </ng-template><i class="fas fa-trash" style="color:#90278E"></i>
                            </a>
                            <!-- <p>{{r.publish_date|date:'yyyy-MM-dd H:m:s'}}</p> -->
                            <p>{{r.created_at}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <i class="ft-more-vertical pull-right"></i>
                </div>
            </div>
            <div class="write-post">
                <div class="col-sm-12 px-2">
                    <h4><a  [attr.href]="'/roar/'+ r.id + '/view'" style="text-decoration:none;color:black"  >{{r.roar_title}}</a></h4>
                    <p [ngClass]="{ 'limitTextHeight': !isReadMore }" [innerHTML]="r.roar_content"> </p>
                 
               
                    <!-- </p> <span> <button type="button" (click)="showText()">
                            {{ isReadMore ? 'Read Less': 'Read More' }}
                        </button></span> --> z

                </div>
                <a style="padding:10px;" (click)="showText()" style="color:#90278E" >{{ isReadMore ? 'Read Less': 'Read More' }}</a>
                <!-- <div class="col-sm-12 px-2 pb-2">
                    <img [src]="getImage(r)" alt="" class="img-fluid">
                </div> -->
                <hr class="m-0">
                <div class="row p-1">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <span (click)="click()"><i class="far fa-heart inlike"  (click)="like(r)" *ngIf="!r.liked"></i> 
                                    <i class="far fa-heart like" style="color:red"  (click)="like(r)"
                                        *ngIf="r.liked"></i>
                                        {{r.like_count}}</span>
                                        <i class="fa fa-eye ccounts" aria-hidden="true"></i>
                                        {{r.view_count}}
                            </div>

                            <!--      <div class="col-8 pl-0">
                              <ul class="list-unstyled users-list m-0">
                                  <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="John Doe" class="avatar avatar-sm pull-up">
                                      <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-19.png" alt="Avatar">
                                  </li>
                                  <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Katherine Nichols" class="avatar avatar-sm pull-up">
                                      <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-18.png" alt="Avatar">
                                  </li>
                                  <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Joseph Weaver" class="avatar avatar-sm pull-up">
                                      <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-17.png" alt="Avatar">
                                  </li>
                                  <li class="avatar avatar-sm">
                                      <span class="badge badge-info">+4 more</span>
                                  </li>
                              </ul>
                          </div> -->
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="pull-right">
                            <!-- <span class="pr-1"><i class="far fa-comment-alt"></i> 44</span> -->
                            <span (click)="open(mymodal,r)" class="pr-1"><i class="far fa-comment-alt"></i>
                                {{r.comment_count}}</span>






                            <span (click)="ope(modal)" class="pr-1"><i class="far fa-share-square"></i>
                                </span>

                            <ng-template #modal let-modal>

                                <share-buttons [theme]="'material-dark'"
                                    [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                                    [show]="15" [url]="getLocation(r.id)" [autoSetMeta]="false">
                                </share-buttons>


                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                        (click)="modal.close('Save click')">Close</button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!-- roar end -->

<!-- resource centre -->
<div class="catd-body" *ngIf="r.category_id=='3'?true:false" >
    <div class="row p-2">
        <div class="col-sm-6">
            <div class="row">
                <div class="col-lg-4 col-3">
                    <!-- <img src="../../assets/images/profile-user-round.png" alt=""
                        class="img-fluid rounded-circle width-50"> -->
                        <!-- <img *ngIf="r.profile_pic_url" [src]="getImagePic(r)"
                        class="img-fluid rounded-circle width-50"> -->
                        <img *ngIf="r.profile_pic_url" src={{r.profile_pic_url}}
                        class="img-fluid rounded-circle width-50">
                        <img *ngIf="!r.profile_pic_url" src="../../assets/images/profile-user-round.png" alt=""
                        class="img-fluid rounded-circle width-50"> 
                </div>
                <div class="col-lg-8 col-7 p-0">
                    <h5 class="m-0">{{r.member_details[0]['username']}}</h5>
                    <span class="badge badge-danger">Resource centre</span>                   
                    <!-- <span> <a title="Edit" *ngIf="memberapi.userInfoapi?.member_id==r.member_id"
                            class=" btn-xs " [attr.href]="'/roar/' + r.id +'/edit'"><i
                                class="fas fa-edit"></i></a></span> -->
                    <!-- <a title="delete" *ngIf="memberapi.userInfoapi?.member_id==r.member_id"
                        (click)="triggerModal(modalData)" class="btn-xs"> 
                        <ng-template #modalData let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title">DELETE DOCUMENT</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p><strong>Are you sure you want to delete this Document</strong></p>


                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger"
                                    (click)="deleteRoar(r.id)"
                                    (click)="modal.close('Save click')">Delete</button>
                            </div>
                        </ng-template><i class="fas fa-trash" style="color:#90278E"></i>
                    </a>-->
                    <!-- <p>{{r.publish_date|date:'yyyy-MM-dd H:m:s'}}</p> -->
                    <p>{{r.created_at}}</p>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <i class="ft-more-vertical pull-right"></i>
        </div>
    </div>
    <div class="write-post">
        <div class="col-sm-12 px-2">
            <h4><a  [attr.href]="'/roar/'+ r.id + '/view'" style="text-decoration:none;color:black"  >{{r.rc_title}}</a></h4>
            <p [ngClass]="{ 'limitTextHeight': !isReadMore }" [innerHTML]="r.rc_description"> </p>
         
       
            <!-- </p> <span> <button type="button" (click)="showText()">
                    {{ isReadMore ? 'Read Less': 'Read More' }}
                </button></span> --> 

        </div>
        <a style="padding:10px;" (click)="showText()" style="color:#90278E" >{{ isReadMore ? 'Read Less': 'Read More' }}</a>
        <div class="col-sm-12 px-2 pb-2">
            <img [src]="getImage(r)" alt="" class="img-fluid">
        </div>
        <hr class="m-0">
        <div class="row p-1">
            <div class="col-6">
                <div class="row">
                    <div class="col-4 pr-0">
                        <span (click)="click()"><i class="far fa-heart inlike"  (click)="like(r)" *ngIf="!r.liked"></i> 
                            <i class="far fa-heart like" style="color:red"  (click)="like(r)"
                                *ngIf="r.liked"></i>
                                {{r.like_count}}</span>
                            <i class="fa fa-eye ccounts" aria-hidden="true"></i>
                            {{r.view_count}}
                    </div>

                    <!--      <div class="col-8 pl-0">
                      <ul class="list-unstyled users-list m-0">
                          <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="John Doe" class="avatar avatar-sm pull-up">
                              <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-19.png" alt="Avatar">
                          </li>
                          <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Katherine Nichols" class="avatar avatar-sm pull-up">
                              <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-18.png" alt="Avatar">
                          </li>
                          <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Joseph Weaver" class="avatar avatar-sm pull-up">
                              <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-17.png" alt="Avatar">
                          </li>
                          <li class="avatar avatar-sm">
                              <span class="badge badge-info">+4 more</span>
                          </li>
                      </ul>
                  </div> -->
                </div>
            </div>
            <div class="col-6">
                <div class="pull-right">
                    <!-- <span class="pr-1"><i class="far fa-comment-alt"></i> 44</span> -->
                    <span (click)="open(mymodal,r)" class="pr-1"><i class="far fa-comment-alt"></i>
                        {{r.comment_count}}</span>
                    <span (click)="ope(modal)" class="pr-1"><i class="far fa-share-square"></i>
                        </span>
                    <ng-template #modal let-modal>
                        <share-buttons [theme]="'material-dark'"
                            [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                            [show]="15" [url]="getLocation(r.id)" [autoSetMeta]="false">
                        </share-buttons>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Close</button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<!--resource centre end -->

<!-- event -->
<div class="catd-body" *ngIf="r.category_id=='4'?true:false" >
    <div class="row p-2">
        <div class="col-sm-6">
            <div class="row">
                <div class="col-lg-4 col-3">
                    <!-- <img src="../../assets/images/profile-user-round.png" alt=""
                        class="img-fluid rounded-circle width-50"> -->
                        <img *ngIf="r.profile_pic_url" [src]="getImagePic(r)"
                        class="img-fluid rounded-circle width-50">
                        <!-- <img *ngIf="r.profile_pic_url" src={{r.profile_pic_url}} -->                    
                        <img *ngIf="!r.profile_pic_url" src="../../assets/images/profile-user-round.png" alt=""
                        class="img-fluid rounded-circle width-50"> 
                </div>
                <div class="col-lg-8 col-7 p-0">
                    <h5 class="m-0">{{r.username}}</h5>
                    <span class="badge badge-warning">Event</span>
                    
                    <span> <a title="Edit" *ngIf="memberapi.userInfoapi?.member_id==r.event_author_id"
                            class=" btn-xs " [attr.href]="'/event/' + r.id +'/edit'"><i
                                class="fas fa-edit"></i></a></span>
                    <a title="delete" *ngIf="memberapi.userInfoapi?.member_id==r.event_author_id"
                        (click)="triggerModal(modalData)" class="btn-xs">
                        <ng-template #modalData let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title">DELETE EVENT</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p><strong>Are you sure you want to delete this Event</strong></p>


                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger"
                                    (click)="deleteEvent(r.id)"
                                    (click)="modal.close('Save click')">Delete</button>
                            </div>
                        </ng-template><i class="fas fa-trash" style="color:#90278E"></i>
                    </a>
                    <!-- <p>{{r.publish_date|date:'yyyy-MM-dd H:m:s'}}</p> -->
                    <p>{{r.created_at}}</p>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <i class="ft-more-vertical pull-right"></i>
        </div>
    </div>
    <div class="write-post">
        <div class="col-sm-12 px-2">
            <!-- <h4><a  [attr.href]="'/roar/'+ r.id + '/view'" style="text-decoration:none;color:black"  >{{r.event_title}}</a></h4> -->
            <h4 ><a [attr.href]="'/event/' + r.id+'/view'" style="text-decoration:none;color:black"  >{{r.event_title}}</a></h4>
            <p [ngClass]="{ 'limitTextHeight': !isReadMore }" [innerHTML]="r.event_description"> </p>
         
       
            <!-- </p> <span> <button type="button" (click)="showText()">
                    {{ isReadMore ? 'Read Less': 'Read More' }}
                </button></span> --> 

        </div>
        <a style="padding:10px;" (click)="showText()" style="color:#90278E" >{{ isReadMore ? 'Read Less': 'Read More' }}</a>
        <div class="col-sm-12 px-2 pb-2">
            <img [src]="getImage(r)" alt="" class="img-fluid">
        </div>
        <hr class="m-0">
        <div class="row p-1">
            <div class="col-6">
                <div class="row">
                    <div class="col-4 pr-0">
                        <span (click)="click()"><i class="far fa-heart inlike"  (click)="like(r)" *ngIf="!r.liked"></i> 
                            <i class="far fa-heart like" style="color:red"  (click)="like(r)"
                                *ngIf="r.liked"></i>
                                {{r.like_count}}</span>
                            <i class="fa fa-eye ccounts" aria-hidden="true"></i>
                            {{r.view_count}}
                    </div>

                    <!--      <div class="col-8 pl-0">
                      <ul class="list-unstyled users-list m-0">
                          <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="John Doe" class="avatar avatar-sm pull-up">
                              <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-19.png" alt="Avatar">
                          </li>
                          <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Katherine Nichols" class="avatar avatar-sm pull-up">
                              <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-18.png" alt="Avatar">
                          </li>
                          <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Joseph Weaver" class="avatar avatar-sm pull-up">
                              <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-17.png" alt="Avatar">
                          </li>
                          <li class="avatar avatar-sm">
                              <span class="badge badge-info">+4 more</span>
                          </li>
                      </ul>
                  </div> -->
                </div>
            </div>
            <div class="col-6">
                <div class="pull-right">
                    <!-- <span class="pr-1"><i class="far fa-comment-alt"></i> 44</span> -->
                    <span (click)="open(mymodal,r)" class="pr-1"><i class="far fa-comment-alt"></i>
                        {{r.comment_count}}</span>
                    <span (click)="ope(modal)" class="pr-1"><i class="far fa-share-square"></i>
                        </span>
                    <ng-template #modal let-modal>
                        <share-buttons [theme]="'material-dark'"
                            [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
                            [show]="15" [url]="getLocation(r.id)" [autoSetMeta]="false">
                        </share-buttons>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Close</button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<!--event end -->



                </div>
                <!-- <div class="d-flex justify-content-center">
                    <pagination-controls
                    id="pagination1"
                      previousLabel="Prev"
                      nextLabel="Next"
                      (pageChange)="onTableDataChange($event)"
                    >
                    </pagination-controls>
                  </div> -->
  <!--Post Comments-->
                <ng-template #mymodal let-modal>
                    
                  
                    <article>
                        <div class="component-box-1 ">
                            <div class="user-info-box-main">

                                <single-comment-form comment-form [postId2]="r.id" [categoryId]="1"
                                    (onSubmit)="onCommentCreate(r.id)">

                                </single-comment-form>

                                <div class="user-info-box ">
                                    <h4>Comments </h4>

                                    <section class="user-info-box-inner no-scroll text-left h-0 p-4">

                                        <div *ngFor="let c of comments  | paginate
                                        : {
                                            id: 'pagination2',
                                            itemsPerPage: tableSizecmt,
                                            currentPage: pagecmt,
                                            totalItems: countcmt
                                          };
                                    let i = index
                                  " >

                                            <single-comment [comment]="c" [groupId]="c.id" (onSubmit)="onCommentCreate(r.id)"></single-comment>
                                            <div style="margin-left:100px">
                                                <single-comment *ngFor="let c1 of c.comments;" [comment]="c1"
                                                    [groupId]="c.id" [isReply]="true"
                                                    (onSubmit)="onCommentCreate(r.id)">
                                                </single-comment>
                                            </div>

                           
                                        </div>
                                    </section>
                                    <div class="d-flex justify-content-center">
                                        <pagination-controls
                                        id="pagination2"
                                          previousLabel="Prev"
                                          nextLabel="Next"
                                          (pageChange)="onTableDataChangecmt($event)"
                                        >
                                        </pagination-controls>
                                      </div>                                 

                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-outline-dark"
                                            (click)="modal.close('Save click')">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                   
                </ng-template>

                <!-- User Post 2 -->
                <!-- <div class="card shadow-none pa roar">
                        <div class="catd-body">
                            <div class="row p-2">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-lg-4 col-3">
                                            <img src="https://test.soolegal.com/cdn-static/assets/images/profile2.jpg" alt="" class="img-fluid rounded-circle width-50">
                                        </div>
                                        <div class="col-lg-8 col-7 p-0">
                                            <h5 class="m-0">Elaine Dreyfuss</h5>
                                            <p>4 hours ago</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <i class="ft-more-vertical pull-right"></i>
                                </div>
                            </div>
                            <div class="write-post">
                                <div class="col-sm-12 px-2 pb-2">
                                    <img src="https://cdn.soolegal.com/assets/uploads/news/_large/whatsapp_image_2022-01-20_at_16_11_35.jpeg" alt="" class="img-fluid">
                                </div>
                                <div class="col-sm-12 px-2">
                                    <p>When searching for videos of a different singer, Scooter Braun, a former marketing executive of So So Def Recordings, clicked on one of Bieber's 2007 videos by</p>
                                </div>
                                <hr class="m-0">
                                <div class="row p-1">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-sm-4 col-3 pr-0">
                                                <span><i class="far fa-heart"></i> 120</span>
                                            </div> -->
                <!--  <div class="col-sm-8 col-7 pl-0">
                                          <ul class="list-unstyled users-list m-0">
                                              <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="John Doe" class="avatar avatar-sm pull-up">
                                                  <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-19.png" alt="Avatar">
                                              </li>
                                              <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Katherine Nichols" class="avatar avatar-sm pull-up">
                                                  <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-18.png" alt="Avatar">
                                              </li>
                                              <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Joseph Weaver" class="avatar avatar-sm pull-up">
                                                  <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-17.png" alt="Avatar">
                                              </li>
                                              <li class="avatar avatar-sm">
                                                  <span class="badge badge-info">+4 more</span>
                                              </li>
                                          </ul>
                                      </div> -->
                <!-- </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="pull-right">
                                            <span class="pr-1"><i class="far fa-comment-alt"></i> 44</span>
                                            <span class="pr-1"><i class="far fa-share-square"></i> 23</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                <!-- User Post 3 -->
                <!-- <div class="card shadow-none pa roar">
                  <div class="catd-body">
                      <div class="row p-2">
                          <div class="col-6">
                              <div class="row">
                                  <div class="col-lg-4 col-3">
                                      <img src="https://test.soolegal.com/cdn-static/assets/images/profile2.jpg" alt="" class="img-fluid rounded-circle width-50">
                                  </div>
                                  <div class="col-lg-8 col-7 p-0">
                                      <h5 class="m-0">Elaine Dreyfuss</h5>
                                      <p>2 hours ago</p>
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-6">
                              <i class="ft-more-vertical pull-right"></i>
                          </div>
                      </div>
                      <div class="write-post">
                          <div class="col-sm-12 px-2 pb-2">
                              <img src="https://cdn.soolegal.com/assets/uploads/news/_large/whatsapp_image_2022-01-20_at_16_11_35.jpeg" alt="" class="img-fluid">
                          </div>
                          <hr class="m-0">
                          <div class="row p-1">
                              <div class="col-6">
                                  <div class="row">
                                      <div class="col-lg-4 col-3 pr-0">
                                          <span><i class="far fa-heart"></i> 120</span>
                                      </div> -->
                <!--  <div class="col-lg-8 col-7 pl-0">
                                          <ul class="list-unstyled users-list m-0">
                                              <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="John Doe" class="avatar avatar-sm pull-up">
                                                  <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-19.png" alt="Avatar">
                                              </li>
                                              <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Katherine Nichols" class="avatar avatar-sm pull-up">
                                                  <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-18.png" alt="Avatar">
                                              </li>
                                              <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Joseph Weaver" class="avatar avatar-sm pull-up">
                                                  <img class="media-object rounded-circle" src="../../../app-assets/images/portrait/small/avatar-s-17.png" alt="Avatar">
                                              </li>
                                              <li class="avatar avatar-sm">
                                                  <span class="badge badge-info">+4 more</span>
                                              </li>
                                          </ul>
                                      </div> -->
                <!-- </div>
                              </div>
                              <div class="col-6">
                                  <div class="pull-right">
                                      <span class="pr-1"><i class="far fa-comment-alt"></i> 44</span>
                                      <span class="pr-1"><i class="far fa-share-square"></i> 23</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> -->
            </div>
            <!-- Birthday Card -->
            <div class="col-lg-3 col-md-12 col-sm-12">

                <!-- Top News -->              
                <div class="card shadow-none mt-2 pa roar">
                    <div class="card-body roar">
                        <div class="last-blog">
                            <h5 class="card-title mb-1">Top News</h5>
                            <!-- <pre>{{top_news_list | json}}</pre> -->
                            <hr>
                            <div class="row" *ngFor="let n of top_news_list;">
                                <div class="col-sm-12">
                                    <h6>{{n.news_title}}</h6>                                   
                                    <span><i class='fa fa-heart' style='color: red'></i>{{n.like_count}} </span>
                                    <span><h6>viewed:</h6> {{n.view_count}} </span>
                                    <span><h6>Posted:</h6> {{n.created_at}} </span>
                                    <!-- <p class="">Posted:<span>{{n.created_at}}</span></p> -->
                                    <!-- <p class="mt-1"  [innerHtml]="n.news_content" ngx-clamp [ngxClampOptions]="{ clamp: 2}">
                                    </p> -->
                                   
                                    <hr>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
                
                    <!-- Last Video -->
                <div class="card shadow-none mt-2 pa">
                    <div class="card-body">
                        <div class="last-video">
                            <h5 class="card-title mb-1">Last Video</h5>
                            <hr>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/9VRzwsxBUR0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    <hr>
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/qls9RutkI9o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Last Blog -->
                <div class="card shadow-none mt-2 pa roar">
                    <div class="card-body roar">
                        <div class="last-blog">
                            <h5 class="card-title mb-1">Latest News</h5>
                            <hr>
                            <div class="row" *ngFor="let n of news;">
                                <div class="col-sm-12">
                                    <h6>{{n.title}}</h6>
                                    <p class="mt-1"  [innerHtml]="n.content" ngx-clamp [ngxClampOptions]="{ clamp: 2}">
                                    </p>
                                    <p class="mt-3">{{n.publish_date*1000|date:'yyyy/MM/dd'}}</p>
                                    <hr>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>

        </div>
    </div>
</section>

<div class="h-50"></div>

<section class="partners-main-box my-3">
    <div class="container-fluid p-0">
        <div class="bg-gray-new shadow-none">

            <div class="row justify-content-between align-items-center">
                <div class="col-md-12 col-lg-5 my-2 logo-box "> <img src="../../assets/images/-logo.png" alt="" style="margin-left: 39px;" class="im" />
                    <h4 style="margin-right: 38px;" class="title-1">Partner Services</h4>
                </div>
                <div class="col-md-12 col-lg-7 my-2">
                    <div class="partners owl-carousel owl-theme">
                        <div class="item">
                            <a href="#"><img src="../../assets/images/partners/partner-1.png" alt="partners" /></a>
                        </div>
                        <div class="item">
                            <a href="#"><img src="../../assetsimages/partners/partner-1.png" alt="partners" /> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<div class="mobile-menu-bar">
    <a data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
        aria-label="Toggle navigation"> <img src="../../assets/images/home.png" alt="home" /> </a>
    <a href="#"> <img src="../../assets/images/users.png" alt="User" /> </a>
</div>






<!-- PopUp Box for comment details -->
<div class="modal fade" id="comment" tabindex="-1" aria-labelledby="comment" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0">

            <button type="button" class="close close-model" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>

            <div class="modal-body popup-body">


                <form class="mt-28">
                    <div class="form-group">
                        <textarea class="form-control rounded-0 bg-white" placeholder="Comment Here"></textarea>
                    </div>


                    <div class="mt-4">
                        <button type="submit" class="d-block btn btn-primary mb-0 mx-auto">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-login (loginEvent)="loginSubmit($event)"></app-login>