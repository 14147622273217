// import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './search.component';
import { PublicProfileComponent } from '../public-profile/public-profile.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    SearchRoutingModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    ShareModule,
    ShareButtonsPopupModule,
    ShareIconsModule,
    ShareButtonsModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class SearchModule { }
