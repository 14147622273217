import { Component, OnInit } from '@angular/core';
import { MemberService } from '../member.service';

@Component({
  selector: 'app-followers',
  templateUrl: './followers.component.html',
  styleUrls: ['./followers.component.css']
})
export class FollowersComponent implements OnInit {
  followerlist: any;

  constructor(private memberapi:MemberService) { }

  ngOnInit(): void {
    this.getfollowers()
  }

  public isFollow: boolean = false;

  
  onClick(){
    this.isFollow = !this.isFollow;

  }

  getfollowers(){
    this.memberapi.getdata('member/followers').subscribe((res2: any) => {

      this.followerlist = res2.data
      console.log(this.followerlist,"this.followerlist")
    })

  }


  unfollow(data){
    console.log(data ,"member id ")
    const formData = new FormData();

    formData.append('to_member_id', data);
 
   this.memberapi.postmember('unfollowMember', formData).subscribe((res: any) => {
     console.log(res,"unfollow")
  this.getfollowers();
   })
  }

}
